import axios from "axios";
import helper from "./helper";

export default {
    getPathStasiun(station_id) {
        const url = helper.getBaseUrl() + "/@aggregation_query?type_name=BmkgStation&station_id=" + station_id + "&_metadata=station_id,station_wmo_id,station_elevation,path";
        return axios.get(url, helper.getConfig());
    },

    async getObserverStation(station_id) {
        var url = helper.getBaseUrl() + "/@search?type_name=Observer&station_id=" + station_id + "&_sort_asc=observer_name&_metadata=id,observer_id,path,observer_nip,observer_name,observer_telephone,is_active";
        var observers = [];
        await axios
            .get(url, helper.getConfig())
            .then(function (dataResponse) {
                var dataItems = dataResponse.data.items;
                for (var i in dataItems) {
                    var observer = {
                        id: dataItems[i].observer_id,
                        observer: dataItems[i].observer_name,
                    };
                    observers.push(observer);
                }
                resolve(observers);
                // observers = dataResponse.data.items
            })
            .catch(function (error) {
                // resolve(observers);
            });
        return observers;
    },

    getListMetaGeography(path) {
        const url = helper.getBaseUrl() + path + "/metageography/@items_list?include=id,geography_latitude,geography_longitude,geography_elevation,geography_valid_from,geography_valid_to";
        return axios.get(url, helper.getConfig());
    },
    /**
     * Metadata QC/Form
     */
    patchStasiun(path, post_data) {
        const url = helper.getBaseUrl() + path;
        return axios.patch(url, post_data, helper.getConfig());
    },

    async updateStasiun(path, post_data) {
        return await new Promise(async (resolve) => {
            const url = helper.getBaseUrl() + path;
            var data = axios.patch(url, post_data, helper.getConfig());
            resolve(data);
        });
    },

    async getQCData(path, key) {
        const url = helper.getBaseUrl() + path;
        let qc_data = {};
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                const { data } = response;
                qc_data = data[key];
                resolve(qc_data);
            })
            .catch(function (error) {});
        return qc_data;
    },
    async getOprHours(path) {
        const url = helper.getBaseUrl() + path;
        let data_opr_hours = {};
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                const { data } = response;
                data_opr_hours = data;
                resolve(data_opr_hours);
            })
            .catch(function (error) {});
        return data_opr_hours;
    },
    async getReferensiData(path) {
        const url = helper.getBaseUrl() + path;
        let ref_data = {};
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                const { data } = response;
                ref_data = data;
                resolve(data);
            })
            .catch(function (error) {});
        return ref_data;
    },

    async getMetadataPibal(path, name) {
        const url = helper.getBaseUrl() + path + name;
        let data = null;
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                data = response.data;
                resolve(response.data);
            })
            .catch(function (error) {});
        return data;
    },

    async getStationOperatingHours(path) {
        const url = helper.getBaseUrl() + path + "/@fieldvalue/BmkgSatu.metadata.mkg.IMKG.station_operating_hours";
        let data = null;
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                data = response.data;
                resolve(response.data);
            })
            .catch(function (error) {});
        return data;
    },

    async getBarometerAirRaksa(path) {
        const url = helper.getBaseUrl() + path + "/@fieldvalue/barometer_air_raksa";
        let data = null;
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                data = response.data;
                resolve(response.data);
            })
            .catch(function (error) {});
        return data;
    },

    async getBarometerDigital(path) {
        const url = helper.getBaseUrl() + path + "/@fieldvalue/barometer_digital";
        let data = null;
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                data = response.data;
                resolve(response.data);
            })
            .catch(function (error) {});
        return data;
    },

    async getBarometerDigital(path) {
        const url = helper.getBaseUrl() + path + "/@fieldvalue/barometer_digital";
        let data = null;
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                data = response.data;
                resolve(response.data);
            })
            .catch(function (error) {});
        return data;
    },

    async getEntryFirstEnable(path) {
        const url = helper.getBaseUrl() + path + "/@fieldvalue/entry_first_enable";
        let data = null;
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                data = response.data;
                resolve(response.data);
            })
            .catch(function (error) {});
        return data;
    },

    async getEntryFirstDate(path) {
        const url = helper.getBaseUrl() + path + "/@fieldvalue/entry_first_date";
        let data = null;
        await axios
            .get(url, helper.getConfig())
            .then(function (response) {
                // console.log(response)
                data = response.data;
                resolve(response.data);
            })
            .catch(function (error) {});
        return data;
    },

    async getStationElement() {
        const url = helper.getBaseUrl() + "/@setting_aelement";
        return await axios.get(url, helper.getConfig());
    },
    async getFormInput(path) {
        const url = helper.getBaseUrl() + path;
        return await axios.get(url, helper.getConfig());
    },
    async getLogQC(path, form_type) {
        const url = helper.getBaseUrl() + path + "/@aggregation_query?type_name=LogQcChange&form_type=" + form_type + "&_metadata=id,station_name,form_type,qc_type,qc_atribut,qc_old_value,qc_new_value,username";
        return await axios.get(url, helper.getConfig());
    },

    async requestMetadataUsage(path, post_data) {
        const url = helper.getBaseUrl() + path;
        return await axios.post(url, post_data, helper.getConfig());
    },

    async listMetadataUsage(path) {
        const url = helper.getBaseUrl() + path + "/usage/@items_list?include=BmkgSatu.metadata.station.behavusage.IBehavStationUsage";
        return await axios.get(url, helper.getConfig());
    },

    async getListManageUsage() {
        const url = helper.getBaseUrl() + "/settingapp/manageusage/@items_manageusage?include=manage_usage_id,manage_usage_name,manage_usage_status&page_size=100";
        return await axios.get(url, helper.getConfig());
    },

    async newManageUsage(post_data) {
        const url = helper.getBaseUrl() + "/settingapp/manageusage/";
        return await axios.post(url, post_data, helper.getConfig());
    },

    async patchManageUsage(id, post_data) {
        const url = helper.getBaseUrl() + "/settingapp/manageusage/" + id;
        return await axios.patch(url, post_data, helper.getConfig());
    },

    async listMetadataGeography(path) {
        const url = helper.getBaseUrl() + path + "/metageography/@items_list?include=geography_latitude,geography_longitude,geography_elevation,geography_valid_from,geography_valid_to";
        return await axios.get(url, helper.getConfig());
    },

    // NIMOS
    async listNimosSynchronization() {
        const url = helper.getBaseUrl() + "/@aggregation_query?type_name=LogNimos&_metadata=nimos_sync_date,nimos_description,nimos_total_rows";
        return await axios.get(url, helper.getConfig());
    },

    // Properties
    async listPenyelia() {
        const url = helper.getBaseUrl() + "@search?type_name=SettingToolbox&sb_form_type=Ku&sb_attribut=PLB";
        return await axios.get(url, helper.getConfig());
    },

    async listKondisiCuacaAwal() {
        const url = helper.getBaseUrl() + "@search?type_name=SettingToolbox&sb_form_type=Ku&sb_attribut=KCL";
        return await axios.get(url, helper.getConfig());
    },

    async listKondisiCuacaAkhir() {
        const url = helper.getBaseUrl() + "@search?type_name=SettingToolbox&sb_form_type=Ku&sb_attribut=KCR";
        return await axios.get(url, helper.getConfig());
    },

    async listKondisiSampling() {
        const url = helper.getBaseUrl() + "@search?type_name=SettingToolbox&sb_form_type=Ku&sb_attribut=KSL";
        return await axios.get(url, helper.getConfig());
    },

    async listKualitasSampel() {
        const url = helper.getBaseUrl() + "@search?type_name=SettingToolbox&sb_form_type=Ku&sb_attribut=KSM";
        return await axios.get(url, helper.getConfig());
    },

    async listCatatanLab() {
        const url = helper.getBaseUrl() + "@search?type_name=SettingToolbox&sb_form_type=Ku&sb_attribut=CL";
        return await axios.get(url, helper.getConfig());
    },

    async listKejadianLuarBiasa() {
        const url = helper.getBaseUrl() + "@search?type_name=SettingToolbox&sb_form_type=Ku&sb_attribut=KLB";
        return await axios.get(url, helper.getConfig());
    },
    async getSekuens(path, tahun) {
        const url = helper.getBaseUrl() + path + "/@search?tgl_angkat__gte=01-01-" + tahun + "&tgl_angkat__lte=31-12-" + tahun;
        return await axios.get(url, helper.getConfig());
    },
    async getUsageStationID(path) {
        const url = helper.getBaseUrl() + path + "/usage/labkualitasudara";
        return await axios.get(url, helper.getConfig());
    },
    async getStasiunLink(station_id) {
        const url = helper.getBaseUrl() + "/@aggregation_query?type_name=BmkgStation&station_id=" + station_id + "&_metadata=station_id,path";
        return await axios.get(url, helper.getConfig());
    },
};
