<template>
  <div>
    <b-row class="mt-n2">
      <b-col cols="12">
        <b-card class="text-dark font-weight-bolder">
          <b-row class="">
            <b-col sm="2 ">
              <b-form-group label-for="select-form" size="sm">
                 <label class="label-required">Petugas Awal Sampling
                  <span class="text-danger">*</span>
                </label>
                <v-select v-model="spm_ptgs_pasang_sampling" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="observer" :options="list_observer" class="select-size-sm">
                </v-select>
                <label v-if="!spm_ptgs_pasang_sampling || !spm_ptgs_angkat_sampling 
                || !spm_ptgs_analis || !tgl_pasang || !jam_pasang || !tgl_angkat || !jam_angkat"
                class="label">Wajib Diisi <span class="text-danger">*</span></label>
              </b-form-group>
            </b-col>

            <b-col sm="2 ">
              <b-form-group  label-for="select-form" size="sm">
                <label class="label-required">Petugas Akhir Sampling
                  <span class="text-danger">*</span>
                </label>
                <v-select v-model="spm_ptgs_angkat_sampling" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="observer" :options="list_observer" class="select-size-sm">
                </v-select>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group  label-for="select-form" size="sm">
                <label class="label-required">Petugas Analis Lab
                  <span class="text-danger">*</span>
                </label>
                <v-select v-model="spm_ptgs_analis" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text"
                  :options="list_penyelia" class="select-size-sm">
                </v-select>
              </b-form-group>
            </b-col>

            <b-col sm="3">
              <b-row>
                <b-col cols="8">
                  <b-form-group label-for="datepicker-4" sm="2">
                    <label class="label-required">Tanggal Pemasangan
                      <span class="text-danger">*</span>
                    </label>
                    <b-form-datepicker id="datepicker-4" 
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }" 
                      locale="en" size="sm" placeholder="tanggal" v-model="tgl_pasang" :disabled="dateDisable"
                      class="select-size-sm" />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group label-for="datepicker-4" sm="2">
                    <label class="label-required">Jam
                      <span class="text-danger">*</span>
                    </label>
                    <b-form-select v-model="jam_pasang" class="col-12 select-size-sm" size="sm" style="height: 33px;">
                      <option disabled value="">Pilih Jam</option>
                      <option value="08">08:00</option>
                      <option value="09">09:00</option>
                      <option value="10">10:00</option>
                      <option value="11">11:00</option>
                      <option value="12">12:00</option>
                      <option value="13">13:00</option>
                      <option value="14">14:00</option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col sm="3">
              <b-row>
                <b-col cols="8">
                  <b-form-group label-for="datepicker-5" sm="2">
                    <label class="label-required">Tanggal Pengangkatan
                      <span class="text-danger">*</span>
                    </label>
                    <b-form-datepicker 
                      id="datepicker-5" 
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }" 
                      locale="en" size="sm" 
                      placeholder="tanggal" 
                      v-model="tgl_angkat" 
                      :disabled="dateDisable"
                      :min="min" 
                      class="select-size-sm" />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group label-for="datepicker-5" sm="2">
                    <label class="label-required">Jam
                      <span class="text-danger">*</span>
                    </label>
                    <b-form-select v-model="jam_angkat" class="col-12 select-size-sm" size="sm" style="height: 33px;">
                      <option disabled value="">Pilih Jam</option>
                      <option value="08">08:00</option>
                      <option value="09">09:00</option>
                      <option value="10">10:00</option>
                      <option value="11">11:00</option>
                      <option value="12">12:00</option>
                      <option value="13">13:00</option>
                      <option value="14">14:00</option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>

        <b-card class="text-dark font-weight-bolder mt-n1">
          <b-row>
            <b-col sm="3 mb-n2">
              <b-form-group label="ID Sample" label-for="id-sample" label-cols-md="4">
                <b-form-input id="id-sample" v-model="spm_id_sampel" disabled class="font-weight-bolder select-grey" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-row>
          <b-col cols="4">
            <b-card class="text-dark font-weight-bolder mt-n1 mr-n1 h-100">
              <b-row>
                <b-col sm="7">
                  <b-form-group label="Konsentrasi SPM" label-for="select-form-spm" size="sm">
                    <!-- <b-form inline id="select-form-spm"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="spm_konsentrasi" 
                        v-on:keypress="isNumber($event)"
                        v-model.number="$v.spm_konsentrasi.$model"
                        v-on:keyup="checkValidate($event, 'spm_konsentrasi')"
                        v-bind:class="{ rangecheck: RC.spm_konsentrasi.status }"
                        :state="validateState($v.spm_konsentrasi)" 
                        class="col-8 text-center mbtm" size="sm"
                        style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.spm_konsentrasi.status" target="spm_konsentrasi" :triggers="'hover'" placement="topleft">
                        {{ RC.spm_konsentrasi.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.spm_konsentrasi.status" :triggers="'hover'" target="spm_konsentrasi" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("spm_konsentrasi") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">&micro;g/m3</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">&micro;g/m3</label>
                </b-col> -->
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="Bobot Filter Kosong" label-for="select-form-bfk" size="sm">
                    <!-- <b-form inline id="select-form-bfk"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="spm_bobot_filter_kosong" 
                        :formatter="format_four_decimal"
                        v-model.number="$v.spm_bobot_filter_kosong.$model"
                        :state="validateState($v.spm_bobot_filter_kosong)"
                        v-on:keyup="checkValidate($event, 'spm_bobot_filter_kosong')"
                        v-bind:class="{ rangecheck: RC.spm_bobot_filter_kosong.status }"
                        class="col-8 text-center" size="sm"
                        style="height: 33px;">
                      </b-form-input>
                      <span class="label label-modif mt-1 ml-1">gram</span>
                      <b-tooltip v-if="CC.spm_bobot_filter_kosong.status" target="spm_bobot_filter_kosong" :triggers="'hover'" placement="topleft">
                        {{ CC.spm_bobot_filter_kosong.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!CC.spm_bobot_filter_kosong.status && RC.spm_bobot_filter_kosong.status" target="spm_bobot_filter_kosong" :triggers="'hover'" placement="topleft">
                        {{ RC.spm_bobot_filter_kosong.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!CC.spm_bobot_filter_kosong.status && !RC.spm_bobot_filter_kosong.status" :triggers="'hover'" target="spm_bobot_filter_kosong" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("spm_bobot_filter_kosong") }}
                      </b-tooltip>
                      </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">gram</label>
                </b-col> -->
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="Bobot Filter Sampling" label-for="select-form-bfs" size="sm">
                    <!-- <b-form inline id="select-form-bfs"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="spm_bobot_filter_sampling" 
                        :formatter="format_four_decimal"
                        v-model.number="$v.spm_bobot_filter_sampling.$model"
                        :state="validateState($v.spm_bobot_filter_sampling)" 
                        v-on:keyup="checkValidate($event, 'spm_bobot_filter_sampling')"
                        v-bind:class="{ rangecheck: RC.spm_bobot_filter_sampling.status }"
                        class="col-8 text-center" size="sm"
                        style="height: 33px;">
                      </b-form-input>
                      <span class="label label-modif mt-1 ml-1">gram</span>
                      <b-tooltip v-if="CC.spm_bobot_filter_sampling.status" target="spm_bobot_filter_sampling" :triggers="'hover'" placement="topleft">
                        {{ CC.spm_bobot_filter_sampling.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!CC.spm_bobot_filter_sampling.status && RC.spm_bobot_filter_sampling.status" target="spm_bobot_filter_sampling" :triggers="'hover'" placement="topleft">
                        {{ RC.spm_bobot_filter_sampling.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!CC.spm_bobot_filter_sampling.status && !RC.spm_bobot_filter_sampling.status" :triggers="'hover'" target="spm_bobot_filter_sampling" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("spm_bobot_filter_sampling") }}
                      </b-tooltip>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">gram</label>
                </b-col> -->
              </b-row>

              <b-row>
                <b-col sm="5">
                  <b-form-group label="Flowrate Awal" label-for="select-form-fra" size="sm">
                    <!-- <b-form inline id="select-form-fra"> -->
                    <div class="">
                      <b-form-input 
                        id="spm_flow_rate_awal" 
                        :formatter="format_two_decimal"
                        v-model.number="$v.spm_flow_rate_awal.$model"
                        :state="validateState($v.spm_flow_rate_awal)" 
                        v-on:keyup="checkValidate($event, 'spm_flow_rate_awal')"
                        v-bind:class="{ rangecheck: RC.spm_flow_rate_awal.status }"
                        class="col-12 mr-1 text-center mbtm" size="sm"
                        style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="CC.spm_flow_rate_awal.status" target="spm_flow_rate_awal" :triggers="'hover'" placement="topleft">
                        {{ CC.spm_flow_rate_awal.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!CC.spm_flow_rate_awal.status && RC.spm_flow_rate_awal.status" target="spm_flow_rate_awal" :triggers="'hover'" placement="topleft">
                        {{ RC.spm_flow_rate_awal.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!CC.spm_flow_rate_awal.status && !RC.spm_flow_rate_awal.status" :triggers="'hover'" target="spm_flow_rate_awal" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("spm_flow_rate_awal") }}
                      </b-tooltip>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <b-col sm="4">
                  <b-form-group label="satuan" label-for="select-form-fras" size="sm">
                    <!-- <b-form inline id="select-form-fras"> -->
                    <div>
                      <b-form-select class="col-12 mr-1" size="sm" 
                        v-model="$v.spm_flow_rate_awal_satuan.$model"
                        :state="validateState($v.spm_flow_rate_awal_satuan)"
                        style="height: 33px;">
                        <option disabled value="">Pilih satuan</option>
                        <option value="m3/min">m3/min</option>
                        <option value="ft3/min">ft3/min</option>
                        <option value="iow">iow</option>
                      </b-form-select>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="5">
                  <b-form-group label="Flowrate Akhir" label-for="select-form-tak" size="sm">
                    <!-- <b-form inline id="select-form-tak"> -->
                    <div class="">
                      <b-form-input 
                        id="spm_flow_rate_akhir" 
                        :formatter="format_two_decimal"
                        v-model.number="$v.spm_flow_rate_akhir.$model"
                        :state="validateState($v.spm_flow_rate_akhir)" 
                        v-on:keyup="checkValidate($event, 'spm_flow_rate_akhir')"
                        v-bind:class="{ rangecheck: RC.spm_flow_rate_akhir.status }"
                        class="col-12 mr-1 text-center mbtm" size="sm"
                        style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="CC.spm_flow_rate_akhir.status" target="spm_flow_rate_akhir" :triggers="'hover'" placement="topleft">
                        {{ CC.spm_flow_rate_akhir.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!CC.spm_flow_rate_akhir.status && RC.spm_flow_rate_akhir.status" target="spm_flow_rate_akhir" :triggers="'hover'" placement="topleft">
                        {{ RC.spm_flow_rate_akhir.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!CC.spm_flow_rate_akhir.status && !RC.spm_flow_rate_akhir.status" :triggers="'hover'" target="spm_flow_rate_akhir" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("spm_flow_rate_akhir") }}
                      </b-tooltip>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <b-col sm="4">
                  <b-form-group label="satuan" label-for="select-form-fraks" size="sm">
                    <!-- <b-form inline id="select-form-fraks"> -->
                    <div>
                      <b-form-select class="col-12 mr-1 " size="sm"
                        v-model="$v.spm_flow_rate_akhir_satuan.$model"
                        :state="validateState($v.spm_flow_rate_akhir_satuan)"
                        style="height: 33px;">
                        <option disabled value="">Pilih satuan</option>
                        <option value="m3/min">m3/min</option>
                        <option value="ft3/min">ft3/min</option>
                        <option value="iow">iow</option>
                      </b-form-select>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
              </b-row>

            </b-card>
          </b-col>

          <b-col cols="4">
            <b-card class="text-dark font-weight-bolder mt-n1 mr-n1 h-100">
              <b-row>
                <b-col sm="7">
                  <b-form-group label="Total waktu sampling" label-for="select-form-wsampling" size="sm">
                    <!-- <b-form inline id="select-form-wsampling"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="spm_total_waktu_sampling" 
                        v-on:keypress="isNumber($event)"
                        v-model.number="$v.spm_total_waktu_sampling.$model"
                        :state="validateState($v.spm_total_waktu_sampling)"
                        v-on:keyup="checkValidate($event, 'spm_total_waktu_sampling')"
                        v-bind:class="{ rangecheck: RC.spm_total_waktu_sampling.status }"
                        class="col-8 text-center" size="sm"
                        style="height: 33px;">
                      </b-form-input>
                      <span class="label label-modif mt-1 ml-1">menit</span>
                      <b-tooltip v-if="RC.spm_total_waktu_sampling.status" target="spm_total_waktu_sampling" :triggers="'hover'" placement="topleft">
                        {{ RC.spm_total_waktu_sampling.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.spm_total_waktu_sampling.status" :triggers="'hover'" target="spm_total_waktu_sampling" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("spm_total_waktu_sampling") }}
                      </b-tooltip>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">menit</label>
                </b-col> -->
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="Tekanan Udara Awal" label-for="select-form-tua" size="sm">
                    <!-- <b-form inline id="select-form-tua"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="spm_tekanan_udara_awal" 
                        :formatter="format_two_decimal"
                        v-model.number="$v.spm_tekanan_udara_awal.$model"
                        :state="validateState($v.spm_tekanan_udara_awal)"
                        v-on:keyup="checkValidate($event, 'spm_tekanan_udara_awal')"
                        v-bind:class="{ rangecheck: RC.spm_tekanan_udara_awal.status }"
                        class="col-8 text-center" size="sm"
                        style="height: 33px;">
                      </b-form-input>
                      <span class="label label-modif mt-1 ml-1">mb</span>
                      <b-tooltip v-if="RC.spm_tekanan_udara_awal.status" target="spm_tekanan_udara_awal" :triggers="'hover'" placement="topleft">
                        {{ RC.spm_tekanan_udara_awal.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.spm_tekanan_udara_awal.status" :triggers="'hover'" target="spm_tekanan_udara_awal" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("spm_tekanan_udara_awal") }}
                      </b-tooltip>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">mb</label>
                </b-col> -->
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="Tekanan Udara Akhir" label-for="select-form-tur" size="sm">
                    <!-- <b-form inline id="select-form-tur"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="spm_tekanan_udara_akhir" 
                        :formatter="format_two_decimal"
                        v-model.number="$v.spm_tekanan_udara_akhir.$model"
                        :state="validateState($v.spm_tekanan_udara_akhir)"
                        v-on:keyup="checkValidate($event, 'spm_tekanan_udara_akhir')"
                        v-bind:class="{ rangecheck: RC.spm_tekanan_udara_akhir.status }"
                        class="col-8 text-center" size="sm"
                        style="height: 33px;">
                      </b-form-input>
                      <span class="label label-modif mt-1 ml-1">mb</span>
                      <b-tooltip v-if="RC.spm_tekanan_udara_akhir.status" target="spm_tekanan_udara_akhir" :triggers="'hover'" placement="topleft">
                        {{ RC.spm_tekanan_udara_akhir.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.spm_tekanan_udara_akhir.status" :triggers="'hover'" target="spm_tekanan_udara_akhir" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("spm_tekanan_udara_akhir") }}
                      </b-tooltip>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">mb</label>
                </b-col> -->
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="Temperatur Awal" label-for="select-form-taw" size="sm">
                    <!-- <b-form inline id="select-form-taw"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="spm_temperatur_awal" 
                        :formatter="format_two_decimal"
                        v-model.number="$v.spm_temperatur_awal.$model"
                        :state="validateState($v.spm_temperatur_awal)"
                        v-on:keyup="checkValidate($event, 'spm_temperatur_awal')"
                        v-bind:class="{ rangecheck: RC.spm_temperatur_awal.status }"
                        class="col-8 text-center" size="sm"
                        style="height: 33px;">
                      </b-form-input>
                      <span class="label label-modif mt-1 ml-1">&#8451;</span>
                      <b-tooltip v-if="RC.spm_temperatur_awal.status" target="spm_temperatur_awal" :triggers="'hover'" placement="topleft">
                        {{ RC.spm_temperatur_awal.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.spm_temperatur_awal.status" :triggers="'hover'" target="spm_temperatur_awal" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("spm_temperatur_awal") }}
                      </b-tooltip>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">&#8451;</label>
                </b-col> -->
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="Temperatur Akhir" label-for="select-form-takr" size="sm">
                    <!-- <b-form inline id="select-form-takr"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="spm_temperatur_akhir" 
                        :formatter="format_two_decimal"
                        v-model.number="$v.spm_temperatur_akhir.$model"
                        :state="validateState($v.spm_temperatur_akhir)"
                        v-on:keyup="checkValidate($event, 'spm_temperatur_akhir')"
                        v-bind:class="{ rangecheck: RC.spm_temperatur_akhir.status }" 
                        class="col-8 text-center" size="sm"
                        style="height: 33px;">
                      </b-form-input>
                      <span class="label label-modif mt-1 ml-1">&#8451;</span>
                      <b-tooltip v-if="RC.spm_temperatur_akhir.status" target="spm_temperatur_akhir" :triggers="'hover'" placement="topleft">
                        {{ RC.spm_temperatur_akhir.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.spm_temperatur_akhir.status" :triggers="'hover'" target="spm_temperatur_akhir" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("spm_temperatur_akhir") }}
                      </b-tooltip>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">&#8451;</label>
                </b-col> -->
              </b-row>

            </b-card>
          </b-col>

          <b-col cols="4">
            <b-card class="text-dark font-weight-bolder mt-n1 h-100">
              <b-row>
                <b-col sm="12">
                  <b-form-group label="Kondisi Cuaca Awal" label-for="select-form-kca" size="sm">                   
                      <v-select v-model="spm_kondisi_cuaca_awal" :reduce="(option) => option.value" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="text" :options="kondisi_cuaca_awal_opt" class="select-size-sm">
                      </v-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="12">
                  <b-form-group label="Kondisi Cuaca Akhir" label-for="select-form-kcr" size="sm">
                      <v-select v-model="spm_kondisi_cuaca_akhir" :reduce="(option) => option.value" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="text" :options="kondisi_cuaca_akhir_opt" class="select-size-sm">
                      </v-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="12">
                  <b-form-group label="Kondisi Sampling" label-for="select-form-ks" size="sm">
                      <v-select v-model="spm_kondisi_sampling" :reduce="(option) => option.value" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="text" :options="kondisi_sampling_opt" class="select-size-sm">
                      </v-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <b-row>
                    <b-col>
                      <label for="textarea-catatan-lainnya" class="font-weight-bolder"> Catatan Lainnya </label>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12">
                      <b-form-textarea id="textarea-catatan-lainnya" placeholder="Catatan Lainnya"
                        v-model="spm_catatan_lainnya"></b-form-textarea>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

            </b-card>
          </b-col>
        </b-row>

      </b-col>
    </b-row>

    <b-row class="mt-n1">
      <b-col cols="12">
        <b-card>
          <b-row class="float-right">
            <div>
              <b-button variant="danger" @click="resetForm('batal')" class="mr-2" size="sm">
                <feather-icon
                  icon="XIcon"
                  class="mr-50"
                />
                <span class="align-middle">Batal</span>
              </b-button>
              <b-button variant="warning" @click="V_Form" v-if="this.t_data_status == 'update'" class="mr-2" size="sm"
                :disabled="!roles.isRoleEdit">
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span class="align-middle">Update</span>
              </b-button>
              <b-button variant="primary" @click="V_Form" v-if="this.t_data_status == 'insert'" class="mr-2" size="sm"
                :disabled="!roles.isRoleAdd">
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                <span class="align-middle">Submit</span>
              </b-button>
            </div>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay>
  </div>
</template>
  
<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { BRow, BCol, BTable, BCardText, BFormInput, BContainer, BFormSelect, BForm, BButton, BCard, VBTooltip, BTooltip, BFormGroup, BFormDatepicker, BFormTextarea, BOverlay } from "bootstrap-vue";
import { methods } from "vue-echarts";
import axios from "axios";
import Helper from "@/api/helper";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import error_handler from "@/api/error_handler";
import { validationMixin } from "vuelidate";
import { required, minLength, between } from "vuelidate/lib/validators";
import { GC_KONSENTRASI_SPM, GC_KU, GC_SPM_TEMPERATUR_AWAL, GC_SPM_TEMPERATUR_AKHIR, GC_SPM_TEKANAN_UDARA_AWAL, GC_SPM_TEKANAN_UDARA_AKHIR, 
          GC_SPM_TOTAL_WAKTU_SAMPLING, GC_SPM_BOBOT_FILTER_SAMPLING, GC_SPM_BOBOT_FILTER_KOSONG, GC_FLOW_RATE_AWAL_M3, GC_FLOW_RATE_AWAL_FT3,
          GC_FLOW_RATE_AKHIR_M3, GC_FLOW_RATE_AKHIR_FT3 } from "@/validators/kuvalidator";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import moment from 'moment';
import labkuservice from "@/api/labkuservice";
import Params from "@/attr/metadata/params";
import RC_helper from "@/validators/RC_helper";
export default {
  name: "SpmForm",
  components: {
    BCardActions,
    BRow,
    BCol,
    BTable,
    BCardText,
    BFormInput,
    BContainer,
    BButton,
    BCard,
    VBTooltip,
    BTooltip,
    ToastificationContent,
    BFormSelect,
    BForm,
    BFormGroup,
    BFormDatepicker,
    vSelect,
    BFormTextarea,
    BOverlay
  },
  mixins: [validationMixin],
  validations: {
    spm_konsentrasi: {  GC_KONSENTRASI_SPM },
    spm_bobot_filter_kosong: {
      GC_SPM_BOBOT_FILTER_KOSONG,
      consistency(val) {
        if (val && val != 9999 && this.spm_bobot_filter_sampling && this.spm_bobot_filter_sampling != 9999) {
          let result = val <= this.spm_bobot_filter_sampling
          this.CC.spm_bobot_filter_kosong.message = "Bobot Filter Sampling >= Bobot Filter Kosong";
          this.CC.spm_bobot_filter_kosong.status = !result;
          return result
        }
        this.CC.spm_bobot_filter_kosong.message = "";
        this.CC.spm_bobot_filter_kosong.status = false;
        return true
      }
    },
    spm_bobot_filter_sampling: {
      GC_SPM_BOBOT_FILTER_SAMPLING,
      consistency(val) {
        if (val && val != 9999 && this.spm_bobot_filter_kosong && this.spm_bobot_filter_kosong != 9999) {
          let result = val >= this.spm_bobot_filter_kosong
          this.CC.spm_bobot_filter_sampling.message = "Bobot Filter Sampling >= Bobot Filter Kosong";
          this.CC.spm_bobot_filter_sampling.status = !result;
          return result
        }
        this.CC.spm_bobot_filter_sampling.message = "";
        this.CC.spm_bobot_filter_sampling.status = false;
        return true
      }
    },
    spm_total_waktu_sampling: {GC_SPM_TOTAL_WAKTU_SAMPLING},
    spm_tekanan_udara_awal: {GC_SPM_TEKANAN_UDARA_AWAL},
    spm_tekanan_udara_akhir: {GC_SPM_TEKANAN_UDARA_AKHIR},
    spm_temperatur_awal: {GC_SPM_TEMPERATUR_AWAL},
    spm_temperatur_akhir: {GC_SPM_TEMPERATUR_AKHIR},

    spm_flow_rate_awal: {
      consistency(val) {
        if (this.spm_flow_rate_awal_satuan == this.spm_flow_rate_akhir_satuan) {
          if (val && val != 9999 && this.spm_flow_rate_akhir && this.spm_flow_rate_akhir != 9999){
            let result = val <= this.spm_flow_rate_akhir
            this.CC.spm_flow_rate_awal.message = "Flow Rate Akhir >= Flow Rate Awal";
            this.CC.spm_flow_rate_awal.status = !result;
            return result
          }
        }
        this.CC.spm_flow_rate_awal.message = "";
        this.CC.spm_flow_rate_awal.status = false;
        return true
      },
      grosscheck(val) {
        if (this.spm_flow_rate_awal_satuan == 'm3/min') {
          return ((val >= GC_KU('spm_flow_rate_awal_m3').min && val <= GC_KU('spm_flow_rate_awal_m3').max) || val == GC_KU('spm_flow_rate_awal_m3').specialValue[0] || ((val === "" || val === null) && val !== 0))
        }
        else if (this.spm_flow_rate_awal_satuan == 'ft3/min') {
          return ((val >= GC_KU('spm_flow_rate_awal_ft3').min && val <= GC_KU('spm_flow_rate_awal_ft3').max) || val == GC_KU('spm_flow_rate_awal_ft3').specialValue[0] || ((val === "" || val === null) && val !== 0))
        }
        return true
      }
    },

    spm_flow_rate_akhir: {   
      consistency(val) {
        if (this.spm_flow_rate_awal_satuan == this.spm_flow_rate_akhir_satuan) {
          if (val && val != 9999 && this.spm_flow_rate_awal && this.spm_flow_rate_awal != 9999){
            let result = val >= this.spm_flow_rate_awal
            this.CC.spm_flow_rate_akhir.message = "Flow Rate Akhir >= Flow Rate Awal";
            this.CC.spm_flow_rate_akhir.status = !result;
            return result
          }
        }
        this.CC.spm_flow_rate_akhir.message = "";
        this.CC.spm_flow_rate_akhir.status = false;
        return true
      },   
      grosscheck(val) {
        if (this.spm_flow_rate_akhir_satuan == 'm3/min') {
          return ((val >= GC_KU('spm_flow_rate_akhir_m3').min && val <= GC_KU('spm_flow_rate_akhir_m3').max) || val == GC_KU('spm_flow_rate_akhir_m3').specialValue[0] || ((val === "" || val === null) && val !== 0))
        }
        else if (this.spm_flow_rate_akhir_satuan == 'ft3/min') {
          return ((val >= GC_KU('spm_flow_rate_akhir_ft3').min && val <= GC_KU('spm_flow_rate_akhir_ft3').max) || val == GC_KU('spm_flow_rate_akhir_ft3').specialValue[0] || ((val === "" || val === null) && val !== 0))
        }
        return true
      }
    },
    spm_flow_rate_awal_satuan: {
      consistency(val) {
        if(this.spm_flow_rate_awal && this.spm_flow_rate_awal != "" && this.spm_flow_rate_awal != null) {
          // this.checkValidate({target: {value: this.spm_flow_rate_awal}}, "spm_flow_rate_awal")
          return val ? true : false
        }
        return true
      }
    },
    spm_flow_rate_akhir_satuan: {
      consistency(val) {
        if(this.spm_flow_rate_akhir && this.spm_flow_rate_akhir != "" && this.spm_flow_rate_akhir != null) {
          // this.checkValidate({target: {value: this.spm_flow_rate_akhir}}, "spm_flow_rate_akhir")
          return val ? true : false
        }
        return true
      }
    }
  },
  props: ["t_station", "d_station", "t_id_form", "t_data", "t_data_status", "t_path", "t_path_update", "list_observer", "list_penyelia", "t_year", "t_periode", "roles","changeForm"],
  data() {
    return {
      dateDisable: false,
      showLoading: false,
      RC_helper_spm: { ...RC_helper },
      spm_ptgs_pasang_sampling: "",
      spm_ptgs_angkat_sampling: "",
      spm_ptgs_analis: "",
      tgl_pasang: null,
      jam_pasang: "",
      tgl_angkat: "",
      jam_angkat: "",
      min: "",
      spm_id_sampel: null,
      spm_bobot_filter_kosong: null,
      spm_bobot_filter_sampling: null,
      spm_konsentrasi: null,
      spm_flow_rate_awal: null,
      spm_flow_rate_akhir: null,
      spm_flow_rate_awal_satuan: "",
      spm_flow_rate_akhir_satuan: "",
      spm_total_waktu_sampling: null,
      spm_tekanan_udara_awal: null,
      spm_tekanan_udara_akhir: null,
      spm_temperatur_awal: null,
      spm_temperatur_akhir: null,
      spm_sekuens: null,
      spm_kondisi_cuaca_awal: "",
      spm_kondisi_cuaca_akhir: "",
      spm_kondisi_sampling: "",
      spm_kejadian_lainnya: "",
      sumber: "input",
      spm_catatan_lainnya: "",
      kondisi_cuaca_awal_opt: [],
      kondisi_cuaca_akhir_opt: [],
      kondisi_sampling_opt: [],
      qc_flag: 0,
      qc_flag_before: 0,
      last_qc_histories: {},
      DATA_RC: {},
      RC: {
        spm_konsentrasi: { status: false, message: "" },
        spm_flow_rate_awal: { status: false, message: "" },
        spm_flow_rate_akhir: { status: false, message: "" },
        spm_total_waktu_sampling: { status: false, message: "" },
        spm_bobot_filter_kosong: { status: false, message: "" },
        spm_bobot_filter_sampling: { status: false, message: "" },
        spm_tekanan_udara_awal: { status: false, message: "" },
        spm_tekanan_udara_akhir: { status: false, message: "" },
        spm_temperatur_awal: { status: false, message: "" },
        spm_temperatur_akhir: { status: false, message: "" }
      },
      CC: {
        spm_bobot_filter_kosong: { status: false, message: "" },
        spm_bobot_filter_sampling: { status: false, message: "" },
        spm_flow_rate_awal: { status: false, message: "" },
        spm_flow_rate_akhir: { status: false, message: "" },
      },
    };
  },
  computed: {
    total: function () {

    },
  },
  mounted() {
    this.getKondisiCuacaAwal()
    this.getKondisiCuacaAkhir()
    this.getKondisiSampling()
    if (this.t_data_status == "update") {
      this.popupData();
    } else {
      this.resetForm();
      this.getSampelID()
    }
    this.initialize()
  },
  watch: {
    t_year(value) {
      this.getSampelID();
    },
    t_periode(value){
      this.getSampelID();
    },
    list_penyelia(value) {
      console.log('list_penyelia', value)
    },
    t_station(value) {
      // console.log("ubah station : ", this.t_path, value);
    },
    t_data_status(newValue) {
      // console.log('watch t_data_status ', newValue)
      // console.log('list_observer', this.list_observer)
      this.getKondisiCuacaAwal()
      this.getKondisiCuacaAkhir()
      this.getKondisiSampling()
      if (newValue == "update") {
        this.popupData();
      } else {
        this.resetForm();
        this.getSampelID()
      }
      this.checkValidateAll()
    },
    list_observer(value) {
      // console.log('list_observer', value)
    },
    tgl_pasang(value) {
      // console.log("tgl_pasang", value)
      if(value) {
        this.min = value
        if (this.tgl_angkat) {
          let dpasang = new Date(value)
          let dangkat = new Date(this.tgl_angkat)
          if (dangkat < dpasang) {
            this.tgl_angkat = ""
            this.min = value
          }
        }
        
      }
    },

    tgl_angkat(value) {
      // console.log("tgl_angkat", value)
      if(value) {
        this.min = this.tgl_pasang ? this.tgl_pasang : null
        if (this.tgl_pasang) {
          let dpasang = new Date(this.tgl_pasang)
          let dangkat= new Date(value)
          if (dangkat < dpasang) {
            this.tgl_angkat = ""
            this.min = this.tgl_pasang
            this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Tanggal Pengangkatan harus lebih besar dari Tanggal Pemasangan. Periksa kembali inputan anda",
                  icon: "BellIcon",
                  variant: "danger",
                },
              });
          }
        }     
      }
    },

    spm_flow_rate_awal_satuan(value) {
      if (this.spm_flow_rate_akhir_satuan != value) {
        if (value == 'm3/min' || value == 'ft3/min' || value == 'iow') {
          this.spm_flow_rate_akhir_satuan = value
        }
      }
    },

    spm_flow_rate_akhir_satuan(value) {
      if (this.spm_flow_rate_awal_satuan != value) {
        if (value == 'm3/min' || value == 'ft3/min' || value == 'iow') {
          this.spm_flow_rate_awal_satuan = value
        }
      }
    }

  },
  methods: {

    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } if (evt.key == "." || evt.key == ",") {
        return evt.preventDefault();
      } else {
        return true;
      }
    },

    format_integer(e, event) {
      if (Math.round(e * 1) / 1 != e) {
        let text = String(e);
        let filter = text.substring(0, text.length - 1);
        return filter;
      } else {
        if (Math.floor(e) === e) {
          return e;
        } else {
          if (e.toString().indexOf(".") > 0) {
            let count = e.toString().split(".")[1].length || 0;
            return count > 2 ? parseFloat(e).toFixed(0) : e;
          } else {
            return e;
          }
        }

        return e;
      }
    },

    format_two_decimal(e, event) {
      if (Math.round(e * 100) / 100 != e) {
        let text = String(e);
        let filter = text.substring(0, text.length - 1);
        return filter;
      } else {
        if (Math.floor(e) === e) {
          return e;
        } else {
          if (e.toString().indexOf(".") > 0) {
            let count = e.toString().split(".")[1].length || 0;
            return count > 2 ? parseFloat(e).toFixed(2) : e;
          } else {
            return e;
          }
        }

        return e;
      }
    },

    format_four_decimal(e, event) {
      if (Math.round(e * 10000) / 10000 != e) {
        let text = String(e);
        let filter = text.substring(0, text.length - 1);
        return filter;
      } else {
        if (Math.floor(e) === e) {
          return e;
        } else {
          if (e.toString().indexOf(".") > 0) {
            let count = e.toString().split(".")[1].length || 0;
            return count > 2 ? parseFloat(e).toFixed(4) : e;
          } else {
            return e;
          }
        }

        return e;
      }
    },

    initialize() {
      // console.log('initialize')
      this.setQCData();

      if (this.t_data_status == "update") {
        this.checkValidateAll()
      }
    },

    async getSampelID() {
      try {
        let { data } = await labkuservice.getUsageStationID(this.t_station.path)
        var kodestasiun = data['BmkgSatu.metadata.station.behavusage.IBehavStationUsage']['usage_station_id']
      } catch (e) {
        console.log(e)
      }
      try {
        const { data } = await labkuservice.getSekuens(this.t_path, String(this.t_year.title));
        var sekuens = data.items_total
      } catch (e) {
        console.log(e)
      }
      var tahun = parseInt(this.t_year.title)
      var tglAwalTahun = moment('2022-01-03T09:00').utcOffset('+0700')
      /**
       * Tanggal 3 Januari 2022 kebetulan adalah tanggal patokan hitung P1 untuk Lab KU
       * 3 Januari 2022 adalah tanggal PASANG pertama, menandakan starting point 
       * untuk menghitung Periode 1 di tahun 2022
       * 
       * NOTE: basis periode adalah tanggal angkat, bukan tanggal pasang
       * 
       * Konsepnya adalah menggunakan basis 3 Januari 2022, dihitunglah P1 untuk tahun-tahun
       * yang lain, baik sebelum maupun sesudah 2022
       * 
       */
      if (tahun > 2022) {
        tglAwalTahun.add((parseInt(this.t_year.title) - 2022) * 6 * 61, 'days');
        let d = tglAwalTahun.diff(moment(tahun + '-01-01T09:00'), 'days');
        if (d > 6) {
          tglAwalTahun.subtract(Math.floor(d / 6) * 6, 'days');
        }
        // console.log("2: d awaltahun "+ d + " " + tglAwalTahun.format())
      } else if (tahun < 2022) {
        tglAwalTahun.subtract((2022 - parseInt(this.t_year.title)) * 6 * 60, 'days');
        let d = tglAwalTahun.diff(moment(tahun +'-01-01T09:00').utcOffset('+0700'), "days");
        if (d > 6) {
          tglAwalTahun.subtract(Math.floor(d / 6) * 6, 'days');
        }
      }
      var tanggal_pasang = moment(tglAwalTahun).add((this.t_periode.value - 1) * 6, 'days');
      var tanggal_angkat = moment(tanggal_pasang).add(1, 'days');
      this.tgl_pasang = tanggal_pasang.format()
      this.tgl_angkat = tanggal_angkat.format()
      this.jam_pasang = "09"//"09:00"
      this.jam_angkat = "09"//"09:00"
      var periode = String(this.t_periode.value).padStart(2, "0");
      var bulan = tanggal_angkat.format('MM');
      let id_sampel = kodestasiun + "" + String(this.t_year.title).substring(2, 4) + "" + bulan + "" + periode + "" + String(parseInt(sekuens) + 1).padStart(2, "0");      
      this.spm_id_sampel = id_sampel
      return id_sampel
    },

    async getKondisiCuacaAwal() {
      try {
        const { data } = await labkuservice.listKondisiCuacaAwal();
        let items = data.items
        let kondisi = []
        for (var i = 0; i < items.length; i++) {
          const item_kondisi = {
            value: items[i].sb_value,
            text: items[i].sb_label,
            path: items[i].path,
          };
          kondisi.push(item_kondisi)
        }
        this.kondisi_cuaca_awal_opt = kondisi
         //make kondisi cuaca awal order by name asc
        this.kondisi_cuaca_awal_opt.sort(function (a, b) {
          var nameA = a.text.toUpperCase(); // ignore upper and lowercase
          var nameB = b.text.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        });

      } catch (e) {
        console.log(e);
      }
    },

    async getKondisiCuacaAkhir() {
      try {
        const { data } = await labkuservice.listKondisiCuacaAkhir();
        let items = data.items
        let kondisi = []
        for (var i = 0; i < items.length; i++) {
          const item_kondisi = {
            value: items[i].sb_value,
            text: items[i].sb_label,
            path: items[i].path,
          };
          kondisi.push(item_kondisi)
        }
        this.kondisi_cuaca_akhir_opt = kondisi
        // make kondisi cuaca akhir order by text ascendant and ignore case
        this.kondisi_cuaca_akhir_opt.sort(function (a, b) {
          var nameA = a.text.toUpperCase(); // ignore upper and lowercase
          var nameB = b.text.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        });
      } catch (e) {
        console.log(e);
      }
    },

    async getKondisiSampling() {
      try {
        const { data } = await labkuservice.listKondisiSampling();
        let items = data.items
        let kondisi = []
        for (var i = 0; i < items.length; i++) {
          const item_kondisi = {
            value: items[i].sb_value,
            text: items[i].sb_label,
            path: items[i].path,
          };
          kondisi.push(item_kondisi)
        }
        this.kondisi_sampling_opt = kondisi
        // make kondisi sampling order by name ascendant and ignore case
        this.kondisi_sampling_opt.sort(function (a, b) {
          var nameA = a.text.toUpperCase(); // ignore upper and lowercase
          var nameB = b.text.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        });

      } catch (e) {
        console.log(e);
      }
    },

    onKeyCheck(event) {
      // console.log('onKeyCheck', event)
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault()
      }
    },
    format_one_decimal(e) {
      if (Math.round(e * 10) / 10 != e) {
        let text = String(e);
        let filter = text.substring(0, text.length - 1);
        return filter;
      } else {
        if (Math.floor(e) === e) {
          return e;
        } else {
          if (e.toString().indexOf(".") > 0) {
            let count = e.toString().split(".")[1].length || 0;
            return count > 1 ? parseFloat(e).toFixed(1) : e;
          } else {
            return e;
          }
        }
        return e;
      }
    },
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },

    tooltipMessage(attr) {
      if (attr == 'spm_flow_rate_awal') {
        if (this.spm_flow_rate_awal_satuan == 'm3/min') {
          return GC_KU('spm_flow_rate_awal_m3').message;
        }
        else if (this.spm_flow_rate_awal_satuan == 'ft3/min') {
          return GC_KU('spm_flow_rate_awal_ft3').message;
        }
        else {
          return 'No Gross Check'
        }
      }
      else if (attr == 'spm_flow_rate_akhir') {
        if (this.spm_flow_rate_akhir_satuan == 'm3/min') {
          return GC_KU('spm_flow_rate_akhir_m3').message;
        }
        else if (this.spm_flow_rate_akhir_satuan == 'ft3/min') {
          return GC_KU('spm_flow_rate_akhir_ft3').message;
        }
        else {
          return 'No Gross Check'
        }
      }
      return GC_KU(attr).message;
    },

    V_Form() {
      this.$v.$touch();
      if (!this.spm_ptgs_pasang_sampling || !this.spm_ptgs_angkat_sampling 
          || !this.spm_ptgs_analis || !this.tgl_pasang || !this.jam_pasang || !this.tgl_angkat || !this.jam_angkat
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Data tidak valid. Periksa kembali inputan anda",
            icon: "BellIcon",
            variant: "danger",
          },
        });
        return;
      }
      if (this.$v.$invalid) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Data tidak valid. Periksa kembali inputan anda",
            icon: "BellIcon",
            variant: "danger",
          },
        });
        return;
      } else {

        let dataRC = Object.entries(this.DATA_RC).filter((e) => e[1].status);
        // console.log('this.DATA_RC', this.DATA_RC, dataRC)
        let temp_QC_After = [];
        for (var i in dataRC) {
          let row_qc_after = {
            raw_type: "SUSPENDED_PARTICULATE_MATTER",
            atribut: dataRC[i][0],
            value: this[dataRC[i][0]],
            roles: dataRC[i][1].message,
            type: "range_check",
            message: dataRC[i][1].message,
          };
          temp_QC_After.push(row_qc_after);
        }

        // console.log("QC  AFTER : ",temp_QC_After);
        // this.last_qc_histories["before"] = "-";
        this.last_qc_histories["after"] = temp_QC_After;

        if (dataRC.length > 0) {
          this.$swal({
            title: "Apakah Anda Yakin Untuk Simpan Data ?",
            text: "Data Anda Terkoreksi Range Check ",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Submit",
            cancelButtonText: "Cek Data",
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              if (this.t_data_status == "insert") {
                this.sumbitSPM();
              } else if (this.t_data_status == "update") {
                this.updateData();
              }
            } else if (result.dismiss === "cancel") {
              return;
            }
          });
        } else {
          if (this.t_data_status == "insert") {
            this.sumbitSPM();
          } else if (this.t_data_status == "update") {
            this.updateData();
          }
        }

        
      }
    },
    popupData() {
      let p_pasang_sampling = this.list_observer.find(item => item.observer == this.t_data.spm_ptgs_pasang_sampling)
      let p_angkat_sampling = this.list_observer.find(item => item.observer == this.t_data.spm_ptgs_angkat_sampling)
      let p_analis_lab = this.list_penyelia.find(item => item.value == this.t_data.spm_ptgs_analis)
      this.spm_id_sampel = this.t_data.spm_id_sampel
      this.spm_ptgs_pasang_sampling = p_pasang_sampling
      this.spm_ptgs_angkat_sampling = p_angkat_sampling
      this.spm_ptgs_analis = p_analis_lab
      this.tgl_pasang = this.t_data.tgl_pasang
      this.jam_pasang = ''
      this.tgl_angkat = this.t_data.tgl_angkat
      this.jam_angkat = ''
      this.spm_bobot_filter_kosong = this.t_data.spm_bobot_filter_kosong
      this.spm_bobot_filter_sampling = this.t_data.spm_bobot_filter_sampling
      this.spm_konsentrasi = this.t_data.spm_konsentrasi
      this.spm_flow_rate_awal = this.t_data.spm_flow_rate_awal
      this.spm_flow_rate_akhir = this.t_data.spm_flow_rate_akhir
      this.spm_flow_rate_awal_satuan = this.t_data.satuan_flowrate_awal
      this.spm_flow_rate_akhir_satuan = this.t_data.satuan_flowrate_akhir
      this.spm_total_waktu_sampling = this.t_data.spm_total_waktu_sampling
      this.spm_tekanan_udara_awal = this.t_data.spm_tekanan_udara_awal
      this.spm_tekanan_udara_akhir = this.t_data.spm_tekanan_udara_akhir
      this.spm_temperatur_awal = this.t_data.spm_temperatur_awal
      this.spm_temperatur_akhir = this.t_data.spm_temperatur_akhir
      this.spm_kondisi_cuaca_awal = this.t_data.spm_kondisi_cuaca_awal
      this.spm_kondisi_cuaca_akhir = this.t_data.spm_kondisi_cuaca_akhir
      this.spm_kondisi_sampling = this.t_data.spm_kondisi_sampling
      this.spm_kejadian_lainnya = this.t_data.spm_kejadian_lainnya
      this.spm_catatan_lainnya = this.t_data.spm_catatan_lainnya
      this.qc_flag_before = this.t_data.qc_flag;
      this.QC_Histories = this.t_data.qc_histories

      if (this.tgl_pasang) {
        let tgl = this.tgl_pasang.split('T')
        let jam = tgl[1].split(':')[0]
        this.jam_pasang = jam
      }

      if (this.tgl_angkat) {
        let tgl = this.tgl_angkat.split('T')
        let jam = tgl[1].split(':')[0]
        this.jam_angkat = jam
      }
      
      if (!this.t_data.spm_id_sampel) {
        this.getSampelID()
      }

      this.$v.$touch();

      
    },
    updateData() {
      const modification_date = this.t_date;
      if (this.jam_pasang) {
        let tglp = this.tgl_pasang.split('T')[0]
        this.tgl_pasang = tglp + 'T' + this.jam_pasang + ':00:00'
      }
      if (this.jam_pasang) {
        let tgla = this.tgl_angkat.split('T')[0]
        this.tgl_angkat = tgla + 'T' + this.jam_angkat + ':00:00'
      }

      // CHECK QC
      var len_qc_after = (this.last_qc_histories) ? this.last_qc_histories["after"].length : 0;
      var temp_qc_histories = {};
      if (this.QC_Histories && this.QC_Histories != "" || this.QC_Histories != null) {
        temp_qc_histories = JSON.parse(this.QC_Histories);
      }
      // console.log("TEMP QC : ", temp_qc_histories);
      this.last_qc_histories["before"] = [];
      if (temp_qc_histories != null) {
        if (temp_qc_histories["after"] != undefined) {
          this.last_qc_histories["before"] = temp_qc_histories["after"];
        }
      }

      var flag_temp = this.qc_flag_before ? this.qc_flag_before : 0;
      if (this.qc_flag_before != 2) {
        flag_temp = this.qc_flag_before;

        if (len_qc_after > 0) {
          flag_temp = 1;
        } 
        else {
          flag_temp = 0;
        }
      }

      var qc_temp = JSON.stringify(this.last_qc_histories);
      
      const post_data = {
        spm_ptgs_pasang_sampling: this.spm_ptgs_pasang_sampling?.observer ? this.spm_ptgs_pasang_sampling.observer : null,
        spm_ptgs_angkat_sampling: this.spm_ptgs_angkat_sampling?.observer ? this.spm_ptgs_angkat_sampling.observer : null,
        spm_ptgs_analis: this.spm_ptgs_analis?.value ? this.spm_ptgs_analis.value : null,
        tgl_pasang: this.tgl_pasang,
        tgl_angkat: this.tgl_angkat,
        spm_konsentrasi: this.spm_konsentrasi ? this.spm_konsentrasi : null,
        spm_bobot_filter_kosong: this.spm_bobot_filter_kosong ? this.spm_bobot_filter_kosong : null,
        spm_bobot_filter_sampling: this.spm_bobot_filter_sampling ? this.spm_bobot_filter_sampling : null,
        spm_flow_rate_awal: this.spm_flow_rate_awal ? this.spm_flow_rate_awal : null,
        spm_flow_rate_akhir: this.spm_flow_rate_akhir ? this.spm_flow_rate_akhir : null,
        satuan_flowrate_awal: (this.spm_flow_rate_awal_satuan === "") ? null : this.spm_flow_rate_awal_satuan,
        satuan_flowrate_akhir: (this.spm_flow_rate_akhir_satuan === "") ? null : this.spm_flow_rate_akhir_satuan,
        spm_total_waktu_sampling: this.spm_total_waktu_sampling ? this.spm_total_waktu_sampling : null,
        spm_tekanan_udara_awal: this.spm_tekanan_udara_awal ? this.spm_tekanan_udara_awal : null,
        spm_tekanan_udara_akhir: this.spm_tekanan_udara_akhir ? this.spm_tekanan_udara_akhir : null,
        spm_temperatur_awal: this.spm_temperatur_awal ? this.spm_temperatur_awal : null,
        spm_temperatur_akhir: this.spm_temperatur_akhir ? this.spm_temperatur_akhir : null,
        spm_kondisi_cuaca_awal: this.spm_kondisi_cuaca_awal,
        spm_kondisi_cuaca_akhir: this.spm_kondisi_cuaca_akhir,
        spm_kondisi_sampling: this.spm_kondisi_sampling,
        spm_kejadian_lainnya: this.spm_kejadian_lainnya,
        spm_catatan_lainnya: this.spm_catatan_lainnya,
        spm_id_sampel: this.spm_id_sampel,
        station_id: this.t_station.value,
        station_wmo_id: this.t_station.wmo,
        station_name: this.t_station.text,
        current_longitude: this.t_station.longitude,
        current_latitude: this.t_station.latitude,
        periode_form: this.t_periode.value,
        tahun_form: this.tgl_angkat,
        qc_flag: flag_temp,
        qc_histories: qc_temp
      };

      const update_url = Helper.getBaseUrl() + this.t_path_update;
      this.showLoading = true
      axios
        .patch(update_url, post_data, Helper.getConfig())
        .then((response) => {
          this.$swal({
            title: "Update data sukses!",
            text: "Data Telah berhasil di Update",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.showLoading = false
          this.resetForm();
          this.changeForm(null);
        })
        .catch((error) => {
          this.showLoading = false
          console.log(error);
          error_handler(this, error, "Suspended Particulate Matter", "Input Data Error");
        });
    },
    sumbitSPM() {
      // console.log('sumbitSPM', this.tgl_pasang);
      if (this.jam_pasang) { 
        let tglp = this.tgl_pasang.split('T')[0]
        this.tgl_pasang = tglp + 'T' + this.jam_pasang + ':00:00'
      }
      if (this.jam_pasang) {
        let tgla = this.tgl_angkat.split('T')[0]
        this.tgl_angkat = tgla + 'T' + this.jam_angkat + ':00:00'
      }
      const spm_id = this.t_id_form//this.t_station_id + "_no2so2_" + this.t_date;
      // const data_timestamp = this.t_date;

      var len_qc_after = this.last_qc_histories["after"].length;
      this.last_qc_histories["before"] = [];
      // console.log("ada QC : ",len_qc_after);
      var flag_temp = 0;
      if (len_qc_after > 0) {
        flag_temp = 1;
      }
      var qc_temp = JSON.stringify(this.last_qc_histories);

      const post_data = {
        "@type": "SPM",
        id: spm_id,
        spm_ptgs_pasang_sampling: this.spm_ptgs_pasang_sampling?.observer ? this.spm_ptgs_pasang_sampling.observer : null,
        spm_ptgs_angkat_sampling: this.spm_ptgs_angkat_sampling?.observer ? this.spm_ptgs_angkat_sampling.observer : null,
        spm_ptgs_analis: this.spm_ptgs_analis?.value ? this.spm_ptgs_analis.value : null,
        tgl_pasang: this.tgl_pasang,
        tgl_angkat: this.tgl_angkat,
        spm_id_sampel: this.spm_id_sampel,
        spm_konsentrasi: this.spm_konsentrasi ? this.spm_konsentrasi : null,
        spm_bobot_filter_kosong: this.spm_bobot_filter_kosong ? this.spm_bobot_filter_kosong : null,
        spm_bobot_filter_sampling: this.spm_bobot_filter_sampling ? this.spm_bobot_filter_sampling : null,
        spm_flow_rate_awal: this.spm_flow_rate_awal ? this.spm_flow_rate_awal : null,
        spm_flow_rate_akhir: this.spm_flow_rate_akhir ? this.spm_flow_rate_akhir : null,
        satuan_flowrate_awal: (this.spm_flow_rate_awal_satuan === "") ? null : this.spm_flow_rate_awal_satuan,
        satuan_flowrate_akhir: (this.spm_flow_rate_akhir_satuan === "") ? null : this.spm_flow_rate_akhir_satuan,
        spm_total_waktu_sampling: this.spm_total_waktu_sampling ? this.spm_total_waktu_sampling : null,
        spm_tekanan_udara_awal: this.spm_tekanan_udara_awal ? this.spm_tekanan_udara_awal : null,
        spm_tekanan_udara_akhir: this.spm_tekanan_udara_akhir ? this.spm_tekanan_udara_akhir : null,
        spm_temperatur_awal: this.spm_temperatur_awal ? this.spm_temperatur_awal : null,
        spm_temperatur_akhir: this.spm_temperatur_akhir ? this.spm_temperatur_akhir : null,
        spm_kondisi_cuaca_awal: this.spm_kondisi_cuaca_awal,
        spm_kondisi_cuaca_akhir: this.spm_kondisi_cuaca_akhir,
        spm_kondisi_sampling: this.spm_kondisi_sampling,
        spm_kejadian_lainnya: this.spm_kejadian_lainnya,
        spm_catatan_lainnya: this.spm_catatan_lainnya,
        spm_id_sampel: this.spm_id_sampel,
        station_id: this.t_station.value,
        station_wmo_id: this.t_station.wmo,
        station_name: this.t_station.text,
        current_longitude: this.t_station.longitude,
        current_latitude: this.t_station.latitude,
        periode_form: this.t_periode.value,
        tahun_form: this.tgl_angkat,
        qc_flag: flag_temp,
        qc_histories: qc_temp
      };

      const post_url = Helper.getBaseUrl() + this.t_path;
      // console.log('post_url', post_url, post_data)
      this.showLoading = true
      axios
        .post(post_url, post_data, Helper.getConfig())
        .then((response) => {
          this.$swal({
            title: "Simpan data sukses!",
            text: "Data Telah berhasil di Simpan",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.showLoading = false
          this.resetForm();
          this.changeForm(null);
        })
        .catch((error) => {
          this.showLoading = false
          console.log(error);
          error_handler(this, error, "Suspended Particulate Matter", "Input Data Error");
        });
    },

    resetForm(tipe = null) {
      this.showLoading = false
      if(tipe == 'batal'){
        this.changeForm(null);
      }
      this.qc_flag = 0,
      this.qc_flag_before = 0,
      this.last_qc_histories = {},
      this.DATA_RC = {},
      this.RC = {
        spm_konsentrasi: { status: false, message: "" },
        spm_flow_rate_awal: { status: false, message: "" },
        spm_flow_rate_akhir: { status: false, message: "" },
        spm_total_waktu_sampling: { status: false, message: "" },
        spm_bobot_filter_kosong: { status: false, message: "" },
        spm_bobot_filter_sampling: { status: false, message: "" },
        spm_tekanan_udara_awal: { status: false, message: "" },
        spm_tekanan_udara_akhir: { status: false, message: "" },
        spm_temperatur_awal: { status: false, message: "" },
        spm_temperatur_akhir: { status: false, message: "" }
      },
      this.spm_ptgs_pasang_sampling = ""
      this.spm_ptgs_angkat_sampling = ""
      this.spm_ptgs_analis = ""
      this.tgl_pasang = null
      this.jam_pasang = ""
      this.tgl_angkat = null
      this.jam_angkat = ""
      this.spm_bobot_filter_kosong = null
      this.spm_bobot_filter_sampling = null
      this.spm_konsentrasi = null
      this.spm_flow_rate_awal = null
      this.spm_flow_rate_akhir = null
      this.spm_flow_rate_awal_satuan = ""
      this.spm_flow_rate_akhir_satuan = ""
      this.spm_total_waktu_sampling = null
      this.spm_tekanan_udara_awal = null
      this.spm_tekanan_udara_akhir = null
      this.spm_temperatur_awal = null
      this.spm_temperatur_akhir = null
      this.spm_kondisi_cuaca_awal = ""
      this.spm_kondisi_cuaca_akhir = ""
      this.spm_kondisi_sampling = ""
      this.spm_kejadian_lainnya = ""
      this.spm_catatan_lainnya = ""
      this.spm_id_sampel = null
      this.$v.$reset();
      // this.changeForm(null);
    },
    // async getSekuensID() {
    //   var sekuens = 0;
    //   var tahun = String(this.t_year.title);
    //   var url = Helper.getBaseUrl() + this.t_path + "/@search?tgl_pasang__gte=01-01-" + tahun + "&tgl_pasang__lte=31-12-" + tahun;
    //   await axios
    //     .get(url, Helper.getConfig())
    //     .then(response => this.sekuens = response.data.items_total)
    //     .catch(error => console.log(error))
    //   this.spm_sekuens = this.sekuens
    // }

    checkValidate: function (event, type) {
      // console.log("checkValidate", event, type);
      let value = event.target.value;

      switch (type) {
        case "spm_konsentrasi":
          var raw = this.RC_helper_spm.check(value, type);
          var gc = GC_KONSENTRASI_SPM(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          // console.log('this.RC[type]', this.RC[type])
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "spm_flow_rate_awal":
          var raw = null//this.RC_helper_spm.check(value, type);
          var gc = null//GC_KONSENTRASI_SPM(value);
          if (this.spm_flow_rate_awal_satuan == 'm3/min') {
            raw = this.RC_helper_spm.check(value, 'spm_flow_rate_awal_m3')
            gc = GC_FLOW_RATE_AWAL_M3(value)
          }
          else if (this.spm_flow_rate_awal_satuan == 'ft3/min') {
            raw = this.RC_helper_spm.check(value, 'spm_flow_rate_awal_ft3')
            gc = GC_FLOW_RATE_AWAL_FT3(value)
          } 
          
          this.RC[type].status = (raw && !raw.status) ? (!gc ? false : true) : false;
          this.RC[type].message = raw ? raw.message : "";
          // console.log('this.RC[type]', this.RC[type])
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "spm_flow_rate_akhir":
          var raw = null//this.RC_helper_spm.check(value, type);
          var gc = null//GC_KONSENTRASI_SPM(value);
          if (this.spm_flow_rate_akhir_satuan == 'm3/min') {
            raw = this.RC_helper_spm.check(value, 'spm_flow_rate_akhir_m3')
            gc = GC_FLOW_RATE_AKHIR_M3(value)
          }
          else if (this.spm_flow_rate_akhir_satuan == 'ft3/min') {
            raw = this.RC_helper_spm.check(value, 'spm_flow_rate_akhir_ft3')
            gc = GC_FLOW_RATE_AKHIR_FT3(value)
          } 
          this.RC[type].status = (raw && !raw.status) ? (!gc ? false : true) : false;
          this.RC[type].message = raw ? raw.message : "";
          // console.log('this.RC[type]', this.RC[type])
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
          case "spm_bobot_filter_kosong":
          var raw = this.RC_helper_spm.check(value, type);
          var gc = GC_SPM_BOBOT_FILTER_KOSONG(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          // console.log('this.RC[type]', this.RC[type])
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "spm_bobot_filter_sampling":
          var raw = this.RC_helper_spm.check(value, type);
          var gc = GC_SPM_BOBOT_FILTER_SAMPLING(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          // console.log('this.RC[type]', this.RC[type])
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "spm_total_waktu_sampling":
          var raw = this.RC_helper_spm.check(value, type);
          var gc = GC_SPM_TOTAL_WAKTU_SAMPLING(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          // console.log('this.RC[type]', this.RC[type])
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "spm_tekanan_udara_awal":
          var raw = this.RC_helper_spm.check(value, type);
          var gc = GC_SPM_TEKANAN_UDARA_AWAL(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          // console.log('this.RC[type]', this.RC[type])
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "spm_tekanan_udara_akhir":
          var raw = this.RC_helper_spm.check(value, type);
          var gc = GC_SPM_TEKANAN_UDARA_AKHIR(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          // console.log('this.RC[type]', this.RC[type])
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "spm_temperatur_awal":
          var raw = this.RC_helper_spm.check(value, type);
          var gc = GC_SPM_TEMPERATUR_AWAL(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          // console.log('this.RC[type]', this.RC[type])
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "spm_temperatur_akhir":
          var raw = this.RC_helper_spm.check(value, type);
          var gc = GC_SPM_TEMPERATUR_AKHIR(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          // console.log('this.RC[type]', this.RC[type])
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;

        default:
          break;
      }
    },

    checkValidateAll() {
      this.checkValidate({target: {value: this.spm_konsentrasi}}, "spm_konsentrasi")
      this.checkValidate({target: {value: this.spm_flow_rate_awal}}, "spm_flow_rate_awal")
      this.checkValidate({target: {value: this.spm_flow_rate_akhir}}, "spm_flow_rate_akhir")
      this.checkValidate({target: {value: this.spm_bobot_filter_kosong}}, "spm_bobot_filter_kosong")
      this.checkValidate({target: {value: this.spm_bobot_filter_sampling}}, "spm_bobot_filter_sampling")
      this.checkValidate({target: {value: this.spm_total_waktu_sampling}}, "spm_total_waktu_sampling")
      this.checkValidate({target: {value: this.spm_tekanan_udara_awal}}, "spm_tekanan_udara_awal")
      this.checkValidate({target: {value: this.spm_tekanan_udara_akhir}}, "spm_tekanan_udara_akhir")
      this.checkValidate({target: {value: this.spm_temperatur_awal}}, "spm_temperatur_awal")
      this.checkValidate({target: {value: this.spm_temperatur_akhir}}, "spm_temperatur_akhir")
    },

    setDataRC(type, status, message, roles) {
      // console.log('setDataRC', type, status, message, roles)
      if (status) {
        this.DATA_RC[type] = {
          status: status,
          message: message,
          roles: roles,
        };
      } else {
        if (this.DATA_RC.hasOwnProperty(type)) {
          delete this.DATA_RC[type];
        }
      }
    },

    async setQCData() {
      // console.log('setQCData')
      if (this.d_station) {
        let qc_data = this.d_station["BmkgSatu.metadata.qc.ISPM_qc"];
        let qc_params = Params.GET_PARAMS("suspended_particulate_matter");
        let qc_key = Params.GET_KEY("suspended_particulate_matter");
        this.RC_helper_spm.setData(qc_data, qc_params, qc_key);
      }

    },

    rangecheck_status(type) {
      if (this.RC[type]?.status) {
        return this.RC[type].status;
      } else {
        return false;
      }
    },

  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
};
</script>

<style>
#spm_flow_rate_awal.rangecheck,
#spm_flow_rate_akhir.rangecheck,
#spm_bobot_filter_kosong.rangecheck,
#spm_bobot_filter_sampling.rangecheck,
#spm_tekanan_udara_awal.rangecheck,
#spm_tekanan_udara_akhir.rangecheck,
#spm_temperatur_awal.rangecheck,
#spm_temperatur_akhir.rangecheck,
#spm_total_waktu_sampling.rangecheck,
#spm_konsentrasi.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
}

.content-inline {
  display: flex;
  flex-direction: row;
}
</style>

<style scoped>
.label-modif{
  margin-top:-5px;font-size:10pt;margin-left:3px
}
.select-grey {
  background-color: lightgray !important;
}

.label-entry1 {
  text-align: center;
  color: #3b4253;
  width: 100%;
  font-weight: 500;
  background-color: #f3f1f1;
  /* font-weight: bold !important; */
  border: 2px solid #6e6b7b;
  border-radius: 3px;
  padding: 4px;
  margin-bottom: 7px;
}

.mbtm {
  margin-bottom: 7px;
}

.bg-light-primary {
  color: #000000 !important;
}
</style>
  