// const BASE_URL = "http://10.74.18.92:8080/db/"
// const BASE_URL = "http://116.90.165.45:7080/db/bmkgsatu";
const BASE_URL = process.env.VUE_APP_BASE_URL
// const BMKGSATU_URL = BASE_URL + 'bmkgsatu/'
// const STATION_URL = BASE_URL + 'bmkgsatu/station'

// const TOKEN = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MzQ3MTY5ODksImV4cCI6MTYzNDcyMDU4OSwiaWQiOiJ1c2VyMSIsInN1YiI6InVzZXIxIn0.y3Pn1L2HlO6dEgLp9OeCZDFR1XZpi_1JW-1ZterbVes"
// const config = {
//     headers: {
//     'Content-Type': 'application/json',
//     'Accept': 'application/json',
//     'Authorization': `Bearer  ${TOKEN}`,
//     }
// };

import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";

export default {
  getFDIH(path) {
    var url = BASE_URL + path;
    return axios.get(url, Helper.getConfig());
  },

  setNewFDIH0700(path, data) {
    var url = BASE_URL + path;
    return axios.post(url, data, Helper.getConfig());
  },

  setNewFDIH07001800(path, data) {
    var url = BASE_URL + path;
    return axios.post(url, data, Helper.getConfig());
  },

  setNewFDIH1700(path, data) {
    var url = BASE_URL + path;
    return axios.post(url, data, Helper.getConfig());
  },

  updateFDIH0700(path, data) {
    var url = BASE_URL + path;
    return axios.patch(url, data, Helper.getConfig());
  },

  setFDIH07301800(path, data) {
    
    var url = BASE_URL + path;    
    return axios.patch(url, data, Helper.getConfig());
  },

  

};
