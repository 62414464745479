import axios from "axios";
import helper from "@/api/helper";

export async function createQCNotification(form_id, form_type, tanggal_pengamatan, jam_pengamatan, histories, qc_flag, station_id, station_name, observer_id, observer_name, region_id, path_oinput) {
  if (form_id === "" || tanggal_pengamatan === "" || jam_pengamatan === "" || histories === "", qc_flag === "" || station_id === "" || station_name === "" || observer_id === "" || observer_name === "" || region_id === "" || path_oinput === "") {
    return false;
  }
  const DATA = {
    "@type": "QcNotification",
    id: "qcnotification_" + form_id,
    form_type,
    tanggal_pengamatan,
    jam_pengamatan,
    histories,
    qc_flag,
    station_id,
    station_name,
    observer_id,
    observer_name,
    region_id, 
    path_oinput
  };
  const URL = helper.getBaseUrl() + "qcnotification";

  axios
    .post(URL, DATA, helper.getConfig())
    .then((response) => {
      // console.log(response);
    })
    .catch((error) => {
      // console.log(error);
    });
}

export async function checkExist(objID) {
  let url = helper.getBaseUrl() + "qcnotification/"+objID;
  console.log('checkExist', url)
  let data = await axios
              .get(url, helper.getConfig())
              .then((response) => {
                return true
              })
              .catch((error) => {
                return false
                // console.log(error);
              });

  console.log(data)
  return data
}

export async function editQCNotification(form_id, form_type, tanggal_pengamatan, jam_pengamatan, histories, qc_flag, station_id, station_name, observer_id, observer_name, region_id, path_oinput) {
  if (form_id === "" || tanggal_pengamatan === "" || jam_pengamatan === "" || histories === "", qc_flag === "" || station_id === "" || station_name === "" || observer_id === "" || observer_name === "" || region_id === "" || path_oinput === "") {
    return false;
  }

  if (await checkQCNotification(form_id)) {
    console.log('editQCNotification', true)
    const DATA = {
      "@type": "QcNotification",
      tanggal_pengamatan,
      jam_pengamatan,
      histories,
      qc_flag,
      observer_id,
      observer_name,
      region_id, 
      path_oinput
    };
    const URL = helper.getBaseUrl() + "qcnotification" + "/" + "qcnotification_" + form_id;
  
    axios
      .patch(URL, DATA, helper.getConfig())
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        // console.log(error);
      });
  }else if (qc_flag != 0) {
    console.log('qc_flag != 0')
    createQCNotification(form_id, form_type, tanggal_pengamatan, jam_pengamatan, histories, qc_flag, station_id, station_name, observer_id, observer_name, region_id, path_oinput);
  }
}

export async function checkQCNotification(form_id) {
  const URL = helper.getBaseUrl() + "qcnotification" + "/" + "qcnotification_" + form_id;
  let exist = false;

  try {
    const response = await axios.get(URL, helper.getConfig());
    exist = response?.status == 200;
  }catch(error) {
    exist = false;
  }

  return exist;
}

export async function approveQC(get_url, post_data) {
  let data = null;
  await axios.patch(get_url, post_data, helper.getConfig())
      .then(function (response) {
        data = response.data;
        // resolve(response.data);
      })
      .catch(function (error) {
      });
  return data;
}

export async function updateQcNotification(id, post_data) {
  const URL = helper.getBaseUrl() + "/qcnotification/" + id
  let data = null;
  await axios.patch(URL, post_data, helper.getConfig())
      .then(function (response) {
        data = response.data;
        // resolve(response.data);
      })
      .catch(function (error) {
      });
  return data;
}

export async function getQcNotification(objID){ 
  const URL = helper.getBaseUrl() + "/qcnotification/" + objID;
  let data = null
  await axios.get(URL, helper.getConfig())
  .then(function (response) {
      data = response
      return data
  })
  .catch(function (error) {
      // resolve(observers);
  });
  return data
              
}

export default {
  createQCNotification,
  editQCNotification,
  checkQCNotification,
  approveQC,
  updateQcNotification,
  checkExist,
  getQcNotification
}
