import axios from 'axios'
import jwt from '@/auth/jwt/useJwt';
import helper from './helper';
import { getNodeFromPath } from 'tree-component';

export default {
    getExStasiunKu(is_entry){
    //    is_aerosol,is_kimia_air_hujan,is_so2_no2,is_spm
        
        var ids;
        let userLogin = JSON.parse(localStorage.getItem('userLogin')) 
        if (userLogin.hasOwnProperty('groupIds')) { 
            ids = userLogin.groupIds 
        }
        let userType = userLogin.username
        var url;
        if (userType == 'root') {
            url = helper.getBaseUrl()+"@aggregation_query?type_name=BmkgStation&"+is_entry+"=true"+
            "&_metadata=station_id,station_wmo_id,station_name,path,region_id,region_description,propinsi_id,propinsi_name,current_latitude,current_longitude,alias_station_id&_size=10000"
        
        }else{
            url = helper.getBaseUrl()+"@aggregation_multiquery?type_name=BmkgStation&"+is_entry+"=true&_size=10000"+
            "&_metadata=station_id,station_wmo_id,station_name,alias_station_id,region_id,region_description,propinsi_id,propinsi_name,current_latitude,current_longitude,path&station_id="+ids
        
        }
        return axios.get(url, helper.getConfig())
    },
}