<template>
    <div v-if="data_input.i_time == '0700ws'" style="font-size: 0.9rem;">
        <!-- <validation-observer ref="simpleRules"> -->
        <b-form>
            <b-row class="mt-n1">
                <b-col md="6" lg="4" class="mb-1">
                    <b-card class="small font-weight-bolder h-100">
                        <b-row>
                            <b-col cols="10">
                                <h6 class="mb-2 small font-weight-bolder badge badge-light-dark">PSYCHROMETER SANGKAR</h6>
                            </b-col>
                            <b-col cols="1">
                                <feather-icon v-b-toggle.collapse-a icon="ChevronDownIcon" />
                            </b-col>
                        </b-row>
                        <b-collapse visible id="collapse-a">
                            <b-row class="">
                                <b-col cols="12">
                                    <b-row v-if="fdih_status.is_fklim || fdih_status.is_agm1a" class="">
                                        <b-col cols="3"></b-col>
                                        <b-col cols="3" class="text-center m-auto pr-1 pl-0">
                                            <div class="label-entry2 bg-light-primary">TBK</div>
                                        </b-col>
                                        <b-col cols="3" class="text-center m-auto pr-1 pl-0">
                                            <div class="label-entry2 bg-light-primary">TBB</div>
                                        </b-col>

                                        <b-col cols="3" class="text-center m-auto pr-1 pl-0">
                                            <div class="label-entry2 bg-light-primary">RH</div>
                                        </b-col>
                                    </b-row>

                                    <b-row v-if="fdih_status.is_fklim || fdih_status.is_agm1a" class="mt-1">
                                        <b-col cols="3" class="">
                                            <div class="label-entry1">
                                                1.2 m
                                                <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.html="{ title: fdih_1c2m_0700 }" />
                                            </div>
                                        </b-col>
                                        <b-col cols="3" class="m-auto pr-1 pl-0">
                                            <div>
                                                <b-form-input
                                                    v-on:keydown="onKeyCheck($event)"
                                                    id="input_tbk_1c2m_0700"
                                                    :formatter="format_one_decimal"
                                                    size="sm"
                                                    :state="validateState($v.tbk_1c2m_0700)"
                                                    v-model.number="$v.tbk_1c2m_0700.$model"
                                                    class="text-center"
                                                    v-on:keyup="checkValidate($event, 'tbk_1c2m_0700')"
                                                    v-bind:class="{ rangecheck: rc_tbk_1c2m_0700 }"
                                                ></b-form-input>
                                            </div>
                                            <b-tooltip v-if="CC.tbk_1c2m_0700.status" target="input_tbk_1c2m_0700" :triggers="'hover'" placement="topleft">
                                                {{ CC.tbk_1c2m_0700.message }}
                                            </b-tooltip>
                                            <b-tooltip
                                                v-if="!rc_tbk_1c2m_0700 && !CC.tbk_1c2m_0700.status"
                                                :show="validateTooltipState('tbk_1c2m_0700')"
                                                :triggers="'hover'"
                                                target="input_tbk_1c2m_0700"
                                                placement="topleft"
                                                custom-class="validation-tooltip"
                                            >
                                                {{ tooltipMessage("tbk_1c2m_0700") }}
                                            </b-tooltip>
                                            <b-tooltip v-if="rc_tbk_1c2m_0700 && !CC.tbk_1c2m_0700.status" target="input_tbk_1c2m_0700" triggers="hover" placement="topleft">{{ rc_tbk_1c2m_0700_message }}</b-tooltip>
                                        </b-col>
                                        <b-col cols="3" class="m-auto pr-1 pl-0">
                                            <b-form-input
                                                v-on:keydown="onKeyCheck($event)"
                                                id="input_tbb_1c2m_0700"
                                                :formatter="format_one_decimal"
                                                size="sm"
                                                :state="validateState($v.tbb_1c2m_0700)"
                                                v-model.number="$v.tbb_1c2m_0700.$model"
                                                class="text-center"
                                                v-on:keyup="checkValidate($event, 'tbb_1c2m_0700')"
                                                v-bind:class="{ rangecheck: rc_tbb_1c2m_0700 }"
                                            ></b-form-input>
                                            <b-tooltip v-if="CC.tbb_1c2m_0700.status" target="input_tbb_1c2m_0700" :triggers="'hover'" placement="topleft">
                                                {{ CC.tbb_1c2m_0700.message }}
                                            </b-tooltip>
                                            <b-tooltip
                                                v-if="!rc_tbb_1c2m_0700 && !CC.tbb_1c2m_0700.status"
                                                :show="validateTooltipState('tbb_1c2m_0700')"
                                                :triggers="'hover'"
                                                target="input_tbb_1c2m_0700"
                                                placement="topleft"
                                                custom-class="validation-tooltip"
                                            >
                                                {{ tooltipMessage("tbb_1c2m_0700") }}
                                            </b-tooltip>
                                            <b-tooltip v-if="rc_tbb_1c2m_0700 && !CC.tbb_1c2m_0700.status" target="input_tbb_1c2m_0700" triggers="hover" placement="topleft">{{ rc_tbb_1c2m_0700_message }}</b-tooltip>
                                        </b-col>

                                        <b-col cols="3" class="m-auto pr-1 pl-0">
                                            <b-form-input size="sm" class="bg-light-success text-center" v-model.number="rh_1c2m_0700" disabled></b-form-input>
                                        </b-col>
                                    </b-row>

                                    <b-row v-if="fdih_status.is_iklim_mikro" class="mt-1" sm="1">
                                        <b-col cols="3" class="">
                                            <div class="label-entry1">
                                                4 m
                                                <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips1" v-b-tooltip.hover.html="{ title: fdih_4m_0700 }" />
                                            </div>
                                        </b-col>
                                        <b-col cols="3" class="m-auto pr-1 pl-0">
                                            <b-form-input
                                                v-on:keydown="onKeyCheck($event)"
                                                id="input_tbk_4m_0700"
                                                :formatter="format_one_decimal"
                                                size="sm"
                                                :state="validateState($v.tbk_4m_0700)"
                                                v-model.number="$v.tbk_4m_0700.$model"
                                                v-on:keyup="checkValidate($event, 'tbk_4m_0700')"
                                                v-bind:class="{ rangecheck: rc_tbk_4m_0700 }"
                                                class="text-center"
                                            ></b-form-input>
                                            <b-tooltip v-if="CC.tbk_4m_0700.status" target="input_tbk_4m_0700" :triggers="'hover'" placement="topleft">
                                                {{ CC.tbk_4m_0700.message }}
                                            </b-tooltip>
                                            <b-tooltip v-if="!rc_tbk_4m_0700 && !CC.tbk_4m_0700.status" :show="validateTooltipState('tbk_4m_0700')" :triggers="'hover'" target="input_tbk_4m_0700" placement="topleft" custom-class="validation-tooltip">
                                                {{ tooltipMessage("tbk_4m_0700") }}
                                            </b-tooltip>
                                            <b-tooltip v-if="rc_tbk_4m_0700 && !CC.tbk_4m_0700.status" target="input_tbk_4m_0700" triggers="hover" placement="topleft">{{ rc_tbk_4m_0700_message }}</b-tooltip>
                                        </b-col>
                                        <b-col cols="3" class="m-auto pr-1 pl-0">
                                            <b-form-input
                                                v-on:keydown="onKeyCheck($event)"
                                                id="input_tbb_4m_0700"
                                                :formatter="format_one_decimal"
                                                size="sm"
                                                :state="validateState($v.tbb_4m_0700)"
                                                v-model.number="$v.tbb_4m_0700.$model"
                                                v-on:keyup="checkValidate($event, 'tbb_4m_0700')"
                                                v-bind:class="{ rangecheck: rc_tbb_4m_0700 }"
                                                class="text-center"
                                            ></b-form-input>
                                            <b-tooltip v-if="CC.tbb_4m_0700.status" target="input_tbb_4m_0700" :triggers="'hover'" placement="topleft">
                                                {{ CC.tbb_4m_0700.message }}
                                            </b-tooltip>
                                            <b-tooltip v-if="!rc_tbb_4m_0700 && !CC.tbb_4m_0700.status" :show="validateTooltipState('tbb_4m_0700')" :triggers="'hover'" target="input_tbb_4m_0700" placement="topleft" custom-class="validation-tooltip">
                                                {{ tooltipMessage("tbb_4m_0700") }}
                                            </b-tooltip>
                                            <b-tooltip v-if="rc_tbb_4m_0700 && !CC.tbb_4m_0700.status" target="input_tbb_4m_0700" triggers="hover" placement="topleft">{{ rc_tbb_4m_0700_message }}</b-tooltip>
                                        </b-col>

                                        <b-col cols="3" class="m-auto pr-1 pl-0">
                                            <b-form-input size="sm" class="bg-light-success text-center" v-model.number="rh_4m_0700" disabled></b-form-input>
                                        </b-col>
                                    </b-row>

                                    <b-row v-if="fdih_status.is_iklim_mikro" class="mt-1">
                                        <b-col cols="3" class="">
                                            <div class="label-entry1">7 m <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips3" v-b-tooltip.hover.html="{ title: fdih_7m_0700 }" /></div>
                                        </b-col>
                                        <b-col cols="3" class="m-auto pr-1 pl-0">
                                            <b-form-input
                                                v-on:keydown="onKeyCheck($event)"
                                                id="input_tbk_7m_0700"
                                                :formatter="format_one_decimal"
                                                size="sm"
                                                :state="validateState($v.tbk_7m_0700)"
                                                v-model.number="$v.tbk_7m_0700.$model"
                                                v-on:keyup="checkValidate($event, 'tbk_7m_0700')"
                                                v-bind:class="{ rangecheck: rc_tbk_7m_0700 }"
                                                class="text-center"
                                            ></b-form-input>
                                            <b-tooltip v-if="CC.tbk_7m_0700.status" target="input_tbk_7m_0700" :triggers="'hover'" placement="topleft">
                                                {{ CC.tbk_7m_0700.message }}
                                            </b-tooltip>
                                            <b-tooltip v-if="!rc_tbk_7m_0700 && !CC.tbk_7m_0700.status" :show="validateTooltipState('tbk_7m_0700')" :triggers="'hover'" target="input_tbk_7m_0700" placement="topleft" custom-class="validation-tooltip">
                                                {{ tooltipMessage("tbk_7m_0700") }}
                                            </b-tooltip>
                                            <b-tooltip v-if="rc_tbk_7m_0700 && !CC.tbk_7m_0700.status" target="input_tbk_7m_0700" triggers="hover" placement="topleft">{{ rc_tbk_7m_0700_message }}</b-tooltip>
                                        </b-col>
                                        <b-col cols="3" class="m-auto pr-1 pl-0">
                                            <b-form-input
                                                v-on:keydown="onKeyCheck($event)"
                                                id="input_tbb_7m_0700"
                                                :formatter="format_one_decimal"
                                                size="sm"
                                                :state="validateState($v.tbb_7m_0700)"
                                                v-model.number="$v.tbb_7m_0700.$model"
                                                class="text-center"
                                            ></b-form-input>
                                            <b-tooltip v-if="CC.tbb_7m_0700.status" target="input_tbb_7m_0700" :triggers="'hover'" placement="topleft">
                                                {{ CC.tbb_7m_0700.message }}
                                            </b-tooltip>
                                            <b-tooltip v-if="!rc_tbb_7m_0700 && !CC.tbb_7m_0700.status" :show="validateTooltipState('tbb_7m_0700')" :triggers="'hover'" target="input_tbb_7m_0700" placement="topleft" custom-class="validation-tooltip">
                                                {{ tooltipMessage("tbb_7m_0700") }}
                                            </b-tooltip>
                                            <b-tooltip v-if="rc_tbb_7m_0700 && !CC.tbb_7m_0700.status" target="input_tbb_7m_0700" triggers="hover" placement="topleft">{{ rc_tbb_7m_0700_message }}</b-tooltip>
                                        </b-col>

                                        <b-col cols="3" class="m-auto pr-1 pl-0">
                                            <b-form-input size="sm" class="bg-light-success text-center" v-model.number="rh_7m_0700" disabled></b-form-input>
                                        </b-col>
                                    </b-row>

                                    <b-row v-if="fdih_status.is_iklim_mikro" class="mt-1">
                                        <b-col cols="3" class="">
                                            <div class="label-entry1">10 m <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips4" v-b-tooltip.hover.html="{ title: fdih_10m_0700 }" /></div>
                                        </b-col>
                                        <b-col cols="3" class="m-auto pr-1 pl-0">
                                            <b-form-input
                                                v-on:keydown="onKeyCheck($event)"
                                                id="input_tbk_10m_0700"
                                                :formatter="format_one_decimal"
                                                size="sm"
                                                :state="validateState($v.tbk_10m_0700)"
                                                v-model.number="$v.tbk_10m_0700.$model"
                                                v-on:keyup="checkValidate($event, 'tbk_10m_0700')"
                                                v-bind:class="{ rangecheck: rc_tbk_10m_0700 }"
                                                class="text-center"
                                            ></b-form-input>
                                            <b-tooltip v-if="CC.tbk_10m_0700.status" target="input_tbk_10m_0700" :triggers="'hover'" placement="topleft">
                                                {{ CC.tbk_10m_0700.message }}
                                            </b-tooltip>
                                            <b-tooltip
                                                v-if="!rc_tbk_10m_0700 && !CC.tbk_10m_0700.status"
                                                :show="validateTooltipState('tbk_10m_0700')"
                                                :triggers="'hover'"
                                                target="input_tbk_10m_0700"
                                                placement="topleft"
                                                custom-class="validation-tooltip"
                                            >
                                                {{ tooltipMessage("tbk_10m_0700") }}
                                            </b-tooltip>
                                            <b-tooltip v-if="rc_tbk_10m_0700 && !CC.tbk_10m_0700.status" target="input_tbk_10m_0700" triggers="hover" placement="topleft">{{ rc_tbk_10m_0700_message }}</b-tooltip>
                                        </b-col>
                                        <b-col cols="3" class="m-auto pr-1 pl-0">
                                            <b-form-input
                                                v-on:keydown="onKeyCheck($event)"
                                                id="input_tbb_10m_0700"
                                                :formatter="format_one_decimal"
                                                size="sm"
                                                :state="validateState($v.tbb_10m_0700)"
                                                v-model.number="$v.tbb_10m_0700.$model"
                                                class="text-center"
                                            ></b-form-input>
                                            <b-tooltip v-if="CC.tbb_10m_0700.status" target="input_tbb_10m_0700" :triggers="'hover'" placement="topleft">
                                                {{ CC.tbb_10m_0700.message }}
                                            </b-tooltip>
                                            <b-tooltip
                                                v-if="!rc_tbb_10m_0700 && !CC.tbb_10m_0700.status"
                                                :show="validateTooltipState('tbb_10m_0700')"
                                                :triggers="'hover'"
                                                target="input_tbb_10m_0700"
                                                placement="topleft"
                                                custom-class="validation-tooltip"
                                            >
                                                {{ tooltipMessage("tbb_10m_0700") }}
                                            </b-tooltip>
                                            <b-tooltip v-if="rc_tbb_10m_0700 && !CC.tbb_10m_0700.status" target="input_tbb_10m_0700" triggers="hover" placement="topleft">{{ rc_tbb_10m_0700_message }}</b-tooltip>
                                        </b-col>

                                        <b-col cols="3" class="m-auto pr-1 pl-0">
                                            <b-form-input size="sm" class="bg-light-success text-center" v-model.number="rh_10m_0700" disabled></b-form-input>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-collapse>
                    </b-card>
                </b-col>

                <b-col md="6" lg="4" class="mb-1">
                    <b-card class="small h-100">
                        <b-row>
                            <b-col cols="10">
                                <h6 class="mb-1 small font-weight-bolder badge badge-light-dark">ANGIN</h6>
                            </b-col>
                            <b-col cols="1">
                                <feather-icon v-b-toggle.collapse-a icon="ChevronDownIcon" />
                            </b-col>
                        </b-row>
                        <b-collapse v-if="fdih_status.is_iklim_mikro || fdih_status.is_op_penguapan || fdih_status.is_agm1a" visible id="collapse-a">
                            <b-row>
                                <b-col cols="12">
                                    <b-row class="mb-1">
                                        <b-col cols="12" class="font-weight-bolder text-primary"> Kecepatan Rata-Rata </b-col>
                                    </b-row>

                                    <b-row class="my-1">
                                        <b-col cols="3"></b-col>
                                        <b-col cols="3" class="text-center pad2">
                                            <div class="label-entry2 font-weight-bolder bg-light-primary h-100">Counter Sebelum</div>
                                        </b-col>
                                        <b-col cols="3" class="text-center pad2">
                                            <div class="label-entry2 font-weight-bolder bg-light-primary h-100">Counter Dibaca</div>
                                        </b-col>
                                        <b-col cols="3" class="text-center pad2">
                                            <div class="label-entry2 font-weight-bolder bg-light-primary h-100">Counter Rata</div>
                                        </b-col>
                                    </b-row>

                                    <b-row v-if="fdih_status.is_op_penguapan" class="mbtm">
                                        <b-col cols="3" class="m-auto pad2">
                                            <div class="label-entry1 font-weight-bolder">0.5 m <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips6" /></div>
                                            <!-- HTML tooltips -->
                                            <b-tooltip target="tooltips6" :triggers="'hover'" placement="topleft">
                                                <dl class="text-left">
                                                    <dt>Counter Sebelum</dt>
                                                    <dd>- Cup Counter dibaca 0,5 m 07.00 WS kemarin</dd>
                                                    <dt>Counter dibaca</dt>
                                                    <dd>- Cup Counter dibaca 0,5 m 07.00 WS hari ini</dd>
                                                    <dt>Counter Rata</dt>
                                                    <dd>
                                                        - Kecepatan angin rata-rata, hasil bagi (sampai dua angka dibelakang koma dari selisih pembacaan Cup Counter yang tingginya 0.5 m antara jam 07.00 WS kemarin dengan jam 07.00 WS hari ini dibagi
                                                        24. Dinyatakan dalam Km/jam.
                                                    </dd>
                                                </dl>
                                            </b-tooltip>
                                        </b-col>
                                        <b-col cols="3">
                                            <b-form-input v-on:keydown="onKeyCheck($event)" :formatter="format_two_decimal" size="sm" class="text-center" v-model.number="counter_sebelum_0c5_0700" :disabled="!is_counter_edit"></b-form-input>
                                        </b-col>
                                        <b-col cols="3">
                                            <b-form-input
                                                v-on:keydown="onKeyCheck($event)"
                                                id="counter_0c5_0700"
                                                :formatter="format_two_decimal"
                                                size="sm"
                                                class="text-center"
                                                :state="validateState($v.counter_0c5_0700)"
                                                v-model.number="$v.counter_0c5_0700.$model"
                                                :disabled="!fdih_status.is_op_penguapan"
                                            ></b-form-input>
                                            <!-- tooltips CC -->
                                            <b-tooltip v-if="CC.counter_0c5_0700.status" target="counter_0c5_0700" :triggers="'hover'" placement="topleft">
                                                {{ CC.counter_0c5_0700.message }}
                                            </b-tooltip>
                                            <b-tooltip v-if="!CC.counter_0c5_0700.status" :show="validateTooltipState('counter_0c5_0700')" :triggers="'hover'" target="counter_0c5_0700" placement="topleft" custom-class="validation-tooltip">
                                                {{ tooltipMessage("counter_0c5_0700") }}
                                            </b-tooltip>
                                        </b-col>
                                        <b-col cols="3">
                                            <b-form-input :formatter="format_two_decimal" size="sm" class="bg-light-success text-center" v-model.number="ws_avg_0c5_0700" disabled></b-form-input>
                                        </b-col>
                                    </b-row>

                                    <b-row v-if="fdih_status.is_iklim_mikro" class="mbtm">
                                        <b-col cols="3" class="m-auto pad2">
                                            <div class="label-entry1 font-weight-bolder">4 m <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips7" /></div>
                                            <!-- HTML tooltips -->
                                            <b-tooltip target="tooltips7" :triggers="'hover'" placement="topleft">
                                                <dl class="text-left">
                                                    <dt>Counter Sebelum</dt>
                                                    <dd>- Cup Counter dibaca 4 m 18.00 WS kemarin</dd>
                                                    <dt>Counter dibaca</dt>
                                                    <dd>- cup counter dibaca 4 m 07.00 WS hari ini</dd>
                                                    <dt>Counter Rata</dt>
                                                    <dd>
                                                        - Kecepatan angin rata-rata, hasil bagi (sampai dua angka di belakang koma) dari selisih pembacaan cup counter yang tingginya 4 m antara jam 18.00 WS kemarin dengan jam 07.00 WS hari ini dibagi
                                                        13, Dinyatakan dalam Km/jam.
                                                    </dd>
                                                </dl>
                                            </b-tooltip>
                                        </b-col>
                                        <b-col cols="3">
                                            <b-form-input v-on:keydown="onKeyCheck($event)" :formatter="format_two_decimal" size="sm" class="text-center" v-model.number="counter_sebelum_4_0700" :disabled="!is_counter_edit"></b-form-input>
                                        </b-col>
                                        <b-col cols="3">
                                            <b-form-input
                                                v-on:keydown="onKeyCheck($event)"
                                                id="counter_4_0700"
                                                :formatter="format_two_decimal"
                                                size="sm"
                                                class="text-center"
                                                :state="validateState($v.counter_4_0700)"
                                                v-model.number="$v.counter_4_0700.$model"
                                            ></b-form-input>
                                            <!-- tooltips CC -->
                                            <b-tooltip v-if="CC.counter_4_0700.status" target="counter_4_0700" :triggers="'hover'" placement="topleft">
                                                {{ CC.counter_4_0700.message }}
                                            </b-tooltip>
                                            <b-tooltip v-if="!CC.counter_4_0700.status" :show="validateTooltipState('counter_4_0700')" :triggers="'hover'" target="counter_4_0700" placement="topleft" custom-class="validation-tooltip">
                                                {{ tooltipMessage("counter_4_0700") }}
                                            </b-tooltip>
                                        </b-col>
                                        <b-col cols="3">
                                            <b-form-input :formatter="format_two_decimal" size="sm" class="bg-light-success text-center" v-model.number="ws_avg_4_0700" disabled></b-form-input>
                                        </b-col>
                                    </b-row>

                                    <b-row v-if="fdih_status.is_iklim_mikro || fdih_status.is_agm1a" class="mbtm">
                                        <b-col cols="3" class="m-auto pad2">
                                            <div class="label-entry1 font-weight-bolder">7 m <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips8" /></div>
                                            <!-- HTML tooltips -->
                                            <b-tooltip target="tooltips8" :triggers="'hover'" placement="topleft">
                                                <dl class="text-left">
                                                    <dt>Counter Sebelum</dt>
                                                    <dd>- Cup Counter dibaca 7 m 18.00 WS kemarin</dd>
                                                    <dt>Counter dibaca</dt>
                                                    <dd>- cup counter dibaca 7 m 07.00 WS hari ini</dd>
                                                    <dt>Counter Rata</dt>
                                                    <dd>
                                                        - Kecepatan angin rata-rata, hasil bagi (sampai dua angka di belakang koma) dari selisih pembacaan cup counter yang tingginya 7 m antara jam 18.00 WS kemarin dengan jam 07.00 WS hari ini dibagi
                                                        13, Dinyatakan dalam Km/jam.
                                                    </dd>
                                                </dl>
                                            </b-tooltip>
                                        </b-col>
                                        <b-col cols="3">
                                            <b-form-input v-on:keydown="onKeyCheck($event)" :formatter="format_two_decimal" size="sm" class="text-center" v-model.number="counter_sebelum_7_0700" :disabled="!is_counter_edit"></b-form-input>
                                        </b-col>
                                        <b-col cols="3">
                                            <b-form-input
                                                v-on:keydown="onKeyCheck($event)"
                                                id="counter_7_0700"
                                                :formatter="format_two_decimal"
                                                size="sm"
                                                class="text-center"
                                                :state="validateState($v.counter_7_0700)"
                                                v-model.number="$v.counter_7_0700.$model"
                                            ></b-form-input>
                                            <!-- tooltips CC -->
                                            <b-tooltip v-if="CC.counter_7_0700.status" target="counter_7_0700" :triggers="'hover'" placement="topleft">
                                                {{ CC.counter_7_0700.message }}
                                            </b-tooltip>
                                            <b-tooltip v-if="!CC.counter_7_0700.status" :show="validateTooltipState('counter_7_0700')" :triggers="'hover'" target="counter_7_0700" placement="topleft" custom-class="validation-tooltip">
                                                {{ tooltipMessage("counter_7_0700") }}
                                            </b-tooltip>
                                        </b-col>
                                        <b-col cols="3">
                                            <b-form-input :formatter="format_two_decimal" size="sm" class="bg-light-success text-center" v-model.number="ws_avg_7_0700" disabled></b-form-input>
                                        </b-col>
                                    </b-row>

                                    <b-row v-if="fdih_status.is_iklim_mikro" class="">
                                        <b-col cols="3" class="m-auto pad2">
                                            <div class="label-entry1 font-weight-bolder">10 m <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips9" /></div>
                                            <!-- HTML tooltips -->
                                            <b-tooltip target="tooltips9" :triggers="'hover'" placement="topleft">
                                                <dl class="text-left">
                                                    <dt>Counter Sebelum</dt>
                                                    <dd>- Cup Counter dibaca 10 m 18.00 WS kemarin</dd>
                                                    <dt>Counter dibaca</dt>
                                                    <dd>- cup counter dibaca 10 m 07.00 WS hari ini</dd>
                                                    <dt>Counter Rata</dt>
                                                    <dd>
                                                        - Kecepatan angin rata-rata, hasil bagi (sampai dua angka di belakang koma) dari selisih pembacaan cup counter yang tingginya 10 m antara jam 18.00 WS kemarin dengan jam 07.00 WS hari ini dibagi
                                                        13, Dinyatakan dalam Km/jam.
                                                    </dd>
                                                </dl>
                                            </b-tooltip>
                                        </b-col>
                                        <b-col cols="3">
                                            <b-form-input v-on:keydown="onKeyCheck($event)" :formatter="format_two_decimal" size="sm" class="text-center" v-model.number="counter_sebelum_10_0700" :disabled="!is_counter_edit"></b-form-input>
                                        </b-col>
                                        <b-col cols="3">
                                            <b-form-input
                                                v-on:keydown="onKeyCheck($event)"
                                                id="counter_10_0700"
                                                :formatter="format_two_decimal"
                                                size="sm"
                                                class="text-center"
                                                :state="validateState($v.counter_10_0700)"
                                                v-model.number="$v.counter_10_0700.$model"
                                            ></b-form-input>
                                            <!-- tooltips CC -->
                                            <b-tooltip v-if="CC.counter_10_0700.status" target="counter_10_0700" :triggers="'hover'" placement="topleft">
                                                {{ CC.counter_10_0700.message }}
                                            </b-tooltip>
                                            <b-tooltip v-if="!CC.counter_10_0700.status" :show="validateTooltipState('counter_10_0700')" :triggers="'hover'" target="counter_10_0700" placement="topleft" custom-class="validation-tooltip">
                                                {{ tooltipMessage("counter_10_0700") }}
                                            </b-tooltip>
                                        </b-col>
                                        <b-col cols="3">
                                            <b-form-input :formatter="format_two_decimal" size="sm" class="bg-light-success text-center" v-model.number="ws_avg_10_0700" disabled></b-form-input>
                                        </b-col>
                                    </b-row>

                                    <b-row v-if="fdih_status.is_iklim_mikro || fdih_status.is_op_penguapan" class="mt-1">
                                        <b-col cols="3" />
                                        <b-col cols="3">
                                            <feather-icon icon="HelpCircleIcon" size="14" class="bg-primary rounded-circle text-white float-right" v-b-tooltip.hover.html="{ title: fdih_counter_sebelum_0700 }" style="margin: 5px auto;" />
                                            <b-form-checkbox tabindex="-1" class="custom-control-primary float-right" name="check-button" switch v-model="is_counter_edit">
                                                <span class="switch-icon-left">
                                                    <feather-icon icon="CheckIcon" />
                                                </span>
                                                <span class="switch-icon-right">
                                                    <feather-icon icon="XIcon" />
                                                </span>
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>

                                    <!-- Kecepatan Rata-Rata end -->

                                    <hr />

                                    <!-- Arah dan Kecepatan start -->
                                    <div v-if="fdih_status.is_agm1a">
                                        <b-row>
                                            <b-col cols="12" class="font-weight-bolder text-primary"> Arah dan Kecepatan saat Peramatan </b-col>
                                        </b-row>

                                        <b-row class="mbtm mt-1">
                                            <b-col cols="4"></b-col>
                                            <b-col cols="4">
                                                <div class="label-entry2 font-weight-bolder bg-light-primary">ARAH</div>
                                            </b-col>
                                            <b-col cols="4 ">
                                                <div class="label-entry2 font-weight-bolder bg-light-primary">KECEPATAN</div>
                                            </b-col>
                                        </b-row>

                                        <b-row>
                                            <b-col cols="4" class="m-auto">
                                                <div class="label-entry1 font-weight-bolder">10 m <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips10" /></div>
                                                <!-- HTML tooltips -->
                                                <b-tooltip target="tooltips10" :triggers="'manual'" placement="topleft">
                                                    <dl class="text-left">
                                                        <dt>Arah</dt>
                                                        <dd>- Arah dari mana datangnya angin ketinggian 10 m</dd>
                                                        <dt>Kecepatan</dt>
                                                        <dd>- Kecepatan angin ketinggian 10 m dalam meter/detik (m/s)</dd>
                                                    </dl>
                                                </b-tooltip>
                                            </b-col>
                                            <b-col cols="4">
                                                <b-form-input id="wd_0700" size="sm" class="text-center" :state="validateState($v.wd_0700)" v-model.number="$v.wd_0700.$model" v-on:keypress="isNumber($event)"></b-form-input>
                                                <b-tooltip :show="validateTooltipState('wd_0700')" :triggers="'hover'" target="wd_0700" placement="topleft" custom-class="validation-tooltip">
                                                    {{ tooltipMessage("wd_0700") }}
                                                </b-tooltip>
                                            </b-col>
                                            <b-col cols="4">
                                                <b-form-input
                                                    id="input_ws_0700"
                                                    :formatter="format_one_decimal"
                                                    v-on:keyup="checkValidate($event, 'ws_0700')"
                                                    v-bind:class="{ rangecheck: rc_ws_0700 }"
                                                    size="sm"
                                                    class="text-center"
                                                    :state="validateState($v.ws_0700)"
                                                    v-model.number="$v.ws_0700.$model"
                                                ></b-form-input>
                                                <b-tooltip v-if="rc_ws_0700" target="input_ws_0700" triggers="hover">{{ rc_ws_0700_message }}</b-tooltip>

                                                <b-tooltip v-if="!rc_ws_0700" :show="validateTooltipState('ws_0700')" :triggers="'hover'" target="input_ws_0700" placement="topleft" custom-class="validation-tooltip">
                                                    {{ tooltipMessage("ws_0700") }}
                                                </b-tooltip>
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <!-- Arah dan Kecepatan end -->
                                </b-col>
                            </b-row>
                        </b-collapse>
                    </b-card>
                </b-col>

                <!-- OP PENGUAPAN start -->
                <b-col md="6" lg="4" class="mb-2">
                    <b-card class="small h-100">
                        <b-row>
                            <b-col cols="10">
                                <h6 class="mb-1 small font-weight-bolder badge badge-light-dark">OP PENGUAPAN</h6>
                            </b-col>
                            <b-col cols="1">
                                <feather-icon v-b-toggle.collapse-a icon="ChevronDownIcon" />
                            </b-col>
                        </b-row>
                        <b-collapse v-if="fdih_status.is_fklim || fdih_status.is_agm1a || fdih_status.is_op_penguapan" visible id="collapse-a">
                            <b-row v-if="fdih_status.is_fklim || fdih_status.is_agm1a || fdih_status.is_op_penguapan">
                                <b-col cols="12">
                                    <b-row v-if="fdih_status.is_op_penguapan" class="my-1">
                                        <b-col cols="6" class="m-auto">
                                            <div class="label-entry1">
                                                Selisih Tinggi Air
                                                <feather-icon
                                                    icon="HelpCircleIcon"
                                                    size="12"
                                                    class="bg-primary rounded-circle text-white"
                                                    v-b-tooltip.hover.v-dark
                                                    title="Beda pembacaan tinggi air pada bejana selama 24 jam (jam 07.00 WS kemarin dan 07.00 WS hari ini)"
                                                />
                                            </div>
                                        </b-col>
                                        <b-col cols="4">
                                            <b-form-input
                                                v-on:keydown="onKeyCheck($event)"
                                                id="input_op_diff_baca_0700"
                                                size="sm"
                                                class="text-center"
                                                v-on:keyup="checkValidate($event, 'op_diff_baca_0700')"
                                                v-bind:class="{ rangecheck: rc_op_diff_baca_0700 }"
                                                :state="validateState($v.op_diff_baca_0700)"
                                                v-model.number="$v.op_diff_baca_0700.$model"
                                                :formatter="format_one_decimal_with_minus"
                                            ></b-form-input>
                                            <b-tooltip v-if="rc_op_diff_baca_0700" target="input_op_diff_baca_0700" triggers="hover">{{ rc_op_diff_baca_0700_message }}</b-tooltip>
                                            <b-tooltip v-if="!rc_op_diff_baca_0700" :show="validateTooltipState('op_diff_baca_0700')" :triggers="'hover'" target="input_op_diff_baca_0700" placement="topleft" custom-class="validation-tooltip">
                                                {{ tooltipMessage("op_diff_baca_0700") }}
                                            </b-tooltip>
                                        </b-col>
                                    </b-row>

                                    <b-row v-if="fdih_status.is_op_penguapan" class="my-1">
                                        <b-col cols="6" class="m-auto">
                                            <div class="label-entry1">
                                                T Air Maks
                                                <feather-icon
                                                    icon="HelpCircleIcon"
                                                    size="12"
                                                    class="bg-primary rounded-circle text-white"
                                                    v-b-tooltip.hover.v-dark
                                                    title="Temperatur Maksimum dari thermometer apung, dinyatakan dalam persepuluhan derajat Celsius."
                                                />
                                            </div>
                                        </b-col>
                                        <b-col cols="4">
                                            <b-form-input
                                                v-on:keydown="onKeyCheck($event)"
                                                id="input_t_max_0700"
                                                size="sm"
                                                class="text-center"
                                                v-on:keyup="checkValidate($event, 't_max_0700')"
                                                v-bind:class="{ rangecheck: rc_t_max_0700 }"
                                                :state="validateState($v.t_max_0700)"
                                                v-model.number="$v.t_max_0700.$model"
                                                :formatter="format_one_decimal"
                                            ></b-form-input>
                                            <b-tooltip v-if="CC.t_max_0700.status" target="input_t_max_0700" :triggers="'hover'" placement="topleft">
                                                {{ CC.t_max_0700.message }}
                                            </b-tooltip>
                                            <b-tooltip v-if="rc_t_max_0700 && !CC.t_max_0700.status" target="input_t_max_0700" triggers="hover">{{ rc_t_max_0700_message }}</b-tooltip>
                                            <b-tooltip v-if="!rc_t_max_0700 && !CC.t_max_0700.status" :show="validateTooltipState('t_max_0700')" :triggers="'hover'" target="input_t_max_0700" placement="topleft" custom-class="validation-tooltip">
                                                {{ tooltipMessage("t_max_0700") }}
                                            </b-tooltip>
                                        </b-col>
                                    </b-row>

                                    <b-row v-if="fdih_status.is_op_penguapan" class="my-1">
                                        <b-col cols="6" class="m-auto">
                                            <div class="label-entry1">
                                                T Air Min
                                                <feather-icon
                                                    icon="HelpCircleIcon"
                                                    size="12"
                                                    class="bg-primary rounded-circle text-white"
                                                    v-b-tooltip.hover.v-dark
                                                    title="Temperatur Minimum dari thermometer apung, dinyatakan dalam persepuluhan derajat Celsius."
                                                />
                                            </div>
                                        </b-col>
                                        <b-col cols="4">
                                            <b-form-input
                                                v-on:keydown="onKeyCheck($event)"
                                                id="input_t_min_0700"
                                                size="sm"
                                                class="text-center"
                                                v-on:keyup="checkValidate($event, 't_min_0700')"
                                                v-bind:class="{ rangecheck: rc_t_min_0700 }"
                                                :state="validateState($v.t_min_0700)"
                                                v-model.number="$v.t_min_0700.$model"
                                                :formatter="format_one_decimal"
                                            ></b-form-input>
                                            <b-tooltip v-if="CC.t_min_0700.status" target="input_t_min_0700" :triggers="'hover'" placement="topleft">
                                                {{ CC.t_min_0700.message }}
                                            </b-tooltip>
                                            <b-tooltip v-if="rc_t_min_0700 && !CC.t_min_0700.status" target="input_t_min_0700" triggers="hover">{{ rc_t_min_0700_message }}</b-tooltip>
                                            <b-tooltip v-if="!rc_t_min_0700 && !CC.t_min_0700.status" :show="validateTooltipState('t_min_0700')" :triggers="'hover'" target="input_t_min_0700" placement="topleft" custom-class="validation-tooltip">
                                                {{ tooltipMessage("t_min_0700") }}
                                            </b-tooltip>
                                        </b-col>
                                    </b-row>

                                    <b-row v-if="fdih_status.is_op_penguapan" class="my-1">
                                        <b-col cols="6" class="m-auto">
                                            <div class="label-entry1">
                                                T Rata Air
                                                <feather-icon
                                                    icon="HelpCircleIcon"
                                                    size="12"
                                                    class="bg-primary rounded-circle text-white"
                                                    v-b-tooltip.hover.v-dark
                                                    title="Temperatur Rata-rata dari Thermometer Apung, diperoleh dari (T Air Max + T Air Min)/2, dinyatakan dalam persepuluhan derajat Celsius."
                                                />
                                            </div>
                                        </b-col>
                                        <b-col cols="4">
                                            <b-form-input size="sm" class="bg-light-success text-center" v-model.number="t_avg_0700" disabled></b-form-input>
                                        </b-col>
                                    </b-row>

                                    <b-row v-if="fdih_status.is_fklim || fdih_status.is_agm1a || fdih_status.is_op_penguapan" class="my-1">
                                        <b-col cols="6" class="m-auto">
                                            <div class="label-entry1">
                                                CH diukur
                                                <feather-icon
                                                    icon="HelpCircleIcon"
                                                    size="12"
                                                    class="bg-primary rounded-circle text-white"
                                                    v-b-tooltip.hover.v-dark
                                                    title="Hasil penakaran curah hujan selama 24 jam yang dibaca pada jam 07.00 WS dinyatakan dalam milimeter"
                                                />
                                            </div>
                                        </b-col>
                                        <b-col cols="4">
                                            <b-form-input
                                                v-on:keydown="onKeyCheck($event)"
                                                id="input_rr_0700"
                                                size="sm"
                                                class="text-center"
                                                v-on:keyup="checkValidate($event, 'rr_0700')"
                                                v-bind:class="{ rangecheck: rc_rr_0700 }"
                                                :state="validateState($v.rr_0700)"
                                                v-model.number="$v.rr_0700.$model"
                                                :formatter="format_one_decimal"
                                            ></b-form-input>
                                            <b-tooltip v-if="rc_rr_0700" target="input_rr_0700" triggers="hover">{{ rc_rr_0700_message }}</b-tooltip>
                                            <b-tooltip v-if="!rc_rr_0700" :show="validateTooltipState('rr_0700')" :triggers="'hover'" target="input_rr_0700" placement="topleft" custom-class="validation-tooltip">
                                                {{ tooltipMessage("rr_0700") }}
                                            </b-tooltip>
                                        </b-col>
                                    </b-row>

                                    <b-row v-if="fdih_status.is_op_penguapan" class="my-1">
                                        <b-col cols="6" class="m-auto">
                                            <div class="label-entry1">
                                                Penguapan
                                                <feather-icon
                                                    icon="HelpCircleIcon"
                                                    size="12"
                                                    class="bg-primary rounded-circle text-white"
                                                    v-b-tooltip.hover.v-dark
                                                    title="
                        Nilai penguapan selama 24 jam, diperoleh dari hasil penjumlahan CH Diukur dengan Selisih Tinggi Air, dinyatakan dalam milimeter"
                                                />
                                            </div>
                                        </b-col>
                                        <b-col cols="4">
                                            <b-form-input size="sm" class="bg-light-success text-center" v-model.number="op_eva_0700" disabled></b-form-input>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>

                            <!-- <b-row class="float-right">
                <b-col cols="12" >
                  <feather-icon 
                    icon="HelpCircleIcon" 
                    size="14" 
                    class="bg-primary rounded-circle text-white float-right " 
                    v-b-tooltip.hover.html="{ title: fdih_op_penguapan_0700 }" 
                    style="margin: 5px auto"
                  />
                  <b-form-checkbox tabindex="-1" class="custom-control-primary float-right" name="check-button" switch v-model="is_op_edit">
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                  
                </b-col>
              </b-row> -->
                        </b-collapse>
                    </b-card>
                </b-col>
                <!-- OP PENGUAPAN end -->
            </b-row>

            <b-row>
                <!-- GUNBELLANI start -->
                <b-col md="6" lg="4">
                    <b-card class="small h-100">
                        <div>
                            <b-row>
                                <b-col cols="10">
                                    <h6 class="mb-1 small font-weight-bolder badge badge-light-dark">GUNBELLANI</h6>
                                </b-col>
                                <b-col cols="1">
                                    <feather-icon v-b-toggle.collapse-b icon="ChevronDownIcon" />
                                </b-col>
                            </b-row>
                            <b-collapse v-if="fdih_status.is_gunbellani" visible id="collapse-b">
                                <b-row>
                                    <b-col cols="12">
                                        <b-row class="mbtm">
                                            <b-col cols="7">
                                                <div class="label-entry1">
                                                    Tinggi Air Sebelum <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Tinggi Air Pembacaan II Kemarin" />
                                                </div>
                                            </b-col>
                                            <b-col cols="5">
                                                <b-form-input v-on:keydown="onKeyCheck($event)" :disabled="!is_edit_gunbellani" size="sm" class="text-center" v-model.number="ha_gunbellani_2_kemarin"></b-form-input>
                                            </b-col>
                                        </b-row>

                                        <b-row class="mbtm">
                                            <b-col cols="7">
                                                <div class="label-entry1">
                                                    Tinggi Air Pembacaan I <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Tinggi Air Awal Tabung Buret " />
                                                </div>
                                            </b-col>
                                            <b-col cols="5">
                                                <b-form-input
                                                    v-on:keydown="onKeyCheck($event)"
                                                    :formatter="format_one_decimal"
                                                    size="sm"
                                                    class="text-center"
                                                    id="input_ha_gunbellani_1"
                                                    v-on:keyup="checkValidate($event, 'ha_gunbellani_1')"
                                                    v-bind:class="{ rangecheck: rc_ha_gunbellani_1 }"
                                                    :state="validateState($v.ha_gunbellani_1)"
                                                    v-model.number="$v.ha_gunbellani_1.$model"
                                                    :disabled="!fdih_status.is_gunbellani"
                                                ></b-form-input>
                                                <!-- <b-form-input
                          :formatter="format_one_decimal"
                          type="number"
                          size="sm"
                          class="text-center"
                          id="input_ha_gunbellani_1"
                          v-on:keyup="checkValidate($event, 'ha_gunbellani_1')"
                          v-bind:class="{ rangecheck: rc_ha_gunbellani_1 }"
                          :state="validateState($v.ha_gunbellani_1)"
                          v-model.number="$v.ha_gunbellani_1.$model"
                        ></b-form-input> -->
                                                <b-tooltip v-if="CC.ha_gunbellani_1.status" target="input_ha_gunbellani_1" :triggers="'hover'" placement="topleft">
                                                    {{ CC.ha_gunbellani_1.message }}
                                                </b-tooltip>
                                                <b-tooltip v-if="rc_ha_gunbellani_1 && !CC.ha_gunbellani_1.status" target="input_ha_gunbellani_1" triggers="hover">{{ rc_ha_gunbellani_1_message }}</b-tooltip>
                                                <b-tooltip
                                                    v-if="!rc_ha_gunbellani_1 && !CC.ha_gunbellani_1.status"
                                                    :show="validateTooltipState('ha_gunbellani_1')"
                                                    :triggers="'hover'"
                                                    target="input_ha_gunbellani_1"
                                                    placement="topleft"
                                                    custom-class="validation-tooltip"
                                                >
                                                    {{ tooltipMessage("ha_gunbellani_1") }}
                                                </b-tooltip>
                                            </b-col>
                                        </b-row>

                                        <b-row class="mbtm">
                                            <b-col cols="7">
                                                <div class="label-entry1">
                                                    Tinggi Air Pembacaan II <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Tinggi Air Tabung Buret Setelah Dibalik" />
                                                </div>
                                            </b-col>
                                            <b-col cols="5">
                                                <b-form-input
                                                    v-on:keydown="onKeyCheck($event)"
                                                    id="input_ha_gunbellani_2"
                                                    size="sm"
                                                    class="text-center"
                                                    v-on:keyup="checkValidate($event, 'ha_gunbellani_2')"
                                                    v-bind:class="{ rangecheck: rc_ha_gunbellani_2 }"
                                                    :state="validateState($v.ha_gunbellani_2)"
                                                    v-model.number="$v.ha_gunbellani_2.$model"
                                                    :formatter="format_one_decimal"
                                                    :disabled="!fdih_status.is_gunbellani"
                                                ></b-form-input>
                                                <b-tooltip v-if="CC.ha_gunbellani_2.status" target="input_ha_gunbellani_2" :triggers="'hover'" placement="topleft">
                                                    {{ CC.ha_gunbellani_2.message }}
                                                </b-tooltip>
                                                <b-tooltip v-if="rc_ha_gunbellani_2 && !CC.ha_gunbellani_2.status" target="input_ha_gunbellani_2" triggers="hover">{{ rc_ha_gunbellani_2_message }}</b-tooltip>
                                                <b-tooltip
                                                    v-if="!rc_ha_gunbellani_2 && !CC.ha_gunbellani_2.status"
                                                    :show="validateTooltipState('ha_gunbellani_2')"
                                                    :triggers="'hover'"
                                                    target="input_ha_gunbellani_2"
                                                    placement="topleft"
                                                    custom-class="validation-tooltip"
                                                >
                                                    {{ tooltipMessage("ha_gunbellani_2") }}
                                                </b-tooltip>
                                            </b-col>
                                        </b-row>

                                        <b-row class="mbtm">
                                            <b-col cols="7">
                                                <div class="label-entry1">
                                                    Selisih Pembacaan <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Selisih Pembacaan I hari ini - Pembacaan II kemarin" />
                                                </div>
                                            </b-col>
                                            <b-col cols="5">
                                                <b-form-input
                                                    id="input_ha_diff_gunbellani"
                                                    size="sm"
                                                    class="text-center bg-light-success"
                                                    v-on:keyup="checkValidate($event, 'ha_diff_gunbellani')"
                                                    v-bind:class="{ rangecheck: rc_ha_diff_gunbellani }"
                                                    v-model.number="ha_diff_gunbellani"
                                                    disabled
                                                ></b-form-input>
                                                <b-tooltip v-if="rc_ha_diff_gunbellani" target="input_ha_diff_gunbellani" triggers="hover">{{ rc_ha_diff_gunbellani_message }}</b-tooltip>
                                            </b-col>
                                        </b-row>

                                        <b-row class="">
                                            <b-col cols="7">
                                                <div class="label-entry1">
                                                    Radiasi Matahari <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Radiasi Matahari selama 24 jam dalam cal / cm2" />
                                                </div>
                                            </b-col>
                                            <b-col cols="5">
                                                <b-form-input size="sm" class="text-center bg-light-success" v-model.number="rad_gunbellani" disabled></b-form-input>
                                            </b-col>
                                        </b-row>

                                        <b-row class="mt-1">
                                            <b-col cols="7" />
                                            <b-col cols="5">
                                                <feather-icon icon="HelpCircleIcon" size="14" class="bg-primary rounded-circle text-white float-right" v-b-tooltip.hover.html="{ title: fdih_gunbellani_0700 }" style="margin: 5px auto;" />
                                                <b-form-checkbox tabindex="-1" class="custom-control-primary float-right" name="check-button" switch v-model="is_edit_gunbellani">
                                                    <span class="switch-icon-left">
                                                        <feather-icon icon="CheckIcon" />
                                                    </span>
                                                    <span class="switch-icon-right">
                                                        <feather-icon icon="XIcon" />
                                                    </span>
                                                </b-form-checkbox>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </b-collapse>
                        </div>
                    </b-card>
                </b-col>
                <!-- GUNBELLANI end -->

                <!-- LAMA PENYINARAN start -->
                <b-col md="6" lg="4">
                    <b-card class="small font-weight-bolder h-100">
                        <b-row>
                            <b-col cols="10">
                                <h6 class="mb-1 small font-weight-bolder badge badge-light-dark">LAMA PENYINARAN</h6>
                            </b-col>
                            <b-col cols="1">
                                <feather-icon v-b-toggle.collapse-b icon="ChevronDownIcon" />
                            </b-col>
                        </b-row>
                        <b-row v-if="fdih_status.is_fklim || fdih_status.is_agm1a">
                            <b-col cols="12">
                                <label v-if="!is_lama_penyinaran_entry" style="color: red;">Form Lamanya Penyinaran Matahari hari sebelumnya belum dientry </label>
                            </b-col>
                        </b-row>
                        <b-collapse v-if="fdih_status.is_fklim || fdih_status.is_agm1a" visible id="collapse-b">
                            <b-row>
                                <b-col cols="12">
                                    <b-row v-if="fdih_status.is_fklim" class="">
                                        <b-col cols="6" class="m-auto">
                                            <div class="label-entry1">
                                                8 Jam
                                                <feather-icon
                                                    icon="HelpCircleIcon"
                                                    size="12"
                                                    class="bg-primary rounded-circle text-white"
                                                    v-b-tooltip.hover.v-dark
                                                    title="Jumlah pembakaran pias terhadap lama penyinaran matahari selama 8 jam (hari sebelumnya)"
                                                />
                                            </div>
                                        </b-col>
                                        <b-col cols="6">
                                            <b-form-input size="sm" class="input-dark-grey text-center" v-model.number="ss_8" disabled></b-form-input>
                                        </b-col>
                                    </b-row>

                                    <b-row v-if="fdih_status.is_agm1a" class="my-1">
                                        <b-col cols="6" class="m-auto">
                                            <div class="label-entry1">
                                                12 Jam
                                                <feather-icon
                                                    icon="HelpCircleIcon"
                                                    size="12"
                                                    class="bg-primary rounded-circle text-white"
                                                    v-b-tooltip.hover.v-dark
                                                    title="Jumlah pembakaran pias terhadap lama penyinaran matahari selama 12 jam (hari sebelumnya)"
                                                />
                                            </div>
                                        </b-col>
                                        <b-col cols="6">
                                            <b-form-input size="sm" class="input-dark-grey text-center" v-model.number="ss_12" disabled></b-form-input>
                                        </b-col>
                                    </b-row>

                                    <b-row class="float-right mt-1">
                                        <b-col cols="12">
                                            <feather-icon icon="HelpCircleIcon" size="14" class="bg-primary rounded-circle text-white float-right" v-b-tooltip.hover.html="{ title: fdih_lama_penyinaran_0700 }" style="margin: 5px auto;" />
                                            <b-form-checkbox tabindex="-1" class="custom-control-primary float-right" name="check-button" switch v-model="is_lm_alat_rusak">
                                                <span class="switch-icon-left">
                                                    <feather-icon icon="CheckIcon" />
                                                </span>
                                                <span class="switch-icon-right">
                                                    <feather-icon icon="XIcon" />
                                                </span>
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-collapse>
                    </b-card>
                </b-col>
                <!-- LAMA PENYINARAN end -->

                <!-- SUHU MINIMUM RUMPUT start -->
                <b-col md="6" lg="4">
                    <b-card class="small font-weight-bolder h-100">
                        <b-row>
                            <b-col cols="10">
                                <h6 class="mb-1 small font-weight-bolder badge badge-light-dark">SUHU MINIMUM RUMPUT</h6>
                            </b-col>
                            <b-col cols="1">
                                <feather-icon v-b-toggle.collapse-b icon="ChevronDownIcon" />
                            </b-col>
                        </b-row>
                        <b-collapse v-if="fdih_status.is_agm1a" visible id="collapse-b">
                            <b-row>
                                <b-col cols="12">
                                    <b-row class="">
                                        <b-col cols="6" class="m-auto">
                                            <div class="label-entry1">
                                                Tmin
                                                <feather-icon
                                                    icon="HelpCircleIcon"
                                                    size="12"
                                                    class="bg-primary rounded-circle text-white"
                                                    v-b-tooltip.hover.v-dark
                                                    title="Temperatur dari thermometer rumput, dinyatakan dalam persepuluhan derajat Celsius."
                                                />
                                            </div>
                                        </b-col>
                                        <b-col cols="6">
                                            <b-form-input
                                                v-on:keydown="onKeyCheck($event)"
                                                :formatter="format_one_decimal"
                                                size="sm"
                                                class="text-center"
                                                id="input_tn_rumput_0700"
                                                v-on:keyup="checkValidate($event, 'tn_rumput_0700')"
                                                v-bind:class="{ rangecheck: rc_tn_rumput_0700 }"
                                                :state="validateState($v.tn_rumput_0700)"
                                                v-model.number="$v.tn_rumput_0700.$model"
                                            ></b-form-input>
                                            <b-tooltip v-if="rc_tn_rumput_0700" target="input_tn_rumput_0700" triggers="hover">{{ rc_tn_rumput_0700_message }}</b-tooltip>
                                            <b-tooltip :show="validateTooltipState('tn_rumput_0700')" :triggers="'hover'" target="input_tn_rumput_0700" placement="topleft" custom-class="validation-tooltip">
                                                {{ tooltipMessage("tn_rumput_0700") }}
                                            </b-tooltip>
                                        </b-col>
                                    </b-row>

                                    <b-row class="my-1">
                                        <b-col cols="6" class="m-auto">
                                            <div class="label-entry1">
                                                Treset
                                                <feather-icon
                                                    icon="HelpCircleIcon"
                                                    size="12"
                                                    class="bg-primary rounded-circle text-white"
                                                    v-b-tooltip.hover.v-dark
                                                    title="Temperatur hasil reset thermometer rumput, dinyatakan dalam persepuluhan derajat Celsius."
                                                />
                                            </div>
                                        </b-col>
                                        <b-col cols="6">
                                            <b-form-input
                                                v-on:keydown="onKeyCheck($event)"
                                                :formatter="format_one_decimal"
                                                size="sm"
                                                class="text-center"
                                                id="input_tn_rumput_reset_0700"
                                                v-on:keyup="checkValidate($event, 'tn_rumput_reset_0700')"
                                                v-bind:class="{ rangecheck: rc_tn_rumput_reset_0700 }"
                                                :state="validateState($v.tn_rumput_reset_0700)"
                                                v-model.number="$v.tn_rumput_reset_0700.$model"
                                            ></b-form-input>
                                            <b-tooltip v-if="rc_tn_rumput_reset_0700" target="input_tn_rumput_reset_0700" triggers="hover">{{ rc_tn_rumput_reset_0700_message }}</b-tooltip>
                                            <b-tooltip :show="validateTooltipState('tn_rumput_reset_0700')" :triggers="'hover'" target="input_tn_rumput_reset_0700" placement="topleft" custom-class="validation-tooltip">
                                                {{ tooltipMessage("tn_rumput_reset_0700") }}
                                            </b-tooltip>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-collapse>
                    </b-card>
                </b-col>
                <!-- SUHU MINIMUM RUMPUT end -->
                <!-- TEKANAN UDARA start
              <b-col md="6" lg="2" >
                  <b-card
                      class="small font-weight-bolder h-100"
                  >
                  <h6 class="mb-1">TEKANAN UDARAg</h6>
                  
                          <b-row>
                              <b-col cols="12">
                                  <b-form-input type="text" size="sm" class="bg-light-secondary"></b-form-input>
                              </b-col>
                          </b-row>
                  
                      
                  </b-card>
              </b-col>
              TEKANAN UDARA end -->
            </b-row>

            <b-row class="mt-1">
                <!-- KEADAAN UDARA start -->
                <b-col md="6" lg="6" class="mb-2">
                    <b-card class="small font-weight-bolder h-100">
                        <b-row>
                            <b-col cols="10">
                                <h6 class="mb-1 small font-weight-bolder badge badge-light-dark">KEADAAN UDARA</h6>
                            </b-col>
                        </b-row>

                        <b-container v-if="fdih_status.is_agm1a" fluid>
                            <b-row class="">
                                <b-col cols="6">
                                    <b-form-group label="Hujan" label-for="select-hujan">
                                        <b-form-select :state="validateState($v.ku_hujan)" v-model="$v.ku_hujan.$model" :options="opt_ku_hujan" class="" size="sm" placeholder="Hujantes"> </b-form-select>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="6">
                                    <b-form-group label="Rambun Tiba-tiba" label-for="select-rambun">
                                        <b-form-select :state="validateState($v.ku_rambun)" v-model="$v.ku_rambun.$model" :options="opt_ku_rambun" class="" size="sm"> </b-form-select>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="6">
                                    <b-form-group label="Pembekuan (Embun Upas)" label-for="select-pembekuan">
                                        <b-form-select :state="validateState($v.ku_pembekuan)" v-model="$v.ku_pembekuan.$model" :options="opt_ku_embun_upas" class="" size="sm"> </b-form-select>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="6">
                                    <b-form-group label="Kabut Debu (Haze)" label-for="select-kabutdebu">
                                        <b-form-select :state="validateState($v.ku_kabut_debu)" v-model="$v.ku_kabut_debu.$model" :options="opt_ku_kabut_debu" class="" size="sm"> </b-form-select>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="6">
                                    <b-form-group label="Embun" label-for="select-embun">
                                        <b-form-select :state="validateState($v.ku_embun)" v-model="$v.ku_embun.$model" :options="opt_ku_embun" class="" size="sm"> </b-form-select>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="6">
                                    <b-form-group label="Badai Debu" label-for="select-badai">
                                        <b-form-select :state="validateState($v.ku_badai_debu)" v-model="$v.ku_badai_debu.$model" :options="opt_ku_badai_debu" class="" size="sm"> </b-form-select>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="6">
                                    <b-form-group label="Kabut" label-for="select-kabut">
                                        <b-form-select :state="validateState($v.ku_kabut)" v-model="$v.ku_kabut.$model" :options="opt_ku_kabut" class="" size="sm"> </b-form-select>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="6">
                                    <b-form-group label="Angin Ribut Kencang " label-for="select-anginribut">
                                        <b-form-select :state="validateState($v.ku_angin_ribut)" v-model="$v.ku_angin_ribut.$model" :options="opt_ku_angin_ribut" class="" size="sm"> </b-form-select>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="6">
                                    <b-form-group label="Udara Buruk Tanpa Rambun" label-for="select-udaraburuk">
                                        <b-form-select :state="validateState($v.ku_tanpa_rambun)" v-model="$v.ku_tanpa_rambun.$model" :options="opt_ku_udara_buruk" class="" size="sm"> </b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-card>
                </b-col>
                <!-- KEADAAN UDARA end -->
                <!-- Catatan start -->
                <b-col md="6" lg="6" class="mb-2">
                    <b-card class="small h-100">
                        <b-container fluid>
                            <b-row>
                                <b-col cols="12">
                                    <b-row v-if="fdih_status.is_agm1a" class="">
                                        <b-col cols="12">
                                            <b-form-group>
                                                <!-- <label style="font-size: 13px; font-weight: bold; color:#333333">Keadaan Cuaca Waktu Peramatan </label> -->
                                                <h6 class="mb-1 small font-weight-bolder badge badge-light-dark">KEADAAN CUACA WAKTU PERAMATAN</h6>
                                                <b-form-select :state="validateState($v.co_cu_0700)" v-model="$v.co_cu_0700.$model" :options="opt_keadaan_cuaca_peramatan" size="sm"> </b-form-select>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-row v-if="fdih_status.is_fklim" class="my-1">
                                        <b-col cols="4" class="m-auto">
                                            <div class="label-entry1">
                                                <h6 class="mb-1 small font-weight-bolder badge badge-light-dark">EVENT</h6>
                                                <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Gejala cuaca yang dapat diamati pada stasiun" />
                                            </div>
                                        </b-col>
                                        <b-col cols="8">
                                            <b-form-input v-model="cu_khusus" type="text" size="sm" class=""></b-form-input>
                                        </b-col>
                                    </b-row>

                                    <b-row v-if="fdih_status.is_agm1a" class="my-1">
                                        <b-col cols="4">
                                            <div class="label-entry1">
                                                <!-- Catatan AGM 1-a -->
                                                <h6 class="mb-1 small font-weight-bolder badge badge-light-dark">CATATAN AGM 1-A</h6>
                                                <feather-icon
                                                    icon="HelpCircleIcon"
                                                    size="12"
                                                    class="bg-primary rounded-circle text-white"
                                                    v-b-tooltip.hover.v-dark
                                                    title="Catatan mengenai jam terjadinya dan lama kejadian serta 
pengaruhnya (jika ada) atas tanaman-tanaman"
                                                />
                                            </div>
                                        </b-col>
                                        <b-col cols="8">
                                            <b-form-textarea v-model="cat_agm1a" class="border border-2 border border-primary" size="sm"></b-form-textarea>
                                        </b-col>
                                    </b-row>

                                    <b-row v-if="fdih_status.is_gunbellani" class="my-1">
                                        <b-col cols="4">
                                            <div class="label-entry1">
                                                <!-- Catatan GunBellani -->
                                                <h6 class="mb-1 small font-weight-bolder badge badge-light-dark">CATATAN GUNBELLANI</h6>
                                                <feather-icon
                                                    icon="HelpCircleIcon"
                                                    size="12"
                                                    class="bg-primary rounded-circle text-white"
                                                    v-b-tooltip.hover.v-dark
                                                    title="Catatan hasil pengukuran intensitas Radiasi Matahari menggunakan GunBellani (jika ada)"
                                                />
                                            </div>
                                        </b-col>
                                        <b-col cols="8">
                                            <b-form-textarea v-model="cat_gunbellani" size="sm" class="border border-2 border border-primary"></b-form-textarea>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-card>
                </b-col>
                <!-- Catatan end -->
            </b-row>

            <!-- button Action-->
            <b-row>
                <b-col cols="12">
                    <b-card>
                        <b-row class="float-right">
                            <div>
                                <b-button @click.prevent="cancelForm" variant="danger" class="mr-2" size="sm">Cancel</b-button>
                                <!-- <b-button v-if="this.t_data_status == 'update'" @click.prevent="validationForm" variant="warning" class="mr-2" size="sm" :disabled="!roles.isRoleEdit">Edit</b-button>
                <b-button v-if="this.t_data_status == 'new'" @click.prevent="validationForm" variant="primary" class="mr-2" size="sm" :disabled="!roles.isRoleAdd">Submit</b-button> -->
                                <b-button @click.prevent="validationForm" variant="primary" class="mr-2" size="sm">Submit</b-button>
                            </div>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </b-form>
        <!-- </validation-observer> -->
        <b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay>
    </div>
</template>
<script>
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from "vue-ripple-directive";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import Service from "@/api/fdihservice";
import Helper from "@/helper/helper";
import qc_helper from "@/api/qc_helper";
import Params from "@/attr/metadata/params";
import RC_helper from "@/validators/RC_helper";
import { VBTooltip, BTooltip, BCard, BCardHeader, BContainer, BRow, BCol, BFormSelect, BForm, BButton, BFormGroup, BCollapse, BFormDatepicker, BIcon, BFormInput, BFormTextarea, VBToggle, BOverlay, BPopover, BFormCheckbox } from "bootstrap-vue";
// import {
//   required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
// } from '@validations'
import { fdih_1c2m_0700, fdih_4m_0700, fdih_7m_0700, fdih_10m_0700, fdih_lama_penyinaran_0700, fdih_op_penguapan_0700, fdih_counter_sebelum_0700, fdih_gunbellani_0700 } from "./fdih_tooltip_content_0700";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { validationMixin } from "vuelidate";
import { required, minLength, between } from "vuelidate/lib/validators";
import {
    GC_FDIH_PS_0700_TBK,
    GC_FDIH_PS_0700_TBB,
    GC_FDIH_0700_ANGIN_COUNTER_BACA,
    GC_FDIH_0700_ANGIN_ARAH,
    GC_FDIH_0700_ANGIN_KECEPATAN,
    GC_FDIH_0700_OP_SELISIH,
    GC_FDIH_0700_OP_T_AIR_MAX,
    GC_FDIH_0700_OP_CH_DIUKUR,
    GC_FDIH_0700_OP_T_AIR_MIN,
    GC_FDIH_0700_GUNBELLANI1,
    GC_FDIH_0700_GUNBELLANI2,
    GC_FDIH_0700_HA_DIFF_GUNBELLANI,
    GC_FDIH_0700_SUHU_RUMPUT,
    GC_FDIH_0700_SUHU_RUMPUT_TRESET,
    RC_FDIH_ROLES,
    RC_FDIH,
    GC_FDIH,
} from "@/validators/fdihvalidator";

export default {
    components: {
        VBTooltip,
        BCardActions,
        BCard,
        BCardHeader,
        BContainer,
        BRow,
        BCol,
        BFormSelect,
        BForm,
        BButton,
        BFormGroup,
        BCollapse,
        BFormDatepicker,
        BFormInput,
        BFormTextarea,
        BIcon,
        ToastificationContent,
        BTooltip,
        BOverlay,
        BPopover,
        BFormCheckbox,
        // ValidationProvider,
        // ValidationObserver,
    },
    directives: {
        "b-toggle": VBToggle,
        "b-tooltip": VBTooltip,
        Ripple,
    },
    props: ["data_input", "t_station", "t_station_id", "t_wmoid", "t_observer", "t_time", "t_date", "t_all_data", "t_data_existing", "t_data_requirement", "t_path", "t_id_form", "t_data_status", "roles", "t_bmkgentry", "d_station", "t_qc_histories"],
    data() {
        return {
            RC_helper_gunbellani: { ...RC_helper },
            RC_helper_fklim: { ...RC_helper },
            RC_helper_agm1a: { ...RC_helper },
            RC_helper_op: { ...RC_helper },
            RC_helper_iklimmikro: { ...RC_helper },
            flagdata_fklim: null,
            flagdata_iklim_mikro: null,
            flagdata_agm1a: null,
            flagdata_op_penguapan: null,
            flagdata_gunbellani: null,
            showLoading: false,
            keadaan_cuaca: "",
            keadaan_udara: "",
            tbk_1c2m_0700: "",
            tbk_4m_0700: "",
            tbk_7m_0700: "",
            tbk_10m_0700: "",
            tbb_1c2m_0700: "",
            tbb_4m_0700: "",
            tbb_7m_0700: "",
            tbb_10m_0700: "",
            rh_1c2m_0700: "",
            rh_4m_0700: "",
            rh_7m_0700: "",
            rh_10m_0700: "",
            counter_sebelum_0c5_0700: "",
            counter_sebelum_4_0700: "",
            counter_sebelum_7_0700: "",
            counter_sebelum_10_0700: "",
            counter_0c5_0700: "",
            counter_4_0700: "",
            counter_7_0700: "",
            counter_10_0700: "",
            CC: {
                tbk_1c2m_0700: { status: false, message: "" },
                tbk_4m_0700: { status: false, message: "" },
                tbk_7m_0700: { status: false, message: "" },
                tbk_10m_0700: { status: false, message: "" },
                tbb_1c2m_0700: { status: false, message: "" },
                tbb_4m_0700: { status: false, message: "" },
                tbb_7m_0700: { status: false, message: "" },
                tbb_10m_0700: { status: false, message: "" },
                counter_0c5_0700: { status: false, message: "" },
                counter_4_0700: { status: false, message: "" },
                counter_7_0700: { status: false, message: "" },
                counter_10_0700: { status: false, message: "" },
                t_min_0700: { status: false, message: "" },
                t_max_0700: { status: false, message: "" },
                ha_gunbellani_1: { status: false, message: "" },
                ha_gunbellani_2: { status: false, message: "" },
            },
            ws_avg_0c5_0700: "",
            ws_avg_4_0700: "",
            ws_avg_7_0700: "",
            ws_avg_10_0700: "",
            wd_0700: "",
            ws_0700: "",
            op_diff_baca_0700: "",
            t_max_0700: "",
            t_min_0700: "",
            t_avg_0700: "",
            rr_0700: "",
            op_eva_0700: "",
            ha_gunbellani_2_kemarin: "",
            ha_gunbellani_1: "",
            ha_gunbellani_2: "",
            ha_diff_gunbellani: "",
            rad_gunbellani: "",
            ss_8: "",
            ss_12: "",
            tn_rumput_0700: "",
            tn_rumput_reset_0700: "",
            co_cu_0700: "-",
            cu_khusus: "",
            cat_agm1a: "",
            cat_gunbellani: "",
            ku_hujan: "-",
            ku_salju: "-",
            ku_badai_debu: "-",
            ku_pembekuan: "-",
            ku_embun: "-",
            ku_kabut: "-",
            ku_tanpa_rambun: "-",
            ku_rambun: "-",
            ku_kabut_debu: "-",
            ku_angin_ribut: "-",
            opt_keadaan_cuaca_peramatan: [
                { value: "-", text: "-" },
                { value: 0, text: "0 - Langit tak berawan / sedikit berawan" },
                { value: 1, text: "1 - Perawanan berganti-ganti / berubah-ubah" },
                { value: 2, text: "2 - Langit berawan seluruhnya" },
                { value: 3, text: "3 - Badai debu" },
                { value: 4, text: "4 - Kabut/kabur yang tebal" },
                { value: 5, text: "5 -  Hujan rintik-rintik" },
                { value: 6, text: "6 - Hujan" },
                { value: 7, text: "7 - Salju" },
                { value: 8, text: "8 - Hujan tiba-tiba " },
                { value: 9, text: "9 - Udara buruk dengan / tanpa hujan" },
            ],
            opt_ku_hujan: [
                { value: "-", text: "-" },
                { value: "ringan", text: "ringan - Kurang dari 13mm /jam" },
                { value: "sedang", text: "sedang - 13mm /jam sampai 38mm /jam " },
                { value: "lebat", text: "lebat - Lebih dari 38mm /jam" },
            ],
            opt_ku_salju: [
                { value: "-", text: "-" },
                { value: "tebal", text: "tebal- Tetes-tetes air besar yang jatuh" },
            ],
            opt_ku_badai_debu: [
                { value: "-", text: "-" },
                { value: "ringan", text: "ringan- Penglihatan kurang dari 4 km" },
                { value: "sedang", text: "sedang- Penglihatan kurang dari 2 km" },
                { value: "tebal", text: "tebal - Penglihatan kurang dari 1 km" },
            ],
            opt_ku_embun: [
                { value: "-", text: "-" },
                { value: "ringan", text: "ringan - Tetes-tetes air kecil" },
                { value: "sedang", text: "sedang - Tetes-tetes air besar" },
            ],
            opt_ku_embun_upas: [
                { value: "-", text: "-" },
                { value: "ringan", text: "ringan - Sedikit memengaruhi tumbuh-tumbuhan" },
                { value: "sedang", text: "sedang - Sebagian tumbuh-tumbuhan mati" },
                { value: "tebal", text: "tebal - Sebagian besar tumbuh-tumbuhan mati" },
            ],
            opt_ku_udara_buruk: [
                { value: "-", text: "-" },
                { value: "ringan", text: "ringan - Guntur dan kilat kadang-kadang" },
                { value: "sedang", text: "sedang - Sering terjadi guntur dan kilat dengan hujan sedang,kec. angin 30-70 km/jam" },
                { value: "kuat", text: "kuat - Guntur dan kilat terus menerus dengan hujan lebat,kec. angin di atas 70 km/jam" },
            ],
            opt_ku_rambun: [
                { value: "-", text: "-" },
                { value: "ringan", text: "ringan - Butir-butir es kecil dan jarang sering bercampur dengan hujan" },
                { value: "sedang", text: "sedang - Jika meleleh dapat menghasilkan genangan air seperti pada hujan sedang" },
                { value: "lebat", text: "lebat - Palawija rusak karenanya daun-daun terlepas dari pohonnya dapat memecahkan kaca" },
            ],
            opt_ku_kabut: [
                { value: "-", text: "-" },
                { value: "ringan", text: "ringan - Penglihatan kurang dari 2 km" },
                { value: "sedang", text: "sedang - Penglihatan kurang dari 1 km" },
                { value: "tebal", text: "tebal - Penglihatan kurang dari 200 m" },
            ],
            opt_ku_kabut_debu: [
                { value: "-", text: "-" },
                { value: "ringan", text: "ringan - Penglihatan kurang dari 4 km" },
                { value: "sedang", text: "sedang - Penglihatan kurang dari 2 km" },
                { value: "tebal", text: "tebal - Penglihatan kurang dari 1 km" },
            ],
            opt_ku_angin_ribut: [
                { value: "-", text: "-" },
                { value: "lemah", text: "lemah - Kencang : kecepatan 30-50 km/jam" },
                { value: "sedang", text: "sedang - Amat kencang : kecepatan diatas 50 km/jam" },
            ],
            rc_tbk_1c2m_0700: false,
            rc_tbk_1c2m_0700_message: "",
            rc_tbb_1c2m_0700: false,
            rc_tbb_1c2m_0700_message: "",
            rc_tbk_4m_0700: false,
            rc_tbk_4m_0700_message: "",
            rc_tbb_4m_0700: false,
            rc_tbb_4m_0700_message: "",
            rc_tbk_7m_0700: false,
            rc_tbk_7m_0700_message: "",
            rc_tbb_7m_0700: false,
            rc_tbb_7m_0700_message: "",
            rc_tbk_10m_0700: false,
            rc_tbk_10m_0700_message: "",
            rc_tbb_10m_0700: false,
            rc_tbb_10m_0700_message: "",

            rc_ws_0700: false,
            rc_ws_0700_message: "",
            rc_op_diff_baca_0700: false,
            rc_op_diff_baca_0700_message: "",
            rc_t_max_0700: false,
            rc_t_max_0700_message: "",
            rc_t_min_0700: false,
            rc_t_min_0700_message: "",
            rc_rr_0700: false,
            rc_rr_0700_message: "",
            rc_ha_gunbellani_1: false,
            rc_ha_gunbellani_1_message: "",
            rc_ha_gunbellani_2: false,
            rc_ha_gunbellani_2_message: "",
            rc_ha_diff_gunbellani: false,
            rc_ha_diff_gunbellani_message: "",

            rc_tn_rumput_0700: false,
            rc_tn_rumput_0700_message: "",
            rc_tn_rumput_reset_0700: false,
            rc_tn_rumput_reset_0700_message: "",

            fdih_status: {
                is_fklim: true,
                is_iklim_mikro: true,
                is_agm1a: true,
                is_agm1b: true,
                is_suhu_tanah: true,
                is_psychrometer_assman: true,
                is_op_penguapan: true,
                is_piche_penguapan: true,
                is_lysimeter: true,
                is_gunbellani: true,
            },

            fdih_1c2m_0700,
            fdih_4m_0700,
            fdih_7m_0700,
            fdih_10m_0700,
            fdih_lama_penyinaran_0700,
            fdih_op_penguapan_0700,
            fdih_counter_sebelum_0700,
            fdih_gunbellani_0700,

            is_lama_penyinaran_entry: false,
            is_lm_alat_rusak: false,

            is_edit_op_penguapan: false,
            // is_op_edit: false,

            is_counter_edit: false,

            is_edit_gunbellani: false,

            DATA_RC: {},
            LIST_QC_FIX: {},
            LIST_QC_NOTIFICATION_FIX: {},

            qc_histories: {
                agm1a: null,
                fklim: null,
                gunbellani: null,
                oppenguapan: null,
            },
        };
    },
    mixins: [validationMixin],
    validations: {
        tbk_1c2m_0700: {
            required,
            GC_FDIH_PS_0700_TBK,
            consistency(val) {
                if (this.tbb_1c2m_0700 && this.tbb_1c2m_0700 != 9999 && val != 9999) {
                    let result = val >= this.tbb_1c2m_0700;
                    this.CC.tbk_1c2m_0700.message = "Suhu Bola Kering >= Suhu bola Basah";
                    this.CC.tbk_1c2m_0700.status = !result;
                    return result;
                }
                this.CC.tbk_1c2m_0700.message = "";
                this.CC.tbk_1c2m_0700.status = false;
                return true;
            },
        },
        tbk_4m_0700: {
            required,
            GC_FDIH_PS_0700_TBK,
            consistency(val) {
                if (this.tbb_4m_0700 && this.tbb_4m_0700 != 9999 && val != 9999) {
                    let result = val >= this.tbb_4m_0700;
                    this.CC.tbk_4m_0700.message = "Suhu Bola Kering >= Suhu bola Basah";
                    this.CC.tbk_4m_0700.status = !result;
                    return result;
                }
                this.CC.tbk_4m_0700.message = "";
                this.CC.tbk_4m_0700.status = false;
                return true;
            },
        },
        tbk_7m_0700: {
            required,
            GC_FDIH_PS_0700_TBK,
            consistency(val) {
                if (this.tbb_7m_0700 && this.tbb_7m_0700 != 9999 && val != 9999) {
                    let result = val >= this.tbb_7m_0700;
                    this.CC.tbk_7m_0700.message = "Suhu Bola Kering >= Suhu bola Basah";
                    this.CC.tbk_7m_0700.status = !result;
                    return result;
                }
                this.CC.tbk_7m_0700.message = "";
                this.CC.tbk_7m_0700.status = false;
                return true;
            },
        },
        tbk_10m_0700: {
            required,
            GC_FDIH_PS_0700_TBK,
            consistency(val) {
                if (this.tbb_10m_0700 && this.tbb_10m_0700 != 9999 && val != 9999) {
                    let result = val >= this.tbb_10m_0700;
                    this.CC.tbk_10m_0700.message = "Suhu Bola Kering >= Suhu bola Basah";
                    this.CC.tbk_10m_0700.status = !result;
                    return result;
                }
                this.CC.tbk_10m_0700.message = "";
                this.CC.tbk_10m_0700.status = false;
                return true;
            },
        },
        tbb_1c2m_0700: {
            required,
            GC_FDIH_PS_0700_TBB,
            consistency(val) {
                if (this.tbk_1c2m_0700 && this.tbk_1c2m_0700 != 9999 && val != 9999) {
                    let result = val <= this.tbk_1c2m_0700;
                    this.CC.tbb_1c2m_0700.message = "Suhu Bola Kering >= Suhu bola Basah";
                    this.CC.tbb_1c2m_0700.status = !result;
                    return result;
                }
                this.CC.tbb_1c2m_0700.message = "";
                this.CC.tbb_1c2m_0700.status = false;
                return true;
            },
        },
        tbb_4m_0700: {
            required,
            GC_FDIH_PS_0700_TBB,
            consistency(val) {
                if (this.tbk_4m_0700 && this.tbk_4m_0700 != 9999 && val != 9999) {
                    let result = val <= this.tbk_4m_0700;
                    this.CC.tbb_4m_0700.message = "Suhu Bola Kering >= Suhu bola Basah";
                    this.CC.tbb_4m_0700.status = !result;
                    return result;
                }
                this.CC.tbb_4m_0700.message = "";
                this.CC.tbb_4m_0700.status = false;
                return true;
            },
        },
        tbb_7m_0700: {
            required,
            GC_FDIH_PS_0700_TBB,
            consistency(val) {
                if (this.tbk_7m_0700 && this.tbk_7m_0700 != 9999 && val != 9999) {
                    let result = val <= this.tbk_7m_0700;
                    this.CC.tbb_7m_0700.message = "Suhu Bola Kering >= Suhu bola Basah";
                    this.CC.tbb_7m_0700.status = !result;
                    return result;
                }
                this.CC.tbb_7m_0700.message = "";
                this.CC.tbb_7m_0700.status = false;
                return true;
            },
        },
        tbb_10m_0700: {
            required,
            GC_FDIH_PS_0700_TBB,
            consistency(val) {
                if (this.tbk_10m_0700 && this.tbk_10m_0700 != 9999 && val != 9999) {
                    let result = val <= this.tbk_10m_0700;
                    this.CC.tbb_10m_0700.message = "Suhu Bola Kering >= Suhu bola Basah";
                    this.CC.tbb_10m_0700.status = !result;
                    return result;
                }
                this.CC.tbb_10m_0700.message = "";
                this.CC.tbb_10m_0700.status = false;
                return true;
            },
        },
        counter_0c5_0700: {
            required,
            GC_FDIH_0700_ANGIN_COUNTER_BACA,
            consistency(val) {
                if (this.counter_sebelum_0c5_0700 && this.counter_sebelum_0c5_0700 != 9999 && val != 9999) {
                    let result = val >= this.counter_sebelum_0c5_0700;
                    this.CC.counter_0c5_0700.message = "Counter Dibaca >= Counter Sebelum";
                    this.CC.counter_0c5_0700.status = !result;
                    return result;
                }
                this.CC.counter_0c5_0700.message = "";
                this.CC.counter_0c5_0700.status = false;
                return true;
            },
        },
        counter_4_0700: {
            required,
            GC_FDIH_0700_ANGIN_COUNTER_BACA,
            consistency(val) {
                if (this.counter_sebelum_4_0700 && this.counter_sebelum_4_0700 != 9999 && val != 9999) {
                    let result = val >= this.counter_sebelum_4_0700;
                    this.CC.counter_4_0700.message = "Counter Dibaca >= Counter Sebelum";
                    this.CC.counter_4_0700.status = !result;
                    return result;
                }
                this.CC.counter_4_0700.message = "";
                this.CC.counter_4_0700.status = false;
                return true;
            },
        },
        counter_7_0700: {
            required,
            GC_FDIH_0700_ANGIN_COUNTER_BACA,
            consistency(val) {
                if (this.counter_sebelum_7_0700 && this.counter_sebelum_7_0700 != 9999 && val != 9999) {
                    let result = val >= this.counter_sebelum_7_0700;
                    this.CC.counter_7_0700.message = "Counter Dibaca >= Counter Sebelum";
                    this.CC.counter_7_0700.status = !result;
                    return result;
                }
                this.CC.counter_7_0700.message = "";
                this.CC.counter_7_0700.status = false;
                return true;
            },
        },
        counter_10_0700: {
            required,
            GC_FDIH_0700_ANGIN_COUNTER_BACA,
            consistency(val) {
                if (this.counter_sebelum_10_0700 && this.counter_sebelum_10_0700 != null && val != 9999) {
                    let result = val >= this.counter_sebelum_10_0700;
                    this.CC.counter_10_0700.message = "Counter Dibaca >= Counter Sebelum";
                    this.CC.counter_10_0700.status = !result;
                    return result;
                }
                this.CC.counter_10_0700.message = "";
                this.CC.counter_10_0700.status = false;
                return true;
            },
        },
        wd_0700: { required, GC_FDIH_0700_ANGIN_ARAH },
        ws_0700: { required, GC_FDIH_0700_ANGIN_KECEPATAN },

        op_diff_baca_0700: { required, GC_FDIH_0700_OP_SELISIH },
        t_max_0700: {
            required,
            GC_FDIH_0700_OP_T_AIR_MAX,
            consistency(val) {
                if (val != 9999 && this.t_min_0700 && this.t_min_0700 != 9999) {
                    let result = val >= this.t_min_0700;
                    this.CC.t_max_0700.message = "Suhu Air Maksimum >= Suhu Air Minimum";
                    this.CC.t_max_0700.status = !result;
                    return result;
                }
                this.CC.t_max_0700.message = "";
                this.CC.t_max_0700.status = false;
                return true;
            },
        },
        t_min_0700: {
            required,
            GC_FDIH_0700_OP_T_AIR_MIN,
            consistency(val) {
                if (val != 9999 && this.t_max_0700 && this.t_max_0700 != 9999) {
                    let result = val <= this.t_max_0700;
                    this.CC.t_min_0700.message = "Suhu Air Maksimum >= Suhu Air Minimum";
                    this.CC.t_min_0700.status = !result;
                    return result;
                }
                this.CC.t_min_0700.message = "";
                this.CC.t_min_0700.status = false;
                return true;
            },
        },
        rr_0700: { required, GC_FDIH_0700_OP_CH_DIUKUR },

        ha_gunbellani_1: {
            required,
            GC_FDIH_0700_GUNBELLANI1,
            consistency(val) {
                if (val != 9999 && this.ha_gunbellani_2 && this.ha_gunbellani_2 != 9999) {
                    let result = val >= this.ha_gunbellani_2;
                    this.CC.ha_gunbellani_1.message = "Tinggi Air Pembacaan 1 >= Tinggi Air Pembacaan 2";
                    this.CC.ha_gunbellani_1.status = !result;
                    return result;
                }
                this.CC.ha_gunbellani_1.message = "";
                this.CC.ha_gunbellani_1.status = false;
                return true;
            },
        },
        ha_gunbellani_2: {
            required,
            GC_FDIH_0700_GUNBELLANI2,
            consistency(val) {
                if (val != 9999 && this.ha_gunbellani_1 && this.ha_gunbellani_1 != 9999) {
                    let result = this.ha_gunbellani_1 >= val;
                    this.CC.ha_gunbellani_2.message = "Tinggi Air Pembacaan 1 >= Tinggi Air Pembacaan 2";
                    this.CC.ha_gunbellani_2.status = !result;
                    return result;
                }
                this.CC.ha_gunbellani_2.message = "";
                this.CC.ha_gunbellani_2.status = false;
                return true;
            },
        },
        tn_rumput_0700: { GC_FDIH_0700_SUHU_RUMPUT },
        tn_rumput_reset_0700: { GC_FDIH_0700_SUHU_RUMPUT_TRESET },
        co_cu_0700: { required },
        ku_hujan: { required },
        ku_salju: { required },
        ku_badai_debu: { required },
        ku_pembekuan: { required },
        ku_embun: { required },
        ku_kabut: { required },
        ku_tanpa_rambun: { required },
        ku_rambun: { required },
        ku_kabut_debu: { required },
        ku_angin_ribut: { required },
    },
    methods: {
        initialize() {
            // console.log("initialize");
            this.setQCData();
            this.is_lm_alat_rusak = false;
            this.is_op_edit = false;
            this.is_edit_gunbellani = false;
            if (this.t_bmkgentry) {
                (this.fdih_status.is_fklim = this.t_bmkgentry.is_fklim),
                    (this.fdih_status.is_iklim_mikro = this.t_bmkgentry.is_iklim_mikro),
                    (this.fdih_status.is_agm1a = this.t_bmkgentry.is_agm1a),
                    (this.fdih_status.is_agm1b = this.t_bmkgentry.is_agm1b),
                    (this.fdih_status.is_suhu_tanah = this.t_bmkgentry.is_suhu_tanah),
                    (this.fdih_status.is_psychrometer_assman = this.t_bmkgentry.is_psychrometer_assman),
                    (this.fdih_status.is_op_penguapan = this.t_bmkgentry.is_op_penguapan),
                    (this.fdih_status.is_piche_penguapan = this.t_bmkgentry.is_piche_penguapan),
                    (this.fdih_status.is_lysimeter = this.t_bmkgentry.is_lysimeter),
                    (this.fdih_status.is_gunbellani = this.t_bmkgentry.is_gunbellani);
            } else {
                (this.fdih_status.is_fklim = true),
                    (this.fdih_status.is_iklim_mikro = true),
                    (this.fdih_status.is_agm1a = true),
                    (this.fdih_status.is_agm1b = true),
                    (this.fdih_status.is_suhu_tanah = true),
                    (this.fdih_status.is_psychrometer_assman = true),
                    (this.fdih_status.is_op_penguapan = true),
                    (this.fdih_status.is_piche_penguapan = true),
                    (this.fdih_status.is_lysimeter = true),
                    (this.fdih_status.is_gunbellani = true);
            }
            // console.log('initialize', this.t_bmkgentry, ' : ', this.fdih_status)
        },

        onKeyCheck(event) {
            if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                event.preventDefault();
            }
        },

        decimalWithDot(event) {
            if (event.key == ",") {
                return event.preventDefault();
            }
        },

        format_one_decimal_with_minus(e) {
            if (e == "-") {
                if (e.length > 1) {
                    return e.charAt(0);
                }
                return e;
            }
            if (Math.round(e * 10) / 10 != e) {
                let text = String(e);
                let filter = text.substring(0, text.length - 1);
                // console.log("filter", filter);
                return filter;
            } else {
                if (Math.floor(e) === e) {
                    // console.log("filter e", e);
                    return e;
                } else {
                    // console.log("filter else", e);
                    if (e.toString().indexOf(".") > 0) {
                        let count = e.toString().split(".")[1].length || 0;
                        return count > 1 ? parseFloat(e).toFixed(1) : e;
                    } else {
                        return e;
                    }
                }
                return e;
            }
        },
        format_one_decimal(e) {
            if (Math.round(e * 10) / 10 != e) {
                let text = String(e);
                let filter = text.substring(0, text.length - 1);
                // console.log("filter", filter);
                return filter;
            } else {
                if (Math.floor(e) === e) {
                    // console.log("filter e", e);
                    return e;
                } else {
                    // console.log("filter else", e);
                    if (e.toString().indexOf(".") > 0) {
                        let count = e.toString().split(".")[1].length || 0;
                        return count > 1 ? parseFloat(e).toFixed(1) : e;
                    } else {
                        return e;
                    }
                }
                return e;
            }
        },
        format_two_decimal(e, event) {
            if (Math.round(e * 100) / 100 != e) {
                let text = String(e);
                let filter = text.substring(0, text.length - 1);
                return filter;
            } else {
                if (Math.floor(e) === e) {
                    return e;
                } else {
                    if (e.toString().indexOf(".") > 0) {
                        let count = e.toString().split(".")[1].length || 0;
                        return count > 2 ? parseFloat(e).toFixed(2) : e;
                    } else {
                        return e;
                    }
                }

                return e;
            }
        },
        isNumber(evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            }
            if (evt.key == "." || evt.key == ",") {
                return evt.preventDefault();
            } else {
                return true;
            }
        },
        checkValidate: function (event, type) {
            let value = event.target.value;

            switch (type) {
                case "tbk_1c2m_0700":
                    var raw = this.RC_helper_agm1a.check(value, "tbk_1c2m_0700");
                    var gc = GC_FDIH_PS_0700_TBK(value);
                    this.rc_tbk_1c2m_0700 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbk_1c2m_0700_message = raw.message;
                    this.setDataRC(type, this.rc_tbk_1c2m_0700, this.rc_tbk_1c2m_0700_message, raw.roles);
                    break;
                case "tbb_1c2m_0700":
                    var raw = this.RC_helper_agm1a.check(value, "tbb_1c2m_0700");
                    var gc = GC_FDIH_PS_0700_TBB(value);
                    this.rc_tbb_1c2m_0700 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbb_1c2m_0700_message = raw.message;
                    this.setDataRC(type, this.rc_tbb_1c2m_0700, this.rc_tbb_1c2m_0700_message, raw.roles);
                    break;

                case "ws_0700":
                    var raw = this.RC_helper_agm1a.check(value, "ws_0700"); //RC_FDIH(value, "ws_0700");
                    var gc = GC_FDIH_0700_ANGIN_KECEPATAN(value);
                    this.rc_ws_0700 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_ws_0700_message = raw.message;
                    this.setDataRC(type, this.rc_ws_0700, this.rc_ws_0700_message, raw.roles);
                    break;
                case "op_diff_baca_0700":
                    var raw = this.RC_helper_op.check(value, "op_diff_baca_0700"); //RC_FDIH(value, "op_diff_baca_0700");
                    var gc = GC_FDIH_0700_OP_SELISIH(value);
                    this.rc_op_diff_baca_0700 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_op_diff_baca_0700_message = raw.message;
                    this.setDataRC(type, this.rc_op_diff_baca_0700, this.rc_op_diff_baca_0700_message, raw.roles);
                    break;
                case "t_max_0700":
                    var raw = this.RC_helper_op.check(value, "t_air_max_0700"); //RC_FDIH(value, "t_max_0700");
                    var gc = GC_FDIH_0700_OP_T_AIR_MAX(value);
                    this.rc_t_max_0700 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_t_max_0700_message = raw.message;
                    this.setDataRC(type, this.rc_t_max_0700, this.rc_t_max_0700_message, raw.roles);
                    break;
                case "t_min_0700":
                    var raw = this.RC_helper_op.check(value, "t_air_min_0700"); //RC_FDIH(value, "t_min_0700");
                    var gc = GC_FDIH_0700_OP_T_AIR_MIN(value);
                    this.rc_t_min_0700 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_t_min_0700_message = raw.message;
                    this.setDataRC(type, this.rc_t_min_0700, this.rc_t_min_0700_message, raw.roles);
                    break;
                case "rr_0700":
                    var raw = this.RC_helper_op.check(value, "rr_0700"); //RC_FDIH(value, "rr_0700");
                    var gc = GC_FDIH_0700_OP_CH_DIUKUR(value);
                    this.rc_rr_0700 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_rr_0700_message = raw.message;
                    this.setDataRC(type, this.rc_rr_0700, this.rc_rr_0700_message, raw.roles);
                    break;
                //tes
                case "tn_rumput_0700":
                    var raw = this.RC_helper_agm1a.check(value, "tn_rumput_0700"); //RC_FDIH(value, "tn_rumput_0700");
                    var gc = GC_FDIH_0700_SUHU_RUMPUT(value);
                    this.rc_tn_rumput_0700 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tn_rumput_0700_message = raw.message;
                    this.setDataRC(type, this.rc_tn_rumput_0700, this.rc_tn_rumput_0700_message, raw.roles);
                    break;

                case "tn_rumput_reset_0700":
                    var raw = this.RC_helper_agm1a.check(value, "tn_rumput_reset_0700"); //RC_FDIH(value, "tn_rumput_reset_0700");
                    var gc = GC_FDIH_0700_SUHU_RUMPUT_TRESET(value);
                    this.rc_tn_rumput_reset_0700 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tn_rumput_reset_0700_message = raw.message;
                    this.setDataRC(type, this.rc_tn_rumput_reset_0700, this.rc_tn_rumput_reset_0700_message, raw.roles);
                    break;

                case "ha_gunbellani_1":
                    var raw = this.RC_helper_gunbellani.check(value, "h_air_gunbellani_1"); //RC_FDIH(value, "ha_gunbellani_1");
                    var gc = GC_FDIH_0700_GUNBELLANI1(value);
                    this.rc_ha_gunbellani_1 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_ha_gunbellani_1_message = raw.message;
                    this.setDataRC(type, this.rc_ha_gunbellani_1, this.rc_ha_gunbellani_1_message, raw.roles);
                    break;
                case "ha_gunbellani_2":
                    var raw = this.RC_helper_gunbellani.check(value, "h_air_gunbellani_2"); //RC_FDIH(value, "ha_gunbellani_2");
                    var gc = GC_FDIH_0700_GUNBELLANI2(value);
                    this.rc_ha_gunbellani_2 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_ha_gunbellani_2_message = raw.message;
                    this.setDataRC(type, this.rc_ha_gunbellani_2, this.rc_ha_gunbellani_2_message, raw.roles);
                    break;
                case "ha_diff_gunbellani":
                    var raw = this.RC_helper_gunbellani.check(value, "h_air_diff_gunbellani"); //RC_FDIH(value, "ha_diff_gunbellani");
                    var gc = GC_FDIH_0700_HA_DIFF_GUNBELLANI(value);
                    this.rc_ha_diff_gunbellani = !raw.status ? (!gc ? false : true) : false;
                    this.rc_ha_diff_gunbellani_message = raw.message;
                    this.setDataRC(type, this.rc_ha_diff_gunbellani, this.rc_ha_diff_gunbellani_message, raw.roles);
                    break;

                case "tbk_4m_0700":
                    var raw = this.RC_helper_iklimmikro.check(value, "tbk_4m_0700");
                    var gc = GC_FDIH_PS_0700_TBK(value);
                    this.rc_tbk_4m_0700 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbk_4m_0700_message = raw.message;
                    this.setDataRC(type, this.rc_tbk_4m_0700, this.rc_tbk_4m_0700_message, raw.roles);
                    break;
                case "tbb_4m_0700":
                    var raw = this.RC_helper_iklimmikro.check(value, "tbb_4m_0700");
                    var gc = GC_FDIH_PS_0700_TBB(value);
                    this.rc_tbb_4m_0700 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbb_4m_0700_message = raw.message;
                    this.setDataRC(type, this.rc_tbb_4m_0700, this.rc_tbb_4m_0700_message, raw.roles);
                    break;
                case "tbk_7m_0700":
                    var raw = this.RC_helper_iklimmikro.check(value, "tbk_7m_0700");
                    var gc = GC_FDIH_PS_0700_TBK(value);
                    this.rc_tbk_7m_0700 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbk_7m_0700_message = raw.message;
                    this.setDataRC(type, this.rc_tbk_7m_0700, this.rc_tbk_7m_0700_message, raw.roles);
                    break;
                case "tbb_7m_0700":
                    var raw = this.RC_helper_iklimmikro.check(value, "tbb_7m_0700");
                    var gc = GC_FDIH_PS_0700_TBB(value);
                    this.rc_tbb_7m_0700 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbb_7m_0700_message = raw.message;
                    this.setDataRC(type, this.rc_tbb_7m_0700, this.rc_tbb_7m_0700_message, raw.roles);
                    break;
                case "tbk_10m_0700":
                    var raw = this.RC_helper_iklimmikro.check(value, "tbk_10m_0700");
                    var gc = GC_FDIH_PS_0700_TBK(value);
                    this.rc_tbk_10m_0700 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbk_10m_0700_message = raw.message;
                    this.setDataRC(type, this.rc_tbk_10m_0700, this.rc_tbk_10m_0700_message, raw.roles);
                    break;
                case "tbb_10m_0700":
                    var raw = this.RC_helper_iklimmikro.check(value, "tbb_10m_0700");
                    var gc = GC_FDIH_PS_0700_TBB(value);
                    this.rc_tbb_10m_0700 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbb_10m_0700_message = raw.message;
                    this.setDataRC(type, this.rc_tbb_10m_0700, this.rc_tbb_10m_0700_message, raw.roles);
                    break;

                default:
                    break;
            }
        },

        setDataRC(type, status, message, roles) {
            if (status) {
                this.DATA_RC[type] = {
                    status: status,
                    message: message,
                    roles: roles,
                };
            } else {
                if (this.DATA_RC.hasOwnProperty(type)) {
                    delete this.DATA_RC[type];
                }
            }
        },

        tooltipMessage(attr) {
            return GC_FDIH(attr).message;
        },
        validateTooltipState(name) {
            const { $error } = this.$v[name];
            return false; //$error;
        },
        validateState(item) {
            const { $dirty, $error } = item;
            return $dirty ? !$error : null;
        },
        cancelForm() {
            this.clearData();
            this.$emit("form", "test");
        },
        validationForm() {
            // this.$v.$reset();
            // this.$v.$touch();
            // this.$v.tbk_1c2m_0700.$touch();
            // this.$v.tbk_4m_0700.$touch();
            // this.$v.tbk_7m_0700.$touch();
            // this.$v.tbk_10m_0700.$touch();
            // this.$v.tbb_1c2m_0700.$touch();
            // this.$v.tbb_4m_0700.$touch();
            // this.$v.tbb_7m_0700.$touch();
            // this.$v.tbb_10m_0700.$touch();
            // this.$v.counter_0c5_0700.$touch();
            // this.$v.counter_4_0700.$touch();
            // this.$v.counter_7_0700.$touch();
            // this.$v.counter_10_0700.$touch();
            // this.$v.wd_0700.$touch();
            // this.$v.ws_0700.$touch();
            // this.$v.op_diff_baca_0700.$touch();
            // this.$v.t_max_0700.$touch();
            // this.$v.t_min_0700.$touch();
            // this.$v.rr_0700.$touch();
            // this.$v.ha_gunbellani_1.$touch();
            // this.$v.ha_gunbellani_2.$touch();
            // this.$v.tn_rumput_0700.$touch();
            // this.$v.tn_rumput_reset_0700.$touch();
            // this.$v.co_cu_0700.$touch();
            // this.$v.ku_hujan.$touch();
            // this.$v.ku_salju.$touch();
            // this.$v.ku_badai_debu.$touch();
            // this.$v.ku_pembekuan.$touch();
            // this.$v.ku_embun.$touch();
            // this.$v.ku_kabut.$touch();
            // this.$v.ku_tanpa_rambun.$touch();
            // this.$v.ku_rambun.$touch();
            // this.$v.ku_kabut_debu.$touch();
            // this.$v.ku_angin_ribut.$touch();
            // if (this.$v.$invalid) {

            let valid = true;
            let messageError = "";

            if (this.fdih_status.is_fklim) {
                this.$v.tbk_1c2m_0700.$touch();
                this.$v.tbb_1c2m_0700.$touch();
                this.$v.rr_0700.$touch();

                if (this.$v.tbk_1c2m_0700.$invalid || this.$v.tbb_1c2m_0700.$invalid || this.$v.rr_0700.$invalid) {
                    valid = false;
                    messageError = "Data tidak valid. Periksa kembali inputan anda";
                }
            }

            if (this.fdih_status.is_agm1a) {
                this.$v.tbk_1c2m_0700.$touch();
                this.$v.tbb_1c2m_0700.$touch();
                this.$v.rr_0700.$touch();
                this.$v.co_cu_0700.$touch();
                this.$v.ku_hujan.$touch();
                this.$v.ku_salju.$touch();
                this.$v.ku_badai_debu.$touch();
                this.$v.ku_pembekuan.$touch();
                this.$v.ku_embun.$touch();
                this.$v.ku_kabut.$touch();
                this.$v.ku_tanpa_rambun.$touch();
                this.$v.ku_rambun.$touch();
                this.$v.ku_kabut_debu.$touch();
                this.$v.ku_angin_ribut.$touch();
                this.$v.counter_7_0700.$touch();
                this.$v.wd_0700.$touch();
                this.$v.ws_0700.$touch();
                this.$v.tn_rumput_0700.$touch();
                this.$v.tn_rumput_reset_0700.$touch();

                if (
                    this.$v.tbk_1c2m_0700.$invalid ||
                    this.$v.tbb_1c2m_0700.$invalid ||
                    this.$v.rr_0700.$invalid ||
                    this.$v.co_cu_0700.$invalid ||
                    this.$v.ku_hujan.$invalid ||
                    this.$v.ku_salju.$invalid ||
                    this.$v.ku_badai_debu.$invalid ||
                    this.$v.ku_pembekuan.$invalid ||
                    this.$v.ku_embun.$invalid ||
                    this.$v.ku_kabut.$invalid ||
                    this.$v.ku_tanpa_rambun.$invalid ||
                    this.$v.ku_rambun.$invalid ||
                    this.$v.ku_kabut_debu.$invalid ||
                    this.$v.ku_angin_ribut.$invalid ||
                    this.$v.counter_7_0700.$invalid ||
                    this.$v.wd_0700.$invalid ||
                    this.$v.ws_0700.$invalid ||
                    this.$v.tn_rumput_0700.$invalid ||
                    this.$v.tn_rumput_reset_0700.$invalid
                ) {
                    valid = false;
                    messageError = "Data tidak valid. Periksa kembali inputan anda";
                }
            }
            if (this.fdih_status.is_iklim_mikro) {
                this.$v.tbk_4m_0700.$touch();
                this.$v.tbb_4m_0700.$touch();
                this.$v.tbk_7m_0700.$touch();
                this.$v.tbb_7m_0700.$touch();
                this.$v.tbk_10m_0700.$touch();
                this.$v.tbb_10m_0700.$touch();
                this.$v.counter_4_0700.$touch();
                this.$v.counter_7_0700.$touch();
                this.$v.counter_10_0700.$touch();

                if (
                    this.$v.tbk_4m_0700.$invalid ||
                    this.$v.tbb_4m_0700.$invalid ||
                    this.$v.tbk_7m_0700.$invalid ||
                    this.$v.tbb_7m_0700.$invalid ||
                    this.$v.tbk_10m_0700.$invalid ||
                    this.$v.tbb_10m_0700.$invalid ||
                    this.$v.counter_4_0700.$invalid ||
                    this.$v.counter_7_0700.$invalid ||
                    this.$v.counter_10_0700.$invalid
                ) {
                    valid = false;
                    messageError = "Data tidak valid. Periksa kembali inputan anda";
                }
            }
            if (this.fdih_status.is_op_penguapan) {
                this.$v.counter_0c5_0700.$touch();
                this.$v.op_diff_baca_0700.$touch();
                this.$v.t_max_0700.$touch();
                this.$v.t_min_0700.$touch();
                this.$v.rr_0700.$touch();

                if (this.$v.counter_0c5_0700.$invalid || this.$v.op_diff_baca_0700.$invalid || this.$v.t_max_0700.$invalid || this.$v.t_min_0700.$invalid || this.$v.rr_0700.$invalid) {
                    valid = false;
                    messageError = "Data tidak valid. Periksa kembali inputan anda";
                }
            }

            if (this.fdih_status.is_gunbellani) {
                this.$v.ha_gunbellani_1.$touch();
                this.$v.ha_gunbellani_2.$touch();

                if (this.$v.ha_gunbellani_1.$invalid || this.$v.ha_gunbellani_2.$invalid) {
                    valid = false;
                    messageError = "Data tidak valid. Periksa kembali inputan anda";
                }
            }

            // if (this.$v.$invalid) {
            //   console.log("this.$v", this.$v);
            //   valid = false;
            // }
            // for (let param in this.$v) {
            //   if (!param.startsWith("$")) {
            //     let hasRequired = this.$v[param].hasOwnProperty("required");
            //     console.log('validationForm - ', param, hasRequired)

            //     if (hasRequired) {
            //       let required = this.$v[param].required;
            //       valid = required ? (this.$v[param].$anyError ? false : true) : true;
            //       messageError = valid ? "" : "Data " + param + " tidak valid. Periksa kembali inputan anda";
            //     } else {
            //       valid = this.$v[param].$anyError ? false : true;
            //       messageError = valid ? "" : "Data " + param + " tidak valid. Periksa kembali inputan anda";
            //     }
            //     // let eror = ""
            //     if (!valid) {
            //       break;
            //     }
            //   }
            // }

            if (!valid) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: messageError, //"Data tidak valid. Periksa kembali inputan anda",
                        icon: "BellIcon",
                        variant: "danger",
                    },
                });
            } else {
                // var qc_flag = this.rc_ws_0700 && this.rc_op_diff_baca_0700 && this.rc_t_max_0700 && this.rc_t_min_0700 && this.rc_rr_0700 && this.rc_ha_gunbellani_1 && this.rc_ha_gunbellani_2 && this.rc_ha_diff_gunbellani ? 0 : 1;

                let qc_flag = Object.keys(this.DATA_RC).length > 0 ? 1 : 0;
                let list_qc = this.getQCExist(); //qc_flag > 0 ? this.getQCExist() : null;
                // let msg_html = this.getHtmlMessage(list_qc)
                this.LIST_QC_FIX = list_qc && Object.keys(list_qc).length > 0 ? list_qc : null;

                console.log("list_qc", list_qc);
                if (qc_flag == 1) {
                    // let qc_histories = {
                    //   before: null,
                    //   after: list_qc,
                    // };

                    this.$swal({
                        title: "Apakah Anda yakin untuk simpan data ?",
                        text: "Ada Data Terkoreksi Range Check ",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Submit",
                        cancelButtonText: "Cek Data",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        customClass: {
                            confirmButton: "btn btn-primary",
                            cancelButton: "btn btn-outline-danger ml-1",
                        },
                        buttonsStyling: false,
                    }).then((result) => {
                        if (result.value) {
                            this.submitFDIH0700();
                        } else if (result.dismiss === "cancel") {
                            return;
                        }
                    });
                } else {
                    this.submitFDIH0700();
                }
            }
        },

        async setQCData() {
            if (this.d_station) {
                let qc_data = this.d_station["BmkgSatu.metadata.qc.IGunbellaniqc"];
                let qc_params = Params.GET_PARAMS("gunbellani");
                let qc_key = Params.GET_KEY("gunbellani");
                this.RC_helper_gunbellani.setData(qc_data, qc_params, qc_key);

                let qc_data_fklim = this.d_station["BmkgSatu.metadata.qc.IFklim71qc"];
                let qc_params_fklim = Params.GET_PARAMS("fklim");
                let qc_key_fklim = Params.GET_KEY("fklim");
                this.RC_helper_fklim.setData(qc_data_fklim, qc_params_fklim, qc_key_fklim);

                let qc_data_agm1a = this.d_station["BmkgSatu.metadata.qc.IAgm1aqc"];
                let qc_params_agm1a = Params.GET_PARAMS("agm1a");
                let qc_key_agm1a = Params.GET_KEY("agm1a");
                this.RC_helper_agm1a.setData(qc_data_agm1a, qc_params_agm1a, qc_key_agm1a);

                let qc_data_op = this.d_station["BmkgSatu.metadata.qc.IOppenguapanqc"];
                let qc_params_op = Params.GET_PARAMS("op_penguapan");
                let qc_key_op = Params.GET_KEY("op_penguapan");
                this.RC_helper_op.setData(qc_data_op, qc_params_op, qc_key_op);

                let qc_data_im = this.d_station["BmkgSatu.metadata.qc.IIklimMikroqc"];
                let qc_params_im = Params.GET_PARAMS("iklim_mikro");
                let qc_key_im = Params.GET_KEY("iklim_mikro");
                this.RC_helper_iklimmikro.setData(qc_data_im, qc_params_im, qc_key_im);
            }
        },

        success() {
            // console.log("alert success ok");
            alert("simpan sukses");
            this.$swal({
                title: "Input Data Berhasil!",
                // text: "Input Data FDIH",
                icon: "success",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
            });
        },
        failed(message) {
            this.$swal({
                title: "Entri data gagal!",
                text: message,
                icon: "error",
                customClass: {
                    confirmButton: "btn btn-danger",
                },
                buttonsStyling: false,
            });
        },

        getHtmlMessage(data) {
            let html = "";
            if (data && data.length > 0) {
                for (let dt of data) {
                    html += "<div>" + dt.atribut + ": " + dt.message + " </div>";
                }
                return html;
            }

            return "<div />";
        },

        getQCExist() {
            // if (Object.keys(this.DATA_RC).length > 0) {
            let qc_gunbellani = Object.entries(this.DATA_RC)
                .filter((e) => e[1].status && (e[0] == "ha_gunbellani_2" || e[0] == "ha_gunbellani_1" || e[0] == "ha_diff_gunbellani"))
                .map((e) => {
                    let param = e[0];
                    let content = e[1];
                    return {
                        raw_type: "GUNBELLANI",
                        time: "0700ws",
                        atribut: param,
                        atribut_name: param,
                        value: this[param],
                        roles: content.roles, //RC_FDIH_ROLES(param),
                        type: "range_check",
                        message: content.message,
                    };
                });

            let qc_fklim = Object.entries(this.DATA_RC)
                .filter((e) => e[1].status && (e[0] == "tbk_1c2m_0700" || e[0] == "rr_0700"))
                .map((e) => {
                    let param = e[0];
                    let content = e[1];
                    return {
                        raw_type: "FKLIM",
                        time: "0700ws",
                        atribut: "fklim_" + param,
                        atribut_name: param,
                        value: this[param],
                        roles: content.roles, //RC_FDIH_ROLES(param),
                        type: "range_check",
                        message: content.message,
                    };
                });

            let qc_agm1a = Object.entries(this.DATA_RC)
                .filter((e) => e[1].status && (e[0] == "tbk_1c2m_0700" || e[0] == "tbb_1c2m_0700" || e[0] == "ws_0700" || e[0] == "tn_rumput_0700" || e[0] == "tn_rumput_reset_0700" || e[0] == "rr_0700"))
                .map((e) => {
                    let param = e[0];
                    let content = e[1];
                    return {
                        raw_type: "AGM1A",
                        time: "0700ws",
                        atribut: "agm1a_" + param,
                        atribut_name: param,
                        value: this[param],
                        roles: content.roles, //RC_FDIH_ROLES(param),
                        type: "range_check",
                        message: content.message,
                    };
                });

            let qc_oppenguapan = Object.entries(this.DATA_RC)
                .filter((e) => e[1].status && (e[0] == "op_diff_baca_0700" || e[0] == "t_max_0700" || e[0] == "t_min_0700" || e[0] == "rr_0700"))
                .map((e) => {
                    let param = e[0];
                    let content = e[1];
                    return {
                        raw_type: "OP_PENGUAPAN",
                        time: "0700ws",
                        atribut: param,
                        atribut_name: param,
                        value: this[param],
                        roles: content.roles, //RC_FDIH_ROLES(param),
                        type: "range_check",
                        message: content.message,
                    };
                });

            let qc_iklimmikro = Object.entries(this.DATA_RC)
                .filter((e) => e[1].status && (e[0] == "tbk_4m_0700" || e[0] == "tbk_7m_0700" || e[0] == "tbk_10m_0700"))
                .map((e) => {
                    let param = e[0];
                    let content = e[1];
                    return {
                        raw_type: "IKLIM_MIKRO",
                        time: "0700ws",
                        atribut_name: param,
                        atribut: "iklimmikro_" + param,
                        value: this[param],
                        roles: content.roles,
                        type: "range_check",
                        message: content.message,
                    };
                });

            let allform = {};
            // QC GUNBELLANI
            if (qc_gunbellani && qc_gunbellani.length > 0) {
                let exist = this.t_qc_histories?.gunbellani;
                exist = exist?.after;
                if (exist && exist.length > 0) {
                    var filtered = exist.filter(function (e) {
                        return e.atribut != "ha_gunbellani_2" && e.atribut != "ha_gunbellani_1" && e.atribut != "ha_diff_gunbellani";
                    });

                    for (let data of qc_gunbellani) {
                        var index = filtered.findIndex((x) => (x.hasOwnProperty("atribut_name") ? x.atribut_name == data.atribut_name : x.atribut == data.atribut_name));
                        if (index === -1) {
                            filtered.push(data);
                        } else {
                            filtered[index]["atribut_name"] = data["atribut_name"];
                            // console.log(" gun belani object already exists");
                        }
                    }
                    allform["gunbellani"] = filtered;
                } else {
                    allform["gunbellani"] = qc_gunbellani;
                }
            } else {
                // ketika current kosong, tetapi masih ada histori yg lama
                let exist = this.t_qc_histories?.gunbellani;
                exist = exist?.after;
                if (exist && exist.length > 0) {
                    var filtered = exist.filter(function (e) {
                        return e.atribut != "ha_gunbellani_2" && e.atribut != "ha_gunbellani_1" && e.atribut != "ha_diff_gunbellani";
                    });
                    allform["gunbellani"] = filtered;
                } else {
                    allform["gunbellani"] = null;
                }
            }

            // QC FKLIM
            if (qc_fklim && qc_fklim.length > 0) {
                let exist = this.t_qc_histories?.fklim;
                exist = exist?.after;
                if (exist && exist.length > 0) {
                    var filtered = exist.filter(function (e) {
                        return e.atribut != "tbk_1c2m_0700" && e.atribut != "fklim_tbk_1c2m_0700" && e.atribut != "rr_0700" && e.atribut != "fklim_rr_0700";
                    });

                    for (let data of qc_fklim) {
                        var index = filtered.findIndex((x) => (x.hasOwnProperty("atribut_name") ? x.atribut_name == data.atribut_name : x.atribut == data.atribut_name));
                        if (index === -1) {
                            filtered.push(data);
                        } else {
                            filtered[index]["atribut"] = data["atribut"];
                            filtered[index]["atribut_name"] = data["atribut_name"];
                            // console.log("fklim object already exists");
                        }
                    }
                    allform["fklim"] = filtered;
                } else {
                    allform["fklim"] = qc_fklim;
                }
            } else {
                // ketika current kosong, tetapi masih ada histori yg lama
                let exist = this.t_qc_histories?.fklim;
                exist = exist?.after;
                if (exist && exist.length > 0) {
                    var filtered = exist.filter(function (e) {
                        return e.atribut != "tbk_1c2m_0700" && e.atribut != "fklim_tbk_1c2m_0700" && e.atribut != "rr_0700" && e.atribut != "fklim_rr_0700";
                    });
                    allform["fklim"] = filtered;
                } else {
                    allform["fklim"] = null;
                }
            }

            // QC AGM1A
            if (qc_agm1a && qc_agm1a.length > 0) {
                let exist = this.t_qc_histories?.agm1a;
                exist = exist?.after;
                //harusnya juga check untuk mengurangi
                if (exist && exist.length > 0) {
                    var filtered = exist.filter(function (e) {
                        return (
                            e.atribut != "tbk_1c2m_0700" &&
                            e.atribut != "agm1a_tbk_1c2m_0700" &&
                            e.atribut != "tbb_1c2m_0700" &&
                            e.atribut != "agm1a_tbb_1c2m_0700" &&
                            e.atribut != "ws_0700" &&
                            e.atribut != "agm1a_ws_0700" &&
                            e.atribut != "tn_rumput_0700" &&
                            e.atribut != "agm1a_tn_rumput_0700" &&
                            (e.atribut != "tn_rumput_reset_0700") & (e.atribut != "agm1a_tn_rumput_reset_0700") &&
                            e.atribut != "rr_0700" &&
                            e.atribut != "agm1a_rr_0700"
                        );
                    });
                    for (let data of qc_agm1a) {
                        var index = filtered.findIndex((x) => (x.hasOwnProperty("atribut_name") ? x.atribut_name == data.atribut_name : x.atribut == data.atribut_name));
                        if (index === -1) {
                            filtered.push(data);
                        } else {
                            filtered[index]["atribut"] = data["atribut"];
                            filtered[index]["atribut_name"] = data["atribut_name"];
                        }
                    }
                    allform["agm1a"] = filtered;
                } else {
                    allform["agm1a"] = qc_agm1a;
                }
            } else {
                // ketika current kosong, tetapi masih ada histori yg lama
                let exist = this.t_qc_histories?.agm1a;
                exist = exist?.after;
                if (exist && exist.length > 0) {
                    var filtered = exist.filter(function (e) {
                        return (
                            e.atribut != "tbk_1c2m_0700" &&
                            e.atribut != "agm1a_tbk_1c2m_0700" &&
                            e.atribut != "tbb_1c2m_0700" &&
                            e.atribut != "agm1a_tbb_1c2m_0700" &&
                            e.atribut != "ws_0700" &&
                            e.atribut != "agm1a_ws_0700" &&
                            e.atribut != "tn_rumput_0700" &&
                            e.atribut != "agm1a_tn_rumput_0700" &&
                            (e.atribut != "tn_rumput_reset_0700") & (e.atribut != "agm1a_tn_rumput_reset_0700") &&
                            e.atribut != "rr_0700" &&
                            e.atribut != "agm1a_rr_0700"
                        );
                    });
                    allform["agm1a"] = filtered;
                } else {
                    allform["agm1a"] = null;
                }
            }

            // QC OP PENGUAPAN
            if (qc_oppenguapan && qc_oppenguapan.length > 0) {
                let exist = this.t_qc_histories?.oppenguapan;
                exist = exist?.after;
                if (exist && exist.length > 0) {
                    var filtered = exist.filter(function (e) {
                        return e.atribut != "op_diff_baca_0700" && e.atribut != "t_max_0700" && e.atribut != "t_min_0700" && e.atribut != "rr_0700";
                    });

                    for (let data of qc_oppenguapan) {
                        var index = filtered.findIndex((x) => (x.hasOwnProperty("atribut_name") ? x.atribut_name == data.atribut_name : x.atribut == data.atribut_name));
                        if (index === -1) {
                            filtered.push(data);
                        } else {
                            filtered[index]["atribut_name"] = data["atribut_name"];
                            // console.log("op penguapan object already exists");
                        }
                    }
                    allform["oppenguapan"] = filtered;
                } else {
                    allform["oppenguapan"] = qc_oppenguapan;
                }
            } else {
                // ketika current kosong, tetapi masih ada histori yg lama
                let exist = this.t_qc_histories?.oppenguapan;
                exist = exist?.after;
                if (exist && exist.length > 0) {
                    var filtered = exist.filter(function (e) {
                        return e.atribut != "op_diff_baca_0700" && e.atribut != "t_max_0700" && e.atribut != "t_min_0700" && e.atribut != "rr_0700";
                    });
                    allform["oppenguapan"] = filtered;
                } else {
                    allform["oppenguapan"] = null;
                }
            }

            // QC IKLIM MIKRO
            if (qc_iklimmikro && qc_iklimmikro.length > 0) {
                let exist = this.t_qc_histories?.iklimmikro;
                exist = exist?.after;
                if (exist && exist.length > 0) {
                    var filtered = exist.filter(function (e) {
                        return e.atribut != "tbk_4m_0700" && e.atribut != "iklimmikro_tbk_4m_0700" && e.atribut != "tbk_7m_0700" && e.atribut != "iklimmikro_tbk_7m_0700" && e.atribut != "tbk_10m_0700" && e.atribut != "iklimmikro_tbk_10m_0700";
                    });
                    for (let data of qc_iklimmikro) {
                        var index = filtered.findIndex((x) => (x.hasOwnProperty("atribut_name") ? x.atribut_name == data.atribut_name : x.atribut == data.atribut_name));
                        if (index === -1) {
                            filtered.push(data);
                        } else {
                            filtered[index]["atribut"] = data["atribut"];
                            filtered[index]["atribut_name"] = data["atribut_name"];
                            // console.log("qc iklim mikro object already exists");
                        }
                    }
                    allform["iklimmikro"] = filtered;
                } else {
                    allform["iklimmikro"] = qc_iklimmikro;
                }
            } else {
                // ketika current kosong, tetapi masih ada histori yg lama
                let exist = this.t_qc_histories?.iklimmikro;
                exist = exist?.after;
                if (exist && exist.length > 0) {
                    var filtered = exist.filter(function (e) {
                        return e.atribut != "tbk_4m_0700" && e.atribut != "iklimmikro_tbk_4m_0700" && e.atribut != "tbk_7m_0700" && e.atribut != "iklimmikro_tbk_7m_0700" && e.atribut != "tbk_10m_0700" && e.atribut != "iklimmikro_tbk_10m_0700";
                    });
                    allform["iklimmikro"] = filtered;
                } else {
                    allform["iklimmikro"] = null;
                }
            }

            return allform;
            // }
            // return null;
        },

        async submitFDIH0700() {
            const fdih_id = this.t_id_form; //"1_fdih_" + this.t_date;
            const data_timestamp = this.t_date;
            let post_data = {
                "@type": "Fdih",
                id: fdih_id,
                data_timestamp: data_timestamp,
                // flagdata_fklim: this.tbk_1c2m_0700 || this.rr_0700 ? 1 : this.flagdata_fklim,
                // flagdata_iklim_mikro: this.tbk_4m_0700 || this.tbk_7m_0700 || this.tbk_10m_0700 ? 1 : this.flagdata_iklim_mikro,
                // flagdata_agm1a: this.tbk_1c2m_0700 || this.tbb_1c2m_0700 || this.ws_0700 || this.tn_rumput_0700 || this.tn_rumput_reset_0700 || this.rr_0700 ? 1 : this.flagdata_agm1a,
                // flagdata_op_penguapan: this.op_diff_baca_0700 || this.t_max_0700 || this.t_min_0700 || this.rr_0700 ? 1 : this.flagdata_op_penguapan,
                // flagdata_gunbellani: this.ha_gunbellani_1 || this.ha_gunbellani_2 ? 1 : this.flagdata_gunbellani,
                observer_id: this.t_observer.id,
                observer_name: this.t_observer.observer,
                m_0700ws: {
                    data_timestamp: data_timestamp,
                    tbk_1c2m_0700: parseFloat(this.tbk_1c2m_0700),
                    tbk_4m_0700: parseFloat(this.tbk_4m_0700),
                    tbk_7m_0700: parseFloat(this.tbk_7m_0700),
                    tbk_10m_0700: parseFloat(this.tbk_10m_0700),
                    tbb_1c2m_0700: parseFloat(this.tbb_1c2m_0700),
                    tbb_4m_0700: parseFloat(this.tbb_4m_0700),
                    tbb_7m_0700: parseFloat(this.tbb_7m_0700),
                    tbb_10m_0700: parseFloat(this.tbb_10m_0700),
                    rh_1c2m_0700: parseFloat(this.rh_1c2m_0700),
                    rh_4m_0700: parseFloat(this.rh_4m_0700),
                    rh_7m_0700: parseFloat(this.rh_7m_0700),
                    rh_10m_0700: parseFloat(this.rh_10m_0700),
                    counter_sebelum_0c5_0700: parseFloat(this.counter_sebelum_0c5_0700),
                    counter_sebelum_4_0700: parseFloat(this.counter_sebelum_4_0700),
                    counter_sebelum_7_0700: parseFloat(this.counter_sebelum_7_0700),
                    counter_sebelum_10_0700: parseFloat(this.counter_sebelum_10_0700),
                    counter_0c5_0700: parseFloat(this.counter_0c5_0700),
                    counter_4_0700: parseFloat(this.counter_4_0700),
                    counter_7_0700: parseFloat(this.counter_7_0700),
                    counter_10_0700: parseFloat(this.counter_10_0700),
                    ws_avg_0c5_0700: parseFloat(this.ws_avg_0c5_0700),
                    ws_avg_4_0700: parseFloat(this.ws_avg_4_0700),
                    ws_avg_7_0700: parseFloat(this.ws_avg_7_0700),
                    ws_avg_10_0700: parseFloat(this.ws_avg_10_0700),
                    wd_0700: parseFloat(this.wd_0700),
                    ws_0700: parseFloat(this.ws_0700),
                    op_diff_baca_0700: parseFloat(this.op_diff_baca_0700),
                    t_air_max_0700: parseFloat(this.t_max_0700),
                    t_air_min_0700: parseFloat(this.t_min_0700),
                    t_air_avg_0700: parseFloat(this.t_avg_0700).toFixed(1),
                    rr_0700: parseFloat(this.rr_0700).toFixed(1),
                    op_eva_0700: parseFloat(this.op_eva_0700),
                    h_air_gunbellani_2_kemarin: parseFloat(this.ha_gunbellani_2_kemarin),
                    h_air_gunbellani_1: parseFloat(this.ha_gunbellani_1),
                    h_air_gunbellani_2: parseFloat(this.ha_gunbellani_2),
                    h_air_diff_gunbellani: parseFloat(this.ha_diff_gunbellani),
                    rad_gunbellani: parseFloat(this.rad_gunbellani),
                    ss_8: parseFloat(this.ss_8),
                    ss_12: parseFloat(this.ss_12),
                    tn_rumput_0700: parseFloat(this.tn_rumput_0700),
                    tn_rumput_reset_0700: parseFloat(this.tn_rumput_reset_0700),
                    co_cu_0700: this.co_cu_0700,
                    cu_khusus: this.cu_khusus,
                    cat_agm1a: this.cat_agm1a,
                    cat_gunbellani: this.cat_gunbellani,
                    ku_hujan: this.ku_hujan,
                    // ku_salju: this.ku_salju,
                    ku_badai_debu: this.ku_badai_debu,
                    ku_pembekuan: this.ku_pembekuan,
                    ku_embun: this.ku_embun,
                    ku_kabut: this.ku_kabut,
                    ku_tanpa_rambun: this.ku_tanpa_rambun,
                    ku_rambun: this.ku_rambun,
                    // ku_24: this.ku_24,
                    ku_kabut_debu: this.ku_kabut_debu,
                    ku_angin_ribut: this.ku_angin_ribut,
                    flagm_0700: 0,
                    // qc_flag: qc_flag,
                },
            };

            if (this.t_data_status == "update") {
                post_data = {
                    data_timestamp: data_timestamp,
                    // flagdata_fklim: this.tbk_1c2m_0700 || this.rr_0700 ? 1 : this.flagdata_fklim,
                    // flagdata_iklim_mikro: this.tbk_4m_0700 || this.tbk_7m_0700 || this.tbk_10m_0700 ? 1 : this.flagdata_iklim_mikro,
                    // flagdata_agm1a: this.tbk_1c2m_0700 || this.tbb_1c2m_0700 || this.ws_0700 || this.tn_rumput_0700 || this.tn_rumput_reset_0700 || this.rr_0700 ? 1 : this.flagdata_agm1a,
                    // flagdata_op_penguapan: this.op_diff_baca_0700 || this.t_max_0700 || this.t_min_0700 || this.rr_0700 ? 1 : this.flagdata_op_penguapan,
                    // flagdata_gunbellani: this.ha_gunbellani_1 || this.ha_gunbellani_2 ? 1 : this.flagdata_gunbellani,
                    observer_id: this.t_observer.id, // observer_id
                    observer_name: this.t_observer.observer,
                    m_0700ws: {
                        data_timestamp: data_timestamp,
                        tbk_1c2m_0700: parseFloat(this.tbk_1c2m_0700),
                        tbk_4m_0700: parseFloat(this.tbk_4m_0700),
                        tbk_7m_0700: parseFloat(this.tbk_7m_0700),
                        tbk_10m_0700: parseFloat(this.tbk_10m_0700),
                        tbb_1c2m_0700: parseFloat(this.tbb_1c2m_0700),
                        tbb_4m_0700: parseFloat(this.tbb_4m_0700),
                        tbb_7m_0700: parseFloat(this.tbb_7m_0700),
                        tbb_10m_0700: parseFloat(this.tbb_10m_0700),
                        rh_1c2m_0700: parseFloat(this.rh_1c2m_0700),
                        rh_4m_0700: parseFloat(this.rh_4m_0700),
                        rh_7m_0700: parseFloat(this.rh_7m_0700),
                        rh_10m_0700: parseFloat(this.rh_10m_0700),
                        counter_sebelum_0c5_0700: parseFloat(this.counter_sebelum_0c5_0700),
                        counter_sebelum_4_0700: parseFloat(this.counter_sebelum_4_0700),
                        counter_sebelum_7_0700: parseFloat(this.counter_sebelum_7_0700),
                        counter_sebelum_10_0700: parseFloat(this.counter_sebelum_10_0700),
                        counter_0c5_0700: parseFloat(this.counter_0c5_0700),
                        counter_4_0700: parseFloat(this.counter_4_0700),
                        counter_7_0700: parseFloat(this.counter_7_0700),
                        counter_10_0700: parseFloat(this.counter_10_0700),
                        ws_avg_0c5_0700: parseFloat(this.ws_avg_0c5_0700),
                        ws_avg_4_0700: parseFloat(this.ws_avg_4_0700),
                        ws_avg_7_0700: parseFloat(this.ws_avg_7_0700),
                        ws_avg_10_0700: parseFloat(this.ws_avg_10_0700),
                        wd_0700: parseFloat(this.wd_0700),
                        ws_0700: parseFloat(this.ws_0700),
                        op_diff_baca_0700: parseFloat(this.op_diff_baca_0700),
                        t_air_max_0700: parseFloat(this.t_max_0700),
                        t_air_min_0700: parseFloat(this.t_min_0700),
                        t_air_avg_0700: parseFloat(this.t_avg_0700).toFixed(1),
                        rr_0700: parseFloat(this.rr_0700).toFixed(1),
                        op_eva_0700: parseFloat(this.op_eva_0700),
                        h_air_gunbellani_2_kemarin: parseFloat(this.ha_gunbellani_2_kemarin),
                        h_air_gunbellani_1: parseFloat(this.ha_gunbellani_1),
                        h_air_gunbellani_2: parseFloat(this.ha_gunbellani_2),
                        h_air_diff_gunbellani: parseFloat(this.ha_diff_gunbellani),
                        rad_gunbellani: parseFloat(this.rad_gunbellani),
                        ss_8: parseFloat(this.ss_8),
                        ss_12: parseFloat(this.ss_12),
                        tn_rumput_0700: parseFloat(this.tn_rumput_0700),
                        tn_rumput_reset_0700: parseFloat(this.tn_rumput_reset_0700),
                        co_cu_0700: this.co_cu_0700,
                        cu_khusus: this.cu_khusus,
                        cat_agm1a: this.cat_agm1a,
                        cat_gunbellani: this.cat_gunbellani,
                        ku_hujan: this.ku_hujan,
                        // ku_salju: this.ku_salju,
                        ku_badai_debu: this.ku_badai_debu,
                        ku_pembekuan: this.ku_pembekuan,
                        ku_embun: this.ku_embun,
                        ku_kabut: this.ku_kabut,
                        ku_tanpa_rambun: this.ku_tanpa_rambun,
                        ku_rambun: this.ku_rambun,
                        // ku_24: this.ku_24,
                        ku_kabut_debu: this.ku_kabut_debu,
                        ku_angin_ribut: this.ku_angin_ribut,
                        flagm_0700: 1,
                        // qc_flag: qc_flag,
                    },
                };
            }

            this.LIST_QC_NOTIFICATION_FIX = {};
            let regionId = this.t_path.split("/")[1];
            if (this.LIST_QC_FIX && Object.keys(this.LIST_QC_FIX).length > 0) {
                if (this.LIST_QC_FIX.hasOwnProperty("oppenguapan")) {
                    post_data["BmkgSatu.behaviors.qc.IFdih_OpPenguapan_qc"] = {
                        qc_flag_op_penguapan: this.flagdata_op_penguapan == 2 ? 2 : this.LIST_QC_FIX.oppenguapan && this.LIST_QC_FIX.oppenguapan.length > 0 ? 1 : 0,
                        qc_histories_op_penguapan: JSON.stringify({ before: null, after: this.LIST_QC_FIX.oppenguapan }),
                    };
                    this.LIST_QC_NOTIFICATION_FIX["oppenguapan"] = [
                        "oppenguapan_" + this.t_id_form, // id entry
                        "OP_PENGUAPAN", // type form entry
                        data_timestamp, // tanggal entry
                        "0700ws", // jam entry
                        JSON.stringify({ before: null, after: this.LIST_QC_FIX.oppenguapan }), // qc histories
                        1, // qc_flag
                        this.t_station_id, // station_id
                        this.t_station.text, //station_name
                        this.t_observer.id, // observer_id
                        this.t_observer.observer, //observer_name
                        regionId, // region_id
                        this.t_path + "/" + this.t_id_form, // path
                    ];
                }

                if (this.LIST_QC_FIX.hasOwnProperty("fklim")) {
                    post_data["BmkgSatu.behaviors.qc.IFdih_Fklim_qc"] = {
                        qc_flag_fklim: this.flagdata_fklim == 2 ? 2 : this.LIST_QC_FIX.fklim && this.LIST_QC_FIX.fklim.length > 0 ? 1 : 0,
                        qc_flag_fklim_0700: this.LIST_QC_FIX.fklim && this.LIST_QC_FIX.fklim.length > 0 ? 1 : 0,
                        qc_histories_fklim: JSON.stringify({ before: null, after: this.LIST_QC_FIX.fklim }),
                    };

                    this.LIST_QC_NOTIFICATION_FIX["fklim"] = [
                        "fklim_" + this.t_id_form,
                        "FKLIM",
                        data_timestamp,
                        "0700ws",
                        JSON.stringify({ before: null, after: this.LIST_QC_FIX.fklim }),
                        1,
                        this.t_station_id,
                        this.t_station.text,
                        this.t_observer.id,
                        this.t_observer.observer,
                        regionId,
                        this.t_path + "/" + this.t_id_form,
                    ];
                }

                if (this.LIST_QC_FIX.hasOwnProperty("gunbellani")) {
                    post_data["BmkgSatu.behaviors.qc.IFdih_Gunbellani_qc"] = {
                        qc_flag_gunbellani: this.flagdata_gunbellani == 2 ? 2 : this.LIST_QC_FIX.gunbellani && this.LIST_QC_FIX.gunbellani.length > 0 ? 1 : 0,
                        qc_histories_gunbellani: JSON.stringify({ before: null, after: this.LIST_QC_FIX.gunbellani }),
                    };

                    this.LIST_QC_NOTIFICATION_FIX["gunbellani"] = [
                        "gunbellani_" + this.t_id_form,
                        "GUNBELLANI",
                        data_timestamp,
                        "0700ws",
                        JSON.stringify({ before: null, after: this.LIST_QC_FIX.gunbellani }),
                        1,
                        this.t_station_id,
                        this.t_station.text,
                        this.t_observer.id,
                        this.t_observer.observer,
                        regionId,
                        this.t_path + "/" + this.t_id_form,
                    ];
                }

                if (this.LIST_QC_FIX.hasOwnProperty("agm1a")) {
                    post_data["BmkgSatu.behaviors.qc.IFdih_Agm1a_qc"] = {
                        qc_flag_agm1a: this.flagdata_agm1a == 2 ? 2 : this.LIST_QC_FIX.agm1a && this.LIST_QC_FIX.agm1a.length > 0 ? 1 : 0,
                        qc_flag_agm1a_0700: this.LIST_QC_FIX.agm1a && this.LIST_QC_FIX.agm1a.length > 0 ? 1 : 0,
                        qc_histories_agm1a: JSON.stringify({ before: null, after: this.LIST_QC_FIX.agm1a }),
                    };

                    this.LIST_QC_NOTIFICATION_FIX["agm1a"] = [
                        "agm1a_" + this.t_id_form, // id entry
                        "AGM1A", // type form entry
                        data_timestamp, // tanggal entry
                        "0700ws", // jam entry
                        JSON.stringify({ before: null, after: this.LIST_QC_FIX.agm1a }), // qc histories
                        1, // qc_flag
                        this.t_station_id, // station_id
                        this.t_station.text, //station_name
                        this.t_observer.id, // observer_id
                        this.t_observer.observer, //observer_name
                        regionId, // region_id
                        this.t_path + "/" + this.t_id_form, // path
                    ];
                }

                if (this.LIST_QC_FIX.hasOwnProperty("iklimmikro")) {
                    post_data["BmkgSatu.behaviors.qc.IFdih_IklimMikro_qc"] = {
                        qc_flag_iklim_mikro: this.flagdata_iklim_mikro == 2 ? 2 : this.LIST_QC_FIX.iklimmikro && this.LIST_QC_FIX.iklimmikro.length > 0 ? 1 : 0,
                        qc_flag_iklim_mikro_0700: this.LIST_QC_FIX.iklimmikro && this.LIST_QC_FIX.iklimmikro.length > 0 ? 1 : 0,
                        qc_histories_iklim_mikro: JSON.stringify({ before: null, after: this.LIST_QC_FIX.iklimmikro }),
                    };

                    this.LIST_QC_NOTIFICATION_FIX["iklimmikro"] = [
                        "iklimmikro_" + this.t_id_form, // id entry
                        "IKLIM_MIKRO", // type form entry
                        data_timestamp, // tanggal entry
                        "0700ws", // jam entry
                        JSON.stringify({ before: null, after: this.LIST_QC_FIX.iklimmikro }), // qc histories
                        1, // qc_flag
                        this.t_station_id, // station_id
                        this.t_station.text, //station_name
                        this.t_observer.id, // observer_id
                        this.t_observer.observer, //observer_name
                        regionId, // region_id
                        this.t_path + "/" + this.t_id_form, // path
                    ];
                }
            }

            this.showLoading = true;
            if (this.t_data_status == "new") {
                Service.setNewFDIH0700(this.t_path, post_data)
                    .then((response) => {
                        if (response.status == 201 || response.status == 200 || response.status == 204) {
                            this.finishingRequest();
                            this.clearData();
                            this.$emit("form", "test");
                            this.$swal({
                                title: "Input Data Berhasil!",
                                //text: "Input Data FDIH",
                                icon: "success",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });

                            this.showLoading = false;
                        }
                    })
                    .catch((error) => {
                        if (error.response.status == 401) {
                            this.$swal({
                                title: "Gagal proses submit. Unauthorized!",
                                text: "Sesi anda telah habis. Logout dan silahkan login kembali",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        } else if (error.response.status == 404) {
                            this.$swal({
                                title: "Gagal entry!",
                                text: "404. Object Not Found",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        } else {
                            let error_msg = error.response?.data?.error?.message || ("" + error.response?.status)
                            this.$swal({
                                title: "Gagal entry!",
                                text: error_msg,
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        }
                        this.showLoading = false;
                    });
            } else if (this.t_data_status == "update") {
                Service.updateFDIH0700(this.t_path + "/" + this.t_id_form, post_data)
                    .then((response) => {
                        if (response.status == 201 || response.status == 200 || response.status == 204) {
                            this.finishingRequest();
                            this.clearData();
                            this.$emit("form", "test");
                            this.$swal({
                                title: "Update Data Berhasil!",
                                //text: "Update Data FDIH",
                                icon: "success",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                            this.showLoading = false;
                        }
                    })
                    .catch((error) => {
                        if (error.response.status == 401) {
                            this.$swal({
                                title: "Gagal proses submit. Unauthorized!",
                                text: "Sesi anda telah habis. Logout dan silahkan login kembali",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        } else if (error.response.status == 404) {
                            this.$swal({
                                title: "Gagal entry!",
                                text: "404. Object Not Found",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        } else {
                            let error_msg = error.response?.data?.error?.message || ("" + error.response?.status)
                            this.$swal({
                                title: "Gagal entry!",
                                text: error_msg,
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        }
                        this.showLoading = false;
                    });
            }
        },

        async finishingRequest() {
            if (this.LIST_QC_NOTIFICATION_FIX && Object.keys(this.LIST_QC_NOTIFICATION_FIX).length > 0) {
                for (let key in this.LIST_QC_NOTIFICATION_FIX) {
                    new Promise(async (resolve) => {
                        let data = await qc_helper.editQCNotification(
                            this.LIST_QC_NOTIFICATION_FIX[key][0],
                            this.LIST_QC_NOTIFICATION_FIX[key][1],
                            this.LIST_QC_NOTIFICATION_FIX[key][2],
                            this.LIST_QC_NOTIFICATION_FIX[key][3],
                            this.LIST_QC_NOTIFICATION_FIX[key][4],
                            this.LIST_QC_NOTIFICATION_FIX[key][5],
                            this.LIST_QC_NOTIFICATION_FIX[key][6],
                            this.LIST_QC_NOTIFICATION_FIX[key][7],
                            this.LIST_QC_NOTIFICATION_FIX[key][8],
                            this.LIST_QC_NOTIFICATION_FIX[key][9],
                            this.LIST_QC_NOTIFICATION_FIX[key][10],
                            this.LIST_QC_NOTIFICATION_FIX[key][11]
                        );
                        resolve(data);
                    });
                }
            }
        },

        checkValidateAll() {
            // check Rangecheck All
            // console.log('check Validate all')
            this.checkValidate({ target: { value: this.tbk_1c2m_0700 } }, "tbk_1c2m_0700");
            this.checkValidate({ target: { value: this.tbb_1c2m_0700 } }, "tbb_1c2m_0700");
            this.checkValidate({ target: { value: this.tbk_4m_0700 } }, "tbk_4m_0700");
            this.checkValidate({ target: { value: this.tbb_4m_0700 } }, "tbb_4m_0700");
            this.checkValidate({ target: { value: this.tbk_7m_0700 } }, "tbk_7m_0700");
            this.checkValidate({ target: { value: this.tbb_7m_0700 } }, "tbb_7m_0700");
            this.checkValidate({ target: { value: this.tbk_10m_0700 } }, "tbk_10m_0700");
            this.checkValidate({ target: { value: this.tbb_10m_0700 } }, "tbb_10m_0700");
            this.checkValidate({ target: { value: this.ws_0700 } }, "ws_0700");
            this.checkValidate({ target: { value: this.op_diff_baca_0700 } }, "op_diff_baca_0700");
            this.checkValidate({ target: { value: this.t_max_0700 } }, "t_max_0700");
            this.checkValidate({ target: { value: this.t_min_0700 } }, "t_min_0700");
            this.checkValidate({ target: { value: this.rr_0700 } }, "rr_0700");
            this.checkValidate({ target: { value: this.ha_gunbellani_1 } }, "ha_gunbellani_1");
            this.checkValidate({ target: { value: this.ha_gunbellani_2 } }, "ha_gunbellani_2");
            this.checkValidate({ target: { value: this.tn_rumput_0700 } }, "tn_rumput_0700");
            this.checkValidate({ target: { value: this.tn_rumput_reset_0700 } }, "tn_rumput_reset_0700");
        },

        clearData() {
            this.keadaan_cuaca = "";
            this.keadaan_udara = "";
            this.tbk_1c2m_0700 = "";
            this.tbk_4m_0700 = "";
            this.tbk_7m_0700 = "";
            this.tbk_10m_0700 = "";
            this.tbb_1c2m_0700 = "";
            this.tbb_4m_0700 = "";
            this.tbb_7m_0700 = "";
            this.tbb_10m_0700 = "";
            this.rh_1c2m_0700 = "";
            this.rh_4m_0700 = "";
            this.rh_7m_0700 = "";
            this.rh_10m_0700 = "";
            this.counter_sebelum_0c5_0700 = "";
            this.counter_sebelum_4_0700 = "";
            this.counter_sebelum_7_0700 = "";
            this.counter_sebelum_10_0700 = "";
            this.counter_0c5_0700 = "";
            this.counter_4_0700 = "";
            this.counter_7_0700 = "";
            this.counter_10_0700 = "";
            this.ws_avg_0c5_0700 = "";
            this.ws_avg_4_0700 = "";
            this.ws_avg_7_0700 = "";
            this.ws_avg_10_0700 = "";
            this.wd_0700 = "";
            this.ws_0700 = "";
            this.op_diff_baca_0700 = "";
            this.t_max_0700 = "";
            this.t_min_0700 = "";
            this.t_avg_0700 = "";
            this.rr_0700 = "";
            this.op_eva_0700 = "";
            this.ha_gunbellani_2_kemarin = "";
            this.ha_gunbellani_1 = "";
            this.ha_gunbellani_2 = "";
            this.ha_diff_gunbellani = "";
            this.rad_gunbellani = "";
            this.ss_8 = "";
            this.ss_12 = "";
            this.tn_rumput_0700 = "";
            this.tn_rumput_reset_0700 = "";
            this.co_cu_0700 = "-";
            this.cu_khusus = "";
            this.cat_agm1a = "";
            this.cat_gunbellani = "";
            this.ku_hujan = "-";
            this.ku_salju = "-";
            this.ku_pembekuan = "-";
            this.ku_embun = "-";
            this.ku_kabut = "-";
            this.ku_tanpa_rambun = "-";
            this.ku_rambun = "-";
            this.ku_kabut_debu = "-";
            this.ku_angin_ribut = "-";
            this.is_lama_penyinaran_entry = false;
            this.is_edit_gunbellani = false;
            this.LIST_QC_FIX = {};
            this.LIST_QC_NOTIFICATION_FIX = {};
            this.flagdata_fklim = null;
            this.flagdata_iklim_mikro = null;
            this.flagdata_agm1a = null;
            this.flagdata_op_penguapan = null;
            this.flagdata_gunbellani = null;

            this.$v.$reset();
        },
    },
    mounted() {
        // console.log('this.roles 07', this.roles)
        this.$root.$emit("bv::hide::tooltip", "tbk_1c2m_0700");
        this.initialize();
        // this.checkQCNotificationExist();
    },

    computed: {},

    watch: {
        roles: function (val) {
            // console.log('watch roles', val)
        },

        data_input: {
            deep: true,
            handler() {
                // console.log("data_input: ", this.data_input.i_time);
                if (this.data_input.i_time == "0700ws") {
                    this.clearData();
                }
            },
        },

        // is_op_edit: function(val) {
        //   if (val) {
        //     this.is_edit_op_penguapan = false
        //   }
        //   else {
        //     this.is_edit_op_penguapan = true
        //   }
        // },

        is_lm_alat_rusak: function (val) {
            if (val) {
                this.ss_8 = 9999;
                this.ss_12 = 9999;
                this.is_lama_penyinaran_entry = true;
            } else {
                let val = this.t_data_requirement;
                this.ss_8 = val.lamapenyinaran_ss_total_8 == "null" ? (this.ss_8 ? this.ss_8 : "") : val.lamapenyinaran_ss_total_8;
                this.ss_12 = val.lamapenyinaran_ss_total_12 == "null" ? (this.ss_8 ? this.ss_8 : "") : val.lamapenyinaran_ss_total_12;
                if (val.lamapenyinaran_id && val.lamapenyinaran_id != "null") {
                    this.is_lama_penyinaran_entry = true;
                } else {
                    this.is_lama_penyinaran_entry = false;
                }
            }
        },

        t_bmkgentry: function (val) {
            this.initialize();
        },

        t_all_data: function (val) {
            // console.log("t_all_data", val);
            this.flagdata_fklim = val["BmkgSatu.behaviors.qc.IFdih_Fklim_qc"].qc_flag_fklim;
            this.flagdata_iklim_mikro = val["BmkgSatu.behaviors.qc.IFdih_IklimMikro_qc"].qc_flag_iklim_mikro;
            this.flagdata_agm1a = val["BmkgSatu.behaviors.qc.IFdih_Agm1a_qc"].qc_flag_agm1a;
            this.flagdata_op_penguapan = val["BmkgSatu.behaviors.qc.IFdih_OpPenguapan_qc"].qc_flag_op_penguapan;
            this.flagdata_gunbellani = val["BmkgSatu.behaviors.qc.IFdih_Gunbellani_qc"].qc_flag_gunbellani;
        },

        t_data_requirement: function (val) {
            // console.log("t_data_requirement: ", val);
            // this.counter_sebelum_0c5_0700 = val.fdih_ws0700_counter_0c5_0700 == "null" || val.fdih_ws0700_counter_0c5_0700 == null ? 0 : val.fdih_ws0700_counter_0c5_0700;
            // this.counter_sebelum_4_0700 = val.fdih_ws0700_counter_4_0700 == "null" || val.fdih_ws0700_counter_4_0700 == null ? 0 : val.fdih_ws0700_counter_4_0700;
            // this.counter_sebelum_7_0700 = val.fdih_ws0700_counter_7_0700 == "null" || val.fdih_ws0700_counter_7_0700 == null ? 0 : val.fdih_ws0700_counter_7_0700;
            // this.counter_sebelum_10_0700 = val.fdih_ws0700_counter_10_0700 == "null" || val.fdih_ws0700_counter_10_0700 == null ? 0 : val.fdih_ws0700_counter_10_0700;
            if (val.fdih_ws0700_counter_0c5_0700 != "null" && val.fdih_ws0700_counter_0c5_0700 != null) {
                // console.log("t_data_requirement - this.counter_sebelum_0c5_0700", this.counter_sebelum_0c5_0700);
                if (this.counter_sebelum_0c5_0700 === null || this.counter_sebelum_0c5_0700 === "null" || this.counter_sebelum_0c5_0700 === "") {
                    this.counter_sebelum_0c5_0700 = val.fdih_ws0700_counter_0c5_0700;
                }
            }
            if (val.fdih_ws0700_counter_4_0700 != "null" && val.fdih_ws0700_counter_4_0700 != null) {
                if (this.counter_sebelum_4_0700 === null || this.counter_sebelum_4_0700 === "null" || this.counter_sebelum_4_0700 === "") {
                    this.counter_sebelum_4_0700 = val.fdih_ws0700_counter_4_0700;
                }
            }
            if (val.fdih_ws0700_counter_7_0700 != "null" && val.fdih_ws0700_counter_7_0700 != null) {
                if (this.counter_sebelum_7_0700 === null || this.counter_sebelum_7_0700 === "null" || this.counter_sebelum_7_0700 === "") {
                    this.counter_sebelum_7_0700 = val.fdih_ws0700_counter_7_0700;
                }
            }
            if (val.fdih_ws0700_counter_10_0700 != "null" && val.fdih_ws0700_counter_10_0700 != null) {
                if (this.counter_sebelum_10_0700 === null || this.counter_sebelum_10_0700 === "null" || this.counter_sebelum_10_0700 === "") {
                    this.counter_sebelum_10_0700 = val.fdih_ws0700_counter_10_0700;
                }
            }

            this.ha_gunbellani_2_kemarin = val.fdih_ws0700_h_air_gunbellani_2_kemarin == "null" || val.fdih_ws0700_h_air_gunbellani_2_kemarin == null ? "" : val.fdih_ws0700_h_air_gunbellani_2_kemarin;
            this.ss_8 = val.lamapenyinaran_ss_total_8 == "null" || val.lamapenyinaran_ss_total_8 == null ? (this.ss_8 ? this.ss_8 : "") : val.lamapenyinaran_ss_total_8;
            this.ss_12 = val.lamapenyinaran_ss_total_12 == "null" || val.lamapenyinaran_ss_total_12 == null ? (this.ss_12 ? this.ss_12 : "") : val.lamapenyinaran_ss_total_12;
            if (val.lamapenyinaran_id && val.lamapenyinaran_id != "null") {
                this.is_lama_penyinaran_entry = true;
            } else {
                this.is_lama_penyinaran_entry = false;
            }
            this.$v.$reset();
            this.checkValidateAll();
            if (this.t_data_status == "update") {
                this.$v.$touch();
            }
            // this.cu_khusus = val.sinop_present_weather_ww == "null" ? "" : val.sinop_present_weather_ww;
        },
        t_data_existing: function (val) {
            // console.log("t_data_existing: ", val);
            this.tbk_1c2m_0700 = val.tbk_1c2m_0700;
            this.tbk_4m_0700 = val.tbk_4m_0700;
            this.tbk_7m_0700 = val.tbk_7m_0700;
            this.tbk_10m_0700 = val.tbk_10m_0700;
            this.tbb_1c2m_0700 = val.tbb_1c2m_0700;
            this.tbb_4m_0700 = val.tbb_4m_0700;
            this.tbb_7m_0700 = val.tbb_7m_0700;
            this.tbb_10m_0700 = val.tbb_10m_0700;
            this.rh_1c2m_0700 = val.rh_1c2m_0700;
            this.rh_4m_0700 = val.rh_4m_0700;
            this.rh_7m_0700 = val.rh_7m_0700;
            this.rh_10m_0700 = val.rh_10m_0700;
            this.counter_sebelum_0c5_0700 = val.counter_sebelum_0c5_0700;
            this.counter_sebelum_4_0700 = val.counter_sebelum_4_0700;
            this.counter_sebelum_7_0700 = val.counter_sebelum_7_0700;
            this.counter_sebelum_10_0700 = val.counter_sebelum_10_0700;
            this.counter_0c5_0700 = val.counter_0c5_0700;
            this.counter_4_0700 = val.counter_4_0700;
            this.counter_7_0700 = val.counter_7_0700;
            this.counter_10_0700 = val.counter_10_0700;
            this.ws_avg_0c5_0700 = val.ws_avg_0c5_0700;
            this.ws_avg_4_0700 = val.ws_avg_4_0700;
            this.ws_avg_7_0700 = val.ws_avg_7_0700;
            this.ws_avg_10_0700 = val.ws_avg_10_0700;
            this.wd_0700 = val.wd_0700;
            this.ws_0700 = val.ws_0700;
            this.op_diff_baca_0700 = val.op_diff_baca_0700;
            this.t_max_0700 = val.t_air_max_0700;
            this.t_min_0700 = val.t_air_min_0700;
            this.t_avg_0700 = val.t_air_avg_0700;
            this.rr_0700 = isNaN(val.rr_0700) ? "" : val.rr_0700;
            this.op_eva_0700 = val.op_eva_0700;
            this.ha_gunbellani_2_kemarin = val.h_air_gunbellani_2_kemarin;
            this.ha_gunbellani_1 = val.h_air_gunbellani_1;
            this.ha_gunbellani_2 = val.h_air_gunbellani_2;
            this.ha_diff_gunbellani = val.h_air_diff_gunbellani;
            this.rad_gunbellani = val.rad_gunbellani;
            this.ss_8 = val.ss_8;
            this.ss_12 = val.ss_12;
            this.tn_rumput_0700 = val.tn_rumput_0700 ? val.tn_rumput_0700 : "";
            this.tn_rumput_reset_0700 = val.tn_rumput_reset_0700 ? val.tn_rumput_reset_0700 : "";
            this.co_cu_0700 = val.co_cu_0700;
            this.cu_khusus = val.cu_khusus;
            this.cat_agm1a = val.cat_agm1a;
            this.cat_gunbellani = val.cat_gunbellani;
            this.ku_hujan = val.ku_hujan;
            // this.ku_salju = val.ku_salju;
            // this.ku_badai_debu = val.ku_badai_debu
            this.ku_pembekuan = val.ku_pembekuan;
            this.ku_embun = val.ku_embun;
            this.ku_kabut = val.ku_kabut;
            this.ku_tanpa_rambun = val.ku_tanpa_rambun;
            this.ku_rambun = val.ku_rambun;
            this.ku_kabut_debu = val.ku_kabut_debu;
            this.ku_angin_ribut = val.ku_angin_ribut;

            // tambahan dari Pargol
            this.$v.$touch();
        },
        tbk_1c2m_0700: function (val) {
            if (val == 9999) {
                this.rh_1c2m_0700 = 9999;
            } else {
                this.$v.tbk_1c2m_0700.$touch();
                var Es = Helper.rumus_Es(this.tbb_1c2m_0700, val);
                var E = Helper.rumus_E(val).toFixed(2);
                this.rh_1c2m_0700 = this.tbb_1c2m_0700 == 9999 ? 9999 : Math.round((Es / E) * 100); //.toFixed(1);
            }
        },
        tbb_1c2m_0700: function (val) {
            if (val == 9999) {
                this.rh_1c2m_0700 = 9999;
            } else {
                var Es = Helper.rumus_Es(val, this.tbk_1c2m_0700);
                var E = Helper.rumus_E(this.tbk_1c2m_0700).toFixed(2);
                this.rh_1c2m_0700 = this.tbk_1c2m_0700 == 9999 ? 9999 : Math.round((Es / E) * 100); //.toFixed(1);
            }
        },
        tbk_4m_0700: function (val) {
            if (val == 9999) {
                this.rh_4m_0700 = 9999;
            } else {
                var Es = Helper.rumus_Es(this.tbb_4m_0700, val);
                var E = Helper.rumus_E(val);
                this.rh_4m_0700 = this.tbb_4m_0700 == 9999 ? 9999 : Math.round((Es / E) * 100); //.toFixed(1);
            }
        },
        tbb_4m_0700: function (val) {
            if (val == 9999) {
                this.rh_4m_0700 = 9999;
            } else {
                var Es = Helper.rumus_Es(val, this.tbk_4m_0700);
                var E = Helper.rumus_E(this.tbk_4m_0700).toFixed(2);
                this.rh_4m_0700 = this.tbk_4m_0700 == 9999 ? 9999 : Math.round((Es / E) * 100); //.toFixed(1);
            }
        },
        tbk_7m_0700: function (val) {
            if (val == 9999) {
                this.rh_7m_0700 = 9999;
            } else {
                var Es = Helper.rumus_Es(this.tbb_7m_0700, val);
                var E = Helper.rumus_E(val).toFixed(2);
                this.rh_7m_0700 = this.tbb_7m_0700 == 9999 ? 9999 : Math.round((Es / E) * 100); //.toFixed(1);
            }
        },
        tbb_7m_0700: function (val) {
            if (val == 9999) {
                this.rh_7m_0700 = 9999;
            } else {
                var Es = Helper.rumus_Es(val, this.tbk_7m_0700);
                var E = Helper.rumus_E(this.tbk_7m_0700).toFixed(2);
                this.rh_7m_0700 = this.tbk_7m_0700 == 9999 ? 9999 : Math.round((Es / E) * 100); //.toFixed(1);
            }
        },
        tbk_10m_0700: function (val) {
            if (val == 9999) {
                this.rh_10m_0700 = 9999;
            } else {
                var Es = Helper.rumus_Es(this.tbb_10m_0700, val);
                var E = Helper.rumus_E(val).toFixed(2);
                this.rh_10m_0700 = this.tbb_10m_0700 == 9999 ? 9999 : Math.round((Es / E) * 100); //.toFixed(1);
            }
        },
        tbb_10m_0700: function (val) {
            if (val == 9999) {
                this.rh_10m_0700 = 9999;
            } else {
                var Es = Helper.rumus_Es(val, this.tbk_10m_0700);
                var E = Helper.rumus_E(this.tbk_10m_0700).toFixed(2);
                this.rh_10m_0700 = this.tbk_10m_0700 == 9999 ? 9999 : Math.round((Es / E) * 100); //.toFixed(1);
            }
        },

        is_counter_edit: function (val) {
            if (!val) {
                if (this.t_data_existing) {
                    this.counter_sebelum_0c5_0700 = this.t_data_existing.counter_sebelum_0c5_0700;
                    this.counter_sebelum_4_0700 = this.t_data_existing.counter_sebelum_4_0700;
                    this.counter_sebelum_7_0700 = this.t_data_existing.counter_sebelum_7_0700;
                    this.counter_sebelum_10_0700 = this.t_data_existing.counter_sebelum_10_0700;
                }
                if (this.t_data_requirement) {
                    this.counter_sebelum_0c5_0700 = this.t_data_requirement.fdih_ws0700_counter_0c5_0700 == "null" ? 0 : this.t_data_requirement.fdih_ws0700_counter_0c5_0700;
                    this.counter_sebelum_4_0700 = this.t_data_requirement.fdih_ws0700_counter_4_0700 == "null" ? 0 : this.t_data_requirement.fdih_ws0700_counter_4_0700;
                    this.counter_sebelum_7_0700 = this.t_data_requirement.fdih_ws0700_counter_7_0700 == "null" ? 0 : this.t_data_requirement.fdih_ws0700_counter_7_0700;
                    this.counter_sebelum_10_0700 = this.t_data_requirement.fdih_ws0700_counter_10_0700 == "null" ? 0 : this.t_data_requirement.fdih_ws0700_counter_10_0700;
                }

                if (this.counter_0c5_0700 != 9999 && this.counter_sebelum_0c5_0700 != 9999) {
                    this.ws_avg_0c5_0700 = parseFloat(((this.counter_0c5_0700 - this.counter_sebelum_0c5_0700) / 24).toFixed(3)).toFixed(2);
                }

                if (this.counter_4_0700 != 9999 && this.counter_sebelum_4_0700 != 9999) {
                    this.ws_avg_4_0700 = parseFloat(((this.counter_4_0700 - this.counter_sebelum_4_0700) / 13).toFixed(3)).toFixed(2);
                }

                if (this.counter_7_0700 != 9999 && this.counter_sebelum_7_0700 != 9999) {
                    this.ws_avg_7_0700 = parseFloat(((this.counter_7_0700 - this.counter_sebelum_7_0700) / 13).toFixed(3)).toFixed(2);
                }

                if (this.counter_10_0700 != 9999 && this.counter_sebelum_10_0700 != 9999) {
                    this.ws_avg_10_0700 = parseFloat(((this.counter_10_0700 - this.counter_sebelum_10_0700) / 13).toFixed(3)).toFixed(2);
                }
            }
        },

        counter_sebelum_0c5_0700: function (val) {
            if (val == 9999) {
                this.ws_avg_0c5_0700 = 9999;
            } else {
                this.ws_avg_0c5_0700 = this.counter_0c5_0700 == 9999 ? 9999 : parseFloat(((this.counter_0c5_0700 - val) / 24).toFixed(3)).toFixed(2);
            }
        },
        counter_sebelum_4_0700: function (val) {
            if (val == 9999) {
                this.ws_avg_4_0700 = 9999;
            } else {
                this.ws_avg_4_0700 = this.counter_4_0700 == 9999 ? 9999 : parseFloat(((this.counter_4_0700 - val) / 13).toFixed(3)).toFixed(2);
            }
        },
        counter_sebelum_7_0700: function (val) {
            if (val == 9999) {
                this.ws_avg_7_0700 = 9999;
            } else {
                this.ws_avg_7_0700 = this.counter_7_0700 == 9999 ? 9999 : parseFloat(((this.counter_7_0700 - val) / 13).toFixed(3)).toFixed(2);
            }
        },
        counter_sebelum_10_0700: function (val) {
            if (val == 9999) {
                this.ws_avg_10_0700 = 9999;
            } else {
                this.ws_avg_10_0700 = this.counter_10_0700 == 9999 ? 9999 : parseFloat(((this.counter_10_0700 - val) / 13).toFixed(3)).toFixed(2);
            }
        },
        counter_0c5_0700: function (val) {
            if (val == 9999) {
                this.ws_avg_0c5_0700 = 9999;
            } else {
                this.ws_avg_0c5_0700 = this.counter_sebelum_0c5_0700 == 9999 ? 9999 : parseFloat(((val - this.counter_sebelum_0c5_0700) / 24).toFixed(3)).toFixed(2);
            }
        },
        counter_4_0700: function (val) {
            if (val == 9999) {
                this.ws_avg_4_0700 = 9999;
            } else {
                this.ws_avg_4_0700 = this.counter_sebelum_4_0700 == 9999 ? 9999 : parseFloat(((val - this.counter_sebelum_4_0700) / 13).toFixed(3)).toFixed(2);
            }
        },
        counter_7_0700: function (val) {
            if (val == 9999) {
                this.ws_avg_7_0700 = 9999;
            } else {
                this.ws_avg_7_0700 = this.counter_sebelum_7_0700 == 9999 ? 9999 : parseFloat(((val - this.counter_sebelum_7_0700) / 13).toFixed(3)).toFixed(2);
            }
        },
        counter_10_0700: function (val) {
            if (val == 9999) {
                this.ws_avg_10_0700 = 9999;
            } else {
                this.ws_avg_10_0700 = this.counter_sebelum_10_0700 == 9999 ? 9999 : parseFloat(((val - this.counter_sebelum_10_0700) / 13).toFixed(3)).toFixed(2);
            }
        },
        op_diff_baca_0700: function (val) {
            // 0 = api, ambil selisih tinggi air kemarin jam 07.00
            if (val == 9999 || this.rr_0700 == 9999) {
                this.op_eva_0700 = 9999;
            } else if (this.rr_0700 == 8888) {
                this.op_eva_0700 = (+val).toFixed(1);
            } else {
                this.op_eva_0700 = (+val + +this.rr_0700).toFixed(1);
            }
        },
        t_max_0700: function (val) {
            if (val == 9999 || this.t_min_0700 == 9999) {
                this.t_avg_0700 = 9999;
            } else {
                this.t_avg_0700 = ((+val + +this.t_min_0700) / 2).toFixed(1);
            }
        },
        t_min_0700: function (val) {
            if (val == 9999 || this.t_max_0700 == 9999) {
                this.t_avg_0700 = 9999;
            } else {
                this.t_avg_0700 = parseFloat((+this.t_max_0700 + +val) / 2).toFixed(1);
            }
        },
        rr_0700: function (val) {
            if (val == 9999 || this.op_diff_baca_0700 == 9999) {
                this.op_eva_0700 = 9999;
                this.rr_0700 = val == 9999 ? 9999 : val;
            } else if (val == 8888) {
                this.rr_0700 = 8888;
                this.op_eva_0700 = parseFloat(+this.op_diff_baca_0700).toFixed(1);
            } else {
                this.op_eva_0700 = parseFloat(+this.op_diff_baca_0700 + +val).toFixed(1);
            }
        },

        is_edit_gunbellani: function (val) {
            if (!val) {
                if (this.t_data_existing) {
                    this.ha_gunbellani_2_kemarin = this.t_data_existing.h_air_gunbellani_2_kemarin;
                }
                if (this.t_data_requirement) {
                    this.ha_gunbellani_2_kemarin = this.t_data_requirement.fdih_ws0700_h_air_gunbellani_2_kemarin == "null" ? 0 : this.t_data_requirement.fdih_ws0700_h_air_gunbellani_2_kemarin;
                }

                if (this.ha_gunbellani_1 != 9999 || this.ha_gunbellani_2 != 9999) {
                    this.ha_diff_gunbellani = (this.ha_gunbellani_1 - this.ha_gunbellani_2_kemarin).toFixed(1);
                    this.rad_gunbellani = (this.ha_diff_gunbellani * 20.8).toFixed(2);
                }
            }
        },

        ha_gunbellani_2_kemarin: function (val) {
            if (val == 9999) {
                this.ha_diff_gunbellani = 9999;
                this.rad_gunbellani = 9999;
            } else if (this.ha_gunbellani_1 == 9999 || this.ha_gunbellani_2 == 9999) {
                this.ha_diff_gunbellani = 9999;
                this.rad_gunbellani = 9999;
            } else {
                this.ha_diff_gunbellani = (this.ha_gunbellani_1 - val).toFixed(1);
                this.rad_gunbellani = (this.ha_diff_gunbellani * 20.8).toFixed(2);
            }
        },

        ha_gunbellani_1: function (val) {
            // console.log('ha_gunbellani_1', val)
            if (val == 9999 || this.ha_gunbellani_2_kemarin == 9999) {
                this.ha_diff_gunbellani = 9999;
                this.rad_gunbellani = 9999;
            } else {
                if (this.ha_gunbellani_2 != 9999) {
                    // let val1 = this.t_data_requirement
                    // if (val1){
                    //   this.ha_gunbellani_2_kemarin = val1.fdih_ws0700_h_air_gunbellani_2_kemarin == "null" ? 0 : val1.fdih_ws0700_h_air_gunbellani_2_kemarin;
                    // }
                    this.ha_diff_gunbellani = (val - this.ha_gunbellani_2_kemarin).toFixed(1);
                    this.rad_gunbellani = (this.ha_diff_gunbellani * 20.8).toFixed(2);
                }
            }
        },
        ha_gunbellani_2: function (val) {
            // console.log('ha_gunbellani_2', val)
            if (val == 9999 || this.ha_gunbellani_2_kemarin == 9999) {
                this.ha_diff_gunbellani = 9999;
                this.rad_gunbellani = 9999;
            } else {
                if (this.ha_gunbellani_1 != 9999) {
                    // let val1 = this.t_data_requirement
                    // console.log('val1', val1)
                    // if (val1) {
                    //   this.ha_gunbellani_2_kemarin = val1.fdih_ws0700_h_air_gunbellani_2_kemarin == "null" ? 0 : val1.fdih_ws0700_h_air_gunbellani_2_kemarin;
                    // }
                    this.ha_diff_gunbellani = (this.ha_gunbellani_1 - this.ha_gunbellani_2_kemarin).toFixed(1);
                    this.rad_gunbellani = (this.ha_diff_gunbellani * 20.8).toFixed(2);
                }
            }
        },
    },
};
</script>
<style scoped>
.label-entry1 {
    /* text-align: center; */
    color: #3b4253;
    width: 100%;
    font-weight: 500;
    /* border: 2px solid #6e6b7b; */
    border-radius: 3px;
    padding: 4px;
}
.label-entry2 {
    text-align: center;
    color: #3b4253;
    width: 100%;
    font-weight: 500;
    border: 2px solid #6e6b7b;
    border-radius: 3px;
    padding: 4px;
}

.input-dark-grey {
    background-color: #ebedf3;
}
.mbtm {
    margin-bottom: 5px;
}
.pad2 {
    padding-left: 2px;
    padding-right: 2px;
}
.tooltip-inner {
    text-align: left;
}
.pad3 {
    padding-left: 11px;
    padding-right: 0px;
}
.bg-light-primary {
    color: #000000 !important;
}
.bg-light-success {
    color: #000000 !important;
}
</style>

<style>
/* .rangecheck {
    background-color: rgb(210, 248, 37) !important;
  } */
#input_tbk_1c2m_0700.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
#input_tbb_1c2m_0700.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
#input_tbk_4m_0700.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
#input_tbb_4m_0700.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
#input_tbk_7m_0700.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
#input_tbb_7m_0700.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
#input_tbk_10m_0700.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
#input_tbb_10m_0700.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
#input_ws_0700.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
#input_op_diff_baca_0700.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
#input_t_max_0700.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
#input_t_min_0700.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
#input_rr_0700.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
#input_ha_gunbellani_1.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
#input_ha_gunbellani_2.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
#input_ha_diff_gunbellani.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
#input_tn_rumput_0700.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
#input_tn_rumput_reset_0700.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
</style>
