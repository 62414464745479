<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <b-card-actions title="Filter" action-collapse>
          <b-row>
            <b-col sm="4">
              <b-form-group label="Stasiun" label-for="v-Name">
                <v-select v-model="t_station" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="t_station_opt" class="select-size-sm" :disabled="t_station_opt.length == 1" placeholder="Pilih Stasiun" />
                
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label="WMO ID" label-for="select-wmo">
                <v-select v-model="t_wmoid" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="t_wmoid_opt" disabled class="select-size-sm" />
                
              </b-form-group>
            </b-col>

            <b-col sm="2 pad2">
              <b-form-group label="Nama Observer" label-for="pic">
                <v-select v-model="t_observer" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="observer" :options="list_observer" class="select-size-sm" >
                </v-select>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label="Tanggal" label-for="datepicker-2">
                <b-form-datepicker
                  id="datepicker-dateformat2"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  locale="en"
                  size="sm"
                  v-model="t_date"
                  :max="max"
                  placeholder="Date"
                  style="padding: 1px;"
                />
              </b-form-group>
            </b-col>

            <b-col sm="2 pad2">
              <b-form-group label="Jam" label-for="select-jam">
                <b-form inline id="select-form">
                  <b-form-select v-model="t_time" class="col-7 mr-1" size="sm" style="height: 36px;">
                    <option disabled value="">Pilih Waktu</option>
                    <option v-if="is0700ws" value="0700ws">07.00 WS</option>
                    <option v-if="is0730ws" value="0730ws">07.30 WS</option>
                    <option v-if="is1300ws" value="1300ws">13.00 WS</option>
                    <option v-if="is1330ws" value="1330ws">13.30 WS</option>
                    <option v-if="is1400ws" value="1400ws">14.00 WS</option>
                    <option v-if="is1700ws" value="1700ws">17.00 WS</option>
                    <option v-if="is1730ws" value="1730ws">17.30 WS</option>
                    <option v-if="is1800ws" value="1800ws">18.00 WS</option>
                  </b-form-select>
                  <b-button v-on:click="onFetch" variant="primary" class="ml-sm-6" size="sm" style="height: 36px;">Fetch</b-button>
                </b-form>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-actions>
      </div>
    </div>
    <!-- Filter end -->

    <time-0700
      :data_input="data_input"
      :t_station="t_station"
      :t_station_id="t_station_id"
      :t_wmoid="t_wmoid"
      :t_observer="t_observer"
      :t_observer_name="t_observer_name"
      :t_time="t_time"
      :t_date="t_date"
      :t_all_data="t_all_data"
      :t_data_existing="t_data_existing"
      :t_data_requirement="t_data_requirement"
      :t_qc_histories="t_qc_histories"
      :t_path="t_path"
      :t_id_form="t_id_form"
      :t_data_status="t_data_status"
      @form="resetForm($event)"
      :roles="roles"
      :t_bmkgentry="t_bmkgentry"
      :d_station="d_station"
    >
    </time-0700>

    <time-0730
      :data_input="data_input"
      :t_station="t_station"
      :t_station_id="t_station_id"
      :t_wmoid="t_wmoid"
      :t_observer="t_observer"
      :t_observer_name="t_observer_name"
      :t_time="t_time"
      :t_date="t_date"
      :t_all_data="t_all_data"
      :t_data_existing="t_data_existing"
      :t_data_requirement="t_data_requirement"
      :t_qc_histories="t_qc_histories"
      :t_path="t_path"
      :t_id_form="t_id_form"
      :t_data_status="t_data_status"
      @form="resetForm($event)"
      :roles="roles"
      :t_bmkgentry="t_bmkgentry"
      :d_station="d_station"
    >
    </time-0730>

    <time-1300
      :data_input="data_input"
      :t_station="t_station"
      :t_station_id="t_station_id"
      :t_wmoid="t_wmoid"
      :t_observer="t_observer"
      :t_observer_name="t_observer_name"
      :t_time="t_time"
      :t_date="t_date"
      :t_all_data="t_all_data"
      :t_data_existing="t_data_existing"
      :t_data_requirement="t_data_requirement"
      :t_qc_histories="t_qc_histories"
      :t_path="t_path"
      :t_id_form="t_id_form"
      :t_data_status="t_data_status"
      @form="resetForm($event)"
      :roles="roles"
      :t_bmkgentry="t_bmkgentry"
      :d_station="d_station"
    >
    </time-1300>

    <time-1330
      :data_input="data_input"
      :t_station="t_station"
      :t_station_id="t_station_id"
      :t_wmoid="t_wmoid"
      :t_observer="t_observer"
      :t_observer_name="t_observer_name"
      :t_time="t_time"
      :t_date="t_date"
      :t_all_data="t_all_data"
      :t_data_existing="t_data_existing"
      :t_data_requirement="t_data_requirement"
      :t_qc_histories="t_qc_histories"
      :t_path="t_path"
      :t_id_form="t_id_form"
      :t_data_status="t_data_status"
      @form="resetForm($event)"
      :roles="roles"
      :t_bmkgentry="t_bmkgentry"
      :d_station="d_station"
    >
    </time-1330>

    <time-1400
      :data_input="data_input"
      :t_station="t_station"
      :t_station_id="t_station_id"
      :t_wmoid="t_wmoid"
      :t_observer="t_observer"
      :t_observer_name="t_observer_name"
      :t_time="t_time"
      :t_date="t_date"
      :t_all_data="t_all_data"
      :t_data_existing="t_data_existing"
      :t_data_requirement="t_data_requirement"
      :t_qc_histories="t_qc_histories"
      :t_path="t_path"
      :t_id_form="t_id_form"
      :t_data_status="t_data_status"
      @form="resetForm($event)"
      :roles="roles"
      :t_bmkgentry="t_bmkgentry"
      :d_station="d_station"
    >
    </time-1400>

    <time-1700
      :data_input="data_input"
      :t_station="t_station"
      :t_station_id="t_station_id"
      :t_wmoid="t_wmoid"
      :t_observer="t_observer"
      :t_observer_name="t_observer_name"
      :t_time="t_time"
      :t_date="t_date"
      :t_all_data="t_all_data"
      :t_data_existing="t_data_existing"
      :t_data_requirement="t_data_requirement"
      :t_qc_histories="t_qc_histories"
      :t_path="t_path"
      :t_id_form="t_id_form"
      :t_data_status="t_data_status"
      @form="resetForm($event)"
      :roles="roles"
      :t_bmkgentry="t_bmkgentry"
      :d_station="d_station"
    >
    </time-1700>

    <time-1730
      :data_input="data_input"
      :t_station="t_station"
      :t_station_id="t_station_id"
      :t_wmoid="t_wmoid"
      :t_observer="t_observer"
      :t_observer_name="t_observer_name"
      :t_time="t_time"
      :t_date="t_date"
      :t_all_data="t_all_data"
      :t_data_existing="t_data_existing"
      :t_data_requirement="t_data_requirement"
      :t_qc_histories="t_qc_histories"
      :t_path="t_path"
      :t_id_form="t_id_form"
      :t_data_status="t_data_status"
      @form="resetForm($event)"
      :roles="roles"
      :t_bmkgentry="t_bmkgentry"
      :d_station="d_station"
    >
    </time-1730>

    <time-1800
      :data_input="data_input"
      :t_station="t_station"
      :t_station_id="t_station_id"
      :t_wmoid="t_wmoid"
      :t_observer="t_observer"
      :t_observer_name="t_observer_name"
      :t_time="t_time"
      :t_date="t_date"
      :t_data_existing="t_data_existing"
      :t_data_requirement="t_data_requirement"
      :t_qc_histories="t_qc_histories"
      :t_path="t_path"
      :t_id_form="t_id_form"
      :t_data_status="t_data_status"
      @form="resetForm($event)"
      :roles="roles"
      :t_bmkgentry="t_bmkgentry"
      :d_station="d_station"
    >
    </time-1800>

    <b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay>
  </div>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import Service from "@/api/fdihservice";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BCard, BCardHeader, BContainer, BRow, BCol, BFormSelect, BForm, BButton, BFormGroup, BCollapse, BFormDatepicker, BFormInput, BOverlay } from "bootstrap-vue";
import Time0700 from "./fdihcomponent/_time0700ws.vue";
import Time0730 from "./fdihcomponent/_time0730ws.vue";
import Time1300 from "./fdihcomponent/_time1300ws.vue";
import Time1330 from "./fdihcomponent/_time1330ws.vue";
import Time1400 from "./fdihcomponent/_time1400ws.vue";
import Time1700 from "./fdihcomponent/_time1700ws.vue";
import Time1730 from "./fdihcomponent/_time1730ws.vue";
import Time1800 from "./fdihcomponent/_time1800ws.vue";
import metadata from "@/api/metadata";
import Helper from "@/api/helper";
import qc_helper from "@/api/qc_helper";
import axios from "axios";

export default {
  components: {
    BCardActions,
    BCard,
    BCardHeader,
    BContainer,
    BRow,
    BCol,
    BFormSelect,
    BForm,
    BButton,
    BFormGroup,
    BCollapse,
    BFormDatepicker,
    BFormInput,
    BOverlay,
    vSelect,
    Time0700,
    Time0730,
    Time1300,
    Time1330,
    Time1400,
    Time1700,
    Time1730,
    Time1800,
  },
  props: ["roles"],
  data: function() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    return {
      max: maxDate,
      option1: [{ title: "Charlie" }, { title: "Jhonson" }, { title: "Brithney" }, { title: "Selly" }],
      data_input: {
        i_date: "",
        i_time: "",
        i_wmoid: "",
        i_station: "",
      },
      option: "",
      t_bmkgentry: null,
      t_date: "",
      t_time: "",
      t_wmoid: "",
      t_wmoid_opt: [],
      t_wmoid_list: {},
      t_station: "",
      t_station_id: "",
      t_station_opt: [],
      t_all_data: "",
      t_data_existing: "",
      t_data_requirement: "",
      t_qc_histories: {
                        iklimmikro: null,
                        fklim: null,
                        agm1a: null,
                        agm1b: null,
                        oppenguapan: null,
                        gunbellani: null,
                        suhutanah: null,
                        psychrometerassman: null
                      },
      t_path: "",
      t_id_form: "",
      t_data_status: "",
      d_station:"",
      showLoading: false,
      t_observer: "",
      t_observer_name: "",
      t_station_path: "",
      list_observer: [],
      allListStasiun: [],
      dateDisable: false,
      is0700ws: false,
      is0730ws: false,
      is1300ws: false,
      is1330ws: false,
      is1400ws: false,
      is1700ws: false,
      is1730ws: false,
      is1800ws: false
      
    };
  },
  methods: {
    resetForm(event) {
      this.data_input.i_time = "";
      // console.log("resetForm: ", event);
    },
    onFetch() {
      if (this.t_station == "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Filter stasiun belum diisi",
            icon: "BellIcon",
            variant: "danger",
          },
        });
        return;
      } else if (this.t_observer == "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Filter observer belum diisi",
            icon: "BellIcon",
            variant: "danger",
          },
        });
        return;
      } else if (this.t_date == "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Filter tanggal belum diisi",
            icon: "BellIcon",
            variant: "danger",
          },
        });
        return;
      } else if (this.t_time == "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Waktu belum dipilih",
            icon: "BellIcon",
            variant: "danger",
          },
        });
        return;
      }

      this.t_data_existing = "",
      this.t_data_requirement = "",

      this.data_input.i_station = this.t_station;
      this.data_input.i_wmoid = this.t_wmoid;
      this.data_input.i_date = this.t_date;
      //   this.data_input.i_time = this.t_time;
      this.getDataExisting();
    },
    async getDataExisting() {
      let type = "";
      switch (this.t_time) {
        case "0700ws":
          type = "ws0700";
          break;
        case "0730ws":
          type = "ws0730";
          break;
        case "1300ws":
          type = "ws1300";
          break;
        case "1330ws":
          type = "ws1330";
          break;
        case "1400ws":
          type = "ws1400";
          break;
        case "1700ws":
          type = "ws1700";
          break;
        case "1730ws":
          type = "ws1730";
          break;
        case "1800ws":
          type = "ws1800";
          break;
      }

      try {
        var path = this.t_path + "/" + this.t_id_form;

        this.t_id_form = this.t_station_id + "_fdih_" + this.t_date;
        var path = this.t_path + "/" + this.t_id_form;
        this.t_all_data = null
        Service.getFDIH(path)
          .then((response) => {
            if (response.status == 201 || response.status == 200 || response.status == 204) {
              let data = response.data;
              this.t_all_data = data
              let ctime = "m_" + this.t_time;
              if (data[ctime] != null) {
                this.$swal({
                  title: "Data untuk waktu ini telah ada.",
                  text: "Data ini telah dientri. Tampilkan data ?",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: "View",
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  if (result.value) {
                    // this.t_data = response.data;
                    this.data_input.i_time = this.t_time;
                    this.t_data_status = "update";
                    this.showLoading = false;
                    this.t_data_existing = data[ctime];

                    this.getDataRequirement(type);
                  } else if (result.dismiss === "cancel") {
                    this.data_input.i_time = "";
                    this.t_data_status = "new";
                    this.showLoading = false;
                    return;
                  }
                });
              } else {
                this.showLoading = false;
                this.data_input.i_time = this.t_time;
                this.t_data_status = "update";//"new";
                this.getDataRequirement(type);
              }

              this.showLoading = false;
            }
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$swal({
                title: "Unauthorized!",
                text: "Sesi anda telah habis. Logout dan silahkan login kembali",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            } else if (error.response.status == 404) {
              this.showLoading = false;
              // if (type == "ws0700" || type == "ws1700") {
                this.t_data_status = "new";
                this.data_input.i_time = this.t_time;
                this.getDataRequirement(type);
              // } else {
              //   this.t_data_status = "new";
              //   this.data_input.i_time = "";
              //   this.$swal({
              //     title: "Alert",
              //     text: "Entry data 0700ws terlebih dahulu",
              //     customClass: {
              //       confirmButton: "btn btn-primary",
              //     },
              //     buttonsStyling: false,
              //   });
              // }
            } else {
              this.t_data_status = "new";
              this.$swal({
                title: "Something wrong!",
                text: "" + error.response.status,
                icon: "warning",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
            this.showLoading = false;
          });
      } catch (e) {
        this.showLoading = false;
      }
    },

    async getDataRequirement(type) {
      var path = this.t_station_path + "/@fdih_" + type + "?type_name=Fdih&station_id=" + this.t_station_id + "&data_timestamp=" + this.t_date;

      await Service.getFDIH(path)
        .then((response) => {
          this.t_data_requirement = response.data;
        })
        .catch((error) => {});

      this.checkQCNotificationExist()
    },

    async checkQCNotificationExist() {
      new Promise(async (resolve) => {
        let data = null
                  
        if (this.t_time == "0700ws") {
          try {
            // let gunbellaniID = "qcnotification_gunbellani_"+this.t_id_form
            // let gunbellani = await qc_helper.getQcNotification(gunbellaniID)
            // this.t_qc_histories.gunbellani =  gunbellani && gunbellani.data.histories ? JSON.parse(gunbellani.data.histories) : null
            let gunbellani = this.t_all_data["BmkgSatu.behaviors.qc.IFdih_Gunbellani_qc"].qc_histories_gunbellani
            this.t_qc_histories.gunbellani = gunbellani ? JSON.parse(gunbellani) : null
          }
          catch(error) {
            // console.log(error)
          }
          
          try{
            // let oppenguapanID = "qcnotification_oppenguapan_"+this.t_id_form
            // let oppenguapan = await qc_helper.getQcNotification(oppenguapanID)
            // this.t_qc_histories.oppenguapan = oppenguapan && oppenguapan.data.histories ? JSON.parse(oppenguapan.data.histories) : null
            let oppenguapan = this.t_all_data["BmkgSatu.behaviors.qc.IFdih_OpPenguapan_qc"].qc_histories_op_penguapan
            this.t_qc_histories.oppenguapan = oppenguapan ? JSON.parse(oppenguapan) : null
          }
          catch(error) {
            // console.log(error)
          }
        }

        if (this.t_time == "0700ws" || this.t_time == "1400ws" || this.t_time == "1800ws") {
          try {
            // let agm1AID = "qcnotification_agm1a_"+this.t_id_form
            // let agm1a = await qc_helper.getQcNotification(agm1AID)
            // this.t_qc_histories.agm1a = agm1a && agm1a.data.histories ? JSON.parse(agm1a.data.histories) : null
            let agm1a = this.t_all_data["BmkgSatu.behaviors.qc.IFdih_Agm1a_qc"].qc_histories_agm1a
            this.t_qc_histories.agm1a = agm1a ? JSON.parse(agm1a) : null
          }
          catch(error) {
            // console.log(error)
          }
        }

        if (this.t_time == "0700ws" || this.t_time == "1300ws" || this.t_time == "1800ws") {
          try{
            // let fklimID = "qcnotification_fklim_"+this.t_id_form
            // let fklim = await qc_helper.getQcNotification(fklimID)
            // this.t_qc_histories.fklim = fklim && fklim.data.histories ? JSON.parse(fklim.data.histories) : null
            let fklim = this.t_all_data["BmkgSatu.behaviors.qc.IFdih_Fklim_qc"].qc_histories_fklim
            this.t_qc_histories.fklim = fklim ? JSON.parse(fklim) : null
          }
          catch(error) {
            // console.log(error)
          }
        }

        if (this.t_time == "0700ws" || this.t_time == "1300ws" || this.t_time == "1800ws") {
          try{
            // let iklimmikroID = "qcnotification_iklimmikro_"+this.t_id_form
            // let iklimmikro = await qc_helper.getQcNotification(iklimmikroID)
            // this.t_qc_histories.iklimmikro = iklimmikro && iklimmikro.data.histories ? JSON.parse(iklimmikro.data.histories) : null
            let iklimmikro = this.t_all_data["BmkgSatu.behaviors.qc.IFdih_IklimMikro_qc"].qc_histories_iklim_mikro
            this.t_qc_histories.iklimmikro = iklimmikro ? JSON.parse(iklimmikro) : null
          }
          catch(error) {
            // console.log(error)
          }
        }

        if (this.t_time == "0730ws" || this.t_time == "1330ws" || this.t_time == "1730ws") {
          try{
            // let agm1bID = "qcnotification_agm1b_"+this.t_id_form
            // let agm1b = await qc_helper.getQcNotification(agm1bID)
            // this.t_qc_histories.agm1b = agm1b && agm1b.data.histories ? JSON.parse(agm1b.data.histories) : null
            let agm1b = this.t_all_data["BmkgSatu.behaviors.qc.IFdih_Agm1b_qc"].qc_histories_agm1b
            this.t_qc_histories.agm1b = agm1b ? JSON.parse(agm1b) : null
          }
          catch(error) {
            // console.log(error)
          }
        }

        if (this.t_time == "0730ws" || this.t_time == "1330ws" || this.t_time == "1730ws") {
          try{
            // let suhutanahID = "qcnotification_suhutanah_"+this.t_id_form
            // let suhutanah = await qc_helper.getQcNotification(suhutanahID)
            // this.t_qc_histories.suhutanah = suhutanah && suhutanah.data.histories ? JSON.parse(suhutanah.data.histories) : null
            let suhutanah = this.t_all_data["BmkgSatu.behaviors.qc.IFdih_SuhuTanah_qc"].qc_histories_suhu_tanah
            this.t_qc_histories.suhutanah = suhutanah ? JSON.parse(suhutanah) : null
          }
          catch(error) {
            // console.log(error)
          }
        }

        if (this.t_time == "0730ws" || this.t_time == "1330ws" || this.t_time == "1730ws") {
          try{
            // let psyAssmanID = "qcnotification_psychrometerassman_"+this.t_id_form
            // let psyAssman = await qc_helper.getQcNotification(psyAssmanID)
            // this.t_qc_histories.psychrometerassman = psyAssman && psyAssman.data.histories ? JSON.parse(psyAssman.data.histories) : null
            let psychrometerassman = this.t_all_data["BmkgSatu.behaviors.qc.IFdih_PsychrometerAssman_qc"].qc_histories_psychrometer_assman
            this.t_qc_histories.psychrometerassman = psychrometerassman ? JSON.parse(psychrometerassman) : null
          }
          catch(error) {
            // console.log(error)
          }
        }

        resolve(data)
      });
      // this.
      // console.log('qc_histories', this.t_qc_histories)
    },

    async getpath() {
      try {
        const { data } = await metadata.getPathwithStation(this.t_station_path, "FdihCollect", this.t_station.value);
        this.t_path = data[0].path;
      } catch (e) {}
    },
    async listStasiun() {
      try {
        const { data } = await metadata.getAllStasiunWithWMO();
        this.allListStasiun = data;
        this.t_station_opt = [];
        let first = {};
        for (var i = 0; i < data.length; i++) {
          const data_station = {
            value: data[i].station_id,
            text: data[i].station_name,
            path: data[i].path,
          };
          first = data_station;
          if (this.t_wmoid_list[data[i].station_id] == undefined) {
            this.t_wmoid_list[data[i].station_id] = "";
          }
          this.t_wmoid_list[data[i].station_id] = data[i].station_wmo_id;

          this.t_station_opt.push(data_station);
          this.t_station = "";
        }

        if (this.t_station_opt.length == 1) {
          this.t_station = first;
          this.t_station_path = first.path;
        }
      } catch (e) {
        // console.log(e);
      }
    },
    async listObserver(station_id) {
      this.t_observer = ""
      var data = await metadata.getObserverStation(station_id);
      this.list_observer = data;

    },
    
    async onStationSelected(value) {
      // console.log('onStationSelected', value)
      this.t_wmoid_opt = [];
      this.t_station_id = value.value;
      const data_wmo = {
        value: this.t_wmoid_list[value.value],
        text: this.t_wmoid_list[value.value],
      };
      this.t_wmoid_opt.push(data_wmo);

      this.t_wmoid = this.t_wmoid_list[value.value];
      // this.getpath();

      try {
        await this.getpath();
        this.showLoading = true;

        this.t_bmkgentry = new Promise(async (resolve) => {
          let url = Helper.getBaseUrl() + this.t_station.path;
          axios.get(url, Helper.getConfig()).then((response) => {
            if (response.status == 201 || response.status == 200 || response.status == 204) {
              this.d_station = response.data
              this.t_bmkgentry = response.data["BmkgSatu.metadata.bmkgentry.IBMKGEntry"];
              if (this.t_bmkgentry){
                this.is0700ws = this.t_bmkgentry.is_iklim_mikro || this.t_bmkgentry.is_fklim || this.t_bmkgentry.is_agm1a || this.t_bmkgentry.is_op_penguapan || this.t_bmkgentry.is_gunbellani
                this.is0730ws = this.t_bmkgentry.is_agm1b || this.t_bmkgentry.is_psychrometer_assman || this.t_bmkgentry.is_suhu_tanah || this.t_bmkgentry.is_piche_penguapan
                this.is1300ws = this.t_bmkgentry.is_iklim_mikro || this.t_bmkgentry.is_fklim
                this.is1330ws = this.t_bmkgentry.is_agm1b || this.t_bmkgentry.is_psychrometer_assman || this.t_bmkgentry.is_suhu_tanah || this.t_bmkgentry.is_piche_penguapan
                this.is1400ws = this.t_bmkgentry.is_agm1a
                this.is1700ws = this.t_bmkgentry.is_lysimeter_gundul || this.t_bmkgentry.is_lysimeter_komoditi || this.t_bmkgentry.is_lysimeter_berumput
                this.is1730ws = this.t_bmkgentry.is_agm1b || this.t_bmkgentry.is_psychrometer_assman || this.t_bmkgentry.is_suhu_tanah || this.t_bmkgentry.is_piche_penguapan
                this.is1800ws = this.t_bmkgentry.is_iklim_mikro || this.t_bmkgentry.is_fklim || this.t_bmkgentry.is_agm1a
              }
            }
          });
          this.showLoading = false;
          this.data_input.i_time = "";
          resolve(this.t_bmkgentry);
        });
        this.showLoading = false;
      } catch (e) {
        this.showLoading = false;
      }


    },
  },
  mounted() {

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    var date = yyyy + "-" + mm + "-" + dd;
    
    this.t_date = date;

    
    this.t_date = date;
    this.listStasiun();
  },
  watch: {
    t_station(value) {
      console.log('t_station', value)
      this.onStationSelected(value);
      this.listObserver(value.value);
    },

  },
};
</script>

<style scoped>
.reduce-margin-row {
  margin-bottom: -1.75rem !important;
}

.icon-right {
  float: right;
}
.pad2 {
  padding-left: 1px;
  padding-right: 1px;
}
</style>

<style>
.nav-pills {
  margin-bottom: 0rem;
}
.container {
  padding-right: 0rem;
  padding-left: 0rem;
  margin-right: 0rem;
  margin-left: 0rem;
}
.v-select {
  color: #000000;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
