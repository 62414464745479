<template>
  <div>
    <b-row class="mt-n2">
      <b-col cols="12">
        <b-card class="text-dark font-weight-bolder">
          <b-row class="">
            <b-col sm="2">
              <b-form-group  label-for="select-form" size="sm">
                <label class="label-required">Petugas Awal Sampling
                  <span class="text-danger">*</span>
                </label>
                <v-select v-model="kah_ptgs_pasang_sampling" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="observer" :options="list_observer" class="select-size-sm">
                </v-select>
                <label v-if="!kah_ptgs_pasang_sampling || !kah_ptgs_angkat_sampling || !kah_penyelia" 
                class="label">Wajib Diisi <span class="text-danger">*</span></label>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label="" label-for="select-form" size="sm">
                <label class="label-required">Petugas Akhir Sampling
                  <span class="text-danger">*</span>
                </label>
                <v-select v-model="kah_ptgs_angkat_sampling" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="observer" :options="list_observer" class="select-size-sm">
                </v-select>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label="" label-for="select-form" size="sm">
                 <label class="label-required">Petugas Analis Lab
                  <span class="text-danger">*</span>
                </label>
                <v-select v-model="kah_penyelia" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text"
                  :options="list_penyelia" class="select-size-sm">
                </v-select>
               


                
              </b-form-group>
            </b-col>

            <b-col sm="3">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Tanggal dan Jam Pemasangan" label-for="datepicker-4" sm="2">
                    <flat-pickr 
                      id="datepicker-4"
                      v-model="tgl_pasang"
                      class="form-control select-grey"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col sm="3">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Tanggal dan Jam Pengangkatan" label-for="datepicker-5" sm="2">
                    <flat-pickr
                      id="datepicker-5"
                      v-model="tgl_angkat"
                      class="form-control select-grey"
                      :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>

        <!-- <b-row class="mt-n1">
          <b-col col="12">
            <b-card class="text-dark font-weight-bolder">
                <b-col>
                  <p>SAMPEL-ID: {{ kah_id_sampel ? kah_id_sampel : getSampelID }}</p>
                </b-col>
            </b-card>
          </b-col>
        </b-row> -->
        <b-card class="text-dark font-weight-bolder mt-n1">
          <b-row>
            <b-col sm="3 mb-n2">
              <b-form-group label="ID Sample" label-for="id-sample" label-cols-md="4">
                <b-form-input id="id-sample" v-model="kah_id_sampel" disabled class="font-weight-bolder select-grey" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-row>
          <b-col cols="4">
            <b-card class="text-dark font-weight-bolder mt-n1 mr-n1 h-100">
              <b-row>
                <b-col sm="7">
                  <b-form-group label="pH" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="kah_ph" 
                        :formatter="format_two_decimal"
                        v-model.number="$v.kah_ph.$model" 
                        :state="validateState($v.kah_ph)"
                        v-on:keyup="checkValidate($event, 'kah_ph')"
                        v-bind:class="{ rangecheck: RC.kah_ph.status }"
                        class="col-8 text-center mbtm" size="sm" 
                        style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.kah_ph.status" target="kah_ph" :triggers="'hover'" placement="topleft">
                        {{ RC.kah_ph.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.kah_ph.status" :triggers="'hover'" target="kah_ph" placement="top" custom-class="validation-tooltip">
                        {{ tooltipMessage("kah_ph") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1  ml-1">pH unit</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>

                <b-col sm="5">
                  <b-form-group label="pH Flag" label-for="select-form" size="sm">
                    <b-form-select 
                      v-model="$v.kah_ph_flag.$model"
                      :state="validateState($v.kah_ph_flag)"
                      :options="flagOptionsKahPh"
                      class="col-12 mr-1" size="sm" style="height: 33px;">
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="Electro Conductivity" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="kah_conductivity" 
                        :formatter="format_one_decimal"
                        v-model.number="$v.kah_conductivity.$model"
                        :state="validateState($v.kah_conductivity)" 
                        v-on:keyup="checkValidate($event, 'kah_conductivity')"
                        v-bind:class="{ rangecheck: RC.kah_conductivity.status }"
                        class="col-8  text-center mbtm" size="sm"
                        style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.kah_conductivity.status" target="kah_conductivity" :triggers="'hover'" placement="topleft">
                        {{ RC.kah_conductivity.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.kah_conductivity.status" :triggers="'hover'" target="kah_conductivity" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("kah_conductivity") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">µS/cm</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                
                <b-col sm="5">
                  <b-form-group label="EC Flag" label-for="select-form" size="sm">
                    <b-form-select 
                      v-model="$v.kah_conductivity_flag.$model"
                      :state="validateState($v.kah_conductivity_flag)"
                      :options="flagOptionsKahConductivity"
                      class="col-12 mr-1" size="sm" style="height: 33px;">
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="Cl- (klorida)" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="kah_cl" 
                        :formatter="format_three_decimal"
                        v-model.number="$v.kah_cl.$model" 
                        :state="validateState($v.kah_cl)"
                        v-on:keyup="checkValidate($event, 'kah_cl')"
                        v-bind:class="{ rangecheck: RC.kah_cl.status }"
                        class="col-8 text-center mbtm" size="sm" style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.kah_cl.status" target="kah_cl" :triggers="'hover'" placement="topleft">
                        {{ RC.kah_cl.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.kah_cl.status" :triggers="'hover'" target="kah_cl" placement="top" custom-class="validation-tooltip">
                        {{ tooltipMessage("kah_cl") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">mg/L</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <b-col sm="5">
                  <b-form-group label="Cl- Flag" label-for="select-form" size="sm">
                    <b-form-select 
                      v-model="$v.kah_cl_flag.$model"
                      :state="validateState($v.kah_cl_flag)"
                      :options="flagOptionsKahCl"
                      class="col-12 mr-1" size="sm" style="height: 33px;">
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="SO42- (Sulfat)" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="kah_so42" 
                        :formatter="format_three_decimal"
                        v-model.number="$v.kah_so42.$model"
                        :state="validateState($v.kah_so42)" 
                        v-on:keyup="checkValidate($event, 'kah_so42')"
                        v-bind:class="{ rangecheck: RC.kah_so42.status }"
                        class="col-8 text-center mbtm" size="sm"
                        style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.kah_so42.status" target="kah_so42" :triggers="'hover'" placement="topleft">
                        {{ RC.kah_so42.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.kah_so42.status" :triggers="'hover'" target="kah_so42" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("kah_so42") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">mg/L</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <b-col sm="5">
                  <b-form-group label="SO42- Flag" label-for="select-form" size="sm">
                    <b-form-select 
                      v-model="$v.kah_so42_flag.$model"
                      :state="validateState($v.kah_so42_flag)"
                      :options="flagOptionsKahSo42"
                      class="col-12 mr-1" size="sm" style="height: 33px;">
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="NO3- (Nitrat)" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="kah_no3" 
                        :formatter="format_three_decimal"
                        v-model.number="$v.kah_no3.$model" 
                        :state="validateState($v.kah_no3)"
                        v-on:keyup="checkValidate($event, 'kah_no3')"
                        v-bind:class="{ rangecheck: RC.kah_no3.status }"
                        class="col-8 text-center mbtm" size="sm" style="height: 33px;">
                      </b-form-input>
                      <b-tooltip v-if="RC.kah_no3.status" target="kah_no3" :triggers="'hover'" placement="topleft">
                        {{ RC.kah_no3.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.kah_no3.status" :triggers="'hover'" target="kah_no3" placement="top" custom-class="validation-tooltip">
                        {{ tooltipMessage("kah_no3") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">mg/L</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>

                <b-col sm="5">
                  <b-form-group label="NO3- Flag" label-for="select-form" size="sm">
                    <b-form-select 
                      v-model="$v.kah_no3_flag.$model"
                      :state="validateState($v.kah_no3_flag)"
                      :options="flagOptionsKahNo3"
                      class="col-12 mr-1" size="sm" style="height: 33px;">
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="Na+ (Natrium)" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="kah_na" 
                        :formatter="format_three_decimal"
                        v-model.number="$v.kah_na.$model" 
                        :state="validateState($v.kah_na)"
                        v-on:keyup="checkValidate($event, 'kah_na')"
                        v-bind:class="{ rangecheck: RC.kah_na.status }"
                        class="col-8 text-center mbtm" size="sm" style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.kah_na.status" target="kah_na" :triggers="'hover'" placement="topleft">
                        {{ RC.kah_na.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.kah_na.status" :triggers="'hover'" target="kah_na" placement="top" custom-class="validation-tooltip">
                        {{ tooltipMessage("kah_na") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">mg/L</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>

                <b-col sm="5">
                  <b-form-group label="Na+ (Natrium) Flag" label-for="select-form" size="sm">
                    <b-form-select 
                      v-model="$v.kah_na_flag.$model"
                      :state="validateState($v.kah_na_flag)"
                      :options="flagOptionsKahNa"
                      class="col-12 mr-1" size="sm" style="height: 33px;">
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="NH4+ (ammonium)" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="kah_nh4" 
                        :formatter="format_three_decimal"
                        v-model.number="$v.kah_nh4.$model" 
                        :state="validateState($v.kah_nh4)"
                        v-on:keyup="checkValidate($event, 'kah_nh4')"
                        v-bind:class="{ rangecheck: RC.kah_nh4.status }"
                        class="col-8 text-center mbtm" size="sm" style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.kah_nh4.status" target="kah_nh4" :triggers="'hover'" placement="topleft">
                        {{ RC.kah_nh4.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.kah_nh4.status" :triggers="'hover'" target="kah_nh4" placement="top" custom-class="validation-tooltip">
                        {{ tooltipMessage("kah_nh4") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">mg/L</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <b-col sm="5">
                  <b-form-group label="NH4+ Flag" label-for="select-form" size="sm">
                    <b-form-select 
                      v-model="$v.kah_nh4_flag.$model"
                      :state="validateState($v.kah_nh4_flag)"
                      :options="flagOptionsKahNh4"
                      class="col-12 mr-1" size="sm" style="height: 33px;">
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col cols="4">
            <b-card class="text-dark font-weight-bolder mt-n1 mr-n1 h-100">
              <b-row>
                <b-col sm="7">
                  <b-form-group label="K+ (Kalium)" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="kah_k" 
                        :formatter="format_three_decimal"
                        v-model.number="$v.kah_k.$model" 
                        :state="validateState($v.kah_k)"
                        v-on:keyup="checkValidate($event, 'kah_k')"
                        v-bind:class="{ rangecheck: RC.kah_k.status }"
                        class="col-8  text-center mbtm" size="sm" style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.kah_k.status" target="kah_k" :triggers="'hover'" placement="topleft">
                        {{ RC.kah_k.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.kah_k.status" :triggers="'hover'" target="kah_k" placement="top" custom-class="validation-tooltip">
                        {{ tooltipMessage("kah_k") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">mg/L</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>

                <b-col sm="5">
                  <b-form-group label="K+ Flag" label-for="select-form" size="sm">
                    <b-form-select 
                      v-model="$v.kah_k_flag.$model"
                      :state="validateState($v.kah_k_flag)"
                      :options="flagOptionsKahK"
                      class="col-12 mr-1" size="sm" style="height: 33px;">
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="Mg2+ (Magnesium)" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="kah_mg2" 
                        :formatter="format_three_decimal"
                        v-model.number="$v.kah_mg2.$model" 
                        :state="validateState($v.kah_mg2)"
                        v-on:keyup="checkValidate($event, 'kah_mg2')"
                        v-bind:class="{ rangecheck: RC.kah_mg2.status }"
                        class="col-8 text-center mbtm" size="sm" style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.kah_mg2.status" target="kah_mg2" :triggers="'hover'" placement="topleft">
                        {{ RC.kah_mg2.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.kah_mg2.status" :triggers="'hover'" target="kah_mg2" placement="top" custom-class="validation-tooltip">
                        {{ tooltipMessage("kah_mg2") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">mg/L</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <b-col sm="5">
                  <b-form-group label="Mg2+ Flag" label-for="select-form" size="sm">
                    <b-form-select 
                      v-model="$v.kah_mg2_flag.$model"
                      :state="validateState($v.kah_mg2_flag)"
                      :options="flagOptionsKahMg2"
                      class="col-12 mr-1" size="sm" style="height: 33px;">
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="Ca2+ (Calsium)" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="kah_ca2" 
                        :formatter="format_three_decimal"
                        v-model.number="$v.kah_ca2.$model" 
                        :state="validateState($v.kah_ca2)"
                        v-on:keyup="checkValidate($event, 'kah_ca2')"
                        v-bind:class="{ rangecheck: RC.kah_ca2.status }"
                        class="col-8 text-center mbtm" size="sm" style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.kah_ca2.status" target="kah_ca2" :triggers="'hover'" placement="topleft">
                        {{ RC.kah_ca2.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.kah_ca2.status" :triggers="'hover'" target="kah_ca2" placement="top" custom-class="validation-tooltip">
                        {{ tooltipMessage("kah_ca2") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">mg/L</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">mg/L</label>
                </b-col> -->
                <b-col sm="5">
                  <b-form-group label="Ca2+ Flag" label-for="select-form" size="sm">
                    <b-form-select 
                      v-model="$v.kah_ca2_flag.$model"
                      :state="validateState($v.kah_ca2_flag)"
                      :options="flagOptionsKahCa2"
                      class="col-12 mr-1" size="sm" style="height: 33px;">
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="Acidity" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="kah_acidity" 
                        :formatter="format_one_decimal"
                        v-model.number="$v.kah_acidity.$model"
                        :state="validateState($v.kah_acidity)" 
                        v-on:keyup="checkValidate($event, 'kah_acidity')"
                        v-bind:class="{ rangecheck: RC.kah_acidity.status }"
                        class="col-8 text-center mbtm" size="sm"
                        style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.kah_acidity.status" target="kah_acidity" :triggers="'hover'" placement="topleft">
                        {{ RC.kah_acidity.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.kah_acidity.status" :triggers="'hover'" target="kah_acidity" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("kah_acidity") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">mg/L</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">mg/L</label>
                </b-col> -->
                <b-col sm="5">
                  <b-form-group label="Acidity Flag" label-for="select-form" size="sm">
                    <b-form-select 
                      v-model="$v.kah_acidity_flag.$model"
                      :state="validateState($v.kah_acidity_flag)"
                      :options="flagOptionsKahAcidity"
                      class="col-12 mr-1" size="sm" style="height: 33px;">
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="Alkalinity" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="kah_alkalinity" 
                        :formatter="format_one_decimal"
                        v-model.number="$v.kah_alkalinity.$model"
                        :state="validateState($v.kah_alkalinity)" 
                        v-on:keyup="checkValidate($event, 'kah_alkalinity')"
                        v-bind:class="{ rangecheck: RC.kah_alkalinity.status }"
                        class="col-8 text-center mbtm" size="sm"
                        style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.kah_alkalinity.status" target="kah_alkalinity" :triggers="'hover'" placement="topleft">
                        {{ RC.kah_alkalinity.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.kah_alkalinity.status" :triggers="'hover'" target="kah_alkalinity" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("kah_alkalinity") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">mg/L</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">mg/L</label>
                </b-col> -->
                <b-col sm="5">
                  <b-form-group label="Alkalinity Flag" label-for="select-form" size="sm">
                    <b-form-select 
                      v-model="$v.kah_alkalinity_flag.$model"
                      :state="validateState($v.kah_alkalinity_flag)"
                      :options="flagOptionsKahAlkalinity"
                      class="col-12 mr-1" size="sm" style="height: 33px;">
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="Curah Hujan Sampling" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="kah_curah_hujan_sampling" 
                        :formatter="format_one_decimal"
                        v-model.number="$v.kah_curah_hujan_sampling.$model"
                        :state="validateState($v.kah_curah_hujan_sampling)" 
                        v-on:keyup="checkValidate($event, 'kah_curah_hujan_sampling')"
                        v-bind:class="{ rangecheck: RC.kah_curah_hujan_sampling.status }"
                        class="col-8 text-center" size="sm"
                        style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.kah_curah_hujan_sampling.status" target="kah_curah_hujan_sampling" :triggers="'hover'" placement="topleft">
                        {{ RC.kah_curah_hujan_sampling.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.kah_curah_hujan_sampling.status" :triggers="'hover'" target="kah_curah_hujan_sampling" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("kah_curah_hujan_sampling") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">mm</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">mm</label>
                </b-col> -->
                <b-col sm="5">
                  <b-form-group label="CH sampling  Flag" label-for="select-form" size="sm">
                    <b-form-select 
                      v-model="$v.kah_curah_hujan_sampling_flag.$model"
                      :state="validateState($v.kah_curah_hujan_sampling_flag)"
                      :options="flagOptionsKahCurahHujanSampling"
                      class="col-12 mr-1" size="sm" style="height: 33px;">
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="Massa sampling" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="kah_massa_sampling" 
                        :formatter="format_one_decimal"
                        v-model.number="$v.kah_massa_sampling.$model"
                        :state="validateState($v.kah_massa_sampling)" 
                        v-on:keyup="checkValidate($event, 'kah_massa_sampling')"
                        v-bind:class="{ rangecheck: RC.kah_massa_sampling.status }"
                        class="col-8 text-center" size="sm"
                        style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.kah_massa_sampling.status" target="kah_massa_sampling" :triggers="'hover'" placement="topleft">
                        {{ RC.kah_massa_sampling.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.kah_massa_sampling.status" :triggers="'hover'" target="kah_massa_sampling" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("kah_massa_sampling") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">g</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">gram</label>
                </b-col> -->
                <b-col sm="5">
                  <b-form-group label="Massa sampling Flag" label-for="select-form" size="sm">
                    <b-form-select 
                      v-model="$v.kah_massa_sampling_flag.$model"
                      :state="validateState($v.kah_massa_sampling_flag)"
                      :options="flagOptionsKahMassaSampling"
                      class="col-12 mr-1" size="sm" style="height: 33px;">
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

            </b-card>
          </b-col>

          <b-col cols="4">
            <b-card class="text-dark font-weight-bolder mt-n1 h-100">
              <b-row>
                <b-col sm="12">
                  <b-form-group label="Kualitas Sampel" label-for="select-form" size="sm">
                    <v-select v-model="kah_kualitas_sampel" :reduce="(option) => option.value" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="text" :options="list_kah_kualitas_sampel" class="select-size-sm">
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="12">
                  <b-form-group label="Catatan Lab" label-for="select-form" size="sm">
                    <v-select v-model="kah_catatan_lab" :reduce="(option) => option.value" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="text" :options="list_kah_catatan_lab" class="select-size-sm">
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="12">
                  <b-form-group label="Kejadian Luar Biasa" label-for="select-form-lain" size="sm">
                    <v-select v-model="kah_kejadian_luar_biasa" :reduce="(option) => option.value" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="text" :options="list_kejadian_lainnya" class="select-size-sm">
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <b-row>
                    <b-col>
                      <label for="textarea-catatan-lainnya" class="font-weight-bolder"> Catatan Lainnya </label>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12">
                      <b-form-textarea id="textarea-catatan-lainnya" placeholder="Catatan Lainnya"
                        v-model="kah_catatan_lainnya"></b-form-textarea>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <!-- buat table statis 2 kolom-->
              <b-row></b-row>
              <b-row style="margin-top:20px">
                <b-col cols="12">
                  <b-row>
                    <b-col sm="12">
                      <label for="keterangan" class="font-weight-bolder"> Keterangan </label>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-row>
                    <b-col sm="1">
                      <label for="label-keterangan" class="font-weight-bolder"> M </label>
                    </b-col>
                    <b-col sm="10">
                      <label for="label-keterangan-value" class="font-weight-bolder"> <span
                          style="margin-left:-6px">:</span> Missing - no value is available</label>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-row>
                    <b-col sm="1">
                      <label for="label-keterangan" class="font-weight-bolder"> X </label>
                    </b-col>
                    <b-col sm="10">
                      <label for="label-keterangan-value" class="font-weight-bolder"> <span
                          style="margin-left:-6px">:</span> Missing – value is invalid due to gauge malfunction,
                        precipitation sampler malfunction, sample contamination, mishandling, etc. </label>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-row>
                    <b-col sm="1">
                      <label for="label-keterangan" class="font-weight-bolder"> V </label>
                    </b-col>
                    <b-col sm="10">
                      <label for="label-keterangan-value" class="font-weight-bolder"> <span
                          style="margin-left:-6px">:</span> Valid measurement </label>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-row>
                    <b-col sm="1">
                      <label for="label-keterangan" class="font-weight-bolder"> D </label>
                    </b-col>
                    <b-col sm="10">
                      <label for="label-keterangan-value" class="font-weight-bolder"> <span
                          style="margin-left:-6px">:</span> Valid measurement that is at or below the method detection
                        limit and is set equal to the detection limit </label>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-row>
                    <b-col sm="1">
                      <label for="label-keterangan" class="font-weight-bolder"> L </label>
                    </b-col>
                    <b-col sm="10">
                      <label for="label-keterangan-value" class="font-weight-bolder"> <span
                          style="margin-left:-6px">:</span> Valid measurement that is below the method detection limit
                      </label>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>



            </b-card>
          </b-col>

        </b-row>

      </b-col>
    </b-row>

    <b-row class="mt-n2">
      <b-col cols="12">
        <b-card>
          <b-row class="float-right">
            <div>
              <b-button variant="danger" @click="resetForm('batal')" class="mr-2" size="sm">
                <feather-icon
                  icon="XIcon"
                  class="mr-50"
                />
                <span class="align-middle">Batal</span>
              </b-button>
              <b-button variant="warning" @click="V_Form" v-if="this.t_data_status == 'update'" class="mr-2" size="sm"
                :disabled="!roles.isRoleEdit">
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span class="align-middle">Update</span>
              </b-button>
              <b-button variant="primary" @click="V_Form" v-if="this.t_data_status == 'insert'" class="mr-2" size="sm"
                :disabled="!roles.isRoleAdd">
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                <span class="align-middle">Submit</span>
              </b-button>
            </div>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay>
  </div>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { BRow, BCol, BTable, BCardText, BFormInput, BContainer, BFormSelect, BForm, BButton, BCard, VBTooltip, BTooltip, BFormGroup, BFormTextarea, BFormDatepicker, BOverlay } from "bootstrap-vue";
import { methods } from "vue-echarts";
import axios from "axios";
import Helper from "@/api/helper";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import error_handler from "@/api/error_handler";
import { validationMixin } from "vuelidate";
import { required, minLength, between } from "vuelidate/lib/validators";
import { GC_PH, GC_CONDUCTIVITY, GC_CL, GC_SO42, GC_NO3, GC_NA, GC_NH4, GC_K, GC_MG2, GC_CA2, GC_ACIDITY, GC_ALKALINITY, GC_CURAH_HUJAN_SAMPLING, GC_MASSA_SAMPLING, GC_KU } from "@/validators/kuvalidator";

import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import moment from "moment";
import labkuservice from "@/api/labkuservice";
import flatPickr from 'vue-flatpickr-component'
import Params from "@/attr/metadata/params";
import RC_helper from "@/validators/RC_helper";
export default {
  name: "KahForm",
  components: {
    BCardActions,
    BRow,
    BCol,
    BTable,
    BCardText,
    BFormInput,
    BContainer,
    BButton,
    BCard,
    VBTooltip,
    BTooltip,
    ToastificationContent,
    BFormSelect,
    BForm,
    BFormGroup,
    vSelect,
    flatPickr,
    BFormTextarea,
    BFormDatepicker,
    BOverlay
  },
  props: ["t_station", "d_station", "t_id_form", "t_data", "t_data_status", "t_path", "t_path_update", "list_observer", "list_penyelia", "t_year", "t_periode", "roles","changeForm"],
  mixins: [validationMixin],
  validations: {
    kah_ph: {  GC_PH },
    kah_conductivity: {  GC_CONDUCTIVITY },
    kah_cl: {  GC_CL },
    kah_so42: {  GC_SO42 },
    kah_no3: {  GC_NO3 },
    kah_na: {  GC_NA },
    kah_nh4: {  GC_NH4 },
    kah_k: {  GC_K },
    kah_mg2: {  GC_MG2 },
    kah_ca2: {  GC_CA2 },
    kah_acidity: {  GC_ACIDITY },
    kah_curah_hujan_sampling: {required, GC_CURAH_HUJAN_SAMPLING },
    kah_alkalinity: {  GC_ALKALINITY },
    kah_massa_sampling: { GC_MASSA_SAMPLING },
    kah_ph_flag: {
      consistency(val) {
        if(this.kah_ph && this.kah_ph != "" && this.kah_ph != null) {
          return val ? true : false
        }
        return true
      }
    },
    kah_conductivity_flag: {
      consistency(val) {
        if(this.kah_conductivity && this.kah_conductivity != "" && this.kah_conductivity != null) {
          return val ? true : false
        }
        return true
      }
    },
    kah_cl_flag: {
      consistency(val) {
        if(this.kah_cl && this.kah_cl != "" && this.kah_cl != null) {
          return val ? true : false
        }
        return true
      }
    },
    kah_so42_flag: {
      consistency(val) {
        if(this.kah_so42 && this.kah_so42 != "" && this.kah_so42 != null) {
          return val ? true : false
        }
        return true
      }
    },       
    kah_no3_flag: {
      consistency(val) {
        if(this.kah_no3 && this.kah_no3 != "" && this.kah_no3 != null) {
          return val ? true : false
        }
        return true
      }
    },
    kah_na_flag: {
      consistency(val) {
        if(this.kah_na && this.kah_na != "" && this.kah_na != null) {
          return val ? true : false
        }
        return true
      }
    },
    kah_ng4_flag: {
      consistency(val) {
        if(this.kah_ng4 && this.kah_ng4 != "" && this.kah_ng4 != null) {
          return val ? true : false
        }
        return true
      }
    },
    kah_nh4_flag: {
      consistency(val) {
        if(this.kah_nh4 && this.kah_nh4 != "" && this.kah_nh4 != null) {
          return val ? true : false
        }
        return true
      }
    },
    kah_k_flag: {
      consistency(val) {
        if(this.kah_k && this.kah_k != "" && this.kah_k != null) {
          return val ? true : false
        }
        return true
      }
    },     
    kah_mg2_flag: {
      consistency(val) {
        if(this.kah_mg2 && this.kah_mg2 != "" && this.kah_mg2 != null) {
          return val ? true : false
        }
        return true
      }
    },
    kah_ca2_flag: {
      consistency(val) {
        if(this.kah_ca2 && this.kah_ca2 != "" && this.kah_ca2 != null) {
          return val ? true : false
        }
        return true
      }
    },
    kah_acidity_flag: {
      consistency(val) {
        if(this.kah_acidity && this.kah_acidity != "" && this.kah_acidity != null) {
          return val ? true : false
        }
        return true
      }
    },
    kah_alkalinity_flag: {
      consistency(val) {
        if(this.kah_alkalinity && this.kah_alkalinity != "" && this.kah_alkalinity != null) {
          return val ? true : false
        }
        return true
      }
    },
    kah_curah_hujan_sampling_flag: {
      consistency(val) {
        if(this.kah_curah_hujan_sampling && this.kah_curah_hujan_sampling != "" && this.kah_curah_hujan_sampling != null) {
          return val ? true : false
        }
        return true
      }
    },
    kah_massa_sampling_flag: {
      consistency(val) {
        if(this.kah_massa_sampling && this.kah_massa_sampling != "" && this.kah_massa_sampling != null) {
          return val ? true : false
        }
        return true
      }
    }
  },
  data() {
    return {
      showLoading: false,
      RC_helper_kah: { ...RC_helper },
      kah_id_sampel: "",
      kah_ptgs_pasang_sampling: "",
      kah_ptgs_angkat_sampling: "",
      kah_penyelia: "",
      kond_lingk: "",
      tgl_pasang: null,
      tgl_angkat: null,
      jam_pasang: "",
      jam_angkat: "",
      kah_ph: "",
      kah_ph_flag: "",
      kah_conductivity: "",
      kah_conductivity_flag: null,
      kah_cl: "",
      kah_cl_flag: null, 
      kah_so42: "",
      kah_so42_flag: null,
      kah_no3: "",
      kah_no3_flag: null,
      kah_na: "",
      kah_na_flag: null,
      kah_ng4: "",
      kah_ng4_flag: null,
      kah_nh4: "",
      kah_nh4_flag: null,
      kah_k: "",
      kah_k_flag: null,
      kah_mg2: "",
      kah_mg2_flag: null,
      kah_ca2: "",
      kah_ca2_flag: null,
      kah_acidity: "",
      kah_acidity_flag: null,
      kah_alkalinity: "",
      kah_alkalinity_flag: null,
      kah_curah_hujan_sampling: "",
      kah_curah_hujan_sampling_flag: null,
      kah_massa_sampling: "",
      kah_massa_sampling_flag: null,
      kah_kualitas_sampel: "",
      kah_catatan_lab: "",
      kah_kejadian_luar_biasa: "",
      kah_catatan_lainnya: "",
      kah_sekuens: null,
      qc_flag: 0,
      qc_flag_before: 0,
      last_qc_histories: {},
      DATA_RC: {},
      RC: {
        kah_ph: { status: false, message: "" },
        kah_conductivity: { status: false, message: "" },
        kah_cl: { status: false, message: "" },
        kah_so42: { status: false, message: "" },
        kah_no3: { status: false, message: "" },
        kah_na: { status: false, message: "" },
        kah_ng4: { status: false, message: "" },
        kah_nh4: { status: false, message: "" },
        kah_k: { status: false, message: "" },
        kah_mg2: { status: false, message: "" },
        kah_ca2: { status: false, message: "" },
        kah_acidity: { status: false, message: "" },
        kah_alkalinity: { status: false, message: "" },
        kah_curah_hujan_sampling: { status: false, message: "" },
        kah_massa_sampling: { status: false, message: "" },
      },
      list_kah_ph_flag: [
        'M', 'X', 'V', 'D', 'L'
      ],
      flagOptions: [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ],
      flagOptionsKahPh: [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ],
      flagOptionsKahConductivity: [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ],
      flagOptionsKahCl: [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ],
      flagOptionsKahSo42: [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ],
      flagOptionsKahNo3: [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ],
      flagOptionsKahNa: [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ],
      flagOptionsKahNh4: [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ],
      flagOptionsKahK: [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ],
      flagOptionsKahMg2: [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ],
      flagOptionsKahCa2: [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ],
      flagOptionsKahAcidity: [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ],
      flagOptionsKahAlkalinity: [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ],
      flagOptionsKahCurahHujanSampling: [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ],
      flagOptionsKahMassaSampling: [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ],
      list_kah_catatan_lab: [],
      list_kejadian_lainnya: [],
      slist_kejadian_lainnya: [],
      list_kah_kualitas_sampel: [],

      list_jam_ambil: [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
      ],

    };
  },
  computed: {
    total: function () {

    },
  },
  mounted() {
    this.getKejadianLuarBiasa()
    this.getCatatanLab()
    this.getKualitasSampel()
    if (this.t_data_status == "update") {
      this.popupData();
    } else {
      this.resetForm();
      this.getSampelID()
    }
    this.initialize()
  },
  watch: {
    t_year(value) {
      this.getSampelID();
    },
    t_periode(value){
      this.getSampelID();
    },
    t_station(value) {
      // console.log('t_station',value);
    },
    t_data_status(newValue, OldValue) {
      this.getKejadianLuarBiasa()
      this.getCatatanLab()
      this.getKualitasSampel()
      if (newValue == "update") {
        this.popupData();
      } else {
        this.resetForm();
        this.getSampelID()
      }
      this.checkValidateAll()
    },
    count_form_click(NewValue, OldValue) {
      if (this.t_data_status == "insert") {
        if (NewValue != OldValue) {
          this.resetForm();
        }
      }
    },
    kah_ph(newValue, OldValue) {
      if (newValue == 9999) {
        let flagOptions = this.checkFlagOptionSelection(true, newValue, this.flagOptionsKahPh)
        this.flagOptionsKahPh = flagOptions
        this.kah_ph_flag = 'M'
      }
      else {
        if (OldValue == 9999) {
          let flagOptions = this.checkFlagOptionSelection(false, OldValue, this.flagOptionsKahPh)
          this.flagOptionsKahPh = flagOptions
          this.kah_ph_flag = ''
        }
      }
    },

    kah_conductivity(newValue, OldValue) {
      if (newValue == 9999) {
        let flagOptions = this.checkFlagOptionSelection(true, newValue, this.flagOptionsKahConductivity)
        this.flagOptionsKahConductivity = flagOptions
        this.kah_conductivity_flag = 'M'
      }
      else {
        if (OldValue == 9999) {
          let flagOptions = this.checkFlagOptionSelection(false, OldValue, this.flagOptionsKahConductivity)
          this.flagOptionsKahConductivity = flagOptions
          this.kah_conductivity_flag = ''
        }
      }
    },

    kah_cl(newValue, OldValue) {
      if (newValue == 9999) {
        let flagOptions = this.checkFlagOptionSelection(true, newValue, this.flagOptionsKahCl)
        this.flagOptionsKahCl = flagOptions
        this.kah_cl_flag = 'M'
      }
      else {
        if (OldValue == 9999) {
          let flagOptions = this.checkFlagOptionSelection(false, OldValue, this.flagOptionsKahCl)
          this.flagOptionsKahCl = flagOptions
          this.kah_cl_flag = ''
        }
      }
    },

    kah_so42(newValue, OldValue) {
      if (newValue == 9999) {
        let flagOptions = this.checkFlagOptionSelection(true, newValue, this.flagOptionsKahSo42)
        this.flagOptionsKahSo42 = flagOptions
        this.kah_so42_flag = 'M'
      }
      else {
        if (OldValue == 9999) {
          let flagOptions = this.checkFlagOptionSelection(false, OldValue, this.flagOptionsKahSo42)
          this.flagOptionsKahSo42 = flagOptions
          this.kah_so42_flag = ''
        }
      }
    },

    kah_no3(newValue, OldValue) {
      if (newValue == 9999) {
        let flagOptions = this.checkFlagOptionSelection(true, newValue, this.flagOptionsKahNo3)
        this.flagOptionsKahNo3 = flagOptions
        this.kah_no3_flag = 'M'
      }
      else {
        if (OldValue == 9999) {
          let flagOptions = this.checkFlagOptionSelection(false, OldValue, this.flagOptionsKahNo3)
          this.flagOptionsKahNo3 = flagOptions
          this.kah_no3_flag = ''
        }
      }
    },

    kah_na(newValue, OldValue) {
      if (newValue == 9999) {
        let flagOptions = this.checkFlagOptionSelection(true, newValue, this.flagOptionsKahNa)
        this.flagOptionsKahNa = flagOptions
        this.kah_na_flag = 'M'
      }
      else {
        if (OldValue == 9999) {
          let flagOptions = this.checkFlagOptionSelection(false, OldValue, this.flagOptionsKahNa)
          this.flagOptionsKahNa = flagOptions
          this.kah_na_flag = ''
        }
      }
    },

    kah_nh4(newValue, OldValue) {
      if (newValue == 9999) {
        let flagOptions = this.checkFlagOptionSelection(true, newValue, this.flagOptionsKahNh4)
        this.flagOptionsKahNh4 = flagOptions
        this.kah_nh4_flag = 'M'
      }
      else {
        if (OldValue == 9999) {
          let flagOptions = this.checkFlagOptionSelection(false, OldValue, this.flagOptionsKahNh4)
          this.flagOptionsKahNh4 = flagOptions
          this.kah_nh4_flag = ''
        }
      }
    },

    kah_k(newValue, OldValue) {
      if (newValue == 9999) {
        let flagOptions = this.checkFlagOptionSelection(true, newValue, this.flagOptionsKahK)
        this.flagOptionsKahK = flagOptions
        this.kah_k_flag = 'M'
      }
      else {
        if (OldValue == 9999) {
          let flagOptions = this.checkFlagOptionSelection(false, OldValue, this.flagOptionsKahK)
          this.flagOptionsKahK = flagOptions
          this.kah_k_flag = ''
        }
      }
    },

    kah_mg2(newValue, OldValue) {
      if (newValue == 9999) {
        let flagOptions = this.checkFlagOptionSelection(true, newValue, this.flagOptionsKahMg2)
        this.flagOptionsKahMg2 = flagOptions
        this.kah_mg2_flag = 'M'
      }
      else {
        if (OldValue == 9999) {
          let flagOptions = this.checkFlagOptionSelection(false, OldValue, this.flagOptionsKahMg2)
          this.flagOptionsKahMg2 = flagOptions
          this.kah_mg2_flag = ''
        }
      }
    },

    kah_ca2(newValue, OldValue) {
      if (newValue == 9999) {
        let flagOptions = this.checkFlagOptionSelection(true, newValue, this.flagOptionsKahCa2)
        this.flagOptionsKahCa2 = flagOptions
        this.kah_ca2_flag = 'M'
      }
      else {
        if (OldValue == 9999) {
          let flagOptions = this.checkFlagOptionSelection(false, OldValue, this.flagOptionsKahCa2)
          this.flagOptionsKahCa2 = flagOptions
          this.kah_ca2_flag = ''
        }
      }
    },

    kah_acidity(newValue, OldValue) {
      if (newValue == 9999) {
        let flagOptions = this.checkFlagOptionSelection(true, newValue, this.flagOptionsKahAcidity)
        this.flagOptionsKahAcidity = flagOptions
        this.kah_acidity_flag = 'M'
      }
      else {
        if (OldValue == 9999) {
          let flagOptions = this.checkFlagOptionSelection(false, OldValue, this.flagOptionsKahAcidity)
          this.flagOptionsKahAcidity = flagOptions
          this.kah_acidity_flag = ''
        }
      }
    },

    kah_alkalinity(newValue, OldValue) {
      if (newValue == 9999) {
        let flagOptions = this.checkFlagOptionSelection(true, newValue, this.flagOptionsKahAlkalinity)
        this.flagOptionsKahAlkalinity = flagOptions
        this.kah_alkalinity_flag = 'M'
      }
      else {
        if (OldValue == 9999) {
          let flagOptions = this.checkFlagOptionSelection(false, OldValue, this.flagOptionsKahAlkalinity)
          this.flagOptionsKahAlkalinity = flagOptions
          this.kah_alkalinity_flag = ''
        }
      }
    },

    kah_curah_hujan_sampling(newValue, OldValue) {
      if (newValue == 9999 || newValue == 8888) {
        let flagOptions = this.checkFlagOptionSelection(true, newValue, this.flagOptionsKahCurahHujanSampling)
        this.flagOptionsKahCurahHujanSampling = flagOptions
        this.kah_curah_hujan_sampling_flag = 'M'
      }
      else {
        if (OldValue == 9999 || OldValue == 8888) {
          let flagOptions = this.checkFlagOptionSelection(false, OldValue, this.flagOptionsKahCurahHujanSampling)
          this.flagOptionsKahCurahHujanSampling = flagOptions
          this.kah_curah_hujan_sampling_flag = ''
        }
      }
    },

    kah_massa_sampling(newValue, OldValue) {
      if (newValue == 9999 || newValue == 8888) {
        let flagOptions = this.checkFlagOptionSelection(true, newValue, this.flagOptionsKahMassaSampling)
        this.flagOptionsKahMassaSampling = flagOptions
        this.kah_massa_sampling_flag = 'M'
      }
      else {
        if (OldValue == 9999 || OldValue == 8888) {
          let flagOptions = this.checkFlagOptionSelection(false, OldValue, this.flagOptionsKahMassaSampling)
          this.flagOptionsKahMassaSampling = flagOptions
          this.kah_massa_sampling_flag = ''
        }
      }
    },
  },
  methods: {

    format_one_decimal(e) {
      if (Math.round(e * 10) / 10 != e) {
        let text = String(e);
        let filter = text.substring(0, text.length - 1);
        // console.log("filter", filter);
        return filter;
      } else {
        if (Math.floor(e) === e) {
          // console.log("filter e", e);
          return e;
        } else {
          // console.log("filter else", e);
          if (e.toString().indexOf(".") > 0) {
            let count = e.toString().split(".")[1].length || 0;
            return count > 1 ? parseFloat(e).toFixed(1) : e;
          } else {
            return e;
          }
        }
        return e;
      }
    },

    format_two_decimal(e, event) {
      if (Math.round(e * 100) / 100 != e) {
        let text = String(e);
        let filter = text.substring(0, text.length - 1);
        return filter;
      } else {
        if (Math.floor(e) === e) {
          return e;
        } else {
          if (e.toString().indexOf(".") > 0) {
            let count = e.toString().split(".")[1].length || 0;
            return count > 2 ? parseFloat(e).toFixed(2) : e;
          } else {
            return e;
          }
        }

        return e;
      }
    },

    format_three_decimal(e, event) {
      if (Math.round(e * 1000) / 1000 != e) {
        let text = String(e);
        let filter = text.substring(0, text.length - 1);
        return filter;
      } else {
        if (Math.floor(e) === e) {
          return e;
        } else {
          if (e.toString().indexOf(".") > 0) {
            let count = e.toString().split(".")[1].length || 0;
            return count > 3 ? parseFloat(e).toFixed(3) : e;
          } else {
            return e;
          }
        }

        return e;
      }
    },

    initialize() {
      this.setQCData();
      if (this.t_data_status == "update") {
        this.checkValidateAll()
      }
    },

    async getSampelID() {
      try {
        let { data } = await labkuservice.getUsageStationID(this.t_station.path)
        var kodestasiun = data['BmkgSatu.metadata.station.behavusage.IBehavStationUsage']['usage_station_id']
      } catch (e) {
        console.log(e)
      }
      try {
        const { data } = await labkuservice.getSekuens(this.t_path, String(this.t_year.title));
        var sekuens = data.items_total
      } catch (e) {
        console.log(e)
      }
      var tahun = parseInt(this.t_year.title)
      var tglAwalTahun
      /**
       * Tanggal 3 Januari 2022 kebetulan adalah tanggal patokan hitung P1 untuk Lab KU
       * 3 Januari 2022 adalah tanggal ANGKAT pertama, menandakan starting point 
       * untuk Periode 1 di tahun 2022
       * 
       * KHUSUS KAH, TANGGAL 3 ADALAH TANGGAL ANGKAT, BUKAN TANGGAL PASANG
       * 
       * Konsepnya adalah menggunakan basis 3 Januari 2022, dihitunglah P1 untuk tahun-tahun
       * yang lain, baik sebelum maupun sesudah 2022
       * 
       */
      if (tahun == 2022) {
        tglAwalTahun = moment('2022-01-03T09:00').utcOffset('+0700')
      } else if (tahun > 2022) {
        tglAwalTahun = moment(tahun + '-01-01T09:00').startOf('IsoWeek').utcOffset('+0700');
        if (tglAwalTahun.year() == tahun - 1) {
          tglAwalTahun = moment(tahun + '-01-07T09:00').startOf('IsoWeek').utcOffset('+0700');
        }
      } else if (tahun < 2022) {
        tglAwalTahun = moment(tahun + '-01-01T09:00').startOf('IsoWeek').utcOffset('+0700');
        if (tglAwalTahun.year() == tahun - 1) {
          tglAwalTahun = moment(tahun + '-01-07T09:00').startOf('IsoWeek').utcOffset('+0700');
        }
      }
      var tanggal_angkat = moment(tglAwalTahun).add((this.t_periode.value - 1) * 7, 'days');
      var tanggal_pasang = moment(tanggal_angkat).add(-7, 'days');
      this.tgl_pasang = tanggal_pasang.format()
      this.tgl_angkat = tanggal_angkat.format()
      this.jam_pasang = "09:00"
      this.jam_angkat = "09:00"
      var periode = String(this.t_periode.value).padStart(2, "0");
      var bulan = tanggal_angkat.format('MM');
      let id_sampel = kodestasiun + "" + String(this.t_year.title).substring(2, 4) + "" + bulan + "" + periode + "" + String(parseInt(sekuens) + 1).padStart(2, "0");
      this.kah_id_sampel = id_sampel
      return id_sampel
    },

    async getKualitasSampel() {
      try {
        const { data } = await labkuservice.listKualitasSampel();
        let items = data.items
        let kondisi = []
        for (var i = 0; i < items.length; i++) {
          const item_kondisi = {
            value: items[i].sb_value,
            text: items[i].sb_label,
            path: items[i].path,
          };
          kondisi.push(item_kondisi)
        }
        this.list_kah_kualitas_sampel = kondisi
        // make list kualitas sampel order by text ascended
        this.list_kah_kualitas_sampel.sort(function (a, b) {
          var textA = a.text.toUpperCase(); // ignore upper and lowercase
          var textB = b.text.toUpperCase(); // ignore upper and lowercase
          if (textA < textB) {
            return -1;
          }
          if (textA > textB) {
            return 1;
          }
          // names must be equal
          return 0;
        });
      } catch (e) {
        console.log(e);
      }
    },

    async getCatatanLab() {
      try {
        const { data } = await labkuservice.listCatatanLab();
        let items = data.items
        let kondisi = []
        for (var i = 0; i < items.length; i++) {
          const item_kondisi = {
            value: items[i].sb_value,
            text: items[i].sb_label,
            path: items[i].path,
          };
          kondisi.push(item_kondisi)
        }
        this.list_kah_catatan_lab = kondisi
        // make list catatan lab order by text ascended
        this.list_kah_catatan_lab.sort(function (a, b) {
          var textA = a.text.toUpperCase(); // ignore upper and lowercase
          var textB = b.text.toUpperCase(); // ignore upper and lowercase
          if (textA < textB) {
            return -1;
          }
          if (textA > textB) {
            return 1;
          }
          // names must be equal
          return 0;
        });
      } catch (e) {
        console.log(e);
      }
    },

    async getKejadianLuarBiasa() {
      try {
        const { data } = await labkuservice.listKejadianLuarBiasa();
        let items = data.items
        let kondisi = []
        for (var i = 0; i < items.length; i++) {
          const item_kondisi = {
            value: items[i].sb_value,
            text: items[i].sb_label,
            path: items[i].path,
          };
          kondisi.push(item_kondisi)
        }
        this.list_kejadian_lainnya = kondisi
        //make list_kejadian_lainnya order by text asc
        this.list_kejadian_lainnya.sort(function (a, b) {
          var textA = a.text.toUpperCase(); // ignore upper and lowercase
          var textB = b.text.toUpperCase(); // ignore upper and lowercase
          if (textA < textB) {
            return -1;
          }
          if (textA > textB) {
            return 1;
          }
          // names must be equal
          return 0;
        });
      } catch (e) {
        console.log(e);
      }
    },

    checkFlagOptionSelection(isNewValue, value, previousFlagOptions) {
      if (isNewValue && (value == 9999 || value == 8888)) {
        let flagOptions = [...previousFlagOptions]
        flagOptions.forEach(function(item){ 
          if (item) {
            if(item.value !== 'M') {
              item['disabled'] = true
            }
            else if(item.value === '') {
              item['disabled'] = true
            }
            else {
              item['disabled'] = false
            }
          }
        })
        return flagOptions
      }
      else {
        if (!isNewValue && (value == 9999 || value == 8888)) {
          let flagOptions = [...previousFlagOptions]
          flagOptions.forEach(function(item){ 
            if (item) {
              if(item.value === 'M') {
                item['disabled'] = true
              }
              else if(item.value === '') {
                item['disabled'] = true
              }
              else {
                item['disabled'] = false
              }
            }
          })
          return flagOptions
        }
      }

      return []
    },

    onKeyCheck(event) {
      // console.log('onKeyCheck', event)
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault()
      }
    },
    format_one_decimal(e) {
      if (Math.round(e * 10) / 10 != e) {
        let text = String(e);
        let filter = text.substring(0, text.length - 1);
        return filter;
      } else {
        if (Math.floor(e) === e) {
          return e;
        } else {
          if (e.toString().indexOf(".") > 0) {
            let count = e.toString().split(".")[1].length || 0;
            return count > 1 ? parseFloat(e).toFixed(1) : e;
          } else {
            return e;
          }
        }
        return e;
      }
    },
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    tooltipMessage(attr) {
      return GC_KU(attr).message;
    },
    V_Form() {
      // console.log('V_Form - t_station', this.t_station)
      this.$v.$touch();
      if (!this.kah_ptgs_pasang_sampling ||
      !this.kah_ptgs_angkat_sampling ||
      !this.kah_penyelia
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Data tidak valid. Periksa kembali inputan anda",
            icon: "BellIcon",
            variant: "danger",
          },
        });
        return;
      }
      if (this.$v.$invalid) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Data tidak valid. Periksa kembali inputan anda",
            icon: "BellIcon",
            variant: "danger",
          },
        });
        return;
      } else {
        let dataRC = Object.entries(this.DATA_RC).filter((e) => e[1].status);
        // console.log('this.DATA_RC', this.DATA_RC, dataRC)
        let temp_QC_After = [];
        for (var i in dataRC) {
          let row_qc_after = {
            raw_type: "KIMIA_AIR_HUJAN",
            atribut: dataRC[i][0],
            value: this[dataRC[i][0]],
            roles: dataRC[i][1].message,
            type: "range_check",
            message: dataRC[i][1].message,
          };
          temp_QC_After.push(row_qc_after);
        }

        // console.log("QC  AFTER : ",temp_QC_After);
        // this.last_qc_histories["before"] = "-";
        this.last_qc_histories["after"] = temp_QC_After;

        if (dataRC.length > 0) {
          this.$swal({
            title: "Apakah Anda Yakin Untuk Simpan Data ?",
            text: "Data Anda Terkoreksi Range Check ",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Submit",
            cancelButtonText: "Cek Data",
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              if (this.t_data_status == "insert") {
                this.submitKAH();
              } else if (this.t_data_status == "update") {
                this.updateData();
              }
            } else if (result.dismiss === "cancel") {
              return;
            }
          });
        } else {
          if (this.t_data_status == "insert") {
            this.submitKAH();
          } else if (this.t_data_status == "update") {
            this.updateData();
          }
        }
        // if (this.t_data_status == "insert") {
        //   this.submitKAH();
        // } else if (this.t_data_status == "update") {
        //   this.updateData();
        // }
      }
    },
    popupData() {
      let p_pasang_sampling = this.list_observer.find(item => item.observer == this.t_data.kah_ptgs_pasang_sampling)
      let p_angkat_sampling = this.list_observer.find(item => item.observer == this.t_data.kah_ptgs_angkat_sampling)
      let p_analis_lab = this.list_penyelia.find(item => item.value == this.t_data.kah_penyelia)
      this.kah_id_sampel = this.t_data.kah_id_sampel
      this.kah_ptgs_pasang_sampling = p_pasang_sampling,
      this.kah_ptgs_angkat_sampling = p_angkat_sampling,
      this.kah_penyelia = p_analis_lab
      this.kond_lingk = this.t_data.kond_lingk
      this.tgl_pasang = this.t_data.tgl_pasang
      this.tgl_angkat = this.t_data.tgl_angkat
      this.kah_ph = this.t_data.kah_ph
      this.kah_ph_flag = this.t_data.kah_ph_flag
      this.kah_conductivity = this.t_data.kah_conductivity
      this.kah_conductivity_flag = this.t_data.kah_conductivity_flag
      this.kah_cl = this.t_data.kah_cl
      this.kah_cl_flag = this.t_data.kah_cl_flag
      this.kah_so42 = this.t_data.kah_so42
      this.kah_so42_flag = this.t_data.kah_so42_flag
      this.kah_no3 = this.t_data.kah_no3
      this.kah_no3_flag = this.t_data.kah_no3_flag
      this.kah_na = this.t_data.kah_na
      this.kah_na_flag = this.t_data.kah_na_flag
      // this.kah_ng4 = this.t_data.kah_ng4
      // this.kah_ng4_flag = this.t_data.kah_ng4_flag
      this.kah_nh4 = this.t_data.kah_nh4
      this.kah_nh4_flag = this.t_data.kah_nh4_flag
      this.kah_k = this.t_data.kah_k
      this.kah_k_flag = this.t_data.kah_k_flag
      this.kah_mg2 = this.t_data.kah_mg2
      this.kah_mg2_flag = this.t_data.kah_mg2_flag
      this.kah_ca2 = this.t_data.kah_ca2
      this.kah_ca2_flag = this.t_data.kah_ca2_flag
      this.kah_acidity = this.t_data.kah_acidity
      this.kah_acidity_flag = this.t_data.kah_acidity_flag
      this.kah_alkalinity = this.t_data.kah_alkalinity
      this.kah_alkalinity_flag = this.t_data.kah_alkalinity_flag
      this.kah_curah_hujan_sampling = this.t_data.kah_curah_hujan_sampling
      this.kah_curah_hujan_sampling_flag = this.t_data.kah_curah_hujan_sampling_flag
      this.kah_massa_sampling = this.t_data.kah_massa_sampling
      this.kah_massa_sampling_flag = this.t_data.kah_massa_sampling_flag
      this.kah_kualitas_sampel = this.t_data.kah_kualitas_sampel
      this.kah_catatan_lab = this.t_data.kah_catatan_lab
      this.kah_kejadian_luar_biasa = this.t_data.kah_kejadian_luar_biasa
      this.kah_catatan_lainnya = this.t_data.kah_catatan_lainnya
      this.qc_flag_before = this.t_data.qc_flag;
      this.QC_Histories = this.t_data.qc_histories
      // if (this.tgl_pasang) {
      //   let tgl = this.tgl_pasang.split('T')
      //   let jam = tgl[1].split(':')[0]
      //   this.jam_pasang = jam
      // }

      // if (this.tgl_angkat) {
      //   let tgl = this.tgl_angkat.split('T')
      //   let jam = tgl[1].split(':')[0]
      //   this.jam_angkat = jam
      // }

      if (!this.t_data.kah_id_sampel) {
        this.getSampelID()
      }

      this.$v.$touch();
    },

    updateData() {
      // console.log('this.jam_pasang', this.tgl_pasang)
      const modification_date = this.t_date;
      // if (this.jam_pasang) {
      //   let tgl = this.tgl_pasang.split('T')[0]
      //   this.tgl_pasang = tgl + 'T' + this.jam_pasang + ':00:00'
      // }
      // if (this.jam_pasang) {
      //   let tgl = this.tgl_angkat.split('T')[0]
      //   this.tgl_angkat = tgl + 'T' + this.jam_angkat + ':00:00'
      // }

      // CHECK QC
      var len_qc_after = (this.last_qc_histories) ? this.last_qc_histories["after"].length : 0;
      var temp_qc_histories = {};
      if (this.QC_Histories && this.QC_Histories != "" || this.QC_Histories != null) {
        temp_qc_histories = JSON.parse(this.QC_Histories);
      }
      // console.log("TEMP QC : ", temp_qc_histories);
      this.last_qc_histories["before"] = [];
      if (temp_qc_histories != null) {
        if (temp_qc_histories["after"] != undefined) {
          this.last_qc_histories["before"] = temp_qc_histories["after"];
        }
      }

      var flag_temp = this.qc_flag_before ? this.qc_flag_before : 0;
      if (this.qc_flag_before != 2) {
        flag_temp = this.qc_flag_before;

        if (len_qc_after > 0) {
          flag_temp = 1;
        } 
        else {
          flag_temp = 0;
        }
      }

      var qc_temp = JSON.stringify(this.last_qc_histories);

      const post_data = {
        kah_ptgs_pasang_sampling: this.kah_ptgs_pasang_sampling?.observer ? this.kah_ptgs_pasang_sampling.observer : null,
        kah_ptgs_angkat_sampling: this.kah_ptgs_angkat_sampling?.observer ? this.kah_ptgs_angkat_sampling.observer : null,
        kah_penyelia: this.kah_penyelia?.value ? this.kah_penyelia.value : null,
        kond_lingk: this.kond_lingk,
        tgl_pasang: this.tgl_pasang ? this.tgl_pasang : null,
        tgl_angkat: this.tgl_angkat ? this.tgl_angkat : null,
        kah_id_sampel: this.kah_id_sampel,
        kah_ph: this.kah_ph ? this.kah_ph : null,
        kah_ph_flag: this.kah_ph_flag,
        kah_conductivity: this.kah_conductivity ? this.kah_conductivity : null,
        kah_conductivity_flag: this.kah_conductivity_flag,
        kah_cl: this.kah_cl ? this.kah_cl : null,
        kah_cl_flag: this.kah_cl_flag,
        kah_so42: this.kah_so42 ? this.kah_so42 : null,
        kah_so42_flag: this.kah_so42_flag,
        kah_no3: this.kah_no3 ? this.kah_no3 : null,
        kah_no3_flag: this.kah_no3_flag,
        kah_na: this.kah_na ? this.kah_na : null,
        kah_na_flag: this.kah_na_flag,
        // kah_ng4 : this.kah_ng4,
        // kah_ng4_flag : this.kah_ng4_flag,
        kah_nh4: this.kah_nh4 ? this.kah_nh4 : null,
        kah_nh4_flag: this.kah_nh4_flag,
        kah_k: this.kah_k ? this.kah_k : null,
        kah_k_flag: this.kah_k_flag,
        kah_mg2: this.kah_mg2 ? this.kah_mg2 : null,
        kah_mg2_flag: this.kah_mg2_flag,
        kah_ca2: this.kah_ca2 ? this.kah_ca2 : null,
        kah_ca2_flag: this.kah_ca2_flag,
        kah_acidity: this.kah_acidity ? this.kah_acidity : null,
        kah_acidity_flag: this.kah_acidity_flag,
        kah_alkalinity: this.kah_alkalinity ? this.kah_alkalinity : null,
        kah_alkalinity_flag: this.kah_alkalinity_flag,
        kah_curah_hujan_sampling: this.kah_curah_hujan_sampling ? this.kah_curah_hujan_sampling : null,
        kah_curah_hujan_sampling_flag: this.kah_curah_hujan_sampling_flag,
        kah_massa_sampling: this.kah_massa_sampling ? this.kah_massa_sampling : null,
        kah_massa_sampling_flag: this.kah_massa_sampling_flag,
        kah_kualitas_sampel: this.kah_kualitas_sampel,
        kah_catatan_lab: this.kah_catatan_lab,
        kah_kejadian_luar_biasa: this.kah_kejadian_luar_biasa,
        kah_catatan_lainnya: this.kah_catatan_lainnya,
        station_id: this.t_station.value,
        station_wmo_id: this.t_station.wmo,
        station_name: this.t_station.text,
        current_longitude: this.t_station.longitude,
        current_latitude: this.t_station.latitude,
        periode_form: this.t_periode.value,
        tahun_form: this.tgl_angkat,
        qc_flag: flag_temp,
        qc_histories: qc_temp
      };

      const update_url = Helper.getBaseUrl() + this.t_path_update;
      this.showLoading = true
      axios
        .patch(update_url, post_data, Helper.getConfig())
        .then((response) => {
          this.$swal({
            title: "Update data sukses!",
            text: "Data Telah berhasil di Update",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.showLoading = false
          this.resetForm();
          this.changeForm(null);
        })
        .catch((error) => {
          this.showLoading = false
          console.log(error);
          error_handler(this, error, "Kualitas Air Hujan", "Input Data Error");
        });
    },
    submitKAH() {
      // console.log(this.tgl_pasang);
      // if (this.jam_pasang) {
      //   let tgl = this.tgl_pasang.split('T')[0]
      //   this.tgl_pasang = tgl + 'T' + this.jam_pasang + ':00:00'
      // }
      // if (this.jam_pasang) {
      //   let tgl = this.tgl_angkat.split('T')[0]
      //   this.tgl_angkat = tgl + 'T' + this.jam_angkat + ':00:00'
      // }
      var len_qc_after = this.last_qc_histories["after"].length;
      this.last_qc_histories["before"] = [];
      // console.log("ada QC : ",len_qc_after);
      var flag_temp = 0;
      if (len_qc_after > 0) {
        flag_temp = 1;
      }
      var qc_temp = JSON.stringify(this.last_qc_histories);

      const kah_id = this.t_id_form;//this.t_station_id + "_aktinograph_" + this.t_date;
      const data_timestamp = this.t_date;
      const post_data = {
        "@type": "KAH",
        id: kah_id,
        kah_ptgs_pasang_sampling: this.kah_ptgs_pasang_sampling?.observer ? this.kah_ptgs_pasang_sampling.observer : null,
        kah_ptgs_angkat_sampling: this.kah_ptgs_angkat_sampling?.observer ? this.kah_ptgs_angkat_sampling.observer : null,
        kah_penyelia: this.kah_penyelia?.value ? this.kah_penyelia.value : null,
        kond_lingk: this.kond_lingk,
        tgl_pasang: this.tgl_pasang ? this.tgl_pasang : null,
        tgl_angkat: this.tgl_angkat ? this.tgl_angkat : null,
        kah_id_sampel: this.kah_id_sampel,
        kah_ph: this.kah_ph ? this.kah_ph : null,
        kah_ph_flag: this.kah_ph_flag,
        kah_conductivity: this.kah_conductivity ? this.kah_conductivity : null,
        kah_conductivity_flag: this.kah_conductivity_flag,
        kah_cl: this.kah_cl ? this.kah_cl : null,
        kah_cl_flag: this.kah_cl_flag,
        kah_so42: this.kah_so42 ? this.kah_so42 : null,
        kah_so42_flag: this.kah_so42_flag,
        kah_no3: this.kah_no3 ? this.kah_no3 : null,
        kah_no3_flag: this.kah_no3_flag,
        kah_na: this.kah_na ? this.kah_na : null,
        kah_na_flag: this.kah_na_flag,
        // kah_ng4 : this.kah_ng4,
        // kah_ng4_flag : this.kah_ng4_flag,
        kah_nh4: this.kah_nh4 ? this.kah_nh4 : null,
        kah_nh4_flag: this.kah_nh4_flag,
        kah_k: this.kah_k ? this.kah_k : null,
        kah_k_flag: this.kah_k_flag,
        kah_mg2: this.kah_mg2 ? this.kah_mg2 : null,
        kah_mg2_flag: this.kah_mg2_flag,
        kah_ca2: this.kah_ca2 ? this.kah_ca2 : null,
        kah_ca2_flag: this.kah_ca2_flag,
        kah_acidity: this.kah_acidity ? this.kah_acidity : null,
        kah_acidity_flag: this.kah_acidity_flag,
        kah_alkalinity: this.kah_alkalinity ? this.kah_alkalinity : null,
        kah_alkalinity_flag: this.kah_alkalinity_flag,
        kah_curah_hujan_sampling: this.kah_curah_hujan_sampling ? this.kah_curah_hujan_sampling : null,
        kah_curah_hujan_sampling_flag: this.kah_curah_hujan_sampling_flag,
        kah_massa_sampling: this.kah_massa_sampling ? this.kah_massa_sampling : null,
        kah_massa_sampling_flag: this.kah_massa_sampling_flag,
        kah_kualitas_sampel: this.kah_kualitas_sampel,
        kah_catatan_lab: this.kah_catatan_lab,
        kah_kejadian_luar_biasa: this.kah_kejadian_luar_biasa,
        kah_catatan_lainnya: this.kah_catatan_lainnya,
        station_id: this.t_station.value,
        station_wmo_id: this.t_station.wmo,
        station_name: this.t_station.text,
        current_longitude: this.t_station.longitude,
        current_latitude: this.t_station.latitude,
        periode_form: this.t_periode.value,
        tahun_form: this.tgl_angkat,
        qc_flag: flag_temp,
        qc_histories: qc_temp
      };

      const post_url = Helper.getBaseUrl() + this.t_path;
      // console.log("change form nich", this.changeForm);
      this.showLoading = true
      axios
        .post(post_url, post_data, Helper.getConfig())
        .then((response) => {
          this.$swal({
            title: "Simpan data sukses!",
            text: "Data Telah berhasil di Simpan",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.showLoading = false
          this.resetForm();
          this.changeForm(null);
          

        })
        .catch((error) => {
          this.showLoading = false
          console.log(error);
          error_handler(this, error, "Kualitas Air Hujan", "Input Data Error");
        });
    },
    resetForm(tipe = null) {
      this.showLoading = false
      if(tipe == 'batal'){ 
          this.changeForm(null);
      }
      this.qc_flag = 0,
      this.qc_flag_before = 0,
      this.last_qc_histories = {},
      this.DATA_RC = {},
      this.RC = {
        kah_ph: { status: false, message: "" },
        kah_conductivity: { status: false, message: "" },
        kah_cl: { status: false, message: "" },
        kah_so42: { status: false, message: "" },
        kah_no3: { status: false, message: "" },
        kah_na: { status: false, message: "" },
        kah_ng4: { status: false, message: "" },
        kah_nh4: { status: false, message: "" },
        kah_k: { status: false, message: "" },
        kah_mg2: { status: false, message: "" },
        kah_ca2: { status: false, message: "" },
        kah_acidity: { status: false, message: "" },
        kah_alkalinity: { status: false, message: "" },
        kah_curah_hujan_sampling: { status: false, message: "" },
        kah_massa_sampling: { status: false, message: "" },
      },
      this.kah_ptgs_pasang_sampling = ""
      this.kah_ptgs_angkat_sampling = ""
      this.kah_penyelia = ""
      this.kond_lingk = ""
      this.tgl_pasang = null
      this.tgl_angkat = null
      this.jam_pasang = ""
      this.jam_angkat = ""
      this.kah_ph = ""
      this.kah_ph_flag = null
      this.kah_conductivity = ""
      this.kah_conductivity_flag = null
      this.kah_cl = ""
      this.kah_cl_flag = null
      this.kah_so42 = ""
      this.kah_so42_flag = null
      this.kah_no3 = ""
      this.kah_no3_flag = null
      this.kah_na = ""
      this.kah_na_flag = null
      this.kah_ng4 = ""
      this.kah_ng4_flag = null
      this.kah_nh4 = ""
      this.kah_nh4_flag = null
      this.kah_k = ""
      this.kah_k_flag = null
      this.kah_mg2 = ""
      this.kah_mg2_flag = null
      this.kah_ca2 = ""
      this.kah_ca2_flag = null
      this.kah_acidity = ""
      this.kah_acidity_flag = null
      this.kah_alkalinity = ""
      this.kah_alkalinity_flag = null
      this.kah_curah_hujan_sampling = ""
      this.kah_curah_hujan_sampling_flag = null
      this.kah_massa_sampling = ""
      this.kah_massa_sampling_flag = null
      this.kah_kualitas_sampel = ""
      this.kah_catatan_lab = ""
      this.kah_kejadian_luar_biasa = ""
      this.kah_catatan_lainnya = ""
      this.kah_id_sampel = null
      this.$v.$reset();
      
      this.resetOptions()
    },

    resetOptions() {
      this.flagOptionsKahPh = [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ]
      this.flagOptionsKahConductivity = [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ]
      this.flagOptionsKahCl = [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ]
      this.flagOptionsKahSo42 = [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ]
      this.flagOptionsKahNo3 = [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ]
      this.flagOptionsKahNa = [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ]
      this.flagOptionsKahNh4 = [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ]
      this.flagOptionsKahK = [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ]
      this.flagOptionsKahMg2 = [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ]
      this.flagOptionsKahCa2 = [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ]
      this.flagOptionsKahAcidity = [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ]
      this.flagOptionsKahAlkalinity = [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ]
      this.flagOptionsKahCurahHujanSampling = [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ]
      this.flagOptionsKahMassaSampling = [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ]
    },

    checkValidate: function (event, type) {
      // console.log("checkValidate", event, type);
      let value = event.target.value;

      switch (type) {
        case "kah_ph":
          var raw = this.RC_helper_kah.check(value, type);
          var gc = GC_PH(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "kah_conductivity":
          var raw = this.RC_helper_kah.check(value, type);
          var gc = GC_CONDUCTIVITY(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "kah_cl": 
          var raw = this.RC_helper_kah.check(value, type);
          var gc = GC_CL(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "kah_so42":
          var raw = this.RC_helper_kah.check(value, type);
          var gc = GC_SO42(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "kah_no3":
          var raw = this.RC_helper_kah.check(value, type);
          var gc = GC_NO3(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "kah_na":
          var raw = this.RC_helper_kah.check(value, type);
          var gc = GC_NA(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        // case "kah_ng4":
        //   var raw = this.RC_helper_kah.check(value, type);
        //   var gc = GC_N(value);
        //   this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
        //   this.RC[type].message = raw.message;
        //   this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
        //   break;
        case "kah_nh4":
          var raw = this.RC_helper_kah.check(value, type);
          var gc = GC_NH4(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "kah_k":
          var raw = this.RC_helper_kah.check(value, type);
          var gc = GC_K(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "kah_mg2": 
          var raw = this.RC_helper_kah.check(value, type);
          var gc = GC_MG2(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "kah_ca2":
          var raw = this.RC_helper_kah.check(value, type);
          var gc = GC_CA2(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "kah_acidity":
          var raw = this.RC_helper_kah.check(value, type);
          var gc = GC_ACIDITY(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "kah_alkalinity":
          var raw = this.RC_helper_kah.check(value, type);
          var gc = GC_ALKALINITY(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "kah_curah_hujan_sampling":
          var raw = this.RC_helper_kah.check(value, type);
          var gc = GC_CURAH_HUJAN_SAMPLING(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "kah_massa_sampling":
          var raw = this.RC_helper_kah.check(value, type);
          var gc = GC_MASSA_SAMPLING(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;

        default:
          break;
      }
    },

    checkValidateAll() {      
      this.checkValidate({target: {value: this.kah_ph}}, "kah_ph")
      this.checkValidate({target: {value: this.kah_conductivity}}, "kah_conductivity")    
      this.checkValidate({target: {value: this.kah_cl}}, "kah_cl")
      this.checkValidate({target: {value: this.kah_so42}}, "kah_so42")
      this.checkValidate({target: {value: this.kah_no3}}, "kah_no3")
      this.checkValidate({target: {value: this.kah_na}}, "kah_na")      
      this.checkValidate({target: {value: this.kah_nh4}}, "kah_nh4")
      this.checkValidate({target: {value: this.kah_k}}, "kah_k")
      this.checkValidate({target: {value: this.kah_mg2}}, "kah_mg2")
      this.checkValidate({target: {value: this.kah_ca2}}, "kah_ca2")
      this.checkValidate({target: {value: this.kah_acidity}}, "kah_acidity")     
      this.checkValidate({target: {value: this.kah_alkalinity}}, "kah_alkalinity")
      this.checkValidate({target: {value: this.kah_curah_hujan_sampling}}, "kah_curah_hujan_sampling")     
      this.checkValidate({target: {value: this.kah_massa_sampling}}, "kah_massa_sampling")
    },

    setDataRC(type, status, message, roles) {
      // console.log('setDataRC', type, status, message, roles)
      if (status) {
        this.DATA_RC[type] = {
          status: status,
          message: message,
          roles: roles,
        };
      } else {
        if (this.DATA_RC.hasOwnProperty(type)) {
          delete this.DATA_RC[type];
        }
      }
    },

    async setQCData() {
      // console.log('setQCData')
      if (this.d_station) {
        let qc_data = this.d_station["BmkgSatu.metadata.qc.IKAH_qc"];
        let qc_params = Params.GET_PARAMS("kimia_air_hujan");
        let qc_key = Params.GET_KEY("kimia_air_hujan");
        this.RC_helper_kah.setData(qc_data, qc_params, qc_key);
        // console.log('setQCData', this.RC_helper_so2no2.getData())
      }
    },

    rangecheck_status(type) {
      if (this.RC[type]?.status) {
        return this.RC[type].status;
      } else {
        return false;
      }
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>

#kah_ph.rangecheck,
#kah_conductivity.rangecheck,
#kah_cl.rangecheck,
#kah_so42.rangecheck,
#kah_no3.rangecheck,
#kah_na.rangecheck,
#kah_ng4.rangecheck,
#kah_nh4.rangecheck,
#kah_k.rangecheck,
#kah_mg2.rangecheck,
#kah_ca2.rangecheck,
#kah_curah_hujan_sampling.rangecheck,
#kah_massa_sampling.rangecheck,
#kah_alkalinity.rangecheck,
#kah_acidity.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
}

.content-inline {
  display: flex;
  flex-direction: row;
}
</style>

<style scoped>
.label-modif {
  margin-top: -5px;
  font-size: 10pt;
  margin-left: 3px
}

.select-grey {
  background-color: lightgray !important;
}

.label-entry1 {
  text-align: center;
  color: #3b4253;
  width: 100%;
  font-weight: 500;
  background-color: #f3f1f1;
  /* font-weight: bold !important; */
  border: 2px solid #6e6b7b;
  border-radius: 3px;
  padding: 4px;
  margin-bottom: 7px;
}

.mbtm {
  margin-bottom: 7px;
}

.bg-light-primary {
  color: #000000 !important;
}

.v-select__selection {
  width: 100%;
  justify-content: center;
}

/* ltr .select-size-sm.v-select.vs--single .vs__selected */
span .vs__selected {
  margin-left: 50%;
}
</style>
