<template>
  <div>
    <b-card>
      <b-row class="">
        <!-- card actions -->
        <b-col cols="10">
          <b-card class="font-weight-bolder text-dark">
            <h3 class="mb-2 font-weight-bolder badge badge-light-dark">Tekanan Udara (mb)</h3>
            <b-row class="">
              <b-col sm="1 mb-2 pad2">
                <div>
                  <label for="input-baro_07" class="label-entry1"
                    >07:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Besarnya tekanan udara pada pukul 7 waktu setempat dalam satuan milibar"
                  /></label>
                </div>
                <div>
                  <label for="input-baro_08" class="label-entry1"
                    >08:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Besarnya tekanan udara pada pukul 8 waktu setempat dalam satuan milibar"
                  /></label>
                </div>
                <div>
                  <label for="input-baro_09" class="label-entry1"
                    >09:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Besarnya tekanan udara pada pukul 9 waktu setempat dalam satuan milibar"
                  /></label>
                </div>
                <div>
                  <label for="input-baro_10" class="label-entry1"
                    >10:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Besarnya tekanan udara pada pukul 10 waktu setempat dalam satuan milibar"
                  /></label>
                </div>
                <div>
                  <label for="input-baro_11" class="label-entry1"
                    >11:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Besarnya tekanan udara pada pukul 11 waktu setempat dalam satuan milibar"
                  /></label>
                </div>
                <div>
                  <label for="input-baro_12" class="label-entry1"
                    >12:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Besarnya tekanan udara pada pukul 12 waktu setempat dalam satuan milibar"
                  /></label>
                </div>
              </b-col>
              <b-col sm="2 mb-2">
                <b-form-input
                  :formatter="format_one_decimal"
                  v-on:keydown="onKeyCheck($event)"
                  v-on:keyup="checkValidate($event, 'rh_7')"
                  v-bind:class="{ rangecheck: rc_rh_7 }"
                  type="number"
                  v-model.number="$v.tekanan.po_baro_07.$model"
                  :state="validateState($v.tekanan.po_baro_07)"
                  id="input_rh_7"
                  size="sm"
                  class="text-center mbtm"
                ></b-form-input>
                <!-- tooltips RC -->
                <b-tooltip v-if="rc_rh_7" target="input_rh_7" triggers="hover" placement="topleft"> {{ rc_rh_7_message }}</b-tooltip>
                <b-tooltip v-if="!rc_rh_7" :show="validateTooltipStateTekanan('po_baro_07')" :triggers="'hover'" target="input_rh_7" placement="topleft" custom-class="validation-tooltip">
                  {{ tooltipMessage("tekanan_po_baro_07") }}
                </b-tooltip>
                <b-form-input
                  :formatter="format_one_decimal"
                  v-on:keydown="onKeyCheck($event)"
                  v-on:keyup="checkValidate($event, 'rh_8')"
                  v-bind:class="{ rangecheck: rc_rh_8 }"
                  type="number"
                  v-model.number="$v.tekanan.po_baro_08.$model"
                  :state="validateState($v.tekanan.po_baro_08)"
                  id="input_rh_8"
                  size="sm"
                  class="text-center mbtm"
                ></b-form-input>
                <b-tooltip v-if="rc_rh_8" target="input_rh_8" triggers="hover" placement="topleft">{{ rc_rh_8_message }}</b-tooltip>
                <b-tooltip v-if="!rc_rh_8" :show="validateTooltipStateTekanan('po_baro_08')" :triggers="'hover'" target="input_rh_8" placement="topleft" custom-class="validation-tooltip">
                  {{ tooltipMessage("tekanan_po_baro_08") }}
                </b-tooltip>
                <b-form-input
                  :formatter="format_one_decimal"
                  v-on:keydown="onKeyCheck($event)"
                  v-on:keyup="checkValidate($event, 'rh_9')"
                  v-bind:class="{ rangecheck: rc_rh_9 }"
                  type="number"
                  v-model.number="$v.tekanan.po_baro_09.$model"
                  :state="validateState($v.tekanan.po_baro_09)"
                  id="input_rh_9"
                  size="sm"
                  class="text-center mbtm"
                ></b-form-input>
                <b-tooltip v-if="rc_rh_9" target="input_rh_9" triggers="hover" placement="topleft">{{ rc_rh_9_message }}</b-tooltip>
                <b-tooltip v-if="!rc_rh_9" :show="validateTooltipStateTekanan('po_baro_09')" :triggers="'hover'" target="input_rh_9" placement="topleft" custom-class="validation-tooltip">
                  {{ tooltipMessage("tekanan_po_baro_09") }}
                </b-tooltip>
                <b-form-input
                  :formatter="format_one_decimal"
                  v-on:keydown="onKeyCheck($event)"
                  v-on:keyup="checkValidate($event, 'rh_10')"
                  v-bind:class="{ rangecheck: rc_rh_10 }"
                  type="number"
                  v-model.number="$v.tekanan.po_baro_10.$model"
                  :state="validateState($v.tekanan.po_baro_10)"
                  id="input_rh_10"
                  size="sm"
                  class="text-center mbtm"
                ></b-form-input>
                <b-tooltip v-if="rc_rh_10" target="input_rh_10" triggers="hover" placement="topleft">{{ rc_rh_10_message }}</b-tooltip>
                <b-tooltip v-if="!rc_rh_10" :show="validateTooltipStateTekanan('po_baro_10')" :triggers="'hover'" target="input_rh_10" placement="topleft" custom-class="validation-tooltip">
                  {{ tooltipMessage("tekanan_po_baro_10") }}
                </b-tooltip>
                <b-form-input
                  :formatter="format_one_decimal"
                  v-on:keydown="onKeyCheck($event)"
                  v-on:keyup="checkValidate($event, 'rh_11')"
                  v-bind:class="{ rangecheck: rc_rh_11 }"
                  type="number"
                  v-model.number="$v.tekanan.po_baro_11.$model"
                  :state="validateState($v.tekanan.po_baro_11)"
                  id="input_rh_11"
                  size="sm"
                  class="text-center mbtm"
                ></b-form-input>
                <b-tooltip v-if="rc_rh_11" target="input_rh_11" triggers="hover" placement="topleft">{{ rc_rh_11_message }}</b-tooltip>
                <b-tooltip v-if="!rc_rh_11" :show="validateTooltipStateTekanan('po_baro_11')" :triggers="'hover'" target="input_rh_11" placement="topleft" custom-class="validation-tooltip">
                  {{ tooltipMessage("tekanan_po_baro_11") }}
                </b-tooltip>
                <b-form-input
                  :formatter="format_one_decimal"
                  v-on:keydown="onKeyCheck($event)"
                  v-on:keyup="checkValidate($event, 'rh_12')"
                  v-bind:class="{ rangecheck: rc_rh_12 }"
                  type="number"
                  v-model.number="$v.tekanan.po_baro_12.$model"
                  :state="validateState($v.tekanan.po_baro_12)"
                  id="input_rh_12"
                  size="sm"
                  class="text-center mbtm"
                ></b-form-input>
                <b-tooltip v-if="rc_rh_12" target="input_rh_12" triggers="hover" placement="topleft">{{ rc_rh_12_message }}</b-tooltip>
                <b-tooltip v-if="!rc_rh_12" :show="validateTooltipStateTekanan('po_baro_12')" :triggers="'hover'" target="input_rh_12" placement="topleft" custom-class="validation-tooltip">
                  {{ tooltipMessage("tekanan_po_baro_12") }}
                </b-tooltip>
              </b-col>

              <b-col sm="1 mb-2 pad2">
                <div>
                  <label for="input-baro_13" class="label-entry1"
                    >13:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Besarnya tekanan udara pada pukul 13 waktu setempat dalam satuan milibar"
                  /></label>
                </div>
                <div>
                  <label for="input-baro_14" class="label-entry1"
                    >14:00<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Besarnya tekanan udara pada pukul 14 waktu setempat dalam satuan milibar"
                  /></label>
                </div>
                <div>
                  <label for="input-baro_15" class="label-entry1"
                    >15:00<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Besarnya tekanan udara pada pukul 15 waktu setempat dalam satuan milibar"
                  /></label>
                </div>
                <div>
                  <label for="input-baro_16" class="label-entry1"
                    >16:00<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Besarnya tekanan udara pada pukul 16 waktu setempat dalam satuan milibar"
                  /></label>
                </div>
                <div>
                  <label for="input-baro_17" class="label-entry1"
                    >17:00<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Besarnya tekanan udara pada pukul 17 waktu setempat dalam satuan milibar"
                  /></label>
                </div>
                <div>
                  <label for="input-baro_18" class="label-entry1"
                    >18:00<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Besarnya tekanan udara pada pukul 18 waktu setempat dalam satuan milibar"
                  /></label>
                </div>
              </b-col>
              <b-col sm="2 mb-2">
                <b-form-input
                  :formatter="format_one_decimal"
                  v-on:keydown="onKeyCheck($event)"
                  v-on:keyup="checkValidate($event, 'rh_13')"
                  v-bind:class="{ rangecheck: rc_rh_13 }"
                  type="number"
                  v-model.number="$v.tekanan.po_baro_13.$model"
                  :state="validateState($v.tekanan.po_baro_13)"
                  id="input_rh_13"
                  size="sm"
                  class="text-center mbtm"
                ></b-form-input>
                <b-tooltip v-if="rc_rh_13" target="input_rh_13" triggers="hover" placement="topleft">{{ rc_rh_13_message }}</b-tooltip>
                <b-tooltip v-if="!rc_rh_13" :show="validateTooltipStateTekanan('po_baro_13')" :triggers="'hover'" target="input_rh_13" placement="topleft" custom-class="validation-tooltip">
                  {{ tooltipMessage("tekanan_po_baro_13") }}
                </b-tooltip>
                <b-form-input
                  :formatter="format_one_decimal"
                  v-on:keydown="onKeyCheck($event)"
                  v-on:keyup="checkValidate($event, 'rh_14')"
                  v-bind:class="{ rangecheck: rc_rh_14 }"
                  type="number"
                  v-model.number="$v.tekanan.po_baro_14.$model"
                  :state="validateState($v.tekanan.po_baro_14)"
                  id="input_rh_14"
                  size="sm"
                  class="text-center mbtm"
                ></b-form-input>
                <b-tooltip v-if="rc_rh_14" target="input_rh_14" triggers="hover" placement="topleft">{{ rc_rh_14_message }}</b-tooltip>
                <b-tooltip v-if="!rc_rh_14" :show="validateTooltipStateTekanan('po_baro_14')" :triggers="'hover'" target="input_rh_14" placement="topleft" custom-class="validation-tooltip">
                  {{ tooltipMessage("tekanan_po_baro_14") }}
                </b-tooltip>
                <b-form-input
                  :formatter="format_one_decimal"
                  v-on:keydown="onKeyCheck($event)"
                  v-on:keyup="checkValidate($event, 'rh_15')"
                  v-bind:class="{ rangecheck: rc_rh_15 }"
                  type="number"
                  v-model.number="$v.tekanan.po_baro_15.$model"
                  :state="validateState($v.tekanan.po_baro_15)"
                  id="input_rh_15"
                  size="sm"
                  class="text-center mbtm"
                ></b-form-input>
                <b-tooltip v-if="rc_rh_15" target="input_rh_15" triggers="hover" placement="topleft">{{ rc_rh_15_message }}</b-tooltip>
                <b-tooltip v-if="!rc_rh_15" :show="validateTooltipStateTekanan('po_baro_15')" :triggers="'hover'" target="input_rh_15" placement="topleft" custom-class="validation-tooltip">
                  {{ tooltipMessage("tekanan_po_baro_15") }}
                </b-tooltip>
                <b-form-input
                  :formatter="format_one_decimal"
                  v-on:keydown="onKeyCheck($event)"
                  v-on:keyup="checkValidate($event, 'rh_16')"
                  v-bind:class="{ rangecheck: rc_rh_16 }"
                  type="number"
                  v-model.number="$v.tekanan.po_baro_16.$model"
                  :state="validateState($v.tekanan.po_baro_16)"
                  id="input_rh_16"
                  size="sm"
                  class="text-center mbtm"
                ></b-form-input>
                <b-tooltip v-if="rc_rh_16" target="input_rh_16" triggers="hover" placement="topleft">{{ rc_rh_16_message }}</b-tooltip>
                <b-tooltip v-if="!rc_rh_16" :show="validateTooltipStateTekanan('po_baro_16')" :triggers="'hover'" target="input_rh_16" placement="topleft" custom-class="validation-tooltip">
                  {{ tooltipMessage("tekanan_po_baro_16") }}
                </b-tooltip>
                <b-form-input
                  :formatter="format_one_decimal"
                  v-on:keydown="onKeyCheck($event)"
                  v-on:keyup="checkValidate($event, 'rh_17')"
                  v-bind:class="{ rangecheck: rc_rh_17 }"
                  type="number"
                  v-model.number="$v.tekanan.po_baro_17.$model"
                  :state="validateState($v.tekanan.po_baro_17)"
                  id="input_rh_17"
                  size="sm"
                  class="text-center mbtm"
                ></b-form-input>
                <b-tooltip v-if="rc_rh_17" target="input_rh_17" triggers="hover" placement="topleft">{{ rc_rh_17_message }}</b-tooltip>
                <b-tooltip v-if="!rc_rh_17" :show="validateTooltipStateTekanan('po_baro_17')" :triggers="'hover'" target="input_rh_17" placement="topleft" custom-class="validation-tooltip">
                  {{ tooltipMessage("tekanan_po_baro_17") }}
                </b-tooltip>
                <b-form-input
                  :formatter="format_one_decimal"
                  v-on:keydown="onKeyCheck($event)"
                  v-on:keyup="checkValidate($event, 'rh_18')"
                  v-bind:class="{ rangecheck: rc_rh_18 }"
                  type="number"
                  v-model.number="$v.tekanan.po_baro_18.$model"
                  :state="validateState($v.tekanan.po_baro_18)"
                  id="input_rh_18"
                  size="sm"
                  class="text-center mbtm"
                ></b-form-input>
                <b-tooltip v-if="rc_rh_18" target="input_rh_18" triggers="hover" placement="topleft">{{ rc_rh_18_message }}</b-tooltip>
                <b-tooltip v-if="!rc_rh_18" :show="validateTooltipStateTekanan('po_baro_18')" :triggers="'hover'" target="input_rh_18" placement="topleft" custom-class="validation-tooltip">
                  {{ tooltipMessage("tekanan_po_baro_18") }}
                </b-tooltip>
              </b-col>

              <b-col sm="1 mb-2 pad2">
                <div>
                  <label for="input-baro_19" class="label-entry1"
                    >19:00<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Besarnya tekanan udara pada pukul 19 waktu setempat dalam satuan milibar"
                  /></label>
                </div>
                <div>
                  <label for="input-baro_20" class="label-entry1"
                    >20:00<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Besarnya tekanan udara pada pukul 20 waktu setempat dalam satuan milibar"
                  /></label>
                </div>
                <div>
                  <label for="input-baro_21" class="label-entry1"
                    >21:00<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Besarnya tekanan udara pada pukul 21 waktu setempat dalam satuan milibar"
                  /></label>
                </div>
                <div>
                  <label for="input-baro_22" class="label-entry1"
                    >22:00<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Besarnya tekanan udara pada pukul 22 waktu setempat dalam satuan milibar"
                  /></label>
                </div>
                <div>
                  <label for="input-baro_23" class="label-entry1"
                    >23:00<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Besarnya tekanan udara pada pukul 23 waktu setempat dalam satuan milibar"
                  /></label>
                </div>
                <div>
                  <label for="input-baro_24" class="label-entry1"
                    >24:00<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Besarnya tekanan udara pada pukul 24 waktu setempat dalam satuan milibar"
                  /></label>
                </div>
              </b-col>
              <b-col sm="2 mb-2">
                <b-form-input
                  :formatter="format_one_decimal"
                  v-on:keydown="onKeyCheck($event)"
                  v-on:keyup="checkValidate($event, 'rh_19')"
                  v-bind:class="{ rangecheck: rc_rh_19 }"
                  type="number"
                  v-model.number="$v.tekanan.po_baro_19.$model"
                  :state="validateState($v.tekanan.po_baro_19)"
                  id="input_rh_19"
                  size="sm"
                  class="text-center mbtm"
                ></b-form-input>
                <b-tooltip v-if="rc_rh_19" target="input_rh_19" triggers="hover" placement="topleft">{{ rc_rh_19_message }}</b-tooltip>
                <b-tooltip v-if="!rc_rh_19" :show="validateTooltipStateTekanan('po_baro_19')" :triggers="'hover'" target="input_rh_19" placement="topleft" custom-class="validation-tooltip">
                  {{ tooltipMessage("tekanan_po_baro_19") }}
                </b-tooltip>
                <b-form-input
                  :formatter="format_one_decimal"
                  v-on:keydown="onKeyCheck($event)"
                  v-on:keyup="checkValidate($event, 'rh_20')"
                  v-bind:class="{ rangecheck: rc_rh_20 }"
                  type="number"
                  v-model.number="$v.tekanan.po_baro_20.$model"
                  :state="validateState($v.tekanan.po_baro_20)"
                  id="input_rh_20"
                  size="sm"
                  class="text-center mbtm"
                ></b-form-input>
                <b-tooltip v-if="rc_rh_20" target="input_rh_20" triggers="hover" placement="topleft">{{ rc_rh_20_message }}</b-tooltip>
                <b-tooltip v-if="!rc_rh_20" :show="validateTooltipStateTekanan('po_baro_20')" :triggers="'hover'" target="input_rh_20" placement="topleft" custom-class="validation-tooltip">
                  {{ tooltipMessage("tekanan_po_baro_20") }}
                </b-tooltip>
                <b-form-input
                  :formatter="format_one_decimal"
                  v-on:keydown="onKeyCheck($event)"
                  v-on:keyup="checkValidate($event, 'rh_21')"
                  v-bind:class="{ rangecheck: rc_rh_21 }"
                  type="number"
                  v-model.number="$v.tekanan.po_baro_21.$model"
                  :state="validateState($v.tekanan.po_baro_21)"
                  id="input_rh_21"
                  size="sm"
                  class="text-center mbtm"
                ></b-form-input>
                <b-tooltip v-if="rc_rh_21" target="input_rh_21" triggers="hover" placement="topleft">{{ rc_rh_21_message }}</b-tooltip>
                <b-tooltip v-if="!rc_rh_21" :show="validateTooltipStateTekanan('po_baro_21')" :triggers="'hover'" target="input_rh_21" placement="topleft" custom-class="validation-tooltip">
                  {{ tooltipMessage("tekanan_po_baro_21") }}
                </b-tooltip>
                <b-form-input
                  :formatter="format_one_decimal"
                  v-on:keydown="onKeyCheck($event)"
                  v-on:keyup="checkValidate($event, 'rh_22')"
                  v-bind:class="{ rangecheck: rc_rh_22 }"
                  type="number"
                  v-model.number="$v.tekanan.po_baro_22.$model"
                  :state="validateState($v.tekanan.po_baro_22)"
                  id="input_rh_22"
                  size="sm"
                  class="text-center mbtm"
                ></b-form-input>
                <b-tooltip v-if="rc_rh_22" target="input_rh_22" triggers="hover" placement="topleft">{{ rc_rh_22_message }}</b-tooltip>
                <b-tooltip v-if="!rc_rh_22" :show="validateTooltipStateTekanan('po_baro_22')" :triggers="'hover'" target="input_rh_22" placement="topleft" custom-class="validation-tooltip">
                  {{ tooltipMessage("tekanan_po_baro_22") }}
                </b-tooltip>
                <b-form-input
                  :formatter="format_one_decimal"
                  v-on:keydown="onKeyCheck($event)"
                  v-on:keyup="checkValidate($event, 'rh_23')"
                  v-bind:class="{ rangecheck: rc_rh_23 }"
                  type="number"
                  v-model.number="$v.tekanan.po_baro_23.$model"
                  :state="validateState($v.tekanan.po_baro_23)"
                  id="input_rh_23"
                  size="sm"
                  class="text-center mbtm"
                ></b-form-input>
                <b-tooltip v-if="rc_rh_23" target="input_rh_23" triggers="hover" placement="topleft">{{ rc_rh_23_message }}</b-tooltip>
                <b-tooltip v-if="!rc_rh_23" :show="validateTooltipStateTekanan('po_baro_23')" :triggers="'hover'" target="input_rh_23" placement="topleft" custom-class="validation-tooltip">
                  {{ tooltipMessage("tekanan_po_baro_23") }}
                </b-tooltip>
                <b-form-input
                  :formatter="format_one_decimal"
                  v-on:keydown="onKeyCheck($event)"
                  v-on:keyup="checkValidate($event, 'rh_24')"
                  v-bind:class="{ rangecheck: rc_rh_24 }"
                  type="number"
                  v-model.number="$v.tekanan.po_baro_24.$model"
                  :state="validateState($v.tekanan.po_baro_24)"
                  id="input_rh_24"
                  size="sm"
                  class="text-center mbtm"
                ></b-form-input>
                <b-tooltip v-if="rc_rh_24" target="input_rh_24" triggers="hover" placement="topleft">{{ rc_rh_24_message }}</b-tooltip>
                <b-tooltip v-if="!rc_rh_24" :show="validateTooltipStateTekanan('po_baro_24')" :triggers="'hover'" target="input_rh_24" placement="topleft" custom-class="validation-tooltip">
                  {{ tooltipMessage("tekanan_po_baro_24") }}
                </b-tooltip>
              </b-col>

              <b-col sm="1 mb-2 pad2">
                <div>
                  <label for="input-baro_01" class="label-entry1"
                    >1:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Besarnya tekanan udara pada pukul 1 waktu setempat dalam satuan milibar"
                  /></label>
                </div>
                <div>
                  <label for="input-baro_02" class="label-entry1"
                    >2:00<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Besarnya tekanan udara pada pukul 2 waktu setempat dalam satuan milibar" />
                  </label>
                </div>
                <div>
                  <label for="input-baro_03" class="label-entry1"
                    >3:00<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Besarnya tekanan udara pada pukul 3 waktu setempat dalam satuan milibar"
                  /></label>
                </div>
                <div>
                  <label for="input-baro_04" class="label-entry1"
                    >4:00<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Besarnya tekanan udara pada pukul 4 waktu setempat dalam satuan milibar"
                  /></label>
                </div>
                <div>
                  <label for="input-baro_05" class="label-entry1"
                    >5:00<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Besarnya tekanan udara pada pukul 5 waktu setempat dalam satuan milibar"
                  /></label>
                </div>
                <div>
                  <label for="input-baro_06" class="label-entry1"
                    >6:00<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Besarnya tekanan udara pada pukul 6 waktu setempat dalam satuan milibar"
                  /></label>
                </div>
              </b-col>
              <b-col sm="2 mb-2">
                <b-form-input
                  :formatter="format_one_decimal"
                  v-on:keydown="onKeyCheck($event)"
                  v-on:keyup="checkValidate($event, 'rh_1')"
                  v-bind:class="{ rangecheck: rc_rh_1 }"
                  type="number"
                  v-model.number="$v.tekanan.po_baro_01.$model"
                  :state="validateState($v.tekanan.po_baro_01)"
                  id="input_rh_1"
                  size="sm"
                  class="text-center mbtm"
                ></b-form-input>
                <b-tooltip v-if="rc_rh_1" target="input_rh_1" triggers="hover" placement="topleft">{{ rc_rh_1_message }}</b-tooltip>
                <b-tooltip v-if="!rc_rh_1" :show="validateTooltipStateTekanan('po_baro_01')" :triggers="'hover'" target="input_rh_1" placement="topleft" custom-class="validation-tooltip">
                  {{ tooltipMessage("tekanan_po_baro_01") }}
                </b-tooltip>
                <b-form-input
                  :formatter="format_one_decimal"
                  v-on:keydown="onKeyCheck($event)"
                  v-on:keyup="checkValidate($event, 'rh_2')"
                  v-bind:class="{ rangecheck: rc_rh_2 }"
                  type="number"
                  v-model.number="$v.tekanan.po_baro_02.$model"
                  :state="validateState($v.tekanan.po_baro_02)"
                  id="input_rh_2"
                  size="sm"
                  class="text-center mbtm"
                ></b-form-input>
                <b-tooltip v-if="rc_rh_2" target="input_rh_2" triggers="hover" placement="topleft">{{ rc_rh_2_message }}</b-tooltip>
                <b-tooltip v-if="!rc_rh_2" :show="validateTooltipStateTekanan('po_baro_02')" :triggers="'hover'" target="input_rh_2" placement="topleft" custom-class="validation-tooltip">
                  {{ tooltipMessage("tekanan_po_baro_02") }}
                </b-tooltip>
                <b-form-input
                  :formatter="format_one_decimal"
                  v-on:keydown="onKeyCheck($event)"
                  v-on:keyup="checkValidate($event, 'rh_3')"
                  v-bind:class="{ rangecheck: rc_rh_3 }"
                  type="number"
                  v-model.number="$v.tekanan.po_baro_03.$model"
                  :state="validateState($v.tekanan.po_baro_03)"
                  id="input_rh_3"
                  size="sm"
                  class="text-center mbtm"
                ></b-form-input>
                <b-tooltip v-if="rc_rh_3" target="input_rh_3" triggers="hover" placement="topleft">{{ rc_rh_3_message }}</b-tooltip>
                <b-tooltip v-if="!rc_rh_3" :show="validateTooltipStateTekanan('po_baro_03')" :triggers="'hover'" target="input_rh_3" placement="topleft" custom-class="validation-tooltip">
                  {{ tooltipMessage("tekanan_po_baro_03") }}
                </b-tooltip>
                <b-form-input
                  :formatter="format_one_decimal"
                  v-on:keydown="onKeyCheck($event)"
                  v-on:keyup="checkValidate($event, 'rh_4')"
                  v-bind:class="{ rangecheck: rc_rh_4 }"
                  type="number"
                  v-model.number="$v.tekanan.po_baro_04.$model"
                  :state="validateState($v.tekanan.po_baro_04)"
                  id="input_rh_4"
                  size="sm"
                  class="text-center mbtm"
                ></b-form-input>
                <b-tooltip v-if="rc_rh_4" target="input_rh_4" triggers="hover" placement="topleft">{{ rc_rh_4_message }}</b-tooltip>
                <b-tooltip v-if="!rc_rh_4" :show="validateTooltipStateTekanan('po_baro_04')" :triggers="'hover'" target="input_rh_4" placement="topleft" custom-class="validation-tooltip">
                  {{ tooltipMessage("tekanan_po_baro_04") }}
                </b-tooltip>
                <b-form-input
                  :formatter="format_one_decimal"
                  v-on:keydown="onKeyCheck($event)"
                  v-on:keyup="checkValidate($event, 'rh_5')"
                  v-bind:class="{ rangecheck: rc_rh_5 }"
                  type="number"
                  v-model.number="$v.tekanan.po_baro_05.$model"
                  :state="validateState($v.tekanan.po_baro_05)"
                  id="input_rh_5"
                  size="sm"
                  class="text-center mbtm"
                ></b-form-input>
                <b-tooltip v-if="rc_rh_5" target="input_rh_5" triggers="hover" placement="topleft">{{ rc_rh_5_message }}</b-tooltip>
                <b-tooltip v-if="!rc_rh_5" :show="validateTooltipStateTekanan('po_baro_05')" :triggers="'hover'" target="input_rh_5" placement="topleft" custom-class="validation-tooltip">
                  {{ tooltipMessage("tekanan_po_baro_05") }}
                </b-tooltip>
                <b-form-input
                  :formatter="format_one_decimal"
                  v-on:keydown="onKeyCheck($event)"
                  v-on:keyup="checkValidate($event, 'rh_6')"
                  v-bind:class="{ rangecheck: rc_rh_6 }"
                  type="number"
                  v-model.number="$v.tekanan.po_baro_06.$model"
                  :state="validateState($v.tekanan.po_baro_06)"
                  id="input_rh_6"
                  size="sm"
                  class="text-center mbtm"
                ></b-form-input>
                <b-tooltip v-if="rc_rh_6" target="input_rh_6" triggers="hover" placement="topleft">{{ rc_rh_6_message }}</b-tooltip>
                <b-tooltip v-if="!rc_rh_6" :show="validateTooltipStateTekanan('po_baro_06')" :triggers="'hover'" target="input_rh_6" placement="topleft" custom-class="validation-tooltip">
                  {{ tooltipMessage("tekanan_po_baro_06") }}
                </b-tooltip>
              </b-col>

              <b-col sm="2 pad2">
                <div>
                  <label for="input-baro_avg_24" class="label-entry bg-light-primary font-weight-bold"
                    >Rata-rata <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Rata-rata tekanan udara selama 24 jam"
                  /></label>
                </div>
              </b-col>
              <b-col sm="2 ">
                <b-form-input type="number" v-model.number="po_baro_avg_24" id="input-baro_avg_24" size="sm" class="text-center input-entry mb-2 bg-light-success" readonly></b-form-input>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-card>

    <b-row class="mt-n2">
      <b-col cols="12">
        <b-card>
          <b-row class="float-right">
            <div>
              <b-button variant="danger" class="mr-2" size="sm" @click="resetForm">Batal</b-button>
              <b-button variant="warning" class="mr-2" size="sm" @click="V_Form" v-if="this.t_data_status == 'update'" :disabled="!roles.isRoleEdit">Update</b-button>
              <b-button variant="primary" class="mr-2" size="sm" @click="V_Form" v-if="this.t_data_status == 'insert'" :disabled="!roles.isRoleAdd">Submit</b-button>
            </div>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { BRow, BCol, BTable, BCardText, BFormInput, BContainer, BButton, BCard, VBTooltip, BTooltip } from "bootstrap-vue";
import axios from "axios";
import Helper from "@/api/helper";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import error_handler from "@/api/error_handler";
import { validationMixin } from "vuelidate";
import { required, minLength, between } from "vuelidate/lib/validators";
import { V_Barograf, GC_PIAS } from "@/validators/piasvalidator";
import Ripple from "vue-ripple-directive";
import Params from "@/attr/metadata/params";
import RC_helper from "@/validators/RC_helper";
import qc_helper from "@/api/qc_helper";
export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BTable,
    BCardText,
    BFormInput,
    BContainer,
    BButton,
    BCard,
    VBTooltip,
    BTooltip,
  },
  props: ["t_station", "t_wmoid", "qc_baro", "t_observer", "t_date", "t_station_id", "t_observer_name", "data_qc_auto", "t_path", "t_data", "t_id_form", "t_data_status", "t_path_update", "roles", "count_form_click"],
  mixins: [validationMixin],
  validations: {
    tekanan: {
      po_baro_07: { required, V_Barograf },
      po_baro_08: { required, V_Barograf },
      po_baro_09: { required, V_Barograf },
      po_baro_10: { required, V_Barograf },
      po_baro_11: { required, V_Barograf },
      po_baro_12: { required, V_Barograf },
      po_baro_13: { required, V_Barograf },
      po_baro_14: { required, V_Barograf },
      po_baro_15: { required, V_Barograf },
      po_baro_16: { required, V_Barograf },
      po_baro_17: { required, V_Barograf },
      po_baro_18: { required, V_Barograf },
      po_baro_19: { required, V_Barograf },
      po_baro_20: { required, V_Barograf },
      po_baro_21: { required, V_Barograf },
      po_baro_22: { required, V_Barograf },
      po_baro_23: { required, V_Barograf },
      po_baro_24: { required, V_Barograf },
      po_baro_01: { required, V_Barograf },
      po_baro_02: { required, V_Barograf },
      po_baro_03: { required, V_Barograf },
      po_baro_04: { required, V_Barograf },
      po_baro_05: { required, V_Barograf },
      po_baro_06: { required, V_Barograf },
    },
  },
  data() {
    return {
      id_baro_update: "",
      tekanan: {
        po_baro_07: "",
        po_baro_08: "",
        po_baro_09: "",
        po_baro_10: "",
        po_baro_11: "",
        po_baro_12: "",
        po_baro_13: "",
        po_baro_14: "",
        po_baro_15: "",
        po_baro_16: "",
        po_baro_17: "",
        po_baro_18: "",
        po_baro_19: "",
        po_baro_20: "",
        po_baro_21: "",
        po_baro_22: "",
        po_baro_23: "",
        po_baro_24: "",
        po_baro_01: "",
        po_baro_02: "",
        po_baro_03: "",
        po_baro_04: "",
        po_baro_05: "",
        po_baro_06: "",
      },
      RC: {},
      RC_helper: RC_helper,
      RC_RH: null,
      rc_rh_1: false,
      rc_rh_1_message: "",
      rc_rh_2: false,
      rc_rh_2_message: "",
      rc_rh_3: false,
      rc_rh_3_message: "",
      rc_rh_4: false,
      rc_rh_4_message: "",
      rc_rh_5: false,
      rc_rh_5_message: "",
      rc_rh_6: false,
      rc_rh_6_message: "",
      rc_rh_7: false,
      rc_rh_7_message: "",
      rc_rh_8: false,
      rc_rh_8_message: "",
      rc_rh_9: false,
      rc_rh_9_message: "",
      rc_rh_10: false,
      rc_rh_10_message: "",

      rc_rh_11: false,
      rc_rh_11_message: "",
      rc_rh_12: false,
      rc_rh_12_message: "",
      rc_rh_13: false,
      rc_rh_13_message: "",
      rc_rh_14: false,
      rc_rh_14_message: "",
      rc_rh_15: false,
      rc_rh_15_message: "",
      rc_rh_16: false,
      rc_rh_16_message: "",
      rc_rh_17: false,
      rc_rh_17_message: "",
      rc_rh_18: false,
      rc_rh_18_message: "",
      rc_rh_19: false,
      rc_rh_19_message: "",
      rc_rh_20: false,
      rc_rh_20_message: "",

      rc_rh_21: false,
      rc_rh_21_message: "",
      rc_rh_22: false,
      rc_rh_22_message: "",
      rc_rh_23: false,
      rc_rh_23_message: "",
      rc_rh_24: false,
      rc_rh_24_message: "",

      data_field: {
        rh_1: "po_baro_01",
        rh_2: "po_baro_02",
        rh_3: "po_baro_03",
        rh_4: "po_baro_04",
        rh_5: "po_baro_05",
        rh_6: "po_baro_06",
        rh_7: "po_baro_07",
        rh_8: "po_baro_08",
        rh_9: "po_baro_09",
        rh_10: "po_baro_10",
        rh_11: "po_baro_11",
        rh_12: "po_baro_12",
        rh_13: "po_baro_13",
        rh_14: "po_baro_14",
        rh_15: "po_baro_15",
        rh_16: "po_baro_16",
        rh_17: "po_baro_17",
        rh_18: "po_baro_18",
        rh_19: "po_baro_19",
        rh_20: "po_baro_20",
        rh_21: "po_baro_21",
        rh_22: "po_baro_22",
        rh_23: "po_baro_23",
        rh_24: "po_baro_24",
      },
      qc_flag: 0,
      qc_flag_before: 0,
      RC_Before: null,
      RC_After: null,
      RC_Current: null,
      QC_Histories: {},
      last_qc_histories: {},
    };
  },
  computed: {
    po_baro_avg_24: function () {
      let data = Object.values(this.tekanan);
      let same9999 = data.every((val, i, arr) => val === 9999);
      let same8888 = data.every((val, i, arr) => val === 8888);
      console.log(data);
      if (same9999) {
        return parseInt(9999);
      } else if (same8888) {
        return parseInt(8888);
      } else {
        let values = data.filter((val) => val !== 9999 && val !== 8888);
        var leng = data.filter((val) => val !== 9999 && val !== 8888 && val !== "").length;
        return parseFloat(values.reduce((a, b) => (a || 0) + (b || 0)) / leng).toFixed(1);
      }

      // return (values.map((val) => parseFloat(val) || 0).reduce((a, b) => (a == 9999 || a == 8888 ? 0 : a || 0) + (b == 9999 || b == 8888 ? 0 : b || 0)) / values.length).toFixed(1);
    },
  },
  mounted() {
    // console.log("auto QC : ",this.data_qc_auto)
    var autoQC = this.data_qc_auto;
    var minQC = autoQC.qc_autorumus_pressure_qfe_mb_derived_min;
    var maxQC = autoQC.qc_autorumus_pressure_qfe_mb_derived_max;
    this.RC_RH = {
      min: minQC,
      max: maxQC,
    };
    if (this.t_data_status == "update") {
      this.popupData();
    } else {
      this.resetForm();
    }
  },
  watch: {
    t_station(value) {
      console.log("ubah station : ", this.t_path);
    },
    t_data_status(newValue, OldValue) {
      if (newValue == "update") {
        this.popupData();
      } else {
        this.resetForm();
      }
    },
    count_form_click(NewValue, OldValue) {
      if (this.t_data_status == "insert") {
        if (NewValue != OldValue) {
          this.resetForm();
        }
      }
    },
  },
  methods: {
    async setQCData() {
      // console.log("call set data qc");
      // const qc_data = await metadata.getQCData(this.path_hujan, Params.GET_QC_STATION_KEY("form_hujan"));
      // const qc_params = Params.GET_PARAMS("form_hujan");
      // const qc_key = Params.GET_KEY("form_hujan");
      // this.RC_helper.setData(qc_data, qc_params, qc_key);
    },
    checkRC(value) {
      let status = true;
      let message = "";
      if (this.RC_RH == null) {
        status = true;
      } else {
        let max = this.RC_RH.max;
        let min = this.RC_RH.min;
        if (min == null && max == null) {
          status = true;
        } else if (min == null && max != null) {
          if (value <= max) {
            status = true;
          } else {
            status = false;
            message = "Range Check - Batas Bawah , Batas Atas " + max;
          }
        } else if (min != null && max == null) {
          if (value >= min) {
            status = true;
          } else {
            status = false;
            message = "Range Check - Batas Bawah " + min + ", Batas Atas ";
          }
        } else {
          if (value >= min && value <= max) {
            status = true;
          } else if (value == 9999 || value == 8888) {
            status = true;
          } else {
            status = false;
            message = "Range Check - Batas Bawah " + min + ", Batas Atas " + max;
          }
        }
      }
      return { status: status, message: message };
    },
    checkValidate: function (event, type) {
      // console.log("check validate  : ",type)
      let value = event.target.value;
      let type_field = type;
      switch (type) {
        case "rh_1":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_1 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_1_message = raw.message;
          console.log("Rc rh 1", this.rc_rh_1);
          break;
        case "rh_2":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_2 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_2_message = raw.message;
          break;
        case "rh_3":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_3 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_3_message = raw.message;
          break;
        case "rh_4":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_4 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_4_message = raw.message;
          break;
        case "rh_5":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_5 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_5_message = raw.message;
          break;
        case "rh_6":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_6 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_6_message = raw.message;
          break;
        case "rh_7":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_7 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_7_message = raw.message;
          break;
        case "rh_8":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_8 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_8_message = raw.message;
          break;
        case "rh_9":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_9 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_9_message = raw.message;
          break;
        case "rh_10":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_10 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_10_message = raw.message;
          break;
        case "rh_11":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_11 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_11_message = raw.message;
          break;
        case "rh_12":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_12 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_12_message = raw.message;
          break;
        case "rh_13":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_13 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_13_message = raw.message;
          break;
        case "rh_14":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_14 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_14_message = raw.message;
          break;
        case "rh_15":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_15 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_15_message = raw.message;
          break;
        case "rh_16":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_16 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_16_message = raw.message;
          break;
        case "rh_17":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_17 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_17_message = raw.message;
          break;
        case "rh_18":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_18 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_18_message = raw.message;
          break;
        case "rh_19":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_19 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_19_message = raw.message;
          break;
        case "rh_20":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_20 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_20_message = raw.message;
          break;
        case "rh_21":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_21 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_21_message = raw.message;
          break;
        case "rh_22":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_22 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_22_message = raw.message;
          break;
        case "rh_23":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_23 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_23_message = raw.message;
          break;
        case "rh_24":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_24 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_24_message = raw.message;
          break;

        default:
          break;
      }
      this.RC[type_field] = {
        status: gc && !raw.status,
        message: raw.message,
        value: value,
      };
    },
    onKeyCheck(event) {
      // console.log('onKeyCheck', event)
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault();
      }
    },
    checkValidateEdit(value, type) {
      // console.log("check validate  : ",type)
      let type_field = type;
      switch (type) {
        case "rh_1":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_1 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_1_message = raw.message;
          // console.log("Rc rh 1", this.rc_rh_1)
          break;
        case "rh_2":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_2 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_2_message = raw.message;
          break;
        case "rh_3":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_3 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_3_message = raw.message;
          break;
        case "rh_4":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_4 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_4_message = raw.message;
          break;
        case "rh_5":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_5 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_5_message = raw.message;
          break;
        case "rh_6":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_6 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_6_message = raw.message;
          break;
        case "rh_7":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_7 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_7_message = raw.message;
          break;
        case "rh_8":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_8 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_8_message = raw.message;
          break;
        case "rh_9":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_9 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_9_message = raw.message;
          break;
        case "rh_10":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_10 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_10_message = raw.message;
          break;
        case "rh_11":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_11 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_11_message = raw.message;
          break;
        case "rh_12":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_12 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_12_message = raw.message;
          break;
        case "rh_13":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_13 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_13_message = raw.message;
          break;
        case "rh_14":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_14 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_14_message = raw.message;
          break;
        case "rh_15":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_15 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_15_message = raw.message;
          break;
        case "rh_16":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_16 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_16_message = raw.message;
          break;
        case "rh_17":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_17 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_17_message = raw.message;
          break;
        case "rh_18":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_18 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_18_message = raw.message;
          break;
        case "rh_19":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_19 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_19_message = raw.message;
          break;
        case "rh_20":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_20 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_20_message = raw.message;
          break;
        case "rh_21":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_21 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_21_message = raw.message;
          break;
        case "rh_22":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_22 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_22_message = raw.message;
          break;
        case "rh_23":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_23 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_23_message = raw.message;
          break;
        case "rh_24":
          var raw = this.checkRC(value);
          var gc = V_Barograf(value);
          this.rc_rh_24 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_24_message = raw.message;
          break;

        default:
          break;
      }
      this.RC[type_field] = {
        status: gc && !raw.status,
        message: raw.message,
        value: value,
      };
    },
    format_one_decimal(e) {
      if (Math.round(e * 10) / 10 != e) {
        let text = String(e);
        let filter = text.substring(0, text.length - 1);
        return filter;
      } else {
        if (Math.floor(e) === e) {
          return e;
        } else {
          if (e.toString().indexOf(".") > 0) {
            let count = e.toString().split(".")[1].length || 0;
            return count > 1 ? parseFloat(e).toFixed(1) : e;
          } else {
            return e;
          }
        }
        return e;
      }
    },
    //tooltips
    tooltipMessage(attr) {
      return GC_PIAS(attr).message;
    },
    validateTooltipStateTekanan(name) {
      // const { $error } = this.$v.tekanan[name];
      // return $error;
      return false;
    },

    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    popupData() {
      this.id_baro_update = this.t_data["@name"];
      this.tekanan.po_baro_07 = this.t_data.po_baro_07;
      this.tekanan.po_baro_08 = this.t_data.po_baro_08;
      this.tekanan.po_baro_09 = this.t_data.po_baro_09;
      this.tekanan.po_baro_10 = this.t_data.po_baro_10;
      this.tekanan.po_baro_11 = this.t_data.po_baro_11;
      this.tekanan.po_baro_12 = this.t_data.po_baro_12;
      this.tekanan.po_baro_13 = this.t_data.po_baro_13;
      this.tekanan.po_baro_14 = this.t_data.po_baro_14;
      this.tekanan.po_baro_15 = this.t_data.po_baro_15;
      this.tekanan.po_baro_16 = this.t_data.po_baro_16;
      this.tekanan.po_baro_17 = this.t_data.po_baro_17;
      this.tekanan.po_baro_18 = this.t_data.po_baro_18;
      this.tekanan.po_baro_19 = this.t_data.po_baro_19;
      this.tekanan.po_baro_20 = this.t_data.po_baro_20;
      this.tekanan.po_baro_21 = this.t_data.po_baro_21;
      this.tekanan.po_baro_22 = this.t_data.po_baro_22;
      this.tekanan.po_baro_23 = this.t_data.po_baro_23;
      this.tekanan.po_baro_24 = this.t_data.po_baro_24;
      this.tekanan.po_baro_01 = this.t_data.po_baro_01;
      this.tekanan.po_baro_02 = this.t_data.po_baro_02;
      this.tekanan.po_baro_03 = this.t_data.po_baro_03;
      this.tekanan.po_baro_04 = this.t_data.po_baro_04;
      this.tekanan.po_baro_05 = this.t_data.po_baro_05;
      this.tekanan.po_baro_06 = this.t_data.po_baro_06;
      this.po_baro_avg_24 = this.t_data.po_baro_avg_24;
      this.qc_flag_before = this.t_data.qc_flag;
      this.QC_Histories = this.t_data.qc_histories;
      // this.QC_Histories =  this.t_data.qc_histories != "" ? JSON.parse(this.t_data.qc_histories) : {}

      this.checkValidateEdit(this.t_data.po_baro_01, "rh_1");
      this.checkValidateEdit(this.t_data.po_baro_02, "rh_2");
      this.checkValidateEdit(this.t_data.po_baro_03, "rh_3");
      this.checkValidateEdit(this.t_data.po_baro_04, "rh_4");
      this.checkValidateEdit(this.t_data.po_baro_05, "rh_5");
      this.checkValidateEdit(this.t_data.po_baro_06, "rh_6");
      this.checkValidateEdit(this.t_data.po_baro_07, "rh_7");
      this.checkValidateEdit(this.t_data.po_baro_08, "rh_8");
      this.checkValidateEdit(this.t_data.po_baro_09, "rh_9");
      this.checkValidateEdit(this.t_data.po_baro_10, "rh_10");
      this.checkValidateEdit(this.t_data.po_baro_11, "rh_11");
      this.checkValidateEdit(this.t_data.po_baro_12, "rh_12");
      this.checkValidateEdit(this.t_data.po_baro_13, "rh_13");
      this.checkValidateEdit(this.t_data.po_baro_14, "rh_14");
      this.checkValidateEdit(this.t_data.po_baro_15, "rh_15");
      this.checkValidateEdit(this.t_data.po_baro_16, "rh_16");
      this.checkValidateEdit(this.t_data.po_baro_17, "rh_17");
      this.checkValidateEdit(this.t_data.po_baro_18, "rh_18");
      this.checkValidateEdit(this.t_data.po_baro_19, "rh_19");
      this.checkValidateEdit(this.t_data.po_baro_20, "rh_20");
      this.checkValidateEdit(this.t_data.po_baro_21, "rh_21");
      this.checkValidateEdit(this.t_data.po_baro_22, "rh_22");
      this.checkValidateEdit(this.t_data.po_baro_23, "rh_23");
      this.checkValidateEdit(this.t_data.po_baro_24, "rh_24");
    },
    V_Form() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Data tidak valid. Periksa kembali inputan anda",
            icon: "BellIcon",
            variant: "danger",
          },
        });
        return;
      } else {
        console.log("data RC: ", this.RC);
        let dataRC = Object.entries(this.RC).filter((e) => e[1].status);
        let temp_QC_After = [];
        for (var i in dataRC) {
          let row_qc_after = {
            raw_type: "Barograph",
            atribut: this.data_field[dataRC[i][0]],
            value: dataRC[i][1].value,
            roles: dataRC[i][1].message,
            type: "range_check",
            message: dataRC[i][1].message,
          };
          temp_QC_After.push(row_qc_after);
        }

        console.log("QC  AFTER : ", temp_QC_After);
        this.last_qc_histories["after"] = temp_QC_After;
        // this.last_qc_histories["before"] = "-";
        // this.last_qc_histories["after"] = temp_QC_After;
        // console.log("QC String : ",JSON.stringify(this.last_qc_histories))
        if (dataRC.length > 0) {
          this.$swal({
            title: "Apakah Anda Yakin?",
            text: "Ada Data Terkena Range Check ",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Submit",
            cancelButtonText: "Cek Data",
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              if (this.t_data_status == "insert") {
                this.submitForm();
              } else if (this.t_data_status == "update") {
                this.updateData();
              }
            } else if (result.dismiss === "cancel") {
              return;
            }
          });
        } else {
          if (this.t_data_status == "insert") {
            this.submitForm();
          } else if (this.t_data_status == "update") {
            this.updateData();
          }
        }
      }
    },
    resetForm() {
      this.tekanan.po_baro_07 = "";
      this.tekanan.po_baro_08 = "";
      this.tekanan.po_baro_09 = "";
      this.tekanan.po_baro_10 = "";
      this.tekanan.po_baro_11 = "";
      this.tekanan.po_baro_12 = "";
      this.tekanan.po_baro_13 = "";
      this.tekanan.po_baro_14 = "";
      this.tekanan.po_baro_15 = "";
      this.tekanan.po_baro_16 = "";
      this.tekanan.po_baro_17 = "";
      this.tekanan.po_baro_18 = "";
      this.tekanan.po_baro_19 = "";
      this.tekanan.po_baro_20 = "";
      this.tekanan.po_baro_21 = "";
      this.tekanan.po_baro_22 = "";
      this.tekanan.po_baro_23 = "";
      this.tekanan.po_baro_24 = "";
      this.tekanan.po_baro_01 = "";
      this.tekanan.po_baro_02 = "";
      this.tekanan.po_baro_03 = "";
      this.tekanan.po_baro_04 = "";
      this.tekanan.po_baro_05 = "";
      this.tekanan.po_baro_06 = "";

      this.rc_rh_1 = false;
      this.rc_rh_1_message = "";
      this.rc_rh_2 = false;
      this.rc_rh_2_message = "";
      this.rc_rh_3 = false;
      this.rc_rh_3_message = "";
      this.rc_rh_4 = false;
      this.rc_rh_4_message = "";
      this.rc_rh_5 = false;
      this.rc_rh_5_message = "";
      this.rc_rh_6 = false;
      this.rc_rh_6_message = "";
      this.rc_rh_7 = false;
      this.rc_rh_7_message = "";
      this.rc_rh_8 = false;
      this.rc_rh_8_message = "";
      this.rc_rh_9 = false;
      this.rc_rh_9_message = "";
      this.rc_rh_10 = false;
      this.rc_rh_10_message = "";

      this.rc_rh_11 = false;
      this.rc_rh_11_message = "";
      this.rc_rh_12 = false;
      this.rc_rh_12_message = "";
      this.rc_rh_13 = false;
      this.rc_rh_13_message = "";
      this.rc_rh_14 = false;
      this.rc_rh_14_message = "";
      this.rc_rh_15 = false;
      this.rc_rh_15_message = "";
      this.rc_rh_16 = false;
      this.rc_rh_16_message = "";
      this.rc_rh_17 = false;
      this.rc_rh_17_message = "";
      this.rc_rh_18 = false;
      this.rc_rh_18_message = "";
      this.rc_rh_19 = false;
      this.rc_rh_19_message = "";
      this.rc_rh_20 = false;
      this.rc_rh_20_message = "";

      this.rc_rh_21 = false;
      this.rc_rh_21_message = "";
      this.rc_rh_22 = false;
      this.rc_rh_22_message = "";
      this.rc_rh_23 = false;
      this.rc_rh_23_message = "";
      this.rc_rh_24 = false;
      this.rc_rh_24_message = "";

      this.$v.$reset();
    },
    updateData() {
      var len_qc_after = this.last_qc_histories["after"].length;
      var temp_qc_histories = {};
      if (this.QC_Histories != "" || this.QC_Histories != null) {
        temp_qc_histories = JSON.parse(this.QC_Histories);
      }
      console.log("TEMP QC : ", temp_qc_histories);
      this.last_qc_histories["before"] = [];
      if (temp_qc_histories != null) {
        if (temp_qc_histories["after"] != undefined) {
          this.last_qc_histories["before"] = temp_qc_histories["after"];
        }
      }

      // console.log("ada QC : ",len_qc_after);
      var flag_temp = 0;
      if (len_qc_after > 0) {
        flag_temp = 1;
      } else {
        if (this.qc_flag_before == 1) {
          flag_temp = 2;
        }
      }
      // console.log("QC FLAG : ",flag_temp);
      // console.log("QC HISTORIES : ",this.last_qc_histories);
      var qc_temp = JSON.stringify(this.last_qc_histories);

      const modification_date = this.t_date;
      const post_data = {
        po_baro_07: this.tekanan.po_baro_07,
        po_baro_08: this.tekanan.po_baro_08,
        po_baro_09: this.tekanan.po_baro_09,
        po_baro_10: this.tekanan.po_baro_10,
        po_baro_11: this.tekanan.po_baro_11,
        po_baro_12: this.tekanan.po_baro_12,
        po_baro_13: this.tekanan.po_baro_13,
        po_baro_14: this.tekanan.po_baro_14,
        po_baro_15: this.tekanan.po_baro_15,
        po_baro_16: this.tekanan.po_baro_16,
        po_baro_17: this.tekanan.po_baro_17,
        po_baro_18: this.tekanan.po_baro_18,
        po_baro_19: this.tekanan.po_baro_19,
        po_baro_20: this.tekanan.po_baro_20,
        po_baro_21: this.tekanan.po_baro_21,
        po_baro_22: this.tekanan.po_baro_22,
        po_baro_23: this.tekanan.po_baro_23,
        po_baro_24: this.tekanan.po_baro_24,
        po_baro_01: this.tekanan.po_baro_01,
        po_baro_02: this.tekanan.po_baro_02,
        po_baro_03: this.tekanan.po_baro_03,
        po_baro_04: this.tekanan.po_baro_04,
        po_baro_05: this.tekanan.po_baro_05,
        po_baro_06: this.tekanan.po_baro_06,
        po_baro_avg_24: this.po_baro_avg_24,
        modification_date: modification_date,
        observer_id: this.t_observer,
        qc_flag: flag_temp,
        qc_histories: qc_temp,
      };

      const update_url = Helper.getBaseUrl() + this.t_path_update;

      axios
        .patch(update_url, post_data, Helper.getConfig())
        .then((response) => {
          console.log(response);
          this.$swal({
            title: "Update data sukses!",
            text: "Data Telah berhasil di Update",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.setQCNotif(flag_temp, this.id_baro_update, modification_date, qc_temp);
          this.resetForm();
        })
        .catch((error) => {
          console.log(error);
          error_handler(this, error, "Barograph", "Update Data Error");
        });

      // if(flag_temp == 1){
      //   qc_helper.editQCNotification(
      //     this.id_baro_update, // id entry
      //     "Barograph", // type form entry
      //     modification_date, // jam entry
      //     "",
      //     qc_temp, // qc histories
      //     flag_temp,
      //     this.t_station_id,
      //     this.t_station.text,
      //     this.t_observer,
      //     this.t_observer_name // observer name
      //   );
      // }
    },
    setQCNotif(flag_temp, id, date, qc_temp) {
      var arr_path = this.t_path.split("/");
      var reg_id = arr_path[1];
      // console.log("qc flag : ",reg_id)
      // console.log("qc histories : ",this.t_path)
      qc_helper.editQCNotification(
        id, // id entry
        "Barograph", // type form entry
        date, // jam entry
        "",
        qc_temp, // qc histories
        flag_temp,
        this.t_station_id,
        this.t_station.text,
        this.t_observer,
        this.t_observer_name, // observer name
        reg_id,
        this.t_path + "/" + id
      );
    },
    submitForm() {
      var len_qc_after = this.last_qc_histories["after"].length;
      this.last_qc_histories["before"] = [];
      // console.log("ada QC : ",len_qc_after);
      var flag_temp = 0;
      if (len_qc_after > 0) {
        flag_temp = 1;
      }
      var qc_temp = JSON.stringify(this.last_qc_histories);
      const ID = this.t_station_id + "_barograph_" + this.t_date;
      const data_timestamp = this.t_date;

      const post_data = {
        "@type": "Barograph",
        id: ID,
        po_baro_07: this.tekanan.po_baro_07,
        po_baro_08: this.tekanan.po_baro_08,
        po_baro_09: this.tekanan.po_baro_09,
        po_baro_10: this.tekanan.po_baro_10,
        po_baro_11: this.tekanan.po_baro_11,
        po_baro_12: this.tekanan.po_baro_12,
        po_baro_13: this.tekanan.po_baro_13,
        po_baro_14: this.tekanan.po_baro_14,
        po_baro_15: this.tekanan.po_baro_15,
        po_baro_16: this.tekanan.po_baro_16,
        po_baro_17: this.tekanan.po_baro_17,
        po_baro_18: this.tekanan.po_baro_18,
        po_baro_19: this.tekanan.po_baro_19,
        po_baro_20: this.tekanan.po_baro_20,
        po_baro_21: this.tekanan.po_baro_21,
        po_baro_22: this.tekanan.po_baro_22,
        po_baro_23: this.tekanan.po_baro_23,
        po_baro_24: this.tekanan.po_baro_24,
        po_baro_01: this.tekanan.po_baro_01,
        po_baro_02: this.tekanan.po_baro_02,
        po_baro_03: this.tekanan.po_baro_03,
        po_baro_04: this.tekanan.po_baro_04,
        po_baro_05: this.tekanan.po_baro_05,
        po_baro_06: this.tekanan.po_baro_06,
        po_baro_avg_24: this.po_baro_avg_24,
        data_timestamp: data_timestamp,
        observer_id: this.t_observer,
        qc_flag: flag_temp,
        qc_histories: qc_temp,
      };

      const post_url = Helper.getBaseUrl() + this.t_path;

      axios
        .post(post_url, post_data, Helper.getConfig())
        .then((response) => {
          console.log(response);
          this.$swal({
            title: "Input Data Berhasil!",
            text: "Data Telah berhasil di Simpan",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          if (flag_temp == 1) {
            this.setQCNotif(flag_temp, ID, data_timestamp, qc_temp);
          }

          this.resetForm();
        })
        .catch((error) => {
          console.log(error);
          error_handler(this, error, "Barograph", "Input Data Error");
        });

      // if(flag_temp == 1){
      //   qc_helper.editQCNotification(
      //     ID, // id entry
      //     "Barograph", // type form entry
      //     data_timestamp, // jam entry,
      //     "",
      //     qc_temp, // qc histories
      //     flag_temp,
      //     this.t_station_id,
      //     this.t_station.text,
      //     this.t_observer,
      //     this.t_observer_name // observer name
      //   );
      // }
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
};
</script>
<style scoped>
.label-entry {
  text-align: center;
  color: white;
  width: 100%;
  /* font-weight: bold !important; */
  border: 2px solid #6e6b7b;
  border-radius: 3px;
  background: #858585;
  padding: 4px;
}
.label-entry1 {
  text-align: center;
  color: #3b4253;
  width: 100%;
  font-weight: 500;
  background-color: #f3f1f1;
  border: 2px solid #6e6b7b;
  border-radius: 3px;
  padding: 4px;
  margin-bottom: 6px;
}
.mbtm {
  margin-bottom: 6px;
}
.pad2 {
  padding-left: 0px;
  padding-right: 1px;
}

.bg-light-primary {
  color: #000000 !important;
}
.bg-light-success {
  color: #000000 !important;
}
#input_rh_1.rangecheck,
#input_rh_2.rangecheck,
#input_rh_3.rangecheck,
#input_rh_4.rangecheck,
#input_rh_5.rangecheck,
#input_rh_6.rangecheck,
#input_rh_7.rangecheck,
#input_rh_8.rangecheck,
#input_rh_9.rangecheck,
#input_rh_10.rangecheck,
#input_rh_11.rangecheck,
#input_rh_12.rangecheck,
#input_rh_13.rangecheck,
#input_rh_14.rangecheck,
#input_rh_15.rangecheck,
#input_rh_16.rangecheck,
#input_rh_17.rangecheck,
#input_rh_18.rangecheck,
#input_rh_19.rangecheck,
#input_rh_20.rangecheck,
#input_rh_21.rangecheck,
#input_rh_22.rangecheck,
#input_rh_23.rangecheck,
#input_rh_24.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
}
</style>
