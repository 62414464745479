
export default {

    rumus_Es(tbb, tbk) { //tbb & tbk
        let _tbb = parseFloat(tbb)
        let _tbk = parseFloat(tbk)
        let es =  ( ( 6.11 * Math.pow( 10, ((7.5 * _tbb)/(237.3 + _tbb)) ) ) - (0.7947 * (_tbk - _tbb)) )
        return es
    },

    rumus_E(tbk) { //tbk
        let _tbk = parseFloat(tbk)
        let e = ( 6.11 * Math.pow( 10 , (7.5 * _tbk/(237.3 + _tbk))) );
        return e
    },

    rumusWaktuSetempat(timezone, bujur) {
        if(!this.isValidValue(timezone)) {
            return ''
        }

        const boundary = {"7": 105, "8": 120, "9": 135}
        const selectedBound = boundary[String(timezone)]
        const diff = 4 * (selectedBound - bujur)
        const diff_rounded = Math.round(diff)

        return `${diff_rounded >=0?'+':''}${diff_rounded} menit`
        // return bujur <= selectedBound? `${diff_rounded} menit` : `${diff_rounded} menit`
    },

    parseIfOnlyNumber(val) {
        const onlyContainsNumbers = (str) => /^\d+$/.test(str);
        return onlyContainsNumbers(val) ? +val : val
    },

    parseIfOnlyNumberElseNull(val) {
        const onlyContainsNumbers = (str) => /^\d+$/.test(str);
        return onlyContainsNumbers(val) ? +val : null
    },

    getCleanValue(val) {
        // Avoid undefined and null, but not zero
        return val == "0"? val : (val || "")
    },

    isValidValue(val, invalid_special_value=[9999, "9999"]) {
        return val !== null && val !== undefined && 
            val !== "" && !isNaN(val) && !invalid_special_value.includes(val)
    }

}