export default {
  GET_PARAMS(type) {
    if (type == "me48") {
      return this.ME48_PARAMS();
    } else if (type == "metar") {
      return this.METAR_PARAMS();
    } else if (type == "pibal") {
      return this.PIBAL_PARAMS();
    } else if (type == "fklim") {
      return this.FKLIM_PARAMS();
    } else if (type == "agm1a") {
      return this.AGM1A_PARAMS();
    } else if (type == "agm1b") {
      return this.AGM1B_PARAMS();
    } else if (type == "iklim_mikro") {
      return this.IKLIM_MIKRO_PARAMS();
    } else if (type == "psychrometer_assman") {
      return this.PSYCHROMETER_ASSMAN_PARAMS();
    } else if (type == "suhu_tanah") {
      return this.SUHU_TANAH_PARAMS();
    } else if (type == "op_penguapan") {
      return this.OP_PENGUAPAN_PARAMS();
    } else if (type == "piche_penguapan") {
      return this.PICHE_PENGUAPAN_PARAMS();
    } else if (type == "lysimeter") {
      return this.LYSIMETER_PARAMS();
    } else if (type == "gunbellani") {
      return this.GUNBELLANI_PARAMS();
    } else if (type == "lama_penyinaran") {
      return this.LAMA_PENYINARAN_PARAMS();
    } else if (type == "aktinograph") {
      return this.AKTINOGRAPH_PARAMS();
    } else if (type == "thermohigograph") {
      return this.THERMOHIGOGRAPH_PARAMS();
    } else if (type == "barograph") {
      return this.BAROGRAPH_PARAMS();
    } else if (type == "perawanan") {
      return this.PERAWANAN_PARAMS();
    } else if (type == "kelembapan_tanah") {
      return this.KELEMBAPAN_TANAH_PARAMS();
    } else if (type == "form_hujan") {
      return this.FORM_HUJAN_PARAMS();
    } else if (type == "form_hujan_harian") {
      return this.FORM_HUJAN_HARIAN_PARAMS();
    } else if (type == "hujan_helman") {
      return this.HUJAN_HELMAN_PARAMS();
    } else if (type == "fenologi") {
      return this.FENOLOGI_PARAMS();
    } else if (type == "kimia_air_hujan") {
      return this.KAH_PARAMS();
    } else if (type == "suspended_particulate_matter") {
      return this.SPM_PARAMS();
    } else if (type == "so2no2") {
      return this.SO2NO2_PARAMS();
    } else if (type == "aerosol") {
      return this.AEROSOL_PARAMS();
    }
  },

  GET_KEY(type) {
    if (type == "me48") {
      return "qc_me48_";
    } else if (type == "metar") {
      return "qc_metar_";
    } else if (type == "pibal") {
      return "qc_pibal_";
    } else if (type == "fklim") {
      return "qc_fklim71_";
    } else if (type == "agm1a") {
      return "qc_agm1a_";
    } else if (type == "agm1b") {
      return "qc_agm1b_";
    } else if (type == "iklim_mikro") {
      return "qc_iklimmikro_";
    } else if (type == "psychrometer_assman") {
      return "qc_psychrometerassman_";
    } else if (type == "suhu_tanah") {
      return "qc_suhutanah_";
    } else if (type == "op_penguapan") {
      return "qc_oppenguapan_";
    } else if (type == "piche_penguapan") {
      return "qc_pichepenguapan_";
    } else if (type == "lysimeter") {
      return "qc_lysimeter_";
    } else if (type == "gunbellani") {
      return "qc_gunbellani_";
    } else if (type == "lama_penyinaran") {
      return "qc_lama_penyinaran_";
    } else if (type == "aktinograph") {
      return "qc_aktinograph_";
    } else if (type == "thermohigograph") {
      return "qc_thermohigograph_";
    } else if (type == "barograph") {
      return "qc_barograph_";
    } else if (type == "perawanan") {
      return "qc_perawanan_";
    } else if (type == "kelembapan_tanah") {
      return "qc_kelembapan_tanah_";
    } else if (type == "form_hujan") {
      return "qc_formhujan_";
    } else if (type == "form_hujan_harian") {
      return "qc_formhujanharian_";
    } else if (type == "hujan_helman") {
      return "qc_hujanhellman_";
    } else if (type == "fenologi") {
      return "qc_fenologi_";
    } else if (type == "kimia_air_hujan") {
      return "qc_kah_";
    } else if (type == "suspended_particulate_matter") {
      return "qc_spm_";
    } else if (type == "so2no2") {
      return "qc_so2no2_";
    } else if (type == "aerosol") {
      return "qc_aerosol_";
    }
  },

  GET_QC_STATION_KEY(type) {
    if (type == "me48") {
      return "BmkgSatu.metadata.qc.IME48qc";
    } else if (type == "metar") {
      return "BmkgSatu.metadata.qc.IMetarSpeciqc";
    } else if (type == "pibal") {
      return "";
    } else if (type == "fklim") {
      return "BmkgSatu.metadata.qc.IFklim71qc";
    } else if (type == "agm1a") {
      return "BmkgSatu.metadata.qc.IAgm1aqc";
    } else if (type == "agm1b") {
      return "BmkgSatu.metadata.qc.IAgm1bqc";
    } else if (type == "iklim_mikro") {
      return "BmkgSatu.metadata.qc.IIklimMikroqc";
    } else if (type == "psychrometer_assman") {
      return "BmkgSatu.metadata.qc.IPsychrometerAssmanqc";
    } else if (type == "suhu_tanah") {
      return "BmkgSatu.metadata.qc.ISuhuTanahqc";
    } else if (type == "op_penguapan") {
      return "BmkgSatu.metadata.qc.IOppenguapanqc";
    } else if (type == "piche_penguapan") {
      return "BmkgSatu.metadata.qc.IPichePenguapanqc";
    } else if (type == "lysimeter") {
      return "BmkgSatu.metadata.qc.ILysiMeterqc";
    } else if (type == "gunbellani") {
      return "BmkgSatu.metadata.qc.IGunbellaniqc";
    } else if (type == "lama_penyinaran") {
      return "";
    } else if (type == "aktinograph") {
      return "";
    } else if (type == "thermohigograph") {
      return "";
    } else if (type == "barograph") {
      return "";
    } else if (type == "perawanan") {
      return "";
    } else if (type == "kelembapan_tanah") {
      return "";
    } else if (type == "form_hujan") {
      return "BmkgSatu.metadata.qc.IFormHujanqc";
    } else if (type == "form_hujan_harian") {
      return "BmkgSatu.metadata.qc.IFormHujanHarianqc";
    } else if (type == "hujan_helman") {
      return "BmkgSatu.metadata.qc.IHujanHellmanqc";
    } else if (type == "fenologi") {
      return "";
    } else if (type == "table_qff_qfe") {
      return "BmkgSatu.metadata.qc.IPressure_Correction_QFE_QFF_qc";
    } else if (type == "pressure_qff_mb_derived") {
      return "BmkgSatu.metadata.qc.IPressure_QFF_Derived_qc";
    } else if (type == "pressure_qfe_mb_derived") {
      return "BmkgSatu.metadata.qc.IPressure_QFE_Derived_qc";
    } else if (type == "temp_drybulb_c_tttttt") {
      return "BmkgSatu.metadata.qc.ITemp_Drybulb_C_TTTTTT_qc";
    } else if (type == "temp_wetbulb_c") {
      return "BmkgSatu.metadata.qc.ITemp_Wetbulb_C_qc";
    } else if (type == "relative_humidity_pc") {
      return "BmkgSatu.metadata.qc.IRelative_Humidity_qc";
    } else if (type == "kimia_air_hujan") {
      return "BmkgSatu.metadata.qc.IKAH_qc";
    } else if (type == "suspended_particulate_matter") {
      return "BmkgSatu.metadata.qc.ISPM_qc";
    } else if (type == "so2no2") {
      return "BmkgSatu.metadata.qc.ISo2no2_qc";
    } else if (type == "aerosol") {
      return "BmkgSatu.metadata.qc.IAerosol_qc";
    }
  },

  ME48_PARAMS() {
    const listAttr = [
      {
        parameter: "visibility_vv",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Jarak penglihatan mendatar(km) - (visibility_vv)",
      },
      {
        parameter: "pressure_temp_c",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Derajat Panas (℃) - (pressure_temp_c) ",
      },
      {
        parameter: "pressure_qff_mb_derived",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Tekanan QFF  - (pressure_QFF_mb_derived)",
        disable: false,
        autorumus: true,
      },
      {
        parameter: "temp_drybulb_c_tttttt",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Suhu Bola Kering (℃) - (temp_drybulb_c_tttttt)",
        disable: false,
        autorumus: true,
      },
      {
        parameter: "temp_dewpoint_c_tdtdtd",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Suhu titik embun - (temp_dewpoint_c_tdtdtd)",
        disable: true,
      },
      {
        parameter: "cloud_layer_1_height_m_hshs",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Tinggi dasar awan 1 - (cloud_layer_1_height_m_hshs)",
        disable: true,
      },
      {
        parameter: "wind_dir_deg_dd",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Arah Angin - (wind_dir_deg_dd)",
        disable: false,
      },
      {
        parameter: "pressure_reading_mb",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Tekanan dibaca - (pressure_reading_mb)",
      },
      {
        parameter: "pressure_qfe_mb_derived",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Tekanan QFE (pressure_QFE_mb_derived)",
        disable: false,
        autorumus: true,
      },
      {
        parameter: "rainfall_last_mm",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Hujan terakhir - (rainfall_last_mm) ",
      },
      // {
      //   parameter: "cloud_med_height_m",
      //   min: "",
      //   max: "",
      //   special_value: "",
      //   mandatory: "N",
      //   label: "Jenis awan menengah - (cloud_med_height_m) ",
      //   disable: true,
      // },
      {
        parameter: "wind_speed_ff",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Kecepatan Angin -(wind_speed_ff)",
      },
      {
        parameter: "pressure_tend_3h_mb_ppp",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Selisih tekanan 3 jam  - (pressure_tend_3h_mb_ppp)",
        disable: true,
      },
      {
        parameter: "pressure_tend_24h_mb_ppp",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Selisih tekanan 24 jam  - (pressure_tend_24h_mb_ppp)",
        disable: true,
      },
      {
        parameter: "temp_wetbulb_c",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Suhu Bola Basah - (temp_wetbulb_c)",
        disable: false,
        autorumus: true,
      },
      {
        parameter: "relative_humidity_pc",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "RH - (relative_humidity_pc)",
        disable: false,
        autorumus: true,
      },
      {
        parameter: "temp_min_c_tntntn",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Suhu Minimun - (temp_min_c_tntntn)",
        disable: false,
        autorumus: true,
      },
      {
        parameter: "temp_max_c_txtxtx",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Suhu Maksimum - (temp_max_c_txtxtx)",
        disable: false,
        autorumus: true,
      },
      {
        parameter: "rainfall_6h_rrr",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Hujan 6 jam - (rainfall_6h_RRR)",
        disable: true,
      },
      {
        parameter: "cloud_low_base_1",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Tinggi dasar awan rendah - (cloud_low_base_1)",
        disable: false,
      },
      {
        parameter: "cloud_low_base_2",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Tinggi dasar awan rendah 2 - (cloud_low_base_2)",
        disable: false,
      },
      {
        parameter: "cloud_low_base_3",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Tinggi dasar awan rendah 3 - (cloud_low_base_3)",
        disable: false,
      },
      //
      {
        parameter: "cloud_med_base_1",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Tinggi dasar awan menengah - (cloud_med_base_1)",
        disable: false,
      },
      {
        parameter: "cloud_med_base_2",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Tinggi dasar awan menengah 2 - (cloud_med_base_2)",
        disable: false,
      },
      {
        parameter: "cloud_high_base_1",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Tinggi dasar awan tinggi - (cloud_high_base_1)",
        disable: false,
      },
      {
        parameter: "cloud_high_base_2",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Tinggi dasar awan tinggi 2 - (cloud_high_base_2)",
        disable: false,
      },

      {
        parameter: "cloud_layer_2_height_m_hshs",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Tinggi dasar awan 2 - (cloud_layer_2_height_m_hshs)",
        disable: true,
      },
      {
        parameter: "rainfall_24h_RRRR",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Hujan ditakar 24 jam - (rainfall_24h_RRRR)",
        disable: true,
      },
      {
        parameter: "cloud_low_peak_1",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Tinggi puncak awan1 - (cloud_low_peak_1)",
        disable: false,
      },
      {
        parameter: "cloud_low_peak_2",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Tinggi puncak awan2 - (cloud_low_peak_2)",
        disable: false,
      },
      // {
      //   parameter: "cloud_high_height_m",
      //   min: "",
      //   max: "",
      //   special_value: "",
      //   mandatory: "N",
      // },
      {
        parameter: "evaporation_24hours_mm_eee",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Penguapan 24 jam - (evaporation_24hours_mm_EEE)",
      },
      {
        parameter: "sunshine_h_sss",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Penyinaran matahari - (sunshine_h_SSS)",
      },
      {
        parameter: "solar_rad_24h_jcm2_f24",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Radiasi matahari 24 jam - (solar_rad_24h_jcm2_F24)",
      },
      {
        parameter: "cloud_layer_3_height_m_hshs",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Tinggi dasar awan 3 - (cloud_layer_3_height_m_hshs)",
        disable: true,
      },
      {
        parameter: "cloud_layer_4_height_m_hshs",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Tinggi dasar awan 4 - (cloud_layer_4_height_m_hshs)",
        disable: true,
      },
      {
        parameter: "wind_indicator_iw",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Pengenal data angin - (wind_indicator_iw)",
        disable: true,
      },
      {
        parameter: "weather_indicator_ix",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Pengenal data cuaca - (weather_indicator_ix)",
        disable: true,
      },
      {
        parameter: "present_weather_ww",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Keadaan cuaca sekarang - (present_weather_ww)",
        disable: true,
      },
      {
        parameter: "past_weather_w1",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Keadaan cuaca lalu 1 - (past_weather_w1)",
        disable: true,
      },
      {
        parameter: "past_weather_w2",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Keadaan cuaca lalu 2 - (past_weather_w2)",
        disable: true,
      },
      {
        parameter: "rainfall_indicator_ir",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Pengenal data hujan - (rainfall_indicator_ir)",
        disable: true,
      },
      {
        parameter: "cloud_low_type_CL",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Jenis awan rendah - (cloud_low_type_CL)",
        disable: true,
      },
      {
        parameter: "cloud_direction_angle_da",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        disable: true,
      },
      {
        parameter: "cloud_direction_2_angle_da",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        disable: true,
      },
      {
        parameter: "cloud_elevation_angle_ec",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Sudut Elevasi - (cloud_elevation_angle_ec)",
        disable: true,
      },
      {
        parameter: "cloud_elevation_2_angle_ec",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Sudut Elevasi - (cloud_elevation_2_angle_ec)",
        disable: true,
      },
      {
        parameter: "cloud_low_med_amt_oktas",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Tutupan awan  rendah(okta) - (cloud_low_med_amt_oktas)",
        disable: true,
      },
      {
        parameter: "cloud_med_cover_oktas",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Tutupan awan  menengah(okta) - (cloud_med_cover_oktas)",
        disable: true,
      },
      {
        parameter: "cloud_med_type_cm",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Jenis Awan Menengah (cloud_med_type_cm)",
        disable: true,
      },
      {
        parameter: "cloud_high_cover_oktas",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Tutupan awan tinggi (okta) - (cloud_high_cover_oktas)",
        disable: true,
      },
      {
        parameter: "cloud_high_type_ch",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Jenis  awan tinggi (cloud_high_type_ch)",
        disable: true,
      },
      {
        parameter: "cloud_med_dir_dm",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: " Arah - (cloud_med_dir_dm)",
        disable: true,
      },
      {
        parameter: "cloud_high_dir_dh",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: " Arah - (cloud_high_dir_dh)",
        disable: true,
      },
      {
        parameter: "cloud_cover_oktas_m",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Tutupan awan (okta)- (cloud_cover_oktas_m)",
        disable: true,
      },
      {
        parameter: "cloud_layer_1_type_c",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Jenis awan 1 - (cloud_layer_1_type_c)",
        disable: true,
      },
      {
        parameter: "cloud_layer_2_type_c",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Jenis awan 2 - (cloud_layer_2_type_c)",
        disable: true,
      },
      {
        parameter: "cloud_layer_3_type_c",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Jenis awan 3 - (cloud_layer_3_type_c)",
        disable: true,
      },
      {
        parameter: "cloud_layer_4_type_c",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Jenis awan 4 - (cloud_layer_4_type_c)",
        disable: true,
      },
      {
        parameter: "cloud_layer_1_amt_oktas_ns",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Tutupan awan 1 - (cloud_layer_1_amt_oktas_ns)",
        disable: true,
      },
      {
        parameter: "cloud_layer_2_amt_oktas_ns",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Tutupan awan 2 - (cloud_layer_2_amt_oktas_ns)",
        disable: true,
      },
      {
        parameter: "cloud_layer_3_amt_oktas_ns",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Tutupan awan 3 - (cloud_layer_3_amt_oktas_ns)",
        disable: true,
      },
      {
        parameter: "cloud_layer_4_amt_oktas_ns",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Tutupan awan 4 - (cloud_layer_4_amt_oktas_ns)",
        disable: true,
      },
      {
        parameter: "evaporation_eq_indicator_iE",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Pengenal Data Penguapan(IE) - (evaporation_eq_indicator_iE)",
        disable: true,
      },
      {
        parameter: "land_cond",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Keadaan Tanah - (land_cond)",
        disable: true,
      },
      {
        parameter: "land_note",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Catatan Tanah - (land_note)",
        disable: true,
      },
      {
        parameter: "observer_id",
        min: "",
        max: "",
        special_value: "",
        mandatory: "N",
        label: "Nama Observer - (observer_id)",
        disable: true,
      },
    ];

    return listAttr;
  },

  METAR_PARAMS() {
    const listAttr = [
      { parameter: "m_wind_speed_ff", min: "", max: "", special_value: "", label: "Kecepatan angin dalam knot - (m_wind_speed_ff)" },
      { parameter: "m_wind_gust_fmfm", min: "", max: "", special_value: "", label: "Kecepatan angin dalam knot - (m_wind_gust_fmfm)" },
      { parameter: "m_cloud_height_hshshs", min: "", max: "", special_value: "", label: "tinggi dasar awan - (m_cloud_height_hshshs)" },
      { parameter: "m_air_temperature_tt", min: "", max: "", special_value: "", label: "Suhu udara dalam (℃) - (m_air_temperature_tt)", disable: true, autorumus: true },
      { parameter: "m_qnh_pressure_phphph", min: "", max: "", special_value: "", label: "Tekanan udara pada runway - (m_qnh_pressure_phphph)", disable: true, autorumus: true },
    ];
    return listAttr;
  },

  PIBAL_PARAMS() {
    const listAttr = [];
    return listAttr;
  },

  FKLIM_PARAMS() {
    const listAttr = [
      { parameter: "tbk_1c2m_0700", min: "", max: "", special_value: "", label: "Suhu bola kering 1.2 m 07.00 - (tbk_1.2m_0700)", autorumus: true },
      { parameter: "tbk_1c2m_1300", min: "", max: "", special_value: "", label: "Suhu bola kering 1.2 m 13.00 - (tbk_1.2m_1300)", autorumus: true },
      { parameter: "tbk_1c2m_1800", min: "", max: "", special_value: "", label: "Suhu bola kering 1.2 m 18.00 - (tbk_1.2m_1800)", autorumus: true },
      { parameter: "tbk_avg", min: "", max: "", special_value: "", label: "Suhu bola kering rata-rata - (tbk_avg)", disable: true },
      { parameter: "t_max_1c2m", min: "", max: "", special_value: "", label: " Suhu maksimum ketingian 1.2 m 18.00 - (t_max_1.2m)" },
      { parameter: "t_min_1300", min: "", max: "", special_value: "", label: "Suhu minimum pada jam 13.00 - (t_min_1300)", autorumus: true },
      { parameter: "rr_0700", min: "", max: "", special_value: "", label: "Curah hujan jam 07.00- (rr_0700)" },

      { parameter: "rh_1c2_0700", min: "", max: "", special_value: "", label: "Lembab udara pengamatan jam 07.00 - (rh_1.2m_0700)", disable: true, autorumus: true },
      { parameter: "rh_1c2_1300", min: "", max: "", special_value: "", label: "Lembab udara pengamatan jam 13.00 - (rh_1.2m_1300)", disable: true, autorumus: true },
      { parameter: "rh_1c2_1800", min: "", max: "", special_value: "", label: "Lembab udara pengamatan jam 18.00 - (rh_1.2m_1800)", disable: true, autorumus: true },
      { parameter: "rh_avg", min: "", max: "", special_value: "", label: "Lembab udara rata-rata - (rh_avg)", disable: true },
      // { parameter: "rel_hum_24h_pc", min: "", max: "", special_value: "", label: "Lembab udara rata-rata 24 h - (rel_hum_24h_pc)" },
      { parameter: "ff_avg_km_jam", min: "", max: "", special_value: "", label: "Kecepatan rata-rata (km/jam), - (ff_avg_km_jam)", disable: true },

      // { parameter: "temp_avg_c", min: "", max: "", special_value: "", label: "Suhu bola kering rata-rata - (temp_avg_c)" },
      // { parameter: "temp_avg_24h_c", min: "", max: "", special_value: "", label: " - (temp_avg_24h_c)" },
      // { parameter: "temp_max_c", min: "", max: "", special_value: "", label: " Suhu maksimum- (temp_max_c)" },
      // { parameter: "temp_min_c", min: "", max: "", special_value: "", label: "Suhu minimum - (temp_min_c)" },
      // { parameter: "rainfall_mm", min: "", max: "", special_value: "", label: "Curah hujan - (rainfall_mm)" },
      // { parameter: "sunshine_%", min: "", max: "", special_value: "", label: "Lama penyinaran 8 jam - (sunshine_%)" },
      // { parameter: "pressure_QFF_mb", min: "", max: "", special_value: "", label: "Tekanan Udara - (pressure_QFF_mb)" },

      // { parameter: "wind_dir_max", min: "", max: "", special_value: "", label: "Arah angin terbanyak - (wind_dir_max)" },
      // { parameter: "wind_speed_max_kt", min: "", max: "", special_value: "", label: "Kecepatan angin maksimum - (wind_speed_max_kt)" },
      // { parameter: "wd_0700", min: "", max: "", special_value: "", label: "Arah Angin - (wd_0700)" },
    ];
    return listAttr;
  },

  AGM1A_PARAMS() {
    const listAttr = [
      { parameter: "tbb_1c2m_0700", min: "", max: "", special_value: "", label: "Suhu bola basah ketinggian 1.2m 0700 - (tbb_1.2m_0700)", disable: true, autorumus: true },
      { parameter: "tbk_1c2m_0700", min: "", max: "", special_value: "", label: "Suhu bola kering 1.2 m 07.00 - (tbk_1.2m_0700)", disable: true, autorumus: true },
      { parameter: "tbb_1c2m_1400", min: "", max: "", special_value: "", label: "Suhu bola basah ketinggian 1.2m 1400 - (tbb_1.2m_1400)", disable: true, autorumus: true },
      { parameter: "tbk_1c2m_1400", min: "", max: "", special_value: "", label: "Suhu bola kering 1.2 m 14.00 - (tbk_1.2m_1400)", disable: true, autorumus: true },
      { parameter: "tbb_1c2m_1800", min: "", max: "", special_value: "", label: "Suhu bola basah ketinggian 1.2m 1800 - (tbb_1.2m_1800)", disable: true, autorumus: true },
      { parameter: "tbk_1c2m_1800", min: "", max: "", special_value: "", label: "Suhu bola kering 1.2 m 18.00 - (tbk_1.2m_1800)", disable: true, autorumus: true },
      { parameter: "tn_rumput_0700", min: "", max: "", special_value: "", label: "Suhu minimum rumput 07.00 - (tn_rumput_0700)" },

      { parameter: "t_min_1c2m", min: "", max: "", special_value: "", label: "Suhu minimum (Tmin) jam 13.00 - (t_min_1.2m)" },
      { parameter: "t_max_1c2m", min: "", max: "", special_value: "", label: "Suhu maksimum di ketinggian 1.2m jam 18.00 - (t_max_1.2m)" },
      { parameter: "rh_1c2m_0700", min: "", max: "", special_value: "", label: "Rel hum ketinggian 1.2m jam 07.00 - (rh_1.2m_0700)", disable: true, autorumus: true },
      { parameter: "rh_1c2m_1400", min: "", max: "", special_value: "", label: "Rel hum ketinggian 1.2m jam 14.00 - (rh_1.2m_1400)", disable: true, autorumus: true },
      { parameter: "rh_1c2m_1800", min: "", max: "", special_value: "", label: "Rel hum ketinggian 1.2m jam 18.00 - (rh_1.2m_1800)", disable: true, autorumus: true },
      { parameter: "ws_avg_7_0700", min: "", max: "", special_value: "", label: "Kecepatan angin rata-rata,  T 7 m di jam 07.00 - (ws_avg_7_0700)", disable: true },
      { parameter: "ws_avg_7_1400", min: "", max: "", special_value: "", label: "Kecepatan angin rata-rata,  T 7 m di jam 14.00 - (ws_avg_7_1400)", disable: true },
      { parameter: "ws_avg_7_1800", min: "", max: "", special_value: "", label: "Kecepatan angin rata-rata,  T 7 m di jam 18.00 - (ws_avg_7_1800)", disable: true },
      { parameter: "wd_0700", min: "", max: "", special_value: "", label: "Arah angin jam 07.00 - (wd_0700)", disable: true },
      { parameter: "wd_1400", min: "", max: "", special_value: "", label: "Arah angin jam 14.00 - (wd_1400)", disable: true },
      { parameter: "wd_1800", min: "", max: "", special_value: "", label: "Arah angin jam 18.00 - (wd_1800)", disable: true },
      { parameter: "ws_0700", min: "", max: "", special_value: "", label: "Kecepatan angin jam 07.00 - (ws_0700)" },
      { parameter: "ws_1400", min: "", max: "", special_value: "", label: "Kecepatan angin jam 14.00 - (ws_1400)" },
      { parameter: "ws_1800", min: "", max: "", special_value: "", label: "Kecepatan angin jam 18.00 - (ws_1800)" },
      { parameter: "ss_12", min: "", max: "", special_value: "", label: "Lama Penyinaran jam 12 - (ss_12)", disable: true },
      { parameter: "rr_0700", min: "", max: "", special_value: "", label: "Curah hujan pada jam 07.00 - (rr_0700)" },
      // { parameter: "co_cu_0700", min: "", max: "", special_value: "", label: "Keadaan cuaca jam 07.00 - (co_cu_0700)" },
      // { parameter: "co_cu_1400", min: "", max: "", special_value: "", label: "Keadaan cuaca jam 14.00 - (co_cu_1400)" },

      { parameter: "t_reset_uji_1400", min: "", max: "", special_value: "", label: "Suhu minimum (TReset) reset jam 14 .00 - (t_reset_uji_1400)" },
      { parameter: "t_min_uji_1400", min: "", max: "", special_value: "", label: "Suhu minimum (Tmin) jam 14 .00 - (t_min_uji_1400)" },
      { parameter: "t_reset_1c2m_1800", min: "", max: "", special_value: "", label: "Suhu minimum (Tmin) reset jam 18.00 - (t_reset_1.2m_1800)" },
      // { parameter: "tn_rumput_reset_0700", min: "", max: "", special_value: "", label: "Suhu minimum rumput TReset 07.00 - (tn_rumput_reset_0700)" },
      // { parameter: "ws_avg_7_1400", min: "", max: "", special_value: "", label: "Kecepatan angin rata-rata,  T 7 m di jam 14.00 - (ws_avg_7_1400)" },
    ];
    return listAttr;
  },

  AGM1B_PARAMS() {
    const listAttr = [
      { parameter: "ttg_5cm_0730", min: "", max: "", special_value: "", label: "Suhu tanah gundul 5 Cm di jam 07.30	- (ttg_5cm_0730)" },
      { parameter: "ttg_10cm_0730", min: "", max: "", special_value: "", label: "Suhu tanah gundul 10 Cm di jam 07.30	- (ttg_10cm_0730)" },
      { parameter: "ttg_20cm_0730", min: "", max: "", special_value: "", label: "Suhu tanah gundul 20 Cm di jam 07.30	- (ttg_20cm_0730)" },

      { parameter: "ttg_5cm_1330", min: "", max: "", special_value: "", label: "Suhu tanah gundul 5 Cm di jam 13.30	- (ttg_5cm_1330)" },
      { parameter: "ttg_10cm_1330", min: "", max: "", special_value: "", label: "Suhu tanah gundul 10 Cm di jam 13.30	- (ttg_10cm_1330)" },
      { parameter: "ttg_20cm_1330", min: "", max: "", special_value: "", label: "Suhu tanah gundul 20 Cm di jam 13.30	- (ttg_20cm_1330)" },

      { parameter: "ttg_5cm_1730", min: "", max: "", special_value: "", label: "Suhu tanah rumput 5 Cm di jam 17.30	- (ttr_5cm_1730)" },
      { parameter: "ttg_10cm_1730", min: "", max: "", special_value: "", label: "Suhu tanah gundul 10 Cm di jam 17.30	- (ttg_10cm_1730)" },
      { parameter: "ttg_20cm_1730", min: "", max: "", special_value: "", label: "Suhu tanah rumput 20 Cm di jam 17.30	- (ttr_20cm_1730)" },
      { parameter: "ttg_50cm_1730", min: "", max: "", special_value: "", label: "Suhu tanah rumput 50 Cm di jam 17.30	- (ttr_50cm_1730)" },
      { parameter: "ttg_100cm_1730", min: "", max: "", special_value: "", label: "Suhu tanah gundul 100 Cm di jam 17.30	- (ttg_100cm_1730)" },

      { parameter: "ttr_5cm_0730", min: "", max: "", special_value: "", label: "Suhu tanah rumput 5 Cm di jam 07.30	- (ttr_5cm_0730)" },
      { parameter: "ttr_10cm_0730", min: "", max: "", special_value: "", label: "Suhu tanah rumput  10 Cm di jam 07.30	- (ttr_10cm_0730)" },
      { parameter: "ttr_20cm_0730", min: "", max: "", special_value: "", label: "Suhu tanah rumput 20 Cm di jam 07.30	- (ttr_20cm_0730)" },

      { parameter: "ttr_5cm_1330", min: "", max: "", special_value: "", label: "Suhu tanah rumput 5 Cm di jam 13.30	- (ttr_5cm_1330)" },
      { parameter: "ttr_10cm_1330", min: "", max: "", special_value: "", label: "Suhu tanah rumput 10 Cm di jam 13.30	- (ttr_10cm_1330)" },
      { parameter: "ttr_20cm_1330", min: "", max: "", special_value: "", label: "Suhu tanah rumput 20 Cm di jam 13.30	- (ttr_20cm_1330)" },

      { parameter: "ttr_5cm_1730", min: "", max: "", special_value: "", label: "Suhu tanah rumput 5 Cm di jam 17.30	- (ttr_5cm_1730)" },
      { parameter: "ttr_10cm_1730", min: "", max: "", special_value: "", label: "Suhu tanah rumput 10 Cm di jam 17.30	- (ttr_10cm_1730)" },
      { parameter: "ttr_20cm_1730", min: "", max: "", special_value: "", label: "Suhu tanah gundul 20 Cm di jam 17.30	- (ttg_20cm_1730)" },
      { parameter: "ttr_50cm_1730", min: "", max: "", special_value: "", label: "Suhu tanah gundul 50 Cm di jam 17.30	- (ttg_50cm_1730)" },
      { parameter: "ttr_100cm_1730", min: "", max: "", special_value: "", label: "Suhu tanah rumput 100 Cm di jam 17.30	- (ttr_100cm_1730)" },

      { parameter: "rr_0730", min: "", max: "", special_value: "", label: "Curah hujan pada jam 07.30 - (rr_0730)" },
      { parameter: "ws_avg_1b_0c5_1", min: "", max: "", special_value: "", label: "Kecepatan angin rata-rata, selisih pembacaan Cup Counter antara 17.30 ws kemarin dengan jam 07.30 hari " }, //hasil bagi (sampai dua angka dibelakang koma dari selisih pembacaan Cup Counter yang tingginya 0.5 m antara jam 17.30 WS. Kemarin dengan jam 07.30 hari ini dibagi 14. Dinyatakan dalam Km/jam - (ws_avg_1b_0.5_1)" },
      { parameter: "ws_10_0730", min: "", max: "", special_value: "", label: "Kecepatan angin 10 meter pada jam 07.30 - (ws_10_0730)" },
      { parameter: "tbk_1c2m_0730", min: "", max: "", special_value: "", label: "Suhu udara bola kering pada jam 07.30  - (tbk_1.2m_0730)", disable: true, autorumus: true },
      { parameter: "rh_1c2m_0730", min: "", max: "", special_value: "", label: "Lembab udara  pada  jam 07.30  - (rh_1.2m_0730)", disable: true, autorumus: true },
      { parameter: "t_air_avg_0730", min: "", max: "", special_value: "", label: "Suhu rata2 Air  jam 07.30  - (t_air_avg_0730)", disable: true },
      { parameter: "op_eva_0730", min: "", max: "", special_value: "", label: "Penguapan antara jam 17.30 kemarin sampai jam 07.30 hari ini- (op_eva_0730)" },

      { parameter: "rr_1330", min: "", max: "", special_value: "", label: "Curah hujan pada jam 13.30 - (rr_1330)" },
      { parameter: "ws_avg_1b_0c5_2", min: "", max: "", special_value: "", label: "Kecepatan angin rata-rata, selisih pembacaan Cup Counter antara jam 07.30 WS dengan jam 13.30 hari ini" }, //hasil bagi (sampai dua angka dibelakang koma dariselisih pembacaan Cup Counter yang tingginya 0.5 m antara jam 07.30 WS dengan jam 13.30 hari ini dibagi 6. Dinyatakan dalam Km/jam. - (ws_avg_1b_0.5_2)" },
      { parameter: "ws_10_1330", min: "", max: "", special_value: "", label: "Kecepatan angin 10 meter pada jam 13.30 	- (ws_10_1330)" },
      { parameter: "tbk_1c2m_1330", min: "", max: "", special_value: "", label: "Suhu udara bola kering pada jam 13.30  - (tbk_1.2m_1330)", disable: true, autorumus: true },
      { parameter: "rh_1c2m_1330", min: "", max: "", special_value: "", label: "Lembab udara  pada  jam 13.30  - (rh_1.2m_1330)", disable: true, autorumus: true },
      { parameter: "t_air_avg_1330", min: "", max: "", special_value: "", label: "Suhu rata2 Air  jam 13.30  - (t_air_avg_1330)", disable: true },
      { parameter: "op_eva_1330", min: "", max: "", special_value: "", label: "Penguapan antara jam 07.30 sampai jam 13.30   - (op_eva_1330)" },

      { parameter: "rr_1730", min: "", max: "", special_value: "", label: "Curah hujan pada jam 17.30 - (rr_1730)" },
      { parameter: "ws_avg_1b_0c5_3", min: "", max: "", special_value: "", label: "Kecepatan angin rata-rata, selisih pembacaan Cup Counter antara jam 13.30 WS dengan jam 17.30 hari ini" }, // hasil bagi (sampai dua angka dibelakang koma dari selisih pembacaan Cup Counter yang tingginya 0.5 m antara jam 13.30 WS dengan jam 17.30 hari ini dibagi 4. Dinyatakan dalam Km/jam. - (ws_avg_1b_0.5_3)" },
      { parameter: "ws_10_1730", min: "", max: "", special_value: "", label: "Kecepatan angin 10 meter pada jam 17.30 	- (ws_10_1730)" },
      { parameter: "tbk_1c2m_1730", min: "", max: "", special_value: "", label: "Suhu udara bola kering pada jam 17.30  - (tbk_1.2m_1730)", disable: true, autorumus: true },
      { parameter: "rh_1c2m_1730", min: "", max: "", special_value: "", label: "Lembab udara  pada  jam 17.30  - (rh_1.2m_1730)", disable: true, autorumus: true },
      { parameter: "t_air_avg_1730", min: "", max: "", special_value: "", label: "Suhu rata2 Air  jam 17.30  - (t_air_avg_1730)", disable: true },
      { parameter: "op_eva_1730", min: "", max: "", special_value: "", label: "Penguapan antara jam 13.30 sampai jam 17.30   - (op_eva_1730)" },
      // Tair max-min
      { parameter: "t_air_max_0700", min: "", max: "", special_value: "", label: "Suhu Air Maksimum jam 07.00 WS   - (t_air_max_0700)" },
      { parameter: "t_air_min_0700", min: "", max: "", special_value: "", label: "Suhu Air Minimum jam 07.00 WS   - (t_air_min_0700)" },
    ];
    return listAttr;
  },

  IKLIM_MIKRO_PARAMS() {
    const listAttr = [
      { parameter: "tbk_4m_0700", min: "", max: "", special_value: "", label: "Suhu bola kering 4 m jam 07.00 - (tbk_4m_0700)" },
      { parameter: "tbk_4m_1300", min: "", max: "", special_value: "", label: "Suhu bola kering 4 m jam 13.00 - (tbk_4m_1300)" },
      { parameter: "tbk_4m_1800", min: "", max: "", special_value: "", label: "Suhu bola kering 4 m jam 18.00 - (tbk_4m_1800)" },
      { parameter: "tbk_avg_4", min: "", max: "", special_value: "", label: "Suhu bola kering rata-rata 4 m - (tbk_avg_4)", disable: true },
      { parameter: "t_max_4m", min: "", max: "", special_value: "", label: "Suhu maksimum ketinggian 4m jam 18.00 - (t_max_4m)" },
      { parameter: "t_min_4m", min: "", max: "", special_value: "", label: "Suhu minimum ketinggian 4 m jam 13.00 - (t_min_4m)" },
      { parameter: "rh_4m_0700", min: "", max: "", special_value: "", label: "Rel hum ketinggian 4 m jam 07.00 - (rh_4m_0700)", disable: true },
      { parameter: "rh_4m_1300", min: "", max: "", special_value: "", label: "Rel hum ketinggian 4 m jam 13.00 - (rh_4m_1300)", disable: true },
      { parameter: "rh_4m_1800", min: "", max: "", special_value: "", label: "Rel hum ketinggian 4 m jam 18.00 - (rh_4m_1800)", disable: true },
      { parameter: "rh_avg_4", min: "", max: "", special_value: "", label: "Rel hum rata ketinggian 4 m - (rh_avg_4)", disable: true },

      { parameter: "tbk_7m_0700", min: "", max: "", special_value: "", label: "Suhu bola kering 7 m jam 07.00 - (tbk_7m_0700)" },
      { parameter: "tbk_7m_1300", min: "", max: "", special_value: "", label: "Suhu bola kering 7 m jam 13.00 - (tbk_7m_1300)" },
      { parameter: "tbk_7m_1800", min: "", max: "", special_value: "", label: "Suhu bola kering 7 m jam 18.00 - (tbk_7m_1800)" },
      { parameter: "tbk_avg_7", min: "", max: "", special_value: "", label: "Suhu bola kering rata-rata 7 m - (tbk_avg_7)", disable: true },
      { parameter: "t_max_7m", min: "", max: "", special_value: "", label: "Suhu maksimum ketinggian 7 m  jam 18.00 - (t_max_7m)" },
      { parameter: "t_min_7m", min: "", max: "", special_value: "", label: "Suhu minimum ketinggian 7 m jam 13.00 - (t_min_7m)" },
      { parameter: "rh_7m_0700", min: "", max: "", special_value: "", label: "Rel hum ketinggian 7 m jam 07.00 - (rh_7m_0700)", disable: true },
      { parameter: "rh_7m_1300", min: "", max: "", special_value: "", label: "Rel hum ketinggian 7 m jam 13.00 - (rh_7m_1300)", disable: true },
      { parameter: "rh_7m_1800", min: "", max: "", special_value: "", label: "Rel hum ketinggian 7 m jam 18.00 - (rh_7m_1800)", disable: true },
      { parameter: "rh_avg_7", min: "", max: "", special_value: "", label: "Rel hum rata ketinggian 7 m - (rh_avg_7)", disable: true },

      { parameter: "tbk_10m_0700", min: "", max: "", special_value: "", label: "Suhu bola kering 10 m jam 07.00 - (tbk_10m_0700)" },
      { parameter: "tbk_10m_1300", min: "", max: "", special_value: "", label: "Suhu bola kering 10 m jam 13.00 - (tbk_10m_1300)" },
      { parameter: "tbk_10m_1800", min: "", max: "", special_value: "", label: "Suhu bola kering 10 m jam 18.00 - (tbk_10m_1800)" },
      { parameter: "tbk_avg_10", min: "", max: "", special_value: "", label: "Suhu bola kering rata-rata 10 m - (tbk_avg_10)", disable: true },
      { parameter: "t_max_10m", min: "", max: "", special_value: "", label: "Suhu maksimum ketinggian 10 m  jam 18.00 - (t_max_10m)" },
      { parameter: "t_min_10m", min: "", max: "", special_value: "", label: "Suhu minimum ketinggian 10 m jam 13.00 - (t_min_10m)"},
      { parameter: "rh_10m_0700", min: "", max: "", special_value: "", label: "Rel hum ketinggian 10 m jam 07.00 - (rh_10m_0700)", disable: true },
      { parameter: "rh_10m_1300", min: "", max: "", special_value: "", label: "Rel hum ketinggian 10 m jam 13.00 - (rh_10m_1300)", disable: true },
      { parameter: "rh_10m_1800", min: "", max: "", special_value: "", label: "Rel hum ketinggian 10 m jam 18.00 - (rh_10m_1800)", disable: true },
      { parameter: "rh_avg_10", min: "", max: "", special_value: "", label: "Rel hum rata ketinggian 10 m - (rh_avg_10)", disable: true },

      { parameter: "ws_avg_4_0713", min: "", max: "", special_value: "", label: "Kec angin rata selisih antara 07 sd 13 ketinggian 4m  - (ws_avg_4_0713)", disable: true },
      { parameter: "ws_avg_7_0713", min: "", max: "", special_value: "", label: "Kec angin rata selisih antara 07 sd 13 ketinggian 7m  - (ws_avg_7_0713)", disable: true },
      { parameter: "ws_avg_10_0713", min: "", max: "", special_value: "", label: "Kec angin rata selisih antara 07 sd 13 ketinggian 10m  - (ws_avg_10_0713)", disable: true },
      { parameter: "ws_avg_4_1318", min: "", max: "", special_value: "", label: "Kec angin rata selisih antara 13 sd 18 ketinggian 4m  - (ws_avg_4_1318)", disable: true },
      { parameter: "ws_avg_7_1318", min: "", max: "", special_value: "", label: "Kec angin rata selisih antara 13 sd 18 ketinggian 7m - (ws_avg_7_1318)", disable: true },
      { parameter: "ws_avg_10_1318", min: "", max: "", special_value: "", label: "Kec angin rata selisih antara 13 sd 18 ketinggian 10m  - (ws_avg_10_1318)", disable: true },
      { parameter: "ws_avg_4_1807", min: "", max: "", special_value: "", label: "Kec angin rata selisih antara 18 sd 07 ketinggian 4m  - (ws_avg_4_1807)", disable: true },
      { parameter: "ws_avg_7_1807", min: "", max: "", special_value: "", label: "Kec angin rata selisih antara 18 sd 07 ketinggian 7m  - (ws_avg_7_1807)", disable: true },
      { parameter: "ws_avg_10_1807", min: "", max: "", special_value: "", label: "Kec angin rata selisih antara 18 sd 07 ketinggian 10m  - (ws_avg_10_1807)", disable: true },
    ];
    return listAttr;
  },

  SUHU_TANAH_PARAMS() {
    const listAttr = [
      { parameter: "ttr_0cm_0730", min: "", max: "", special_value: "", label: "suhu tanah rumput 0 Cm di jam 07.30 - (ttr_0cm_0730)" },
      { parameter: "ttr_2cm_0730", min: "", max: "", special_value: "", label: "Suhu tanah rumput 2 Cm di jam 07.30	- (ttr_2cm_0730)" },
      { parameter: "ttr_5cm_0730", min: "", max: "", special_value: "", label: "Suhu tanah rumput 5 Cm di jam 07.30	- (ttr_5cm_0730)" },
      { parameter: "ttr_10cm_0730", min: "", max: "", special_value: "", label: "Suhu tanah rumput  10 Cm di jam 07.30	- (ttr_10cm_0730)" },
      { parameter: "ttr_20cm_0730", min: "", max: "", special_value: "", label: "Suhu tanah rumput 20 Cm di jam 07.30	- (ttr_20cm_0730)" },
      { parameter: "ttr_50cm_0730", min: "", max: "", special_value: "", label: "Suhu tanah rumput 50 Cm di jam 07.30	- (ttr_50cm_0730)" },
      { parameter: "ttr_100cm_0730", min: "", max: "", special_value: "", label: "Suhu tanah rumput 100 Cm di jam 07.30	- (ttr_100cm_0730)" },
      { parameter: "ttg_0cm_0730", min: "", max: "", special_value: "", label: "Suhu tanah gundul 0 Cm di jam 07.30	- (ttg_0cm_0730)" },
      { parameter: "ttg_2cm_0730", min: "", max: "", special_value: "", label: "Suhu tanah gundul 2 Cm di jam 07.30	- (ttg_2cm_0730)" },
      { parameter: "ttg_5cm_0730", min: "", max: "", special_value: "", label: "Suhu tanah gundul 5 Cm di jam 07.30	- (ttg_5cm_0730)" },
      { parameter: "ttg_10cm_0730", min: "", max: "", special_value: "", label: "Suhu tanah gundul 10 Cm di jam 07.30	- (ttg_10cm_0730)" },
      { parameter: "ttg_20cm_0730", min: "", max: "", special_value: "", label: "Suhu tanah gundul 20 Cm di jam 07.30	- (ttg_20cm_0730)" },
      { parameter: "ttg_50cm_0730", min: "", max: "", special_value: "", label: "Suhu tanah gundul 50 Cm di jam 07.30	- (ttg_50cm_0730)" },
      { parameter: "ttg_100cm_0730", min: "", max: "", special_value: "", label: "Suhu tanah gundul 100 Cm di jam 07.30	- (ttg_100cm_0730)" },
      { parameter: "ttr_0cm_1330", min: "", max: "", special_value: "", label: "Suhu tanah rumput 0 Cm di jam 13.30	- (ttr_0cm_1330)" },
      { parameter: "ttr_2cm_1330", min: "", max: "", special_value: "", label: "Suhu tanah rumput 2 Cm di jam 13.30	- (ttr_2cm_1330)" },
      { parameter: "ttr_5cm_1330", min: "", max: "", special_value: "", label: "Suhu tanah rumput 5 Cm di jam 13.30	- (ttr_5cm_1330)" },
      { parameter: "ttr_10cm_1330", min: "", max: "", special_value: "", label: "Suhu tanah rumput 10 Cm di jam 13.30	- (ttr_10cm_1330)" },
      { parameter: "ttr_20cm_1330", min: "", max: "", special_value: "", label: "Suhu tanah rumput 20 Cm di jam 13.30	- (ttr_20cm_1330)" },
      { parameter: "ttr_50cm_1330", min: "", max: "", special_value: "", label: "Suhu tanah rumput 50 Cm di jam 13.30	- (ttr_50cm_1330)" },
      { parameter: "ttr_100cm_1330", min: "", max: "", special_value: "", label: "Suhu tanah rumput 100 Cm di jam 13.30	- (ttr_100cm_1330)" },
      { parameter: "ttg_0cm_1330", min: "", max: "", special_value: "", label: "Suhu tanah gundul 0 Cm di jam 13.30	- (ttg_0cm_1330)" },
      { parameter: "ttg_2cm_1330", min: "", max: "", special_value: "", label: "Suhu tanah gundul 2 Cm di jam 13.30	- (ttg_2cm_1330)" },
      { parameter: "ttg_5cm_1330", min: "", max: "", special_value: "", label: "Suhu tanah gundul 5 Cm di jam 13.30	- (ttg_5cm_1330)" },
      { parameter: "ttg_10cm_1330", min: "", max: "", special_value: "", label: "Suhu tanah gundul 10 Cm di jam 13.30	- (ttg_10cm_1330)" },
      { parameter: "ttg_20cm_1330", min: "", max: "", special_value: "", label: "Suhu tanah gundul 20 Cm di jam 13.30	- (ttg_20cm_1330)" },
      { parameter: "ttg_50cm_1330", min: "", max: "", special_value: "", label: "Suhu tanah gundul 50 Cm di jam 13.30	- (ttg_50cm_1330)" },
      { parameter: "ttg_100cm_1330", min: "", max: "", special_value: "", label: "Suhu tanah gundul 100 Cm di jam 13.30	- (ttg_100cm_1330)" },
      { parameter: "ttr_0cm_1730", min: "", max: "", special_value: "", label: "Suhu tanah rumput 0 Cm di jam 17.30	- (ttr_0cm_1730)" },
      { parameter: "ttr_2cm_1730", min: "", max: "", special_value: "", label: "Suhu tanah rumput 2 Cm di jam 17.30	- (ttr_2cm_1730)" },
      { parameter: "ttr_5cm_1730", min: "", max: "", special_value: "", label: "Suhu tanah rumput 5 Cm di jam 17.30	- (ttr_5cm_1730)" },
      { parameter: "ttr_10cm_1730", min: "", max: "", special_value: "", label: "Suhu tanah rumput 10 Cm di jam 17.30	- (ttr_10cm_1730)" },
      { parameter: "ttr_20cm_1730", min: "", max: "", special_value: "", label: "Suhu tanah rumput 20 Cm di jam 17.30	- (ttr_20cm_1730)" },
      { parameter: "ttr_50cm_1730", min: "", max: "", special_value: "", label: "Suhu tanah rumput 50 Cm di jam 17.30	- (ttr_50cm_1730)" },
      { parameter: "ttr_100cm_1730", min: "", max: "", special_value: "", label: "Suhu tanah rumput 100 Cm di jam 17.30	- (ttr_100cm_1730)" },
      { parameter: "ttg_0cm_1730", min: "", max: "", special_value: "", label: "Suhu tanah gundul 0 Cm di jam 17.30	- (ttg_0cm_1730)" },
      { parameter: "ttg_2cm_1730", min: "", max: "", special_value: "", label: "Suhu tanah gundul 2 Cm di jam 17.30	- (ttg_2cm_1730)" },
      { parameter: "ttg_5cm_1730", min: "", max: "", special_value: "", label: "Suhu tanah gundul 5 Cm di jam 17.30	- (ttg_5cm_1730)" },
      { parameter: "ttg_10cm_1730", min: "", max: "", special_value: "", label: "Suhu tanah gundul 10 Cm di jam 17.30	- (ttg_10cm_1730)" },
      { parameter: "ttg_20cm_1730", min: "", max: "", special_value: "", label: "Suhu tanah gundul 20 Cm di jam 17.30	- (ttg_20cm_1730)" },
      { parameter: "ttg_50cm_1730", min: "", max: "", special_value: "", label: "Suhu tanah gundul 50 Cm di jam 17.30	- (ttg_50cm_1730)" },
      { parameter: "ttg_100cm_1730", min: "", max: "", special_value: "", label: "Suhu tanah gundul 100 Cm di jam 17.30	- (ftg_100cm_1730)" },
    ];
    return listAttr;
  },

  PSYCHROMETER_ASSMAN_PARAMS() {
    const listAttr = [
      { parameter: "tbk_5cm_0730", min: "", max: "", special_value: "", label: "Suhu bola kering 5 cm di jam 07.30 - (tbk_5cm_0730)" },
      { parameter: "tbb_5cm_0730", min: "", max: "", special_value: "", label: "Suhu bola basah 5 cm di jam 07.30 - (tbb_5cm_0730)" },
      { parameter: "rh_5cm_0730", min: "", max: "", special_value: "", label: "Kelembaban 5 cm di jam 07.30 - (rh_5cm_0730)", disable: true },

      { parameter: "tbk_10cm_0730", min: "", max: "", special_value: "", label: "Suhu bola kering 10 cm di jam 07.30 - (tbk_10cm_0730)" },
      { parameter: "tbb_10cm_0730", min: "", max: "", special_value: "", label: "Suhu bola basah 10 cm di jam 07.30 - (tbb_10cm_0730)" },
      { parameter: "rh_10cm_0730", min: "", max: "", special_value: "", label: "Kelembaban 5 cm di jam 17.30 - (rh_5cm_1730)", disable: true },

      { parameter: "tbk_20cm_0730", min: "", max: "", special_value: "", label: "Suhu bola kering 20 cm di jam 07.30 - (tbk_20cm_0730)" },
      { parameter: "tbb_20cm_0730", min: "", max: "", special_value: "", label: "Suhu bola basah 20 cm di jam 07.30 - (tbb_20cm_0730)" },
      { parameter: "rh_20cm_0730", min: "", max: "", special_value: "", label: "Kelembaban 20 cm di jam 07.30 - (rh_20cm_0730)", disable: true },

      { parameter: "tbk_50cm_0730", min: "", max: "", special_value: "", label: "Suhu bola kering 50 cm di jam 07.30 - (tbk_50cm_0730)" },
      { parameter: "tbb_50cm_0730", min: "", max: "", special_value: "", label: "Suhu bola basah 50 cm di jam 07.30 - (tbb_50cm_0730)" },
      { parameter: "rh_50cm_0730", min: "", max: "", special_value: "", label: "Kelembaban 50 cm di jam 07.30 - (rh_50cm_0730)", disable: true },

      { parameter: "tbk_100cm_0730", min: "", max: "", special_value: "", label: "Suhu bola kering 100 cm di jam 07.30 - (tbk_100cm_0730)" },
      { parameter: "tbb_100cm_0730", min: "", max: "", special_value: "", label: "Suhu bola basah 100 cm di jam 07.30 - (tbb_100cm_0730)" },
      { parameter: "rh_100cm_0730", min: "", max: "", special_value: "", label: "Kelembaban 100 cm di jam 07.30 - (rh_100cm_0730)", disable: true },

      { parameter: "tbk_150cm_0730", min: "", max: "", special_value: "", label: "Suhu bola kering 150 cm di jam 07.30 - (tbk_150cm_0730)" },
      { parameter: "tbb_150cm_0730", min: "", max: "", special_value: "", label: "Suhu bola basah 150 cm di jam 07.30 - (tbb_150cm_0730)" },
      { parameter: "rh_150cm_0730", min: "", max: "", special_value: "", label: "Kelembaban 150 cm di jam 07.30 - (rh_150cm_0730)", disable: true },

      { parameter: "tbk_200cm_0730", min: "", max: "", special_value: "", label: "Suhu bola kering 200 cm di jam 07.30 - (tbk_200cm_0730)" },
      { parameter: "tbb_200cm_0730", min: "", max: "", special_value: "", label: "Suhu bola basah 200 cm di jam 07.30 - (tbb_200cm_0730)" },
      { parameter: "rh_200cm_0730", min: "", max: "", special_value: "", label: "Kelembaban 200 cm di jam 07.30 - (rh_200cm_0730)", disable: true },

      { parameter: "tbk_5cm_1330", min: "", max: "", special_value: "", label: "Suhu bola kering 5 cm di jam 13.30 - (tbk_5cm_1330)" },
      { parameter: "tbb_5cm_1330", min: "", max: "", special_value: "", label: "Suhu bola basah 5 cm di jam 13.30 - (tbb_5cm_1330)" },
      { parameter: "rh_5cm_1330", min: "", max: "", special_value: "", label: "Kelembaban 5 cm di jam 13.30 - (rh_5cm_1330)", disable: true },

      { parameter: "tbk_10cm_1330", min: "", max: "", special_value: "", label: "Suhu bola kering 10 cm di jam 03.30 - (tbk_10cm_1330)" },
      { parameter: "tbb_10cm_1330", min: "", max: "", special_value: "", label: "Suhu bola basah 10 cm di jam 03.30 - (tbb_10cm_1330)" },
      { parameter: "rh_10cm_1330", min: "", max: "", special_value: "", label: "Kelembaban 10 cm di jam 03.30 - (rh_10cm_1330)", disable: true },

      { parameter: "tbk_20cm_1330", min: "", max: "", special_value: "", label: "Suhu bola kering 20 cm di jam 13.30 - (tbk_20cm_1330)" },
      { parameter: "tbb_20cm_1330", min: "", max: "", special_value: "", label: "Suhu bola basah 20 cm di jam 13.30 - (tbb_20cm_1330)" },
      { parameter: "rh_20cm_1330", min: "", max: "", special_value: "", label: "Kelembaban 20 cm di jam 13.30 - (rh_20cm_1330)", disable: true },

      { parameter: "tbk_50cm_1330", min: "", max: "", special_value: "", label: "Suhu bola kering 50 cm di jam 13.30 - (tbk_50cm_1330)" },
      { parameter: "tbb_50cm_1330", min: "", max: "", special_value: "", label: "Suhu bola basah 50 cm di jam 13.30 - (tbb_50cm_1330)" },
      { parameter: "rh_50cm_1330", min: "", max: "", special_value: "", label: "Kelembaban 50 cm di jam 13.30 - (rh_50cm_1330)", disable: true },

      { parameter: "tbk_100cm_1330", min: "", max: "", special_value: "", label: "Suhu bola kering 100 cm di jam 13.30 - (tbk_100cm_1330)" },
      { parameter: "tbb_100cm_1330", min: "", max: "", special_value: "", label: "Suhu bola basah 100 cm di jam 13.30 - (tbb_100cm_1330)" },
      { parameter: "rh_100cm_1330", min: "", max: "", special_value: "", label: "Kelembaban 100 cm di jam 13.30 - (rh_100cm_1330)", disable: true },

      { parameter: "tbk_150cm_1330", min: "", max: "", special_value: "", label: "Suhu bola kering 150 cm di jam 13.30 - (tbk_150cm_1330)" },
      { parameter: "tbb_150cm_1330", min: "", max: "", special_value: "", label: "Suhu bola basah 150 cm di jam 13.30 - (tbb_150cm_1330)" },
      { parameter: "rh_150cm_1330", min: "", max: "", special_value: "", label: "Kelembaban 150 cm di jam 13.30 - (rh_150cm_1330)", disable: true },

      { parameter: "tbk_200cm_1330", min: "", max: "", special_value: "", label: "Suhu bola kering 200 cm di jam 13.30 - (tbk_200cm_1330)" },
      { parameter: "tbb_200cm_1330", min: "", max: "", special_value: "", label: "Suhu bola basah 200 cm di jam 13.30 - (tbb_200cm_1330)" },
      { parameter: "rh_200cm_1330", min: "", max: "", special_value: "", label: "Kelembaban 200 cm di jam 13.30 - (rh_200cm_1330)", disable: true },

      { parameter: "tbk_5cm_1730", min: "", max: "", special_value: "", label: "Suhu bola kering 5 cm di jam 17.30 - (tbk_5cm_1730)" },
      { parameter: "tbb_5cm_1730", min: "", max: "", special_value: "", label: "Suhu bola basah 5 cm di jam 17.30 - (tbb_5cm_1730)" },
      { parameter: "rh_5cm_1730", min: "", max: "", special_value: "", label: "Kelembaban 5 cm di jam 17.30 - (rh_5cm_1730)", disable: true },

      { parameter: "tbk_10cm_1730", min: "", max: "", special_value: "", label: "Suhu bola kering 10 cm di jam 17.30 - (tbk_10cm_1730)" },
      { parameter: "tbb_10cm_1730", min: "", max: "", special_value: "", label: "Suhu bola basah 10 cm di jam 17.30 - (tbb_10cm_1730)" },
      { parameter: "rh_10cm_1730", min: "", max: "", special_value: "", label: "Kelembaban 10 cm di jam 17.30 - (rh_10cm_1730)", disable: true },

      { parameter: "tbk_20cm_1730", min: "", max: "", special_value: "", label: "Suhu bola kering 20 cm di jam 17.30 - (tbk_20cm_1730)" },
      { parameter: "tbb_20cm_1730", min: "", max: "", special_value: "", label: "Suhu bola basah 20 cm di jam 17.30 - (tbb_20cm_1730)" },
      { parameter: "rh_20cm_1730", min: "", max: "", special_value: "", label: "Kelembaban 20 cm di jam 17.30 - (rh_20cm_1730)", disable: true },

      { parameter: "tbk_50cm_1730", min: "", max: "", special_value: "", label: "Suhu bola kering 50 cm di jam 17.30 - (tbk_50cm_1730)" },
      { parameter: "tbb_50cm_1730", min: "", max: "", special_value: "", label: "Suhu bola basah 50 cm di jam 17.30 - (tbb_50cm_1730)" },
      { parameter: "rh_50cm_1730", min: "", max: "", special_value: "", label: "Kelembaban 50 cm di jam 17.30 - (rh_50cm_1730)", disable: true },

      { parameter: "tbk_100cm_1730", min: "", max: "", special_value: "", label: "Suhu bola kering 100 cm di jam 17.30 - (tbk_100cm_1730)" },
      { parameter: "tbb_100cm_1730", min: "", max: "", special_value: "", label: "Suhu bola basah 100 cm di jam 17.30 - (tbb_100cm_1730)" },
      { parameter: "rh_100cm_1730", min: "", max: "", special_value: "", label: "Kelembaban 100 cm di jam 17.30 - (rh_100cm_1730)", disable: true },

      { parameter: "tbk_150cm_1730", min: "", max: "", special_value: "", label: "Suhu bola kering 150 cm di jam 17.30 - (tbk_150cm_1730)" },
      { parameter: "tbb_150cm_1730", min: "", max: "", special_value: "", label: "Suhu bola basah 150 cm di jam 17.30 - (tbb_150cm_1730)" },
      { parameter: "rh_150cm_1730", min: "", max: "", special_value: "", label: "Kelembaban 150 cm di jam 17.30 - (rh_150cm_1730)", disable: true },

      { parameter: "tbk_200cm_1730", min: "", max: "", special_value: "", label: "Suhu bola kering 200 cm di jam 17.30 - (tbk_200cm_1730)" },
      { parameter: "tbb_200cm_1730", min: "", max: "", special_value: "", label: "Suhu bola basah 200 cm di jam 17.30 - (tbb_200cm_1730)" },
      { parameter: "rh_200cm_1730", min: "", max: "", special_value: "", label: "Kelembaban 200 cm di jam 17.30 - (rh_200cm_1730)", disable: true },
    ];
    return listAttr;
  },

  OP_PENGUAPAN_PARAMS() {
    const listAttr = [
      // { parameter: "counter_0c5_0700", min: "", max: "", special_value: "", label: "Cup counter dibaca 0,5 M - (counter_0c5_0700)" },
      { parameter: "op_diff_baca_0700", min: "", max: "", special_value: "", label: "Beda pembacaan tinggi air - (op_diff_baca_0700)" },
      { parameter: "rr_0700", min: "", max: "", special_value: "", label: "Curah hujan pada jam 07.00 - (rr_0700)" },
      { parameter: "op_eva_0700", min: "", max: "", special_value: "", label: "Penguapan antara jam 07.00 kemarin sampai jam 07.00 hari ini - (op_eva_0700)" },
      { parameter: "h_air_0700", min: "", max: "", special_value: "", label: "Tinggi air dibaca Jam 07.00 - (h_air_0700)" },
      { parameter: "ws_avg_0c5_0700", min: "", max: "", special_value: "", label: "Kecepatan Angin Rata-rata 0,5 m - (ws_avg_0.5_0700)", disable: true },
      { parameter: "t_air_max_0700", min: "", max: "", special_value: "", label: "Suhu air maximum - (t_air_max_0700)" },
      { parameter: "t_air_min_0700", min: "", max: "", special_value: "", label: "Suhu air minimum - (t_air_min_0700)" },
      { parameter: "t_air_avg_0700", min: "", max: "", special_value: "", label: "Suhu rata2 Air jam 07.00  - (t_air_avg_0700)", disable: true },

      // { parameter: "op_diff_baca_0730", min: "", max: "", special_value: "", label: "Beda pembacaan tinggi air - (op_diff_baca_0730)" },
      // { parameter: "h_air_0730", min: "", max: "", special_value: "", label: "Tinggi air dibaca Jam 07.30 - (h_air_0730)" },
      // { parameter: "h_air_diff_0730", min: "", max: "", special_value: "", label: "Selisih tinggi air jam 07.30 - (h_air_diff_0730)" },
      // { parameter: "t_air_max_0730", min: "", max: "", special_value: "", label: "Tinggi air maksimal rata-rata jam 07.30 - (t_air_max_0730)" },
      // { parameter: "t_air_min_0730", min: "", max: "", special_value: "", label: "Tinggi air minimal rata-rata jam 07.30 - (t_air_min_0730)" },
      // { parameter: "t_air_avg_0730", min: "", max: "", special_value: "", label: "Suhu rata2 Air jam 07.30 - (t_air_avg_0730)" },
      // { parameter: "rr_0730", min: "", max: "", special_value: "", label: "Curah hujan pada jam 07.30 - (rr_0730)" },
      // { parameter: "op_eva_0730", min: "", max: "", special_value: "", label: "Penguapan antara jam 07.00  sampai jam 07.30 - (op_eva_0730)" },

      // { parameter: "op_diff_baca_1330", min: "", max: "", special_value: "", label: "Beda pembacaan tinggi air - (op_diff_baca_1330)" },
      // { parameter: "h_air_1330", min: "", max: "", special_value: "", label: "Tinggi air dibaca Jam 13.30 - (h_air_1330)" },
      // { parameter: "h_air_diff_1330", min: "", max: "", special_value: "", label: "Selisih tinggi air jam 1330 - (h_air_diff_1330)" },
      // { parameter: "t_air_max_1330", min: "", max: "", special_value: "", label: "Tinggi air maksimal rata-rata jam 1330 - (t_air_max_1330)" },
      // { parameter: "t_air_min_1330", min: "", max: "", special_value: "", label: "Tinggi air minimal rata-rata jam 1330 - (t_air_min_1330)" },
      // { parameter: "t_air_avg_1330", min: "", max: "", special_value: "", label: "Suhu rata2 Air  jam 13.30 - (t_air_avg_1330)" },
      // { parameter: "rr_1330", min: "", max: "", special_value: "", label: "Curah hujan pada jam 13.30 - (rr_1330)" },
      // { parameter: "op_eva_1330", min: "", max: "", special_value: "", label: "Penguapan antara jam 07.30  sampai jam 13.30 - (op_eva_1330)" },

      // { parameter: "op_diff_baca_1730", min: "", max: "", special_value: "", label: "Beda pembacaan tinggi air - (op_diff_baca_1730)" },
      // { parameter: "h_air_1730", min: "", max: "", special_value: "", label: "Tinggi air dibaca Jam 17.30 - (h_air_1730)" },
      // { parameter: "h_air_diff_1730", min: "", max: "", special_value: "", label: "Selisih tinggi air jam 1730 - (h_air_diff_1730)" },
      // { parameter: "t_air_max_1730", min: "", max: "", special_value: "", label: "Tinggi air minimal rata-rata - (t_air_max_1730)" },
      // { parameter: "t_air_min_1730", min: "", max: "", special_value: "", label: "Tinggi air minimal rata-rata - (t_air_min_1730)" },
      // { parameter: "t_air_avg_1730", min: "", max: "", special_value: "", label: "Suhu rata2 Air  jam 17.30 - (t_air_avg_1730)" },
      // { parameter: "rr_1730", min: "", max: "", special_value: "", label: "Curah hujan pada jam 17.30 - (rr_1730)" },
      // { parameter: "op_eva_1730", min: "", max: "", special_value: "", label: "Penguapan antara jam 13.30  sampai jam 17.30 - (op_eva_1730)" },

      // { parameter: "ws_avg_0c5_0700", min: "", max: "", special_value: "", label: "Kecepatan angin rata-rata 0,5 m jam 07.00- (ws_avg_0c5_0700)" },
      // {
      //   parameter: "h_air_diff_sebelum_kuras_0700",
      //   min: "",
      //   max: "",
      //   special_value: "",
      //   label: "selisih tinggi air sebelum kuras jam 0700 - (h_air_diff_sebelum_kuras_0700)",
      // },
      // {
      //   parameter: "h_air_diff_sebelum_kuras_1330",
      //   min: "",
      //   max: "",
      //   special_value: "",
      //   label: "selisih tinggi air sebelum kuras jam 1330 - (h_air_diff_sebelum_kuras_1330)",
      // },
      // {
      //   parameter: "h_air_diff_sebelum_kuras_1730",
      //   min: "",
      //   max: "",
      //   special_value: "",
      //   label: " - ( selisih tinggi air sebelum kuras jam 1730 - (h_air_diff_sebelum_kuras_1730)",
      // },
      // {
      //   parameter: "h_air_diff_setelah_kuras_0700",
      //   min: "",
      //   max: "",
      //   special_value: "",
      //   label: "selisih tinggi air setelah kuras jam 0700  - (h_air_diff_setelah_kuras_0700)",
      // },
      // {
      //   parameter: "h_air_diff_setelah_kuras_1330",
      //   min: "",
      //   max: "",
      //   special_value: "",
      //   label: "selisih tinggi air setelah kuras jam 1330 - (h_air_diff_setelah_kuras_1330)",
      // },
      // {
      //   parameter: "h_air_diff_setelah_kuras_1730",
      //   min: "",
      //   max: "",
      //   special_value: "",
      //   label: "selisih tinggi air setelah kuras jam 1730 - (h_air_diff_setelah_kuras_1730)",
      // },
      // {
      //   parameter: "h_air_sebelum_kuras_0700",
      //   min: "",
      //   max: "",
      //   special_value: "",
      //   label: "Nilai tinggi air sebelum kuras jam 0700 - (h_air_sebelum_kuras_0700)",
      // },
      // {
      //   parameter: "h_air_sebelum_kuras_1330",
      //   min: "",
      //   max: "",
      //   special_value: "",
      //   label: "Nilai tinggi air sebelum kuras jam 1330 - (h_air_sebelum_kuras_1330)",
      // },
      // {
      //   parameter: "h_air_sebelum_kuras_1730",
      //   min: "",
      //   max: "",
      //   special_value: "",
      //   label: "Nilai tinggi air sebelum kuras jam 1730 - (h_air_sebelum_kuras_1730)",
      // },
      // {
      //   parameter: "h_air_setelah_kuras_0700",
      //   min: "",
      //   max: "",
      //   special_value: "",
      //   label: "Nilai tinggi air setelah kuras jam 0700 - (h_air_setelah_kuras_0700)",
      // },
      // {
      //   parameter: "h_air_setelah_kuras_1330",
      //   min: "",
      //   max: "",
      //   special_value: "",
      //   label: "Nilai tinggi air setelah kuras jam 1330 - (h_air_setelah_kuras_1330)",
      // },
      // {
      //   parameter: "h_air_setelah_kuras_1730",
      //   min: "",
      //   max: "",
      //   special_value: "",
      //   label: "Nilai tinggi air setelah kuras jam 1730 - (h_air_setelah_kuras_1730)",
      // },
    ];
    return listAttr;
  },

  PICHE_PENGUAPAN_PARAMS() {
    const listAttr = [
      { parameter: "h_piche_0730", min: "", max: "", special_value: "", label: "Tinggi piche di jam 07.30 - (h_piche_0730)" },
      { parameter: "h_piche_1330", min: "", max: "", special_value: "", label: "Tinggi piche di jam 13.30 - (h_piche_1330)" },
      { parameter: "h_piche_1730", min: "", max: "", special_value: "", label: "Tinggi piche di jam 17.30 - (h_piche_1730)" },
      { parameter: "eva_piche_0730", min: "", max: "", special_value: "", label: "Selisih penguapan piche di jam 07.30 - (eva_piche_0730)" },
      { parameter: "eva_piche_1330", min: "", max: "", special_value: "", label: "Selisih penguapan piche di jam 13.30 - (eva_piche_1330)" },
      { parameter: "eva_piche_1730", min: "", max: "", special_value: "", label: "Selisih penguapan piche di jam 17.30 - (eva_piche_1730)" },
    ];
    return listAttr;
  },

  LYSIMETER_PARAMS() {
    const listAttr = [
      { parameter: "s_gundul", min: "", max: "", special_value: "" },
      { parameter: "s_komoditi", min: "", max: "", special_value: "" },
      { parameter: "s_berumput", min: "", max: "", special_value: "" },
      { parameter: "rr_gundul", min: "", max: "", special_value: "" },
      { parameter: "rr_komoditi", min: "", max: "", special_value: "" },
      { parameter: "rr_berumput", min: "", max: "", special_value: "" },
      { parameter: "pk_gundul", min: "", max: "", special_value: "" },
      { parameter: "pk_komoditi", min: "", max: "", special_value: "" },
      { parameter: "pk_berumput", min: "", max: "", special_value: "" },
      { parameter: "eva_gundul", min: "", max: "", special_value: "" },
      { parameter: "eva_komoditi", min: "", max: "", special_value: "" },
      { parameter: "eva_berumput", min: "", max: "", special_value: "" },
    ];
    return listAttr;
  },

  GUNBELLANI_PARAMS() {
    const listAttr = [
      { parameter: "h_air_gunbellani_2_kemarin", min: "", max: "", special_value: "", label: "Tinggi Air Pembacaan II Kemarin - (h_air_gunbellani_2_kemarin)", disable: true },
      { parameter: "h_air_gunbellani_1", min: "", max: "", special_value: "", label: "Tinggi Air Pembacaan I - (h_air_gunbellani_1)" },
      { parameter: "h_air_gunbellani_2", min: "", max: "", special_value: "", label: "Tinggi Air Pembacaan II - (h_air_gunbellani_2)" },
      { parameter: "h_air_diff_gunbellani", min: "", max: "", special_value: "", label: "Selisih pembacaan hari ini - (h_air_diff_gunbellani)" },
      { parameter: "rad_gunbellani", min: "", max: "", special_value: "", label: "Radiasi Matahari (calori/cm2) - (rad_gunbellani)", disable: true },
      // { parameter: "cat_gunbellani", min: "", max: "", special_value: "", label: "catatan gunbellani - (cat_gunbellani)" },
    ];
    return listAttr;
  },

  LAMA_PENYINARAN_PARAMS() {
    const listAttr = [];
    return listAttr;
  },

  AKTINOGRAPH_PARAMS() {
    const listAttr = [];
    return listAttr;
  },

  THERMOHIGOGRAPH_PARAMS() {
    const listAttr = [];
    return listAttr;
  },

  BAROGRAPH_PARAMS() {
    const listAttr = [];
    return listAttr;
  },

  PERAWANAN_PARAMS() {
    const listAttr = [];
    return listAttr;
  },

  FORM_HUJAN_PARAMS() {
    const listAttr = [
      { parameter: "rr_07_01", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 1 - (rr_07_01)" },
      { parameter: "rr_07_02", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 2 - (rr_07_02)" },
      { parameter: "rr_07_03", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 3 - (rr_07_03)" },
      { parameter: "rr_07_04", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 4 - (rr_07_04)" },
      { parameter: "rr_07_05", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 5 - (rr_07_05)" },
      { parameter: "rr_07_06", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 6 - (rr_07_06)" },
      { parameter: "rr_07_07", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 7 - (rr_07_07)" },
      { parameter: "rr_07_08", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 8 - (rr_07_08)" },
      { parameter: "rr_07_09", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 9 - (rr_07_09)" },
      { parameter: "rr_07_10", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 10 - (rr_07_10)" },
      { parameter: "rr_07_11", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 11 - (rr_07_11)" },
      { parameter: "rr_07_12", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 12 - (rr_07_12)" },
      { parameter: "rr_07_13", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 13 - (rr_07_13)" },
      { parameter: "rr_07_14", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 14 - (rr_07_14)" },
      { parameter: "rr_07_15", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 15 - (rr_07_15)" },
      { parameter: "rr_07_16", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 16 - (rr_07_16)" },
      { parameter: "rr_07_17", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 17 - (rr_07_17)" },
      { parameter: "rr_07_18", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 18 - (rr_07_18)" },
      { parameter: "rr_07_19", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 19 - (rr_07_19)" },
      { parameter: "rr_07_20", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 20 - (rr_07_20)" },
      { parameter: "rr_07_21", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 21 - (rr_07_21)" },
      { parameter: "rr_07_22", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 22 - (rr_07_22)" },
      { parameter: "rr_07_23", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 23 - (rr_07_23)" },
      { parameter: "rr_07_24", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 24 - (rr_07_24)" },
      { parameter: "rr_07_25", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 25 - (rr_07_25)" },
      { parameter: "rr_07_26", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 26 - (rr_07_26)" },
      { parameter: "rr_07_27", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 27 - (rr_07_27)" },
      { parameter: "rr_07_28", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 28 - (rr_07_28)" },
      { parameter: "rr_07_29", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 29 - (rr_07_29)" },
      { parameter: "rr_07_30", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 30 - (rr_07_30)" },
      { parameter: "rr_07_31", min: "", max: "", special_value: "", label: "curah hujan jam 07 WS, pengukuran tgl 31 - (rr_07_31)" },
    ];
    return listAttr;
  },

  FORM_HUJAN_HARIAN_PARAMS() {
    const listAttr = [
      { parameter: "rr_phk_mm", min: "", max: "", special_value: "", label: "Curah Hujan - (rr_phk_mm)" }
    ]
    return listAttr;
  },

  PIBAL_PARAMS() {
    const listAttr = [];
    return listAttr;
  },

  HUJAN_HELMAN_PARAMS() {
    const listAttr = [
      { parameter: "hellman_5m", min: "", max: "", special_value: "", label: "Jumlah pada masing-masing periode waktu 5 menit - (hellman_5m)" },
      { parameter: "hellman_10m", min: "", max: "", special_value: "", label: "Jumlah pada masing-masing periode waktu 10 menit - (hellman_10m)" },
      { parameter: "hellman_15m", min: "", max: "", special_value: "", label: "Jumlah pada masing-masing periode waktu 15 menit - (hellman_15m) " },
      { parameter: "hellman_30m", min: "", max: "", special_value: "", label: "Jumlah pada masing-masing periode waktu 30 menit - (hellman_30m)" },
      { parameter: "hellman_45m", min: "", max: "", special_value: "", label: "Jumlah pada masing-masing periode waktu 45 menit - (hellman_45m)" },
      { parameter: "hellman_60m", min: "", max: "", special_value: "", label: "Jumlah pada masing-masing periode waktu 60 menit - (hellman_60m)" },
      { parameter: "hellman_120m", min: "", max: "", special_value: "", label: "Jumlah pada masing-masing periode waktu 120 menit - (hellman_120m)" },
      { parameter: "hellman_3h", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 3 jam - (hellman_3h)" },
      { parameter: "hellman_6h", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 6 jam - (hellman_6h)" },
      { parameter: "hellman_12h", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 12 jam - (hellman_12h)" },
      { parameter: "hellman_07_08", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 07-08 - (hellman_07_08)" },
      { parameter: "hellman_08_09", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 08-09 - (hellman_08_09)" },
      { parameter: "hellman_09_10", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 09-10 - (hellman_09_10)" },
      { parameter: "hellman_10_11", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 10-11 - (hellman_10_11)" },
      { parameter: "hellman_11_12", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 11-12 - (hellman_11_12)" },
      { parameter: "hellman_12_13", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 12-13 - (hellman_12_13)" },
      { parameter: "hellman_13_14", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 13-14 - (hellman_13_14)" },
      { parameter: "hellman_14_15", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 14-15 - (hellman_14_15)" },
      { parameter: "hellman_15_16", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 15-16 - (hellman_15-16)" },
      { parameter: "hellman_16_17", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 16-17 - (hellman_16_17)" },
      { parameter: "hellman_17_18", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 17-18 - (hellman_17_18)" },
      { parameter: "hellman_18_19", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 18-19 - (hellman_18_19)" },
      { parameter: "hellman_19_20", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 19-20 - (hellman_19_20)" },
      { parameter: "hellman_20_21", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 20-21 - (hellman_20_21)" },
      { parameter: "hellman_21_22", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 21-22 - (hellman_21_22)" },
      { parameter: "hellman_22_23", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 22-23 - (hellman_22_23)" },
      { parameter: "hellman_23_24", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 23-24 - (hellman_23_24)" },
      { parameter: "hellman_24_01", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 24-01 - (hellman_24_01)" },
      { parameter: "hellman_01_02", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 01-02 - (hellman_01_02)" },
      { parameter: "hellman_02_03", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 02-03 - (hellman_02_03)" },
      { parameter: "hellman_03_04", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 03-04 - (hellman_03_04)" },
      { parameter: "hellman_04_05", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 04-05 - (hellman_04_05)" },
      { parameter: "hellman_05_06", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 05-06 - (hellman_05_06)" },
      { parameter: "hellman_06_07", min: "", max: "", special_value: "", label: "Jumlah hujan tiap Jam 06-07 - (hellman_06_07)" },
    ];
    return listAttr;
  },

  KELEMBAPAN_TANAH_PARAMS() {
    const listAttr = [];
    return listAttr;
  },

  FENOLOGI_PARAMS() {
    const listAttr = [];
    return listAttr;
  },

  KAH_PARAMS() {
    const listAttr = [
      { parameter: "kah_ph", min: "", max: "", special_value: "", label: "pH - (ph)", disable: false },
      { parameter: "kah_conductivity", min: "", max: "", special_value: "", label: "Electro-Conductivity - (conductivity)", disable: false },
      { parameter: "kah_cl", min: "", max: "", special_value: "", label: "Cl- (klorida) - (cl)", disable: false },
      { parameter: "kah_so42", min: "", max: "", special_value: "", label: "SO42- (Sulfat) - (so42)", disable: false },
      { parameter: "kah_no3", min: "", max: "", special_value: "", label: "NO3- (Nitrat) - (no3)", disable: false },
      { parameter: "kah_na", min: "", max: "", special_value: "", label: "Na+ (Natrium) - (na)", disable: false },
      { parameter: "kah_nh4", min: "", max: "", special_value: "", label: "NH4+ (ammonium) - (ng4)", disable: false },
      { parameter: "kah_k", min: "", max: "", special_value: "", label: "K+ (Kalium) - (k)", disable: false },
      { parameter: "kah_mg2", min: "", max: "", special_value: "", label: "Mg2+ (Magnesium) - (mg2)", disable: false },
      { parameter: "kah_ca2", min: "", max: "", special_value: "", label: "Ca2+ (Kalsium) - (ca2)", disable: false },
      { parameter: "kah_acidity", min: "", max: "", special_value: "", label: "Acidity - (acidity)", disable: false },
      { parameter: "kah_alkalinity", min: "", max: "", special_value: "", label: "Alkalinity - (alkalinity)", disable: false },
      { parameter: "kah_curah_hujan_sampling", min: "", max: "", special_value: "", label: "Curah Hujan Sampling - (curah_hujan_sampling)", disable: false },
      { parameter: "kah_massa_sampling", min: "", max: "", special_value: "", label: "Massa sampling (air+botol) - (massa_sampling)", disable: false },
    ];
    return listAttr;
  },

  SPM_PARAMS() {
    const listAttr = [
      { parameter: "spm_konsentrasi", min: "", max: "", special_value: "", label: "Konsentrasi SPM - (konsentrasi_spm)", disable: false },
      { parameter: "spm_bobot_filter_kosong", min: "", max: "", special_value: "", label: "Bobot Filter Kosong - (bobot_filter_kosong)", disable: false },
      { parameter: "spm_bobot_filter_sampling", min: "", max: "", special_value: "", label: "Bobot Filter Sampling - (bobot_filter_sampling)", disable: false },
      { parameter: "spm_flow_rate_awal_m3", min: "", max: "", special_value: "", label: "Flow Rate Awal - (m3/min)", disable: false },
      { parameter: "spm_flow_rate_awal_ft3", min: "", max: "", special_value: "", label: "Flow Rate Awal - (ft3/min)", disable: false },
      { parameter: "spm_flow_rate_akhir_m3", min: "", max: "", special_value: "", label: "Flow Rate Akhir - (m3/min)", disable: false },
      { parameter: "spm_flow_rate_akhir_ft3", min: "", max: "", special_value: "", label: "Flow Rate Akhir - (ft3/min)", disable: false },
      { parameter: "spm_total_waktu_sampling", min: "", max: "", special_value: "", label: "Total Waktu Sampling - (total_waktu_sampling)", disable: false },
      { parameter: "spm_tekanan_udara_awal", min: "", max: "", special_value: "", label: "Tekanan udara awal - (tekanan_udara_awal)", disable: false },
      { parameter: "spm_tekanan_udara_akhir", min: "", max: "", special_value: "", label: "Tekanan udara akhir - (tekanan_udara_akhir)", disable: false },
      { parameter: "spm_temperatur_awal", min: "", max: "", special_value: "", label: "Temperature awal - (temperatur_awal)", disable: false },
      { parameter: "spm_temperatur_akhir", min: "", max: "", special_value: "", label: "Temperature akhir - (temperatur_akhir)", disable: false },
    ];
    return listAttr;
  },

  SO2NO2_PARAMS() {
    const listAttr = [
      { parameter: "konsentrasi_so2", min: "", max: "", special_value: "", label: "Konsentrasi SO2 - (konsentrasi_so2)", disable: false },
      { parameter: "konsentrasi_no2", min: "", max: "", special_value: "", label: "Konsentrasi NO2 - (konsentrasi_no2)", disable: false },
    ];
    return listAttr;
  },

  AEROSOL_PARAMS() {
    const listAttr = [
      { parameter: "aerosol_ph", min: "", max: "", special_value: "", label: "pH - (ph)", disable: false },
      { parameter: "aerosol_conductivity", min: "", max: "", special_value: "", label: "Electro-Conductivity - (conductivity)", disable: false },
      { parameter: "aerosol_konsentrasi", min: "", max: "", special_value: "", label: "Konsentrasi Aerosol - (konsentrasi_aerosol)", disable: false },
      { parameter: "aerosol_bobot_filter_kosong", min: "", max: "", special_value: "", label: "Bobot Filter Kosong - (bobot_filter_kosong)", disable: false },
      { parameter: "aerosol_bobot_filter_sampling", min: "", max: "", special_value: "", label: "Bobot Filter Sampling - (bobot_filter_sampling)", disable: false },
      { parameter: "aerosol_total_waktu_sampling", min: "", max: "", special_value: "", label: "Total Waktu Sampling - (total_waktu_sampling)", disable: false },
      { parameter: "aerosol_flow_rate_awal", min: "", max: "", special_value: "", label: "Flow Rate Awal - (flow_rate_awal)", disable: false },
      { parameter: "aerosol_flow_rate_akhir", min: "", max: "", special_value: "", label: "Flow Rate Akhir - (flow_rate_akhir)", disable: false },
      { parameter: "aerosol_tekanan_udara_awal", min: "", max: "", special_value: "", label: "Tekanan Udara Awal - (tekanan_udara_awal)", disable: false },
      { parameter: "aerosol_tekanan_udara_akhir", min: "", max: "", special_value: "", label: "Tekanan Udara Akhir - (tekanan_udara_akhir)", disable: false },
      { parameter: "aerosol_temperatur_awal", min: "", max: "", special_value: "", label: "Temperature Awal - (temperatur_awal)", disable: false },
      { parameter: "aerosol_temperatur_akhir", min: "", max: "", special_value: "", label: "Temperature Akhir - (temperatur_akhir)", disable: false },
      { parameter: "aerosol_cl", min: "", max: "", special_value: "", label: "Cl- (klorida) - (cl)", disable: false },
      { parameter: "aerosol_so42", min: "", max: "", special_value: "", label: "SO42- (Sulfat) - (so42)", disable: false },
      { parameter: "aerosol_no3", min: "", max: "", special_value: "", label: "NO3- (Nitrat) - (no3)", disable: false },
      { parameter: "aerosol_na", min: "", max: "", special_value: "", label: "Na+ (Natrium) - (na)", disable: false },
      { parameter: "aerosol_nh4", min: "", max: "", special_value: "", label: "NH4+ (ammonium) - (nh4)", disable: false },
      { parameter: "aerosol_k", min: "", max: "", special_value: "", label: "K+ (Kalium) - (k)", disable: false },
      { parameter: "aerosol_mg2", min: "", max: "", special_value: "", label: "Mg2+ (Magnesium) - (mg2)", disable: false },
      { parameter: "aerosol_ca2", min: "", max: "", special_value: "", label: "Ca2+ (Kalsium) - (ca2)", disable: false },
    ];
    return listAttr;
  },
};
