<template>
  <div>
    <b-row class="mt-n2">
      <!-- card actions -->
      <b-col cols="6">
        <b-card>
          <h6 class="text-dark badge badge-light-dark font-weight-bolder mb-2">Suhu Udara (&#8451;)</h6>
          <b-row>
            <b-col sm="2 mb-1 ml-2">
              <div>
                <label for="input60" class="label-entry1">01:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu dari Thermohigrograph pukul 01.00 WS" /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1">02:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu dari Thermohigrograph pukul 02.00 WS" /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1">03:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu dari Thermohigrograph pukul 03.00 WS" /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1">04:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu dari Thermohigrograph pukul 04.00 WS" /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1">05:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu dari Thermohigrograph pukul 05.00 WS" /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1">06:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu dari Thermohigrograph pukul 06.00 WS" /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1">07:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu dari Thermohigrograph pukul 07.00 WS" /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1">08:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu dari Thermohigrograph pukul 08.00 WS" /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1">09:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu dari Thermohigrograph pukul 09.00 WS" /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1">10:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu dari Thermohigrograph pukul 10.00 WS" /></label>
              </div>
              <div>
                <label for="input30" class="label-entry1">11:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu dari Thermohigrograph pukul 11.00 WS" /></label>
              </div>
              <div>
                <label for="input30" class="label-entry1">12:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu dari Thermohigrograph pukul 12.00 WS" /></label>
              </div>
            </b-col>

            <b-col sm="3 mb-1">
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                :state="validateState($v.suhu._1)"
                v-model.number="$v.suhu._1.$model"
                id="input_t_1"
                v-on:keyup="checkValidate($event, 't_1')"
                v-bind:class="{ rangecheck: rc_t_1 }"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              >
              </b-form-input>
              <!-- tooltips RC -->
              <b-tooltip v-if="rc_t_1" target="input_t_1" triggers="hover" placement="topleft">{{ rc_t_1_message }}</b-tooltip>
              <!-- tooltips GC -->
              <b-tooltip v-if="!rc_t_1" :show="validateTooltipStateSuhu('_1')" :triggers="'hover'" target="input_t_1" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("suhu_1") }}
              </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                :state="validateState($v.suhu._2)"
                v-model.number="$v.suhu._2.$model"
                id="input_t_2"
                v-on:keyup="checkValidate($event, 't_2')"
                v-bind:class="{ rangecheck: rc_t_2 }"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              >
              </b-form-input>
              <!-- tooltips RC -->
              <b-tooltip v-if="rc_t_2" target="input_t_2" triggers="hover" placement="topleft">{{ rc_t_2_message }}</b-tooltip>
              <!-- tooltips GC -->
              <b-tooltip v-if="!rc_t_1" :show="validateTooltipStateSuhu('_2')" :triggers="'hover'" target="input_t_2" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("suhu_2") }}
              </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                :state="validateState($v.suhu._3)"
                v-model.number="$v.suhu._3.$model"
                id="input_t_3"
                v-on:keyup="checkValidate($event, 't_3')"
                v-bind:class="{ rangecheck: rc_t_3 }"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              >
              </b-form-input>
              <!-- tooltips RC -->
              <b-tooltip v-if="rc_t_3" target="input_t_3" triggers="hover" placement="topleft">{{ rc_t_3_message }}</b-tooltip>
              <!-- tooltips GC -->
              <b-tooltip v-if="!rc_t_3" :show="validateTooltipStateSuhu('_3')" :triggers="'hover'" target="rc_t_3" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("suhu_3") }}
              </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                :state="validateState($v.suhu._4)"
                v-model.number="$v.suhu._4.$model"
                id="input_t_4"
                v-on:keyup="checkValidate($event, 't_4')"
                v-bind:class="{ rangecheck: rc_t_4 }"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              >
              </b-form-input>
              <!-- tooltips RC -->
              <b-tooltip v-if="rc_t_4" target="input_t_4" triggers="hover" placement="topleft">{{ rc_t_4_message }}</b-tooltip>
              <!-- tooltips GC -->
              <b-tooltip v-if="!rc_t_4" :show="validateTooltipStateSuhu('_4')" :triggers="'hover'" target="input_t_4" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("suhu_4") }}
              </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                :state="validateState($v.suhu._5)"
                v-model.number="$v.suhu._5.$model"
                id="input_t_5"
                v-on:keyup="checkValidate($event, 't_5')"
                v-bind:class="{ rangecheck: rc_t_5 }"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              >
              </b-form-input>
              <!-- tooltips RC -->
              <b-tooltip v-if="rc_t_5" target="input_t_5" triggers="hover" placement="topleft">{{ rc_t_5_message }}</b-tooltip>
              <!-- tooltips GC -->
              <b-tooltip v-if="!rc_t_5" :show="validateTooltipStateSuhu('_5')" :triggers="'hover'" target="input_t_5" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("suhu_5") }}
              </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                :state="validateState($v.suhu._6)"
                v-model.number="$v.suhu._6.$model"
                id="input_t_6"
                v-on:keyup="checkValidate($event, 't_6')"
                v-bind:class="{ rangecheck: rc_t_6 }"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              >
              </b-form-input>
              <!-- tooltips RC -->
              <b-tooltip v-if="rc_t_6" target="input_t_6" triggers="hover" placement="topleft">{{ rc_t_6_message }}</b-tooltip>
              <!-- tooltips GC -->
              <b-tooltip v-if="!rc_t_6" :show="validateTooltipStateSuhu('_6')" :triggers="'hover'" target="input_t_6" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("suhu_6") }}
              </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                :state="validateState($v.suhu._7)"
                v-model.number="$v.suhu._7.$model"
                id="input_t_7"
                v-on:keyup="checkValidate($event, 't_7')"
                v-bind:class="{ rangecheck: rc_t_7 }"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              >
              </b-form-input>
              <!-- tooltips RC -->
              <b-tooltip v-if="rc_t_7" target="input_t_7" triggers="hover" placement="topleft">{{ rc_t_7_message }}</b-tooltip>
              <!-- tooltips GC -->
              <b-tooltip v-if="!rc_t_7" :show="validateTooltipStateSuhu('_7')" :triggers="'hover'" target="input_t_7" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("suhu_7") }}
              </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                :state="validateState($v.suhu._8)"
                v-model.number="$v.suhu._8.$model"
                id="input_t_8"
                v-on:keyup="checkValidate($event, 't_8')"
                v-bind:class="{ rangecheck: rc_t_8 }"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              >
              </b-form-input>
              <!-- tooltips RC -->
              <b-tooltip v-if="rc_t_8" target="input_t_8" triggers="hover" placement="topleft">{{ rc_t_8_message }}</b-tooltip>
              <!-- tooltips GC -->
              <b-tooltip v-if="!rc_t_8" :show="validateTooltipStateSuhu('_8')" :triggers="'hover'" target="input_t_8" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("suhu_8") }}
              </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                :state="validateState($v.suhu._9)"
                v-model.number="$v.suhu._9.$model"
                id="input_t_9"
                v-on:keyup="checkValidate($event, 't_9')"
                v-bind:class="{ rangecheck: rc_t_9 }"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              >
              </b-form-input>
              <!-- tooltips RC -->
              <b-tooltip v-if="rc_t_9" target="input_t_9" triggers="hover" placement="topleft">{{ rc_t_9_message }}</b-tooltip>
              <!-- tooltips GC -->
              <b-tooltip v-if="!rc_t_9" :show="validateTooltipStateSuhu('_9')" :triggers="'hover'" target="input_t_9" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("suhu_9") }}
              </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                :state="validateState($v.suhu._10)"
                v-model.number="$v.suhu._10.$model"
                id="input_t_10"
                v-on:keyup="checkValidate($event, 't_10')"
                v-bind:class="{ rangecheck: rc_t_10 }"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              >
              </b-form-input>
              <!-- tooltips RC -->
              <b-tooltip v-if="rc_t_10" target="input_t_10" triggers="hover" placement="topleft">{{ rc_t_10_message }}</b-tooltip>
              <!-- tooltips GC -->
              <b-tooltip v-if="!rc_t_10" :show="validateTooltipStateSuhu('_10')" :triggers="'hover'" target="input_t_10" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("suhu_10") }}
              </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                :state="validateState($v.suhu._11)"
                v-model.number="$v.suhu._11.$model"
                id="input_t_11"
                v-on:keyup="checkValidate($event, 't_11')"
                v-bind:class="{ rangecheck: rc_t_11 }"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              >
              </b-form-input>
              <!-- tooltips RC -->
              <b-tooltip v-if="rc_t_11" target="input_t_11" triggers="hover" placement="topleft">{{ rc_t_11_message }}</b-tooltip>
              <!-- tooltips GC -->
              <b-tooltip v-if="!rc_t_11" :show="validateTooltipStateSuhu('_11')" :triggers="'hover'" target="input_t_11" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("suhu_11") }}
              </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                :state="validateState($v.suhu._12)"
                v-model.number="$v.suhu._12.$model"
                id="input_t_12"
                v-on:keyup="checkValidate($event, 't_12')"
                v-bind:class="{ rangecheck: rc_t_12 }"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              >
              </b-form-input>
              <!-- tooltips RC -->
              <b-tooltip v-if="rc_t_12" target="input_t_12" triggers="hover" placement="topleft">{{ rc_t_12_message }}</b-tooltip>
              <!-- tooltips GC -->
              <b-tooltip v-if="!rc_t_12" :show="validateTooltipStateSuhu('_12')" :triggers="'hover'" target="input_t_12" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("suhu_12") }}
              </b-tooltip>
            </b-col>

            <b-col sm="2 mb-1 ml-3">
              <div>
                <label for="input60" class="label-entry1">13:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu dari Thermohigrograph pukul 13.00 WS" /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1">14:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu dari Thermohigrograph pukul 14.00 WS" /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1">15:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu dari Thermohigrograph pukul 15.00 WS" /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1">16:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu dari Thermohigrograph pukul 16.00 WS" /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1">17:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu dari Thermohigrograph pukul 17.00 WS" /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1">18:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu dari Thermohigrograph pukul 18.00 WS" /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1">19:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu dari Thermohigrograph pukul 19.00 WS" /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1">20:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu dari Thermohigrograph pukul 20.00 WS" /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1">21:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu dari Thermohigrograph pukul 21.00 WS" /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1">22:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu dari Thermohigrograph pukul 22.00 WS" /></label>
              </div>
              <div>
                <label for="input30" class="label-entry1">23:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu dari Thermohigrograph pukul 23.00 WS" /></label>
              </div>
              <div>
                <label for="input30" class="label-entry1">24:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu dari Thermohigrograph pukul 24.00 WS" /></label>
              </div>
            </b-col>

            <b-col sm="3 mb-1">
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                :state="validateState($v.suhu._13)"
                v-model.number="$v.suhu._13.$model"
                id="input_t_13"
                v-on:keyup="checkValidate($event, 't_13')"
                v-bind:class="{ rangecheck: rc_t_13 }"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              >
              </b-form-input>
              <!-- tooltips RC -->
              <b-tooltip v-if="rc_t_13" target="input_t_13" triggers="hover" placement="topleft">{{ rc_t_13_message }}</b-tooltip>
              <!-- tooltips GC -->
              <b-tooltip v-if="!rc_t_13" :show="validateTooltipStateSuhu('_13')" :triggers="'hover'" target="input_t_13" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("suhu_13") }}
              </b-tooltip>

              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                :state="validateState($v.suhu._14)"
                v-model.number="$v.suhu._14.$model"
                id="input_t_14"
                v-on:keyup="checkValidate($event, 't_14')"
                v-bind:class="{ rangecheck: rc_t_14 }"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              >
              </b-form-input>
              <!-- tooltips RC -->
              <b-tooltip v-if="rc_t_14" target="input_t_14" triggers="hover" placement="topleft">{{ rc_t_14_message }}</b-tooltip>
              <!-- tooltips GC -->
              <b-tooltip v-if="!rc_t_14" :show="validateTooltipStateSuhu('_14')" :triggers="'hover'" target="input_t_14" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("suhu_14") }}
              </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                :state="validateState($v.suhu._15)"
                v-model.number="$v.suhu._15.$model"
                id="input_t_15"
                v-on:keyup="checkValidate($event, 't_15')"
                v-bind:class="{ rangecheck: rc_t_15 }"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              >
              </b-form-input>
              <!-- tooltips RC -->
              <b-tooltip v-if="rc_t_15" target="input_t_15" triggers="hover" placement="topleft">{{ rc_t_15_message }}</b-tooltip>
              <!-- tooltips GC -->
              <b-tooltip v-if="!rc_t_15" :show="validateTooltipStateSuhu('_15')" :triggers="'hover'" target="input_t_15" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("suhu_15") }}
              </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                :state="validateState($v.suhu._16)"
                v-model.number="$v.suhu._16.$model"
                id="input_t_16"
                v-on:keyup="checkValidate($event, 't_16')"
                v-bind:class="{ rangecheck: rc_t_16 }"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              >
              </b-form-input>
              <!-- tooltips RC -->
              <b-tooltip v-if="rc_t_16" target="input_t_16" triggers="hover" placement="topleft">{{ rc_t_16_message }}</b-tooltip>
              <!-- tooltips GC -->
              <b-tooltip v-if="!rc_t_16" :show="validateTooltipStateSuhu('_16')" :triggers="'hover'" target="input_t_16" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("suhu_16") }}
              </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                :state="validateState($v.suhu._17)"
                v-model.number="$v.suhu._17.$model"
                id="input_t_17"
                v-on:keyup="checkValidate($event, 't_17')"
                v-bind:class="{ rangecheck: rc_t_17 }"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              >
              </b-form-input>
              <!-- tooltips RC -->
              <b-tooltip v-if="rc_t_17" target="input_t_17" triggers="hover" placement="topleft">{{ rc_t_17_message }}</b-tooltip>
              <!-- tooltips GC -->
              <b-tooltip v-if="!rc_t_17" :show="validateTooltipStateSuhu('_17')" :triggers="'hover'" target="input_t_17" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("suhu_17") }}
              </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                :state="validateState($v.suhu._18)"
                v-model.number="$v.suhu._18.$model"
                id="input_t_18"
                v-on:keyup="checkValidate($event, 't_18')"
                v-bind:class="{ rangecheck: rc_t_18 }"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              >
              </b-form-input>
              <!-- tooltips RC -->
              <b-tooltip v-if="rc_t_18" target="input_t_18" triggers="hover" placement="topleft">{{ rc_t_18_message }}</b-tooltip>
              <!-- tooltips GC -->
              <b-tooltip v-if="!rc_t_18" :show="validateTooltipStateSuhu('_18')" :triggers="'hover'" target="input_t_18" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("suhu_18") }}
              </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                :state="validateState($v.suhu._19)"
                v-model.number="$v.suhu._19.$model"
                id="input_t_19"
                v-on:keyup="checkValidate($event, 't_19')"
                v-bind:class="{ rangecheck: rc_t_19 }"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              >
              </b-form-input>
              <!-- tooltips RC -->
              <b-tooltip v-if="rc_t_19" target="input_t_19" triggers="hover" placement="topleft">{{ rc_t_19_message }}</b-tooltip>
              <!-- tooltips GC -->
              <b-tooltip v-if="!rc_t_19" :show="validateTooltipStateSuhu('_19')" :triggers="'hover'" target="input_t_19" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("suhu_20") }}
              </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                :state="validateState($v.suhu._20)"
                v-model.number="$v.suhu._20.$model"
                id="input_t_20"
                v-on:keyup="checkValidate($event, 't_20')"
                v-bind:class="{ rangecheck: rc_t_20 }"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              >
              </b-form-input>
              <!-- tooltips RC -->
              <b-tooltip v-if="rc_t_20" target="input_t_20" triggers="hover" placement="topleft">{{ rc_t_20_message }}</b-tooltip>
              <!-- tooltips GC -->
              <b-tooltip v-if="!rc_t_20" :show="validateTooltipStateSuhu('_20')" :triggers="'hover'" target="input_t_20" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("suhu_20") }}
              </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                :state="validateState($v.suhu._21)"
                v-model.number="$v.suhu._21.$model"
                id="input_t_21"
                v-on:keyup="checkValidate($event, 't_21')"
                v-bind:class="{ rangecheck: rc_t_21 }"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              >
              </b-form-input>
              <!-- tooltips RC -->
              <b-tooltip v-if="rc_t_21" target="input_t_21" triggers="hover" placement="topleft">{{ rc_t_21_message }}</b-tooltip>
              <!-- tooltips GC -->
              <b-tooltip v-if="!rc_t_21" :show="validateTooltipStateSuhu('_21')" :triggers="'hover'" target="input_t_21" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("suhu_21") }}
              </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                :state="validateState($v.suhu._22)"
                v-model.number="$v.suhu._22.$model"
                id="input_t_22"
                v-on:keyup="checkValidate($event, 't_22')"
                v-bind:class="{ rangecheck: rc_t_22 }"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              >
              </b-form-input>
              <!-- tooltips RC -->
              <b-tooltip v-if="rc_t_22" target="input_t_22" triggers="hover" placement="topleft">{{ rc_t_22_message }}</b-tooltip>
              <!-- tooltips GC -->
              <b-tooltip v-if="!rc_t_22" :show="validateTooltipStateSuhu('_22')" :triggers="'hover'" target="input_t_22" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("suhu_22") }}
              </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                :state="validateState($v.suhu._23)"
                v-model.number="$v.suhu._23.$model"
                id="input_t_23"
                v-on:keyup="checkValidate($event, 't_23')"
                v-bind:class="{ rangecheck: rc_t_23 }"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              >
              </b-form-input>
              <!-- tooltips RC -->
              <b-tooltip v-if="rc_t_23" target="input_t_23" triggers="hover" placement="topleft">{{ rc_t_23_message }}</b-tooltip>
              <!-- tooltips GC -->
              <b-tooltip v-if="!rc_t_23" :show="validateTooltipStateSuhu('_23')" :triggers="'hover'" target="input_t_23" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("suhu_23") }}
              </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                :state="validateState($v.suhu._24)"
                v-model.number="$v.suhu._24.$model"
                id="input_t_24"
                v-on:keyup="checkValidate($event, 't_24')"
                v-bind:class="{ rangecheck: rc_t_24 }"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              >
              </b-form-input>
              <!-- tooltips RC -->
              <b-tooltip v-if="rc_t_24" target="input_t_24" triggers="hover" placement="topleft">{{ rc_t_24_message }}</b-tooltip>
              <!-- tooltips GC -->
              <b-tooltip v-if="!rc_t_24" :show="validateTooltipStateSuhu('_24')" :triggers="'hover'" target="input_t_24" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("suhu_24") }}
              </b-tooltip>
            </b-col>
            <b-row>
              <b-col sm="4" style="padding-left: 50px">
                <div>
                  <label for="input60" class="label-entry bg-light-primary font-weight-bolder"
                    >Maksimum <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu maksimum dari Thermohigrograph"
                  /></label>
                </div>
              </b-col>
              <b-col sm="3 ">
                <div>
                  <label for="input60" class="label-entry bg-light-primary font-weight-bolder"
                    >Minimum <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu minimum dari Thermohigrograph"
                  /></label>
                </div>
              </b-col>
              <b-col sm="4 ">
                <div>
                  <label for="input60" class="label-entry bg-light-primary font-weight-bolder"
                    >Rata-rata <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Rata-rata dari Thermohigrograph"
                  /></label>
                </div>
              </b-col>
              <b-col sm="1"></b-col>

              <b-col sm="4" style="padding-left: 50px">
                <b-form-input type="number" v-model.number="suhu_max" id="input10" size="sm" class="text-center mb-2" placeholder="" readonly></b-form-input>
              </b-col>
              <b-col sm="3 ">
                <b-form-input type="number" v-model.number="suhu_min" id="input10" size="sm" class="text-center mb-2" placeholder="" readonly></b-form-input>
              </b-col>
              <b-col sm="4 ">
                <b-form-input type="number" v-model.number="suhu_rata" id="input10" size="sm" class="text-center bg-light-success mb-2" placeholder="" readonly></b-form-input>
              </b-col>
            </b-row>
          </b-row>
        </b-card>
      </b-col>

      <b-col cols="6">
        <b-card>
          <h6 class="text-dark badge badge-light-dark font-weight-bolder mb-2">Kelembaban Udara (%)</h6>
          <b-row>
            <b-col sm="2 mb-1 ml-2">
              <div>
                <label for="input60" class="label-entry1"
                  >01:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban dari Thermohigrograph pukul 01.00 WS"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >02:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban dari Thermohigrograph pukul 02.00 WS"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >03:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban dari Thermohigrograph pukul 03.00 WS"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >04:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban dari Thermohigrograph pukul 04.00 WS"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >05:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban dari Thermohigrograph pukul 05.00 WS"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >06:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban dari Thermohigrograph pukul 06.00 WS"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >07:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban dari Thermohigrograph pukul 07.00 WS"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >08:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban dari Thermohigrograph pukul 08.00 WS"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >09:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban dari Thermohigrograph pukul 09.00 WS"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >10:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban dari Thermohigrograph pukul 10.00 WS"
                /></label>
              </div>
              <div>
                <label for="input30" class="label-entry1"
                  >11:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban dari Thermohigrograph pukul 11.00 WS"
                /></label>
              </div>
              <div>
                <label for="input30" class="label-entry1"
                  >12:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban dari Thermohigrograph pukul 12.00 WS"
                /></label>
              </div>
            </b-col>

            <b-col sm="3 mb-1">
              <b-form-input
                v-on:keypress="format_integer"
                id="input_rh_1"
                v-on:keyup="checkValidate($event, 'rh_1')"
                v-bind:class="{ rangecheck: rc_rh_1 }"
                v-on:keydown="onKeyCheck($event)"
                type="number"
                :state="validateState($v.kelembaban._1)"
                v-model.number="$v.kelembaban._1.$model"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <!-- tooltips RC -->
              <b-tooltip v-if="rc_rh_1" target="input_rh_1" triggers="hover" placement="topleft">{{ rc_rh_1_message }}</b-tooltip>
              <b-tooltip v-if="!rc_rh_1" :triggers="'hover'" target="input_rh_1" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("kelembaban_1") }}
              </b-tooltip>

              <b-form-input
                v-on:keypress="format_integer"
                id="input_rh_2"
                v-on:keyup="checkValidate($event, 'rh_2')"
                v-bind:class="{ rangecheck: rc_rh_2 }"
                v-on:keydown="onKeyCheck($event)"
                type="number"
                :state="validateState($v.kelembaban._2)"
                v-model.number="$v.kelembaban._2.$model"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>

              <b-tooltip v-if="rc_rh_2" target="input_rh_2" triggers="hover" placement="topleft">{{ rc_rh_2_message }}</b-tooltip>
              <b-tooltip v-if="!rc_rh_2" :show="validateTooltipStateKelembaban('_2')" :triggers="'hover'" target="input_rh_2" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("kelembaban_2") }}
              </b-tooltip>

              <b-form-input
                v-on:keypress="format_integer"
                id="input_rh_3"
                v-on:keyup="checkValidate($event, 'rh_3')"
                v-bind:class="{ rangecheck: rc_rh_3 }"
                v-on:keydown="onKeyCheck($event)"
                type="number"
                :state="validateState($v.kelembaban._3)"
                v-model.number="$v.kelembaban._3.$model"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_rh_3" target="input_rh_3" triggers="hover" placement="topleft">{{ rc_rh_3_message }}</b-tooltip>
              <b-tooltip v-if="!rc_rh_3" :show="validateTooltipStateKelembaban('_3')" :triggers="'hover'" target="input_rh_3" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("kelembaban_3") }}
              </b-tooltip>

              <b-form-input
                v-on:keypress="format_integer"
                id="input_rh_4"
                v-on:keyup="checkValidate($event, 'rh_4')"
                v-bind:class="{ rangecheck: rc_rh_4 }"
                v-on:keydown="onKeyCheck($event)"
                type="number"
                :state="validateState($v.kelembaban._4)"
                v-model.number="$v.kelembaban._4.$model"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_rh_4" target="input_rh_4" triggers="hover" placement="topleft">{{ rc_rh_4_message }}</b-tooltip>
              <b-tooltip v-if="!rc_rh_4" :show="validateTooltipStateKelembaban('_4')" :triggers="'hover'" target="input_rh_4" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("kelembaban_4") }}
              </b-tooltip>

              <b-form-input
                v-on:keypress="format_integer"
                id="input_rh_5"
                v-on:keyup="checkValidate($event, 'rh_5')"
                v-bind:class="{ rangecheck: rc_rh_5 }"
                v-on:keydown="onKeyCheck($event)"
                type="number"
                :state="validateState($v.kelembaban._5)"
                v-model.number="$v.kelembaban._5.$model"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_rh_5" target="input_rh_5" triggers="hover" placement="topleft">{{ rc_rh_5_message }}</b-tooltip>
              <b-tooltip v-if="!rc_rh_5" :show="validateTooltipStateKelembaban('_5')" :triggers="'hover'" target="input_rh_5" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("kelembaban_5") }}
              </b-tooltip>

              <b-form-input
                v-on:keypress="format_integer"
                id="input_rh_6"
                v-on:keyup="checkValidate($event, 'rh_6')"
                v-bind:class="{ rangecheck: rc_rh_6 }"
                v-on:keydown="onKeyCheck($event)"
                type="number"
                :state="validateState($v.kelembaban._6)"
                v-model.number="$v.kelembaban._6.$model"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_rh_6" target="input_rh_6" triggers="hover" placement="topleft">{{ rc_rh_6_message }}</b-tooltip>
              <b-tooltip v-if="!rc_rh_6" :show="validateTooltipStateKelembaban('_6')" :triggers="'hover'" target="input_rh_6" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("kelembaban_6") }}
              </b-tooltip>

              <b-form-input
                v-on:keypress="format_integer"
                id="input_rh_7"
                v-on:keyup="checkValidate($event, 'rh_7')"
                v-bind:class="{ rangecheck: rc_rh_7 }"
                v-on:keydown="onKeyCheck($event)"
                type="number"
                :state="validateState($v.kelembaban._7)"
                v-model.number="$v.kelembaban._7.$model"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_rh_7" target="input_rh_7" triggers="hover" placement="topleft">{{ rc_rh_7_message }}</b-tooltip>
              <b-tooltip v-if="!rc_rh_7" :show="validateTooltipStateKelembaban('_7')" :triggers="'hover'" target="input_rh_7" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("kelembaban_7") }}
              </b-tooltip>

              <b-form-input
                v-on:keypress="format_integer"
                id="input_rh_8"
                v-on:keyup="checkValidate($event, 'rh_8')"
                v-bind:class="{ rangecheck: rc_rh_8 }"
                v-on:keydown="onKeyCheck($event)"
                type="number"
                :state="validateState($v.kelembaban._8)"
                v-model.number="$v.kelembaban._8.$model"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_rh_8" target="input_rh_8" triggers="hover" placement="topleft">{{ rc_rh_8_message }}</b-tooltip>
              <b-tooltip v-if="!rc_rh_8" :show="validateTooltipStateKelembaban('_8')" :triggers="'hover'" target="input_rh_8" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("kelembaban_8") }}
              </b-tooltip>

              <b-form-input
                v-on:keypress="format_integer"
                id="input_rh_9"
                v-on:keyup="checkValidate($event, 'rh_9')"
                v-bind:class="{ rangecheck: rc_rh_9 }"
                v-on:keydown="onKeyCheck($event)"
                type="number"
                :state="validateState($v.kelembaban._9)"
                v-model.number="$v.kelembaban._9.$model"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_rh_9" target="input_rh_9" triggers="hover" placement="topleft">{{ rc_rh_9_message }}</b-tooltip>
              <b-tooltip v-if="!rc_rh_9" :show="validateTooltipStateKelembaban('_9')" :triggers="'hover'" target="input_rh_9" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("kelembaban_9") }}
              </b-tooltip>

              <b-form-input
                v-on:keypress="format_integer"
                id="input_rh_10"
                v-on:keyup="checkValidate($event, 'rh_10')"
                v-bind:class="{ rangecheck: rc_rh_10 }"
                v-on:keydown="onKeyCheck($event)"
                type="number"
                :state="validateState($v.kelembaban._10)"
                v-model.number="$v.kelembaban._10.$model"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_rh_10" target="input_rh_10" triggers="hover" placement="topleft">{{ rc_rh_10_message }}</b-tooltip>
              <b-tooltip v-if="!rc_rh_10" :show="validateTooltipStateKelembaban('_10')" :triggers="'hover'" target="input_rh_10" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("kelembaban_10") }}
              </b-tooltip>

              <b-form-input
                v-on:keypress="format_integer"
                id="input_rh_11"
                v-on:keyup="checkValidate($event, 'rh_11')"
                v-bind:class="{ rangecheck: rc_rh_11 }"
                v-on:keydown="onKeyCheck($event)"
                type="number"
                :state="validateState($v.kelembaban._11)"
                v-model.number="$v.kelembaban._11.$model"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_rh_11" target="input_rh_11" triggers="hover" placement="topleft">{{ rc_rh_11_message }}</b-tooltip>
              <b-tooltip v-if="!rc_rh_11" :show="validateTooltipStateKelembaban('_11')" :triggers="'hover'" target="input_rh_11" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("kelembaban_11") }}
              </b-tooltip>

              <b-form-input
                v-on:keypress="format_integer"
                id="input_rh_12"
                v-on:keyup="checkValidate($event, 'rh_12')"
                v-bind:class="{ rangecheck: rc_rh_12 }"
                v-on:keydown="onKeyCheck($event)"
                type="number"
                :state="validateState($v.kelembaban._12)"
                v-model.number="$v.kelembaban._12.$model"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_rh_12" target="input_rh_12" triggers="hover" placement="topleft">{{ rc_rh_12_message }}</b-tooltip>
              <b-tooltip v-if="!rc_rh_12" :show="validateTooltipStateKelembaban('_12')" :triggers="'hover'" target="input_rh_12" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("kelembaban_12") }}
              </b-tooltip>
            </b-col>

            <b-col sm="2 mb-1 ml-3">
              <div>
                <label for="input60" class="label-entry1"
                  >13:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban dari Thermohigrograph pukul 13.00 WS"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >14:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban dari Thermohigrograph pukul 14.00 WS"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >15:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban dari Thermohigrograph pukul 15.00 WS"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >16:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban dari Thermohigrograph pukul 16.00 WS"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >17:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban dari Thermohigrograph pukul 17.00 WS"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >18:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban dari Thermohigrograph pukul 18.00 WS"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >19:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban dari Thermohigrograph pukul 19.00 WS"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >20:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban dari Thermohigrograph pukul 20.00 WS"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >21:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban dari Thermohigrograph pukul 21.00 WS"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >22:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban dari Thermohigrograph pukul 22.00 WS"
                /></label>
              </div>
              <div>
                <label for="input30" class="label-entry1"
                  >23:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban dari Thermohigrograph pukul 23.00 WS"
                /></label>
              </div>
              <div>
                <label for="input30" class="label-entry1"
                  >24:00 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban dari Thermohigrograph pukul 24.00 WS"
                /></label>
              </div>
            </b-col>

            <b-col sm="3 mb-1">
              <b-form-input
                v-on:keypress="format_integer"
                id="input_rh_13"
                v-on:keyup="checkValidate($event, 'rh_13')"
                v-bind:class="{ rangecheck: rc_rh_13 }"
                v-on:keydown="onKeyCheck($event)"
                type="number"
                :state="validateState($v.kelembaban._13)"
                v-model.number="$v.kelembaban._13.$model"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_rh_13" target="input_rh_13" triggers="hover" placement="topleft">{{ rc_rh_13_message }}</b-tooltip>
              <b-tooltip v-if="!rc_rh_13" :show="validateTooltipStateKelembaban('_13')" :triggers="'hover'" target="input_rh_13" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("kelembaban_13") }}
              </b-tooltip>
              <b-form-input
                v-on:keypress="format_integer"
                id="input_rh_14"
                v-on:keyup="checkValidate($event, 'rh_14')"
                v-bind:class="{ rangecheck: rc_rh_14 }"
                v-on:keydown="onKeyCheck($event)"
                type="number"
                :state="validateState($v.kelembaban._14)"
                v-model.number="$v.kelembaban._14.$model"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_rh_14" target="input_rh_14" triggers="hover" placement="topleft">{{ rc_rh_14_message }}</b-tooltip>
              <b-tooltip v-if="!rc_rh_14" :show="validateTooltipStateKelembaban('_14')" :triggers="'hover'" target="input_rh_14" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("kelembaban_14") }}
              </b-tooltip>
              <b-form-input
                v-on:keypress="format_integer"
                id="input_rh_15"
                v-on:keyup="checkValidate($event, 'rh_15')"
                v-bind:class="{ rangecheck: rc_rh_15 }"
                v-on:keydown="onKeyCheck($event)"
                type="number"
                :state="validateState($v.kelembaban._15)"
                v-model.number="$v.kelembaban._15.$model"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_rh_15" target="input_rh_15" triggers="hover" placement="topleft">{{ rc_rh_15_message }}</b-tooltip>
              <b-tooltip v-if="!rc_rh_15" :show="validateTooltipStateKelembaban('_15')" :triggers="'hover'" target="input_rh_15" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("kelembaban_15") }}
              </b-tooltip>
              <b-form-input
                v-on:keypress="format_integer"
                id="input_rh_16"
                v-on:keyup="checkValidate($event, 'rh_16')"
                v-bind:class="{ rangecheck: rc_rh_16 }"
                v-on:keydown="onKeyCheck($event)"
                type="number"
                :state="validateState($v.kelembaban._16)"
                v-model.number="$v.kelembaban._16.$model"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_rh_16" target="input_rh_16" triggers="hover" placement="topleft">{{ rc_rh_16_message }}</b-tooltip>
              <b-tooltip v-if="!rc_rh_16" :show="validateTooltipStateKelembaban('_16')" :triggers="'hover'" target="input_rh_16" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("kelembaban_16") }}
              </b-tooltip>
              <b-form-input
                v-on:keypress="format_integer"
                id="input_rh_17"
                v-on:keyup="checkValidate($event, 'rh_17')"
                v-bind:class="{ rangecheck: rc_rh_17 }"
                v-on:keydown="onKeyCheck($event)"
                type="number"
                :state="validateState($v.kelembaban._17)"
                v-model.number="$v.kelembaban._17.$model"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_rh_17" target="input_rh_17" triggers="hover" placement="topleft">{{ rc_rh_17_message }}</b-tooltip>
              <b-tooltip v-if="!rc_rh_17" :show="validateTooltipStateKelembaban('_17')" :triggers="'hover'" target="input_rh_17" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("kelembaban_17") }}
              </b-tooltip>
              <b-form-input
                v-on:keypress="format_integer"
                id="input_rh_18"
                v-on:keyup="checkValidate($event, 'rh_18')"
                v-bind:class="{ rangecheck: rc_rh_18 }"
                v-on:keydown="onKeyCheck($event)"
                type="number"
                :state="validateState($v.kelembaban._18)"
                v-model.number="$v.kelembaban._18.$model"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_rh_18" target="input_rh_18" triggers="hover" placement="topleft">{{ rc_rh_18_message }}</b-tooltip>
              <b-tooltip v-if="!rc_rh_18" :show="validateTooltipStateKelembaban('_18')" :triggers="'hover'" target="input_rh_18" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("kelembaban_18") }}
              </b-tooltip>
              <b-form-input
                v-on:keypress="format_integer"
                id="input_rh_19"
                v-on:keyup="checkValidate($event, 'rh_19')"
                v-bind:class="{ rangecheck: rc_rh_19 }"
                v-on:keydown="onKeyCheck($event)"
                type="number"
                :state="validateState($v.kelembaban._19)"
                v-model.number="$v.kelembaban._19.$model"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_rh_19" target="input_rh_19" triggers="hover" placement="topleft">{{ rc_rh_19_message }}</b-tooltip>
              <b-tooltip v-if="!rc_rh_19" :show="validateTooltipStateKelembaban('_19')" :triggers="'hover'" target="input_rh_19" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("kelembaban_19") }}
              </b-tooltip>
              <b-form-input
                v-on:keypress="format_integer"
                id="input_rh_20"
                v-on:keyup="checkValidate($event, 'rh_20')"
                v-bind:class="{ rangecheck: rc_rh_20 }"
                v-on:keydown="onKeyCheck($event)"
                type="number"
                :state="validateState($v.kelembaban._20)"
                v-model.number="$v.kelembaban._20.$model"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_rh_20" target="input_rh_20" triggers="hover" placement="topleft">{{ rc_rh_20_message }}</b-tooltip>
              <b-tooltip v-if="!rc_rh_20" :show="validateTooltipStateKelembaban('_20')" :triggers="'hover'" target="input_rh_20" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("kelembaban_20") }}
              </b-tooltip>
              <b-form-input
                v-on:keypress="format_integer"
                id="input_rh_21"
                v-on:keyup="checkValidate($event, 'rh_21')"
                v-bind:class="{ rangecheck: rc_rh_21 }"
                v-on:keydown="onKeyCheck($event)"
                type="number"
                :state="validateState($v.kelembaban._21)"
                v-model.number="$v.kelembaban._21.$model"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_rh_21" target="input_rh_21" triggers="hover" placement="topleft">{{ rc_rh_21_message }}</b-tooltip>
              <b-tooltip v-if="!rc_rh_21" :show="validateTooltipStateKelembaban('_21')" :triggers="'hover'" target="input_rh_21" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("kelembaban_21") }}
              </b-tooltip>
              <b-form-input
                v-on:keypress="format_integer"
                id="input_rh_22"
                v-on:keyup="checkValidate($event, 'rh_22')"
                v-bind:class="{ rangecheck: rc_rh_22 }"
                v-on:keydown="onKeyCheck($event)"
                type="number"
                :state="validateState($v.kelembaban._22)"
                v-model.number="$v.kelembaban._22.$model"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_rh_22" target="input_rh_22" triggers="hover" placement="topleft">{{ rc_rh_22_message }}</b-tooltip>
              <b-tooltip v-if="!rc_rh_22" :show="validateTooltipStateKelembaban('_22')" :triggers="'hover'" target="input_rh_22" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("kelembaban_22") }}
              </b-tooltip>
              <b-form-input
                v-on:keypress="format_integer"
                id="input_rh_23"
                v-on:keyup="checkValidate($event, 'rh_23')"
                v-bind:class="{ rangecheck: rc_rh_23 }"
                v-on:keydown="onKeyCheck($event)"
                type="number"
                :state="validateState($v.kelembaban._23)"
                v-model.number="$v.kelembaban._23.$model"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_rh_23" target="input_rh_23" triggers="hover" placement="topleft">{{ rc_rh_23_message }}</b-tooltip>
              <b-tooltip v-if="!rc_rh_23" :show="validateTooltipStateKelembaban('_23')" :triggers="'hover'" target="input_rh_23" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("kelembaban_23") }}
              </b-tooltip>
              <b-form-input
                v-on:keypress="format_integer"
                id="input_rh_24"
                v-on:keyup="checkValidate($event, 'rh_24')"
                v-bind:class="{ rangecheck: rc_rh_24 }"
                v-on:keydown="onKeyCheck($event)"
                type="number"
                :state="validateState($v.kelembaban._24)"
                v-model.number="$v.kelembaban._24.$model"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_rh_24" target="input_rh_24" triggers="hover" placement="topleft">{{ rc_rh_24_message }}</b-tooltip>
              <b-tooltip v-if="!rc_rh_24" :show="validateTooltipStateKelembaban('_24')" :triggers="'hover'" target="input_rh_24" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("kelembaban_24") }}
              </b-tooltip>
            </b-col>
            <b-row>
              <b-col sm="4" style="padding-left: 50px">
                <div>
                  <label for="input60" class="label-entry bg-light-primary font-weight-bolder"
                    >Maksimum <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu maksimum dari Thermohigrograph"
                  /></label>
                </div>
              </b-col>
              <b-col sm="3 ">
                <div>
                  <label for="input60" class="label-entry bg-light-primary font-weight-bolder"
                    >Minimum <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Suhu Minimum dari Thermohigrograph"
                  /></label>
                </div>
              </b-col>
              <b-col sm="4 ">
                <div>
                  <label for="input60" class="label-entry bg-light-primary font-weight-bolder"
                    >Rata-rata <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Rata-rata dari Thermohigrograph"
                  /></label>
                </div>
              </b-col>

              <b-col sm="1 "></b-col>

              <b-col sm="4 " style="padding-left: 50px">
                <b-form-input type="number" v-model.number="kelembaban_max" id="input10" size="sm" class="text-center mb-2" placeholder="" readonly></b-form-input>
              </b-col>
              <b-col sm="3 ">
                <b-form-input type="number" v-model.number="kelembaban_min" id="input10" size="sm" class="text-center mb-2" placeholder="" readonly></b-form-input>
              </b-col>
              <b-col sm="4 ">
                <b-form-input type="number" v-model.number="kelembaban_rata" id="input10" size="sm" class="text-center bg-light-success mb-2" placeholder="" readonly></b-form-input>
              </b-col>
            </b-row>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-n2">
      <b-col cols="12">
        <b-card>
          <b-row class="float-right">
            <div>
              <b-button variant="danger" @click="resetForm" class="mr-1" size="sm"><b-icon icon="x-circle-fill"></b-icon> Cancel</b-button>
              <b-button variant="warning" @click="V_Form" v-if="this.t_data_status == 'update'" class="mr-1" size="sm" :disabled="!roles.isRoleEdit"><b-icon icon="pencil-fill"></b-icon> Update</b-button>
              <b-button variant="primary" @click="V_Form" v-if="this.t_data_status == 'insert'" class="mr-1" size="sm" :disabled="!roles.isRoleAdd"><b-icon icon="cursor-fill"></b-icon> Submit</b-button>
            </div>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { BRow, BCol, BTable, BCardText, BFormInput, BContainer, BButton, BCard, VBTooltip, BTooltip } from "bootstrap-vue";
import axios from "axios";
import Helper from "@/api/helper";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import error_handler from "@/api/error_handler";
import { validationMixin } from "vuelidate";
import { required, minLength, between } from "vuelidate/lib/validators";
import { V_TermohigrografSuhu, V_TermohigrografKelebaban, GC_PIAS } from "@/validators/piasvalidator";
import Ripple from "vue-ripple-directive";
import Params from "@/attr/metadata/params";
import RC_helper from "@/validators/RC_helper";
import qc_helper from "@/api/qc_helper";

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BTable,
    BCardText,
    BFormInput,
    BContainer,
    BButton,
    BCard,
    VBTooltip,
    BTooltip,
  },
  props: ["t_station", "t_wmoid", "qc_thermo", "t_observer", "t_date", "t_station_id", "t_path", "t_observer_name", "t_data", "t_id_form", "t_data_status", "t_path_update", "roles", "count_form_click", "data_qc_auto"],
  mixins: [validationMixin],
  validations: {
    suhu: {
      _1: { required, V_TermohigrografSuhu },
      _2: { required, V_TermohigrografSuhu },
      _3: { required, V_TermohigrografSuhu },
      _4: { required, V_TermohigrografSuhu },
      _5: { required, V_TermohigrografSuhu },
      _6: { required, V_TermohigrografSuhu },
      _7: { required, V_TermohigrografSuhu },
      _8: { required, V_TermohigrografSuhu },
      _9: { required, V_TermohigrografSuhu },
      _10: { required, V_TermohigrografSuhu },
      _11: { required, V_TermohigrografSuhu },
      _12: { required, V_TermohigrografSuhu },
      _13: { required, V_TermohigrografSuhu },
      _14: { required, V_TermohigrografSuhu },
      _15: { required, V_TermohigrografSuhu },
      _16: { required, V_TermohigrografSuhu },
      _17: { required, V_TermohigrografSuhu },
      _18: { required, V_TermohigrografSuhu },
      _19: { required, V_TermohigrografSuhu },
      _20: { required, V_TermohigrografSuhu },
      _21: { required, V_TermohigrografSuhu },
      _22: { required, V_TermohigrografSuhu },
      _23: { required, V_TermohigrografSuhu },
      _24: { required, V_TermohigrografSuhu },
    },
    kelembaban: {
      _1: { required, V_TermohigrografKelebaban },
      _2: { required, V_TermohigrografKelebaban },
      _3: { required, V_TermohigrografKelebaban },
      _4: { required, V_TermohigrografKelebaban },
      _5: { required, V_TermohigrografKelebaban },
      _6: { required, V_TermohigrografKelebaban },
      _7: { required, V_TermohigrografKelebaban },
      _8: { required, V_TermohigrografKelebaban },
      _9: { required, V_TermohigrografKelebaban },
      _10: { required, V_TermohigrografKelebaban },
      _11: { required, V_TermohigrografKelebaban },
      _12: { required, V_TermohigrografKelebaban },
      _13: { required, V_TermohigrografKelebaban },
      _14: { required, V_TermohigrografKelebaban },
      _15: { required, V_TermohigrografKelebaban },
      _16: { required, V_TermohigrografKelebaban },
      _17: { required, V_TermohigrografKelebaban },
      _18: { required, V_TermohigrografKelebaban },
      _19: { required, V_TermohigrografKelebaban },
      _20: { required, V_TermohigrografKelebaban },
      _21: { required, V_TermohigrografKelebaban },
      _22: { required, V_TermohigrografKelebaban },
      _23: { required, V_TermohigrografKelebaban },
      _24: { required, V_TermohigrografKelebaban },
    },
  },

  data() {
    return {
      suhu: {
        _1: 0,
        _2: 0,
        _3: 0,
        _4: 0,
        _5: 0,
        _6: 0,
        _7: 0,
        _8: 0,
        _9: 0,
        _10: 0,
        _11: 0,
        _12: 0,
        _13: 0,
        _14: 0,
        _15: 0,
        _16: 0,
        _17: 0,
        _18: 0,
        _19: 0,
        _20: 0,
        _21: 0,
        _22: 0,
        _23: 0,
        _24: 0,
      },
      kelembaban: {
        _1: 0,
        _2: 0,
        _3: 0,
        _4: 0,
        _5: 0,
        _6: 0,
        _7: 0,
        _8: 0,
        _9: 0,
        _10: 0,
        _11: 0,
        _12: 0,
        _13: 0,
        _14: 0,
        _15: 0,
        _16: 0,
        _17: 0,
        _18: 0,
        _19: 0,
        _20: 0,
        _21: 0,
        _22: 0,
        _23: 0,
        _24: 0,
      },
      status: "",
      id_thermo_update: "",
      pesan: "",
      last_qc_histories: {},
      RC: {},
      RC_helper: RC_helper,
      RC_RH: null,
      RC_T: null,
      //RC RH
      rc_rh_1: false,
      rc_rh_1_message: "",
      rc_rh_2: false,
      rc_rh_2_message: "",
      rc_rh_3: false,
      rc_rh_3_message: "",
      rc_rh_4: false,
      rc_rh_4_message: "",
      rc_rh_5: false,
      rc_rh_5_message: "",
      rc_rh_6: false,
      rc_rh_6_message: "",
      rc_rh_7: false,
      rc_rh_7_message: "",
      rc_rh_8: false,
      rc_rh_8_message: "",
      rc_rh_9: false,
      rc_rh_9_message: "",
      rc_rh_10: false,
      rc_rh_10_message: "",

      rc_rh_11: false,
      rc_rh_11_message: "",
      rc_rh_12: false,
      rc_rh_12_message: "",
      rc_rh_13: false,
      rc_rh_13_message: "",
      rc_rh_14: false,
      rc_rh_14_message: "",
      rc_rh_15: false,
      rc_rh_15_message: "",
      rc_rh_16: false,
      rc_rh_16_message: "",
      rc_rh_17: false,
      rc_rh_17_message: "",
      rc_rh_18: false,
      rc_rh_18_message: "",
      rc_rh_19: false,
      rc_rh_19_message: "",
      rc_rh_20: false,
      rc_rh_20_message: "",

      rc_rh_21: false,
      rc_rh_21_message: "",
      rc_rh_22: false,
      rc_rh_22_message: "",
      rc_rh_23: false,
      rc_rh_23_message: "",
      rc_rh_24: false,
      rc_rh_24_message: "",

      //RC SUHU
      rc_t_1: false,
      rc_t_1_message: "",
      rc_t_2: false,
      rc_t_2_message: "",
      rc_t_3: false,
      rc_t_3_message: "",
      rc_t_4: false,
      rc_t_4_message: "",
      rc_t_5: false,
      rc_t_5_message: "",
      rc_t_6: false,
      rc_t_6_message: "",
      rc_t_7: false,
      rc_t_7_message: "",
      rc_t_8: false,
      rc_t_8_message: "",
      rc_t_9: false,
      rc_t_9_message: "",
      rc_t_10: false,
      rc_t_10_message: "",

      rc_t_11: false,
      rc_t_11_message: "",
      rc_t_12: false,
      rc_t_12_message: "",
      rc_t_13: false,
      rc_t_13_message: "",
      rc_t_14: false,
      rc_t_14_message: "",
      rc_t_15: false,
      rc_t_15_message: "",
      rc_t_16: false,
      rc_t_16_message: "",
      rc_t_17: false,
      rc_t_17_message: "",
      rc_t_18: false,
      rc_t_18_message: "",
      rc_t_19: false,
      rc_t_19_message: "",
      rc_t_20: false,
      rc_t_20_message: "",

      rc_t_21: false,
      rc_t_21_message: "",
      rc_t_22: false,
      rc_t_22_message: "",
      rc_t_23: false,
      rc_t_23_message: "",
      rc_t_24: false,
      rc_t_24_message: "",

      field_name: {
        // rh
        rh_1: "rh_higro_01",
        rh_2: "rh_higro_02",
        rh_3: "rh_higro_03",
        rh_4: "rh_higro_04",
        rh_5: "rh_higro_05",
        rh_6: "rh_higro_06",
        rh_7: "rh_higro_07",
        rh_8: "rh_higro_08",
        rh_9: "rh_higro_09",
        rh_10: "rh_higro_10",
        rh_11: "rh_higro_11",
        rh_12: "rh_higro_12",
        rh_13: "rh_higro_13",
        rh_14: "rh_higro_14",
        rh_15: "rh_higro_15",
        rh_16: "rh_higro_16",
        rh_17: "rh_higro_17",
        rh_18: "rh_higro_18",
        rh_19: "rh_higro_19",
        rh_20: "rh_higro_20",
        rh_21: "rh_higro_21",
        rh_22: "rh_higro_22",
        rh_23: "rh_higro_23",
        rh_24: "rh_higro_24",
        // suhu
        t_1: "t_thermo_01",
        t_2: "t_thermo_02",
        t_3: "t_thermo_03",
        t_4: "t_thermo_04",
        t_5: "t_thermo_05",
        t_6: "t_thermo_06",
        t_7: "t_thermo_07",
        t_8: "t_thermo_08",
        t_9: "t_thermo_09",
        t_10: "t_thermo_10",
        t_11: "t_thermo_11",
        t_12: "t_thermo_12",
        t_13: "t_thermo_13",
        t_14: "t_thermo_14",
        t_15: "t_thermo_15",
        t_16: "t_thermo_16",
        t_17: "t_thermo_17",
        t_18: "t_thermo_18",
        t_19: "t_thermo_19",
        t_20: "t_thermo_20",
        t_21: "t_thermo_21",
        t_22: "t_thermo_22",
        t_23: "t_thermo_23",
        t_24: "t_thermo_24",
      },
      qc_flag: 0,
      qc_flag_before: 0,
      RC_Before: null,
      RC_After: null,
      RC_Current: null,
      QC_Histories: "",
    };
  },
  mounted() {
    // console.log("t_path : ", this.t_path);
    // console.log("t_data : ", this.t_data);
    // console.log("t_id_form : ", this.t_id_form);
    // console.log("t_data_status : ", this.t_data_status);
    // console.log("t_path_update : ", this.t_path_update);
    // console.log("auto QC : ",this.data_qc_auto)
    var autoQC = this.data_qc_auto;
    var RHminQC = autoQC.qc_autorumus_relative_humidity_pc_min;
    var RHmaxQC = autoQC.qc_autorumus_relative_humidity_pc_max;
    if (RHminQC != null) {
      RHminQC = Math.floor(RHminQC);
    }
    if (RHmaxQC != null) {
      RHmaxQC = Math.floor(RHmaxQC);
    }

    var TminQC = autoQC.qc_autorumus_temp_drybulb_c_tttttt_min;
    var TmaxQC = autoQC.qc_autorumus_temp_drybulb_c_tttttt_max;
    // if(TminQC != null){
    //   TminQC = Math.floor(TminQC);
    // }
    // if(TmaxQC != null){
    //   TmaxQC = Math.floor(TmaxQC);
    // }

    this.RC_RH = {
      min: RHminQC,
      max: RHmaxQC,
    };
    this.RC_T = {
      min: TminQC,
      max: TmaxQC,
    };

    // console.log("RH : ",this.RC_RH);
    // console.log("Suhu : ",this.RC_T);
    if (this.t_data_status == "update") {
      this.popupData();
    } else {
      this.resetForm();
      this.$v.$reset();
    }
  },
  watch: {
    t_station(value) {
      console.log("ubah station : ", this.t_path);
    },
    t_data_status(newValue, OldValue) {
      console.log("new value : ", newValue);
      if (newValue == "update") {
        this.popupData();
      } else {
        this.resetForm();
        this.$v.$reset();
      }
    },
    t_date(newVal, OldVal) {
      if (newVal != OldVal) {
        this.$v.$reset();
      }
    },
    count_form_click(NewValue, OldValue) {
      if (this.t_data_status == "insert") {
        if (NewValue != OldValue) {
          this.resetForm();
        }
      }
    },
  },
  computed: {
    suhu_max: function () {
      let data = Object.values(this.suhu);
      let same9999 = data.every((val, i, arr) => val === 9999);
      let same8888 = data.every((val, i, arr) => val === 8888);
      if (same9999) {
        return parseInt(9999);
      } else if (same8888) {
        return parseInt(8888);
      } else {
        return Math.max(...Object.values(this.suhu).filter((val) => val !== 9999 && val !== 8888));
      }
    },
    suhu_min: function () {
      let data = Object.values(this.suhu);
      let same9999 = data.every((val, i, arr) => val === 9999);
      let same8888 = data.every((val, i, arr) => val === 8888);
      if (same9999) {
        return parseInt(9999);
      } else if (same8888) {
        return parseInt(8888);
      } else {
        return Math.min(...Object.values(this.suhu).filter((val) => val !== 9999 && val !== 8888));
      }
    },
    suhu_rata: function () {
      let data = Object.values(this.suhu);
      let same9999 = data.every((val, i, arr) => val === 9999);
      let same8888 = data.every((val, i, arr) => val === 8888);
      if (same9999) {
        return parseInt(9999);
      } else if (same8888) {
        return parseInt(8888);
      } else {
        let values = Object.values(this.suhu).filter((val) => val !== 9999 && val !== 8888);
        // return parseFloat(values.reduce((a, b) => a + b) / values.length).toFixed(1);
        let result = 0;
        for (var i in values) {
          let nilai = values[i] != "" ? values[i] : 0;
          result += nilai;
        }
        // console.log("result : ",result);
        let rata2 = parseFloat(result / values.length).toFixed(1);
        return rata2;
      }
    },
    kelembaban_max: function () {
      let data = Object.values(this.kelembaban);
      let same9999 = data.every((val, i, arr) => val === 9999);
      let same8888 = data.every((val, i, arr) => val === 8888);
      if (same9999) {
        return parseInt(9999);
      } else if (same8888) {
        return parseInt(8888);
      } else {
        return Math.max(...Object.values(this.kelembaban).filter((val) => val !== 9999 && val !== 8888));
      }
    },
    kelembaban_min: function () {
      let data = Object.values(this.kelembaban);
      let same9999 = data.every((val, i, arr) => val === 9999);
      let same8888 = data.every((val, i, arr) => val === 8888);
      if (same9999) {
        return parseInt(9999);
      } else if (same8888) {
        return parseInt(8888);
      } else {
        return Math.min(...Object.values(this.kelembaban).filter((val) => val !== 9999 && val !== 8888));
      }
    },
    kelembaban_rata: function () {
      let data = Object.values(this.kelembaban);
      let same9999 = data.every((val, i, arr) => val === 9999);
      let same8888 = data.every((val, i, arr) => val === 8888);
      if (same9999) {
        return parseInt(9999);
      } else if (same8888) {
        return parseInt(8888);
      } else {
        let values = Object.values(this.kelembaban).filter((val) => val !== 9999 && val !== 8888);
        // return parseFloat(values.reduce((a, b) => a + b) / values.length).toFixed(1);
        let result = 0;
        for (var i in values) {
          let nilai = values[i] != "" ? values[i] : 0;
          result += nilai;
        }
        // console.log("result : ",result);
        let rata2 = parseFloat(result / values.length).toFixed(1);
        return rata2;
      }
    },
  },
  methods: {
    async setQCData() {
      console.log("call set data qc");
      const qc_data = await metadata.getQCData(this.path_hujan, Params.GET_QC_STATION_KEY("form_hujan"));
      const qc_params = Params.GET_PARAMS("form_hujan");
      const qc_key = Params.GET_KEY("form_hujan");
      this.RC_helper.setData(qc_data, qc_params, qc_key);
    },
    checkRC(value, type) {
      let status = true;
      let message = "";
      // console.log("his.RC_RH : ",this.RC_RH)
      if (type == "RH") {
        if (this.RC_RH == null) {
          status = true;
        } else {
          let max = this.RC_RH.max;
          let min = this.RC_RH.min;
          if (min == null && max == null) {
            status = true;
          } else if (min == null && max != null) {
            if (value <= max) {
              status = true;
            } else {
              status = false;
              message = "Range Check - Batas Bawah , Batas Atas " + max;
            }
          } else if (min != null && max == null) {
            if (value >= min) {
              status = true;
            } else {
              status = false;
              message = "Range Check - Batas Bawah " + min + ", Batas Atas ";
            }
          } else {
            if (value >= min && value <= max) {
              status = true;
            } else if (value == 9999 || value == 8888) {
              status = true;
            } else if (value == "") {
              status = true;
            } else {
              status = false;
              message = "Range Check - Batas Bawah " + min + ", Batas Atas " + max;
            }
          }
        }
      } else if (type == "T") {
        if (this.RC_T == null) {
          status = true;
        } else {
          let max = this.RC_T.max;
          let min = this.RC_T.min;
          if (min == null && max == null) {
            status = true;
          } else if (min == null && max != null) {
            if (value <= max) {
              status = true;
            } else {
              status = false;
              message = "Range Check - Batas Bawah , Batas Atas " + max;
            }
          } else if (min != null && max == null) {
            if (value >= min) {
              status = true;
            } else {
              status = false;
              message = "Range Check - Batas Bawah " + min + ", Batas Atas ";
            }
          } else {
            if (value >= min && value <= max) {
              status = true;
            } else if (value == 9999 || value == 8888) {
              status = true;
            } else {
              status = false;
              message = "Range Check - Batas Bawah " + min + ", Batas Atas " + max;
            }
          }
        }
      }

      return { status: status, message: message };
    },
    checkValidate: function (event, type) {
      // console.log("check validate  : ",type)
      let value = event.target.value;
      let type_field = this.field_name[type];
      switch (type) {
        // RC RH
        case "rh_1":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_1 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_1_message = raw.message;
          // console.log("Rc rh 1", this.rc_rh_1)
          break;
        case "rh_2":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_2 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_2_message = raw.message;
          break;
        case "rh_3":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_3 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_3_message = raw.message;
          break;
        case "rh_4":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_4 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_4_message = raw.message;
          break;
        case "rh_5":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_5 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_5_message = raw.message;
          break;
        case "rh_6":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_6 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_6_message = raw.message;
          break;
        case "rh_7":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_7 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_7_message = raw.message;
          break;
        case "rh_8":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_8 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_8_message = raw.message;
          break;
        case "rh_9":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_9 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_9_message = raw.message;
          break;
        case "rh_10":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_10 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_10_message = raw.message;
          break;
        case "rh_11":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_11 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_11_message = raw.message;
          break;
        case "rh_12":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_12 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_12_message = raw.message;
          break;
        case "rh_13":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_13 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_13_message = raw.message;
          break;
        case "rh_14":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_14 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_14_message = raw.message;
          break;
        case "rh_15":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_15 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_15_message = raw.message;
          break;
        case "rh_16":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_16 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_16_message = raw.message;
          break;
        case "rh_17":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_17 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_17_message = raw.message;
          break;
        case "rh_18":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_18 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_18_message = raw.message;
          break;
        case "rh_19":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_19 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_19_message = raw.message;
          break;
        case "rh_20":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_20 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_20_message = raw.message;
          break;
        case "rh_21":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_21 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_21_message = raw.message;
          break;
        case "rh_22":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_22 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_22_message = raw.message;
          break;
        case "rh_23":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_23 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_23_message = raw.message;
          break;
        case "rh_24":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_24 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_24_message = raw.message;
          break;

        // RC Suhu
        case "t_1":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_1 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_1_message = raw.message;
          // console.log("Rc t 1", this.rc_t_1)
          break;
        case "t_2":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_2 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_2_message = raw.message;
          break;
        case "t_3":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_3 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_3_message = raw.message;
          break;
        case "t_4":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_4 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_4_message = raw.message;
          break;
        case "t_5":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_5 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_5_message = raw.message;
          break;
        case "t_6":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_6 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_6_message = raw.message;
          break;
        case "t_7":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_7 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_7_message = raw.message;
          break;
        case "t_8":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_8 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_8_message = raw.message;
          break;
        case "t_9":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_9 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_9_message = raw.message;
          break;
        case "t_10":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_10 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_10_message = raw.message;
          break;
        case "t_11":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_11 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_11_message = raw.message;
          break;
        case "t_12":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_12 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_12_message = raw.message;
          break;
        case "t_13":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_13 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_13_message = raw.message;
          break;
        case "t_14":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_14 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_14_message = raw.message;
          break;
        case "t_15":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_15 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_15_message = raw.message;
          break;
        case "t_16":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_16 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_16_message = raw.message;
          break;
        case "t_17":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_17 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_17_message = raw.message;
          break;
        case "t_18":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_18 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_18_message = raw.message;
          break;
        case "t_19":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_19 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_19_message = raw.message;
          break;
        case "t_20":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_20 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_20_message = raw.message;
          break;
        case "t_21":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_21 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_21_message = raw.message;
          break;
        case "t_22":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_22 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_22_message = raw.message;
          break;
        case "t_23":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_23 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_23_message = raw.message;
          break;
        case "t_24":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_24 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_24_message = raw.message;
          break;
        default:
          break;
      }
      this.RC[type_field] = {
        status: gc && !raw.status,
        message: raw.message,
        value: value,
      };
    },
    onKeyCheck(event) {
      console.log("onKeyCheck", event);
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault();
      }
    },
    format_integer(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 47 && charCode < 58) {
        return true;
      } else {
        evt.preventDefault();
      }
    },
    format_one_decimal(e) {
      if (Math.round(e * 10) / 10 != e) {
        let text = String(e);
        let filter = text.substring(0, text.length - 1);
        return filter;
      } else {
        if (Math.floor(e) === e) {
          return e;
        } else {
          if (e.toString().indexOf(".") > 0) {
            let count = e.toString().split(".")[1].length || 0;
            return count > 1 ? parseFloat(e).toFixed(1) : e;
          } else {
            return e;
          }
        }
        return e;
      }
    },
    //validate tooltips GC
    tooltipMessage(attr) {
      return GC_PIAS(attr).message;
    },
    validateTooltipStateSuhu(name) {
      // const { $error } = this.$v.suhu[name];
      // return $error;
      return false;
    },
    validateTooltipStateKelembaban(name) {
      // const { $error } = this.$v.kelembaban[name];
      // return $error;
      return false;
    },
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    V_Form() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Data tidak valid. Periksa kembali inputan anda",
            icon: "BellIcon",
            variant: "danger",
          },
        });
        return;
      } else {
        console.log("data RC: ", this.RC);
        let dataRC = Object.entries(this.RC).filter((e) => e[1].status);
        let temp_QC_After = [];
        for (var i in dataRC) {
          let row_qc_after = {
            raw_type: "Thermohigrograph",
            atribut: dataRC[i][0],
            value: dataRC[i][1].value,
            roles: dataRC[i][1].message,
            type: "range_check",
            message: dataRC[i][1].message,
          };
          temp_QC_After.push(row_qc_after);
        }

        // console.log("QC  AFTER : ",temp_QC_After);
        // this.last_qc_histories["before"] = "-";
        this.last_qc_histories["after"] = temp_QC_After;
        // console.log("QC String : ",JSON.stringify(this.last_qc_histories))
        if (dataRC.length > 0) {
          this.$swal({
            title: "Apakah Anda Yakin?",
            text: "Ada Data Terkena Range Check ",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Submit",
            cancelButtonText: "Cek Data",
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              if (this.t_data_status == "insert") {
                this.submitThemohigoghraph();
              } else if (this.t_data_status == "update") {
                this.updateData();
              }
            } else if (result.dismiss === "cancel") {
              return;
            }
          });
        } else {
          if (this.t_data_status == "insert") {
            this.submitThemohigoghraph();
          } else if (this.t_data_status == "update") {
            this.updateData();
          }
        }
      }
    },
    flagStatus(type) {
      let qc_flag_temp = 0;
      if (type == "insert") {
        if (this.RC.length > 0) {
          qc_flag_temp = 0;
        } else {
          qc_flag_temp = 1;
        }
      } else {
        if (this.RC.length > 0) {
          qc_flag_temp = 1;
        } else {
          if (this.qc_flag_before == 1) {
            qc_flag_temp = 2;
          } else if (this.qc_flag_before == 2) {
            qc_flag_temp = 2;
          } else {
            qc_flag_temp = 0;
          }
        }
      }
      return qc_flag_temp;
    },
    checkValidateEdit(value, type) {
      // console.log("check validate  : ",type)

      let type_field = this.field_name[type];
      switch (type) {
        // RC RH
        case "rh_1":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_1 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_1_message = raw.message;
          // console.log("Rc rh 1", this.rc_rh_1)
          break;
        case "rh_2":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_2 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_2_message = raw.message;
          break;
        case "rh_3":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_3 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_3_message = raw.message;
          break;
        case "rh_4":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_4 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_4_message = raw.message;
          break;
        case "rh_5":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_5 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_5_message = raw.message;
          break;
        case "rh_6":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_6 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_6_message = raw.message;
          break;
        case "rh_7":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_7 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_7_message = raw.message;
          break;
        case "rh_8":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_8 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_8_message = raw.message;
          break;
        case "rh_9":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_9 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_9_message = raw.message;
          break;
        case "rh_10":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_10 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_10_message = raw.message;
          break;
        case "rh_11":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_11 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_11_message = raw.message;
          break;
        case "rh_12":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_12 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_12_message = raw.message;
          break;
        case "rh_13":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_13 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_13_message = raw.message;
          break;
        case "rh_14":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_14 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_14_message = raw.message;
          break;
        case "rh_15":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_15 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_15_message = raw.message;
          break;
        case "rh_16":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_16 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_16_message = raw.message;
          break;
        case "rh_17":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_17 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_17_message = raw.message;
          break;
        case "rh_18":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_18 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_18_message = raw.message;
          break;
        case "rh_19":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_19 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_19_message = raw.message;
          break;
        case "rh_20":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_20 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_20_message = raw.message;
          break;
        case "rh_21":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_21 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_21_message = raw.message;
          break;
        case "rh_22":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_22 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_22_message = raw.message;
          break;
        case "rh_23":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_23 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_23_message = raw.message;
          break;
        case "rh_24":
          var raw = this.checkRC(value, "RH");
          var gc = V_TermohigrografKelebaban(value);
          this.rc_rh_24 = !raw.status ? (!gc ? false : true) : false;
          this.rc_rh_24_message = raw.message;
          break;

        // RC Suhu
        case "t_1":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_1 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_1_message = raw.message;
          // console.log("Rc t 1", this.rc_t_1)
          break;
        case "t_2":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_2 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_2_message = raw.message;
          break;
        case "t_3":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_3 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_3_message = raw.message;
          break;
        case "t_4":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_4 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_4_message = raw.message;
          break;
        case "t_5":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_5 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_5_message = raw.message;
          break;
        case "t_6":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_6 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_6_message = raw.message;
          break;
        case "t_7":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_7 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_7_message = raw.message;
          break;
        case "t_8":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_8 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_8_message = raw.message;
          break;
        case "t_9":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_9 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_9_message = raw.message;
          break;
        case "t_10":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_10 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_10_message = raw.message;
          break;
        case "t_11":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_11 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_11_message = raw.message;
          break;
        case "t_12":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_12 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_12_message = raw.message;
          break;
        case "t_13":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_13 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_13_message = raw.message;
          break;
        case "t_14":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_14 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_14_message = raw.message;
          break;
        case "t_15":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_15 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_15_message = raw.message;
          break;
        case "t_16":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_16 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_16_message = raw.message;
          break;
        case "t_17":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_17 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_17_message = raw.message;
          break;
        case "t_18":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_18 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_18_message = raw.message;
          break;
        case "t_19":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_19 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_19_message = raw.message;
          break;
        case "t_20":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_20 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_20_message = raw.message;
          break;
        case "t_21":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_21 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_21_message = raw.message;
          break;
        case "t_22":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_22 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_22_message = raw.message;
          break;
        case "t_23":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_23 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_23_message = raw.message;
          break;
        case "t_24":
          var raw = this.checkRC(value, "T");
          var gc = V_TermohigrografSuhu(value);
          this.rc_t_24 = !raw.status ? (!gc ? false : true) : false;
          this.rc_t_24_message = raw.message;
          break;
        default:
          break;
      }
      this.RC[type_field] = {
        status: gc && !raw.status,
        message: raw.message,
        value: value,
      };
    },
    updateData() {
      // alert("modification_date")
      // const thermohigograph_id = this.t_station_id+"_thermohigograph_" + this.t_date;
      // return
      var len_qc_after = this.last_qc_histories["after"].length;
      var temp_qc_histories = {};
      if (this.QC_Histories != "" || this.QC_Histories != null) {
        temp_qc_histories = JSON.parse(this.QC_Histories);
      }
      console.log("TEMP QC : ", temp_qc_histories);
      this.last_qc_histories["before"] = [];
      if (temp_qc_histories != null) {
        if (temp_qc_histories["after"] != undefined) {
          this.last_qc_histories["before"] = temp_qc_histories["after"];
        }
      }

      // console.log("ada QC : ",len_qc_after);
      var flag_temp = 0;
      if (len_qc_after > 0) {
        flag_temp = 1;
      } else {
        if (this.qc_flag_before == 1) {
          flag_temp = 2;
        }
      }
      // console.log("QC FLAG : ",flag_temp);
      // console.log("QC HISTORIES : ",this.last_qc_histories);
      var qc_temp = JSON.stringify(this.last_qc_histories);
      const modification_date = this.t_date;
      const post_data = {
        // "@type": "Thermohigograph",
        // id: thermohigograph_id,
        t_thermo_01: this.suhu._1,
        t_thermo_02: this.suhu._2,
        t_thermo_03: this.suhu._3,
        t_thermo_04: this.suhu._4,
        t_thermo_05: this.suhu._5,
        t_thermo_06: this.suhu._6,
        t_thermo_07: this.suhu._7,
        t_thermo_08: this.suhu._8,
        t_thermo_09: this.suhu._9,
        t_thermo_10: this.suhu._10,
        t_thermo_11: this.suhu._11,
        t_thermo_12: this.suhu._12,
        t_thermo_13: this.suhu._13,
        t_thermo_14: this.suhu._14,
        t_thermo_15: this.suhu._15,
        t_thermo_16: this.suhu._16,
        t_thermo_17: this.suhu._17,
        t_thermo_18: this.suhu._18,
        t_thermo_19: this.suhu._19,
        t_thermo_20: this.suhu._20,
        t_thermo_21: this.suhu._21,
        t_thermo_22: this.suhu._22,
        t_thermo_23: this.suhu._23,
        t_thermo_24: this.suhu._24,
        tx_thermo: this.suhu_max,
        tn_thermo: this.suhu_min,
        t_avg_thermo: this.suhu_rata,
        rh_higro_01: this.kelembaban._1,
        rh_higro_02: this.kelembaban._2,
        rh_higro_03: this.kelembaban._3,
        rh_higro_04: this.kelembaban._4,
        rh_higro_05: this.kelembaban._5,
        rh_higro_06: this.kelembaban._6,
        rh_higro_07: this.kelembaban._7,
        rh_higro_08: this.kelembaban._8,
        rh_higro_09: this.kelembaban._9,
        rh_higro_10: this.kelembaban._10,
        rh_higro_11: this.kelembaban._11,
        rh_higro_12: this.kelembaban._12,
        rh_higro_13: this.kelembaban._13,
        rh_higro_14: this.kelembaban._14,
        rh_higro_15: this.kelembaban._15,
        rh_higro_16: this.kelembaban._16,
        rh_higro_17: this.kelembaban._17,
        rh_higro_18: this.kelembaban._18,
        rh_higro_19: this.kelembaban._19,
        rh_higro_20: this.kelembaban._20,
        rh_higro_21: this.kelembaban._21,
        rh_higro_22: this.kelembaban._22,
        rh_higro_23: this.kelembaban._23,
        rh_higro_24: this.kelembaban._24,
        rh_max_higro: this.kelembaban_max,
        rh_min_higro: this.kelembaban_min,
        rh_avg_higro: this.kelembaban_rata,
        modification_date: modification_date,
        observer_id: this.t_observer,
        qc_flag: flag_temp,
        qc_histories: qc_temp,
      };
      console.log("UPDATE DATA : ", post_data);
      const update_url = Helper.getBaseUrl() + this.t_path_update;
      axios
        .patch(update_url, post_data, Helper.getConfig())
        .then((response) => {
          this.$swal({
            title: "Update data sukses!",
            text: "Data Telah berhasil di simpan",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.resetForm();
        })
        .catch((error) => {
          error_handler(this, error, "Thermohigograph", "Edit Data Error");
        });
      var arr_path = this.t_path.split("/");
      var reg_id = arr_path[1];
      qc_helper.editQCNotification(
        this.id_thermo_update, // id entry
        "Thermohigograph", // type form entry
        modification_date, // jam entry
        "",
        qc_temp, // qc histories
        flag_temp,
        this.t_station_id,
        this.t_station.text,
        this.t_observer,
        this.t_observer_name, // observer name
        reg_id,
        this.t_path + "/" + this.id_thermo_update
      );
    },
    submitThemohigoghraph() {
      // console.log(this.t_path);
      const thermohigograph_id = this.t_station_id + "_thermohigograph_" + this.t_date;
      const data_timestamp = this.t_date;
      // console.log(data_timestamp)
      // return
      var len_qc_after = this.last_qc_histories["after"].length;
      this.last_qc_histories["before"] = [];
      // console.log("ada QC : ",len_qc_after);
      var flag_temp = 0;
      if (len_qc_after > 0) {
        flag_temp = 1;
      }
      // console.log("QC FLAG : ",flag_temp);
      // console.log("QC HISTORIES : ",this.last_qc_histories);
      var qc_temp = JSON.stringify(this.last_qc_histories);
      const post_data = {
        "@type": "Thermohigograph",
        id: thermohigograph_id,
        t_thermo_01: this.suhu._1,
        t_thermo_02: this.suhu._2,
        t_thermo_03: this.suhu._3,
        t_thermo_04: this.suhu._4,
        t_thermo_05: this.suhu._5,
        t_thermo_06: this.suhu._6,
        t_thermo_07: this.suhu._7,
        t_thermo_08: this.suhu._8,
        t_thermo_09: this.suhu._9,
        t_thermo_10: this.suhu._10,
        t_thermo_11: this.suhu._11,
        t_thermo_12: this.suhu._12,
        t_thermo_13: this.suhu._13,
        t_thermo_14: this.suhu._14,
        t_thermo_15: this.suhu._15,
        t_thermo_16: this.suhu._16,
        t_thermo_17: this.suhu._17,
        t_thermo_18: this.suhu._18,
        t_thermo_19: this.suhu._19,
        t_thermo_20: this.suhu._20,
        t_thermo_21: this.suhu._21,
        t_thermo_22: this.suhu._22,
        t_thermo_23: this.suhu._23,
        t_thermo_24: this.suhu._24,
        tx_thermo: this.suhu_max,
        tn_thermo: this.suhu_min,
        t_avg_thermo: parseFloat(this.suhu_rata),
        rh_higro_01: this.kelembaban._1,
        rh_higro_02: this.kelembaban._2,
        rh_higro_03: this.kelembaban._3,
        rh_higro_04: this.kelembaban._4,
        rh_higro_05: this.kelembaban._5,
        rh_higro_06: this.kelembaban._6,
        rh_higro_07: this.kelembaban._7,
        rh_higro_08: this.kelembaban._8,
        rh_higro_09: this.kelembaban._9,
        rh_higro_10: this.kelembaban._10,
        rh_higro_11: this.kelembaban._11,
        rh_higro_12: this.kelembaban._12,
        rh_higro_13: this.kelembaban._13,
        rh_higro_14: this.kelembaban._14,
        rh_higro_15: this.kelembaban._15,
        rh_higro_16: this.kelembaban._16,
        rh_higro_17: this.kelembaban._17,
        rh_higro_18: this.kelembaban._18,
        rh_higro_19: this.kelembaban._19,
        rh_higro_20: this.kelembaban._20,
        rh_higro_21: this.kelembaban._21,
        rh_higro_22: this.kelembaban._22,
        rh_higro_23: this.kelembaban._23,
        rh_higro_24: this.kelembaban._24,
        rh_max_higro: this.kelembaban_max,
        rh_min_higro: this.kelembaban_min,
        rh_avg_higro: parseFloat(this.kelembaban_rata),
        // "lon": "Text",
        // "lat": "Text",
        // "dpl": "Text",
        data_timestamp: data_timestamp,
        observer_id: this.t_observer,
        qc_flag: flag_temp,
        qc_histories: qc_temp,
      };
      console.log("POST DATA : ", post_data);
      // console.log(post_data);
      // const post_url = "http://10.74.18.92:8080/db/bmkgsatu/station/1/klimatologi/pias/thermohigograph";
      const station_id = "1";
      // const post_url = Helper.getBaseUrl() + "station/" + station_id + "/klimatologi/pias/thermohigograph";
      const post_url = Helper.getBaseUrl() + this.t_path;
      axios
        .post(post_url, post_data, Helper.getConfig())
        .then((response) => {
          // console.log("responnya : ", response);
          // console.log(response.status);
          // console.log(response.statusText);
          this.resetForm();
          this.status = "ok";
          this.$swal({
            title: "Input data sukses!",
            text: "Data Telah berhasil di simpan",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          if (error.response) {
            this.status = "fail";
            // Request made and server responded
            // console.log("datanya : ", error.response.data);
            // console.log("statusnya : ", error.response.status);
            // console.log("headernya : ", error.response.headers);
            // console.log("reasonnya : ", error.response.data.reason);
            if (error.response.data.reason == "conflictId") {
              alert("Data tanggal ini telah diinput sebelumnya");
              this.$swal({
                title: "Input data Error!",
                text: "Data Telah diinput sebelumnya",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            } else if (error.response.data.reason == "deserializationError") {
              alert("Pastikan tipe data input sudah sesuai format");
            } else {
              // alert("Terjadi Kesalahan");
              this.$swal({
                title: "Input data Error!",
                text: "Terjadi Kesalahan",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
          } else if (error.request) {
            // The request was made but no response was received
            this.status = "fail";
            // console.log("err Reqnya :", error.request);
            this.$swal({
              title: "Input data Error!",
              text: "Terjadi Kesalahan",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else {
            // Something happened in setting up the request that triggered an Error
            this.status = "fail";
            // console.log("Error aja : ", error.message);
            this.$swal({
              title: "Input data Error!",
              text: "Terjadi Kesalahan",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        });
      // console.log("t_station name : ",this.t_station.text);
      if (flag_temp == 1) {
        var arr_path = this.t_path.split("/");
        var reg_id = arr_path[1];
        qc_helper.editQCNotification(
          thermohigograph_id, // id entry
          "Thermohigograph", // type form entry
          data_timestamp, // jam entry,
          "",
          qc_temp, // qc histories
          flag_temp,
          this.t_station_id,
          this.t_station.text,
          this.t_observer,
          this.t_observer_name, // observer name
          reg_id,
          this.t_path + "/" + this.id_thermo_update
        );
      }
    },
    popupData() {
      // console.log(" data nya",this.t_data['@name'])
      this.id_thermo_update = this.t_data["@name"];
      this.suhu._1 = this.t_data.t_thermo_01;
      this.suhu._2 = this.t_data.t_thermo_02;
      this.suhu._3 = this.t_data.t_thermo_03;
      this.suhu._4 = this.t_data.t_thermo_04;
      this.suhu._5 = this.t_data.t_thermo_05;
      this.suhu._6 = this.t_data.t_thermo_06;
      this.suhu._7 = this.t_data.t_thermo_07;
      this.suhu._8 = this.t_data.t_thermo_08;
      this.suhu._9 = this.t_data.t_thermo_09;
      this.suhu._10 = this.t_data.t_thermo_10;
      this.suhu._11 = this.t_data.t_thermo_11;
      this.suhu._12 = this.t_data.t_thermo_12;
      this.suhu._13 = this.t_data.t_thermo_13;
      this.suhu._14 = this.t_data.t_thermo_14;
      this.suhu._15 = this.t_data.t_thermo_15;
      this.suhu._16 = this.t_data.t_thermo_16;
      this.suhu._17 = this.t_data.t_thermo_17;
      this.suhu._18 = this.t_data.t_thermo_18;
      this.suhu._19 = this.t_data.t_thermo_19;
      this.suhu._20 = this.t_data.t_thermo_20;
      this.suhu._21 = this.t_data.t_thermo_21;
      this.suhu._22 = this.t_data.t_thermo_22;
      this.suhu._23 = this.t_data.t_thermo_23;
      this.suhu._24 = this.t_data.t_thermo_24;
      this.suhu_max = this.t_data.tx_thermo;
      this.suhu_min = this.t_data.tn_thermo;
      this.suhu_rata = this.t_data.t_avg_thermo;
      this.kelembaban._1 = this.t_data.rh_higro_01;
      this.kelembaban._2 = this.t_data.rh_higro_02;
      this.kelembaban._3 = this.t_data.rh_higro_03;
      this.kelembaban._4 = this.t_data.rh_higro_04;
      this.kelembaban._5 = this.t_data.rh_higro_05;
      this.kelembaban._6 = this.t_data.rh_higro_06;
      this.kelembaban._7 = this.t_data.rh_higro_07;
      this.kelembaban._8 = this.t_data.rh_higro_08;
      this.kelembaban._9 = this.t_data.rh_higro_09;
      this.kelembaban._10 = this.t_data.rh_higro_10;
      this.kelembaban._11 = this.t_data.rh_higro_11;
      this.kelembaban._12 = this.t_data.rh_higro_12;
      this.kelembaban._13 = this.t_data.rh_higro_13;
      this.kelembaban._14 = this.t_data.rh_higro_14;
      this.kelembaban._15 = this.t_data.rh_higro_15;
      this.kelembaban._16 = this.t_data.rh_higro_16;
      this.kelembaban._17 = this.t_data.rh_higro_17;
      this.kelembaban._18 = this.t_data.rh_higro_18;
      this.kelembaban._19 = this.t_data.rh_higro_19;
      this.kelembaban._20 = this.t_data.rh_higro_20;
      this.kelembaban._21 = this.t_data.rh_higro_21;
      this.kelembaban._22 = this.t_data.rh_higro_22;
      this.kelembaban._23 = this.t_data.rh_higro_23;
      this.kelembaban._24 = this.t_data.rh_higro_24;
      this.kelembaban_max = this.t_data.rh_max_higro;
      this.kelembaban_min = this.t_data.rh_min_higro;
      this.kelembaban_rata = this.t_data.rh_avg_higro;
      this.qc_flag_before = this.t_data.qc_flag;
      this.QC_Histories = this.t_data.qc_histories;

      // console.log("QC His : ",this.QC_Histories);
      // console.log("Flag : ",this.qc_flag_before);
      // Suhu cek RC
      this.checkValidateEdit(this.t_data.t_thermo_01, "t_1");
      this.checkValidateEdit(this.t_data.t_thermo_02, "t_2");
      this.checkValidateEdit(this.t_data.t_thermo_03, "t_3");
      this.checkValidateEdit(this.t_data.t_thermo_04, "t_4");
      this.checkValidateEdit(this.t_data.t_thermo_05, "t_5");
      this.checkValidateEdit(this.t_data.t_thermo_06, "t_6");
      this.checkValidateEdit(this.t_data.t_thermo_07, "t_7");
      this.checkValidateEdit(this.t_data.t_thermo_08, "t_8");
      this.checkValidateEdit(this.t_data.t_thermo_09, "t_9");
      this.checkValidateEdit(this.t_data.t_thermo_10, "t_10");
      this.checkValidateEdit(this.t_data.t_thermo_11, "t_11");
      this.checkValidateEdit(this.t_data.t_thermo_12, "t_12");
      this.checkValidateEdit(this.t_data.t_thermo_13, "t_13");
      this.checkValidateEdit(this.t_data.t_thermo_14, "t_14");
      this.checkValidateEdit(this.t_data.t_thermo_15, "t_15");
      this.checkValidateEdit(this.t_data.t_thermo_16, "t_16");
      this.checkValidateEdit(this.t_data.t_thermo_17, "t_17");
      this.checkValidateEdit(this.t_data.t_thermo_18, "t_18");
      this.checkValidateEdit(this.t_data.t_thermo_19, "t_19");
      this.checkValidateEdit(this.t_data.t_thermo_20, "t_20");
      this.checkValidateEdit(this.t_data.t_thermo_21, "t_21");
      this.checkValidateEdit(this.t_data.t_thermo_22, "t_22");
      this.checkValidateEdit(this.t_data.t_thermo_23, "t_23");
      this.checkValidateEdit(this.t_data.t_thermo_24, "t_24");

      //RH CEK RC
      this.checkValidateEdit(this.t_data.rh_higro_01, "rh_1");
      this.checkValidateEdit(this.t_data.rh_higro_02, "rh_2");
      this.checkValidateEdit(this.t_data.rh_higro_03, "rh_3");
      this.checkValidateEdit(this.t_data.rh_higro_04, "rh_4");
      this.checkValidateEdit(this.t_data.rh_higro_05, "rh_5");
      this.checkValidateEdit(this.t_data.rh_higro_06, "rh_6");
      this.checkValidateEdit(this.t_data.rh_higro_07, "rh_7");
      this.checkValidateEdit(this.t_data.rh_higro_08, "rh_8");
      this.checkValidateEdit(this.t_data.rh_higro_09, "rh_9");
      this.checkValidateEdit(this.t_data.rh_higro_10, "rh_10");
      this.checkValidateEdit(this.t_data.rh_higro_11, "rh_11");
      this.checkValidateEdit(this.t_data.rh_higro_12, "rh_12");
      this.checkValidateEdit(this.t_data.rh_higro_13, "rh_13");
      this.checkValidateEdit(this.t_data.rh_higro_14, "rh_14");
      this.checkValidateEdit(this.t_data.rh_higro_15, "rh_15");
      this.checkValidateEdit(this.t_data.rh_higro_16, "rh_16");
      this.checkValidateEdit(this.t_data.rh_higro_17, "rh_17");
      this.checkValidateEdit(this.t_data.rh_higro_18, "rh_18");
      this.checkValidateEdit(this.t_data.rh_higro_19, "rh_19");
      this.checkValidateEdit(this.t_data.rh_higro_20, "rh_20");
      this.checkValidateEdit(this.t_data.rh_higro_21, "rh_21");
      this.checkValidateEdit(this.t_data.rh_higro_22, "rh_22");
      this.checkValidateEdit(this.t_data.rh_higro_23, "rh_23");
      this.checkValidateEdit(this.t_data.rh_higro_24, "rh_24");
    },
    resetForm() {
      // console.log("reset form");
      this.suhu._1 = "";
      this.suhu._2 = "";
      this.suhu._3 = "";
      this.suhu._4 = "";
      this.suhu._5 = "";
      this.suhu._6 = "";
      this.suhu._7 = "";
      this.suhu._8 = "";
      this.suhu._9 = "";
      this.suhu._10 = "";
      this.suhu._11 = "";
      this.suhu._12 = "";
      this.suhu._13 = "";
      this.suhu._14 = "";
      this.suhu._15 = "";
      this.suhu._16 = "";
      this.suhu._17 = "";
      this.suhu._18 = "";
      this.suhu._19 = "";
      this.suhu._20 = "";
      this.suhu._21 = "";
      this.suhu._22 = "";
      this.suhu._23 = "";
      this.suhu._24 = "";
      this.kelembaban._1 = "";
      this.kelembaban._2 = "";
      this.kelembaban._3 = "";
      this.kelembaban._4 = "";
      this.kelembaban._5 = "";
      this.kelembaban._6 = "";
      this.kelembaban._7 = "";
      this.kelembaban._8 = "";
      this.kelembaban._9 = "";
      this.kelembaban._10 = "";
      this.kelembaban._11 = "";
      this.kelembaban._12 = "";
      this.kelembaban._13 = "";
      this.kelembaban._14 = "";
      this.kelembaban._15 = "";
      this.kelembaban._16 = "";
      this.kelembaban._17 = "";
      this.kelembaban._18 = "";
      this.kelembaban._19 = "";
      this.kelembaban._20 = "";
      this.kelembaban._21 = "";
      this.kelembaban._22 = "";
      this.kelembaban._23 = "";
      this.kelembaban._24 = "";

      this.rc_rh_1 = false;
      this.rc_rh_1_message = "";
      this.rc_rh_2 = false;
      this.rc_rh_2_message = "";
      this.rc_rh_3 = false;
      this.rc_rh_3_message = "";
      this.rc_rh_4 = false;
      this.rc_rh_4_message = "";
      this.rc_rh_5 = false;
      this.rc_rh_5_message = "";
      this.rc_rh_6 = false;
      this.rc_rh_6_message = "";
      this.rc_rh_7 = false;
      this.rc_rh_7_message = "";
      this.rc_rh_8 = false;
      this.rc_rh_8_message = "";
      this.rc_rh_9 = false;
      this.rc_rh_9_message = "";
      this.rc_rh_10 = false;
      this.rc_rh_10_message = "";

      this.rc_rh_11 = false;
      this.rc_rh_11_message = "";
      this.rc_rh_12 = false;
      this.rc_rh_12_message = "";
      this.rc_rh_13 = false;
      this.rc_rh_13_message = "";
      this.rc_rh_14 = false;
      this.rc_rh_14_message = "";
      this.rc_rh_15 = false;
      this.rc_rh_15_message = "";
      this.rc_rh_16 = false;
      this.rc_rh_16_message = "";
      this.rc_rh_17 = false;
      this.rc_rh_17_message = "";
      this.rc_rh_18 = false;
      this.rc_rh_18_message = "";
      this.rc_rh_19 = false;
      this.rc_rh_19_message = "";
      this.rc_rh_20 = false;
      this.rc_rh_20_message = "";

      this.rc_rh_21 = false;
      this.rc_rh_21_message = "";
      this.rc_rh_22 = false;
      this.rc_rh_22_message = "";
      this.rc_rh_23 = false;
      this.rc_rh_23_message = "";
      this.rc_rh_24 = false;
      this.rc_rh_24_message = "";

      //RC Suhu/T
      this.rc_t_1 = false;
      this.rc_t_1_message = "";
      this.rc_t_2 = false;
      this.rc_t_2_message = "";
      this.rc_t_3 = false;
      this.rc_t_3_message = "";
      this.rc_t_4 = false;
      this.rc_t_4_message = "";
      this.rc_t_5 = false;
      this.rc_t_5_message = "";
      this.rc_t_6 = false;
      this.rc_t_6_message = "";
      this.rc_t_7 = false;
      this.rc_t_7_message = "";
      this.rc_t_8 = false;
      this.rc_t_8_message = "";
      this.rc_t_9 = false;
      this.rc_t_9_message = "";
      this.rc_t_10 = false;
      this.rc_t_10_message = "";

      this.rc_t_11 = false;
      this.rc_t_11_message = "";
      this.rc_t_12 = false;
      this.rc_t_12_message = "";
      this.rc_t_13 = false;
      this.rc_t_13_message = "";
      this.rc_t_14 = false;
      this.rc_t_14_message = "";
      this.rc_t_15 = false;
      this.rc_t_15_message = "";
      this.rc_t_16 = false;
      this.rc_t_16_message = "";
      this.rc_t_17 = false;
      this.rc_t_17_message = "";
      this.rc_t_18 = false;
      this.rc_t_18_message = "";
      this.rc_t_19 = false;
      this.rc_t_19_message = "";
      this.rc_t_20 = false;
      this.rc_t_20_message = "";

      this.rc_t_21 = false;
      this.rc_t_21_message = "";
      this.rc_t_22 = false;
      this.rc_t_22_message = "";
      this.rc_t_23 = false;
      this.rc_t_23_message = "";
      this.rc_t_24 = false;
      this.rc_t_24_message = "";

      this.$v.$reset();
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
};
</script>

<style scoped>
.label-entry {
  text-align: center;
  color: white;
  width: 100%;
  /* font-weight: bold !important; */
  border: 2px solid #6e6b7b;
  border-radius: 3px;
  background: #858585;
  padding: 4px;
}
.label-entry1 {
  text-align: center;
  color: #3b4253;
  width: 100%;
  font-weight: 500;
  background-color: #f3f1f1;
  /* font-weight: bold !important; */
  border: 1px solid #858585;
  border-radius: 3px;
  padding: 4px;
  margin-bottom: 7px;
  font-weight: 500;
}
.mbtm {
  margin-bottom: 5px;
}
.bg-light-primary {
  color: #000000 !important;
}
#input_rh_1.rangecheck,
#input_rh_2.rangecheck,
#input_rh_3.rangecheck,
#input_rh_4.rangecheck,
#input_rh_5.rangecheck,
#input_rh_6.rangecheck,
#input_rh_7.rangecheck,
#input_rh_8.rangecheck,
#input_rh_9.rangecheck,
#input_rh_10.rangecheck,
#input_rh_11.rangecheck,
#input_rh_12.rangecheck,
#input_rh_13.rangecheck,
#input_rh_14.rangecheck,
#input_rh_15.rangecheck,
#input_rh_16.rangecheck,
#input_rh_17.rangecheck,
#input_rh_18.rangecheck,
#input_rh_19.rangecheck,
#input_rh_20.rangecheck,
#input_rh_21.rangecheck,
#input_rh_22.rangecheck,
#input_rh_23.rangecheck,
#input_rh_24.rangecheck,
#input_t_1.rangecheck,
#input_t_2.rangecheck,
#input_t_3.rangecheck,
#input_t_4.rangecheck,
#input_t_5.rangecheck,
#input_t_6.rangecheck,
#input_t_7.rangecheck,
#input_t_8.rangecheck,
#input_t_9.rangecheck,
#input_t_10.rangecheck,
#input_t_11.rangecheck,
#input_t_12.rangecheck,
#input_t_13.rangecheck,
#input_t_14.rangecheck,
#input_t_15.rangecheck,
#input_t_16.rangecheck,
#input_t_17.rangecheck,
#input_t_18.rangecheck,
#input_t_19.rangecheck,
#input_t_20.rangecheck,
#input_t_21.rangecheck,
#input_t_22.rangecheck,
#input_t_23.rangecheck,
#input_t_24.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
}

.bg-light-success {
  color: #000000 !important;
}
</style>
