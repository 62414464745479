<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <b-card-actions title="Filter" action-collapse>
          <b-row class="font-weight-bolder">
            <b-col sm="3">
              <b-form-group label="Stasiun" label-for="select-station" size="sm">
                <v-select v-model="t_station" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="t_station_opt" class="select-size-sm" />
                <!-- <a-select
                  show-search
                  :not-found-content="null"
                  style="width: 100%; height: 35px"
                  :value="t_station.value"
                  @change="(value) => (t_station = t_station_opt.find((item) => item.value === value))"
                  id="select-station"
                  class="select-size-sm"
                >
                  <a-select-option v-for="opt in t_station_opt" :key="opt.text + opt.value" :value="opt.value"> {{ opt.text }} </a-select-option>
                </a-select> -->
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label="WMO ID" label-for="select-wmo" size="sm">
                <!-- <v-select v-model="t_wmoid" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="t_wmoid_opt" disabled class="select-size-sm" /> -->
                <a-select show-search :not-found-content="null" style="width: 100%; height: 35px" :value="t_wmoid" @change="(value) => (t_wmoid = t_wmoid_opt.find((item) => item.value === value))" id="select-station" class="select-size-sm">
                  <a-select-option v-for="opt in t_wmoid_opt" :key="opt.text + opt.value" :value="opt.value"> {{ opt.text }} </a-select-option>
                </a-select>
              </b-form-group>
            </b-col>

            <b-col sm="2 pad2">
              <b-form-group label-size="sm" label="Nama Observer" label-for="pic">
                <v-select v-model="t_observer" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="observer" :options="list_observer" class="select-size-sm" style="margin-top:-3px; font-weight: normal;" :reduce="(item) => item.id" />
                <!-- <a-select
                  show-search
                  :filter-option="filterOption"
                  :not-found-content="null"
                  style="width: 100%; height: 35px"
                  :value="t_observer"
                  @change="(value) => (t_observer = list_observer.find((item) => item.value === value))"
                  id="select-station"
                  class="select-size-sm"
                >
                  <a-select-option v-for="opt in list_observer" :key="opt.text + opt.value" :value="opt.value"> {{ opt.text }} </a-select-option>
                </a-select> -->
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label="Tanggal" label-for="datepicker-3" sm="2">
                <!-- <b-form-datepicker :max="max" :disabled="dateDisable" id="datepicker-dateformat2" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="en" size="sm" placeholder="Tanggal" v-model="t_date" /> -->
                <b-form-datepicker
                  id="datepicker-3"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  locale="en"
                  size="sm"
                  placeholder="tanggal"
                  v-model="t_date"
                  :disabled="dateDisable"
                  :max="max"
                />
              </b-form-group>
            </b-col>

            <b-col sm="3">
              <b-form-group label="Jenis Form" label-for="select-form" size="sm">
                <b-form inline id="select-form">
                  <b-form-select v-model="selectedForm" class="col-7 mr-1" size="sm" style="height: 33px;">
                    <option disabled value="">Pilih Form</option>
                    <option v-if="dataFormInput.is_lama_penyinaran" value="01">Lama Penyinaran</option>
                    <option v-if="dataFormInput.is_aktinograph" value="02">Aktinograph</option>
                    <option v-if="dataFormInput.is_thermohigograph" value="03">Thermohigrograph</option>
                    <option  value="04">Form Hujan</option>
                    <!-- <option v-if="dataFormInput.is_form_hujan" value="04">Form Hujan</option> -->
                    
                    <option v-if="dataFormInput.is_barograph" value="05">Barograph</option>
                    <option v-if="dataFormInput.is_perawanan" value="06">Form Perawanan</option>
                    <option v-if="dataFormInput.is_hujan_helman" value="07">Intensitas Hujan (Hellman)</option>
                    <option v-if="dataFormInput.is_kelembapan_tanah" value="08">Kelembaban Tanah</option>
                    <option v-if="dataFormInput.is_fenologi" value="09">Fenologi</option>
                  </b-form-select>
                  <b-button v-on:click="onFetch" variant="primary" class="ml-sm-6" size="sm">Fetch</b-button>
                </b-form>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-actions>
      </div>
    </div>

    <!-- <b-col sm="3">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="4"
                    label-size="sm"
                    label="WMO ID"
                    label-for="select-wmo"
                    size="sm"
                  >
                    <b-form-select
                      v-model="t_wmoid"
                      class=""
                      id="select-wmo"
                      size="sm"
                    >
                      <option :value="option">Pilih WMO ID</option>
                      <option value="a">96374</option>
                      <option value="b">89776</option>
                    </b-form-select>
                  </b-form-group>
                </b-col> -->

    <lama-penyinaran
      v-if="choosenForm == '01'"
      :t_station="t_station"
      :t_path_update="t_path_update"
      :t_id_form="t_id_form"
      :t_data="t_data"
      :t_data_status="t_data_status"
      :t_wmoid="t_wmoid"
      :t_observer="t_observer"
      :t_station_id="t_station_id"
      :t_path="t_path"
      :t_date="t_date"
      :roles="roles"
      :count_form_click="count_form_click"
    ></lama-penyinaran>
    <aktinograph
      v-if="choosenForm == '02'"
      :t_station="t_station"
      :t_path_update="t_path_update"
      :t_id_form="t_id_form"
      :t_data="t_data"
      :t_data_status="t_data_status"
      :t_wmoid="t_wmoid"
      :t_observer="t_observer"
      :t_station_id="t_station_id"
      :t_path="t_path"
      :t_date="t_date"
      :roles="roles"
      :count_form_click="count_form_click"
    ></aktinograph>
    <thermohigograph
      v-if="choosenForm == '03'"
      :data_qc_auto="data_qc_auto"
      :qc_thermo="qc_thermo"
      :t_station="t_station"
      :t_path_update="t_path_update"
      :t_id_form="t_id_form"
      :t_data="t_data"
      :t_data_status="t_data_status"
      :t_wmoid="t_wmoid"
      :t_observer="t_observer"
      
      :t_station_id="t_station_id"
      :t_path="t_path"
      :t_date="t_date"
      :roles="roles"
      :count_form_click="count_form_click"
    ></thermohigograph>
    <hujan v-if="choosenForm == '04'" 
      :t_station="t_station" 
      :t_path_update="t_path_update" 
      :t_wmoid="t_wmoid" 
      :t_observer="t_observer" 
      :t_observer_name="t_observer_name"
      :t_station_id="t_station_id" 
      :t_path="t_path" 
      :t_date="t_date" 
      :roles="roles"></hujan>
    <barograph
      v-if="choosenForm == '05'"
      :data_qc_auto="data_qc_auto"
      :qc_baro="qc_baro"
      :t_station="t_station"
      :t_path_update="t_path_update"
      :t_id_form="t_id_form"
      :t_data="t_data"
      :t_data_status="t_data_status"
      :t_wmoid="t_wmoid"
      :t_observer="t_observer"
      :t_observer_name="t_observer_name"
      :t_station_id="t_station_id"
      :t_path="t_path"
      :t_date="t_date"
      :roles="roles"
      :count_form_click="count_form_click"
    ></barograph>
    <perawanan
      v-if="choosenForm == '06'"
      :t_station="t_station"
      :t_path_update="t_path_update"
      :t_id_form="t_id_form"
      :t_data="t_data"
      :t_data_status="t_data_status"
      :t_wmoid="t_wmoid"
      :t_observer="t_observer"
      :t_station_id="t_station_id"
      :t_path="t_path"
      :t_date="t_date"
      :roles="roles"
      :count_form_click="count_form_click"
    ></perawanan>
    <hujan-helman
      v-if="choosenForm == '07'"
      :t_station="t_station"
      :t_path_update="t_path_update"
      :t_id_form="t_id_form"
      :t_data="t_data"
      :t_data_status="t_data_status"
      :t_wmoid="t_wmoid"
      :t_observer="t_observer"
      :t_observer_name="t_observer_name"
      :t_station_id="t_station_id"
      :t_path="t_path"
      :t_date="t_date"
      :roles="roles"
      :count_form_click="count_form_click"
    ></hujan-helman>
    <kelembaban-tanah
      v-if="choosenForm == '08'"
      :t_station="t_station"
      :t_path_update="t_path_update"
      :t_id_form="t_id_form"
      :t_data="t_data"
      :t_data_status="t_data_status"
      :t_wmoid="t_wmoid"
      :t_observer="t_observer"
      :t_station_id="t_station_id"
      :t_path="t_path"
      :t_date="t_date"
      :roles="roles"
      :count_form_click="count_form_click"
    ></kelembaban-tanah>
    <fenologi
      v-if="choosenForm == '09'"
      :t_station="t_station"
      :t_path_update="t_path_update"
      :t_id_form="t_id_form"
      :t_data="t_data"
      :t_data_status="t_data_status"
      :t_wmoid="t_wmoid"
      :t_observer="t_observer"
      :t_station_id="t_station_id"
      :t_path="t_path"
      :t_date="t_date"
      :roles="roles"
      :count_form_click="count_form_click"
    ></fenologi>

    <b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay>
  </div>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { BCard, BCardHeader, BOverlay, BContainer, BRow, BCol, BFormSelect, BForm, BButton, BFormGroup, BCollapse, BFormDatepicker, BFormInput } from "bootstrap-vue";
import vSelect from "vue-select";

import LamaPenyinaran from "./piascomponent/lamapenyinaran.vue";
import Aktinograph from "./piascomponent/aktinograph.vue";
import Barograph from "./piascomponent/barograph.vue";
import Fenologi from "./piascomponent/fenologi.vue";
import Hujan from "./piascomponent/hujan.vue";
import HujanHelman from "./piascomponent/hujanhelman.vue";
import KelembabanTanah from "./piascomponent/kelembapantanah.vue";
import Perawanan from "./piascomponent/perawanan.vue";
import Thermohigograph from "./piascomponent/thermohigoghraph.vue";

import metadata from "@/api/metadata";
import axios from "axios";
import Helper from "@/api/helper";
// import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    BCardActions,
    BOverlay,
    BCard,
    BCardHeader,
    BContainer,
    BRow,
    vSelect,
    BCol,
    BFormSelect,
    BForm,
    BButton,
    BFormGroup,
    BCollapse,
    BFormDatepicker,
    BFormInput,
    LamaPenyinaran,
    Aktinograph,
    Barograph,
    Fenologi,
    Hujan,
    HujanHelman,
    KelembabanTanah,
    Perawanan,
    Thermohigograph,
  },

  props: ["roles"],

  data: function() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);

    return {
      max: maxDate,
      selectedForm: "",
      choosenForm: "",
      t_station: "",
      t_station_id: "",
      t_station_opt: [],
      t_wmoid: "",
      t_wmoid_opt: [],
      t_wmoid_list: {},
      t_observer: "",
      t_observer_name: "",
      t_date: "",
      t_path: "",
      t_pias: {
        "01": "LamaPenyinaranCollect",
        "02": "AktinographCollect",
        "03": "ThermohigographCollect",
        "04": "FormHujanCollect",
        "05": "BarographCollect",
        "06": "FormPerawananCollect",
        "07": "HujanHellmanCollect",
        "08": "KelembabanTanahCollect",
        "09": "FenologiCollect",
      },
      t_form: {
        "01": "lamapenyinaran",
        "02": "aktinograph",
        "03": "thermohigograph",
        "05": "barograph",
        "06": "formperawanan",
        "07": "hujanhellman",
        "08": "kelembabantanah",
        "09": "fenologi",
      },
      t_data: {},
      t_id_form: "",
      showLoading: false,
      t_data_status: "insert",
      t_path_update: "",
      list_observer: [],
      dateDisable: false,
      dataFormInput: {
        is_aktinograph: false,
        is_barograph: false,
        is_fenologi: false,
        is_form_hujan: false,
        is_hujan_helman: false,
        is_kelembapan_tanah: false,
        is_lama_penyinaran: false,
        is_perawanan: false,
        is_thermohigograph: false,
      },
      data_qc_auto : {},
      count_form_click : 0,
      qc_baro:null,
      qc_thermo:null,
    };
  },

  methods: {
    async onFetch() {
      this.t_data_status = "insert";
      this.showLoading = true;
      if (this.selectedForm == "" || this.t_station == "" || this.t_date == "" || this.t_observer == "") {
        this.$swal({
          title: "Error!",
          text: "Silahkan isi Filter",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        this.showLoading = false;
        return;
      }
      try {
        await this.getpath();
        this.t_id_form = this.t_station_id + "_" + this.t_form[this.selectedForm] + "_" + this.t_date;
        var path = this.t_path + "/" + this.t_id_form;
        this.t_path_update = path;
        await this.cekExistData(path);
      } catch (e) {
        this.showLoading = false;
        console.log("Eee : ", e);
      }
    },
    async cekExistData(path) {
      if (this.selectedForm != "04") {
        var url_data = Helper.getBaseUrl() + path;
        // console.log("url : a" + url_data);
        await axios
          .get(url_data, Helper.getConfig())
          .then((response) => {
            this.$swal({
              title: "Apakah Anda Yakin?",
              text: "Data Tanggal Tersebut Sudah Ada, Edit data??",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Edit",
              allowOutsideClick: false,
              allowEscapeKey: false,
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.t_data = response.data;
                let observer_valid = this.list_observer.find((el) => el.id === `${this.t_data.observer_id}`);
                // console.log("observer_valid : ", observer_valid);
                if (observer_valid) {
                  this.t_observer = `${this.t_data.observer_id}`;
                  this.t_observer_name = observer_valid.observer;
                } else {
                  this.t_observer = null;
                  this.t_observer_name = null;
                }

                this.t_data_status = "update";
                this.choosenForm = this.selectedForm;
                this.showLoading = false;
              } else if (result.dismiss === "cancel") {
                this.choosenForm = "";
                this.showLoading = false;
                
                return;
              }
            });
          })
          .catch((error) => {
            this.choosenForm = this.selectedForm;
            if (error.response) {
              console.log(error.response);
              this.showLoading = false;
              this.t_data_status = "insert";
              this.count_form_click++;
            } else if (error.request) {
              // The request was made but no response was received
              // this.status = "fail";
              this.showLoading = false;
              console.log("err Reqnya :", error.request);
              this.$swal({
                title: "Error!",
                text: "Server Not Respond!",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              return;
            } else {
              // Something happened in setting up the request that triggered an Error
              // this.status = "fail";
              this.showLoading = false;
              console.log("Error aja : ", error.message);
              this.$swal({
                title: "Error!",
                text: "Terjadi Kesalahan",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              return;
            }
          });
      } else {
        this.choosenForm = this.selectedForm;
        this.showLoading = false;
        
      }
    },
    async getpath() {
      try {
        const { data } = await metadata.getPath(this.t_pias[this.selectedForm], this.t_station.value, this.t_station.path);
        this.t_path = data[0].path;
        // console.log("path : ", this.t_path);
      } catch (e) {
        console.log(e);
      }
    },
    async listStasiun() {
      try {
        const { data } = await metadata.getAllStasiunWithWMO();
        // this.t_station_opt = [
        //   {
        //     value: "",
        //     text: "Pilih Stasiun",
        //   },
        // ];
        for (var i = 0; i < data.length; i++) {
          const data_station = {
            value: data[i].station_id,
            text: data[i].station_name,
            path: data[i].path,
          };
          if (this.t_wmoid_list[data[i].station_id] == undefined) {
            this.t_wmoid_list[data[i].station_id] = "";
          }
          this.t_wmoid_list[data[i].station_id] = data[i].station_wmo_id;

          this.t_station_opt.push(data_station);
          this.t_station = "";
        }
      } catch (e) {
        console.log(e);
      }
    },
    async listObserver(station_id) {
      var data = await metadata.getObserverStation(station_id);
      // console.log("data observer: ",data)
      this.list_observer = data;
      this.t_observer = "";
    },
    async listForm(path) {
      this.showLoading = true;
      var { data } = await metadata.getFormInput(path);
      // console.log("patnya : ",path);
      // console.log("datana : ",data);
      // console.log("form input : ",data["BmkgSatu.metadata.bmkgentry.IBMKGEntry"]);
      this.dataFormInput = data["BmkgSatu.metadata.bmkgentry.IBMKGEntry"];
      this.data_qc_auto = data["BmkgSatu.metadata.qc.IAutorumus_qc"];
      this.qc_baro = data["BmkgSatu.metadata.qc.IBarograph_qc"];
      this.qc_thermo  = data["BmkgSatu.metadata.qc.IThermohigrograph_qc"];
      // console.log("data_qc_auto : ",this.data_qc_auto)
      this.showLoading = false;
    },
  },
  // computed: {
  //   nameState() {
  //     return this.name.length > 2;
  //   }
  // },
  mounted() {
    this.listStasiun();
    // this.listObserver();

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    var date = yyyy + "-" + mm + "-" + dd;
    // var date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
    // console.log("date : ",date)
    this.t_date = date;

    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    // console.log("userLogin : ", userLogin);
    // this.t_observer = userLogin.username;
  },
  watch: {
    t_station(value) {
      this.t_wmoid_opt = [];
      this.t_station_id = value.value;
      this.listObserver(value.value);
      const data_wmo = {
        value: this.t_wmoid_list[value.value],
        text: this.t_wmoid_list[value.value],
      };
      this.t_wmoid_opt.push(data_wmo);

      this.t_wmoid = this.t_wmoid_list[value.value];
      this.listForm(value.path);
      if (this.selectedForm != "") {
        this.getpath();
      }
      this.choosenForm = "";
      // console.log("station : ",value);
    },
    selectedForm(val) {
      if (val == "04") {
        this.dateDisable = true;
        var today = new Date();
        var date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
        this.t_date = date;
      } else {
        this.dateDisable = false;
      }
    },
    t_observer(val){
      this.t_observer_name = this.list_observer.find((el) => el.id === val).observer
      // console.log(this.list_observer.find((el) => el.id === val).observer)
    },
    t_wmoid() {},
    // selectedForm(){
    //   this.getpath()
    // },
  },
};
</script>

<style scoped>
.reduce-margin-row {
  margin-bottom: -1.75rem !important;
}

.icon-right {
  float: right;
}

.card-full {
  height: 100%;
}

#container-header {
  width: 100%;
}

#left-header {
  width: 100%;
}

#right-header {
  width: 100%;
}
.pad2 {
  padding-left: 2px;
  padding-right: 2px;
}
</style>
