export const fdih_counter_sebelum_1730 = 
'<dl class="text-left">'+
'<dt>ON - Jika ingin melakukan reset/edit manual'+
'<dt>OFF - Jika ingin ambil data otomatis</dt>'+
'</dl>'

export const fdih_op_penguapan_1730 = 
'<dl class="text-left">'+
'<dt>ON - Jika ingin melakukan edit manual'+
'<dt>OFF - Jika ingin perhitungan otomatis</dt>'+
'</dl>'

export const fdih_piche_1730 = 
'<dl class="text-left">'+
'<dt>ON - Jika ingin melakukan edit manual'+
'<dt>OFF - Jika ingin perhitungan otomatis</dt>'+
'</dl>'