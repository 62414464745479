<template>
  <div>
    <b-row class="mt-n2">
      <!-- card actions -->
      <b-col cols="12">
        <b-card class="text-dark font-weight-bolder">
          <h3 class="mb-2 font-weight-bolder badge badge-light-dark">Intensitas Radiasi Matahari (cal/cm2)</h3>
          <b-row class="">
            <b-col sm="1 mb-2">
              <div>
                <label for="input60" class="label-entry1"
                  >5-6 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah radiasi pukul 5 - 6 dalam satuan cal/cm2"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >6-7 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah radiasi pukul 6 - 7 dalam satuan cal/cm2"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >7-8 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah radiasi pukul 7 - 8 dalam satuan cal/cm2"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >8-9 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah radiasi pukul 8 - 9 dalam satuan cal/cm2"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >9-10 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah radiasi pukul 9 - 10 dalam satuan cal/cm2"
                /></label>
              </div>
            </b-col>
            <b-col sm="1 mb-2">
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                v-model.number="$v.input_5_6.$model"
                :state="validateState($v.input_5_6)"
                id="input10"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip :triggers="'hover'" target="input10" placement="topleft" custom-class="validation-tooltip"> Gross Check - Batas Bawah 0, Batas Atas 3000, Special Value 9999 </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                v-model.number="$v.input_6_7.$model"
                :state="validateState($v.input_6_7)"
                id="input11"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip :triggers="'hover'" target="input11" placement="topleft" custom-class="validation-tooltip"> Gross Check - Batas Bawah 0, Batas Atas 3000, Special Value 9999 </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                v-model.number="$v.input_7_8.$model"
                :state="validateState($v.input_7_8)"
                id="input12"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip :triggers="'hover'" target="input12" placement="topleft" custom-class="validation-tooltip"> Gross Check - Batas Bawah 0, Batas Atas 3000, Special Value 9999 </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                v-model.number="$v.input_8_9.$model"
                :state="validateState($v.input_8_9)"
                id="input13"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip :triggers="'hover'" target="input13" placement="topleft" custom-class="validation-tooltip"> Gross Check - Batas Bawah 0, Batas Atas 3000, Special Value 9999 </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                v-model.number="$v.input_9_10.$model"
                :state="validateState($v.input_9_10)"
                id="input14"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip :triggers="'hover'" target="input14" placement="topleft" custom-class="validation-tooltip"> Gross Check - Batas Bawah 0, Batas Atas 3000, Special Value 9999 </b-tooltip>
            </b-col>
            <b-col sm="1 mb-2">
              <div>
                <label for="input60" class="label-entry1"
                  >10-11 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah radiasi pukul 10 - 11 dalam satuan cal/cm2"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >11-12 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah radiasi pukul 11 - 12 dalam satuan cal/cm2"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >12-13 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah radiasi pukul 12 - 13 dalam satuan cal/cm2"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >13-14 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah radiasi pukul 13 - 14 dalam satuan cal/cm2"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >14-15 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah radiasi pukul 14 - 15 dalam satuan cal/cm2"
                /></label>
              </div>
            </b-col>

            <b-col sm="1 mb-2">
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                v-model.number="$v.input_10_11.$model"
                :state="validateState($v.input_10_11)"
                id="input14"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip :triggers="'hover'" target="input14" placement="topleft" custom-class="validation-tooltip"> Gross Check - Batas Bawah 0, Batas Atas 3000, Special Value 9999 </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                v-model.number="$v.input_11_12.$model"
                :state="validateState($v.input_11_12)"
                id="input15"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip :triggers="'hover'" target="input15" placement="topleft" custom-class="validation-tooltip"> Gross Check - Batas Bawah 0, Batas Atas 3000, Special Value 9999 </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                v-model.number="$v.input_12_13.$model"
                :state="validateState($v.input_12_13)"
                id="input16"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip :triggers="'hover'" target="input16" placement="topleft" custom-class="validation-tooltip"> Gross Check - Batas Bawah 0, Batas Atas 3000, Special Value 9999 </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                v-model.number="$v.input_13_14.$model"
                :state="validateState($v.input_13_14)"
                id="input17"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip :triggers="'hover'" target="input17" placement="topleft" custom-class="validation-tooltip"> Gross Check - Batas Bawah 0, Batas Atas 3000, Special Value 9999 </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                v-model.number="$v.input_14_15.$model"
                :state="validateState($v.input_14_15)"
                id="input18"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip :triggers="'hover'" target="input18" placement="topleft" custom-class="validation-tooltip"> Gross Check - Batas Bawah 0, Batas Atas 3000, Special Value 9999 </b-tooltip>
            </b-col>

            <b-col sm="1 mb-2">
              <div>
                <label for="input60" class="label-entry1"
                  >15-16 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah radiasi pukul 15 - 16 dalam satuan cal/cm2"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >16-17 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah radiasi pukul 16 - 17 dalam satuan cal/cm2"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >17-18 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah radiasi pukul 17 - 18 dalam satuan cal/cm2"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >18-19 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah radiasi pukul 18 - 19 dalam satuan cal/cm2"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >19-20 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah radiasi pukul 19 - 20 dalam satuan cal/cm2"
                /></label>
              </div>
            </b-col>

            <b-col sm="1 mb-2">
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                v-model.number="$v.input_15_16.$model"
                :state="validateState($v.input_15_16)"
                id="input19"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip :triggers="'hover'" target="input19" placement="topleft" custom-class="validation-tooltip"> Gross Check - Batas Bawah 0, Batas Atas 3000, Special Value 9999 </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                v-model.number="$v.input_16_17.$model"
                :state="validateState($v.input_16_17)"
                id="input20"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip :triggers="'hover'" target="input20" placement="topleft" custom-class="validation-tooltip"> Gross Check - Batas Bawah 0, Batas Atas 3000, Special Value 9999 </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                v-model.number="$v.input_17_18.$model"
                :state="validateState($v.input_17_18)"
                id="input21"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip :triggers="'hover'" target="input21" placement="topleft" custom-class="validation-tooltip"> Gross Check - Batas Bawah 0, Batas Atas 3000, Special Value 9999 </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                v-model.number="$v.input_18_19.$model"
                :state="validateState($v.input_18_19)"
                id="input22"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip :triggers="'hover'" target="input22" placement="topleft" custom-class="validation-tooltip"> Gross Check - Batas Bawah 0, Batas Atas 3000, Special Value 9999 </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                v-model.number="$v.input_19_20.$model"
                :state="validateState($v.input_19_20)"
                id="input_19_20"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip :triggers="'hover'" target="input_19_20" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("input_19_20") }}
              </b-tooltip>
            </b-col>

            <b-col sm="1 mb-2">
              <div>
                <label for="input60" class="label-entry1"
                  >20-21 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah radiasi pukul 20 - 21 dalam satuan cal/cm2"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >21-22 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah radiasi pukul 21 - 22 dalam satuan cal/cm2"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >22-23 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah radiasi pukul 22 - 23 dalam satuan cal/cm2"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1"
                  >23-24 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah radiasi pukul 23 - 24 dalam satuan cal/cm2"
                /></label>
              </div>
              <div>
                <label for="input60" class="label-entry1 bg-light-primary"
                  >Total <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah radiasi total selama 24 jam"
                /></label>
              </div>
            </b-col>

            <b-col sm="1 mb-2">
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                v-model.number="$v.input_20_21.$model"
                :state="validateState($v.input_20_21)"
                id="input_20_21"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip :triggers="'hover'" target="input_20_21" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("input_20_21") }}
              </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                v-model.number="$v.input_21_22.$model"
                :state="validateState($v.input_21_22)"
                id="input_21_22"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip :triggers="'hover'" target="input_21_22" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("input_21_22") }}
              </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                v-model.number="$v.input_22_23.$model"
                :state="validateState($v.input_22_23)"
                id="input_22_23"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip :triggers="'hover'" target="input_22_23" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("input_22_23") }}
              </b-tooltip>
              <b-form-input
                v-on:keydown="onKeyCheck($event)"
                :formatter="format_one_decimal"
                type="number"
                v-model.number="$v.input_23_24.$model"
                :state="validateState($v.input_23_24)"
                id="input_23_24"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip :triggers="'hover'" target="input_23_24" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("input_23_24") }}
              </b-tooltip>
              <b-form-input :formatter="format_one_decimal" type="number" v-model.number="total" id="input10" size="sm" class="text-center mbtm bg-light-success" placeholder="" readonly></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="4">
              <span class="text-danger">Keterangan : </span><br />
              0 Tidak ada Radiasi Matahari<br />
              9999 Alat Rusak / Tidak Ada Data
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-n2">
      <b-col cols="12">
        <b-card>
          <b-row class="float-right">
            <div>
              <b-button variant="danger" @click="resetForm" class="mr-2" size="sm"><b-icon icon="x-circle-fill"></b-icon> Batal</b-button>
              <b-button variant="warning" @click="V_Form" v-if="this.t_data_status == 'update'" class="mr-2" size="sm" :disabled="!roles.isRoleEdit"><b-icon icon="pencil-fill"></b-icon> Update</b-button>
              <b-button variant="primary" @click="V_Form" v-if="this.t_data_status == 'insert'" class="mr-2" size="sm" :disabled="!roles.isRoleAdd"><b-icon icon="cursor-fill"></b-icon> Submit</b-button>
            </div>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { BRow, BCol, BTable, BCardText, BFormInput, BContainer, BButton, BCard, VBTooltip, BTooltip } from "bootstrap-vue";
import { methods } from "vue-echarts";
import axios from "axios";
import Helper from "@/api/helper";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import error_handler from "@/api/error_handler";
import { validationMixin } from "vuelidate";
import { required, minLength, between } from "vuelidate/lib/validators";
import { V_Aktinograf } from "@/validators/piasvalidator";
import { V_aktinograf_19_24, GC_PIAS, GC_PIAS_AKTINOGRAPH } from "@/validators/piasvalidator";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BTable,
    BCardText,
    BFormInput,
    BContainer,
    BButton,
    BCard,
    VBTooltip,
    BTooltip,
    ToastificationContent,
  },
  props: ["t_station", "t_wmoid", "t_observer", "t_date", "t_station_id", "t_path", "t_data", "t_id_form", "t_data_status", "t_path_update", "roles", "count_form_click"],
  mixins: [validationMixin],
  validations: {
    input_5_6: { required, V_Aktinograf },
    input_6_7: { required, V_Aktinograf },
    input_7_8: { required, V_Aktinograf },
    input_8_9: { required, V_Aktinograf },
    input_9_10: { required, V_Aktinograf },
    input_10_11: { required, V_Aktinograf },
    input_11_12: { required, V_Aktinograf },
    input_12_13: { required, V_Aktinograf },
    input_13_14: { required, V_Aktinograf },
    input_14_15: { required, V_Aktinograf },
    input_15_16: { required, V_Aktinograf },
    input_16_17: { required, V_Aktinograf },
    input_17_18: { required, V_Aktinograf },
    input_18_19: { required, V_Aktinograf },
    input_19_20: { required, V_aktinograf_19_24 },
    input_20_21: { required, V_aktinograf_19_24 },
    input_21_22: { required, V_aktinograf_19_24 },
    input_22_23: { required, V_aktinograf_19_24 },
    input_23_24: { required, V_aktinograf_19_24 },
  },
  data() {
    return {
      input_5_6: "",
      input_6_7: "",
      input_7_8: "",
      input_8_9: "",
      input_9_10: "",
      input_10_11: "",
      input_11_12: "",
      input_12_13: "",
      input_13_14: "",
      input_14_15: "",
      input_15_16: "",
      input_16_17: "",
      input_17_18: "",
      input_18_19: "",
      input_19_20: "",
      input_20_21: "",
      input_21_22: "",
      input_22_23: "",
      input_23_24: "",
    };
  },
  computed: {
    total: function () {
      if (
        this.input_5_6 == "9999" &&
        this.input_6_7 == "9999" &&
        this.input_7_8 == "9999" &&
        this.input_8_9 == "9999" &&
        this.input_9_10 == "9999" &&
        this.input_10_11 == "9999" &&
        this.input_11_12 == "9999" &&
        this.input_12_13 == "9999" &&
        this.input_13_14 == "9999" &&
        this.input_14_15 == "9999" &&
        this.input_15_16 == "9999" &&
        this.input_16_17 == "9999" &&
        this.input_17_18 == "9999" &&
        this.input_18_19 == "9999" &&
        this.input_19_20 == "9999" &&
        this.input_20_21 == "9999" &&
        this.input_21_22 == "9999" &&
        this.input_22_23 == "9999" &&
        this.input_23_24 == "9999"
      ) {
        return parseInt(9999);
      } else if (
        this.input_5_6 == "8888" &&
        this.input_6_7 == "8888" &&
        this.input_7_8 == "8888" &&
        this.input_8_9 == "8888" &&
        this.input_9_10 == "8888" &&
        this.input_10_11 == "8888" &&
        this.input_11_12 == "8888" &&
        this.input_12_13 == "8888" &&
        this.input_13_14 == "8888" &&
        this.input_14_15 == "8888" &&
        this.input_15_16 == "8888" &&
        this.input_16_17 == "8888" &&
        this.input_17_18 == "8888" &&
        this.input_18_19 == "8888" &&
        this.input_19_20 == "8888" &&
        this.input_20_21 == "8888" &&
        this.input_21_22 == "8888" &&
        this.input_22_23 == "8888" &&
        this.input_23_24 == "8888"
      ) {
        return parseInt(8888);
      } else {
        return parseFloat(
          ((this.input_5_6 == "9999" || this.input_5_6 == "8888" ? 0 : this.input_5_6) || 0) +
            ((this.input_6_7 == "9999" || this.input_6_7 == "8888" ? 0 : this.input_6_7) || 0) +
            ((this.input_7_8 == "9999" || this.input_7_8 == "8888" ? 0 : this.input_7_8) || 0) +
            ((this.input_8_9 == "9999" || this.input_8_9 == "8888" ? 0 : this.input_8_9) || 0) +
            ((this.input_9_10 == "9999" || this.input_9_10 == "8888" ? 0 : this.input_9_10) || 0) +
            ((this.input_10_11 == "9999" || this.input_10_11 == "8888" ? 0 : this.input_10_11) || 0) +
            ((this.input_11_12 == "9999" || this.input_11_12 == "8888" ? 0 : this.input_11_12) || 0) +
            ((this.input_12_13 == "9999" || this.input_12_13 == "8888" ? 0 : this.input_12_13) || 0) +
            ((this.input_13_14 == "9999" || this.input_13_14 == "8888" ? 0 : this.input_13_14) || 0) +
            ((this.input_14_15 == "9999" || this.input_14_15 == "8888" ? 0 : this.input_14_15) || 0) +
            ((this.input_15_16 == "9999" || this.input_15_16 == "8888" ? 0 : this.input_15_16) || 0) +
            ((this.input_16_17 == "9999" || this.input_16_17 == "8888" ? 0 : this.input_16_17) || 0) +
            ((this.input_17_18 == "9999" || this.input_17_18 == "8888" ? 0 : this.input_17_18) || 0) +
            ((this.input_18_19 == "9999" || this.input_18_19 == "8888" ? 0 : this.input_18_19) || 0) +
            ((this.input_19_20 == "9999" || this.input_19_20 == "8888" ? 0 : this.input_19_20) || 0) +
            ((this.input_20_21 == "9999" || this.input_20_21 == "8888" ? 0 : this.input_20_21) || 0) +
            ((this.input_21_22 == "9999" || this.input_21_22 == "8888" ? 0 : this.input_21_22) || 0) +
            ((this.input_22_23 == "9999" || this.input_22_23 == "8888" ? 0 : this.input_22_23) || 0) +
            ((this.input_23_24 == "9999" || this.input_23_24 == "8888" ? 0 : this.input_23_24) || 0)
        ).toFixed(1);
      }
    },
  },
  mounted() {
    if (this.t_data_status == "update") {
      this.popupData();
    } else {
      this.resetForm();
    }
  },
  watch: {
    t_station(value) {
      console.log("ubah station : ", this.t_path);
    },
    t_data_status(newValue, OldValue) {
      if (newValue == "update") {
        this.popupData();
      } else {
        this.resetForm();
      }
    },
    count_form_click(NewValue, OldValue) {
      if (this.t_data_status == "insert") {
        if (NewValue != OldValue) {
          this.resetForm();
        }
      }
    },
  },
  methods: {
    onKeyCheck(event) {
      console.log("onKeyCheck", event);
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault();
      }
    },
    format_one_decimal(e) {
      if (Math.round(e * 10) / 10 != e) {
        let text = String(e);
        let filter = text.substring(0, text.length - 1);
        return filter;
      } else {
        if (Math.floor(e) === e) {
          return e;
        } else {
          if (e.toString().indexOf(".") > 0) {
            let count = e.toString().split(".")[1].length || 0;
            return count > 1 ? parseFloat(e).toFixed(1) : e;
          } else {
            return e;
          }
        }
        return e;
      }
    },
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },

    tooltipMessage(attr) {
      return GC_PIAS_AKTINOGRAPH(attr).message;
    },

    V_Form() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Data tidak valid. Periksa kembali inputan anda",
            icon: "BellIcon",
            variant: "danger",
          },
        });
        return;
      } else {
        if (this.t_data_status == "insert") {
          this.sumbitAktinograph();
        } else if (this.t_data_status == "update") {
          this.updateData();
        }
      }
    },
    popupData() {
      this.input_5_6 = this.t_data.f_5_6;
      this.input_6_7 = this.t_data.f_6_7;
      this.input_7_8 = this.t_data.f_7_8;
      this.input_8_9 = this.t_data.f_8_9;
      this.input_9_10 = this.t_data.f_9_10;
      this.input_10_11 = this.t_data.f_10_11;
      this.input_11_12 = this.t_data.f_11_12;
      this.input_12_13 = this.t_data.f_12_13;
      this.input_13_14 = this.t_data.f_13_14;
      this.input_14_15 = this.t_data.f_14_15;
      this.input_15_16 = this.t_data.f_15_16;
      this.input_16_17 = this.t_data.f_16_17;
      this.input_17_18 = this.t_data.f_17_18;
      this.input_18_19 = this.t_data.f_18_19;
      this.input_19_20 = this.t_data.f_19_20;
      this.input_20_21 = this.t_data.f_20_21;
      this.input_21_22 = this.t_data.f_21_22;
      this.input_22_23 = this.t_data.f_22_23;
      this.input_23_24 = this.t_data.f_23_24;
      this.total = this.t_data.f_total19;
    },
    updateData() {
      const modification_date = this.t_date;
      const post_data = {
        "@type": "Aktinograph",
        f_5_6: this.input_5_6,
        f_6_7: this.input_6_7,
        f_7_8: this.input_7_8,
        f_8_9: this.input_8_9,
        f_9_10: this.input_9_10,
        f_10_11: this.input_10_11,
        f_11_12: this.input_11_12,
        f_12_13: this.input_12_13,
        f_13_14: this.input_13_14,
        f_14_15: this.input_14_15,
        f_15_16: this.input_15_16,
        f_16_17: this.input_16_17,
        f_17_18: this.input_17_18,
        f_18_19: this.input_18_19,
        f_19_20: this.input_19_20,
        f_20_21: this.input_20_21,
        f_21_22: this.input_21_22,
        f_22_23: this.input_22_23,
        f_23_24: this.input_23_24,
        f_total19: this.total,
        modification_date: modification_date,
        observer_id: this.t_observer,
      };

      const update_url = Helper.getBaseUrl() + this.t_path_update;

      axios
        .patch(update_url, post_data, Helper.getConfig())
        .then((response) => {
          this.$swal({
            title: "Update data sukses!",
            text: "Data Telah berhasil di Update",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.resetForm();
        })
        .catch((error) => {
          console.log(error);
          error_handler(this, error, "Aktinograph", "Input Data Error");
        });
    },
    sumbitAktinograph() {
      console.log(this.t_date);
      //return
      const aktinograph_id = this.t_station_id + "_aktinograph_" + this.t_date;
      const data_timestamp = this.t_date;
      const post_data = {
        "@type": "Aktinograph",
        id: aktinograph_id,
        f_5_6: this.input_5_6,
        f_6_7: this.input_6_7,
        f_7_8: this.input_7_8,
        f_8_9: this.input_8_9,
        f_9_10: this.input_9_10,
        f_10_11: this.input_10_11,
        f_11_12: this.input_11_12,
        f_12_13: this.input_12_13,
        f_13_14: this.input_13_14,
        f_14_15: this.input_14_15,
        f_15_16: this.input_15_16,
        f_16_17: this.input_16_17,
        f_17_18: this.input_17_18,
        f_18_19: this.input_18_19,
        f_19_20: this.input_19_20,
        f_20_21: this.input_20_21,
        f_21_22: this.input_21_22,
        f_22_23: this.input_22_23,
        f_23_24: this.input_23_24,
        f_total19: this.total,
        // "lon" : "Text",
        // "lat" : "Text",
        // "dpl" : "Text",
        data_timestamp: data_timestamp,
        observer_id: this.t_observer,
      };

      const post_url = Helper.getBaseUrl() + this.t_path;

      axios
        .post(post_url, post_data, Helper.getConfig())
        .then((response) => {
          this.$swal({
            title: "Input Data Berhasil!",
            text: "Data Telah berhasil di Simpan",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.resetForm();
        })
        .catch((error) => {
          console.log(error);
          error_handler(this, error, "Aktinograph", "Input Data Error");
        });
    },

    resetForm() {
      this.input_5_6 = "";
      this.input_6_7 = "";
      this.input_7_8 = "";
      this.input_8_9 = "";
      this.input_9_10 = "";
      this.input_10_11 = "";
      this.input_11_12 = "";
      this.input_12_13 = "";
      this.input_13_14 = "";
      this.input_14_15 = "";
      this.input_15_16 = "";
      this.input_16_17 = "";
      this.input_17_18 = "";
      this.input_18_19 = "";
      this.input_19_20 = "";
      this.input_20_21 = "";
      this.input_21_22 = "";
      this.input_22_23 = "";
      this.input_23_24 = "";
      this.$v.$reset();
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
};
</script>
<style scoped>
.label-entry1 {
  text-align: center;
  color: #3b4253;
  width: 100%;
  font-weight: 500;
  background-color: #f3f1f1;
  /* font-weight: bold !important; */
  border: 2px solid #6e6b7b;
  border-radius: 3px;
  padding: 4px;
  margin-bottom: 7px;
}
.mbtm {
  margin-bottom: 7px;
}
.bg-light-primary {
  color: #000000 !important;
}
</style>
