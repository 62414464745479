<template>
  <div>
    <b-row class="mt-n2">
      <b-col cols="12">
        <b-card class="text-dark font-weight-bolder">
          <b-row class="">
            <b-col sm="2">
              <b-form-group  label-for="select-form" size="sm">
                <label class="label-required">Petugas Awal Sampling
                  <span class="text-danger">*</span>
                </label>
                <v-select v-model="aerosol_ptgs_pasang_sampling" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="observer" :options="list_observer" class="select-size-sm">
                </v-select>
                <label v-if="!aerosol_ptgs_pasang_sampling || !aerosol_ptgs_angkat_sampling 
                || !ptgs_analis || !tgl_pasang || !jam_pasang || !tgl_angkat || !jam_angkat"
                class="label">Wajib Diisi <span class="text-danger">*</span></label>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group  label-for="select-form" size="sm">
                <label class="label-required">Petugas Akhir Sampling
                  <span class="text-danger">*</span>
                </label>
                <v-select v-model="aerosol_ptgs_angkat_sampling" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="observer" :options="list_observer" class="select-size-sm">
                </v-select>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label-for="select-form" size="sm">
                <label class="label-required">Petugas Analis Lab
                  <span class="text-danger">*</span>
                </label>
                <v-select v-model="ptgs_analis" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text"
                  :options="list_penyelia" class="select-size-sm">
                </v-select>
              </b-form-group>
            </b-col>

            <b-col sm="3">
              <b-row>
                <b-col cols="8">
                  <b-form-group label-for="datepicker-4" sm="2">
                    <label class="label-required">Tanggal Pemasangan
                      <span class="text-danger">*</span>
                    </label>
                    <b-form-datepicker id="datepicker-4" :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }" locale="en" size="sm" placeholder="tanggal" v-model="tgl_pasang" :disabled="dateDisable"
                      class="select-size-sm" />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group  label-for="datepicker-4" sm="2">
                    <label class="label-required">Jam
                      <span class="text-danger">*</span>
                    </label>
                    <b-form-select v-model="jam_pasang" class="col-12 select-size-sm" size="sm" style="height: 33px;">
                      <option disabled value="">Pilih Jam</option>
                      <option value="08">08:00</option>
                      <option value="09">09:00</option>
                      <option value="10">10:00</option>
                      <option value="11">11:00</option>
                      <option value="12">12:00</option>
                      <option value="13">13:00</option>
                      <option value="14">14:00</option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col sm="3">
              <b-row>
                <b-col cols="8">
                  <b-form-group label-for="datepicker-5" sm="2">
                    <label class="label-required">Tanggal Pengangkatan
                      <span class="text-danger">*</span>
                    </label>
                    <b-form-datepicker id="datepicker-5" :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }" locale="en" size="sm" placeholder="tanggal" v-model="tgl_angkat" :disabled="dateDisable"
                      :min="min" class="select-size-sm" />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group label-for="datepicker-5" sm="2">
                    <label class="label-required">Jam
                      <span class="text-danger">*</span>
                    </label>
                    <b-form-select v-model="jam_angkat" class="col-12 select-size-sm" size="sm" style="height: 33px;">
                      <option disabled value="">Pilih Jam</option>
                      <option value="08">08:00</option>
                      <option value="09">09:00</option>
                      <option value="10">10:00</option>
                      <option value="11">11:00</option>
                      <option value="12">12:00</option>
                      <option value="13">13:00</option>
                      <option value="14">14:00</option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>

        <!-- ID SAMPLE-->
        <b-card class="text-dark font-weight-bolder mt-n2">
          <b-row>
            <b-col sm="3 mb-n2">
              <b-form-group label="ID Sample" label-for="id-sample" label-cols-md="4">
                <b-form-input id="id-sample" v-model="aerosol_id_sampel" disabled
                  class="font-weight-bolder select-grey" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <!-- SUB CONTENT-->

        <b-row>
          <b-col cols="4">
            <b-card class="text-dark font-weight-bolder mt-n2 mr-n1 h-100">
              <b-row>
                <b-col sm="7">
                  <b-form-group label="Konsentrasi Aerosol" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="aerosol_konsentrasi" 
                        :formatter="format_two_decimal"
                        v-model.number="$v.aerosol_konsentrasi.$model"
                        :state="validateState($v.aerosol_konsentrasi)" 
                        v-on:keyup="checkValidate($event, 'aerosol_konsentrasi')"
                        v-bind:class="{ rangecheck: RC.aerosol_konsentrasi.status }"
                        class="col-8 text-center" size="sm"
                        style="height: 33px;">
                      </b-form-input>
                      <b-tooltip v-if="RC.aerosol_konsentrasi.status" target="aerosol_konsentrasi" :triggers="'hover'" placement="topleft">
                        {{ RC.aerosol_konsentrasi.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.aerosol_konsentrasi.status" :triggers="'hover'" target="aerosol_konsentrasi" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("aerosol_konsentrasi") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">mg/m3</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">&micro;g/m3</label>
                </b-col> -->
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="Bobot Filter Kosong" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="aerosol_bobot_filter_kosong" 
                        :formatter="format_four_decimal"
                        v-model.number="$v.aerosol_bobot_filter_kosong.$model"
                        :state="validateState($v.aerosol_bobot_filter_kosong)" 
                        v-on:keyup="checkValidate($event, 'aerosol_bobot_filter_kosong')"
                        v-bind:class="{ rangecheck: RC.aerosol_bobot_filter_kosong.status }"
                        class="col-8 text-center" size="sm"
                        style="height: 33px;" >
                      </b-form-input>
                      <b-tooltip v-if="RC.aerosol_bobot_filter_kosong.status" target="aerosol_bobot_filter_kosong" :triggers="'hover'" placement="topleft">
                        {{ RC.aerosol_bobot_filter_kosong.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.aerosol_bobot_filter_kosong.status" :triggers="'hover'" target="aerosol_bobot_filter_kosong" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("aerosol_bobot_filter_kosong") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">gram</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">gram</label>
                </b-col> -->
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="Bobot Filter Sampling" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="aerosol_bobot_filter_sampling" 
                        :formatter="format_four_decimal"
                        v-model.number="$v.aerosol_bobot_filter_sampling.$model" 
                        :state="validateState($v.aerosol_bobot_filter_sampling)" 
                        v-on:keyup="checkValidate($event, 'aerosol_bobot_filter_sampling')"
                        v-bind:class="{ rangecheck: RC.aerosol_bobot_filter_sampling.status }"
                        class="col-8 text-center" size="sm"
                        style="height: 33px;">
                      </b-form-input>
                      <b-tooltip v-if="RC.aerosol_bobot_filter_sampling.status" target="aerosol_bobot_filter_sampling" :triggers="'hover'" placement="topleft">
                        {{ RC.aerosol_bobot_filter_sampling.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.aerosol_bobot_filter_sampling.status" :triggers="'hover'" target="aerosol_bobot_filter_sampling" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("aerosol_bobot_filter_sampling") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">gram</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">gram</label>
                </b-col> -->
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="Flowrate Awal" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="aerosol_flow_rate_awal" 
                        :formatter="format_two_decimal"
                        v-model.number="$v.aerosol_flow_rate_awal.$model"
                        v-on:keyup="checkValidate($event, 'aerosol_flow_rate_awal')"
                        v-bind:class="{ rangecheck: RC.aerosol_flow_rate_awal.status }"
                        :state="validateState($v.aerosol_flow_rate_awal)" 
                        class="col-8 text-center mbtm" size="sm"
                        style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.aerosol_flow_rate_awal.status" target="aerosol_flow_rate_awal" :triggers="'hover'" placement="topleft">
                        {{ RC.aerosol_flow_rate_awal.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.aerosol_flow_rate_awal.status" :triggers="'hover'" target="aerosol_flow_rate_awal" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("aerosol_flow_rate_awal") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">lpm</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">lpm</label>
                </b-col> -->
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="Flowrate akhir" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="aerosol_flow_rate_akhir" 
                        :formatter="format_two_decimal"
                        v-model.number="$v.aerosol_flow_rate_akhir.$model"
                        v-on:keyup="checkValidate($event, 'aerosol_flow_rate_akhir')"
                        v-bind:class="{ rangecheck: RC.aerosol_flow_rate_akhir.status }"
                        :state="validateState($v.aerosol_flow_rate_akhir)" 
                        class="col-8 text-center mbtm" size="sm"
                        style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.aerosol_flow_rate_akhir.status" target="aerosol_flow_rate_akhir" :triggers="'hover'" placement="topleft">
                        {{ RC.aerosol_flow_rate_akhir.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.aerosol_flow_rate_akhir.status" :triggers="'hover'" target="aerosol_flow_rate_akhir" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("aerosol_flow_rate_akhir") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">lpm</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">lpm</label>
                </b-col> -->
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="Total Waktu Sampling" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input  
                        id="aerosol_total_waktu_sampling" 
                        v-on:keypress="isNumber($event)"
                        v-model.number="$v.aerosol_total_waktu_sampling.$model"
                        :state="validateState($v.aerosol_total_waktu_sampling)" 
                        v-on:keyup="checkValidate($event, 'aerosol_total_waktu_sampling')"
                        v-bind:class="{ rangecheck: RC.aerosol_total_waktu_sampling.status }"
                        class="col-8 text-center" size="sm"
                        style="height: 33px;">
                      </b-form-input>
                      <b-tooltip v-if="RC.aerosol_total_waktu_sampling.status" target="aerosol_total_waktu_sampling" :triggers="'hover'" placement="topleft">
                        {{ RC.aerosol_total_waktu_sampling.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.aerosol_total_waktu_sampling.status"  :triggers="'hover'" target="aerosol_total_waktu_sampling" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("aerosol_total_waktu_sampling") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">minutes</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="3">
                  <label class="mb-2 font-weight-bolder mt-2">minutes</label>
                </b-col> -->
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="Tekanan Udara Awal" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="aerosol_tekanan_udara_awal" 
                        :formatter="format_two_decimal"
                        v-model.number="$v.aerosol_tekanan_udara_awal.$model"
                        :state="validateState($v.aerosol_tekanan_udara_awal)" 
                        v-on:keyup="checkValidate($event, 'aerosol_tekanan_udara_awal')"
                        v-bind:class="{ rangecheck: RC.aerosol_tekanan_udara_awal.status }"
                        class="col-8 text-center" size="sm"
                        style="height: 33px;">
                      </b-form-input>
                      <b-tooltip v-if="RC.aerosol_tekanan_udara_awal.status" target="aerosol_tekanan_udara_awal" :triggers="'hover'" placement="topleft">
                        {{ RC.aerosol_tekanan_udara_awal.message }}
                      </b-tooltip>
                       <b-tooltip v-if="!RC.aerosol_tekanan_udara_awal.status" :triggers="'hover'" target="aerosol_tekanan_udara_awal" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("aerosol_tekanan_udara_awal") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">mb</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">mb</label>
                </b-col> -->
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="Tekanan Udara Akhir" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="aerosol_tekanan_udara_akhir" 
                        :formatter="format_two_decimal"
                        v-model.number="$v.aerosol_tekanan_udara_akhir.$model" 
                        :state="validateState($v.aerosol_tekanan_udara_akhir)" 
                        v-on:keyup="checkValidate($event, 'aerosol_tekanan_udara_akhir')"
                        v-bind:class="{ rangecheck: RC.aerosol_tekanan_udara_akhir.status }"
                        class="col-8 text-center" size="sm"
                        style="height: 33px;">
                      </b-form-input>
                      <b-tooltip v-if="RC.aerosol_tekanan_udara_akhir.status" target="aerosol_tekanan_udara_akhir" :triggers="'hover'" placement="topleft">
                        {{ RC.aerosol_tekanan_udara_akhir.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.aerosol_tekanan_udara_akhir.status" :triggers="'hover'" target="aerosol_tekanan_udara_akhir" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("aerosol_tekanan_udara_akhir") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">mb</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">mb</label>
                </b-col> -->
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="Temperatur Awal" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="aerosol_temperatur_awal" 
                        :formatter="format_two_decimal"
                        v-model.number="$v.aerosol_temperatur_awal.$model" 
                        :state="validateState($v.aerosol_temperatur_awal)" 
                        v-on:keyup="checkValidate($event, 'aerosol_temperatur_awal')"
                        v-bind:class="{ rangecheck: RC.aerosol_temperatur_awal.status }"
                        class="col-8 text-center" size="sm"
                        style="height: 33px;">
                      </b-form-input>
                      <b-tooltip v-if="RC.aerosol_temperatur_awal.status" target="aerosol_temperatur_awal" :triggers="'hover'" placement="topleft">
                        {{ RC.aerosol_temperatur_awal.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.aerosol_temperatur_awal.status" :triggers="'hover'" target="aerosol_temperatur_awal" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("aerosol_temperatur_awal") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">&#8451;</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">&#8451;</label>
                </b-col> -->
              </b-row>

              <b-row>
                <b-col sm="7">
                  <b-form-group label="Temperatur akhir" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="aerosol_temperatur_akhir" 
                        :formatter="format_two_decimal"
                        v-model.number="$v.aerosol_temperatur_akhir.$model" 
                        v-on:keyup="checkValidate($event, 'aerosol_temperatur_akhir')"
                        v-bind:class="{ rangecheck: RC.aerosol_temperatur_akhir.status }"
                        class="col-8 text-center" size="sm"
                        :state="validateState($v.aerosol_temperatur_akhir)" style="height: 33px;">
                      </b-form-input>
                      <b-tooltip v-if="RC.aerosol_temperatur_akhir.status" target="aerosol_temperatur_akhir" :triggers="'hover'" placement="topleft">
                        {{ RC.aerosol_temperatur_akhir.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.aerosol_temperatur_akhir.status" :triggers="'hover'" target="aerosol_temperatur_akhir" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("aerosol_temperatur_akhir") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">&#8451;</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">&#8451;</label>
                </b-col> -->
              </b-row>

            </b-card>
          </b-col>

          <b-col cols="4">
            <b-card class="text-dark font-weight-bolder mt-n2 mr-n1 h-100">
              <b-row>
                <b-col sm="6">
                  <b-form-group label="pH" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="aerosol_ph" 
                        :formatter="format_two_decimal"
                        v-model.number="$v.aerosol_ph.$model"
                        v-on:keyup="checkValidate($event, 'aerosol_ph')"
                        v-bind:class="{ rangecheck: RC.aerosol_ph.status }"
                        :state="validateState($v.aerosol_ph)" class="col-8 text-center mbtm" size="sm"
                        style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.aerosol_ph.status" target="aerosol_ph" :triggers="'hover'" placement="topleft">
                        {{ RC.aerosol_ph.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.aerosol_ph.status" :triggers="'hover'" target="aerosol_ph" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("aerosol_ph") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">pH unit</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">pH unit</label>
                </b-col> -->
                <b-col sm="1">
                </b-col>
                <b-col sm="4">
                  <b-form-group label="pH Flag" label-for="select-form" size="sm">
                    <b-form inline id="select-form">
                      <b-form-select 
                        v-model="$v.aerosol_ph_flag.$model"
                        :state="validateState($v.aerosol_ph_flag)"
                        :options="flagOptionsAerosolPh"
                        class="col-12 mr-1" size="sm" style="height: 33px;">
                        <!-- <option disabled value="">Pilih Flag</option>
                        <option value="M">M</option>
                        <option value="X">X</option>
                        <option value="V">V</option>
                        <option value="D">D</option>
                        <option value="L">L</option> -->
                      </b-form-select>
                    </b-form>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="6">
                  <b-form-group label="Electro Conductivity" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <!-- <b-form-input v-model="aerosol_conductivity" class="col-12 mr-1" size="sm" style="height: 33px;">
                              </b-form-input> -->
                      <b-form-input 
                        id="aerosol_conductivity" 
                        :formatter="format_one_decimal"
                        v-model.number="$v.aerosol_conductivity.$model"
                        :state="validateState($v.aerosol_conductivity)" 
                        v-on:keyup="checkValidate($event, 'aerosol_conductivity')"
                        v-bind:class="{ rangecheck: RC.aerosol_conductivity.status }"
                        class="col-8 text-center mbtm" size="sm"
                        style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.aerosol_conductivity.status" target="aerosol_conductivity" :triggers="'hover'" placement="topleft">
                        {{ RC.aerosol_conductivity.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.aerosol_conductivity.status" :triggers="'hover'" target="aerosol_conductivity" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("aerosol_conductivity") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">uS/cm</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">uS/cm</label>
                </b-col> -->
                <b-col sm="1">
                </b-col>
                <b-col sm="4">
                  <b-form-group label="EC Flag" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-select 
                        v-model="$v.aerosol_conductivity_flag.$model"
                        :state="validateState($v.aerosol_conductivity_flag)"
                        :options="flagOptionsAerosolConductivity"
                        class="col-12 mr-1" size="sm"
                        style="height: 33px;">
                        <!-- <option disabled value="">Pilih Flag</option>
                        <option value="M">M</option>
                        <option value="X">X</option>
                        <option value="V">V</option>
                        <option value="D">D</option>
                        <option value="L">L</option> -->
                      </b-form-select>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="6">
                  <b-form-group label="Cl- (Klorida)" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="aerosol_cl" 
                        :formatter="format_three_decimal"
                        v-model.number="$v.aerosol_cl.$model"
                        :state="validateState($v.aerosol_cl)" 
                        v-on:keyup="checkValidate($event, 'aerosol_cl')"
                        v-bind:class="{ rangecheck: RC.aerosol_cl.status }"
                        class="col-8 text-center mbtm" size="sm"
                        style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.aerosol_cl.status" target="aerosol_cl" :triggers="'hover'" placement="topleft">
                        {{ RC.aerosol_cl.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.aerosol_cl.status" :triggers="'hover'" target="aerosol_cl" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("aerosol_cl") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">mg/L</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">mg/L</label>
                </b-col> -->
                <b-col sm="1">
                </b-col>
                <b-col sm="4">
                  <b-form-group label="Cl- Flag" label-for="select-form" size="sm">
                    <b-form inline id="select-form">
                      <b-form-select  
                        v-model="$v.aerosol_cl_flag.$model"
                        :state="validateState($v.aerosol_cl_flag)"
                        :options="flagOptionsAerosolCl"
                        class="col-12 mr-1" size="sm" style="height: 33px;">
                        <!-- <option disabled value="">Pilih Flag</option>
                        <option value="M">M</option>
                        <option value="X">X</option>
                        <option value="V">V</option>
                        <option value="D">D</option>
                        <option value="L">L</option> -->
                      </b-form-select>
                    </b-form>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="6">
                  <b-form-group label="SO42- (Sulfat)" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="aerosol_so42" 
                        :formatter="format_three_decimal"
                        v-model.number="$v.aerosol_so42.$model"
                        :state="validateState($v.aerosol_so42)" 
                        v-on:keyup="checkValidate($event, 'aerosol_so42')"
                        v-bind:class="{ rangecheck: RC.aerosol_so42.status }"
                        class="col-8 text-center mbtm" size="sm"
                        style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.aerosol_so42.status" target="aerosol_so42" :triggers="'hover'" placement="topleft">
                        {{ RC.aerosol_so42.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.aerosol_so42.status" :triggers="'hover'" target="aerosol_so42" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("aerosol_so42") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">mg/L</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">mg/L</label>
                </b-col> -->
                <b-col sm="1">
                </b-col>
                <b-col sm="4">
                  <b-form-group label="SO42- Flag" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div>
                      <b-form-select 
                        v-model="$v.aerosol_so42_flag.$model"
                        :state="validateState($v.aerosol_so42_flag)"
                        :options="flagOptionsAerosolSo42"
                        class="col-12 mr-1" size="sm" style="height: 33px;">
                        <!-- <option disabled value="">Pilih Flag</option>
                        <option value="M">M</option>
                        <option value="X">X</option>
                        <option value="V">V</option>
                        <option value="D">D</option>
                        <option value="L">L</option> -->
                      </b-form-select>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="6">
                  <b-form-group label="NO3- (NItrat)" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="aerosol_no3" 
                        :formatter="format_three_decimal"
                        v-model.number="$v.aerosol_no3.$model"
                        :state="validateState($v.aerosol_no3)" 
                        v-on:keyup="checkValidate($event, 'aerosol_no3')"
                        v-bind:class="{ rangecheck: RC.aerosol_no3.status }"
                        class="col-8 text-center mbtm" size="sm"
                        style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.aerosol_no3.status" target="aerosol_no3" :triggers="'hover'" placement="topleft">
                        {{ RC.aerosol_no3.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.aerosol_no3.status" :triggers="'hover'" target="aerosol_no3" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("aerosol_no3") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">mg/L</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">mg/L</label>
                </b-col> -->
                <b-col sm="1">
                </b-col>
                <b-col sm="4">
                  <b-form-group label="NO3- Flag" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div>
                      <b-form-select 
                        v-model="$v.aerosol_no3_flag.$model"
                        :state="validateState($v.aerosol_no3_flag)"
                        :options="flagOptionsAerosolNo3"
                        class="col-12 mr-1" size="sm" style="height: 33px;">
                        <!-- <option disabled value="">Pilih Flag</option>
                        <option value="M">M</option>
                        <option value="X">X</option>
                        <option value="V">V</option>
                        <option value="D">D</option>
                        <option value="L">L</option> -->
                      </b-form-select>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="6">
                  <b-form-group label="Na+ (Natrium)" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="aerosol_na" 
                        :formatter="format_three_decimal"
                        v-model.number="$v.aerosol_na.$model"
                        :state="validateState($v.aerosol_na)" 
                        v-on:keyup="checkValidate($event, 'aerosol_na')"
                        v-bind:class="{ rangecheck: RC.aerosol_na.status }"
                        class="col-8 text-center mbtm" size="sm"
                        style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.aerosol_na.status" target="aerosol_na" :triggers="'hover'" placement="topleft">
                        {{ RC.aerosol_na.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.aerosol_na.status" :triggers="'hover'" target="aerosol_na" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("aerosol_na") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">mg/L</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">mg/L</label>
                </b-col> -->
                <b-col sm="1">
                </b-col>
                <b-col sm="4">
                  <b-form-group label="Na+  Flag" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div>
                      <b-form-select 
                        v-model="$v.aerosol_na_flag.$model"
                        :state="validateState($v.aerosol_na_flag)"
                        :options="flagOptionsAerosolNa"
                        class="col-12 mr-1" size="sm" style="height: 33px;">
                        <!-- <option disabled value="">Pilih Flag</option>
                        <option value="M">M</option>
                        <option value="X">X</option>
                        <option value="V">V</option>
                        <option value="D">D</option>
                        <option value="L">L</option> -->
                      </b-form-select>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="6">
                  <b-form-group label="NH4+ (Ammonium)" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="aerosol_nh4" 
                        :formatter="format_three_decimal"
                        v-model.number="$v.aerosol_nh4.$model"
                        :state="validateState($v.aerosol_nh4)" 
                        v-on:keyup="checkValidate($event, 'aerosol_nh4')"
                        v-bind:class="{ rangecheck: RC.aerosol_nh4.status }"
                        class="col-8 text-center mbtm" size="sm"
                        style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.aerosol_nh4.status" target="aerosol_nh4" :triggers="'hover'" placement="topleft">
                        {{ RC.aerosol_nh4.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.aerosol_nh4.status" :triggers="'hover'" target="aerosol_nh4" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("aerosol_nh4") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">gram</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">gram</label>
                </b-col> -->
                <b-col sm="1">
                </b-col>
                <b-col sm="4">
                  <b-form-group label="NH4+ Flag" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div>
                      <b-form-select 
                        v-model="$v.aerosol_nh4_flag.$model"
                        :state="validateState($v.aerosol_nh4_flag)"
                        :options="flagOptionsAerosolNh4"
                        class="col-12 mr-1" size="sm" style="height: 33px;">
                        <!-- <option disabled value="">Pilih Flag</option>
                        <option value="M">M</option>
                        <option value="X">X</option>
                        <option value="V">V</option>
                        <option value="D">D</option>
                        <option value="L">L</option> -->
                      </b-form-select>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="6">
                  <b-form-group label="K+ (Kalium)" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="aerosol_k" 
                        :formatter="format_three_decimal"
                        v-model.number="$v.aerosol_k.$model"
                        :state="validateState($v.aerosol_k)" 
                        v-on:keyup="checkValidate($event, 'aerosol_k')"
                        v-bind:class="{ rangecheck: RC.aerosol_k.status }"
                        class="col-8 text-center mbtm" size="sm"
                        style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.aerosol_k.status" target="aerosol_k" :triggers="'hover'" placement="topleft">
                        {{ RC.aerosol_k.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.aerosol_k.status" :triggers="'hover'" target="aerosol_k" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("aerosol_k") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">mg/L</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">mg/L</label>
                </b-col> -->
                <b-col sm="1">
                </b-col>
                <b-col sm="4">
                  <b-form-group label="K+ Flag" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div>
                      <b-form-select 
                        v-model="$v.aerosol_k_flag.$model"
                        :state="validateState($v.aerosol_k_flag)"
                        :options="flagOptionsAerosolK"
                        class="col-12 mr-1" size="sm" style="height: 33px;">
                        <!-- <option disabled value="">Pilih Flag</option>
                        <option value="M">M</option>
                        <option value="X">X</option>
                        <option value="V">V</option>
                        <option value="D">D</option>
                        <option value="L">L</option> -->
                      </b-form-select>
                      </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="6">
                  <b-form-group label="Mg2+ (Magnesium)" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <!-- <b-form-input v-model="aerosol_mg2" class="col-12 mr-1" size="sm" style="height: 33px;">
                                </b-form-input> -->
                      <b-form-input 
                        id="aerosol_mg2" 
                        :formatter="format_three_decimal"
                        v-model.number="$v.aerosol_mg2.$model"
                        :state="validateState($v.aerosol_mg2)" 
                        v-on:keyup="checkValidate($event, 'aerosol_mg2')"
                        v-bind:class="{ rangecheck: RC.aerosol_mg2.status }"
                        class="col-8 text-center mbtm" size="sm"
                        style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.aerosol_mg2.status" target="aerosol_mg2" :triggers="'hover'" placement="topleft">
                        {{ RC.aerosol_mg2.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.aerosol_mg2.status" :triggers="'hover'" target="aerosol_mg2" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("aerosol_mg2") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">mg/L</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">mg/L</label>
                </b-col> -->
                <b-col sm="1">
                </b-col>
                <b-col sm="4">
                  <b-form-group label="Mg2+  Flag" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div>
                      <b-form-select 
                        v-model="$v.aerosol_mg2_flag.$model"
                        :state="validateState($v.aerosol_mg2_flag)"
                        :options="flagOptionsAerosolMg2"
                        class="col-12 mr-1" size="sm" style="height: 33px;">
                        <!-- <option disabled value="">Pilih Flag</option>
                        <option value="M">M</option>
                        <option value="X">X</option>
                        <option value="V">V</option>
                        <option value="D">D</option>
                        <option value="L">L</option> -->
                      </b-form-select>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="6">
                  <b-form-group label="Ca2+ (Calsium)" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="aerosol_ca2" 
                        :formatter="format_three_decimal"
                        v-model.number="$v.aerosol_ca2.$model"
                        :state="validateState($v.aerosol_ca2)" 
                        v-on:keyup="checkValidate($event, 'aerosol_ca2')"
                        v-bind:class="{ rangecheck: RC.aerosol_ca2.status }"
                        class="col-8 text-center mbtm" size="sm"
                        style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.aerosol_ca2.status" target="aerosol_ca2" :triggers="'hover'" placement="topleft">
                        {{ RC.aerosol_ca2.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.aerosol_ca2.status" :triggers="'hover'" target="aerosol_ca2" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("aerosol_ca2") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">mg/L</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">mg/L</label>
                </b-col> -->
                <b-col sm="1">
                </b-col>
                <b-col sm="4">
                  <b-form-group label="Ca2+ Flag" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                    <div>
                      <b-form-select  
                        v-model="$v.aerosol_ca2_flag.$model"
                        :state="validateState($v.aerosol_ca2_flag)"
                        :options="flagOptionsAerosolCa2"
                        class="col-12 mr-1" size="sm" style="height: 33px;">
                        <!-- <option disabled value="">Pilih Flag</option>
                        <option value="M">M</option>
                        <option value="X">X</option>
                        <option value="V">V</option>
                        <option value="D">D</option>
                        <option value="L">L</option> -->
                      </b-form-select>
                      </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
              </b-row>

            </b-card>
          </b-col>

          <b-col cols="4">
            <b-card class="text-dark font-weight-bolder mt-n2 h-100">
              <b-row>
                <b-col sm="12">
                  <b-form-group label="Kondisi Cuaca Awal" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                      <!-- <b-form-select v-model="aerosol_kondisi_cuaca_awal" :options="kondisi_cuaca_awal_opt"
                        class="col-12 mr-1 select-size-sm" size="sm" style="height: 33px;">
                      </b-form-select> -->
                      <v-select v-model="aerosol_kondisi_cuaca_awal" :reduce="(option) => option.value" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="text" :options="kondisi_cuaca_awal_opt" class="select-size-sm">
                      </v-select>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="12">
                  <b-form-group label="Kondisi Cuaca Akhir" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                      <!-- <b-form-select v-model="aerosol_kondisi_cuaca_akhir" :options="kondisi_cuaca_akhir_opt"
                        class="col-12 mr-1 select-size-sm" size="sm" style="height: 33px;">
                      </b-form-select> -->
                      <v-select v-model="aerosol_kondisi_cuaca_akhir" :reduce="(option) => option.value" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="text" :options="kondisi_cuaca_akhir_opt" class="select-size-sm">
                      </v-select>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="12">
                  <b-form-group label="Kondisi Sampling" label-for="select-form" size="sm">
                    <!-- <b-form inline id="select-form"> -->
                      <!-- <b-form-select v-model="aerosol_kondisi_sampling" :options="kondisi_sampling_opt"
                        class="col-12 mr-1 select-size-sm" size="sm" style="height: 33px;">
                      </b-form-select> -->
                      <v-select v-model="aerosol_kondisi_sampling" :reduce="(option) => option.value" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="text" :options="kondisi_sampling_opt" class="select-size-sm">
                      </v-select>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <b-row>
                    <b-col>
                      <label for="textarea-catatan-lainnya" class="font-weight-bolder"> Catatan Lainnya </label>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12">
                      <b-form-textarea id="textarea-catatan-lainnya" placeholder="Catatan Lainnya"
                        v-model="aerosol_catatan_lainnya"></b-form-textarea>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <!-- buat table statis 2 kolom-->
              <b-row></b-row>
              <b-row style="margin-top:20px">
                <b-col cols="12">
                  <b-row>
                    <b-col sm="12">
                      <label for="keterangan" class="font-weight-bolder"> Keterangan </label>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-row>
                    <b-col sm="1">
                      <label for="label-keterangan" class="font-weight-bolder"> M </label>
                    </b-col>
                    <b-col sm="10">
                      <label for="label-keterangan-value" class="font-weight-bolder"> <span
                          style="margin-left:-6px">:</span> Missing - no value is available</label>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-row>
                    <b-col sm="1">
                      <label for="label-keterangan" class="font-weight-bolder"> X </label>
                    </b-col>

                    <b-col sm="10">
                      <label for="label-keterangan-value" class="font-weight-bolder"> <span
                          style="margin-left:-6px">:</span> Missing – value is invalid due to gauge malfunction,
                        precipitation sampler malfunction, sample contamination, mishandling, etc. </label>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-row>
                    <b-col sm="1">
                      <label for="label-keterangan" class="font-weight-bolder"> V </label>
                    </b-col>

                    <b-col sm="10">
                      <label for="label-keterangan-value" class="font-weight-bolder"> <span
                          style="margin-left:-6px">:</span> Valid measurement </label>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-row>
                    <b-col sm="1">
                      <label for="label-keterangan" class="font-weight-bolder"> D </label>
                    </b-col>

                    <b-col sm="10">
                      <label for="label-keterangan-value" class="font-weight-bolder"> <span
                          style="margin-left:-6px">:</span> Valid measurement that is at or below the method detection
                        limit and is set equal to the detection limit </label>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-row>
                    <b-col sm="1">
                      <label for="label-keterangan" class="font-weight-bolder"> L </label>
                    </b-col>

                    <b-col sm="10">
                      <label for="label-keterangan-value" class="font-weight-bolder"> <span
                          style="margin-left:-6px">:</span> Valid measurement that is below the method detection limit
                      </label>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

        </b-row>

      </b-col>
    </b-row>

    <b-row class="mt-n2">
      <b-col cols="12">
        <b-card>
          <b-row class="float-right">
            <div>
              <b-button variant="danger" @click="resetForm('batal')" class="mr-2" size="sm">
                <feather-icon
                  icon="XIcon"
                  class="mr-50"
                />
                <span class="align-middle">Batal</span>
                 
              </b-button>
              <b-button variant="warning" @click="V_Form" v-if="this.t_data_status == 'update'" class="mr-2" size="sm"
                :disabled="!roles.isRoleEdit">
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span class="align-middle">Update</span>
                 
              </b-button>
              <b-button variant="primary" @click="V_Form" v-if="this.t_data_status == 'insert'" class="mr-2" size="sm"
                :disabled="!roles.isRoleAdd">
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                <span class="align-middle">Submit</span>
                 
              </b-button>
            </div>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay>
  </div>
</template>
  
<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { BRow, BCol, BTable, BCardText, BFormInput, BContainer, BFormSelect, BForm, BButton, BCard, VBTooltip, BTooltip, BFormGroup, BFormDatepicker, BFormTextarea, BOverlay } from "bootstrap-vue";
import { methods } from "vue-echarts";
import axios from "axios";
import Helper from "@/api/helper";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import error_handler from "@/api/error_handler";
import { validationMixin } from "vuelidate";
import { required, minLength, between } from "vuelidate/lib/validators";
import { GC_AE_BOBOT_FILTER_KOSONG,GC_AE_BOBOT_FILTER_SAMPLING,GC_AE_TOTAL_WAKTU_SAMPLING,GC_AE_TEKANAN_UDARA_AWAL,GC_AE_TEKANAN_UDARA_AKHIR,GC_AE_TEMPERATUR_AWAL,
          GC_AE_TEMPERATUR_AKHIR, GC_AE_PH, GC_AE_CONDUCTIVITY, GC_AE_KONSENTRASI, GC_AE_CL, GC_AE_SO42, GC_AE_NO3, GC_AE_NA, GC_AE_NH4, GC_AE_K, GC_AE_MG2, GC_AE_CA2, GC_KU,
          GC_AE_FLOW_RATE_AWAL, GC_AE_FLOW_RATE_AKHIR } from "@/validators/kuvalidator";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import moment from 'moment';
import labkuservice from "@/api/labkuservice";
import Params from "@/attr/metadata/params";
import RC_helper from "@/validators/RC_helper";
export default {
  name: "Aerosol",
  components: {
    BCardActions,
    BRow,
    BCol,
    BTable,
    BCardText,
    BFormInput,
    BContainer,
    BButton,
    BCard,
    VBTooltip,
    BTooltip,
    ToastificationContent,
    BFormSelect,
    BForm,
    BFormGroup,
    BFormDatepicker,
    vSelect,
    BFormTextarea,
    BOverlay
  },
  mixins: [validationMixin],
  validations: {
    aerosol_bobot_filter_kosong: {
      GC_AE_BOBOT_FILTER_KOSONG
    },
    aerosol_bobot_filter_sampling: {
      GC_AE_BOBOT_FILTER_SAMPLING
    },
    aerosol_total_waktu_sampling: {
      GC_AE_TOTAL_WAKTU_SAMPLING
    },
    aerosol_tekanan_udara_awal: {
      GC_AE_TEKANAN_UDARA_AWAL
    },
    aerosol_tekanan_udara_akhir: {
      GC_AE_TEKANAN_UDARA_AKHIR
    },
    aerosol_temperatur_awal: {
      GC_AE_TEMPERATUR_AWAL
    },
    aerosol_temperatur_akhir: {
      GC_AE_TEMPERATUR_AKHIR
    },
    aerosol_ph: {  GC_AE_PH },
    aerosol_conductivity: {  GC_AE_CONDUCTIVITY },
    aerosol_konsentrasi: {  GC_AE_KONSENTRASI },
    aerosol_cl: {  GC_AE_CL },
    aerosol_so42: {  GC_AE_SO42 },
    aerosol_no3: {  GC_AE_NO3 },
    aerosol_na: {  GC_AE_NA },
    aerosol_nh4: {  GC_AE_NH4 },
    aerosol_k: {  GC_AE_K },
    aerosol_mg2: {  GC_AE_MG2 },
    aerosol_ca2: {  GC_AE_CA2 },
    aerosol_flow_rate_awal: {
      GC_AE_FLOW_RATE_AWAL,
      // consistency(val) {
      //   if (this.aerosol_flow_rate_awal_satuan == 'm3/min') {
      //     return ((val >= 0.565 && val <= 3.40) || ((val === "" || val === null) && val !== 0))
      //   }
      //   else if (this.aerosol_flow_rate_awal_satuan == 'ft3/min') {
      //     return ((val >= 20 && val <= 120) || ((val === "" || val === null) && val !== 0))
      //   }
      //   return true
      // }
    },
    aerosol_flow_rate_akhir: {
      GC_AE_FLOW_RATE_AKHIR
      // consistency(val) {
      //   if (this.aerosol_flow_rate_akhir_satuan == 'm3/min') {
      //     return ((val >= 0.565 && val <= 3.40) || ((val === "" || val === null) && val !== 0))
      //   }
      //   else if (this.aerosol_flow_rate_akhir_satuan == 'ft3/min') {
      //     return ((val >= 20 && val <= 120) || ((val === "" || val === null) && val !== 0))
      //   }
      //   return true
      // }
    },
    aerosol_ph_flag: {
      consistency(val) {
        if(this.aerosol_ph && this.aerosol_ph != "" && this.aerosol_ph != null) {
          return val ? true : false
        }
        return true
      }
    },
    aerosol_conductivity_flag: {
      consistency(val) {
        if(this.aerosol_conductivity && this.aerosol_conductivity != "" && this.aerosol_conductivity != null) {
          return val ? true : false
        }
        return true
      }
    },
    aerosol_cl_flag: {
      consistency(val) {
        if(this.aerosol_cl && this.aerosol_cl != "" && this.aerosol_cl != null) {
          return val ? true : false
        }
        return true
      }
    },     
    aerosol_so42_flag: {
      consistency(val) {
        if(this.aerosol_so42 && this.aerosol_so42 != "" && this.aerosol_so42 != null) {
          return val ? true : false
        }
        return true
      }
    },
    aerosol_no3_flag: {
      consistency(val) {
        if(this.aerosol_no3 && this.aerosol_no3 != "" && this.aerosol_no3 != null) {
          return val ? true : false
        }
        return true
      }
    },
    aerosol_na_flag: {
      consistency(val) {
        if(this.aerosol_na && this.aerosol_na != "" && this.aerosol_na != null) {
          return val ? true : false
        }
        return true
      }
    },
    aerosol_nh4_flag: {
      consistency(val) {
        if(this.aerosol_nh4 && this.aerosol_nh4 != "" && this.aerosol_nh4 != null) {
          return val ? true : false
        }
        return true
      }
    },
    aerosol_k_flag: {
      consistency(val) {
        if(this.aerosol_k && this.aerosol_k != "" && this.aerosol_k != null) {
          return val ? true : false
        }
        return true
      }
    },
    aerosol_mg2_flag: {
      consistency(val) {
        if(this.aerosol_mg2 && this.aerosol_mg2 != "" && this.aerosol_mg2 != null) {
          return val ? true : false
        }
        return true
      }
    },
    aerosol_ca2_flag: {
      consistency(val) {
        if(this.aerosol_ca2 && this.aerosol_ca2 != "" && this.aerosol_ca2 != null) {
          return val ? true : false
        }
        return true
      }
    },

  },
  props: ["t_station", "d_station", "t_id_form", "t_data", "t_data_status", "t_path", "t_path_update", "list_observer", "list_penyelia", "t_year", "t_periode", "roles","changeForm"],
  data() {
    return {
      dateDisable: false,
      showLoading: false,
      RC_helper_aerosol: { ...RC_helper },
      aerosol_ptgs_pasang_sampling: "",
      aerosol_ptgs_angkat_sampling: "",
      ptgs_analis: "",
      tgl_pasang: null,
      jam_pasang: "",
      tgl_angkat: null,
      jam_angkat: "",
      min: "",
      aerosol_id_sampel: null,
      aerosol_ph: null,
      aerosol_ph_flag: null,
      aerosol_conductivity: null,
      aerosol_konsentrasi: null,
      aerosol_conductivity_flag: null,
      aerosol_bobot_filter_kosong: null,
      aerosol_bobot_filter_sampling: null,
      aerosol_flow_rate_awal: null,
      aerosol_flow_rate_akhir: null,
      aerosol_total_waktu_sampling: null,
      aerosol_tekanan_udara_awal: null,
      aerosol_tekanan_udara_akhir: null,
      aerosol_temperatur_awal: null,
      aerosol_temperatur_akhir: null,
      aerosol_cl: null, 
      aerosol_cl_flag: null, 
      aerosol_so42: null,
      aerosol_so42_flag: null,
      aerosol_no3: null,
      aerosol_no3_flag: null,
      aerosol_na: null,
      aerosol_na_flag: null,
      aerosol_nh4: null,
      aerosol_nh4_flag: null,
      aerosol_k: null,
      aerosol_k_flag: null,
      aerosol_mg2: null,
      aerosol_mg2_flag: null,
      aerosol_ca2: null,
      aerosol_ca2_flag: null,
      aerosol_sekuens: null,
      aerosol_kondisi_cuaca_awal: "",
      aerosol_kondisi_cuaca_akhir: "",
      aerosol_kondisi_sampling: "",
      aerosol_kejadian_lainnya: "",
      aerosol_flow_rate_awal_satuan: "",
      aerosol_flow_rate_akhir_satuan: "",
      aerosol_catatan_lainnya: "",
      kondisi_cuaca_awal_opt: [],
      kondisi_cuaca_akhir_opt: [],
      kondisi_sampling_opt: [],
      qc_flag: 0,
      qc_flag_before: 0,
      last_qc_histories: {},
      DATA_RC: {},
      RC: {
        aerosol_flow_rate_awal: { status: false, message: "" },
        aerosol_flow_rate_akhir: { status: false, message: "" },
        aerosol_ph: { status: false, message: "" },
        aerosol_conductivity: { status: false, message: "" },
        aerosol_konsentrasi: { status: false, message: "" },
        aerosol_cl: { status: false, message: "" },
        aerosol_so42: { status: false, message: "" },
        aerosol_no3: { status: false, message: "" },
        aerosol_na: { status: false, message: "" },
        aerosol_nh4: { status: false, message: "" },
        aerosol_k: { status: false, message: "" },
        aerosol_mg2: { status: false, message: "" },
        aerosol_ca2: { status: false, message: "" },
        aerosol_bobot_filter_kosong: { status: false, message: "" },
        aerosol_bobot_filter_sampling: { status: false, message: "" },
        aerosol_total_waktu_sampling: { status: false, message: "" },
        aerosol_tekanan_udara_awal: { status: false, message: "" },
        aerosol_tekanan_udara_akhir: { status: false, message: "" },
        aerosol_temperatur_awal: { status: false, message: "" },
        aerosol_temperatur_akhir: { status: false, message: "" }
      },
      flagOptions: [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ],
      flagOptionsAerosolPh: [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ],
      flagOptionsAerosolConductivity: [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ],
      flagOptionsAerosolCl: [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ],
      flagOptionsAerosolSo42: [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ],
      flagOptionsAerosolNo3: [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ],
      flagOptionsAerosolNa: [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ],
      flagOptionsAerosolNh4: [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ],
      flagOptionsAerosolK: [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ],
      flagOptionsAerosolMg2: [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ],
      flagOptionsAerosolCa2: [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ],
    };
  },
  computed: {
    total: function () {

    },
  },
  mounted() {
    this.getKondisiCuacaAwal()
    this.getKondisiCuacaAkhir()
    this.getKondisiSampling()
    if (this.t_data_status == "update") {
      this.popupData();
    } else {
      this.resetForm();
      this.getSampelID()
    }
    this.initialize()
  },
  watch: {
    t_year(value) {
      this.getSampelID();
    },
    t_periode(value){
      this.getSampelID();
    },
    t_station(value) {
      // console.log("ubah station : ", this.t_path);
    },
    t_data_status(newValue, OldValue) {
      this.getKondisiCuacaAwal()
      this.getKondisiCuacaAkhir()
      this.getKondisiSampling()
      if (newValue == "update") {
        this.popupData();
      } else {
        this.resetForm();
        this.getSampelID()
      }
      this.checkValidateAll()
    },
    count_form_click(NewValue, OldValue) {
      if (this.t_data_status == "insert") {
        if (NewValue != OldValue) {
          this.resetForm();
        }
      }
    },

    aerosol_ph(newValue, OldValue) {
      if (newValue == 9999) {
        let flagOptions = this.checkFlagOptionSelection(true, newValue, this.flagOptionsAerosolPh)
        this.flagOptionsAerosolPh = flagOptions
        this.aerosol_ph_flag = 'M'
      }
      else {
        if (OldValue == 9999) {
          let flagOptions = this.checkFlagOptionSelection(false, OldValue, this.flagOptionsAerosolPh)
          this.flagOptionsAerosolPh = flagOptions
          this.aerosol_ph_flag = ''
        }
      }
    },
    aerosol_conductivity(newValue, OldValue) {
      if (newValue == 9999) {
        let flagOptions = this.checkFlagOptionSelection(true, newValue, this.flagOptionsAerosolConductivity)
        this.flagOptionsAerosolConductivity = flagOptions
        this.aerosol_conductivity_flag = 'M'
      }
      else {
        if (OldValue == 9999) {
          let flagOptions = this.checkFlagOptionSelection(false, OldValue, this.flagOptionsAerosolConductivity)
          this.flagOptionsAerosolConductivity = flagOptions
          this.aerosol_conductivity_flag = ''
        }
      }
    },
    aerosol_cl(newValue, OldValue) {
      if (newValue == 9999) {
        let flagOptions = this.checkFlagOptionSelection(true, newValue, this.flagOptionsAerosolCl)
        this.flagOptionsAerosolCl = flagOptions
        this.aerosol_cl_flag = 'M'
      }
      else {
        if (OldValue == 9999) {
          let flagOptions = this.checkFlagOptionSelection(false, OldValue, this.flagOptionsAerosolCl)
          this.flagOptionsAerosolCl = flagOptions
          this.aerosol_cl_flag = ''
        }
      }
    },
    aerosol_so42(newValue, OldValue) {
      if (newValue == 9999) {
        let flagOptions = this.checkFlagOptionSelection(true, newValue, this.flagOptionsAerosolSo42)
        this.flagOptionsAerosolSo42 = flagOptions
        this.aerosol_so42_flag = 'M'
      }
      else {
        if (OldValue == 9999) {
          let flagOptions = this.checkFlagOptionSelection(false, OldValue, this.flagOptionsAerosolSo42)
          this.flagOptionsAerosolSo42 = flagOptions
          this.aerosol_so42_flag = ''
        }
      }
    },
    aerosol_no3(newValue, OldValue) {
      if (newValue == 9999) {
        let flagOptions = this.checkFlagOptionSelection(true, newValue, this.flagOptionsAerosolNo3)
        this.flagOptionsAerosolNo3 = flagOptions
        this.aerosol_no3_flag = 'M'
      }
      else {
        if (OldValue == 9999) {
          let flagOptions = this.checkFlagOptionSelection(false, OldValue, this.flagOptionsAerosolNo3)
          this.flagOptionsAerosolNo3 = flagOptions
          this.aerosol_no3_flag = ''
        }
      }
    },
    aerosol_na(newValue, OldValue) {
      if (newValue == 9999) {
        let flagOptions = this.checkFlagOptionSelection(true, newValue, this.flagOptionsAerosolNa)
        this.flagOptionsAerosolNa = flagOptions
        this.aerosol_na_flag = 'M'
      }
      else {
        if (OldValue == 9999) {
          let flagOptions = this.checkFlagOptionSelection(false, OldValue, this.flagOptionsAerosolNa)
          this.flagOptionsAerosolNa = flagOptions
          this.aerosol_na_flag = ''
        }
      }
    },
    aerosol_nh4(newValue, OldValue) {
      if (newValue == 9999) {
        let flagOptions = this.checkFlagOptionSelection(true, newValue, this.flagOptionsAerosolNh4)
        this.flagOptionsAerosolNh4 = flagOptions
        this.aerosol_nh4_flag = 'M'
      }
      else {
        if (OldValue == 9999) {
          let flagOptions = this.checkFlagOptionSelection(false, OldValue, this.flagOptionsAerosolNh4)
          this.flagOptionsAerosolNh4 = flagOptions
          this.aerosol_nh4_flag = ''
        }
      }
    },
    aerosol_k(newValue, OldValue) {
      if (newValue == 9999) {
        let flagOptions = this.checkFlagOptionSelection(true, newValue, this.flagOptionsAerosolK)
        this.flagOptionsAerosolK = flagOptions
        this.aerosol_k_flag = 'M'
      }
      else {
        if (OldValue == 9999) {
          let flagOptions = this.checkFlagOptionSelection(false, OldValue, this.flagOptionsAerosolK)
          this.flagOptionsAerosolK = flagOptions
          this.aerosol_k_flag = ''
        }
      }
    },
    aerosol_mg2(newValue, OldValue) {
      if (newValue == 9999) {
        let flagOptions = this.checkFlagOptionSelection(true, newValue, this.flagOptionsAerosolMg2)
        this.flagOptionsAerosolMg2 = flagOptions
        this.aerosol_mg2_flag = 'M'
      }
      else {
        if (OldValue == 9999) {
          let flagOptions = this.checkFlagOptionSelection(false, OldValue, this.flagOptionsAerosolMg2)
          this.flagOptionsAerosolMg2 = flagOptions
          this.aerosol_mg2_flag = ''
        }
      }
    },
    aerosol_ca2(newValue, OldValue) {
      if (newValue == 9999) {
        let flagOptions = this.checkFlagOptionSelection(true, newValue, this.flagOptionsAerosolCa2)
        this.flagOptionsAerosolCa2 = flagOptions
        this.aerosol_ca2_flag = 'M'
      }
      else {
        if (OldValue == 9999) {
          let flagOptions = this.checkFlagOptionSelection(false, OldValue, this.flagOptionsAerosolCa2)
          this.flagOptionsAerosolCa2 = flagOptions
          this.aerosol_ca2_flag = ''
        }
      }
    },

    tgl_pasang(value) {
      // console.log("tgl_pasang", value)
      if(value) {
        this.min = value
        if (this.tgl_angkat) {
          let dpasang = new Date(value)
          let dangkat = new Date(this.tgl_angkat)
          if (dangkat < dpasang) {
            this.tgl_angkat = ""
            this.min = value
          }
        }
        
      }
    },

    tgl_angkat(value) {
      // console.log("tgl_angkat", value)
      if(value) {
        this.min = this.tgl_pasang ? this.tgl_pasang : null
        if (this.tgl_pasang) {
          let dpasang = new Date(this.tgl_pasang)
          let dangkat= new Date(value)
          if (dangkat < dpasang) {
            this.tgl_angkat = ""
            this.min = this.tgl_pasang
            this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Tanggal Pengangkatan harus lebih besar dari Tanggal Pemasangan. Periksa kembali inputan anda",
                  icon: "BellIcon",
                  variant: "danger",
                },
              });
          }
        }     
      }
    }
  },
  methods: {

    toggleToItemEditForm() {
      console.log('toggleToItemEditForm', this.$refs.ca2ref);
    },

    toggleToTab(event) {
      console.log('toggleToTab', this.$refs.ca2ref, event);
    },

    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } if (evt.key == "." || evt.key == ",") {
        return evt.preventDefault();
      } else {
        return true;
      }
    },

    format_one_decimal(e) {
      if (Math.round(e * 10) / 10 != e) {
        let text = String(e);
        let filter = text.substring(0, text.length - 1);
        // console.log("filter", filter);
        return filter;
      } else {
        if (Math.floor(e) === e) {
          // console.log("filter e", e);
          return e;
        } else {
          // console.log("filter else", e);
          if (e.toString().indexOf(".") > 0) {
            let count = e.toString().split(".")[1].length || 0;
            return count > 1 ? parseFloat(e).toFixed(1) : e;
          } else {
            return e;
          }
        }
        return e;
      }
    },

    format_two_decimal(e, event) {
      if (Math.round(e * 100) / 100 != e) {
        let text = String(e);
        let filter = text.substring(0, text.length - 1);
        return filter;
      } else {
        if (Math.floor(e) === e) {
          return e;
        } else {
          if (e.toString().indexOf(".") > 0) {
            let count = e.toString().split(".")[1].length || 0;
            return count > 2 ? parseFloat(e).toFixed(2) : e;
          } else {
            return e;
          }
        }

        return e;
      }
    },

    format_three_decimal(e) {
      if (Math.round(e * 1000) / 1000 != e) {
        let text = String(e);
        let filter = text.substring(0, text.length - 1);
        // console.log("filter", filter);
        return filter;
      } else {
        if (Math.floor(e) === e) {
          // console.log("filter e", e);
          return e;
        } else {
          // console.log("filter else", e);
          if (e.toString().indexOf(".") > 0) {
            let count = e.toString().split(".")[1].length || 0;
            return count > 3 ? parseFloat(e).toFixed(3) : e;
          } else {
            return e;
          }
        }
        return e;
      }
    },

    format_four_decimal(e, event) {
      if (Math.round(e * 10000) / 10000 != e) {
        let text = String(e);
        let filter = text.substring(0, text.length - 1);
        return filter;
      } else {
        if (Math.floor(e) === e) {
          return e;
        } else {
          if (e.toString().indexOf(".") > 0) {
            let count = e.toString().split(".")[1].length || 0;
            return count > 4 ? parseFloat(e).toFixed(4) : e;
          } else {
            return e;
          }
        }

        return e;
      }
    },

    initialize() {

      this.setQCData();

      if (this.t_data_status == "update") {
        this.checkValidateAll()
      }
    },

    async getSampelID() {
      try {
        let { data } = await labkuservice.getUsageStationID(this.t_station.path)
        var kodestasiun = data['BmkgSatu.metadata.station.behavusage.IBehavStationUsage']['usage_station_id']
      } catch (e) {
        console.log(e)
      }
      try {
        const { data } = await labkuservice.getSekuens(this.t_path, String(this.t_year.title));
        var sekuens = data.items_total
      } catch (e) {
        console.log(e)
      }
      var tahun = parseInt(this.t_year.title)
      var tglAwalTahun = moment('2022-01-03T09:00').utcOffset('+0700')
      /**
       * Tanggal 3 Januari 2022 kebetulan adalah tanggal patokan hitung P1 untuk Lab KU
       * 3 Januari 2022 adalah tanggal PASANG pertama, menandakan starting point 
       * untuk menghitung Periode 1 di tahun 2022
       * 
       * NOTE: basis periode adalah tanggal angkat, bukan tanggal pasang
       * 
       * Konsepnya adalah menggunakan basis 3 Januari 2022, dihitunglah P1 untuk tahun-tahun
       * yang lain, baik sebelum maupun sesudah 2022
       * 
       */
      if (tahun > 2022) {
        tglAwalTahun.add((parseInt(this.t_year.title) - 2022) * 6 * 61, 'days');
        let d = tglAwalTahun.diff(moment(tahun + '-01-01T09:00'), 'days');
        if (d > 6) {
          tglAwalTahun.subtract(Math.floor(d / 6) * 6, 'days');
        }
      } else if (tahun < 2022) {
        tglAwalTahun.subtract((2022 - parseInt(this.t_year.title)) * 6 * 60, 'days');
        let d = tglAwalTahun.diff(moment(tahun +'-01-01T09:00').utcOffset('+0700'), "days");
        if (d > 6) {
          tglAwalTahun.subtract(Math.floor(d / 6) * 6, 'days');
        }
      }
      var tanggal_pasang = moment(tglAwalTahun).add((this.t_periode.value - 1) * 6, 'days');
      var tanggal_angkat = moment(tanggal_pasang).add(1, 'days');
      this.tgl_pasang = tanggal_pasang.format()
      this.tgl_angkat = tanggal_angkat.format()
      this.jam_pasang = "09"//"09:00"
      this.jam_angkat = "09"//"09:00"
      var periode = String(this.t_periode.value).padStart(2, "0");
      var bulan = tanggal_angkat.format('MM');
      let id_sampel = kodestasiun + "" + String(this.t_year.title).substring(2, 4) + "" + bulan + "" + periode + "" + String(parseInt(sekuens) + 1).padStart(2, "0");
      this.aerosol_id_sampel = id_sampel
      return id_sampel
    },

    async getKondisiCuacaAwal() {
      try {
        const { data } = await labkuservice.listKondisiCuacaAwal();
        let items = data.items
        let kondisi = []
        for (var i = 0; i < items.length; i++) {
          const item_kondisi = {
            value: items[i].sb_value,
            text: items[i].sb_label,
            path: items[i].path,
          };
          kondisi.push(item_kondisi)
        }
        this.kondisi_cuaca_awal_opt = kondisi
        //make kondisi cuaca awal order by name asc
        this.kondisi_cuaca_awal_opt.sort(function (a, b) {
          var nameA = a.text.toUpperCase(); // ignore upper and lowercase
          var nameB = b.text.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        });
      } catch (e) {
        console.log(e);
      }
    },

    async getKondisiCuacaAkhir() {
      try {
        const { data } = await labkuservice.listKondisiCuacaAkhir();
        let items = data.items
        let kondisi = []
        for (var i = 0; i < items.length; i++) {
          const item_kondisi = {
            value: items[i].sb_value,
            text: items[i].sb_label,
            path: items[i].path,
          };
          kondisi.push(item_kondisi)
        }
        this.kondisi_cuaca_akhir_opt = kondisi
        //make kondisi cuaca akhir order by name asc
        this.kondisi_cuaca_akhir_opt.sort(function (a, b) {
          var nameA = a.text.toUpperCase(); // ignore upper and lowercase
          var nameB = b.text.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        });
      } catch (e) {
        console.log(e);
      }
    },

    async getKondisiSampling() {
      try {
        const { data } = await labkuservice.listKondisiSampling();
        let items = data.items
        let kondisi = []
        for (var i = 0; i < items.length; i++) {
          const item_kondisi = {
            value: items[i].sb_value,
            text: items[i].sb_label,
            path: items[i].path,
          };
          kondisi.push(item_kondisi)
        }
        this.kondisi_sampling_opt = kondisi
        //make kondisi sampling order by name asc
        this.kondisi_sampling_opt.sort(function (a, b) {
          var nameA = a.text.toUpperCase(); // ignore upper and lowercase
          var nameB = b.text.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        });
      } catch (e) {
        console.log(e);
      }
    },

    checkFlagOptionSelection(isNewValue, value, previousFlagOptions) {
      if (isNewValue && value == 9999) {
        let flagOptions = [...previousFlagOptions]
        flagOptions.forEach(function(item){ 
          if (item) {
            if(item.value !== 'M') {
              item['disabled'] = true
            }
            else if(item.value === '') {
              item['disabled'] = true
            }
            else {
              item['disabled'] = false
            }
          }
        })
        return flagOptions
      }
      else {
        if (!isNewValue && value == 9999) {
          let flagOptions = [...previousFlagOptions]
          flagOptions.forEach(function(item){ 
            if (item) {
              if(item.value === 'M') {
                item['disabled'] = true
              }
              else if(item.value === '') {
                item['disabled'] = true
              }
              else {
                item['disabled'] = false
              }
            }
          })
          return flagOptions
        }
      }
      return []
    },


    onKeyCheck(event) {
      // console.log('onKeyCheck', event)
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault()
      }
    },
    format_one_decimal(e) {
      if (Math.round(e * 10) / 10 != e) {
        let text = String(e);
        let filter = text.substring(0, text.length - 1);
        return filter;
      } else {
        if (Math.floor(e) === e) {
          return e;
        } else {
          if (e.toString().indexOf(".") > 0) {
            let count = e.toString().split(".")[1].length || 0;
            return count > 1 ? parseFloat(e).toFixed(1) : e;
          } else {
            return e;
          }
        }
        return e;
      }
    },
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },

    tooltipMessage(attr) {
      if (attr == 'aerosol_flow_rate_awal') {
        // if (this.aerosol_flow_rate_awal_satuan == 'm3/min') {
        //   return GC_KU('aerosol_flow_rate_awal_m3').message;
        // }
        // else if (this.aerosol_flow_rate_awal_satuan == 'ft3/min') {
        //   return GC_KU('aerosol_flow_rate_awal_ft3').message;
        // }
        // else {
           return GC_KU('aerosol_flow_rate_awal').message;
        // }
      }
      else if (attr == 'aerosol_flow_rate_akhir') {
        // if (this.aerosol_flow_rate_akhir_satuan == 'm3/min') {
        //   return GC_KU('aerosol_flow_rate_akhir_m3').message;
        // }
        // else if (this.aerosol_flow_rate_akhir_satuan == 'ft3/min') {
        //   return GC_KU('aerosol_flow_rate_akhir_ft3').message;
        // }
        // else {
          return GC_KU('aerosol_flow_rate_akhir').message;
        // }
      }

      return GC_KU(attr).message;
    },

    V_Form() {
      this.$v.$touch();
      // console.log('this.$v', this.$v)
      if (!this.aerosol_ptgs_pasang_sampling || !this.aerosol_ptgs_angkat_sampling 
          || !this.ptgs_analis || !this.tgl_pasang || !this.jam_pasang || !this.tgl_angkat || !this.jam_angkat
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Data tidak valid. Periksa kembali inputan anda",
            icon: "BellIcon",
            variant: "danger",
          },
        });
        return;
      }
      if (this.$v.$invalid) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Data tidak valid. Periksa kembali inputan anda",
            icon: "BellIcon",
            variant: "danger",
          },
        });
        return;
      } else {

        let dataRC = Object.entries(this.DATA_RC).filter((e) => e[1].status);
        // console.log('this.DATA_RC', this.DATA_RC, dataRC)
        let temp_QC_After = [];
        for (var i in dataRC) {
          let row_qc_after = {
            raw_type: "SO2NO2",
            atribut: dataRC[i][0],
            value: this[dataRC[i][0]],
            roles: dataRC[i][1].message,
            type: "range_check",
            message: dataRC[i][1].message,
          };
          temp_QC_After.push(row_qc_after);
        }

        // console.log("QC  AFTER : ",temp_QC_After);
        // this.last_qc_histories["before"] = "-";
        this.last_qc_histories["after"] = temp_QC_After;

        if (dataRC.length > 0) {
          this.$swal({
            title: "Apakah Anda Yakin Untuk Simpan Data ?",
            text: "Data Anda Terkoreksi Range Check ",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Submit",
            cancelButtonText: "Cek Data",
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              if (this.t_data_status == "insert") {
                this.sumbitAerosol();
              } else if (this.t_data_status == "update") {
                this.updateData();
              }
            } else if (result.dismiss === "cancel") {
              return;
            }
          });
        } else {
          if (this.t_data_status == "insert") {
            this.sumbitAerosol();
          } else if (this.t_data_status == "update") {
            this.updateData();
          }
        }
        // if (this.t_data_status == "insert") {
        //   this.sumbitAerosol();
        // } else if (this.t_data_status == "update") {
        //   this.updateData();
        // }
      }
    },
    popupData() {
      let p_pasang_sampling = this.list_observer.find(item => item.observer == this.t_data.aerosol_ptgs_pasang_sampling)
      let p_angkat_sampling = this.list_observer.find(item => item.observer == this.t_data.aerosol_ptgs_angkat_sampling)
      let p_analis_lab = this.list_penyelia.find(item => item.value == this.t_data.ptgs_analis)
      this.aerosol_ptgs_pasang_sampling = p_pasang_sampling
      this.aerosol_ptgs_angkat_sampling = p_angkat_sampling
      this.ptgs_analis = p_analis_lab
      this.tgl_pasang = this.t_data.tgl_pasang
      this.jam_pasang = ""
      this.tgl_angkat = this.t_data.tgl_angkat
      this.jam_angkat = ""
      this.aerosol_id_sampel = this.t_data.aerosol_id_sampel
      this.aerosol_ph = this.t_data.aerosol_ph
      this.aerosol_ph_flag = this.t_data.aerosol_ph_flag
      this.aerosol_conductivity = this.t_data.aerosol_conductivity
      this.aerosol_konsentrasi = this.t_data.aerosol_konsentrasi
      this.aerosol_conductivity_flag = this.t_data.aerosol_conductivity_flag
      this.aerosol_bobot_filter_kosong = this.t_data.aerosol_bobot_filter_kosong
      this.aerosol_bobot_filter_sampling = this.t_data.aerosol_bobot_filter_sampling
      this.aerosol_flow_rate_awal = this.t_data.aerosol_flow_rate_awal
      this.aerosol_flow_rate_akhir = this.t_data.aerosol_flow_rate_akhir
      this.aerosol_total_waktu_sampling = this.t_data.aerosol_total_waktu_sampling
      this.aerosol_tekanan_udara_awal = this.t_data.aerosol_tekanan_udara_awal
      this.aerosol_tekanan_udara_akhir = this.t_data.aerosol_tekanan_udara_akhir
      this.aerosol_temperatur_awal = this.t_data.aerosol_temperatur_awal
      this.aerosol_temperatur_akhir = this.t_data.aerosol_temperatur_akhir
      this.aerosol_cl = this.t_data.aerosol_cl
      this.aerosol_cl_flag = this.t_data.aerosol_cl_flag
      this.aerosol_so42 = this.t_data.aerosol_so42
      this.aerosol_so42_flag = this.t_data.aerosol_so42_flag
      this.aerosol_no3 = this.t_data.aerosol_no3
      this.aerosol_no3_flag = this.t_data.aerosol_no3_flag
      this.aerosol_na = this.t_data.aerosol_na
      this.aerosol_na_flag = this.t_data.aerosol_na_flag
      this.aerosol_nh4 = this.t_data.aerosol_nh4
      this.aerosol_nh4_flag = this.t_data.aerosol_nh4_flag
      this.aerosol_k = this.t_data.aerosol_k
      this.aerosol_k_flag = this.t_data.aerosol_k_flag
      this.aerosol_mg2 = this.t_data.aerosol_mg2
      this.aerosol_mg2_flag = this.t_data.aerosol_mg2_flag
      this.aerosol_ca2 = this.t_data.aerosol_ca2
      this.aerosol_ca2_flag = this.t_data.aerosol_ca2_flag
      this.aerosol_kondisi_cuaca_awal = this.t_data.aerosol_kondisi_cuaca_awal
      this.aerosol_kondisi_cuaca_akhir = this.t_data.aerosol_kondisi_cuaca_akhir
      this.aerosol_kondisi_sampling = this.t_data.aerosol_kondisi_sampling
      this.aerosol_kejadian_lainnya = this.t_data.aerosol_kejadian_lainnya
      this.aerosol_catatan_lainnya = this.t_data.aerosol_catatan_lainnya
      this.qc_flag_before = this.t_data.qc_flag;
      this.QC_Histories = this.t_data.qc_histories

      if (this.tgl_pasang) {
        let tglp = this.tgl_pasang.split('T')
        let jamp = tglp[1].split(':')[0]
        this.jam_pasang = jamp
      }

      if (this.tgl_angkat) {
        let tgla = this.tgl_angkat.split('T')
        let jama = tgla[1].split(':')[0]
        this.jam_angkat = jama
      }

      if (!this.t_data.aerosol_id_sampel) {
        this.getSampelID()
      }

      this.$v.$touch();
    },

    updateData() {
      const modification_date = this.t_date;
      if (this.jam_pasang) {
        let tglp = this.tgl_pasang.split('T')[0]
        this.tgl_pasang = tglp + 'T' + this.jam_pasang + ':00:00'
      }
      if (this.jam_pasang) {
        let tgla = this.tgl_angkat.split('T')[0]
        this.tgl_angkat = tgla + 'T' + this.jam_angkat + ':00:00'
      }

      // CHECK QC
      var len_qc_after = (this.last_qc_histories) ? this.last_qc_histories["after"].length : 0;
      var temp_qc_histories = {};
      if (this.QC_Histories && this.QC_Histories != "" || this.QC_Histories != null) {
        temp_qc_histories = JSON.parse(this.QC_Histories);
      }
      // console.log("TEMP QC : ", temp_qc_histories);
      this.last_qc_histories["before"] = [];
      if (temp_qc_histories != null) {
        if (temp_qc_histories["after"] != undefined) {
          this.last_qc_histories["before"] = temp_qc_histories["after"];
        }
      }

      var flag_temp = this.qc_flag_before ? this.qc_flag_before : 0;
      if (this.qc_flag_before != 2) {
        flag_temp = this.qc_flag_before;

        if (len_qc_after > 0) {
          flag_temp = 1;
        } 
        else {
          flag_temp = 0;
        }
      }

      var qc_temp = JSON.stringify(this.last_qc_histories);
      // console.log('this.aerosol_ptgs_pasang_sampling', this.aerosol_ptgs_pasang_sampling, this.aerosol_ptgs_angkat_sampling)
      const post_data = {
        aerosol_ptgs_pasang_sampling: this.aerosol_ptgs_pasang_sampling.observer,
        aerosol_ptgs_angkat_sampling: this.aerosol_ptgs_angkat_sampling.observer,
        ptgs_analis: this.ptgs_analis?.value ? this.ptgs_analis.value : null,
        tgl_pasang: this.tgl_pasang,
        tgl_angkat: this.tgl_angkat,
        aerosol_id_sampel: this.aerosol_id_sampel,
        aerosol_ph: this.aerosol_ph ? this.aerosol_ph : null,
        aerosol_ph_flag: this.aerosol_ph_flag,
        aerosol_conductivity: this.aerosol_conductivity ? this.aerosol_conductivity : null,
        aerosol_konsentrasi: this.aerosol_konsentrasi ? this.aerosol_konsentrasi : null,
        aerosol_conductivity_flag: this.aerosol_conductivity_flag,
        aerosol_bobot_filter_kosong: this.aerosol_bobot_filter_kosong ? this.aerosol_bobot_filter_kosong : null,
        aerosol_bobot_filter_sampling: this.aerosol_bobot_filter_sampling ? this.aerosol_bobot_filter_sampling : null,
        aerosol_flow_rate_awal: this.aerosol_flow_rate_awal ? this.aerosol_flow_rate_awal : null,
        aerosol_flow_rate_akhir: this.aerosol_flow_rate_akhir ? this.aerosol_flow_rate_akhir : null,
        aerosol_total_waktu_sampling: this.aerosol_total_waktu_sampling ? this.aerosol_total_waktu_sampling : null,
        aerosol_tekanan_udara_awal: this.aerosol_tekanan_udara_awal ? this.aerosol_tekanan_udara_awal : null,
        aerosol_tekanan_udara_akhir: this.aerosol_tekanan_udara_akhir ? this.aerosol_tekanan_udara_akhir : null,
        aerosol_temperatur_awal: this.aerosol_temperatur_awal ? this.aerosol_temperatur_awal : null,
        aerosol_temperatur_akhir: this.aerosol_temperatur_akhir ? this.aerosol_temperatur_akhir : null,
        aerosol_cl: this.aerosol_cl ? this.aerosol_cl : null,
        aerosol_cl_flag: this.aerosol_cl_flag,
        aerosol_so42: this.aerosol_so42 ? this.aerosol_so42 : null,
        aerosol_so42_flag: this.aerosol_so42_flag,
        aerosol_no3: this.aerosol_no3 ? this.aerosol_no3 : null,
        aerosol_no3_flag: this.aerosol_no3_flag,
        aerosol_na: this.aerosol_na ? this.aerosol_na : null,
        aerosol_na_flag: this.aerosol_na_flag,
        aerosol_nh4: this.aerosol_nh4 ? this.aerosol_nh4 : null,
        aerosol_nh4_flag: this.aerosol_nh4_flag,
        aerosol_k: this.aerosol_k ? this.aerosol_k : null,
        aerosol_k_flag: this.aerosol_k_flag,
        aerosol_mg2: this.aerosol_mg2 ? this.aerosol_mg2 : null,
        aerosol_mg2_flag: this.aerosol_mg2_flag,
        aerosol_ca2: this.aerosol_ca2 ? this.aerosol_ca2 : null,
        aerosol_ca2_flag: this.aerosol_ca2_flag,
        aerosol_kondisi_cuaca_awal: this.aerosol_kondisi_cuaca_awal,
        aerosol_kondisi_cuaca_akhir: this.aerosol_kondisi_cuaca_akhir,
        aerosol_kondisi_sampling: this.aerosol_kondisi_sampling,
        aerosol_kejadian_lainnya: this.aerosol_kejadian_lainnya,
        aerosol_catatan_lainnya: this.aerosol_catatan_lainnya,
        station_id: this.t_station.value,
        station_wmo_id: this.t_station.wmo,
        station_name: this.t_station.text,
        current_longitude: this.t_station.longitude,
        current_latitude: this.t_station.latitude,
        periode_form: this.t_periode.value,
        tahun_form: this.tgl_angkat,
        qc_flag: flag_temp,
        qc_histories: qc_temp
      };

      const update_url = Helper.getBaseUrl() + this.t_path_update;
      this.showLoading = true
      axios
        .patch(update_url, post_data, Helper.getConfig())
        .then((response) => {
          this.$swal({
            title: "Update data sukses!",
            text: "Data Telah berhasil di Update",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.showLoading = false
          this.resetForm();
          this.changeForm(null);
        })
        .catch((error) => {
          this.showLoading = false
          console.log(error);
          error_handler(this, error, "Aerosol", "Input Data Error");
        });
    },
    sumbitAerosol() {
      // console.log('sumbitAerosol', this.tgl_pasang);
      //return
      const aerosol_id = this.t_id_form;//this.t_station_id + "_aerosol_" + this.t_date;
      // const data_timestamp = this.t_date;
      if (this.jam_pasang) {
        let tglp = this.tgl_pasang.split('T')[0]
        this.tgl_pasang = tglp + 'T' + this.jam_pasang + ':00:00'
      }
      if (this.jam_pasang) {
        let tgla = this.tgl_angkat.split('T')[0]
        this.tgl_angkat = tgla + 'T' + this.jam_angkat + ':00:00'
      }

      var len_qc_after = this.last_qc_histories["after"].length;
      this.last_qc_histories["before"] = [];
      // console.log("ada QC : ",len_qc_after);
      var flag_temp = 0;
      if (len_qc_after > 0) {
        flag_temp = 1;
      }
      var qc_temp = JSON.stringify(this.last_qc_histories);

      const post_data = {
        "@type": "Aerosol",
        id: aerosol_id,
        aerosol_ptgs_pasang_sampling: this.aerosol_ptgs_pasang_sampling.observer,
        aerosol_ptgs_angkat_sampling: this.aerosol_ptgs_angkat_sampling.observer,
        ptgs_analis: this.ptgs_analis?.value ? this.ptgs_analis.value : null,
        tgl_pasang: this.tgl_pasang,
        tgl_angkat: this.tgl_angkat,
        aerosol_id_sampel: this.aerosol_id_sampel,
        aerosol_ph: this.aerosol_ph ? this.aerosol_ph : null,
        aerosol_ph_flag: this.aerosol_ph_flag,
        aerosol_conductivity: this.aerosol_conductivity ? this.aerosol_conductivity : null,
        aerosol_konsentrasi: this.aerosol_konsentrasi ? this.aerosol_konsentrasi : null,
        aerosol_conductivity_flag: this.aerosol_conductivity_flag,
        aerosol_bobot_filter_kosong: this.aerosol_bobot_filter_kosong ? this.aerosol_bobot_filter_kosong : null,
        aerosol_bobot_filter_sampling: this.aerosol_bobot_filter_sampling ? this.aerosol_bobot_filter_sampling : null,
        aerosol_flow_rate_awal: this.aerosol_flow_rate_awal ? this.aerosol_flow_rate_awal : null,
        aerosol_flow_rate_akhir: this.aerosol_flow_rate_akhir ? this.aerosol_flow_rate_akhir : null,
        aerosol_total_waktu_sampling: this.aerosol_total_waktu_sampling ? this.aerosol_total_waktu_sampling : null,
        aerosol_tekanan_udara_awal: this.aerosol_tekanan_udara_awal ? this.aerosol_tekanan_udara_awal : null,
        aerosol_tekanan_udara_akhir: this.aerosol_tekanan_udara_akhir ? this.aerosol_tekanan_udara_akhir : null,
        aerosol_temperatur_awal: this.aerosol_temperatur_awal ? this.aerosol_temperatur_awal : null,
        aerosol_temperatur_akhir: this.aerosol_temperatur_akhir ? this.aerosol_temperatur_akhir : null,
        aerosol_cl: this.aerosol_cl ? this.aerosol_cl : null,
        aerosol_cl_flag: this.aerosol_cl_flag,
        aerosol_so42: this.aerosol_so42 ? this.aerosol_so42 : null,
        aerosol_so42_flag: this.aerosol_so42_flag,
        aerosol_no3: this.aerosol_no3 ? this.aerosol_no3 : null,
        aerosol_no3_flag: this.aerosol_no3_flag,
        aerosol_na: this.aerosol_na ? this.aerosol_na : null,
        aerosol_na_flag: this.aerosol_na_flag,
        aerosol_nh4: this.aerosol_nh4 ? this.aerosol_nh4 : null,
        aerosol_nh4_flag: this.aerosol_nh4_flag,
        aerosol_k: this.aerosol_k ? this.aerosol_k : null,
        aerosol_k_flag: this.aerosol_k_flag,
        aerosol_mg2: this.aerosol_mg2 ? this.aerosol_mg2 : null,
        aerosol_mg2_flag: this.aerosol_mg2_flag,
        aerosol_ca2: this.aerosol_ca2 ? this.aerosol_ca2 : null,
        aerosol_ca2_flag: this.aerosol_ca2_flag,
        aerosol_kondisi_cuaca_awal: this.aerosol_kondisi_cuaca_awal,
        aerosol_kondisi_cuaca_akhir: this.aerosol_kondisi_cuaca_akhir,
        aerosol_kondisi_sampling: this.aerosol_kondisi_sampling,
        aerosol_kejadian_lainnya: this.aerosol_kejadian_lainnya,
        aerosol_catatan_lainnya: this.aerosol_catatan_lainnya,
        station_id: this.t_station.value,
        station_wmo_id: this.t_station.wmo,
        station_name: this.t_station.text,
        current_longitude: this.t_station.longitude,
        current_latitude: this.t_station.latitude,
        periode_form: this.t_periode.value,
        tahun_form: this.tgl_angkat,
        qc_flag: flag_temp,
        qc_histories: qc_temp
      };

      const post_url = Helper.getBaseUrl() + this.t_path;
      this.showLoading = true
      axios
        .post(post_url, post_data, Helper.getConfig())
        .then((response) => {
          this.$swal({
            title: "Simpan data sukses!",
            text: "Data Telah berhasil di Simpan",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.showLoading = false
          this.resetForm();
          this.changeForm(null);
        })
        .catch((error) => {
          this.showLoading = false
          console.log(error);
          error_handler(this, error, "Aerosol", "Input Data Error");
        });
    },

    resetForm(tipe = null) {
      this.showLoading = false
      if(tipe == 'batal'){
        this.changeForm(null);
      }
      this.qc_flag = 0,
      this.qc_flag_before = 0,
      this.last_qc_histories = {},
      this.DATA_RC = {},
      this.RC = {
        aerosol_flow_rate_awal: { status: false, message: "" },
        aerosol_flow_rate_akhir: { status: false, message: "" },
        aerosol_ph: { status: false, message: "" },
        aerosol_conductivity: { status: false, message: "" },
        aerosol_konsentrasi: { status: false, message: "" },
        aerosol_cl: { status: false, message: "" },
        aerosol_so42: { status: false, message: "" },
        aerosol_no3: { status: false, message: "" },
        aerosol_na: { status: false, message: "" },
        aerosol_nh4: { status: false, message: "" },
        aerosol_k: { status: false, message: "" },
        aerosol_mg2: { status: false, message: "" },
        aerosol_ca2: { status: false, message: "" },
        aerosol_bobot_filter_kosong: { status: false, message: "" },
        aerosol_bobot_filter_sampling: { status: false, message: "" },
        aerosol_total_waktu_sampling: { status: false, message: "" },
        aerosol_tekanan_udara_awal: { status: false, message: "" },
        aerosol_tekanan_udara_akhir: { status: false, message: "" },
        aerosol_temperatur_awal: { status: false, message: "" },
        aerosol_temperatur_akhir: { status: false, message: "" }
      },
      this.aerosol_ptgs_pasang_sampling = ""
      this.aerosol_ptgs_angkat_sampling = ""
      this.ptgs_analis = ""
      this.tgl_pasang = null
      this.jam_pasang = null
      this.tgl_angkat = null
      this.jam_angkat = null
      this.aerosol_ph = null
      this.aerosol_ph_flag = null
      this.aerosol_conductivity = null
      this.aerosol_konsentrasi = null
      this.aerosol_conductivity_flag = null
      this.aerosol_bobot_filter_kosong = null
      this.aerosol_bobot_filter_sampling = null
      this.aerosol_flow_rate_awal = null
      this.aerosol_flow_rate_akhir = null
      this.aerosol_total_waktu_sampling = null
      this.aerosol_tekanan_udara_awal = null
      this.aerosol_tekanan_udara_akhir = null
      this.aerosol_temperatur_awal = null
      this.aerosol_temperatur_akhir = null
      this.aerosol_cl = null
      this.aerosol_cl_flag = null
      this.aerosol_so42 = null
      this.aerosol_so42_flag = null
      this.aerosol_no3 = null
      this.aerosol_no3_flag = null
      this.aerosol_na = null
      this.aerosol_na_flag = null
      this.aerosol_nh4 = null
      this.aerosol_nh4_flag = null
      this.aerosol_k = null
      this.aerosol_k_flag = null
      this.aerosol_mg2 = null
      this.aerosol_mg2_flag = null
      this.aerosol_ca2 = null
      this.aerosol_ca2_flag = null
      this.aerosol_kondisi_cuaca_awal = ""
      this.aerosol_kondisi_cuaca_akhir = ""
      this.aerosol_kondisi_sampling = ""
      this.aerosol_kejadian_lainnya = ""
      this.aerosol_flow_rate_awal_satuan = ""
      this.aerosol_flow_rate_akhir_satuan = ""
      this.aerosol_catatan_lainnya = ""
      this.aerosol_id_sampel = null
      this.$v.$reset();
      // this.changeForm(null);
      this.resetOptions()
    },

    resetOptions() {
      this.flagOptionsAerosolPh = [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ]
      this.flagOptionsAerosolConductivity = [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ]
      this.flagOptionsAerosolCl = [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ]
      this.flagOptionsAerosolSo42 = [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ]
      this.flagOptionsAerosolNo3 = [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ]
      this.flagOptionsAerosolNa = [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ]
      this.flagOptionsAerosolNh4 = [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ]
      this.flagOptionsAerosolK = [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ]
      this.flagOptionsAerosolMg2 = [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ]
      this.flagOptionsAerosolCa2 = [
        { value: '', text: 'Pilih Flag', disabled: true },
        { value: 'M', text: 'M', disabled: true },
        { value: 'X', text: 'X' },
        { value: 'V', text: 'V' },
        { value: 'D', text: 'D' },
        { value: 'L', text: 'L' },
      ]
    },

    async getSekuensID() {
      var sekuens = 0;
      var tahun = String(this.t_year.title);
      var url = Helper.getBaseUrl() + this.t_path + "/@search?tgl_pasang__gte=01-01-" + tahun + "&tgl_pasang__lte=31-12-" + tahun;
      await axios
        .get(url, Helper.getConfig())
        .then(response => this.sekuens = response.data.items_total)
        .catch(error => console.log(error))
      this.aerosol_sekuens = this.sekuens
    },

    checkValidate: function (event, type) {
      let value = event.target.value;

      switch (type) {
        case "aerosol_flow_rate_awal":
          var raw = this.RC_helper_aerosol.check(value, type);
          var gc = GC_AE_FLOW_RATE_AWAL(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "aerosol_flow_rate_akhir":
          var raw = this.RC_helper_aerosol.check(value, type);
          var gc = GC_AE_FLOW_RATE_AKHIR(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "aerosol_ph":
          var raw = this.RC_helper_aerosol.check(value, type);
          var gc = GC_AE_PH(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "aerosol_conductivity": 
          var raw = this.RC_helper_aerosol.check(value, type);
          var gc = GC_AE_CONDUCTIVITY(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "aerosol_konsentrasi": 
          var raw = this.RC_helper_aerosol.check(value, type);
          var gc = GC_AE_KONSENTRASI(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "aerosol_cl":
          var raw = this.RC_helper_aerosol.check(value, type);
          var gc = GC_AE_CL(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "aerosol_so42":
          var raw = this.RC_helper_aerosol.check(value, type);
          var gc = GC_AE_SO42(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "aerosol_no3":
          var raw = this.RC_helper_aerosol.check(value, type);
          var gc = GC_AE_NO3(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "aerosol_na":
          var raw = this.RC_helper_aerosol.check(value, type);
          var gc = GC_AE_NA(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "aerosol_nh4": 
          var raw = this.RC_helper_aerosol.check(value, type);
          var gc = GC_AE_NH4(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "aerosol_k":
          var raw = this.RC_helper_aerosol.check(value, type);
          var gc = GC_AE_K(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "aerosol_mg2": 
          var raw = this.RC_helper_aerosol.check(value, type);
          var gc = GC_AE_MG2(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "aerosol_ca2":
          var raw = this.RC_helper_aerosol.check(value, type);
          var gc = GC_AE_CA2(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "aerosol_bobot_filter_kosong":
          var raw = this.RC_helper_aerosol.check(value, type);
          var gc = GC_AE_BOBOT_FILTER_KOSONG(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "aerosol_bobot_filter_sampling":
          var raw = this.RC_helper_aerosol.check(value, type);
          var gc = GC_AE_BOBOT_FILTER_SAMPLING(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "aerosol_total_waktu_sampling":
          var raw = this.RC_helper_aerosol.check(value, type);
          var gc = GC_AE_TOTAL_WAKTU_SAMPLING(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "aerosol_tekanan_udara_awal":
          var raw = this.RC_helper_aerosol.check(value, type);
          var gc = GC_AE_TEKANAN_UDARA_AWAL(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "aerosol_tekanan_udara_akhir":
          var raw = this.RC_helper_aerosol.check(value, type);
          var gc = GC_AE_TEKANAN_UDARA_AKHIR(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "aerosol_temperatur_awal":
          var raw = this.RC_helper_aerosol.check(value, type);
          var gc = GC_AE_TEMPERATUR_AWAL(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "aerosol_temperatur_akhir":
          var raw = this.RC_helper_aerosol.check(value, type);
          var gc = GC_AE_TEMPERATUR_AKHIR(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;

        default:
          break;
      }
    },

    checkValidateAll() {
      this.checkValidate({target: {value: this.aerosol_flow_rate_awal}}, "aerosol_flow_rate_awal")
      this.checkValidate({target: {value: this.aerosol_flow_rate_akhir}}, "aerosol_flow_rate_akhir")    
      this.checkValidate({target: {value: this.aerosol_ph}}, "aerosol_ph")
      this.checkValidate({target: {value: this.aerosol_conductivity}}, "aerosol_conductivity")
      this.checkValidate({target: {value: this.aerosol_konsentrasi}}, "aerosol_konsentrasi")
      this.checkValidate({target: {value: this.aerosol_cl}}, "aerosol_cl")
      this.checkValidate({target: {value: this.aerosol_so42}}, "aerosol_so42")      
      this.checkValidate({target: {value: this.aerosol_no3}}, "aerosol_no3")
      this.checkValidate({target: {value: this.aerosol_na}}, "aerosol_na")
      this.checkValidate({target: {value: this.aerosol_nh4}}, "aerosol_nh4")
      this.checkValidate({target: {value: this.aerosol_k}}, "aerosol_k")
      this.checkValidate({target: {value: this.aerosol_mg2}}, "aerosol_mg2")
      this.checkValidate({target: {value: this.aerosol_ca2}}, "aerosol_ca2")
      this.checkValidate({target: {value: this.aerosol_bobot_filter_kosong}}, "aerosol_bobot_filter_kosong")
      this.checkValidate({target: {value: this.aerosol_bobot_filter_sampling}}, "aerosol_bobot_filter_sampling")    
      this.checkValidate({target: {value: this.aerosol_total_waktu_sampling}}, "aerosol_total_waktu_sampling")
      this.checkValidate({target: {value: this.aerosol_tekanan_udara_awal}}, "aerosol_tekanan_udara_awal")
      this.checkValidate({target: {value: this.aerosol_tekanan_udara_akhir}}, "aerosol_tekanan_udara_akhir")
      this.checkValidate({target: {value: this.aerosol_temperatur_awal}}, "aerosol_temperatur_awal")      
      this.checkValidate({target: {value: this.aerosol_temperatur_akhir}}, "aerosol_temperatur_akhir")
    },

    setDataRC(type, status, message, roles) {
      // console.log('setDataRC', type, status, message, roles)
      if (status) {
        this.DATA_RC[type] = {
          status: status,
          message: message,
          roles: roles,
        };
      } else {
        if (this.DATA_RC.hasOwnProperty(type)) {
          delete this.DATA_RC[type];
        }
      }
    },

    async setQCData() {
      // console.log('setQCData')
      if (this.d_station) {
        let qc_data = this.d_station["BmkgSatu.metadata.qc.IAerosol_qc"];
        let qc_params = Params.GET_PARAMS("aerosol");
        let qc_key = Params.GET_KEY("aerosol");
        this.RC_helper_aerosol.setData(qc_data, qc_params, qc_key);
        // console.log('setQCData', this.RC_helper_so2no2.getData())
      }
    },

    rangecheck_status(type) {
      if (this.RC[type]?.status) {
        return this.RC[type].status;
      } else {
        return false;
      }
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
};
</script>

<style>

#aerosol_bobot_filter_kosong.rangecheck,
#aerosol_bobot_filter_sampling.rangecheck,
#aerosol_total_waktu_sampling.rangecheck,
#aerosol_tekanan_udara_awal.rangecheck,
#aerosol_tekanan_udara_akhir.rangecheck,
#aerosol_temperatur_awal.rangecheck,
#aerosol_temperatur_akhir.rangecheck,
#aerosol_ph.rangecheck,
#aerosol_conductivity.rangecheck,
#aerosol_konsentrasi.rangecheck,
#aerosol_cl.rangecheck,
#aerosol_so42.rangecheck,
#aerosol_no3.rangecheck,
#aerosol_na.rangecheck,
#aerosol_nh4.rangecheck,
#aerosol_k.rangecheck,
#aerosol_mg2.rangecheck,
#aerosol_ca2.rangecheck,
#aerosol_flow_rate_akhir.rangecheck,
#aerosol_flow_rate_awal.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
}

.content-inline {
  display: flex;
  flex-direction: row;
}
</style>

<style scoped>
.label-modif {
  margin-top: -5px;
  font-size: 10pt;
  margin-left: 3px
}

.select-grey {
  background-color: lightgray !important;
}

.label-entry1 {
  text-align: center;
  color: #3b4253;
  width: 100%;
  font-weight: 500;
  background-color: #f3f1f1;
  /* font-weight: bold !important; */
  border: 2px solid #6e6b7b;
  border-radius: 3px;
  padding: 4px;
  margin-bottom: 7px;
}

.mbtm {
  margin-bottom: 7px;
}

.bg-light-primary {
  color: #000000 !important;
}
</style>
  