<template>
  <div>
    <!-- ===Card==== -->
    <b-row class="mt-n2">
      <b-col cols="12">
        <b-card class="text-dark font-weight-bolder">
          <h3 class="mb-2 font-weight-bolder badge badge-light-dark">Frekuensi Perawanan</h3>
          <b-row>
            <b-col>
              <div>
                <label for="input-cerah" class="label-entry1"
                  >Cerah <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Tutupan awan 0 (tidak ada tutupan awan) / langit clear"
                /></label>
              </div>
              <b-form-input id="input-cerah" size="sm" class="mb-2" v-model="ww_cerah" disabled></b-form-input>
            </b-col>
            <b-col>
              <div>
                <label for="input-sebagian" class="label-entry1"
                  >Berawan Sebagian <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Tutupan awan 1 okta - 4 okta"
                /></label>
              </div>
              <b-form-input id="input-sebagian" size="sm" class="mb-2" v-model="ww_berawan_sebagian" disabled></b-form-input>
            </b-col>
            <b-col>
              <div>
                <label for="input-berawan" class="label-entry1">Berawan <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Tutupan awan 5 okta - 7 okta" /></label>
              </div>
              <b-form-input id="input-berawan" size="sm" class="mb-2" v-model="ww_berawan" disabled></b-form-input>
            </b-col>
            <b-col>
              <div>
                <label for="input-banyak" class="label-entry1">Berawan Banyak <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Tutupan awan penuh / 8 okta" /></label>
              </div>
              <b-form-input id="input-banyak" size="sm" class="mb-2" v-model="ww_berawan_banyak" disabled></b-form-input>
            </b-col>
            <b-col>
              <div>
                <label for="input-jumlah" class="label-entry1"
                  >Jumlah Pengamatan <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah Pengamatan selama 24 jam"
                /></label>
              </div>
              <!-- <b-form-input id="input-jumlah" size="sm" class="mb-2" v-model="obsawan_total_24" disabled></b-form-input> -->
              <b-form-input id="input-jumlah" size="sm" class="mb-2" v-model="jumlah_observasi" disabled></b-form-input>
            </b-col>
            <b-col>
              <div>
                <label for="textarea-small" class="label-entry1 bg-light-primary">Keterangan </label>
              </div>
              <b-form-textarea id="textarea-small" class="border-primary" size="sm" v-model="ket_perawanan" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-n2">
      <b-col cols="12">
        <b-card>
          <b-row class="float-right">
            <div>
              <b-button variant="warning" @click="submitForm()" v-if="this.t_data_status == 'update'" class="mr-2" size="sm" :disabled="!roles.isRoleEdit">Update</b-button>
              <b-button variant="primary" @click="submitForm()" v-if="this.t_data_status == 'insert'" class="mr-2" size="sm" :disabled="!roles.isRoleAdd">Submit</b-button>
            </div>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay>
  </div>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { BRow, BCol, BTable, BCardText, BFormInput, BContainer, BButton, BCard, BFormDatepicker, BFormTextarea, BOverlay, VBTooltip, BTooltip } from "bootstrap-vue";
import axios from "axios";
import Helper from "@/api/helper";
import error_handler from "@/api/error_handler";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BTable,
    BCardText,
    BFormInput,
    BContainer,
    BButton,
    BCard,
    BFormDatepicker,
    BFormTextarea,
    BOverlay,
    VBTooltip,
    BTooltip,
  },
  props: ["t_station", "t_wmoid", "t_observer", "t_date", "t_station_id", "t_path", "t_data", "t_id_form", "t_data_status", "t_path_update", "roles"],
  data() {
    return {
      showLoading: false,
      ww_cerah: "",
      ww_berawan_sebagian: "",
      ww_berawan: "",
      ww_berawan_banyak: "",
      obsawan_total_24: "",
      ket_perawanan: "",
      jumlah_observasi: "",
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  mounted() {
    if (this.t_data_status == "update") {
      this.popupData();
    } else {
      this.resetForm();
    }
  },
  methods: {
    resetForm() {
      this.ww_cerah = "";
      this.ww_berawan_sebagian = "";
      this.ww_berawan = "";
      this.ww_berawan_banyak = "";
      this.obsawan_total_24 = "";
      this.ket_perawanan = "";
      this.jumlah_observasi = "";
    },
    popupData() {
      this.ww_cerah = this.t_data.ww_cerah;
      this.ww_berawan_sebagian = this.t_data.ww_berawan_sebagian;
      this.ww_berawan = this.t_data.ww_berawan;
      this.ww_berawan_banyak = this.t_data.ww_berawan_banyak;
      this.obsawan_total_24 = this.t_data.obsawan_total_24;
      this.ket_perawanan = this.t_data.ket_perawanan;
      this.jumlah_observasi = this.t_data.jumlah_observasi;
    },
    submitForm() {
      const ID = this.t_station_id + "_formperawanan_" + this.t_date;

      if (this.t_data_status == "insert") {
        this.$swal({
          title: "Get Data Error",
          text: "Data Sinoptik belum diinput, silahkan input data Sinoptik terlebih dahulu",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return false;
      } else if (this.t_data_status == "update") {
        // melakukan update
        const post_data = {
          // "@type": "FormPerawanan",
          // ww_cerah: this.ww_cerah,
          // ww_berawan_sebagian: this.ww_berawan_sebagian,
          // ww_berawan: this.ww_berawan,
          // ww_berawan_banyak: this.ww_berawan_banyak,
          // obsawan_total_24: this.obsawan_total_24,
          ket_perawanan: this.ket_perawanan,
          modification_date: this.t_date,
          observer_id: this.t_observer,
        };
        const update_url = Helper.getBaseUrl() + this.t_path_update;

        axios
          .patch(update_url, post_data, Helper.getConfig())
          .then((response) => {
            this.$swal({
              title: "Input Data Berhasil!",
              text: "Data Telah berhasil di Simpan",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.resetForm();
          })
          .catch((error) => {
            error_handler(this, error, "Perawanan", "Update Data Error");
          });
      }
    },
    padZero(val, n) {
      return `${"0".repeat(n)}${val}`.substr(-n, n);
    },
    getPerawananData() {
      this.showLoading = true;

      let date = new Date(this.t_date);
      let tanggal = `${date.getFullYear()}-${this.padZero(date.getMonth() + 1, 2)}-${this.padZero(date.getDate() + 1, 2)}`;

      const config = {
        mode: "cors",
        withCredentials: false,
        params: {
          type_name__eq: "Sinoptik",
          station_id: this.t_station_id,
          data_timestamp__lt: tanggal + "T00",
          _size: "24",
        },
        headers: Helper.getHeader(),
      };
      const get_url = Helper.getBaseUrl() + "@pias_perawanan24hr";

      axios
        .get(get_url, config)
        .then((response) => {
          const { data } = response;
          this.ww_cerah = data.sinop_cloud_cover_oktas_m?.ww_cerah || 0;
          this.ww_berawan_sebagian = data.sinop_cloud_cover_oktas_m?.ww_berawan_sebagian || 0;
          this.ww_berawan = data.sinop_cloud_cover_oktas_m?.ww_berawan || 0;
          this.ww_berawan_banyak = data.sinop_cloud_cover_oktas_m?.ww_berawan_banyak || 0;
          this.obsawan_total_24 = data.sinop_cloud_cover_oktas_m?.obsawan_total || 0;
          this.showLoading = false;
        })
        .catch((error) => {
          this.$swal({
            title: "Refresh Data Error",
            text: `Data Sinoptik tanggal ${this.t_date} tidak ditemukan`,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.resetForm();
          this.showLoading = false;
        });
    },
  },
  computed: {},
  watch: {
    t_data_status(newValue) {
      if (newValue == "update") {
        this.popupData();
      } else {
        this.resetForm();
      }
    },
  },
};
</script>
<style scoped>
.label-entry {
  text-align: center;
  color: white;
  width: 100%;
  /* font-weight: bold !important; */
  border: 1px solid #b1b1b1;
  border-radius: 3px;
  background: #858585;
  padding: 4px;
}
.label-entry1 {
  text-align: center;
  color: #3b4253;
  width: 100%;
  font-weight: 500;
  background-color: #f3f1f1;
  border: 2px solid #6e6b7b;
  font-weight: 500;
  border-radius: 3px;
  padding: 4px;
  height: 32px;
  margin-bottom: 7px;
}
.mbtm {
  margin-bottom: 4px;
}
.pad2 {
  padding-left: 2px;
  padding-right: 4px;
}
.bg-light-primary {
  color: #000000 !important;
}
.bg-light-success {
  color: #000000 !important;
}
</style>
