const rangecheck = {
  data: {},
  check(value, key) {
    let status = true;
    let message = "";
    let roles = "null, null, null";

    let min = this.data[key]?.min;
    let max = this.data[key]?.max;
    let values = this.data[key]?.values;
    // console.log('range checking', min, max, values, this.data)
    if (min === undefined || max === undefined || values === undefined) {
      return { status: status, message: message };
    }
    
    let min_pass = false;
    if (min === null) {
      min_pass = true;
    } else {
      min_pass = value >= min;
    }

    let max_pass = false;
    if (max === null) {
      max_pass = true;
    } else {
      max_pass = value <= max;
    }

    let values_pass = false;
    if (values === null) {
      values_pass = (min !== null && max != null) ? false : true;
    } else {
      values = ("" + values).split(",");
      values_pass = values.some((e) => e == value);
    }
    // console.log('check', key, value, values_pass, min_pass, max_pass, min, max, values)
    if (value === "" || value === null || values_pass || (min_pass && max_pass)) {
      status = true;
      message = "";
    } else {
      status = false;
      roles = min+","+max+","+(values? values:null)
      message = "Range Check - Batas Bawah " + min + ", Batas Atas " + max + ", Special Values " + values;
    }

    return { status: status, message: message, roles: roles };
  },
  setData(qc_data, qc_params, qc_key) {
    const result = {};
    // console.log('RC_helper - setData : ', qc_data, qc_params, qc_key)
    for (const i in qc_params) {
      for (const key in qc_data) {
        const parameter = qc_params[i].parameter.toLowerCase();
        if (key.indexOf(parameter) > -1) {
          result[parameter] = {};
          result[parameter].min = qc_data[qc_key + parameter + "_min"];
          result[parameter].max = qc_data[qc_key + parameter + "_max"];
          result[parameter].values = qc_data[qc_key + parameter + "_values"];
          break;
        }
      }
    }
    this.data = result;
  },
  getData() {
    return this.data
  }
};

export default rangecheck;
