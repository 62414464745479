<template>
  <div>
    <b-row class="mt-n2">
      <!-- card 1 -->
      <b-col cols="12">
        <b-card class="font-weight-bolder text-dark">
          <h3 class="mb-2 font-weight-bolder badge badge-light-dark">Hujan</h3>
          <b-row>
            <b-col sm="2 pad3">
              <label>Tahun <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Tahun pada waktu pengamatan" /></label>
              <b-form-select v-model="t_tahun" class="mb-1" id="t_tahun" size="sm">
                <option disabled value="">-</option>
                <option value="2030">2030</option>
                <option value="2029">2029</option>
                <option value="2028">2028</option>
                <option value="2027">2027</option>
                <option value="2026">2026</option>
                <option value="2025">2025</option>
                <option value="2024">2024</option>
                <option value="2023">2023</option>
                <option value="2022">2022</option>
                <option value="2021">2021</option>
                <option value="2020">2020</option>
                <option value="2019">2019</option>
                <option value="2018">2018</option>
                <option value="2017">2017</option>
                <option value="2016">2016</option>
                <option value="2015">2015</option>
                <option value="2014">2014</option>
                <option value="2013">2013</option>
                <option value="2012">2012</option>
                <option value="2011">2011</option>
                <option value="2010">2010</option>
                <option value="2009">2009</option>
                <option value="2008">2008</option>
                <option value="2007">2007</option>
                <option value="2006">2006</option>
                <option value="2005">2005</option>
                <option value="2004">2004</option>
                <option value="2003">2003</option>
              </b-form-select>
            </b-col>

            <b-col sm="2 pad3">
              <label>Bulan <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Bulan pada waktu pengamatan" /></label>
              <b-form-select v-model="t_bulan" class="mb-1" id="t_bulan" size="sm">
                <option disabled value="">-</option>
                <option value="01">Januari</option>
                <option value="02">Februari</option>
                <option value="03">Maret</option>
                <option value="04">April</option>
                <option value="05">Mei</option>
                <option value="06">Juni</option>
                <option value="07">Juli</option>
                <option value="08">Agustus</option>
                <option value="09">September</option>
                <option value="10">Oktober</option>
                <option value="11">November</option>
                <option value="12">Desember</option>
              </b-form-select>
            </b-col>

            <!-- ========Baris 2===== -->
            <b-col sm="2">
              <label>Provinsi <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Nama provinsi tempat dimana diadakan peramatan penakar hujan" /></label>
              <!-- <b-form-select v-model="t_provinsi" :options="propinsi_opt" size="sm" disabled /> -->
              <v-select disabled v-model="t_provinsi" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="propinsi_opt" class="select-size-sm" :reduce="(item) => item.id" />
            </b-col>

            <b-col sm="2">
              <label>Kota/Kabupaten <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Nama kabupaten tempat dimana diadakan peramatan penakar hujan" /></label>
              <v-select disabled v-model="t_kabupaten" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="kabupaten_opt" class="select-size-sm" :reduce="(item) => item.id" />
            </b-col>

            <b-col sm="3">
              <label>No/Nama Pos Hujan <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="(refer ID dan Nama Pos Hujan Kerjasama)" /></label>
              <b-form-group>
                <v-select v-model="poshujan" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="poshujan_opt" class="select-size-sm" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- =end card1 -->
    <!--==card 2 -->
    <b-row class="mt-n2">
      <b-col cols="4">
        <b-card-actions action-collapse>
          <b-row>
            <b-col sm="6 mb-2">
              <div><label for="input30" class="label-entry bg-light-primary font-weight-bolder">Tanggal Pemeriksaan</label></div>
              <div><label for="input60" class="label-entry1">1 </label></div>
              <div><label for="input60" class="label-entry1">2</label></div>
              <div><label for="input60" class="label-entry1">3</label></div>
              <div><label for="input60" class="label-entry1">4</label></div>
              <div><label for="input60" class="label-entry1">5</label></div>
              <div><label for="input60" class="label-entry1">6</label></div>
              <div><label for="input60" class="label-entry1">7</label></div>
              <div><label for="input60" class="label-entry1">8</label></div>
              <div><label for="input60" class="label-entry1">9</label></div>
              <div><label for="input60" class="label-entry1">10</label></div>
              <div><label for="input30" class="label-entry bg-light-primary font-weight-bolder">Jumlah</label></div>
            </b-col>

            <b-col sm="6 mb-2">
              <div>
                <label for="input40" class="label-entry bg-light-primary font-weight-bolder"
                  >Hujan ditakar(mm) <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips1" />
                  <!-- HTML tooltips -->
                  <b-tooltip target="tooltips1" triger="hover" placement="top">
                    <dl class="text-left">
                      <dt>Catatan</dt>
                      <dd>1. Penakaran dilakukan tiap pukul 07.00 WS, diinput dalam per sepuluhan mm</dd>
                      <dd>2. Bila tidak ada hujan diberi tanda 0</dd>
                      <dd>3. Nilai hujan TTU diinput tanda 8888</dd>
                      <dd>4. alat rusak / tidak ada data diinput 9999</dd>
                    </dl>
                  </b-tooltip></label
                >
              </div>
              <b-form-input
                :disabled="EDForm.s_col_1_1"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_1._1.$model"
                :state="validateState($v.col_1._1)"
                id="input_col_1_1"
                v-on:keyup="checkValidate($event, 'col_1_1')"
                v-bind:class="{ rangecheck: rc_col_1_1 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_1_1" target="input_col_1_1" triggers="hover" placement="topleft">{{ rc_col_1_1_message }}</b-tooltip>
              <!-- tooltips GC  -->
              <b-tooltip v-if="!rc_col_1_1" :show="validateTooltipStateCol_1('_1')" :triggers="'hover'" target="input_col_1_1" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_1_1") }}
              </b-tooltip>

              <b-form-input
                :disabled="EDForm.s_col_1_2"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_1._2.$model"
                :state="validateState($v.col_1._2)"
                id="input_col_1_2"
                v-on:keyup="checkValidate($event, 'col_1_2')"
                v-bind:class="{ rangecheck: rc_col_1_2 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
                :required="date_10"
              ></b-form-input>
              <b-tooltip v-if="rc_col_1_2" target="input_col_1_2" triggers="hover" placement="topleft">{{ rc_col_1_2_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_1('_2')" :triggers="'hover'" target="input_col_1_2" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_1_2") }}
              </b-tooltip>
              <b-form-input
                :disabled="EDForm.s_col_1_3"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_1._3.$model"
                :state="validateState($v.col_1._3)"
                id="input_col_1_3"
                v-on:keyup="checkValidate($event, 'col_1_3')"
                v-bind:class="{ rangecheck: rc_col_1_3 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_1_3" target="input_col_1_3" triggers="hover" placement="topleft">{{ rc_col_1_3_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_1('_3')" :triggers="'hover'" target="input_col_1_3" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_1_3") }}
              </b-tooltip>
              <b-form-input
                :disabled="EDForm.s_col_1_4"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_1._4.$model"
                :state="validateState($v.col_1._4)"
                id="input_col_1_4"
                v-on:keyup="checkValidate($event, 'col_1_4')"
                v-bind:class="{ rangecheck: rc_col_1_4 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_1_4" target="input_col_1_4" triggers="hover" placement="topleft">{{ rc_col_1_4_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_1('_4')" :triggers="'hover'" target="input_col_1_4" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_1_4") }}
              </b-tooltip>
              <b-form-input
                :disabled="EDForm.s_col_1_5"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_1._5.$model"
                :state="validateState($v.col_1._5)"
                id="input_col_1_5"
                v-on:keyup="checkValidate($event, 'col_1_5')"
                v-bind:class="{ rangecheck: rc_col_1_5 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_1_5" target="input_col_1_5" triggers="hover" placement="topleft">{{ rc_col_1_5_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_1('_5')" :triggers="'hover'" target="input_col_1_5" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_1_5") }}
              </b-tooltip>
              <b-form-input
                :disabled="EDForm.s_col_1_6"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_1._6.$model"
                :state="validateState($v.col_1._6)"
                id="input_col_1_6"
                v-on:keyup="checkValidate($event, 'col_1_6')"
                v-bind:class="{ rangecheck: rc_col_1_6 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_1_6" target="input_col_1_6" triggers="hover" placement="topleft">{{ rc_col_1_6_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_1('_6')" :triggers="'hover'" target="input_col_1_6" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_1_6") }}
              </b-tooltip>
              <b-form-input
                :disabled="EDForm.s_col_1_7"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_1._7.$model"
                :state="validateState($v.col_1._7)"
                id="input_col_1_7"
                v-on:keyup="checkValidate($event, 'col_1_7')"
                v-bind:class="{ rangecheck: rc_col_1_7 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_1_7" target="input_col_1_7" triggers="hover" placement="topleft">{{ rc_col_1_7_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_1('_7')" :triggers="'hover'" target="input_col_1_7" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_1_7") }}
              </b-tooltip>
              <b-form-input
                :disabled="EDForm.s_col_1_8"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_1._8.$model"
                :state="validateState($v.col_1._8)"
                id="input_col_1_8"
                v-on:keyup="checkValidate($event, 'col_1_8')"
                v-bind:class="{ rangecheck: rc_col_1_8 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_1_8" target="input_col_1_8" triggers="hover" placement="topleft">{{ rc_col_1_8_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_1('_8')" :triggers="'hover'" target="input_col_1_8" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_1_8") }}
              </b-tooltip>
              <b-form-input
                :disabled="EDForm.s_col_1_9"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_1._9.$model"
                :state="validateState($v.col_1._9)"
                id="input_col_1_9"
                v-on:keyup="checkValidate($event, 'col_1_9')"
                v-bind:class="{ rangecheck: rc_col_1_9 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_1_9" target="input_col_1_9" triggers="hover" placement="topleft">{{ rc_col_1_9_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_1('_9')" :triggers="'hover'" target="input_col_1_9" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_1_9") }}
              </b-tooltip>
              <b-form-input
                :disabled="EDForm.s_col_1_10"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_1._10.$model"
                :state="validateState($v.col_1._10)"
                id="input_col_1_10"
                v-on:keyup="checkValidate($event, 'col_1_10')"
                v-bind:class="{ rangecheck: rc_col_1_10 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_1_10" target="input_col_1_10" triggers="hover" placement="topleft">{{ rc_col_1_10_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_1('_10')" :triggers="'hover'" target="input_col_1_10" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_1_10") }}
              </b-tooltip>
              <b-form-input type="number" v-model.number="total_1" id="input10" size="sm" class="text-center mbtm bg-light-success" placeholder="" readonly></b-form-input>
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>

      <b-col cols="4">
        <b-card-actions action-collapse>
          <b-row>
            <b-col sm="6 mb-2">
              <div><label for="input60" class="label-entry bg-light-primary font-weight-bolder">Tanggal Pemeriksaan</label></div>
              <div><label for="input60" class="label-entry1">11</label></div>
              <div><label for="input60" class="label-entry1">12</label></div>
              <div><label for="input60" class="label-entry1">13</label></div>
              <div><label for="input60" class="label-entry1">14</label></div>
              <div><label for="input60" class="label-entry1">15</label></div>
              <div><label for="input60" class="label-entry1">16</label></div>
              <div><label for="input60" class="label-entry1">17</label></div>
              <div><label for="input60" class="label-entry1">18</label></div>
              <div><label for="input60" class="label-entry1">19</label></div>
              <div><label for="input60" class="label-entry1">20</label></div>
              <div><label for="input30" class="label-entry bg-light-primary font-weight-bolder">Jumlah</label></div>
            </b-col>

            <b-col sm="6 mb-2">
              <div>
                <label for="input100" class="label-entry bg-light-primary font-weight-bolder"
                  >Hujan ditakar (mm)<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips12" />
                  <!-- HTML tooltips -->
                  <b-tooltip target="tooltips12" triger="hover" placement="topleft">
                    <dl class="text-left">
                      <dt>Catatan</dt>
                      <dd>1. Penakaran dilakukan tiap pukul 07.00 WS, diinput dalam per sepuluhan mm</dd>
                      <dd>2. Bila tidak ada hujan diberi tanda 0</dd>
                      <dd>3. Nilai hujan TTU diinput tanda 8888</dd>
                      <dd>4. alat rusak / tidak ada data diinput 9999</dd>
                    </dl>
                  </b-tooltip>
                </label>
              </div>
              <b-form-input
                :disabled="EDForm.s_col_2_11"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_2._11.$model"
                :state="validateState($v.col_2._11)"
                id="input_col_2_11"
                v-on:keyup="checkValidate($event, 'col_2_11')"
                v-bind:class="{ rangecheck: rc_col_2_11 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_2_11" target="input_col_2_11" triggers="hover" placement="topleft">{{ rc_col_2_11_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_2('_11')" :triggers="'hover'" target="input_col_2_11" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_2_11") }}
              </b-tooltip>

              <b-form-input
                :disabled="EDForm.s_col_2_12"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_2._12.$model"
                :state="validateState($v.col_2._12)"
                id="input_col_2_12"
                v-on:keyup="checkValidate($event, 'col_2_12')"
                v-bind:class="{ rangecheck: rc_col_2_12 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_2_12" target="input_col_2_12" triggers="hover" placement="topleft">{{ rc_col_2_12_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_2('_12')" :triggers="'hover'" target="input_col_2_12" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_2_12") }}
              </b-tooltip>
              <b-form-input
                :disabled="EDForm.s_col_2_13"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_2._13.$model"
                :state="validateState($v.col_2._13)"
                id="input_col_2_13"
                v-on:keyup="checkValidate($event, 'col_2_13')"
                v-bind:class="{ rangecheck: rc_col_2_13 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_2_13" target="input_col_2_13" triggers="hover" placement="topleft">{{ rc_col_2_13_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_2('_13')" :triggers="'hover'" target="input_col_2_13" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_2_13") }}
              </b-tooltip>
              <b-form-input
                :disabled="EDForm.s_col_2_14"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_2._14.$model"
                :state="validateState($v.col_2._14)"
                id="input_col_2_14"
                v-on:keyup="checkValidate($event, 'col_2_14')"
                v-bind:class="{ rangecheck: rc_col_2_14 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_2_14" target="input_col_2_14" triggers="hover" placement="topleft">{{ rc_col_2_14_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_2('_14')" :triggers="'hover'" target="input_col_2_14" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_2_14") }}
              </b-tooltip>
              <b-form-input
                :disabled="EDForm.s_col_2_15"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_2._15.$model"
                :state="validateState($v.col_2._15)"
                id="input_col_2_15"
                v-on:keyup="checkValidate($event, 'col_2_15')"
                v-bind:class="{ rangecheck: rc_col_2_15 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_2_15" target="input_col_2_15" triggers="hover" placement="topleft">{{ rc_col_2_15_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_2('_15')" :triggers="'hover'" target="input_col_2_15" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_2_15") }}
              </b-tooltip>
              <b-form-input
                :disabled="EDForm.s_col_2_16"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_2._16.$model"
                :state="validateState($v.col_2._16)"
                id="input_col_2_16"
                v-on:keyup="checkValidate($event, 'col_2_16')"
                v-bind:class="{ rangecheck: rc_col_2_16 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_2_16" target="input_col_2_16" triggers="hover" placement="topleft">{{ rc_col_2_16_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_2('_16')" :triggers="'hover'" target="input_col_2_16" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_2_16") }}
              </b-tooltip>
              <b-form-input
                :disabled="EDForm.s_col_2_17"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_2._17.$model"
                :state="validateState($v.col_2._17)"
                id="input_col_2_17"
                v-on:keyup="checkValidate($event, 'col_2_17')"
                v-bind:class="{ rangecheck: rc_col_2_17 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_2_17" target="input_col_2_17" triggers="hover" placement="topleft">{{ rc_col_2_17_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_2('_17')" :triggers="'hover'" target="input_col_2_17" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_2_17") }}
              </b-tooltip>
              <b-form-input
                :disabled="EDForm.s_col_2_18"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_2._18.$model"
                :state="validateState($v.col_2._18)"
                id="input_col_2_18"
                v-on:keyup="checkValidate($event, 'col_2_18')"
                v-bind:class="{ rangecheck: rc_col_2_18 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_2_18" target="input_col_2_18" triggers="hover" placement="topleft">{{ rc_col_2_18_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_2('_18')" :triggers="'hover'" target="input_col_2_18" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_2_18") }}
              </b-tooltip>
              <b-form-input
                :disabled="EDForm.s_col_2_19"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_2._19.$model"
                :state="validateState($v.col_2._19)"
                id="input_col_2_19"
                v-on:keyup="checkValidate($event, 'col_2_19')"
                v-bind:class="{ rangecheck: rc_col_2_19 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_2_19" target="input_col_2_19" triggers="hover" placement="topleft">{{ rc_col_2_19_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_2('_19')" :triggers="'hover'" target="input_col_2_19" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_2_19") }}
              </b-tooltip>
              <b-form-input
                :disabled="EDForm.s_col_2_20"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_2._20.$model"
                :state="validateState($v.col_2._20)"
                id="input_col_2_20"
                v-on:keyup="checkValidate($event, 'col_2_20')"
                v-bind:class="{ rangecheck: rc_col_2_20 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_2_20" target="input_col_2_20" triggers="hover" placement="topleft">{{ rc_col_2_20_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_2('_20')" :triggers="'hover'" target="input_col_2_20" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_2_20") }}
              </b-tooltip>
              <b-form-input type="number" v-model.number="total_2" id="input10" size="sm" class="text-center mbtm bg-light-success" placeholder="" readonly></b-form-input>
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>

      <b-col cols="4">
        <b-card-actions action-collapse>
          <b-row>
            <b-col sm="6 mb-0">
              <div><label for="input60" class="label-entry bg-light-primary font-weight-bolder">Tanggal Pemeriksaan</label></div>
              <div><label for="input60" class="label-entry1">21</label></div>
              <div><label for="input60" class="label-entry1">22</label></div>
              <div><label for="input60" class="label-entry1">23</label></div>
              <div><label for="input60" class="label-entry1">24</label></div>
              <div><label for="input60" class="label-entry1">25</label></div>
              <div><label for="input60" class="label-entry1">26</label></div>
              <div><label for="input60" class="label-entry1">27</label></div>
              <div><label for="input60" class="label-entry1">28</label></div>
              <div><label for="input60" class="label-entry1">29</label></div>
              <div><label for="input60" class="label-entry1">30</label></div>
              <div><label for="input60" class="label-entry1">31</label></div>
              <div><label for="input30" class="label-entry bg-light-primary font-weight-bolder">Jumlah</label></div>
            </b-col>

            <b-col sm="6 mb-2">
              <div>
                <label for="input100" class="label-entry bg-light-primary font-weight-bolder"
                  >Hujan ditakar (mm)<feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips13" />
                  <!-- HTML tooltips -->
                  <b-tooltip target="tooltips13" triger="hover" placement="top">
                    <dl class="text-left">
                      <dt>Catatan</dt>
                      <dd>1. Penakaran dilakukan tiap pukul 07.00 WS, diinput dalam per sepuluhan mm</dd>
                      <dd>2. Bila tidak ada hujan diberi tanda 0</dd>
                      <dd>3. Nilai hujan TTU diinput tanda 8888</dd>
                      <dd>4. alat rusak / tidak ada data diinput 9999</dd>
                    </dl>
                  </b-tooltip>
                </label>
              </div>
              <b-form-input
                :disabled="EDForm.s_col_3_21"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_3._21.$model"
                :state="validateState($v.col_3._21)"
                id="input_col_3_21"
                v-on:keyup="checkValidate($event, 'col_3_21')"
                v-bind:class="{ rangecheck: rc_col_3_21 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_3_21" target="input_col_3_21" triggers="hover" placement="topleft">{{ rc_col_3_21_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_3('_21')" :triggers="'hover'" target="input_col_3_21" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_3_21") }}
              </b-tooltip>
              <b-form-input
                :disabled="EDForm.s_col_3_22"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_3._22.$model"
                :state="validateState($v.col_3._22)"
                id="input_col_3_22"
                v-on:keyup="checkValidate($event, 'col_3_22')"
                v-bind:class="{ rangecheck: rc_col_3_22 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_3_22" target="input_col_3_22" triggers="hover" placement="topleft">{{ rc_col_3_22_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_3('_22')" :triggers="'hover'" target="input_col_3_22" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_3_22") }}
              </b-tooltip>
              <b-form-input
                :disabled="EDForm.s_col_3_23"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_3._23.$model"
                :state="validateState($v.col_3._23)"
                id="input_col_3_23"
                v-on:keyup="checkValidate($event, 'col_3_23')"
                v-bind:class="{ rangecheck: rc_col_3_23 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_3_23" target="input_col_3_23" triggers="hover" placement="topleft">{{ rc_col_3_23_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_3('_23')" :triggers="'hover'" target="input_col_3_23" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_3_23") }}
              </b-tooltip>
              <b-form-input
                :disabled="EDForm.s_col_3_24"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_3._24.$model"
                :state="validateState($v.col_3._24)"
                id="input_col_3_24"
                v-on:keyup="checkValidate($event, 'col_3_24')"
                v-bind:class="{ rangecheck: rc_col_3_24 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_3_24" target="input_col_3_24" triggers="hover" placement="topleft">{{ rc_col_3_24_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_3('_24')" :triggers="'hover'" target="input_col_3_24" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_3_24") }}
              </b-tooltip>
              <b-form-input
                :disabled="EDForm.s_col_3_25"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_3._25.$model"
                :state="validateState($v.col_3._25)"
                id="input_col_3_25"
                v-on:keyup="checkValidate($event, 'col_3_25')"
                v-bind:class="{ rangecheck: rc_col_3_25 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_3_25" target="input_col_3_25" triggers="hover" placement="topleft">{{ rc_col_3_25_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_3('_25')" :triggers="'hover'" target="input_col_3_25" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_3_25") }}
              </b-tooltip>
              <b-form-input
                :disabled="EDForm.s_col_3_26"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_3._26.$model"
                :state="validateState($v.col_3._26)"
                id="input_col_3_26"
                v-on:keyup="checkValidate($event, 'col_3_26')"
                v-bind:class="{ rangecheck: rc_col_3_26 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_3_26" target="input_col_3_26" triggers="hover" placement="topleft">{{ rc_col_3_26_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_3('_26')" :triggers="'hover'" target="input_col_3_26" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_3_26") }}
              </b-tooltip>
              <b-form-input
                :disabled="EDForm.s_col_3_27"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_3._27.$model"
                :state="validateState($v.col_3._27)"
                id="input_col_3_27"
                v-on:keyup="checkValidate($event, 'col_3_27')"
                v-bind:class="{ rangecheck: rc_col_3_27 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_3_27" target="input_col_3_27" triggers="hover" placement="topleft">{{ rc_col_3_27_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_3('_27')" :triggers="'hover'" target="input_col_3_27" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_3_27") }}
              </b-tooltip>
              <b-form-input
                :disabled="EDForm.s_col_3_28"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_3._28.$model"
                :state="validateState($v.col_3._28)"
                id="input_col_3_28"
                v-on:keyup="checkValidate($event, 'col_3_28')"
                v-bind:class="{ rangecheck: rc_col_3_28 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_3_28" target="input_col_3_28" triggers="hover" placement="topleft">{{ rc_col_3_28_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_3('_28')" :triggers="'hover'" target="input_col_3_28" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_3_28") }}
              </b-tooltip>
              <b-form-input
                :disabled="EDForm.s_col_3_29"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_3._29.$model"
                :state="validateState($v.col_3._29)"
                id="input_col_3_29"
                v-on:keyup="checkValidate($event, 'col_3_29')"
                v-bind:class="{ rangecheck: rc_col_3_29 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_3_29" target="input_col_3_29" triggers="hover" placement="topleft">{{ rc_col_3_29_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_3('_29')" :triggers="'hover'" target="input_col_3_29" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_3_29") }}
              </b-tooltip>
              <b-form-input
                :disabled="EDForm.s_col_3_30"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_3._30.$model"
                :state="validateState($v.col_3._30)"
                id="input_col_3_30"
                v-on:keyup="checkValidate($event, 'col_3_30')"
                v-bind:class="{ rangecheck: rc_col_3_30 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_3_30" target="input_col_3_30" triggers="hover" placement="topleft">{{ rc_col_3_30_message }}</b-tooltip>
              <b-tooltip :show="validateTooltipStateCol_3('_30')" :triggers="'hover'" target="input_col_3_30" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_3_30") }}
              </b-tooltip>
              <b-form-input
                :disabled="EDForm.s_col_3_31"
                type="number"
                v-on:keydown="onKeyCheck($event)"
                v-model.number="$v.col_3._31.$model"
                :state="validateState($v.col_3._31)"
                id="input_col_3_31"
                v-on:keyup="checkValidate($event, 'col_3_31')"
                v-bind:class="{ rangecheck: rc_col_3_31 }"
                :formatter="format_one_decimal"
                size="sm"
                class="text-center mbtm"
                placeholder=""
              ></b-form-input>
              <b-tooltip v-if="rc_col_3_31" target="input_col_3_31" triggers="hover" placement="topleft">{{ rc_col_3_31_message }}</b-tooltip>
              <b-tooltip v-if="!rc_col_3_31" :triggers="'hover'" target="input_col_3_30" placement="topleft" custom-class="validation-tooltip">
                {{ tooltipMessage("col_3_31") }}
              </b-tooltip>
              <b-form-input type="number" v-model.number="total_3" id="input10" size="sm" class="text-center mbtm bg-light-success" placeholder="" readonly></b-form-input>
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>
    <!-- ==end card 2 -->

    <b-row class="mt-n2">
      <b-col cols="12">
        <b-card>
          <b-row class="float-right">
            <div>
              <b-button variant="danger" class="mr-1" size="sm"><b-icon icon="x-circle-fill"></b-icon> Batal</b-button>
              <b-button variant="warning" v-if="this.t_data_status == 'update'" class="mr-1" @click="V_Form" size="sm" :disabled="!roles.isRoleEdit"><b-icon icon="pencil-fill"></b-icon> Update</b-button>
              <b-button variant="primary" v-if="this.t_data_status == 'insert'" @click="V_Form" class="mr-1" size="sm" :disabled="!roles.isRoleAdd"><b-icon icon="cursor-fill"></b-icon> Submit</b-button>
            </div>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { VueAutosuggest } from "vue-autosuggest";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { BOverlay,BRow, BCol, BTable, BCardText, BFormInput, BContainer, BButton, BCard, BFormSelect, BFormGroup, VBTooltip, BTooltip } from "bootstrap-vue";
import Helper from "@/api/helper";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import error_handler from "@/api/error_handler";
import { validationMixin } from "vuelidate";
import { required, minLength, between } from "vuelidate/lib/validators";
import { RC_PIAS, V_Hujan, GC_PIAS, CEK_REQUIRED } from "@/validators/piasvalidator";
import Ripple from "vue-ripple-directive";

import metadata from "@/api/metadata";
import Params from "@/attr/metadata/params";
import RC_helper from "@/validators/RC_helper";
import qc_helper from "@/api/qc_helper";

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BTable,
    BCardText,
    BFormInput,
    BContainer,
    BButton,
    BCard,
    BFormSelect,
    VueAutosuggest,
    vSelect,
    BFormGroup,
    VBTooltip,
    BTooltip,
    BOverlay,
  },

  props: ["t_station", "t_wmoid", "t_observer", "t_date", "t_observer_name", "t_path", "t_station_id", "t_wmoid", "roles"],
  mixins: [validationMixin],
  validations() {
    if (this.date_10) {
      return {
        
        col_1: {
          _1: { required, V_Hujan },
          _2: { required, V_Hujan },
          _3: { required, V_Hujan },
          _4: { required, V_Hujan },
          _5: { required, V_Hujan },
          _6: { required, V_Hujan },
          _7: { required, V_Hujan },
          _8: { required, V_Hujan },
          _9: { required, V_Hujan },
          _10: { required, V_Hujan },
        },
        col_2: {
          _11: { V_Hujan },
          _12: { V_Hujan },
          _13: { V_Hujan },
          _14: { V_Hujan },
          _15: { V_Hujan },
          _16: { V_Hujan },
          _17: { V_Hujan },
          _18: { V_Hujan },
          _19: { V_Hujan },
          _20: { V_Hujan },
        },
        col_3: {
          _21: { V_Hujan },
          _22: { V_Hujan },
          _23: { V_Hujan },
          _24: { V_Hujan },
          _25: { V_Hujan },
          _26: { V_Hujan },
          _27: { V_Hujan },
          _28: { V_Hujan },
          _29: { V_Hujan },
          _30: { V_Hujan },
          _31: { V_Hujan },
        },
      };
    }
    if (this.date_20) {
      return {
        col_1: {
          _1: { required, V_Hujan },
          _2: { required, V_Hujan },
          _3: { required, V_Hujan },
          _4: { required, V_Hujan },
          _5: { required, V_Hujan },
          _6: { required, V_Hujan },
          _7: { required, V_Hujan },
          _8: { required, V_Hujan },
          _9: { required, V_Hujan },
          _10: { required, V_Hujan },
        },
        col_2: {
          _11: { required, V_Hujan },
          _12: { required, V_Hujan },
          _13: { required, V_Hujan },
          _14: { required, V_Hujan },
          _15: { required, V_Hujan },
          _16: { required, V_Hujan },
          _17: { required, V_Hujan },
          _18: { required, V_Hujan },
          _19: { required, V_Hujan },
          _20: { required, V_Hujan },
        },
        col_3: {
          _21: { V_Hujan },
          _22: { V_Hujan },
          _23: { V_Hujan },
          _24: { V_Hujan },
          _25: { V_Hujan },
          _26: { V_Hujan },
          _27: { V_Hujan },
          _28: { V_Hujan },
          _29: { V_Hujan },
          _30: { V_Hujan },
          _31: { V_Hujan },
        },
      };
    }
    if (this.date_30) {
      let columnnya = {};
      columnnya["col_1"] = {
        _1: { required, V_Hujan },
        _2: { required, V_Hujan },
        _3: { required, V_Hujan },
        _4: { required, V_Hujan },
        _5: { required, V_Hujan },
        _6: { required, V_Hujan },
        _7: { required, V_Hujan },
        _8: { required, V_Hujan },
        _9: { required, V_Hujan },
        _10: { required, V_Hujan },
      };
      columnnya["col_2"] = {
        _11: { required, V_Hujan },
        _12: { required, V_Hujan },
        _13: { required, V_Hujan },
        _14: { required, V_Hujan },
        _15: { required, V_Hujan },
        _16: { required, V_Hujan },
        _17: { required, V_Hujan },
        _18: { required, V_Hujan },
        _19: { required, V_Hujan },
        _20: { required, V_Hujan },
      };
      let colnya3 = {};
      colnya3["_21"] = { required, V_Hujan };
      colnya3["_22"] = { required, V_Hujan };
      colnya3["_23"] = { required, V_Hujan };
      colnya3["_24"] = { required, V_Hujan };
      colnya3["_25"] = { required, V_Hujan };
      colnya3["_26"] = { required, V_Hujan };
      colnya3["_27"] = { required, V_Hujan };
      colnya3["_28"] = { required, V_Hujan };
      colnya3["_29"] = { V_Hujan };
      colnya3["_30"] = { V_Hujan };
      colnya3["_31"] = { V_Hujan };
      if (this.req_date_29) {
        colnya3["_29"] = { required, V_Hujan };
      }
      if (this.req_date_30) {
        colnya3["_30"] = { required, V_Hujan };
      }
      if (this.req_date_31) {
        colnya3["_31"] = { required, V_Hujan };
      }
      columnnya["col_3"] = colnya3;
      return columnnya;
    } else {
      return {
        col_1: {
          _1: { required, V_Hujan },
          _2: { V_Hujan },
          _3: { V_Hujan },
          _4: { V_Hujan },
          _5: { V_Hujan },
          _6: { V_Hujan },
          _7: { V_Hujan },
          _8: { V_Hujan },
          _9: { V_Hujan },
          _10: { V_Hujan },
        },
        col_2: {
          _11: { V_Hujan },
          _12: { V_Hujan },
          _13: { V_Hujan },
          _14: { V_Hujan },
          _15: { V_Hujan },
          _16: { V_Hujan },
          _17: { V_Hujan },
          _18: { V_Hujan },
          _19: { V_Hujan },
          _20: { V_Hujan },
        },
        col_3: {
          _21: { V_Hujan },
          _22: { V_Hujan },
          _23: { V_Hujan },
          _24: { V_Hujan },
          _25: { V_Hujan },
          _26: { V_Hujan },
          _27: { V_Hujan },
          _28: { V_Hujan },
          _29: { V_Hujan },
          _30: { V_Hujan },
          _31: { V_Hujan },
        },
      };
    }
  },
  data() {
    return {
      RC_MAX:"",
        RC_MIN:"",
        RC_SPECIAL:"",
      showLoading:false,
      reg_id: "",
      req_date_29: false,
      req_date_30: false,
      req_date_31: false,
      date_10: false,
      date_20: false,
      date_30: false,
      last_qc_histories: null,
      qc_histories_db: {},
      qc_flag_before: 0,
      RC: {},
      RC_helper: RC_helper,
      poshujan_selected: { title: "No Pos Hujan" },
      poshujan: "",
      s_id: "",
      path_hujan: "",
      t_data_status: "insert",
      option: [
        { title: "12072601a-Saentis", id: "20315945" },
        { title: "12072602a-Bandar Klippa", id: "20345945" },
      ],
      // datasuggest: ["110034", "230045", "340056", "450067", "560078", "670089"],
      // filteredOptions: [],
      poshujan_id: "",
      // poshujan_selected: "",
      // limit: 10,
      propinsi_opt: [],
      kabupaten_opt: [],
      poshujan_opt: [],
      col_1: {
        _1: "",
        _2: "",
        _3: "",
        _4: "",
        _5: "",
        _6: "",
        _7: "",
        _8: "",
        _9: "",
        _10: "",
      },
      col_2: {
        _11: "",
        _12: "",
        _13: "",
        _14: "",
        _15: "",
        _16: "",
        _17: "",
        _18: "",
        _19: "",
        _20: "",
      },
      col_3: {
        _21: "",
        _22: "",
        _23: "",
        _24: "",
        _25: "",
        _26: "",
        _27: "",
        _28: "",
        _29: "",
        _30: "",
        _31: "",
      },
      token: "",
      t_tahun: "",
      t_bulan: "",
      t_provinsi: "",
      t_pemeriksaan: "",
      temp_provinsiid: "",

      t_kabupaten: "",
      t_kecamatan: "",
      status_post: "INSERT",

      EDForm: {
        s_col_1_1: false,
        s_col_1_2: false,
        s_col_1_3: false,
        s_col_1_4: false,
        s_col_1_5: false,
        s_col_1_6: false,
        s_col_1_7: false,
        s_col_1_8: false,
        s_col_1_9: false,
        s_col_1_10: false,
        s_col_2_11: false,
        s_col_2_12: false,
        s_col_2_13: false,
        s_col_2_14: false,
        s_col_2_15: false,
        s_col_2_16: false,
        s_col_2_17: false,
        s_col_2_18: false,
        s_col_2_19: false,
        s_col_2_20: false,
        s_col_3_21: false,
        s_col_3_22: false,
        s_col_3_23: false,
        s_col_3_24: false,
        s_col_3_25: false,
        s_col_3_26: false,
        s_col_3_27: false,
        s_col_3_28: false,
        s_col_3_29: false,
        s_col_3_30: false,
        s_col_3_31: false,
      },
      DS_data: {
        1: "s_col_1_1",
        2: "s_col_1_2",
        3: "s_col_1_3",
        4: "s_col_1_4",
        5: "s_col_1_5",
        6: "s_col_1_6",
        7: "s_col_1_7",
        8: "s_col_1_8",
        9: "s_col_1_9",
        10: "s_col_1_10",
        11: "s_col_2_11",
        12: "s_col_2_12",
        13: "s_col_2_13",
        14: "s_col_2_14",
        15: "s_col_2_15",
        16: "s_col_2_16",
        17: "s_col_2_17",
        18: "s_col_2_18",
        19: "s_col_2_19",
        20: "s_col_2_20",
        21: "s_col_3_21",
        22: "s_col_3_22",
        23: "s_col_3_23",
        24: "s_col_3_24",
        25: "s_col_3_25",
        26: "s_col_3_26",
        27: "s_col_3_27",
        28: "s_col_3_28",
        29: "s_col_3_29",
        30: "s_col_3_30",
        31: "s_col_3_31",
      },
      datetime_col_1_1: "",
      datetime_col_1_2: "",
      datetime_col_1_3: "",
      datetime_col_1_4: "",
      datetime_col_1_5: "",
      datetime_col_1_6: "",
      datetime_col_1_7: "",
      datetime_col_1_8: "",
      datetime_col_1_9: "",
      datetime_col_1_10: "",
      datetime_col_2_11: "",
      datetime_col_2_12: "",
      datetime_col_2_13: "",
      datetime_col_2_14: "",
      datetime_col_2_15: "",
      datetime_col_2_16: "",
      datetime_col_2_17: "",
      datetime_col_2_18: "",
      datetime_col_2_19: "",
      datetime_col_2_20: "",
      datetime_col_3_21: "",
      datetime_col_3_22: "",
      datetime_col_3_23: "",
      datetime_col_3_24: "",
      datetime_col_3_25: "",
      datetime_col_3_26: "",
      datetime_col_3_27: "",
      datetime_col_3_28: "",
      datetime_col_3_29: "",
      datetime_col_3_30: "",
      datetime_col_3_31: "",

      rc_col_1_1: false,
      rc_col_1_1_message: "",
      rc_col_1_2: false,
      rc_col_1_2_message: "",
      rc_col_1_3: false,
      rc_col_1_3_message: "",
      rc_col_1_4: false,
      rc_col_1_4_message: "",
      rc_col_1_5: false,
      rc_col_1_5_message: "",
      rc_col_1_6: false,
      rc_col_1_6_message: "",
      rc_col_1_7: false,
      rc_col_1_7_message: "",
      rc_col_1_8: false,
      rc_col_1_8_message: "",
      rc_col_1_9: false,
      rc_col_1_9_message: "",
      rc_col_1_10: false,
      rc_col_1_10_message: "",
      rc_col_2_11: false,
      rc_col_2_11_message: "",
      rc_col_2_12: false,
      rc_col_2_12_message: "",
      rc_col_2_13: false,
      rc_col_2_13_message: "",
      rc_col_2_14: false,
      rc_col_2_14_message: "",
      rc_col_2_15: false,
      rc_col_2_15_message: "",
      rc_col_2_16: false,
      rc_col_2_16_message: "",
      rc_col_2_17: false,
      rc_col_2_17_message: "",
      rc_col_2_18: false,
      rc_col_2_18_message: "",
      rc_col_2_19: false,
      rc_col_2_19_message: "",
      rc_col_2_20: false,
      rc_col_2_20_message: "",
      rc_col_3_21: false,
      rc_col_3_21_message: "",
      rc_col_3_22: false,
      rc_col_3_22_message: "",
      rc_col_3_23: false,
      rc_col_3_23_message: "",
      rc_col_3_24: false,
      rc_col_3_24_message: "",
      rc_col_3_25: false,
      rc_col_3_25_message: "",
      rc_col_3_26: false,
      rc_col_3_26_message: "",
      rc_col_3_27: false,
      rc_col_3_27_message: "",
      rc_col_3_28: false,
      rc_col_3_28_message: "",
      rc_col_3_29: false,
      rc_col_3_29_message: "",
      rc_col_3_30: false,
      rc_col_3_30_message: "",
      rc_col_3_31: false,
      rc_col_3_31_message: "",
    };
  },
  computed: {
    total_1: function () {
      // let values = Object.values(this.col_1).filter(val=>val !== 9999 && val !== 8888);
      // return values.reduce((a, b) => a + b)
      return Object.values(this.col_1).reduce((a, b) => (a == 9999 || a == 8888 ? 0 : a || 0) + (b == 9999 || b == 8888 ? 0 : b || 0));
    },
    total_2: function () {
      // let values = Object.values(this.col_1).filter(val=>val !== 9999 && val !== 8888);
      // return values.reduce((a, b) => a + b)
      return Object.values(this.col_2).reduce((a, b) => (a == 9999 || a == 8888 ? 0 : a || 0) + (b == 9999 || b == 8888 ? 0 : b || 0));
    },
    total_3: function () {
      // let values = Object.values(this.col_1).filter(val=>val !== 9999 && val !== 8888);
      // return values.reduce((a, b) => a + b)
      return Object.values(this.col_3).reduce((a, b) => (a == 9999 || a == 8888 ? 0 : a || 0) + (b == 9999 || b == 8888 ? 0 : b || 0));
    },
    t_data_id() {
      return this.s_id + "_formhujan_" + this.t_tahun + "-" + this.t_bulan + "_" + this.poshujan_id;
    },
  },
  created() {
    // this.$http.get('/autosuggest/data')
    //   .then(res => { this.datasuggest = res })
  },
  async mounted() {
    var tahun = new Date().getFullYear();
    this.t_tahun = tahun;
    // await this.listPropinsi();
    await this.listAllPropinsi();
    await this.listAllKabupaten();
    await this.listPoshujanByCoordinator();
    // this.t_provinsi = propinsi_id
    // await this.listKabupaten(propinsi_id)
    // console.log("oberver_name : ",this.t_observer_name)
  },

  watch: {
    // poshujan_id(value) {
    //   console.log(this.poshujan_id);
    //   console.log("poshujan ID : ", value);
    //   // this.poshujan_id = value
    // },
    t_path(val) {
      // this.listPropinsi();
      this.listAllPropinsi();
      this.listAllKabupaten();
      this.listPoshujanByCoordinator();
    },
    t_kabupaten(val) {
      // console.log("old ",old);
      // this.listPoshujan(val.value);
      // if(old == )
      // if (val == undefined) {
      //   let prov_id = this.t_provinsi;
      //   this.listPoshujanByProvinsi(prov_id);
      // } else {
      //   this.listPoshujan(val);
      // }
      // console.log(val)
    },
    t_station(val) {
      // var arr = this.t_path.split("/")
      // console.l
      // var region_id = arr[1]
      // var propinsi_id = arr[2]
      // this.listPropinsi(region_id)
      // this.t_provinsi = propinsi_id
      // this.listKabupaten(propinsi_id)
    },
    // poshujan_selected(value) {

    //   if (this.poshujan_id != "" && this.t_tahun != "" && this.t_bulan != "") {
    //     this.getDataHujan();
    //   }
    // },
    poshujan(val, old) {
      // console.log("kabypaten : ", this.t_kabupaten);
      this.poshujan_id = val.value;
      this.s_id = val.s_id;
      this.reg_id = val.reg_id;
      // console.log(val,old);
      // console.log(val.K_id + " = " + this.t_kabupaten);
      this.path_hujan = val.path;
      // if(val.K_id != this.t_kabupaten){
      //   this.t_kabupaten = `${val.K_id}`;
      // }
      
      this.t_kabupaten = `${val.K_id}`;
      this.t_provinsi = `${val.P_id}`;
      if (val == "") {
        this.t_kabupaten = `${old.K_id}`;
        this.t_provinsi = `${old.P_id}`;
      }

      if (this.poshujan_id != "" && this.t_tahun != "" && this.t_bulan != "") {
        this.resetForm()
        this.getDataHujan();
      }
    },
    t_bulan(value) {
      if (this.t_tahun != "" && this.poshujan_id != "") {
        this.resetForm()
        this.getDataHujan();
      }
    },
    t_tahun(value) {
      if (this.t_bulan != "" && this.poshujan_id != "") {
        this.resetForm()
        this.getDataHujan();
      }
    },
  },
  methods: {
    onKeyCheck(event) {
      // console.log('onKeyCheck', event)
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault();
      }
    },
    checkRC(value,param) {
      let status = true;
      let message = "";
      
      let max = this.RC_MAX;
      let min = this.RC_MIN;
      let spec = this.RC_SPECIAL;
      
      if(spec == null){
        if (min == null && max == null) {
          status = true;
        }else if(value === "" || value == null){
          status = true;
        } else if (min == null && max != null) {
          if (value <= max) {
            status = true;
          } else {
            status = false;
            message = "Range Check - Batas Bawah , Batas Atas " + max;
          }
        } else if (min != null && max == null) {
          if (value >= min) {
            status = true;
          } else {
            status = false;
            message = "Range Check - Batas Bawah " + min + ", Batas Atas ";
          }
        } else {
          if (value >= min && value <= max) {
            status = true;
          
          } else if (value == 9999 || value == 8888) {
            status = true;
          } else {
            status = false;
            message = "Range Check - Batas Bawah " + min + ", Batas Atas " + max;
          }
        }
      }else{
        let arr_spec = spec.split(",");
        if ((value >= min && value <= max) || arr_spec.indexOf(value) != -1) {
          status = true;
        }else if(value === "" || value === null){
          status = true;
        }else if(value == 9999 || value == 8888){
          status = true;
        }else{
          status = false;
          message = "Range Check - Batas Bawah " + min + ", Batas Atas " + max+", Special values "+spec;
        }
      }
      
      
      return { status: status, message: message };
    },
    async getQCRC(path) {
      if(path != undefined){
        this.showLoading = true;
        var { data } = await metadata.getFormInput(path);
        // console.log("patnya : ",path);
        // console.log("datana : ",data);
        // console.log("form input : ",data["BmkgSatu.metadata.bmkgentry.IBMKGEntry"]);
        var QC_RC = data["BmkgSatu.metadata.qc.IFormHujanHarianqc"];
        this.RC_MAX = QC_RC.qc_formhujanharian_rr_phk_mm_max;
        this.RC_MIN = QC_RC.qc_formhujanharian_rr_phk_mm_min;
        this.RC_SPECIAL = QC_RC.qc_formhujanharian_rr_phk_mm_values;
        this.showLoading = false;
      }
      
    },
    notSkip() {
      var col_1 = this.col_1;
      let oldVal = 1;
      let oldAttr = "";
      let newVal = 1;
      let data = Object.values(col_1);
      let allDataNull = data.every((val, i, arr) => val === "");
      let result = true;
      if (!allDataNull) {
        for (var i in col_1) {
          // console.log("data col : ",col_1[i]);
          newVal = col_1[i];
          // if((oldVal == "" || oldVal == '' || oldVal == null) && (newVal != "" || newVal != '' || newVal != null)){
          if (oldVal === "" && newVal != "") {
            // console.log("old val: "+oldVal+" new val : "+newVal)
            result = false;
          }
          oldVal = col_1[i];
          oldAttr = i;
        }
      }
      return result;
    },
    notSkip2() {
      var col_2 = this.col_2;
      let oldVal = 1;
      let oldAttr = "";
      let newVal = 1;
      let data = Object.values(col_2);
      let allDataNull = data.every((val, i, arr) => val === "");
      let result = true;
      if (!allDataNull) {
        for (var i in col_2) {
          // console.log("data col : ",col_2[i]);
          newVal = col_2[i];
          // if((oldVal == "" || oldVal == '' || oldVal == null) && (newVal != "" || newVal != '' || newVal != null)){
          if (oldVal === "" && newVal != "") {
            result = false;
          }
          oldVal = col_2[i];
          oldAttr = i;
        }
      }
      return result;
    },
    // notSkip2(){
    //   var col_2 = this.col_2;
    //   let oldVal = 1;
    //   let oldAttr = "";
    //   let newVal = 1
    //   let data = Object.values(col_2);
    //   let allDataNull = data.every( (val, i, arr) => val === "");
    //   let result = true;
    //   if(!allDataNull){
    //     for(var i in col_2){
    //       console.log("data col : ",col_2[i]);
    //       newVal = col_2[i];
    //       if(oldVal == ""  && newVal != "" ){
    //         // console.log("oldval : ",oldVal);
    //         // console.log("newVal : ",newVal);
    //         result = false;
    //       }
    //       oldVal = col_2[i];
    //       oldAttr = i;
    //     }
    //   }
    //   return result;

    // },
    notSkip3() {
      var col_3 = this.col_3;
      let oldVal = 1;
      let oldAttr = "";
      let newVal = 1;
      let data = Object.values(col_3);
      let allDataNull = data.every((val, i, arr) => val === "");
      let result = true;
      if (!allDataNull) {
        for (var i in col_3) {
          newVal = col_3[i];
          // console.log("data col : ",oldVal,newVal);
          if (oldVal === "" && newVal != "") {
            // console.log("terpanggil ")
            result = false;
          }
          oldVal = col_3[i];
          oldAttr = i;
        }
      }
      return result;
    },
    getRequiredByDate() {
      // Initial date
      let tahun = this.t_tahun;
      let bulan = this.t_bulan;

      const dateNow = new Date();
      let yearNow = dateNow.getFullYear();
      let month = dateNow.getMonth() + 1;
      let day = parseInt(dateNow.getDate());
      // Final date
      const dateEntry = new Date(tahun + "-" + bulan);
      if (yearNow > tahun) {
        // console.log("tahun lebih kecil");
        // console.log(tahun+'-'+yearNow);
        this.date_30 = true;
        this.date_20 = false;
        this.date_10 = false;
      } else if (yearNow == tahun) {
        // console.log("tahun sama besar");
        //  console.log(tahun+'-'+yearNow);
        if (month > bulan) {
          this.date_30 = true;
          this.date_20 = false;
          this.date_10 = false;
        }
        if (month == bulan) {
          this.date_30 = false;
          this.date_20 = false;
          this.date_10 = false;
          if (day > 20) {
            this.date_30 = false;
            this.date_20 = true;
            this.date_10 = false;
          } else if (day > 10) {
            this.date_30 = false;
            this.date_20 = false;
            this.date_10 = true;
          }
          this.disableByDay();
        }
        if (month < bulan) {
          this.date_30 = true;
          this.date_20 = false;
          this.date_10 = false;
          this.disableForm();
        }
      } else {
        this.date_30 = true;
        this.date_20 = false;
        this.date_10 = false;
        this.disableForm();
      }
      // console.log("dateNow : ",yearNow);
      // console.log("dateEntry : ",month);
      // if (dateNow > dateEntry) {
      //   alert("hari ini udah lewat bulan");
      // }else if (dateNow < dateEntry){
      //   alert("hari ini udah blm bulan.");
      // }else{
      //   alert("sama bulan");
      // }
      this.req_date_29 = false;
      this.req_date_30 = false;
      this.req_date_31 = false;
      var lastDayOfMonth = new Date(this.t_tahun, parseInt(this.t_bulan), 0).getDate();
      if (lastDayOfMonth == "29") {
        this.req_date_29 = true;
      } else if (lastDayOfMonth == "30") {
        this.req_date_29 = true;
        this.req_date_30 = true;
      } else if (lastDayOfMonth == "31") {
        this.req_date_29 = true;
        this.req_date_30 = true;
        this.req_date_31 = true;
      }
    },
    async setQCData() {
      // console.log("call set data qc");
      const qc_data = await metadata.getQCData(this.path_hujan, Params.GET_QC_STATION_KEY("form_hujan"));
      const qc_params = Params.GET_PARAMS("form_hujan");
      const qc_key = Params.GET_KEY("form_hujan");
      this.RC_helper.setData(qc_data, qc_params, qc_key);
      // console.log("set QC : ", qc_data, qc_params,qc_key)
      // console.log("set qc_params : ", qc_params)
      // console.log("set qc_key : ", qc_key)
    },
    format_one_decimal(e) {
      if (Math.round(e * 10) / 10 != e) {
        let text = String(e);
        let filter = text.substring(0, text.length - 1);
        return filter;
      } else {
        if (Math.floor(e) === e) {
          return e;
        } else {
          if (e.toString().indexOf(".") > 0) {
            let count = e.toString().split(".")[1].length || 0;
            return count > 1 ? parseFloat(e).toFixed(1) : e;
          } else {
            return e;
          }
        }
        return e;
      }
    },
    checkValidate: function (event, type) {
      // console.log("event", event);
      let value = event.target.value;
      var type_field = "";
      switch (type) {
        case "col_1_1":
          // var raw = RC_PIAS(value, "col_1_1");
          type_field = "rr_07_01";
          var raw = this.checkRC(value, "rr_07_01");
          var gc = V_Hujan(value);
          // console.log("raw", raw);
          // console.log("gc", gc);
          this.rc_col_1_1 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_1_1_message = raw.message;

          // console.log("raw_ : ", raw)
          break;
        case "col_1_2":
          // var raw = RC_PIAS(value, "col_1_2");
          type_field = "rr_07_02";
          var raw = this.checkRC(value, "rr_07_02");
          var gc = V_Hujan(value);

          this.rc_col_1_2 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_1_2_message = raw.message;
          break;
        case "col_1_3":
          // var raw = RC_PIAS(value, "col_1_3");
          type_field = "rr_07_03";
          var raw = this.checkRC(value, "rr_07_03");
          var gc = V_Hujan(value);
          this.rc_col_1_3 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_1_3_message = raw.message;
          break;
        case "col_1_4":
          // var raw = RC_PIAS(value, "col_1_4");
          type_field = "rr_07_04";
          var raw = this.checkRC(value, "rr_07_04");
          var gc = V_Hujan(value);

          this.rc_col_1_4 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_1_4_message = raw.message;
          break;
        case "col_1_5":
          // var raw = RC_PIAS(value, "col_1_5");
          type_field = "rr_07_05";
          var raw = this.checkRC(value, "rr_07_05");
          var gc = V_Hujan(value);

          this.rc_col_1_5 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_1_5_message = raw.message;
          break;
        case "col_1_6":
          // var raw = RC_PIAS(value, "col_1_6");
          type_field = "rr_07_06";
          var raw = this.checkRC(value, "rr_07_06");
          var gc = V_Hujan(value);

          this.rc_col_1_6 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_1_6_message = raw.message;
          break;
        case "col_1_7":
          // var raw = RC_PIAS(value, "col_1_7");
          type_field = "rr_07_07";
          var raw = this.checkRC(value, "rr_07_07");
          var gc = V_Hujan(value);

          this.rc_col_1_7 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_1_7_message = raw.message;
          break;
        case "col_1_8":
          // var raw = RC_PIAS(value, "col_1_8");
          type_field = "rr_07_08";
          var raw = this.checkRC(value, "rr_07_08");
          var gc = V_Hujan(value);

          this.rc_col_1_8 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_1_8_message = raw.message;
          break;
        case "col_1_9":
          // var raw = RC_PIAS(value, "col_1_9");
          type_field = "rr_07_09";
          var raw = this.checkRC(value, "rr_07_09");
          var gc = V_Hujan(value);

          this.rc_col_1_9 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_1_9_message = raw.message;
          break;
        case "col_1_10":
          // var raw = RC_PIAS(value, "col_1_10");
          type_field = "rr_07_10";
          var raw = this.checkRC(value, "rr_07_10");
          var gc = V_Hujan(value);

          this.rc_col_1_10 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_1_10_message = raw.message;
          break;

        case "col_2_11":
          // var raw = RC_PIAS(value, "col_2_11");
          type_field = "rr_07_11";
          var raw = this.checkRC(value, "rr_07_11");
          var gc = V_Hujan(value);

          this.rc_col_2_11 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_2_11_message = raw.message;
          break;
        case "col_2_12":
          // var raw = RC_PIAS(value, "col_2_12");
          type_field = "rr_07_12";
          var raw = this.checkRC(value, "rr_07_12");
          var gc = V_Hujan(value);

          this.rc_col_2_12 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_2_12_message = raw.message;
          break;
        case "col_2_13":
          // var raw = RC_PIAS(value, "col_2_13");
          type_field = "rr_07_13";
          var raw = this.checkRC(value, "rr_07_13");
          var gc = V_Hujan(value);

          this.rc_col_2_13 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_2_13_message = raw.message;
          break;
        case "col_2_14":
          // var raw = RC_PIAS(value, "col_2_14");
          type_field = "rr_07_14";
          var raw = this.checkRC(value, "rr_07_14");
          var gc = V_Hujan(value);
          this.rc_col_2_14 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_2_14_message = raw.message;
          break;
        case "col_2_15":
          // var raw = RC_PIAS(value, "col_2_15");
          type_field = "rr_07_15";
          var raw = this.checkRC(value, "rr_07_15");
          var gc = V_Hujan(value);

          this.rc_col_2_15 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_2_15_message = raw.message;
          break;
        case "col_2_16":
          // var raw = RC_PIAS(value, "col_2_16");
          type_field = "rr_07_16";
          var raw = this.checkRC(value, "rr_07_16");
          var gc = V_Hujan(value);

          this.rc_col_2_16 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_2_16_message = raw.message;
          break;
        case "col_2_17":
          // var raw = RC_PIAS(value, "col_2_17");
          type_field = "rr_07_17";
          var raw = this.checkRC(value, "rr_07_17");
          var gc = V_Hujan(value);

          this.rc_col_2_17 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_2_17_message = raw.message;
          break;
        case "col_2_18":
          // var raw = RC_PIAS(value, "col_2_18");
          type_field = "rr_07_18";
          var raw = this.checkRC(value, "rr_07_18");
          var gc = V_Hujan(value);

          this.rc_col_2_18 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_2_18_message = raw.message;
          break;
        case "col_2_19":
          // var raw = RC_PIAS(value, "col_2_19");
          type_field = "rr_07_19";
          var raw = this.checkRC(value, "rr_07_19");
          var gc = V_Hujan(value);

          this.rc_col_2_19 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_2_19_message = raw.message;
          break;
        case "col_2_20":
          // var raw = RC_PIAS(value, "col_2_20");
          type_field = "rr_07_20";
          var raw = this.checkRC(value, "rr_07_20");
          var gc = V_Hujan(value);

          this.rc_col_2_20 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_2_20_message = raw.message;
          break;

        case "col_3_21":
          // var raw = RC_PIAS(value, "col_3_21");
          type_field = "rr_07_21";
          var raw = this.checkRC(value, "rr_07_21");
          var gc = V_Hujan(value);

          this.rc_col_3_21 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_3_21_message = raw.message;
          break;
        case "col_3_22":
          // var raw = RC_PIAS(value, "col_3_22");
          type_field = "rr_07_22";
          var raw = this.checkRC(value, "rr_07_22");
          var gc = V_Hujan(value);

          this.rc_col_3_22 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_3_22_message = raw.message;
          break;
        case "col_3_23":
          // var raw = RC_PIAS(value, "col_3_23");
          type_field = "rr_07_23";
          var raw = this.checkRC(value, "rr_07_23");
          var gc = V_Hujan(value);

          this.rc_col_3_23 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_3_23_message = raw.message;
          break;
        case "col_3_24":
          // var raw = RC_PIAS(value, "col_3_24");
          type_field = "rr_07_24";
          var raw = this.checkRC(value, "rr_07_24");
          var gc = V_Hujan(value);

          this.rc_col_3_24 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_3_24_message = raw.message;
          break;
        case "col_3_25":
          // var raw = RC_PIAS(value, "col_3_25");
          type_field = "rr_07_25";
          var raw = this.checkRC(value, "rr_07_25");
          var gc = V_Hujan(value);

          this.rc_col_3_25 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_3_25_message = raw.message;
          break;
        case "col_3_26":
          // var raw = RC_PIAS(value, "col_3_26");
          type_field = "rr_07_26";
          var raw = this.checkRC(value, "rr_07_26");
          var gc = V_Hujan(value);

          this.rc_col_3_26 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_3_26_message = raw.message;
          break;
        case "col_3_27":
          // var raw = RC_PIAS(value, "col_3_27");
          type_field = "rr_07_27";
          var raw = this.checkRC(value, "rr_07_27");
          var gc = V_Hujan(value);

          this.rc_col_3_27 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_3_27_message = raw.message;
          break;
        case "col_3_28":
          // var raw = RC_PIAS(value, "col_3_28");
          type_field = "rr_07_28";
          var raw = this.checkRC(value, "rr_07_28");
          var gc = V_Hujan(value);

          this.rc_col_3_28 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_3_28_message = raw.message;
          break;
        case "col_3_29":
          // var raw = RC_PIAS(value, "col_3_29");
          type_field = "rr_07_29";
          var raw = this.checkRC(value, "rr_07_29");
          var gc = V_Hujan(value);

          this.rc_col_3_29 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_3_29_message = raw.message;
          break;
        case "col_3_30":
          // var raw = RC_PIAS(value, "col_3_30");
          type_field = "rr_07_30";
          var raw = this.checkRC(value, "rr_07_30");
          var gc = V_Hujan(value);

          this.rc_col_3_30 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_3_30_message = raw.message;
          break;
        case "col_3_31":
          // var raw = RC_PIAS(value, "col_3_31");
          type_field = "rr_07_31";
          var raw = this.checkRC(value, "rr_07_31");
          var gc = V_Hujan(value);

          this.rc_col_3_31 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_3_31_message = raw.message;
          break;

        default:
          break;
      }
      this.RC[type_field] = {
        status: gc && !raw.status,
        value: value,
        message: raw.message,
      };
      // console.log("RC : ",this.RC)
    },
    //validate tooltips GC
    tooltipMessage(attr) {
      return GC_PIAS(attr).message;
    },
    validateTooltipStateCol_1(name) {
      // const { $error } = this.$v.col_1[name];
      // return $error;
      return false;
    },
    validateTooltipStateCol_2(name) {
      return false;
    },
    validateTooltipStateCol_3(name) {
      return false;
    },
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    EditValidate(value, type) {
      // console.log(value, type);
      // let value = event.target.value;
      var type_field = "";
      switch (type) {
        case "col_1_1":
          // var raw = RC_PIAS(value, "col_1_1");
          type_field = "rr_07_01";
          var raw = this.checkRC(value, "rr_07_01");
          var gc = V_Hujan(value);
          // console.log("raw", raw);
          // console.log("gc", gc);
          this.rc_col_1_1 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_1_1_message = raw.message;

          // console.log("raw_ : ", raw)
          break;
        case "col_1_2":
          // var raw = RC_PIAS(value, "col_1_2");
          type_field = "rr_07_02";
          var raw = this.checkRC(value, "rr_07_02");
          var gc = V_Hujan(value);

          this.rc_col_1_2 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_1_2_message = raw.message;
          break;
        case "col_1_3":
          // var raw = RC_PIAS(value, "col_1_3");
          type_field = "rr_07_03";
          var raw = this.checkRC(value, "rr_07_03");
          var gc = V_Hujan(value);
          this.rc_col_1_3 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_1_3_message = raw.message;
          break;
        case "col_1_4":
          // var raw = RC_PIAS(value, "col_1_4");
          type_field = "rr_07_04";
          var raw = this.checkRC(value, "rr_07_04");
          var gc = V_Hujan(value);

          this.rc_col_1_4 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_1_4_message = raw.message;
          break;
        case "col_1_5":
          // var raw = RC_PIAS(value, "col_1_5");
          type_field = "rr_07_05";
          var raw = this.checkRC(value, "rr_07_05");
          var gc = V_Hujan(value);

          this.rc_col_1_5 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_1_5_message = raw.message;
          break;
        case "col_1_6":
          // var raw = RC_PIAS(value, "col_1_6");
          type_field = "rr_07_06";
          var raw = this.checkRC(value, "rr_07_06");
          var gc = V_Hujan(value);

          this.rc_col_1_6 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_1_6_message = raw.message;
          break;
        case "col_1_7":
          // var raw = RC_PIAS(value, "col_1_7");
          type_field = "rr_07_07";
          var raw = this.checkRC(value, "rr_07_07");
          var gc = V_Hujan(value);

          this.rc_col_1_7 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_1_7_message = raw.message;
          break;
        case "col_1_8":
          // var raw = RC_PIAS(value, "col_1_8");
          type_field = "rr_07_08";
          var raw = this.checkRC(value, "rr_07_08");
          var gc = V_Hujan(value);

          this.rc_col_1_8 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_1_8_message = raw.message;
          break;
        case "col_1_9":
          // var raw = RC_PIAS(value, "col_1_9");
          type_field = "rr_07_09";
          var raw = this.checkRC(value, "rr_07_09");
          var gc = V_Hujan(value);

          this.rc_col_1_9 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_1_9_message = raw.message;
          break;
        case "col_1_10":
          // var raw = RC_PIAS(value, "col_1_10");
          type_field = "rr_07_10";
          var raw = this.checkRC(value, "rr_07_10");
          var gc = V_Hujan(value);

          this.rc_col_1_10 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_1_10_message = raw.message;
          break;

        case "col_2_11":
          // var raw = RC_PIAS(value, "col_2_11");
          type_field = "rr_07_11";
          var raw = this.checkRC(value, "rr_07_11");
          var gc = V_Hujan(value);

          this.rc_col_2_11 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_2_11_message = raw.message;
          break;
        case "col_2_12":
          // var raw = RC_PIAS(value, "col_2_12");
          type_field = "rr_07_12";
          var raw = this.checkRC(value, "rr_07_12");
          var gc = V_Hujan(value);

          this.rc_col_2_12 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_2_12_message = raw.message;
          break;
        case "col_2_13":
          // var raw = RC_PIAS(value, "col_2_13");
          type_field = "rr_07_13";
          var raw = this.checkRC(value, "rr_07_13");
          var gc = V_Hujan(value);

          this.rc_col_2_13 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_2_13_message = raw.message;
          break;
        case "col_2_14":
          // var raw = RC_PIAS(value, "col_2_14");
          type_field = "rr_07_14";
          var raw = this.checkRC(value, "rr_07_14");
          var gc = V_Hujan(value);
          this.rc_col_2_14 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_2_14_message = raw.message;
          break;
        case "col_2_15":
          // var raw = RC_PIAS(value, "col_2_15");
          type_field = "rr_07_15";
          var raw = this.checkRC(value, "rr_07_15");
          var gc = V_Hujan(value);

          this.rc_col_2_15 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_2_15_message = raw.message;
          break;
        case "col_2_16":
          // var raw = RC_PIAS(value, "col_2_16");
          type_field = "rr_07_16";
          var raw = this.checkRC(value, "rr_07_16");
          var gc = V_Hujan(value);

          this.rc_col_2_16 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_2_16_message = raw.message;
          break;
        case "col_2_17":
          // var raw = RC_PIAS(value, "col_2_17");
          type_field = "rr_07_17";
          var raw = this.checkRC(value, "rr_07_17");
          var gc = V_Hujan(value);

          this.rc_col_2_17 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_2_17_message = raw.message;
          break;
        case "col_2_18":
          // var raw = RC_PIAS(value, "col_2_18");
          type_field = "rr_07_18";
          var raw = this.checkRC(value, "rr_07_18");
          var gc = V_Hujan(value);

          this.rc_col_2_18 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_2_18_message = raw.message;
          break;
        case "col_2_19":
          // var raw = RC_PIAS(value, "col_2_19");
          type_field = "rr_07_19";
          var raw = this.checkRC(value, "rr_07_19");
          var gc = V_Hujan(value);

          this.rc_col_2_19 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_2_19_message = raw.message;
          break;
        case "col_2_20":
          // var raw = RC_PIAS(value, "col_2_20");
          type_field = "rr_07_20";
          var raw = this.checkRC(value, "rr_07_20");
          var gc = V_Hujan(value);

          this.rc_col_2_20 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_2_20_message = raw.message;
          break;

        case "col_3_21":
          // var raw = RC_PIAS(value, "col_3_21");
          type_field = "rr_07_21";
          var raw = this.checkRC(value, "rr_07_21");
          var gc = V_Hujan(value);

          this.rc_col_3_21 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_3_21_message = raw.message;
          break;
        case "col_3_22":
          // var raw = RC_PIAS(value, "col_3_22");
          type_field = "rr_07_22";
          var raw = this.checkRC(value, "rr_07_22");
          var gc = V_Hujan(value);

          this.rc_col_3_22 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_3_22_message = raw.message;
          break;
        case "col_3_23":
          // var raw = RC_PIAS(value, "col_3_23");
          type_field = "rr_07_23";
          var raw = this.checkRC(value, "rr_07_23");
          var gc = V_Hujan(value);

          this.rc_col_3_23 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_3_23_message = raw.message;
          break;
        case "col_3_24":
          // var raw = RC_PIAS(value, "col_3_24");
          type_field = "rr_07_24";
          var raw = this.checkRC(value, "rr_07_24");
          var gc = V_Hujan(value);

          this.rc_col_3_24 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_3_24_message = raw.message;
          break;
        case "col_3_25":
          // var raw = RC_PIAS(value, "col_3_25");
          type_field = "rr_07_25";
          var raw = this.checkRC(value, "rr_07_25");
          var gc = V_Hujan(value);

          this.rc_col_3_25 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_3_25_message = raw.message;
          break;
        case "col_3_26":
          // var raw = RC_PIAS(value, "col_3_26");
          type_field = "rr_07_26";
          var raw = this.checkRC(value, "rr_07_26");
          var gc = V_Hujan(value);

          this.rc_col_3_26 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_3_26_message = raw.message;
          break;
        case "col_3_27":
          // var raw = RC_PIAS(value, "col_3_27");
          type_field = "rr_07_27";
          var raw = this.checkRC(value, "rr_07_27");
          var gc = V_Hujan(value);

          this.rc_col_3_27 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_3_27_message = raw.message;
          break;
        case "col_3_28":
          // var raw = RC_PIAS(value, "col_3_28");
          type_field = "rr_07_28";
          var raw = this.checkRC(value, "rr_07_28");
          var gc = V_Hujan(value);

          this.rc_col_3_28 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_3_28_message = raw.message;
          break;
        case "col_3_29":
          // var raw = RC_PIAS(value, "col_3_29");
          type_field = "rr_07_29";
          var raw = this.checkRC(value, "rr_07_29");
          var gc = V_Hujan(value);

          this.rc_col_3_29 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_3_29_message = raw.message;
          break;
        case "col_3_30":
          // var raw = RC_PIAS(value, "col_3_30");
          type_field = "rr_07_30";
          var raw = this.checkRC(value, "rr_07_30");
          var gc = V_Hujan(value);

          this.rc_col_3_30 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_3_30_message = raw.message;
          break;
        case "col_3_31":
          // var raw = RC_PIAS(value, "col_3_31");
          type_field = "rr_07_31";
          var raw = this.checkRC(value, "rr_07_31");
          var gc = V_Hujan(value);

          this.rc_col_3_31 = !raw.status ? (!gc ? false : true) : false;
          this.rc_col_3_31_message = raw.message;
          break;

        default:
          break;
      }
      this.RC[type_field] = {
        status: gc && !raw.status,
        value: value,
        message: raw.message,
      };
      // console.log("RC : ",this.RC)
    },
    V_Form() {
      // console.log("skip 1")
      let cekSkip1 = this.notSkip();
      // console.log("skip 2")
      let cekSkip2 = this.notSkip2();
      // console.log("skip 3")
      let cekSkip3 = this.notSkip3();
      // console.log("cek skip : ", cekSkip1,cekSkip2,cekSkip3)
      if (!cekSkip1 || !cekSkip2 || !cekSkip3) {
        this.$swal({
          title: "ERROR",
          text: "Isian Tidak Boleh Melompat, Harus berurutan!",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });

        return
      }
      // console.log("skip valid",this.$v)
      // console.log("rc: ",this.RC)
      // console.log("$v : ",this.$v)
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Data tidak valid. Periksa kembali inputan anda",
            icon: "BellIcon",
            variant: "danger",
          },
        });
        return;
      } else {
        if (this.t_tahun == "" || this.t_bulan == "" || this.t_provinsi == "" || this.t_kabupaten == "" || this.poshujan_id == "") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Data tidak valid. Periksa kembali inputan anda",
              icon: "BellIcon",
              variant: "danger",
            },
          });
          return;
        }
        let dataRc = Object.entries(this.RC).filter((e) => e[1].status);

        if (dataRc.length > 0) {
          this.$swal({
            title: "Apakah Anda Yakin?",
            text: "Ada Data Terkena Range Check ",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Submit",
            cancelButtonText: "Cek Data",
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              this.submitHujan();
            } else if (result.dismiss === "cancel") {
              return;
            }
          });
        } else {
          this.submitHujan();
        }
      }
    },
    async getDataHujan() {
      this.showLoading = true;
      // this.getRequiredByDate();
      // await this.setQCData();
      await this.getQCRC(this.path_hujan);
      if (this.poshujan_id != "") {
        // const arr_id = this.poshujan_id.split("-")
        const formhujan_id = this.s_id + "_formhujan_" + this.t_tahun + "-" + this.t_bulan + "_" + this.poshujan_id;
        const get_url = Helper.getBaseUrl() + this.path_hujan + "/klimatologi/pias/formhujan/" + formhujan_id;
        this.t_data_status = "insert";
        try {
          const { data } = await axios.get(get_url, Helper.getConfig());
          this.$swal({
            title: "Apakah Anda Yakin?",
            text: "Data Tanggal Tersebut Sudah Ada, Edit data??",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Edit",
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              this.t_data_status = "update";
              this.showLoading = false;
              this.enableForm();
              this.disableByMonth();
              // this.disableByDay();
              this.getRequiredByDate();
            } else if (result.dismiss === "cancel") {
              this.showLoading = false;
              this.disableForm();
              return;
            }
          });
          this.t_data_status = "update";
          this.status_post = "PATCH";
          if (data.qc_histories != null) {
            this.qc_histories_db = data.qc_histories;
          }
          this.qc_flag_before = data.qc_flag;
          if (data.rr_07_01 != null) {
            this.EditValidate(data.rr_07_01, "col_1_1");
            this.col_1._1 = data.rr_07_01;
            this.datetime_col_1_1 = data.rr_07_01_timestamp;
            // this.s_col_1_1 = true;
          }
          if (data.rr_07_02 != null) {
            this.EditValidate(data.rr_07_02, "col_1_2");
            this.col_1._2 = data.rr_07_02;
            this.datetime_col_1_2 = data.rr_07_02_timestamp;
            // this.s_col_1_2 = true;
          }
          if (data.rr_07_03 != null) {
            this.EditValidate(data.rr_07_03, "col_1_3");
            this.col_1._3 = data.rr_07_03;
            this.datetime_col_1_3 = data.rr_07_03_timestamp;
            // this.s_col_1_3 = true;
          }
          if (data.rr_07_04 != null) {
            this.EditValidate(data.rr_07_04, "col_1_4");
            this.col_1._4 = data.rr_07_04;
            this.datetime_col_1_4 = data.rr_07_04_timestamp;
            // this.s_col_1_4 = true;
          }
          if (data.rr_07_05 != null) {
            this.EditValidate(data.rr_07_05, "col_1_5");
            this.col_1._5 = data.rr_07_05;
            this.datetime_col_1_5 = data.rr_07_05_timestamp;
            // this.s_col_1_5 = true;
          }
          if (data.rr_07_06 != null) {
            this.EditValidate(data.rr_07_06, "col_1_6");
            this.col_1._6 = data.rr_07_06;
            this.datetime_col_1_6 = data.rr_07_06_timestamp;
            // this.s_col_1_6 = true;
          }
          if (data.rr_07_07 != null) {
            this.EditValidate(data.rr_07_07, "col_1_7");
            this.col_1._7 = data.rr_07_07;
            this.datetime_col_1_7 = data.rr_07_07_timestamp;
            // this.s_col_1_7 = true;
          }
          if (data.rr_07_08 != null) {
            this.EditValidate(data.rr_07_08, "col_1_8");
            this.col_1._8 = data.rr_07_08;
            this.datetime_col_1_8 = data.rr_07_08_timestamp;
            // this.s_col_1_8 = true;
          }
          if (data.rr_07_09 != null) {
            this.EditValidate(data.rr_07_09, "col_1_9");
            this.col_1._9 = data.rr_07_09;
            this.datetime_col_1_9 = data.rr_07_09_timestamp;
            // this.s_col_1_9 = true;
          }
          if (data.rr_07_10 != null) {
            this.col_1._10 = data.rr_07_10;
            this.EditValidate(data.rr_07_10, "col_1_10");
            this.datetime_col_1_10 = data.rr_07_10_timestamp;
            // this.s_col_1_10 = true;
          }

          if (data.rr_07_11 != null) {
            this.EditValidate(data.rr_07_11, "col_2_11");
            this.col_2._11 = data.rr_07_11;
            this.datetime_col_2_11 = data.rr_07_11_timestamp;
            // this.s_col_2_11 = true;
          }
          if (data.rr_07_12 != null) {
            this.EditValidate(data.rr_07_12, "col_2_12");
            this.col_2._12 = data.rr_07_12;
            this.datetime_col_2_12 = data.rr_07_12_timestamp;
            // this.s_col_2_12 = true;
          }
          if (data.rr_07_13 != null) {
            this.col_2._13 = data.rr_07_13;
            this.EditValidate(data.rr_07_13, "col_2_13");
            this.datetime_col_2_13 = data.rr_07_13_timestamp;
            // this.s_col_2_13 = true;
          }
          if (data.rr_07_14 != null) {
            this.col_2._14 = data.rr_07_14;
            this.EditValidate(data.rr_07_14, "col_2_14");
            this.datetime_col_2_14 = data.rr_07_14_timestamp;
            // this.s_col_2_14 = true;
          }
          if (data.rr_07_15 != null) {
            this.col_2._15 = data.rr_07_15;
            this.EditValidate(data.rr_07_15, "col_2_15");
            this.datetime_col_2_15 = data.rr_07_15_timestamp;
            // this.s_col_2_15 = true;
          }
          if (data.rr_07_16 != null) {
            this.col_2._16 = data.rr_07_16;
            this.EditValidate(data.rr_07_16, "col_2_16");
            this.datetime_col_2_16 = data.rr_07_16_timestamp;
            // this.s_col_2_16 = true;
          }
          if (data.rr_07_17 != null) {
            this.col_2._17 = data.rr_07_17;
            this.EditValidate(data.rr_07_17, "col_2_17");
            this.datetime_col_2_17 = data.rr_07_17_timestamp;
            // this.s_col_2_17 = true;
          }
          if (data.rr_07_18 != null) {
            this.col_2._18 = data.rr_07_18;
            this.EditValidate(data.rr_07_18, "col_2_18");
            this.datetime_col_2_18 = data.rr_07_18_timestamp;
            // this.s_col_2_18 = true;
          }
          if (data.rr_07_19 != null) {
            this.col_2._19 = data.rr_07_19;
            this.EditValidate(data.rr_07_19, "col_2_19");
            this.datetime_col_2_19 = data.rr_07_19_timestamp;
            // this.s_col_2_19 = true;
          }
          if (data.rr_07_20 != null) {
            this.col_2._20 = data.rr_07_20;
            this.EditValidate(data.rr_07_20, "col_2_20");
            this.datetime_col_2_20 = data.rr_07_20_timestamp;
            // this.s_col_2_20 = true;
          }

          if (data.rr_07_21 != null) {
            this.col_3._21 = data.rr_07_21;
            this.EditValidate(data.rr_07_21, "col_3_21");
            this.datetime_col_3_21 = data.rr_07_21_timestamp;
            // this.s_col_3_21 = true;
          }
          if (data.rr_07_22 != null) {
            this.col_3._22 = data.rr_07_22;
            this.EditValidate(data.rr_07_22, "col_3_22");
            this.datetime_col_3_22 = data.rr_07_22_timestamp;
            // this.s_col_3_22 = true;
          }
          if (data.rr_07_23 != null) {
            this.col_3._23 = data.rr_07_23;
            this.EditValidate(data.rr_07_23, "col_3_23");
            this.datetime_col_3_23 = data.rr_07_23_timestamp;
            // this.s_col_3_23 = true;
          }
          if (data.rr_07_24 != null) {
            this.col_3._24 = data.rr_07_24;
            this.EditValidate(data.rr_07_24, "col_3_24");
            this.datetime_col_3_24 = data.rr_07_24_timestamp;
            // this.s_col_3_24 = true;
          }
          if (data.rr_07_25 != null) {
            this.col_3._25 = data.rr_07_25;
            this.EditValidate(data.rr_07_25, "col_3_25");
            this.datetime_col_3_25 = data.rr_07_25_timestamp;
            // this.s_col_3_25 = true;
          }
          if (data.rr_07_26 != null) {
            this.col_3._26 = data.rr_07_26;
            this.EditValidate(data.rr_07_26, "col_3_26");
            this.datetime_col_3_26 = data.rr_07_26_timestamp;
            // this.s_col_3_26 = true;
          }
          if (data.rr_07_27 != null) {
            this.col_3._27 = data.rr_07_27;
            this.EditValidate(data.rr_07_27, "col_3_27");
            this.datetime_col_3_27 = data.rr_07_27_timestamp;
            // this.s_col_3_27 = true;
          }
          if (data.rr_07_28 != null) {
            this.col_3._28 = data.rr_07_28;
            this.EditValidate(data.rr_07_28, "col_3_28");
            this.datetime_col_3_28 = data.rr_07_28_timestamp;
            // this.s_col_3_28 = true;
          }
          if (data.rr_07_29 != null) {
            this.col_3._29 = data.rr_07_29;
            this.EditValidate(data.rr_07_29, "col_3_29");
            this.datetime_col_3_9 = data.rr_07_29_timestamp;
            // this.s_col_3_29 = true;
          }
          if (data.rr_07_30 != null) {
            this.col_3._30 = data.rr_07_30;
            this.EditValidate(data.rr_07_30, "col_3_30");
            this.datetime_col_3_30 = data.rr_07_30_timestamp;
            // this.s_col_3_30 = true;
          }
          if (data.rr_07_31 != null) {
            this.col_3._31 = data.rr_07_31;
            this.EditValidate(data.rr_07_31, "col_3_31");
            this.datetime_col_3_31 = data.rr_07_31_timestamp;
            // this.s_col_3_31 = true;
          }
          this.showLoading = false;
        } catch (e) {
          // console.log("apa : ",e)
          this.resetForm();
          this.status_post = "INSERT";
          this.enableForm();
          this.disableByMonth();
          this.showLoading = false;
          // this.disableForm()
        }
      }
      this.getRequiredByDate();
    },
    disableByMonth() {
      var lastDayOfMonth = new Date(this.t_tahun, parseInt(this.t_bulan), 0).getDate();
      if (lastDayOfMonth == "28") {
        this.EDForm.s_col_3_29 = true;
        this.EDForm.s_col_3_30 = true;
        this.EDForm.s_col_3_31 = true;
      } else if (lastDayOfMonth == "29") {
        this.EDForm.s_col_3_30 = true;
        this.EDForm.s_col_3_31 = true;
      } else if (lastDayOfMonth == "30") {
        this.EDForm.s_col_3_31 = true;
      }
      // const lastdate = new Date(y, m , 0).getDate();
      // console.log("last Day : ", lastDayOfMonth);
    },
    disableEntryByMonth() {
      var lastDayOfMonth = new Date(this.t_tahun, parseInt(this.t_bulan), 0).getDate();
      if (lastDayOfMonth == "28") {
        this.EDForm.s_col_3_29 = true;
        this.EDForm.s_col_3_30 = true;
        this.EDForm.s_col_3_31 = true;
      } else if (lastDayOfMonth == "29") {
        this.EDForm.s_col_3_30 = true;
        this.EDForm.s_col_3_31 = true;
      } else if (lastDayOfMonth == "30") {
        this.EDForm.s_col_3_31 = true;
      }
      // const lastdate = new Date(y, m , 0).getDate();
      // console.log("last Day : ", lastDayOfMonth);
    },
    disableByDay() {
      var data = this.DS_data;
      var today = new Date();
      var dd = parseInt(today.getDate());
      // console.log("disable by day is called : ",dd)
      for (var i in data) {
        let tgl = parseInt(i);
        this.EDForm[data[i]] = false;
        // console.log("aa : ",data[i],tgl)
        if (tgl > dd) {
          this.EDForm[data[i]] = true;
        }
      }
    },
    enableForm() {
      this.EDForm["s_col_1_1"] = false;
      this.EDForm["s_col_1_2"] = false;
      this.EDForm["s_col_1_3"] = false;
      this.EDForm["s_col_1_4"] = false;
      this.EDForm["s_col_1_5"] = false;
      this.EDForm["s_col_1_6"] = false;
      this.EDForm["s_col_1_7"] = false;
      this.EDForm["s_col_1_8"] = false;
      this.EDForm["s_col_1_9"] = false;
      this.EDForm["s_col_1_10"] = false;
      this.EDForm["s_col_2_11"] = false;
      this.EDForm["s_col_2_12"] = false;
      this.EDForm["s_col_2_13"] = false;
      this.EDForm["s_col_2_14"] = false;
      this.EDForm["s_col_2_15"] = false;
      this.EDForm["s_col_2_16"] = false;
      this.EDForm["s_col_2_17"] = false;
      this.EDForm["s_col_2_18"] = false;
      this.EDForm["s_col_2_19"] = false;
      this.EDForm["s_col_2_20"] = false;
      this.EDForm["s_col_3_21"] = false;
      this.EDForm["s_col_3_22"] = false;
      this.EDForm["s_col_3_23"] = false;
      this.EDForm["s_col_3_24"] = false;
      this.EDForm["s_col_3_25"] = false;
      this.EDForm["s_col_3_26"] = false;
      this.EDForm["s_col_3_27"] = false;
      this.EDForm["s_col_3_28"] = false;
      this.EDForm["s_col_3_29"] = false;
      this.EDForm["s_col_3_30"] = false;
      this.EDForm["s_col_3_31"] = false;
    },
    disableForm() {
      this.resetForm();
      this.EDForm["s_col_1_1"] = true;
      this.EDForm["s_col_1_2"] = true;
      this.EDForm["s_col_1_3"] = true;
      this.EDForm["s_col_1_4"] = true;
      this.EDForm["s_col_1_5"] = true;
      this.EDForm["s_col_1_6"] = true;
      this.EDForm["s_col_1_7"] = true;
      this.EDForm["s_col_1_8"] = true;
      this.EDForm["s_col_1_9"] = true;
      this.EDForm["s_col_1_10"] = true;
      this.EDForm["s_col_2_11"] = true;
      this.EDForm["s_col_2_12"] = true;
      this.EDForm["s_col_2_13"] = true;
      this.EDForm["s_col_2_14"] = true;
      this.EDForm["s_col_2_15"] = true;
      this.EDForm["s_col_2_16"] = true;
      this.EDForm["s_col_2_17"] = true;
      this.EDForm["s_col_2_18"] = true;
      this.EDForm["s_col_2_19"] = true;
      this.EDForm["s_col_2_20"] = true;
      this.EDForm["s_col_3_21"] = true;
      this.EDForm["s_col_3_22"] = true;
      this.EDForm["s_col_3_23"] = true;
      this.EDForm["s_col_3_24"] = true;
      this.EDForm["s_col_3_25"] = true;
      this.EDForm["s_col_3_26"] = true;
      this.EDForm["s_col_3_27"] = true;
      this.EDForm["s_col_3_28"] = true;
      this.EDForm["s_col_3_29"] = true;
      this.EDForm["s_col_3_30"] = true;
      this.EDForm["s_col_3_31"] = true;
    },
    submitHujan() {
      this.showLoading = true;
      // let dataRC = Object.entries(this.RC).filter((e) => e[1].status);
      // const arr_id = this.poshujan_id.split("-")
      // console.log("asd : 1")
      if (this.t_tahun == "" || this.t_bulan == "" || this.poshujan_id == "") {
        return;
      }
      const formhujan_id = this.s_id + "_formhujan_" + this.t_tahun + "-" + this.t_bulan + "_" + this.poshujan_id;
      const data_timestamp = this.t_date;
      const received_timestamp = this.t_tahun + "-" + this.t_bulan + "-01";
      // console.log("asd : 2")
      var get_qc_value = {
        rr_07_01: this.col_1._1,
        rr_07_02: this.col_1._2,
        rr_07_03: this.col_1._3,
        rr_07_04: this.col_1._4,
        rr_07_05: this.col_1._5,
        rr_07_06: this.col_1._6,
        rr_07_07: this.col_1._7,
        rr_07_08: this.col_1._8,
        rr_07_09: this.col_1._9,
        rr_07_10: this.col_1._10,

        rr_07_11: this.col_2._11,
        rr_07_12: this.col_2._12,
        rr_07_13: this.col_2._13,
        rr_07_14: this.col_2._14,
        rr_07_15: this.col_2._15,
        rr_07_16: this.col_2._16,
        rr_07_17: this.col_2._17,
        rr_07_18: this.col_2._18,
        rr_07_19: this.col_2._19,
        rr_07_20: this.col_2._20,

        rr_07_21: this.col_3._21,
        rr_07_22: this.col_3._22,
        rr_07_23: this.col_3._23,
        rr_07_24: this.col_3._24,
        rr_07_25: this.col_3._25,
        rr_07_26: this.col_3._26,
        rr_07_27: this.col_3._27,
        rr_07_28: this.col_3._28,
        rr_07_29: this.col_3._29,
        rr_07_30: this.col_3._30,
        rr_07_31: this.col_3._31,
      };
      // console.log("asd : 3")
      let qc_flag = 0;
      let qc_histories = {
        before: this.last_qc_histories,
        after: null,
      };
      // console.log("asd : 4")
      // console.log("asd : 4 : ",this.RC)
      let qc_found = Object.entries(this.RC)
        .filter((e) => e[1].status)
        .map((e) => e[0])
        .map((e) => {
          return {
            raw_type: "FormHujan",
            atribut: e,
            value: this.RC[e].value,
            roles: this.RC_MIN+","+this.RC_MAX+","+this.RC_SPECIAL,
            type: "range_check",
            message: `batas bawah ${this.RC_MIN}, batas atas ${this.RC_MAX}, special value ${this.RC_SPECIAL}`,
          };
        });
        // console.log("asd : 5")
      if (Object.values(qc_found).length) {
        qc_flag = 1;
        qc_histories.after = qc_found;
      } else {
        if (this.qc_flag_before == 1) {
          qc_flag = 2;
        }
      }
      console.log("asd : 6")
      var id_notif = formhujan_id;
      const post_data = {
        "@type": "FormHujan",
        pos_hujan_id: this.poshujan_id,
        id: formhujan_id,
        received_timestamp: received_timestamp,
        rr_07_01: this.col_1._1 === "" ? null : this.col_1._1,
        rr_07_01_timestamp: this.datetime_col_1_1 === "" ? data_timestamp : this.datetime_col_1_1,
        rr_07_02: this.col_1._2 === "" ? null : this.col_1._2,
        rr_07_02_timestamp: this.datetime_col_1_2 == "" ? (this.col_1._2 == "" ? null : data_timestamp) : this.datetime_col_1_2,
        rr_07_03: this.col_1._3 === "" ? null : this.col_1._3,
        rr_07_03_timestamp: this.datetime_col_1_3 == "" ? (this.col_1._3 == "" ? null : data_timestamp) : this.datetime_col_1_3,
        rr_07_04: this.col_1._4 === "" ? null : this.col_1._4,
        rr_07_04_timestamp: this.datetime_col_1_4 == "" ? (this.col_1._4 == "" ? null : data_timestamp) : this.datetime_col_1_4,
        rr_07_05: this.col_1._5 === "" ? null : this.col_1._5,
        rr_07_05_timestamp: this.datetime_col_1_5 == "" ? (this.col_1._5 == "" ? null : data_timestamp) : this.datetime_col_1_5,
        rr_07_06: this.col_1._6 === "" ? null : this.col_1._6,
        rr_07_06_timestamp: this.datetime_col_1_6 == "" ? (this.col_1._6 == "" ? null : data_timestamp) : this.datetime_col_1_6,
        rr_07_07: this.col_1._7 === "" ? null : this.col_1._7,
        rr_07_07_timestamp: this.datetime_col_1_7 == "" ? (this.col_1._7 == "" ? null : data_timestamp) : this.datetime_col_1_7,
        rr_07_08: this.col_1._8 === "" ? null : this.col_1._8,
        rr_07_08_timestamp: this.datetime_col_1_8 == "" ? (this.col_1._8 == "" ? null : data_timestamp) : this.datetime_col_1_8,
        rr_07_09: this.col_1._9 === "" ? null : this.col_1._9,
        rr_07_09_timestamp: this.datetime_col_1_9 == "" ? (this.col_1._9 == "" ? null : data_timestamp) : this.datetime_col_1_9,
        rr_07_10: this.col_1._10 === "" ? null : this.col_1._10,
        rr_07_10_timestamp: this.datetime_col_1_10 == "" ? (this.col_1._10 == "" ? null : data_timestamp) : this.datetime_col_1_10,
        rr_07_11: this.col_2._11 === "" ? null : this.col_2._11,
        rr_07_11_timestamp: this.datetime_col_2_11 == "" ? (this.col_2._11 == "" ? null : data_timestamp) : this.datetime_col_2_11,
        rr_07_12: this.col_2._12 === "" ? null : this.col_2._12,
        rr_07_12_timestamp: this.datetime_col_2_12 == "" ? (this.col_2._12 == "" ? null : data_timestamp) : this.datetime_col_2_12,
        rr_07_13: this.col_2._13 === "" ? null : this.col_2._13,
        rr_07_13_timestamp: this.datetime_col_2_13 == "" ? (this.col_2._13 == "" ? null : data_timestamp) : this.datetime_col_2_13,
        rr_07_14: this.col_2._14 === "" ? null : this.col_2._14,
        rr_07_14_timestamp: this.datetime_col_2_14 == "" ? (this.col_2._14 == "" ? null : data_timestamp) : this.datetime_col_2_14,
        rr_07_15: this.col_2._15 === "" ? null : this.col_2._15,
        rr_07_15_timestamp: this.datetime_col_2_15 == "" ? (this.col_2._15 == "" ? null : data_timestamp) : this.datetime_col_2_15,
        rr_07_16: this.col_2._16 === "" ? null : this.col_2._16,
        rr_07_16_timestamp: this.datetime_col_2_16 == "" ? (this.col_2._16 == "" ? null : data_timestamp) : this.datetime_col_2_16,
        rr_07_17: this.col_2._17 === "" ? null : this.col_2._17,
        rr_07_17_timestamp: this.datetime_col_2_17 == "" ? (this.col_2._17 == "" ? null : data_timestamp) : this.datetime_col_2_17,
        rr_07_18: this.col_2._18 === "" ? null : this.col_2._18,
        rr_07_18_timestamp: this.datetime_col_2_18 == "" ? (this.col_2._18 == "" ? null : data_timestamp) : this.datetime_col_2_18,
        rr_07_19: this.col_2._19 === "" ? null : this.col_2._19,
        rr_07_19_timestamp: this.datetime_col_2_19 == "" ? (this.col_2._19 == "" ? null : data_timestamp) : this.datetime_col_2_19,
        rr_07_20: this.col_2._20 === "" ? null : this.col_2._20,
        rr_07_20_timestamp: this.datetime_col_2_20 == "" ? (this.col_2._20 == "" ? null : data_timestamp) : this.datetime_col_2_20,
        rr_07_21: this.col_3._21 === "" ? null : this.col_3._21,
        rr_07_21_timestamp: this.datetime_col_3_21 == "" ? (this.col_3._21 == "" ? null : data_timestamp) : this.datetime_col_3_21,
        rr_07_22: this.col_3._22 === "" ? null : this.col_3._22,
        rr_07_22_timestamp: this.datetime_col_3_22 == "" ? (this.col_3._22 == "" ? null : data_timestamp) : this.datetime_col_3_22,
        rr_07_23: this.col_3._23 === "" ? null : this.col_3._23,
        rr_07_23_timestamp: this.datetime_col_3_23 == "" ? (this.col_3._23 == "" ? null : data_timestamp) : this.datetime_col_3_23,
        rr_07_24: this.col_3._24 === "" ? null : this.col_3._24,
        rr_07_24_timestamp: this.datetime_col_3_24 == "" ? (this.col_3._24 == "" ? null : data_timestamp) : this.datetime_col_3_24,
        rr_07_25: this.col_3._25 === "" ? null : this.col_3._25,
        rr_07_25_timestamp: this.datetime_col_3_25 == "" ? (this.col_3._25 == "" ? null : data_timestamp) : this.datetime_col_3_25,
        rr_07_26: this.col_3._26 === "" ? null : this.col_3._26,
        rr_07_26_timestamp: this.datetime_col_3_26 == "" ? (this.col_3._26 == "" ? null : data_timestamp) : this.datetime_col_3_26,
        rr_07_27: this.col_3._27 === "" ? null : this.col_3._27,
        rr_07_27_timestamp: this.datetime_col_3_27 == "" ? (this.col_3._27 == "" ? null : data_timestamp) : this.datetime_col_3_27,
        rr_07_28: this.col_3._28 === "" ? null : this.col_3._28,
        rr_07_28_timestamp: this.datetime_col_3_28 == "" ? (this.col_3._28 == "" ? null : data_timestamp) : this.datetime_col_3_28,
        rr_07_29: this.col_3._29 === "" ? null : this.col_3._29,
        rr_07_29_timestamp: this.datetime_col_3_29 == "" ? (this.col_3._29 == "" ? null : data_timestamp) : this.datetime_col_3_29,
        rr_07_30: this.col_3._30 === "" ? null : this.col_3._30,
        rr_07_30_timestamp: this.datetime_col_3_30 == "" ? (this.col_3._30 == "" ? null : data_timestamp) : this.datetime_col_3_30,
        rr_07_31: this.col_3._31 === "" ? null : this.col_3._31,
        rr_07_31_timestamp: this.datetime_col_3_31 == "" ? (this.col_3._31 == "" ? null : data_timestamp) : this.datetime_col_3_31,
        rr_total_07_10: this.total_1 === "" ? null : this.total_1,
        rr_total_1_20: this.total_2 === "" ? null : this.total_2,
        rr_total_21_31: this.total_3 === "" ? null : this.total_3,
        data_timestamp: data_timestamp,
        observer_id: this.t_observer,
        qc_flag: qc_flag,
        qc_histories: JSON.stringify(qc_histories),
        //         RR DAS 1 (1-10) mm
        // RR DAS 2 (11-20) mm
        // RR DAS 3 (21-31) mm

        // "lon": "Text",
        // "lat": "Text",
        // "dpl": "Text",
      };

      // console.log(post_data);
      const station_id = "1";
      const post_url = Helper.getBaseUrl() + this.path_hujan + "/klimatologi/pias/formhujan";
      if (this.status_post == "INSERT") {
        axios
          .post(post_url, post_data, Helper.getConfig())
          .then((response) => {
            if (response.status == 201 || response.status == 200 || response.status == 204) {
              var dateQCNotif = this.t_tahun + "/" + this.t_bulan;
              if (qc_flag == 1) {
                this.createQCNotif(qc_flag, id_notif, dateQCNotif, qc_histories, formhujan_id);
              }

              this.$swal({
                title: "Entri data berhasil!",
                text: "Input Data Form Hujan",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              // this.poshujan_id = "";
              this.poshujan = "";
              this.resetForm();
              this.disableForm();
            }
            this.showLoading = false;
          })
          .catch((error) => {
            this.showLoading = false;
            console.log(error);
            error_handler(this, error, "Form Hujan", "Input Data Error");
          });
      } else {
        axios
          .patch(post_url + "/" + formhujan_id, post_data, Helper.getConfig())
          .then((response) => {
            if (response.status == 201 || response.status == 200 || response.status == 204) {
              var dateQCNotif = this.t_tahun + "/" + this.t_bulan;
              this.createQCNotif(qc_flag, id_notif, dateQCNotif, qc_histories, formhujan_id);
              this.$swal({
                title: "Update data berhasil!",
                text: "Update Data Form Hujan",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              // this.poshujan_id = "";
              this.poshujan = "";
              this.resetForm();
              this.disableForm();
              // this.status_post = "INSERT"
              this.showLoading = false;
            }
          })
          .catch((error) => {
            this.showLoading = false;
            console.log(error);
            error_handler(this, error, "Form Hujan", "Edit Data Error");
          });
        // var dateQCNotif = this.t_tahun + "/" + this.t_bulan
        // this.createQCNotif(qc_flag,this.formhujan_id,dateQCNotif,qc_histories)
        // if (qc_flag == 1) {
        //   qc_helper.editQCNotification(
        //     this.formhujan_id, // id entry
        //     "FormHujan", // type form entry
        //     this.t_tahun + "/" + this.t_bulan, // jam entry
        //     "",
        //     JSON.stringify(qc_histories), // qc histories
        //     qc_flag,
        //     this.t_station_id,
        //     this.t_station.s_name,
        //     this.t_observer,
        //     this.t_observer_name // observer name
        //   );
        // }
      }
    },
    createQCNotif(qc_flag, id, date, qc_histories, formhujan_id) {
      // console.log("QC Notif terpanggil");
      // console.log("QC Notif : ",id)
      // console.log("QC Notif : ",date)
      // console.log("QC Notif : ",qc_histories)
      // console.log("QC Notif : ",qc_flag)
      // console.log("QC Notif : ",this.t_station.text)
      // console.log("QC Notif : ",this.t_station_id)
      // console.log("QC Notif : ",this.t_observer)
      // console.log("QC Notif : ",this.t_observer_name)
      // console.log("QC Notif : ",this.path_hujan)
      // console.log("QC Notif : ",this.reg_id)

      // console.log("terpanggil ")
      qc_helper.editQCNotification(
        id, // id entry
        "FormHujan", // type form entry
        date, // jam entry
        "",
        JSON.stringify(qc_histories), // qc histories
        qc_flag,
        this.t_station_id,
        this.t_station.text,
        this.t_observer,
        this.t_observer_name, // observer name
        this.reg_id,
        this.path_hujan + "/" + formhujan_id
      );
    },
    resetForm() {
      this.last_qc_histories = null;
      this.col_1._1 = "";
      this.col_1._2 = "";
      this.col_1._3 = "";
      this.col_1._4 = "";
      this.col_1._5 = "";
      this.col_1._6 = "";
      this.col_1._7 = "";
      this.col_1._8 = "";
      this.col_1._9 = "";
      this.col_1._10 = "";
      this.col_2._11 = "";
      this.col_2._12 = "";
      this.col_2._13 = "";
      this.col_2._14 = "";
      this.col_2._15 = "";
      this.col_2._16 = "";
      this.col_2._17 = "";
      this.col_2._18 = "";
      this.col_2._19 = "";
      this.col_2._20 = "";
      this.col_3._21 = "";
      this.col_3._22 = "";
      this.col_3._23 = "";
      this.col_3._24 = "";
      this.col_3._25 = "";
      this.col_3._26 = "";
      this.col_3._27 = "";
      this.col_3._28 = "";
      this.col_3._29 = "";
      this.col_3._30 = "";
      this.col_3._31 = "";
      this.rc_col_1_1 = false;
      this.rc_col_1_1_message = "";
      this.rc_col_1_2 = false;
      this.rc_col_1_2_message = "";
      this.rc_col_1_3 = false;
      this.rc_col_1_3_message = "";
      this.rc_col_1_4 = false;
      this.rc_col_1_4_message = "";
      this.rc_col_1_5 = false;
      this.rc_col_1_5_message = "";
      this.rc_col_1_6 = false;
      this.rc_col_1_6_message = "";
      this.rc_col_1_7 = false;
      this.rc_col_1_7_message = "";
      this.rc_col_1_8 = false;
      this.rc_col_1_8_message = "";
      this.rc_col_1_9 = false;
      this.rc_col_1_9_message = "";
      this.rc_col_1_10 = false;
      this.rc_col_1_10_message = "";
      this.rc_col_2_11 = false;
      this.rc_col_2_11_message = "";
      this.rc_col_2_12 = false;
      this.rc_col_2_12_message = "";
      this.rc_col_2_13 = false;
      this.rc_col_2_13_message = "";
      this.rc_col_2_14 = false;
      this.rc_col_2_14_message = "";
      this.rc_col_2_15 = false;
      this.rc_col_2_15_message = "";
      this.rc_col_2_16 = false;
      this.rc_col_2_16_message = "";
      this.rc_col_2_17 = false;
      this.rc_col_2_17_message = "";
      this.rc_col_2_18 = false;
      this.rc_col_2_18_message = "";
      this.rc_col_2_19 = false;
      this.rc_col_2_19_message = "";
      this.rc_col_2_20 = false;
      this.rc_col_2_20_message = "";
      this.rc_col_3_21 = false;
      this.rc_col_3_21_message = "";
      this.rc_col_3_22 = false;
      this.rc_col_3_22_message = "";
      this.rc_col_3_23 = false;
      this.rc_col_3_23_message = "";
      this.rc_col_3_24 = false;
      this.rc_col_3_24_message = "";
      this.rc_col_3_25 = false;
      this.rc_col_3_25_message = "";
      this.rc_col_3_26 = false;
      this.rc_col_3_26_message = "";
      this.rc_col_3_27 = false;
      this.rc_col_3_27_message = "";
      this.rc_col_3_28 = false;
      this.rc_col_3_28_message = "";
      this.rc_col_3_29 = false;
      this.rc_col_3_29_message = "";
      this.rc_col_3_30 = false;
      this.rc_col_3_30_message = "";
      this.rc_col_3_31 = false;
      this.rc_col_3_31_message = "";
      this.$v.$reset();
    },
    onInputChange(text) {
      if (text === "" || text === undefined) {
        return;
      }

      // alert(text)
      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      // const filteredDevelopers = this.datasuggest.data[0].developers.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      // const filteredDesigner = this.datasuggest.data[0].designers.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      // const filteredData = filteredDevelopers.concat(filteredDesigner)
      // this.filteredOptions = [{
      //   data: datasuggest,
      // }]
      // alert("1")
    },
    async listAllPropinsi() {
      // console.log("regid : ", region_id);
      // var arr = this.t_path.split("/");
      // var region_id = arr[1];
      this.propinsi_opt = [{ value: "", text: "Semua Propinsi" }];
      try {
        const { data } = await metadata.getPropinsiList();
        // console.log(data);
        for (var i = 0; i < data.length; i++) {
          const rowsPropinsi = {
            id: data[i].propinsi_id,
            value: data[i].propinsi_id,
            text: data[i].propinsi_name,
          };
          this.propinsi_opt.push(rowsPropinsi);

          //  console.log(rowsPropinsi);
          // this.items.push(dataPropinsi);
        }
        this.t_provinsi = { value: "", text: "Semua Propinsi" };
        // this.t_kabupaten = { value: "", text: "Semua Kota/Kabupaten" };
      } catch (e) {
        console.log(e);
      }
    },
    async listPropinsi() {
      // console.log("regid : ", region_id);
      var arr = this.t_path.split("/");
      var region_id = arr[1];
      this.propinsi_opt = [{ value: "", text: "Pilih Propinsi" }];
      try {
        const { data } = await metadata.getPropinsiListByRegion(region_id);
        // console.log(data);
        for (var i = 0; i < data.length; i++) {
          const rowsPropinsi = {
            value: data[i].propinsi_id,
            text: data[i].propinsi_name,
          };
          this.propinsi_opt.push(rowsPropinsi);

          //  console.log(rowsPropinsi);
          // this.items.push(dataPropinsi);
        }
        var arr = this.t_path.split("/");
        var region_id = arr[1];
        var propinsi_id = arr[2];
        this.t_provinsi = propinsi_id;
        this.listKabupaten(propinsi_id);
        this.listPoshujanByProvinsi(propinsi_id);
      } catch (e) {
        console.log(e);
      }
    },
    async listAllKabupaten() {
      this.kabupaten_opt = [{ value: "", text: "Semua Kota/Kabupaten" }];
      try {
        const { data } = await metadata.getKabupatenList();
        // console.log(data);
        for (var i = 0; i < data.length; i++) {
          const rowsKabupaten = {
            id: data[i].kabupaten_id,
            value: data[i].kabupaten_id,
            text: data[i].kabupaten_name,
          };
          this.kabupaten_opt.push(rowsKabupaten);
        }
        this.t_kabupaten = { value: "", text: "Semua Kota/Kabupaten" };
      } catch (e) {
        console.log(e);
      }
    },
    async listKabupaten(propinsi_id) {
      this.kabupaten_opt = [{ value: "", text: "Semua Kota/Kabupaten" }];
      try {
        const { data } = await metadata.getKabupatenListByPropinsi(propinsi_id);
        // console.log(data);
        for (var i = 0; i < data.length; i++) {
          const rowsKabupaten = {
            id: data[i].kabupaten_id,
            value: data[i].kabupaten_id,
            text: data[i].kabupaten_name,
          };
          this.kabupaten_opt.push(rowsKabupaten);
        }
        this.t_kabupaten = { value: "", text: "Semua Kota/Kabupaten" };
      } catch (e) {
        console.log(e);
      }
    },
    async listPoshujan(kab_id) {
      this.showLoading = true;
      this.poshujan_opt = [{ value: "", text: "Pilih Poshujan" }];
      try {
        const { data } = await metadata.getPoshujanByKabupaten(this.t_station_id, kab_id);
        // console.log(data);
        for (var i = 0; i < data.length; i++) {
          const rowsPoshujan = {
            value: data[i].pos_hujan_id,
            text: data[i].pos_hujan_id + " - " + data[i].station_name,
            s_id: data[i].station_id,
            K_id: data[i].kabupaten_id,
            path: data[i].path,
            s_name: data[i].station_name,
            reg_id: data[i].region_id,
          };
          this.poshujan_opt.push(rowsPoshujan);
          this.poshujan = "";
          this.showLoading = false;
        }
      } catch (e) {
        console.log(e);
        this.showLoading = false;
      }
    },
    async listPoshujanByProvinsi(prov_id) {
      this.poshujan_opt = [{ value: "", text: "Pilih Poshujan" }];
      try {
        const { data } = await metadata.getPoshujanByProvinsi(this.t_station_id, prov_id);
        // console.log(data);
        for (var i = 0; i < data.length; i++) {
          const rowsPoshujan = {
            value: data[i].pos_hujan_id,
            text: data[i].pos_hujan_id + " - " + data[i].station_name,
            s_id: data[i].station_id,
            path: data[i].path,
            K_id: data[i].kabupaten_id,
            s_name: data[i].station_name,
            reg_id: data[i].region_id,
          };
          this.poshujan_opt.push(rowsPoshujan);
          this.poshujan = "";
        }
      } catch (e) {
        console.log(e);
      }
    },
    async listPoshujanByCoordinator() {
      this.showLoading = true;
      this.poshujan_opt = [{ value: "", text: "Pilih Poshujan" }];
      try {
        const { data } = await metadata.getPoshujanByCoordinator(this.t_station_id);
        // console.log(data);
        if(data.length == 0){
          this.$swal({
            title: "ERROR",
            text: "Tidak Ada Poshujan",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
        }
        for (var i = 0; i < data.length; i++) {
          const rowsPoshujan = {
            value: data[i].pos_hujan_id,
            text: data[i].pos_hujan_id + " - " + data[i].station_name,
            s_id: data[i].station_id,
            path: data[i].path,
            K_id: data[i].kabupaten_id,
            P_id: data[i].propinsi_id,
            K_name: data[i].kabupaten_name,
            P_name: data[i].propinsi_name,
            s_name: data[i].station_name,
            reg_id: data[i].region_id,
          };
          this.poshujan_opt.push(rowsPoshujan);
          this.poshujan = "";
         
        }
        this.showLoading = false;
      } catch (e) {
        console.log(e);
        this.showLoading = false;
      }
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
};
</script>

<style scoped>
.label-entry {
  text-align: center;
  color: white;
  width: 100%;
  /* font-weight: bold !important; */
  border: 2px solid #6e6b7b;
  border-radius: 3px;
  background: #858585;
  padding: 4px;
}
.label-entry1 {
  text-align: center;
  color: #3b4253;
  width: 100%;
  font-weight: 500;
  background-color: #f3f1f1;
  /* font-weight: bold !important; */
  border: 1px solid #b5b5c3;
  border-radius: 3px;
  padding: 4px;
  margin-bottom: 7px;
}
.mbtm {
  margin-bottom: 5px;
}
.pad3 {
  padding-right: 0px;
}
#input_col_1_1.rangecheck,
#input_col_1_2.rangecheck,
#input_col_1_3.rangecheck,
#input_col_1_4.rangecheck,
#input_col_1_5.rangecheck,
#input_col_1_6.rangecheck,
#input_col_1_7.rangecheck,
#input_col_1_8.rangecheck,
#input_col_1_9.rangecheck,
#input_col_1_10.rangecheck,
#input_col_2_11.rangecheck,
#input_col_2_12.rangecheck,
#input_col_2_13.rangecheck,
#input_col_2_14.rangecheck,
#input_col_2_15.rangecheck,
#input_col_2_16.rangecheck,
#input_col_2_17.rangecheck,
#input_col_2_18.rangecheck,
#input_col_2_19.rangecheck,
#input_col_2_20.rangecheck,
#input_col_3_21.rangecheck,
#input_col_3_22.rangecheck,
#input_col_3_23.rangecheck,
#input_col_3_24.rangecheck,
#input_col_3_25.rangecheck,
#input_col_3_26.rangecheck,
#input_col_3_27.rangecheck,
#input_col_3_28.rangecheck,
#input_col_3_29.rangecheck,
#input_col_3_30.rangecheck,
#input_col_3_31.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
}
.bg-light-primary {
  color: #000000 !important;
}
.bg-light-success {
  color: #000000 !important;
}
</style>
<style>
/* .card-title{
  font-size: 16px;
  font-weight: bold;
  color: red;
} */
</style>
