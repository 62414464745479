<template>
  <div>
    <!--===gol A=== -->
    <b-row class="mt-n2">
      <b-col cols="12">
        <b-card-actions action-collapse>
          <h6 class="text-dark font-weight-bolder mb-2 badge badge-light-dark">Golongan A (Tanaman Makanan Utama)</h6>
          <b-row class="mb-2">
            <b-col sm="3">
              <a-select show-search :not-found-content="null" style="width: 100%" :value="modelGolA.tanaman" @change="(value) => (modelGolA.tanaman = value)">
                <a-select-option value="" disabled>Nama Tanaman</a-select-option>
                <a-select-option v-for="(data, i) in dataGolA.tanaman" :key="i" :value="data.value">
                  <div v-html="data.text"></div>
                </a-select-option>
              </a-select>
            </b-col>

            <b-col sm="3">
              <b-form-select v-model="modelGolA.peramatan" class="mb-1" size="sm">
                <option value="" disabled>Peramatan Fenologi</option>
                <option v-for="(value, i) in dataGolA.peramatan" :key="i" :value="value">
                  {{ value }}
                </option>
              </b-form-select>
            </b-col>

            <b-col sm="2">
              <b-form-select v-model="modelGolA.fenomena" class="mb-1" size="sm">
                <option value="" disabled>Keadaan Fenomena</option>
                <option v-for="(value, i) in dataGolA.fenomena" :key="i" :value="value">
                  {{ value }}
                </option>
              </b-form-select>
            </b-col>

            <b-col sm="1">
              <label for="textarea-default">Keterangan</label>
            </b-col>
            <b-col sm="3">
              <b-form-textarea id="textarea-default" placeholder="Keterangan" v-model="modelGolA.keterangan"></b-form-textarea>
            </b-col>
          </b-row>
          <b-row class="float-right mb-2">
            <div>
              <b-button variant="danger" class="mr-1" size="sm" @click="clearGolA"> Cancel </b-button>
              <b-button variant="primary" class="mr-1" size="sm" @click="addGolA"> Save </b-button>
            </div>
          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>
    <!-- end gol A -->
    <!-- ===Gol B -->
    <b-row class="mt-n2">
      <b-col cols="12">
        <b-card-actions action-collapse>
          <h6 class="text-dark font-weight-bolder mb-2 badge badge-light-dark">Golongan B (Tanaman Buah-buahan Yang Menghasilkan Sepanjang Tahun)</h6>
          <b-row class="mb-2">
            <b-col sm="3">
              <a-select show-search :not-found-content="null" style="width: 100%" :value="modelGolB.tanaman" @change="(value) => (modelGolB.tanaman = value)">
                <a-select-option value="" disabled>Nama Tanaman</a-select-option>
                <a-select-option v-for="(data, i) in dataGolB.tanaman" :key="i" :value="data.value">
                  <div v-html="data.text"></div>
                </a-select-option>
              </a-select>
            </b-col>

            <b-col sm="3">
              <b-form-select v-model="modelGolB.peramatan" class="mb-1" size="sm">
                <option value="" disabled>Peramatan Fenologi</option>
                <option v-for="(value, i) in dataGolB.peramatan" :key="i" :value="value">
                  {{ value }}
                </option>
              </b-form-select>
            </b-col>

            <b-col sm="2">
              <b-form-select v-model="modelGolB.fenomena" class="mb-1" size="sm">
                <option value="" disabled>Keadaan Fenomena</option>
                <option v-for="(value, i) in dataGolB.fenomena" :key="i" :value="value">
                  {{ value }}
                </option>
              </b-form-select>
            </b-col>

            <b-col sm="1">
              <label for="textarea-default">Keterangan</label>
            </b-col>
            <b-col sm="3">
              <b-form-textarea id="textarea-default" placeholder="Keterangan" v-model="modelGolB.keterangan"></b-form-textarea>
            </b-col>
          </b-row>
          <b-row class="float-right mb-2">
            <div>
              <b-button variant="danger" class="mr-1" size="sm" @click="clearGolB"> Cancel </b-button>
              <b-button variant="primary" class="mr-1" size="sm" @click="addGolB"> Save </b-button>
            </div>
          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>
    <!-- ==end Gol B -->
    <!-- ==Gol C== -->
    <b-row class="mt-n2">
      <b-col cols="12">
        <b-card-actions action-collapse>
          <h6 class="badge badge-light-dark text-dark font-weight-bolder mb-2">Golongan C (Tanaman Buah-buahan Yang Menghasilkan Menurut Musim)</h6>
          <b-row class="mb-2">
            <b-col sm="3">
              <a-select show-search :not-found-content="null" style="width: 100%" :value="modelGolC.tanaman" @change="(value) => (modelGolC.tanaman = value)">
                <a-select-option value="" disabled>Nama Tanaman</a-select-option>
                <a-select-option v-for="(data, i) in dataGolC.tanaman" :key="i" :value="data.value">
                  <div v-html="data.text"></div>
                </a-select-option>
              </a-select>
            </b-col>

            <b-col sm="3">
              <b-form-select v-model="modelGolC.peramatan" class="mb-1" size="sm">
                <option value="" disabled>Peramatan Fenologi</option>
                <option v-for="(value, i) in dataGolC.peramatan" :key="i" :value="value">
                  {{ value }}
                </option>
              </b-form-select>
            </b-col>

            <b-col sm="2">
              <b-form-select v-model="modelGolC.fenomena" class="mb-1" size="sm">
                <option value="" disabled>Keadaan Fenomena</option>
                <option v-for="(value, i) in dataGolC.fenomena" :key="i" :value="value">
                  {{ value }}
                </option>
              </b-form-select>
            </b-col>

            <b-col sm="1">
              <label for="textarea-default">Keterangan</label>
            </b-col>
            <b-col sm="3">
              <b-form-textarea id="textarea-default" placeholder="Keterangan" v-model="modelGolC.keterangan"></b-form-textarea>
            </b-col>
          </b-row>
          <b-row class="float-right mb-2">
            <div>
              <b-button variant="danger" class="mr-1" size="sm" @click="clearGolC"> Cancel </b-button>
              <b-button variant="primary" class="mr-1" size="sm" @click="addGolC"> Save </b-button>
            </div>
          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>
    <!-- end Gol== -->
    <!-- ==Hasil Gol A -->
    <b-row class="mt-n2">
      <b-col cols="12">
        <b-card-actions action-collapse>
          <b-row class="mb-2">
            <b-col cols="3">
              <div class="label-entry bg-light-primary font-weight-bolder">Hasil Bumi Yang diamati</div>
            </b-col>
            <b-col cols="3" class="text-center">
              <div class="label-entry bg-light-primary font-weight-bolder">Peramatan Fenologi</div>
            </b-col>
            <b-col cols="2" class="text-center">
              <div class="label-entry bg-light-primary font-weight-bolder">Keadaan Fenomena</div>
            </b-col>
            <b-col cols="3">
              <div class="label-entry bg-light-primary font-weight-bolder">Keterangan</div>
            </b-col>
            <b-col cols="1"></b-col>
          </b-row>

          <!-- HASIL GOL A START -->
          <b-row class="my-1">
            <b-col cols="3">
              <div class="label-entry2 small">A. Tanaman Makanan Utama</div>
            </b-col>
          </b-row>

          <b-row class="my-1" v-for="(data, i) in hasilGolA" :key="data.tanaman + i">
            <b-col sm="3">
              <div class="label-entry1 small" v-if="!editing">
                {{ data.tanaman }}
              </div>
              <a-select v-else show-search :not-found-content="null" style="width: 100%" :value="hasilGolA[i].tanaman" @change="(value) => (modelGolA.tanaman = value)">
                <a-select-option value="" disabled>Nama Tanaman</a-select-option>
                <a-select-option v-for="(data, i) in dataGolA.tanaman" :key="i" :value="data.value">
                  <div v-html="data.text"></div>
                </a-select-option>
              </a-select>
            </b-col>
            <b-col sm="3">
              <div class="label-entry1 small" v-if="!editing">
                {{ data.peramatan }}
              </div>
              <b-form-select v-model="hasilGolA[i].peramatan" class="mb-1" size="sm" v-else>
                <option value="" disabled>Peramatan Fenologi</option>
                <option v-for="(value, i) in dataGolA.peramatan" :key="i" :value="value">
                  {{ value }}
                </option>
              </b-form-select>
            </b-col>
            <b-col sm="2">
              <div class="label-entry1 small" v-if="!editing">
                {{ data.fenomena }}
              </div>
              <b-form-select v-model="hasilGolA[i].fenomena" class="mb-1" size="sm" v-else>
                <option value="" disabled>Keadaan Fenomena</option>
                <option v-for="(value, i) in dataGolA.fenomena" :key="i" :value="value">
                  {{ value }}
                </option>
              </b-form-select>
            </b-col>
            <b-col sm="3">
              <div class="label-entry1 small" v-if="!editing">
                {{ data.keterangan }}
              </div>
              <b-form-textarea id="textarea-default" placeholder="Keterangan" v-model="hasilGolA[i].keterangan" v-else></b-form-textarea>
            </b-col>
            <b-col sm="1" class="d-flex align-items-center">
              <b-button variant="danger" size="sm" class="btn-icon rounded-circle" @click="() => deleteGolA(i)">
                <feather-icon icon="XIcon" />
              </b-button>
            </b-col>
          </b-row>
          <!-- HASIL GOL A END -->

          <!-- HASIL GOL B START -->
          <b-row class="my-1">
            <b-col cols="7">
              <div class="label-entry2 small">B. Tanaman Buah-buahan Yang menghasilkan Hampir Sepanjang Tahun</div>
            </b-col>
          </b-row>

          <b-row class="my-1" v-for="(data, i) in hasilGolB" :key="data.tanaman + i">
            <b-col sm="3">
              <div class="label-entry1 small" v-if="!editing">
                {{ data.tanaman }}
              </div>
              <a-select v-else show-search :not-found-content="null" style="width: 100%" :value="hasilGolB[i].tanaman" @change="(value) => (modelGolA.tanaman = value)">
                <a-select-option value="" disabled>Nama Tanaman</a-select-option>
                <a-select-option v-for="(data, i) in dataGolB.tanaman" :key="i" :value="data.value">
                  <div v-html="data.text"></div>
                </a-select-option>
              </a-select>
            </b-col>
            <b-col sm="3">
              <div class="label-entry1 small" v-if="!editing">
                {{ data.peramatan }}
              </div>
              <b-form-select v-model="hasilGolB[i].peramatan" class="mb-1" size="sm" v-else>
                <option value="" disabled>Peramatan Fenologi</option>
                <option v-for="(value, i) in dataGolB.peramatan" :key="i" :value="value">
                  {{ value }}
                </option>
              </b-form-select>
            </b-col>
            <b-col sm="2">
              <div class="label-entry1 small" v-if="!editing">
                {{ data.fenomena }}
              </div>
              <b-form-select v-model="hasilGolB[i].fenomena" class="mb-1" size="sm" v-else>
                <option value="" disabled>Keadaan Fenomena</option>
                <option v-for="(value, i) in dataGolB.fenomena" :key="i" :value="value">
                  {{ value }}
                </option>
              </b-form-select>
            </b-col>
            <b-col sm="3">
              <div class="label-entry1 small" v-if="!editing">
                {{ data.keterangan }}
              </div>
              <b-form-textarea id="textarea-default" placeholder="Keterangan" v-model="hasilGolB[i].keterangan" v-else></b-form-textarea>
            </b-col>
            <b-col sm="1" class="d-flex align-items-center">
              <b-button variant="danger" size="sm" class="btn-icon rounded-circle" @click="() => deleteGolB(i)">
                <feather-icon icon="XIcon" />
              </b-button>
            </b-col>
          </b-row>
          <!-- HASIL GOL B END -->

          <!-- HASIL GOL C START -->
          <b-row class="my-1">
            <b-col cols="6">
              <div class="label-entry2 small">C. Tanaman Buah-buahan Yang menghasilkan Menurut Musim</div>
            </b-col>
          </b-row>

          <b-row class="my-1" v-for="(data, i) in hasilGolC" :key="data.tanaman + i">
            <b-col sm="3">
              <div class="label-entry1 small" v-if="!editing">
                {{ data.tanaman }}
              </div>
              <a-select v-else show-search :not-found-content="null" style="width: 100%" :value="hasilGolC[i].tanaman" @change="(value) => (modelGolA.tanaman = value)">
                <a-select-option value="" disabled>Nama Tanaman</a-select-option>
                <a-select-option v-for="(data, i) in dataGolC.tanaman" :key="i" :value="data.value">
                  <div v-html="data.text"></div>
                </a-select-option>
              </a-select>
            </b-col>
            <b-col sm="3">
              <div class="label-entry1 small" v-if="!editing">
                {{ data.peramatan }}
              </div>
              <b-form-select v-model="hasilGolC[i].peramatan" class="mb-1" size="sm" v-else>
                <option value="" disabled>Peramatan Fenologi</option>
                <option v-for="(value, i) in dataGolC.peramatan" :key="i" :value="value">
                  {{ value }}
                </option>
              </b-form-select>
            </b-col>
            <b-col sm="2">
              <div class="label-entry1 small" v-if="!editing">
                {{ data.fenomena }}
              </div>
              <b-form-select v-model="hasilGolC[i].fenomena" class="mb-1" size="sm" v-else>
                <option value="" disabled>Keadaan Fenomena</option>
                <option v-for="(value, i) in dataGolC.fenomena" :key="i" :value="value">
                  {{ value }}
                </option>
              </b-form-select>
            </b-col>
            <b-col sm="3">
              <div class="label-entry1 small" v-if="!editing">
                {{ data.keterangan }}
              </div>
              <b-form-textarea id="textarea-default" placeholder="Keterangan" v-model="hasilGolC[i].keterangan" v-else></b-form-textarea>
            </b-col>
            <b-col sm="1" class="d-flex align-items-center">
              <b-button variant="danger" size="sm" class="btn-icon rounded-circle" @click="() => deleteGolC(i)"> <feather-icon icon="XIcon" /> </b-button>
            </b-col>
          </b-row>
          <!-- HASIL GOL C END -->

          <b-row class="float-right my-2">
            <div>
              <b-button variant="danger" class="mr-1" size="sm" @click="cancelEdit()"> Cancel </b-button>
              <b-button variant="warning" class="mr-1" size="sm" @click="toggleEdit()">
                {{ editing ? "Selesai" : "Edit" }}
              </b-button>
              <b-button variant="warning" @click="submitForm()" v-if="this.t_data_status == 'update'" class="mr-2" size="sm" :disabled="!roles.isRoleEdit">Update</b-button>
              <b-button variant="primary" @click="submitForm()" v-if="this.t_data_status == 'insert'" class="mr-2" size="sm" :disabled="!roles.isRoleAdd">Submit</b-button>
            </div>
          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { BRow, BCol, BTable, BCardText, BFormInput, BContainer, BButton, BCard, BFormSelect, BFormTextarea } from "bootstrap-vue";
import axios from "axios";
import Helper from "@/api/helper";
import error_handler from "@/api/error_handler";
export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BTable,
    BCardText,
    BFormInput,
    BContainer,
    BButton,
    BCard,
    BFormSelect,
    BFormTextarea,
  },
  props: ["t_station", "t_wmoid", "t_observer", "t_date", "t_station_id", "t_path", "t_data", "t_id_form", "t_data_status", "t_path_update", "roles"],
  data() {
    return {
      editing: false,
      // dataGolA.tanaman diubah jadi object, yang 'text' nama latinnya dikasih <i></i>
      dataGolA: {
        tanaman: [
          {
            value: "Padi (Oryza Sativa)",
            text: "Padi (<i>Oryza Sativa</i>)",
          },
          {
            value: "Ubi (Ipomoea batatas)",
            text: "Ubi (<i>Ipomoea batatas</i>)",
          },
          {
            value: "Kacang kedelai (Glycine max)",
            text: "Kacang kedelai (<i>Glycine max</i>)",
          },
          {
            value: "Ketela (Manihot utilissima)",
            text: "Ketela (<i>Manihot utilissima</i>)",
          },
          {
            value: "Jagung (Zea may)",
            text: "Jagung (<i>Zea mays</i>)",
          },
        ],
        peramatan: [
          "Persiapan persemaian",
          "Penyebaran bibit di persemaian (kalau ada padi sawah)",
          "Berkecambah",
          "Persiapan tanah",
          "Penyebaran bibit / pemindahan",
          "Pertumbuhan daun / pertumbuhan memanjang",
          "Berbunga jantan / betina",
          "Pertumbuhan buah",
          "Pemasakan dan panen",
        ],
        fenomena: ["Sangat Baik", "Baik", "Sedang", "Buruk"],
      },
      modelGolA: {
        tanaman: "",
        peramatan: "",
        fenomena: "",
        keterangan: "",
      },
      hasilGolA: [],
      curEdithasilGolA: [],
      dataGolB: {
        tanaman: [
          {
            value: "Sawo (Achras sapota)",
            text: "Sawo (<i>Achras sapota</i>)",
          },
          {
            value: "Salak (Salacca edulis)",
            text: "Salak (<i>Salacca edulis</i>)",
          },
          {
            value: "Apokat (Persea Americana)",
            text: "Apokat (<i>Persea Americana</i>)",
          },
          {
            value: "Jambu biji (Psidium guajava)",
            text: "Jambu biji (<i>Psidium guajava</i>)",
          },
          {
            value: "Cempedak (Artocarpus Champeden)",
            text: "Cempedak (<i>Artocarpus Champeden</i>)",
          },
        ],
        // tanaman: ["Sawo (Achras sapota)", "Salak (salacca edulis)", "Apokat (Persea Americana)", "Jambu biji (Psidium guajava)", "Cempedak (Artocarpus Champeden)"],
        peramatan: ["Perontokan daun (kalau ada)", "Pertumbuhan daun baru", "Pembentukan kuncup bunga", "Pembungaan", "Pembentukan buah muda", "Pemasakan dan panen"],
        fenomena: ["Sangat Baik", "Baik", "Sedang", "Buruk"],
      },
      modelGolB: {
        tanaman: "",
        peramatan: "",
        fenomena: "",
        keterangan: "",
      },
      hasilGolB: [],
      curEdithasilGolB: [],
      dataGolC: {
        tanaman: [
          {
            value: "Duren (Durio zibethinus)",
            text: "Duren (<i>Durio zibethinus</i>)",
          },
          {
            value: "Duku (Lansium domesticum)",
            text: "Duku (<i>Lansium domesticum</i>)",
          },
          {
            value: "Manggis (Garcinia mangostana)",
            text: "Manggis (<i>Garcinia mangostana</i>)",
          },
          {
            value: "Rambutan (Nephelium lappaceum)",
            text: "Rambutan (<i>Nephelium lappaceum</i>)",
          },
          {
            value: "Jeruk (Citrus Sp)",
            text: "Jeruk (<i>Citrus Sp</i>)",
          },
          {
            value: "Mangga (Mangifera indica)",
            text: "Mangga (<i>Mangifera indica</i>)",
          },
          {
            value: "Asam (Tamarindus indica)",
            text: "Asam (<i>Tamarindus indica</i>)",
          },
        ],
        peramatan: ["Perontokan daun (kalau ada)", "Pertumbuhan daun baru", "Pembentukan kuncup bunga", "Pembungaan", "Pembentukan buah muda", "Pemasakan dan panen"],
        fenomena: ["Sangat Baik", "Baik", "Sedang", "Buruk"],
      },
      modelGolC: {
        tanaman: "",
        peramatan: "",
        fenomena: "",
        keterangan: "",
      },
      hasilGolC: [],
      curEdithasilGolC: [],
      CEGolA: [],
      CEGolB: [],
      CEGolC: [],
    };
  },
  mounted() {
    this.getListTanaman();
    if (this.t_data_status == "update") {
      this.popupData();
    } else {
      this.resetForm();
    }
  },
  methods: {
    dynamicSort(property) {
      var sortOrder = 1;

      if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
      }

      return function (a, b) {
        if (sortOrder == -1) {
          return b[property].localeCompare(a[property]);
        } else {
          return a[property].localeCompare(b[property]);
        }
      };
    },
    async getListTanaman() {
      const url_get = Helper.getBaseUrl() + "@search?type_name=SettingToolbox&sb_form_type=Fenologi&sb_is_active=1";
      const { data } = await axios.get(url_get, Helper.getConfig());
      const listTanaman = data.items;

      let tanamanA = [];
      let tanamanB = [];
      let tanamanC = [];
      for (var i in listTanaman) {
        let Gol = listTanaman[i].sb_attribut;

        if (Gol == "golA") {
          let tanaman = {
            value: listTanaman[i].sb_value + "(" + listTanaman[i].sb_text + ")",
            text: listTanaman[i].sb_value + "(<i>" + listTanaman[i].sb_text + "</i>)",
          };
          tanamanA.push(tanaman);
        }

        if (Gol == "golB") {
          let tanaman = {
            value: listTanaman[i].sb_value + "(" + listTanaman[i].sb_text + ")",
            text: listTanaman[i].sb_value + "(<i>" + listTanaman[i].sb_text + "</i>)",
          };
          tanamanB.push(tanaman);
        }

        if (Gol == "golC") {
          let tanaman = {
            value: listTanaman[i].sb_value + "(" + listTanaman[i].sb_text + ")",
            text: listTanaman[i].sb_value + "(<i>" + listTanaman[i].sb_text + "</i>)",
          };
          tanamanC.push(tanaman);
        }
        tanamanA.sort(this.dynamicSort("value"));
        tanamanB.sort(this.dynamicSort("value"));
        tanamanC.sort(this.dynamicSort("value"));
        this.dataGolA.tanaman = tanamanA;
        this.dataGolB.tanaman = tanamanB;
        this.dataGolC.tanaman = tanamanC;
      }
      // console.log("data tanaman : ",tanamanA);
      // console.log("data tanaman : ",tanamanB);
      // console.log("data tanaman : ",tanamanC);
    },
    toggleEdit() {
      this.editing = !this.editing;
      this.curEdithasilGolC = this.hasilGolC;
      this.curEdithasilGolB = this.hasilGolB;
      this.curEdithasilGolA = this.hasilGolA;
    },
    popupData() {
      console.log("this.t_data : ", this.t_data);
      this.editing = false;
      this.CEGolA = Object.entries(this.t_data.feno_golA).map(([key, item]) => item.tanaman_golA);
      console.log("CEGolA : ", this.CEGolA);
      this.hasilGolA = Object.entries(this.t_data.feno_golA).map(([key, item]) => ({
        tanaman: item.tanaman_golA,
        peramatan: item.peramatan_golA,
        fenomena: item.fenomena_golA,
        keterangan: item.ket_feno_golA,
      }));

      this.CEGolB = Object.entries(this.t_data.feno_golB).map(([key, item]) => item.tanaman_golB);
      console.log("CEGolB : ", this.CEGolB);
      this.hasilGolB = Object.entries(this.t_data.feno_golB).map(([key, item]) => ({
        tanaman: item.tanaman_golB,
        peramatan: item.peramatan_golB,
        fenomena: item.fenomena_golB,
        keterangan: item.ket_feno_golB,
      }));

      this.CEGolC = Object.entries(this.t_data.feno_golC).map(([key, item]) => item.tanaman_golC);
      console.log("CEGolC : ", this.CEGolC);
      this.hasilGolC = Object.entries(this.t_data.feno_golC).map(([key, item]) => ({
        tanaman: item.tanaman_golC,
        peramatan: item.peramatan_golC,
        fenomena: item.fenomena_golC,
        keterangan: item.ket_feno_golC,
      }));
    },
    addGolA() {
      let fenomena = this.modelGolA.fenomena;
      let tanaman = this.modelGolA.tanaman;
      let peramatan = this.modelGolA.peramatan;
      if (fenomena == "" || tanaman == "" || peramatan == "") {
        this.$swal({
          title: "ERROR!",
          text: "Nama Tanaman, Peramatan dan Fenomena Tidak Boleh Kosong",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        return;
      }
      let cekData = this.CEGolA.indexOf(tanaman);
      console.log("this.CEGolA: ", this.CEGolA);
      if (cekData == "-1" || cekData == -1) {
        this.hasilGolA.push(this.modelGolA);
        this.CEGolA.push(tanaman);
      } else {
        this.$swal({
          title: "ERROR!",
          text: "Nama Tanaman Tidak Boleh Sama",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        return;
      }
      this.clearGolA();
    },
    clearGolA() {
      this.modelGolA = {
        tanaman: "",
        peramatan: "",
        fenomena: "",
        keterangan: "",
      };
    },
    deleteGolA(idx) {
      let data = this.hasilGolA.filter((data, i) => i == idx);
      let tanaman = data[0].tanaman;
      console.log("data : ", data[0]);
      let index = this.CEGolA.indexOf(tanaman);
      this.CEGolA.splice(index, 1);
      this.hasilGolA = this.hasilGolA.filter((data, i) => i !== idx);
    },
    addGolB() {
      let fenomena = this.modelGolB.fenomena;
      let tanaman = this.modelGolB.tanaman;
      let peramatan = this.modelGolB.peramatan;
      if (fenomena == "" || tanaman == "" || peramatan == "") {
        this.$swal({
          title: "ERROR!",
          text: "Nama Tanaman, Peramatan dan Fenomena Tidak Boleh Kosong",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        return;
      }
      let cekData = this.CEGolB.indexOf(tanaman);
      console.log("CEGolB : ", this.CEGolB);
      if (cekData == "-1" || cekData == -1) {
        this.hasilGolB.push(this.modelGolB);
        this.CEGolB.push(tanaman);
      } else {
        this.$swal({
          title: "ERROR!",
          text: "Nama Tanaman Tidak Boleh Sama",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        return;
      }

      this.clearGolB();
    },
    clearGolB() {
      this.modelGolB = {
        tanaman: "",
        peramatan: "",
        fenomena: "",
        keterangan: "",
      };
    },
    deleteGolB(idx) {
      let data = this.hasilGolB.filter((data, i) => i == idx);
      let tanaman = data[0].tanaman;
      let index = this.CEGolB.indexOf(tanaman);
      this.CEGolB.splice(index, 1);
      this.hasilGolB = this.hasilGolB.filter((data, i) => i !== idx);
    },
    addGolC() {
      let fenomena = this.modelGolC.fenomena;
      let tanaman = this.modelGolC.tanaman;
      let peramatan = this.modelGolC.peramatan;
      if (fenomena == "" || tanaman == "" || peramatan == "") {
        this.$swal({
          title: "ERROR!",
          text: "Nama Tanaman, Peramatan dan Fenomena Tidak Boleh Kosong",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        return;
      }
      let cekData = this.CEGolC.indexOf(tanaman);
      console.log("this.CEGolC : ", this.CEGolC);
      console.log("cekData: ", cekData);
      if (cekData == "-1" || cekData == -1) {
        this.hasilGolC.push(this.modelGolC);
        this.CEGolC.push(tanaman);
      } else {
        this.$swal({
          title: "ERROR!",
          text: "Nama Tanaman Tidak Boleh Sama",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        return;
      }
      this.clearGolC();
    },
    clearGolC() {
      this.modelGolC = {
        tanaman: "",
        peramatan: "",
        fenomena: "",
        keterangan: "",
      };
    },
    deleteGolC(idx) {
      let data = this.hasilGolC.filter((data, i) => i == idx);
      let tanaman = data[0].tanaman;
      let index = this.CEGolC.indexOf(tanaman);
      this.CEGolC.splice(index, 1);
      this.hasilGolC = this.hasilGolC.filter((data, i) => i !== idx);
    },
    validateForm() {
      let valid = false;
      valid = this.hasilGolA.length > 0 || this.hasilGolB.length > 0 || this.hasilGolC.length > 0;

      if (!valid) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Data tidak valid. Periksa kembali inputan anda",
            icon: "BellIcon",
            variant: "danger",
          },
        });
      }
      return valid;
    },
    resetForm() {
      this.CEGolA = [];
      this.CEGolB = [];
      this.CEGolC = [];
      this.clearGolA();
      this.clearGolB();
      this.clearGolC();
      this.hasilGolA = [];
      this.hasilGolB = [];
      this.hasilGolC = [];
    },
    cancelEdit() {
      this.editing = false;
      this.hasilGolA = this.curEdithasilGolA;
      this.hasilGolB = this.curEdithasilGolB;
      this.hasilGolC = this.curEdithasilGolC;
    },
    convertArrToObj(arr) {
      const res = {};
      arr = [...arr];
      for (let i = 0; i < arr.length; i++) {
        res[i + 1] = { ...arr[i] };
      }
      return res;
    },
    submitForm() {
      if (!this.validateForm()) return false;

      const ID = this.t_station_id + "_fenologi_" + this.t_date;

      const feno_golA = this.convertArrToObj(
        this.hasilGolA.map((item) => ({
          tanaman_golA: item.tanaman,
          peramatan_golA: item.peramatan,
          fenomena_golA: item.fenomena,
          ket_feno_golA: item.keterangan,
        }))
      );

      const feno_golB = this.convertArrToObj(
        this.hasilGolB.map((item) => ({
          tanaman_golB: item.tanaman,
          peramatan_golB: item.peramatan,
          fenomena_golB: item.fenomena,
          ket_feno_golB: item.keterangan,
        }))
      );

      const feno_golC = this.convertArrToObj(
        this.hasilGolC.map((item) => ({
          tanaman_golC: item.tanaman,
          peramatan_golC: item.peramatan,
          fenomena_golC: item.fenomena,
          ket_feno_golC: item.keterangan,
        }))
      );

      if (this.t_data_status == "insert") {
        const post_data = {
          "@type": "Fenologi",
          id: ID,
          feno_golA: feno_golA,
          feno_golB: feno_golB,
          feno_golC: feno_golC,
          data_timestamp: this.t_date,
          observer_id: this.t_observer,
        };
        const post_url = Helper.getBaseUrl() + this.t_path;
        axios
          .post(post_url, post_data, Helper.getConfig())
          .then((response) => {
            this.$swal({
              title: "Input Data Berhasil!",
              text: "Data Telah berhasil di Simpan",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.resetForm();
          })
          .catch((error) => {
            error_handler(this, error, "Fenologi", "Input Data Error");
          });
      } else if (this.t_data_status == "update") {
        const post_data = {
          "@type": "Fenologi",
          feno_golA: feno_golA,
          feno_golB: feno_golB,
          feno_golC: feno_golC,
          modification_date: this.t_date,
          observer_id: this.t_observer,
        };
        const update_url = Helper.getBaseUrl() + this.t_path_update;
        axios
          .patch(update_url, post_data, Helper.getConfig())
          .then((response) => {
            this.$swal({
              title: "Input Data Berhasil!",
              text: "Data Telah berhasil di Simpan",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.resetForm();
          })
          .catch((error) => {
            error_handler(this, error, "Fenologi", "Update Data Error");
          });
      }
    },
  },
  watch: {
    t_data_status(newValue) {
      if (newValue == "update") {
        this.popupData();
      } else {
        this.resetForm();
      }
    },
  },
};
</script>
<style scoped>
.label-entry {
  text-align: center;
  color: rgb(0, 0, 0);
  width: 100%;
  /* font-weight: bold !important; */
  border: 2px solid #676768;
  border-radius: 3px;
  background: #f8f8f8;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 4px;
  padding-right: 4px;
}
.label-entry1 {
  color: #3b4253;
  width: 100%;
  font-weight: 500;
  border: 2px solid #95b8dd;
  background: #f7fbff;
  border-radius: 3px;
  padding: 4px;
  height: 38px;
  line-height: 25px;
  margin: auto;
  overflow: auto;
}
.label-entry2 {
  color: #3b4253;
  width: 100%;
  font-weight: 500;
  /* border: 2px solid #6e6b7b; */
  border-radius: 3px;
  background: #e9e8e8;
  padding: 4px;
  height: 38px;
  line-height: 25px;
  margin-bottom: 7px;
}
.mbtm {
  margin-bottom: 6px;
}
.bg-light-primary {
  color: #000000 !important;
}
</style>
