export const fdih_1c2m_0700 = 
'<dl class="text-left">'+
'<dt>Tbk</dt>'+
'<dd>- Temperatur dari thermometer bola kering pada ketinggian 1,2 m, dinyatakan dalam persepuluhan derajat Celsius.</dd>'+
'<dt>Tbb</dt>'+
'<dd>- Temperatur dari thermometer bola basah pada ketinggian 1,2 m, dinyatakan dalam persepuluhan derajat Celsius.</dd>'+
'<dt>RH</dt>'+
'<dd>- Lembab Nisbi udara pada ketinggian 1,2 m, dinyatakan dalam persen (%).</dd>'+
'</dl>'

export const fdih_4m_0700 = 
"<dl class='text-left'>" +
"<dt>Tbk</dt>" +
"<dd>- Temperatur dari thermometer bola kering pada ketinggian 4 m, dinyatakan dalam persepuluhan derajat Celsius.</dd>"+
"<dt>Tbb</dt>"+
"<dd>- Temperatur dari thermometer bola basah pada ketinggian 4 m, dinyatakan dalam persepuluhan derajat Celsius.</dd>"+
"<dt>RH</dt>"+
"<dd>- Lembab Nisbi udara pada ketinggian 4 m, dinyatakan dalam persen (%).</dd>"+
"</dl>"

export const fdih_7m_0700 = 
'<dl class="text-left">'+
'<dt>Tbk</dt>'+
'<dd>- Temperatur dari thermometer bola kering pada ketinggian 7 m, dinyatakan dalam persepuluhan derajat Celsius.</dd>'+
'<dt>Tbb</dt>'+
'<dd>- Temperatur dari thermometer bola basah pada ketinggian 7 m, dinyatakan dalam persepuluhan derajat Celsius.</dd>'+
'<dt>RH</dt>'+
'<dd>- Lembab Nisbi udara pada ketinggian 7 m, dinyatakan dalam persen (%).</dd>'+
'</dl>'

export const fdih_10m_0700 = 
'<dl class="text-left">'+
'<dt>Tbk</dt>'+
'<dd>- Temperatur dari thermometer bola kering pada ketinggian 10 m, dinyatakan dalam persepuluhan derajat Celsius.</dd>'+
'<dt>Tbb</dt>'+
'<dd>- Temperatur dari thermometer bola basah pada ketinggian 10 m, dinyatakan dalam persepuluhan derajat Celsius.</dd>'+
'<dt>RH</dt>'+
'<dd>- Lembab Nisbi udara pada ketinggian 10 m, dinyatakan dalam persen (%).</dd>'+
'</dl>'

export const fdih_lama_penyinaran_0700 = 
'<dl class="text-left">'+
'<dt>ON - Jika terjadi alat rusak</dt>'+
'<dt>OFF - Jika tidak terjadi alat rusak</dt>'+
'</dl>'

export const fdih_op_penguapan_0700 = 
'<dl class="text-left">'+
'<dt>ON - Jika ingin melakukan edit manual'+
'<dt>OFF - Jika ingin perhitungan otomatis</dt>'+
'</dl>'

export const fdih_counter_sebelum_0700 = 
'<dl class="text-left">'+
'<dt>ON - Jika ingin melakukan reset/edit manual'+
'<dt>OFF - Jika ingin ambil data otomatis</dt>'+
'</dl>'

export const fdih_gunbellani_0700 = 
'<dl class="text-left">'+
'<dt>ON - Jika ingin melakukan reset/edit manual'+
'<dt>OFF - Jika ingin ambil data otomatis</dt>'+
'</dl>'