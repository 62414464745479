<template>
    <div>
        <b-row class="mt-n2">
            <!-- card actions -->
            <b-col cols="8">
                <b-card class="font-weight-bolder text-dark">
                    <h3 class="mb-2 font-weight-bolder badge badge-light-dark">Intensitas Hujan Dalam Menit</h3>
                    <div class="row">
                        <div class="col-xl-5">
                            <b-row class="text-center">
                                <b-col cols="4">
                                    <label for="hellman_5m" class="label-entry bg-light-primary"
                                        >5 menit <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah curah hujan terbesar dalam periode 5 menit (dalam kurun 24 jam)"
                                    /></label>
                                </b-col>
                                <b-col cols="4 pad4">
                                    <label for="hellman_10m" class="label-entry bg-light-primary"
                                        >10 menit <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah curah hujan terbesar dalam periode 10 menit (dalam kurun 24 jam)"
                                    /></label>
                                </b-col>
                                <b-col cols="4 pad4">
                                    <label for="hellman_15m" class="label-entry bg-light-primary"
                                        >15 menit <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah curah hujan terbesar dalam periode 15 menit (dalam kurun 24 jam)"
                                    /></label>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="4">
                                    <b-form-input
                                        id="hellman_5m"
                                        class="text-center"
                                        size="sm"
                                        type="number"
                                        v-on:keydown="onKeyCheck($event)"
                                        v-model.number="$v.hellman_5m.$model"
                                        :state="validateState($v.hellman_5m)"
                                        @keyup="checkValidate(formatPersepuluhan($event), 'hellman_5m')"
                                        :class="{ rangecheck: RC.hellman_5m.status }"
                                    ></b-form-input>
                                    <b-tooltip v-if="RC.hellman_5m.status" target="hellman_5m" triggers="hover">{{ RC.hellman_5m.message }}</b-tooltip>
                                    <b-tooltip v-if="CC.hellman_5m.status" target="hellman_5m" triggers="hover">{{ CC.hellman_5m.message }}</b-tooltip>

                                    <b-tooltip :show="validateTooltipState('hellman_5m')" :triggers="'manual'" target="hellman_5m" placement="top" custom-class="validation-tooltip">
                                        {{ tooltipMessage("hellman_5m") }}
                                    </b-tooltip>
                                </b-col>
                                <b-col cols="4">
                                    <b-form-input
                                        id="hellman_10m"
                                        class="text-center"
                                        size="sm"
                                        type="number"
                                        v-on:keydown="onKeyCheck($event)"
                                        v-model.number="$v.hellman_10m.$model"
                                        :state="validateState($v.hellman_10m)"
                                        @keyup="checkValidate(formatPersepuluhan($event), 'hellman_10m')"
                                        :class="{ rangecheck: RC.hellman_10m.status }"
                                    ></b-form-input>
                                    <b-tooltip v-if="RC.hellman_10m.status && !CC.hellman_10m.status" target="hellman_10m" triggers="hover">{{ RC.hellman_10m.message }}</b-tooltip>
                                    <b-tooltip v-if="CC.hellman_10m.status" target="hellman_10m" triggers="hover">{{ CC.hellman_10m.message }}</b-tooltip>
                                </b-col>
                                <b-col cols="4">
                                    <b-form-input
                                        id="hellman_15m"
                                        class="text-center"
                                        size="sm"
                                        type="number"
                                        v-on:keydown="onKeyCheck($event)"
                                        v-model.number="$v.hellman_15m.$model"
                                        :state="validateState($v.hellman_15m)"
                                        @keyup="checkValidate(formatPersepuluhan($event), 'hellman_15m')"
                                        :class="{ rangecheck: RC.hellman_15m.status }"
                                    ></b-form-input>
                                    <b-tooltip v-if="RC.hellman_15m.status && !CC.hellman_15m.status" target="hellman_15m" triggers="hover">{{ RC.hellman_15m.message }}</b-tooltip>
                                    <b-tooltip v-if="CC.hellman_15m.status" target="hellman_15m" triggers="hover">{{ CC.hellman_15m.message }}</b-tooltip>
                                </b-col>
                            </b-row>
                        </div>
                        <!-- ==2== -->
                        <div class="col-xl-7">
                            <b-row class="text-center mt-6">
                                <b-col cols="3">
                                    <label for="hellman_30m" class="label-entry bg-light-primary"
                                        >30 menit <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah curah hujan terbesar dalam periode 30 menit (dalam kurun 24 jam)"
                                    /></label>
                                </b-col>
                                <b-col cols="3">
                                    <label for="hellman_45m" class="label-entry bg-light-primary"
                                        >45 menit <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah curah hujan terbesar dalam periode 45 menit (dalam kurun 24 jam)"
                                    /></label>
                                </b-col>
                                <b-col cols="3 pad4">
                                    <label for="hellman_60m" class="label-entry bg-light-primary"
                                        >60 menit <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah curah hujan terbesar dalam periode 60 menit (dalam kurun 24 jam)"
                                    /></label>
                                </b-col>
                                <b-col cols="3 pad3">
                                    <label for="hellman_120m" class="label-entry bg-light-primary"
                                        >120 menit <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah curah hujan terbesar dalam periode 120 menit (dalam kurun 24 jam)"
                                    /></label>
                                </b-col>
                            </b-row>

                            <b-row class="text-center">
                                <b-col cols="3">
                                    <b-form-input
                                        id="hellman_30m"
                                        class="text-center"
                                        size="sm"
                                        type="number"
                                        v-on:keydown="onKeyCheck($event)"
                                        v-model.number="$v.hellman_30m.$model"
                                        :state="validateState($v.hellman_30m)"
                                        @keyup="checkValidate(formatPersepuluhan($event), 'hellman_30m')"
                                        :class="{ rangecheck: RC.hellman_30m.status }"
                                    ></b-form-input>
                                    <b-tooltip v-if="RC.hellman_30m.status && !CC.hellman_30m.status" target="hellman_30m" triggers="hover">{{ RC.hellman_30m.message }}</b-tooltip>
                                    <b-tooltip v-if="CC.hellman_30m.status" target="hellman_30m" triggers="hover">{{ CC.hellman_30m.message }}</b-tooltip>
                                </b-col>
                                <b-col cols="3">
                                    <b-form-input
                                        id="hellman_45m"
                                        class="text-center"
                                        size="sm"
                                        type="number"
                                        v-on:keydown="onKeyCheck($event)"
                                        v-model.number="$v.hellman_45m.$model"
                                        :state="validateState($v.hellman_45m)"
                                        @keyup="checkValidate(formatPersepuluhan($event), 'hellman_45m')"
                                        :class="{ rangecheck: RC.hellman_45m.status }"
                                    ></b-form-input>
                                    <b-tooltip v-if="RC.hellman_45m.status && !CC.hellman_45m.status" target="hellman_45m" triggers="hover">{{ RC.hellman_45m.message }}</b-tooltip>
                                    <b-tooltip v-if="CC.hellman_45m.status" target="hellman_45m" triggers="hover">{{ CC.hellman_45m.message }}</b-tooltip>
                                </b-col>
                                <b-col cols="3">
                                    <b-form-input
                                        id="hellman_60m"
                                        class="text-center"
                                        size="sm"
                                        type="number"
                                        v-on:keydown="onKeyCheck($event)"
                                        v-model.number="$v.hellman_60m.$model"
                                        :state="validateState($v.hellman_60m)"
                                        @keyup="checkValidate(formatPersepuluhan($event), 'hellman_60m')"
                                        :class="{ rangecheck: RC.hellman_60m.status }"
                                    ></b-form-input>
                                    <b-tooltip v-if="RC.hellman_60m.status && !CC.hellman_60m.status" target="hellman_60m" triggers="hover">{{ RC.hellman_60m.message }}</b-tooltip>
                                    <b-tooltip v-if="CC.hellman_60m.status" target="hellman_60m" triggers="hover">{{ CC.hellman_60m.message }}</b-tooltip>
                                </b-col>
                                <b-col cols="3">
                                    <b-form-input
                                        id="hellman_120m"
                                        class="text-center"
                                        size="sm"
                                        type="number"
                                        v-on:keydown="onKeyCheck($event)"
                                        v-model.number="$v.hellman_120m.$model"
                                        :state="validateState($v.hellman_120m)"
                                        @keyup="checkValidate(formatPersepuluhan($event), 'hellman_120m')"
                                        :class="{ rangecheck: RC.hellman_120m.status }"
                                    ></b-form-input>
                                    <b-tooltip v-if="RC.hellman_120m.status && !CC.hellman_120m.status" target="hellman_120m" triggers="hover">{{ RC.hellman_120m.message }}</b-tooltip>
                                    <b-tooltip v-if="CC.hellman_120m.status" target="hellman_120m" triggers="hover">{{ CC.hellman_120m.message }}</b-tooltip>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-card>
            </b-col>

            <b-col cols="4">
                <b-card class="font-weight-bolder text-dark">
                    <h3 class="mb-2 font-weight-bolder badge badge-light-dark">Intensitas Hujan Dalam Jam</h3>
                    <b-row>
                        <b-col sm="4 ">
                            <div>
                                <label for="hellman_3h" class="label-entry bg-light-success"
                                    >3 jam <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah curah hujan terbesar dalam periode 03 jam (dalam kurun 24 jam)"
                                /></label>
                            </div>
                            <b-form-input
                                id="hellman_3h"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_3h.$model"
                                :state="validateState($v.hellman_3h)"
                                @keyup="checkValidate(formatPersepuluhan($event), 'hellman_3h')"
                                :class="{ rangecheck: RC.hellman_3h.status }"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_3h.status && !CC.hellman_3h.status" target="hellman_3h" triggers="hover">{{ RC.hellman_3h.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_3h.status" target="hellman_3h" triggers="hover">{{ CC.hellman_3h.message }}</b-tooltip>

                            <!-- <b-tooltip :show="validateTooltipState('hellman_3h')" :triggers="'manual'" target="hellman_3h" placement="top" custom-class="validation-tooltip">
                {{ tooltipMessage("hellman_3h") }}
              </b-tooltip> -->
                        </b-col>

                        <b-col sm="4 ">
                            <div>
                                <label for="hellman_6h" class="label-entry bg-light-success"
                                    >6 jam <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah curah hujan terbesar dalam periode 06 jam (dalam kurun 24 jam)"
                                /></label>
                            </div>
                            <b-form-input
                                id="hellman_6h"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_6h.$model"
                                :state="validateState($v.hellman_6h)"
                                @keyup="checkValidate(formatPersepuluhan($event), 'hellman_6h')"
                                :class="{ rangecheck: RC.hellman_6h.status }"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_6h.status && !CC.hellman_3h.status" target="hellman_6h" triggers="hover">{{ RC.hellman_6h.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_6h.status" target="hellman_6h" triggers="hover">{{ CC.hellman_6h.message }}</b-tooltip>
                        </b-col>

                        <b-col sm="4 ">
                            <div>
                                <label for="hellman_12h" class="label-entry bg-light-success"
                                    >12 jam <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah curah hujan terbesar dalam periode 12 jam (dalam kurun 24 jam)"
                                /></label>
                            </div>
                            <b-form-input
                                id="hellman_12h"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_12h.$model"
                                :state="validateState($v.hellman_12h)"
                                @keyup="checkValidate(formatPersepuluhan($event), 'hellman_12h')"
                                :class="{ rangecheck: RC.hellman_12h.status }"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_12h.status && !CC.hellman_3h.status" target="hellman_12h" triggers="hover">{{ RC.hellman_12h.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_12h.status" target="hellman_12h" triggers="hover">{{ CC.hellman_12h.message }}</b-tooltip>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        <b-row class="mt-n2">
            <!-- card actions -->
            <b-col cols="12">
                <b-card class="font-weight-bolder text-dark">
                    <h3 class="mb-2 font-weight-bolder badge badge-light-dark">Intensitas Hujan Per Jam</h3>
                    <b-row>
                        <b-col sm="1">
                            <div>
                                <label for="input_hellman_07_08" class="label-entry"
                                    >07-08
                                    <feather-icon
                                        icon="HelpCircleIcon"
                                        size="12"
                                        class="bg-primary rounded-circle text-white"
                                        v-b-tooltip.hover.v-dark
                                        title="Jumlah curah hujan pada pukul 07 - 08 waktu setempat dalam satuan milimeter (bisa diisi dengan pecahan desimal 1 angka di belakang koma)"
                                /></label>
                            </div>
                            <b-form-input
                                id="input_hellman_07_08"
                                v-on:keyup="checkValidate(formatPersepuluhan($event), 'hellman_07_08')"
                                v-bind:class="{ rangecheck: RC.hellman_07_08.status }"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_07_08.$model"
                                :state="validateState($v.hellman_07_08)"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_07_08.status && !CC.hellman_07_08m.status" target="input_hellman_07_08" triggers="hover">{{ RC.hellman_07_08.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_07_08.status" target="input_hellman_07_08" triggers="hover">{{ CC.hellman_07_08.message }}</b-tooltip>

                            <b-tooltip v-if="!CC.hellman_07_08.status" :show="validateTooltipState('hellman_07_08')" :triggers="'hover'" target="input_hellman_07_08" placement="top" custom-class="validation-tooltip">
                                {{ tooltipMessage("hellman_07_08") }}
                            </b-tooltip>
                        </b-col>

                        <b-col sm="1">
                            <div>
                                <label for="input_hellman_08_09" class="label-entry"
                                    >08-09
                                    <feather-icon
                                        icon="HelpCircleIcon"
                                        size="12"
                                        class="bg-primary rounded-circle text-white"
                                        v-b-tooltip.hover.v-dark
                                        title="Jumlah curah hujan pada pukul 08 - 09 waktu setempat dalam satuan milimeter (bisa diisi dengan pecahan desimal 1 angka di belakang koma)"
                                /></label>
                            </div>
                            <b-form-input
                                id="input_hellman_08_09"
                                v-on:keyup="checkValidate(formatPersepuluhan($event), 'hellman_08_09')"
                                v-bind:class="{ rangecheck: RC.hellman_08_09.status }"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_08_09.$model"
                                :state="validateState($v.hellman_08_09)"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_08_09.status" target="input_hellman_08_09" triggers="hover">{{ RC.hellman_08_09.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_08_09.status" target="input_hellman_08_09" triggers="hover">{{ CC.hellman_08_09.message }}</b-tooltip>
                        </b-col>

                        <b-col sm="1">
                            <div>
                                <label for="input_hellman_09_10" class="label-entry"
                                    >09-10
                                    <feather-icon
                                        icon="HelpCircleIcon"
                                        size="12"
                                        class="bg-primary rounded-circle text-white"
                                        v-b-tooltip.hover.v-dark
                                        title="Jumlah curah hujan pada pukul 09 - 10 waktu setempat dalam satuan milimeter (bisa diisi dengan pecahan desimal 1 angka di belakang koma)"
                                /></label>
                            </div>
                            <b-form-input
                                id="input_hellman_09_10"
                                v-on:keyup="checkValidate(formatPersepuluhan($event), 'hellman_09_10')"
                                v-bind:class="{ rangecheck: RC.hellman_09_10.status }"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_09_10.$model"
                                :state="validateState($v.hellman_09_10)"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_09_10.status" target="input_hellman_09_10" triggers="hover">{{ RC.hellman_09_10.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_09_10.status" target="input_hellman_09_10" triggers="hover">{{ CC.hellman_09_10.message }}</b-tooltip>
                        </b-col>

                        <b-col sm="1">
                            <div>
                                <label for="input_hellman_10_11" class="label-entry"
                                    >10-11
                                    <feather-icon
                                        icon="HelpCircleIcon"
                                        size="12"
                                        class="bg-primary rounded-circle text-white"
                                        v-b-tooltip.hover.v-dark
                                        title="Jumlah curah hujan pada pukul 10 - 11 waktu setempat dalam satuan milimeter (bisa diisi dengan pecahan desimal 1 angka di belakang koma)"
                                /></label>
                            </div>
                            <b-form-input
                                id="input_hellman_10_11"
                                v-on:keyup="checkValidate(formatPersepuluhan($event), 'hellman_10_11')"
                                v-bind:class="{ rangecheck: RC.hellman_10_11.status }"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_10_11.$model"
                                :state="validateState($v.hellman_10_11)"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_10_11.status" target="input_hellman_10_11" triggers="hover">{{ RC.hellman_10_11.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_10_11.status" target="input_hellman_10_11" triggers="hover">{{ CC.hellman_10_11.message }}</b-tooltip>
                        </b-col>

                        <b-col sm="1">
                            <div>
                                <label for="input_hellman_11_12" class="label-entry"
                                    >11-12
                                    <feather-icon
                                        icon="HelpCircleIcon"
                                        size="12"
                                        class="bg-primary rounded-circle text-white"
                                        v-b-tooltip.hover.v-dark
                                        title="Jumlah curah hujan pada pukul 11 - 12 waktu setempat dalam satuan milimeter (bisa diisi dengan pecahan desimal 1 angka di belakang koma)"
                                /></label>
                            </div>
                            <b-form-input
                                id="input_hellman_11_12"
                                v-on:keyup="checkValidate(formatPersepuluhan($event), 'hellman_11_12')"
                                v-bind:class="{ rangecheck: RC.hellman_11_12.status }"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_11_12.$model"
                                :state="validateState($v.hellman_11_12)"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_11_12.status" target="input_hellman_11_12" triggers="hover">{{ RC.hellman_11_12.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_11_12.status" target="input_hellman_11_12" triggers="hover">{{ CC.hellman_11_12.message }}</b-tooltip>
                        </b-col>

                        <b-col sm="1">
                            <div>
                                <label for="input_hellman_12_13" class="label-entry"
                                    >12-13
                                    <feather-icon
                                        icon="HelpCircleIcon"
                                        size="12"
                                        class="bg-primary rounded-circle text-white"
                                        v-b-tooltip.hover.v-dark
                                        title="Jumlah curah hujan pada pukul 12 - 13 waktu setempat dalam satuan milimeter (bisa diisi dengan pecahan desimal 1 angka di belakang koma)"
                                /></label>
                            </div>
                            <b-form-input
                                id="input_hellman_12_13"
                                v-on:keyup="checkValidate(formatPersepuluhan($event), 'hellman_12_13')"
                                v-bind:class="{ rangecheck: RC.hellman_12_13.status }"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_12_13.$model"
                                :state="validateState($v.hellman_12_13)"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_12_13.status" target="input_hellman_12_13" triggers="hover">{{ RC.hellman_12_13.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_12_13.status" target="input_hellman_12_13" triggers="hover">{{ CC.hellman_12_13.message }}</b-tooltip>
                        </b-col>
                        <b-col sm="1">
                            <div>
                                <label for="input_hellman_13_14" class="label-entry"
                                    >13-14
                                    <feather-icon
                                        icon="HelpCircleIcon"
                                        size="12"
                                        class="bg-primary rounded-circle text-white"
                                        v-b-tooltip.hover.v-dark
                                        title="Jumlah curah hujan pada pukul 13 - 14 waktu setempat dalam satuan milimeter (bisa diisi dengan pecahan desimal 1 angka di belakang koma)"
                                /></label>
                            </div>
                            <b-form-input
                                id="input_hellman_13_14"
                                v-on:keyup="checkValidate(formatPersepuluhan($event), 'hellman_13_14')"
                                v-bind:class="{ rangecheck: RC.hellman_13_14.status }"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_13_14.$model"
                                :state="validateState($v.hellman_13_14)"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_13_14.status" target="input_hellman_13_14" triggers="hover">{{ RC.hellman_13_14.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_13_14.status" target="input_hellman_13_14" triggers="hover">{{ CC.hellman_13_14.message }}</b-tooltip>
                        </b-col>

                        <b-col sm="1">
                            <div>
                                <label for="input_hellman_14_15" class="label-entry"
                                    >14-15
                                    <feather-icon
                                        icon="HelpCircleIcon"
                                        size="12"
                                        class="bg-primary rounded-circle text-white"
                                        v-b-tooltip.hover.v-dark
                                        title="Jumlah curah hujan pada pukul 14 - 15 waktu setempat dalam satuan milimeter (bisa diisi dengan pecahan desimal 1 angka di belakang koma)"
                                /></label>
                            </div>
                            <b-form-input
                                id="input_hellman_14_15"
                                v-on:keyup="checkValidate(formatPersepuluhan($event), 'hellman_14_15')"
                                v-bind:class="{ rangecheck: RC.hellman_14_15.status }"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_14_15.$model"
                                :state="validateState($v.hellman_14_15)"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_14_15.status" target="input_hellman_14_15" triggers="hover">{{ RC.hellman_14_15.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_14_15.status" target="input_hellman_14_15" triggers="hover">{{ CC.hellman_14_15.message }}</b-tooltip>
                        </b-col>

                        <b-col sm="1">
                            <div>
                                <label for="input_hellman_15_16" class="label-entry"
                                    >15-16
                                    <feather-icon
                                        icon="HelpCircleIcon"
                                        size="12"
                                        class="bg-primary rounded-circle text-white"
                                        v-b-tooltip.hover.v-dark
                                        title="Jumlah curah hujan pada pukul 15 - 16 waktu setempat dalam satuan milimeter (bisa diisi dengan pecahan desimal 1 angka di belakang koma)"
                                /></label>
                            </div>
                            <b-form-input
                                id="input_hellman_15_16"
                                v-on:keyup="checkValidate(formatPersepuluhan($event), 'hellman_15_16')"
                                v-bind:class="{ rangecheck: RC.hellman_15_16.status }"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_15_16.$model"
                                :state="validateState($v.hellman_15_16)"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_15_16.status" target="input_hellman_15_16" triggers="hover">{{ RC.hellman_15_16.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_15_16.status" target="input_hellman_15_16" triggers="hover">{{ CC.hellman_15_16.message }}</b-tooltip>
                        </b-col>

                        <b-col sm="1">
                            <div>
                                <label for="input_hellman_16_17" class="label-entry"
                                    >16-17
                                    <feather-icon
                                        icon="HelpCircleIcon"
                                        size="12"
                                        class="bg-primary rounded-circle text-white"
                                        v-b-tooltip.hover.v-dark
                                        title="Jumlah curah hujan pada pukul 16 - 17 waktu setempat dalam satuan milimeter (bisa diisi dengan pecahan desimal 1 angka di belakang koma)"
                                /></label>
                            </div>
                            <b-form-input
                                id="input_hellman_16_17"
                                v-on:keyup="checkValidate(formatPersepuluhan($event), 'hellman_16_17')"
                                v-bind:class="{ rangecheck: RC.hellman_16_17.status }"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_16_17.$model"
                                :state="validateState($v.hellman_16_17)"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_16_17.status" target="input_hellman_16_17" triggers="hover">{{ RC.hellman_16_17.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_16_17.status" target="input_hellman_16_17" triggers="hover">{{ CC.hellman_16_17.message }}</b-tooltip>
                        </b-col>

                        <b-col sm="1">
                            <div>
                                <label for="input_hellman_17_18" class="label-entry"
                                    >17-18
                                    <feather-icon
                                        icon="HelpCircleIcon"
                                        size="12"
                                        class="bg-primary rounded-circle text-white"
                                        v-b-tooltip.hover.v-dark
                                        title="Jumlah curah hujan pada pukul 17 - 18 waktu setempat dalam satuan milimeter (bisa diisi dengan pecahan desimal 1 angka di belakang koma)"
                                /></label>
                            </div>
                            <b-form-input
                                id="input_hellman_17_18"
                                v-on:keyup="checkValidate(formatPersepuluhan($event), 'hellman_17_18')"
                                v-bind:class="{ rangecheck: RC.hellman_17_18.status }"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_17_18.$model"
                                :state="validateState($v.hellman_17_18)"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_17_18.status" target="input_hellman_17_18" triggers="hover">{{ RC.hellman_17_18.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_17_18.status" target="input_hellman_17_18" triggers="hover">{{ CC.hellman_17_18.message }}</b-tooltip>
                        </b-col>

                        <b-col sm="1">
                            <div>
                                <label for="input_hellman_18_19" class="label-entry"
                                    >18-19
                                    <feather-icon
                                        icon="HelpCircleIcon"
                                        size="12"
                                        class="bg-primary rounded-circle text-white"
                                        v-b-tooltip.hover.v-dark
                                        title="Jumlah curah hujan pada pukul 18 - 19 waktu setempat dalam satuan milimeter (bisa diisi dengan pecahan desimal 1 angka di belakang koma)"
                                /></label>
                            </div>
                            <b-form-input
                                id="input_hellman_18_19"
                                v-on:keyup="checkValidate(formatPersepuluhan($event), 'hellman_18_19')"
                                v-bind:class="{ rangecheck: RC.hellman_18_19.status }"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_18_19.$model"
                                :state="validateState($v.hellman_18_19)"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_18_19.status" target="input_hellman_18_19" triggers="hover">{{ RC.hellman_18_19.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_18_19.status" target="input_hellman_18_19" triggers="hover">{{ CC.hellman_18_19.message }}</b-tooltip>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col sm="1">
                            <div>
                                <label for="input_hellman_19_20" class="label-entry"
                                    >19-20
                                    <feather-icon
                                        icon="HelpCircleIcon"
                                        size="12"
                                        class="bg-primary rounded-circle text-white"
                                        v-b-tooltip.hover.v-dark
                                        title="Jumlah curah hujan pada pukul 19 - 20 waktu setempat dalam satuan milimeter (bisa diisi dengan pecahan desimal 1 angka di belakang koma)"
                                /></label>
                            </div>
                            <b-form-input
                                id="input_hellman_19_20"
                                v-on:keyup="checkValidate(formatPersepuluhan($event), 'hellman_19_20')"
                                v-bind:class="{ rangecheck: RC.hellman_19_20.status }"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_19_20.$model"
                                :state="validateState($v.hellman_19_20)"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_19_20.status" target="input_hellman_19_20" triggers="hover">{{ RC.hellman_19_20.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_19_20.status" target="input_hellman_19_20" triggers="hover">{{ CC.hellman_19_20.message }}</b-tooltip>

                            <!-- <b-tooltip :show="validateTooltipState('hellman_19_20')" :triggers="'manual'" target="input_hellman_19_20" placement="top" custom-class="validation-tooltip">
                {{ tooltipMessage("hellman_19_20") }}
              </b-tooltip> -->
                        </b-col>

                        <b-col sm="1">
                            <div>
                                <label for="input_hellman_20_21" class="label-entry"
                                    >20-21
                                    <feather-icon
                                        icon="HelpCircleIcon"
                                        size="12"
                                        class="bg-primary rounded-circle text-white"
                                        v-b-tooltip.hover.v-dark
                                        title="Jumlah curah hujan pada pukul 20 - 21 waktu setempat dalam satuan milimeter (bisa diisi dengan pecahan desimal 1 angka di belakang koma)"
                                /></label>
                            </div>
                            <b-form-input
                                id="input_hellman_20_21"
                                v-on:keyup="checkValidate(formatPersepuluhan($event), 'hellman_20_21')"
                                v-bind:class="{ rangecheck: RC.hellman_20_21.status }"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_20_21.$model"
                                :state="validateState($v.hellman_20_21)"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_20_21.status" target="input_hellman_20_21" triggers="hover">{{ RC.hellman_20_21.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_20_21.status" target="input_hellman_20_21" triggers="hover">{{ CC.hellman_20_21.message }}</b-tooltip>
                        </b-col>

                        <b-col sm="1">
                            <div>
                                <label for="input_hellman_21_22" class="label-entry"
                                    >21-22
                                    <feather-icon
                                        icon="HelpCircleIcon"
                                        size="12"
                                        class="bg-primary rounded-circle text-white"
                                        v-b-tooltip.hover.v-dark
                                        title="Jumlah curah hujan pada pukul 21 - 22 waktu setempat dalam satuan milimeter (bisa diisi dengan pecahan desimal 1 angka di belakang koma)"
                                /></label>
                            </div>
                            <b-form-input
                                id="input_hellman_21_22"
                                v-on:keyup="checkValidate(formatPersepuluhan($event), 'hellman_21_22')"
                                v-bind:class="{ rangecheck: RC.hellman_21_22.status }"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_21_22.$model"
                                :state="validateState($v.hellman_21_22)"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_21_22.status" target="input_hellman_21_22" triggers="hover">{{ RC.hellman_21_22.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_21_22.status" target="input_hellman_21_22" triggers="hover">{{ CC.hellman_21_22.message }}</b-tooltip>
                        </b-col>

                        <b-col sm="1">
                            <div>
                                <label for="input_hellman_22_23" class="label-entry"
                                    >22-23
                                    <feather-icon
                                        icon="HelpCircleIcon"
                                        size="12"
                                        class="bg-primary rounded-circle text-white"
                                        v-b-tooltip.hover.v-dark
                                        title="Jumlah curah hujan pada pukul 22 - 23 waktu setempat dalam satuan milimeter (bisa diisi dengan pecahan desimal 1 angka di belakang koma)"
                                /></label>
                            </div>
                            <b-form-input
                                id="input_hellman_22_23"
                                v-on:keyup="checkValidate(formatPersepuluhan($event), 'hellman_22_23')"
                                v-bind:class="{ rangecheck: RC.hellman_22_23.status }"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_22_23.$model"
                                :state="validateState($v.hellman_22_23)"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_22_23.status" target="input_hellman_22_23" triggers="hover">{{ RC.hellman_22_23.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_22_23.status" target="input_hellman_22_23" triggers="hover">{{ CC.hellman_22_23.message }}</b-tooltip>
                        </b-col>

                        <b-col sm="1">
                            <div>
                                <label for="input_hellman_23_24" class="label-entry"
                                    >23-24
                                    <feather-icon
                                        icon="HelpCircleIcon"
                                        size="12"
                                        class="bg-primary rounded-circle text-white"
                                        v-b-tooltip.hover.v-dark
                                        title="Jumlah curah hujan pada pukul 23 - 24 waktu setempat dalam satuan milimeter (bisa diisi dengan pecahan desimal 1 angka di belakang koma)"
                                /></label>
                            </div>
                            <b-form-input
                                id="input_hellman_23_24"
                                v-on:keyup="checkValidate(formatPersepuluhan($event), 'hellman_23_24')"
                                v-bind:class="{ rangecheck: RC.hellman_23_24.status }"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_23_24.$model"
                                :state="validateState($v.hellman_23_24)"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_23_24.status" target="input_hellman_23_24" triggers="hover">{{ RC.hellman_23_24.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_23_24.status" target="input_hellman_23_24" triggers="hover">{{ CC.hellman_23_24.message }}</b-tooltip>
                        </b-col>

                        <b-col sm="1">
                            <div>
                                <label for="input_hellman_24_01" class="label-entry"
                                    >24-01
                                    <feather-icon
                                        icon="HelpCircleIcon"
                                        size="12"
                                        class="bg-primary rounded-circle text-white"
                                        v-b-tooltip.hover.v-dark
                                        title="Jumlah curah hujan pada pukul 24 - 01 waktu setempat dalam satuan milimeter (bisa diisi dengan pecahan desimal 1 angka di belakang koma)"
                                /></label>
                            </div>
                            <b-form-input
                                id="input_hellman_24_01"
                                v-on:keyup="checkValidate(formatPersepuluhan($event), 'hellman_24_01')"
                                v-bind:class="{ rangecheck: RC.hellman_24_01.status }"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_24_01.$model"
                                :state="validateState($v.hellman_24_01)"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_24_01.status" target="input_hellman_24_01" triggers="hover">{{ RC.hellman_24_01.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_24_01.status" target="input_hellman_24_01" triggers="hover">{{ CC.hellman_24_01.message }}</b-tooltip>
                        </b-col>

                        <b-col sm="1">
                            <div>
                                <label for="input_hellman_01_02" class="label-entry"
                                    >01-02
                                    <feather-icon
                                        icon="HelpCircleIcon"
                                        size="12"
                                        class="bg-primary rounded-circle text-white"
                                        v-b-tooltip.hover.v-dark
                                        title="Jumlah curah hujan pada pukul 1 - 2 waktu setempat dalam satuan milimeter (bisa diisi dengan pecahan desimal 1 angka di belakang koma)"
                                /></label>
                            </div>
                            <b-form-input
                                id="input_hellman_01_02"
                                v-on:keyup="checkValidate(formatPersepuluhan($event), 'hellman_01_02')"
                                v-bind:class="{ rangecheck: RC.hellman_01_02.status }"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_01_02.$model"
                                :state="validateState($v.hellman_01_02)"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_01_02.status" target="input_hellman_01_02" triggers="hover">{{ RC.hellman_01_02.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_01_02.status" target="input_hellman_01_02" triggers="hover">{{ CC.hellman_01_02.message }}</b-tooltip>
                        </b-col>

                        <b-col sm="1">
                            <div>
                                <label for="input_hellman_02_03" class="label-entry"
                                    >02-03
                                    <feather-icon
                                        icon="HelpCircleIcon"
                                        size="12"
                                        class="bg-primary rounded-circle text-white"
                                        v-b-tooltip.hover.v-dark
                                        title="Jumlah curah hujan pada pukul 2 - 3 waktu setempat dalam satuan milimeter (bisa diisi dengan pecahan desimal 1 angka di belakang koma)"
                                /></label>
                            </div>
                            <b-form-input
                                id="input_hellman_02_03"
                                v-on:keyup="checkValidate(formatPersepuluhan($event), 'hellman_02_03')"
                                v-bind:class="{ rangecheck: RC.hellman_02_03.status }"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_02_03.$model"
                                :state="validateState($v.hellman_02_03)"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_02_03.status" target="input_hellman_02_03" triggers="hover">{{ RC.hellman_02_03.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_02_03.status" target="input_hellman_02_03" triggers="hover">{{ CC.hellman_02_03.message }}</b-tooltip>
                        </b-col>

                        <b-col sm="1">
                            <div>
                                <label for="input_hellman_03_04" class="label-entry"
                                    >03-04
                                    <feather-icon
                                        icon="HelpCircleIcon"
                                        size="12"
                                        class="bg-primary rounded-circle text-white"
                                        v-b-tooltip.hover.v-dark
                                        title="Jumlah curah hujan pada pukul 3 - 4 waktu setempat dalam satuan milimeter (bisa diisi dengan pecahan desimal 1 angka di belakang koma)"
                                /></label>
                            </div>
                            <b-form-input
                                id="input_hellman_03_04"
                                v-on:keyup="checkValidate(formatPersepuluhan($event), 'hellman_03_04')"
                                v-bind:class="{ rangecheck: RC.hellman_03_04.status }"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_03_04.$model"
                                :state="validateState($v.hellman_03_04)"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_03_04.status" target="input_hellman_03_04" triggers="hover">{{ RC.hellman_03_04.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_03_04.status" target="input_hellman_03_04" triggers="hover">{{ CC.hellman_03_04.message }}</b-tooltip>
                        </b-col>

                        <b-col sm="1">
                            <div>
                                <label for="input_hellman_04_05" class="label-entry"
                                    >04-05
                                    <feather-icon
                                        icon="HelpCircleIcon"
                                        size="12"
                                        class="bg-primary rounded-circle text-white"
                                        v-b-tooltip.hover.v-dark
                                        title="Jumlah curah hujan pada pukul 4 - 5 waktu setempat dalam satuan milimeter (bisa diisi dengan pecahan desimal 1 angka di belakang koma)"
                                /></label>
                            </div>
                            <b-form-input
                                id="input_hellman_04_05"
                                v-on:keyup="checkValidate(formatPersepuluhan($event), 'hellman_04_05')"
                                v-bind:class="{ rangecheck: RC.hellman_04_05.status }"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_04_05.$model"
                                :state="validateState($v.hellman_04_05)"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_04_05.status" target="input_hellman_04_05" triggers="hover">{{ RC.hellman_04_05.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_04_05.status" target="input_hellman_04_05" triggers="hover">{{ CC.hellman_04_05.message }}</b-tooltip>
                        </b-col>

                        <b-col sm="1">
                            <div>
                                <label for="input_hellman_05_06" class="label-entry"
                                    >05-06
                                    <feather-icon
                                        icon="HelpCircleIcon"
                                        size="12"
                                        class="bg-primary rounded-circle text-white"
                                        v-b-tooltip.hover.v-dark
                                        title="Jumlah curah hujan pada pukul 5 - 6 waktu setempat dalam satuan milimeter (bisa diisi dengan pecahan desimal 1 angka di belakang koma)"
                                /></label>
                            </div>
                            <b-form-input
                                id="input_hellman_05_06"
                                v-on:keyup="checkValidate(formatPersepuluhan($event), 'hellman_05_06')"
                                v-bind:class="{ rangecheck: RC.hellman_05_06.status }"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_05_06.$model"
                                :state="validateState($v.hellman_05_06)"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_05_06.status" target="input_hellman_05_06" triggers="hover">{{ RC.hellman_05_06.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_05_06.status" target="input_hellman_05_06" triggers="hover">{{ CC.hellman_05_06.message }}</b-tooltip>
                        </b-col>

                        <b-col sm="1">
                            <div>
                                <label for="input_hellman_06_07" class="label-entry"
                                    >06-07
                                    <feather-icon
                                        icon="HelpCircleIcon"
                                        size="12"
                                        class="bg-primary rounded-circle text-white"
                                        v-b-tooltip.hover.v-dark
                                        title="Jumlah curah hujan pada pukul 6 - 7 waktu setempat dalam satuan milimeter (bisa diisi dengan pecahan desimal 1 angka di belakang koma)"
                                /></label>
                            </div>
                            <b-form-input
                                id="input_hellman_06_07"
                                v-on:keyup="checkValidate(formatPersepuluhan($event), 'hellman_06_07')"
                                v-bind:class="{ rangecheck: RC.hellman_06_07.status }"
                                class="text-center"
                                size="sm"
                                type="number"
                                v-on:keydown="onKeyCheck($event)"
                                v-model.number="$v.hellman_06_07.$model"
                                :state="validateState($v.hellman_06_07)"
                            ></b-form-input>
                            <b-tooltip v-if="RC.hellman_06_07.status" target="input_hellman_06_07" triggers="hover">{{ RC.hellman_06_07.message }}</b-tooltip>
                            <b-tooltip v-if="CC.hellman_06_07.status" target="input_hellman_06_07" triggers="hover">{{ CC.hellman_06_07.message }}</b-tooltip>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        <b-row class="mt-n2">
            <b-col cols="12">
                <b-card>
                    <b-row class="float-right">
                        <div>
                            <b-button variant="danger" class="mr-1" size="sm" @click="resetForm()">Batal</b-button>
                            <b-button variant="warning" class="mr-1" size="sm" @click="V_Form" v-if="this.t_data_status == 'update'" :disabled="!roles.isRoleEdit">Update</b-button>
                            <b-button variant="primary" class="mr-1" size="sm" @click="V_Form" v-if="this.t_data_status == 'insert'" :disabled="!roles.isRoleAdd">Submit</b-button>
                        </div>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { BRow, BCol, BTable, BCardText, BFormInput, BContainer, BButton, BCard, VBTooltip, BTooltip } from "bootstrap-vue";
import axios from "axios";
import Helper from "@/api/helper";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import error_handler from "@/api/error_handler";
import { validationMixin } from "vuelidate";
import { required, minLength, between } from "vuelidate/lib/validators";
import { RC_PIAS, V_IntensitasHujan, GC_PIAS } from "@/validators/piasvalidator";
import Ripple from "vue-ripple-directive";
import metadata from "@/api/metadata";
import Params from "@/attr/metadata/params";
import RC_helper from "@/validators/RC_helper";
import qc_helper from "@/api/qc_helper";

export default {
    components: {
        BCardActions,
        BRow,
        BCol,
        BTable,
        BCardText,
        BFormInput,
        BContainer,
        BButton,
        BCard,
        VBTooltip,
        BTooltip,
    },
    directives: {
        "b-tooltip": VBTooltip,
        Ripple,
    },
    props: ["t_station", "t_wmoid", "t_observer", "t_observer_name", "t_date", "t_station_id", "t_path", "t_data", "t_id_form", "t_data_status", "t_path_update", "roles"],
    mixins: [validationMixin],
    validations() {
        return {
            hellman_5m: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_5m.status },
            hellman_10m: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_10m.status },
            hellman_15m: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_15m.status },
            hellman_30m: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_30m.status },
            hellman_45m: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_45m.status },
            hellman_60m: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_60m.status },
            hellman_120m: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_120m.status },
            hellman_3h: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_3h.status },
            hellman_6h: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_6h.status },
            hellman_12h: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_12h.status },
            hellman_07_08: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_07_08.status },
            hellman_08_09: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_08_09.status },
            hellman_09_10: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_09_10.status },
            hellman_10_11: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_10_11.status },
            hellman_11_12: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_11_12.status },
            hellman_12_13: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_12_13.status },
            hellman_13_14: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_13_14.status },
            hellman_14_15: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_14_15.status },
            hellman_15_16: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_15_16.status },
            hellman_16_17: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_16_17.status },
            hellman_17_18: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_17_18.status },
            hellman_18_19: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_18_19.status },
            hellman_19_20: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_19_20.status },
            hellman_20_21: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_20_21.status },
            hellman_21_22: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_21_22.status },
            hellman_22_23: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_22_23.status },
            hellman_23_24: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_23_24.status },
            hellman_24_01: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_24_01.status },
            hellman_01_02: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_01_02.status },
            hellman_02_03: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_02_03.status },
            hellman_03_04: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_03_04.status },
            hellman_04_05: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_04_05.status },
            hellman_05_06: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_05_06.status },
            hellman_06_07: { required, V_IntensitasHujan, consistency: (val) => !this.CC.hellman_06_07.status },
        };
    },
    data() {
        return {
            last_qc_histories: null,
            RC_helper: RC_helper,
            hellman_5m: "",
            hellman_10m: "",
            hellman_15m: "",
            hellman_30m: "",
            hellman_45m: "",
            hellman_60m: "",
            hellman_120m: "",
            hellman_3h: "",
            hellman_6h: "",
            hellman_12h: "",
            hellman_07_08: "",
            hellman_08_09: "",
            hellman_09_10: "",
            hellman_10_11: "",
            hellman_11_12: "",
            hellman_12_13: "",
            hellman_13_14: "",
            hellman_14_15: "",
            hellman_15_16: "",
            hellman_16_17: "",
            hellman_17_18: "",
            hellman_18_19: "",
            hellman_19_20: "",
            hellman_20_21: "",
            hellman_21_22: "",
            hellman_22_23: "",
            hellman_23_24: "",
            hellman_24_01: "",
            hellman_01_02: "",
            hellman_02_03: "",
            hellman_03_04: "",
            hellman_04_05: "",
            hellman_05_06: "",
            hellman_06_07: "",
            RC: {
                hellman_5m: { status: false, message: "" },
                hellman_10m: { status: false, message: "" },
                hellman_15m: { status: false, message: "" },
                hellman_30m: { status: false, message: "" },
                hellman_45m: { status: false, message: "" },
                hellman_60m: { status: false, message: "" },
                hellman_120m: { status: false, message: "" },
                hellman_3h: { status: false, message: "" },
                hellman_6h: { status: false, message: "" },
                hellman_12h: { status: false, message: "" },
                hellman_07_08: { status: false, message: "" },
                hellman_08_09: { status: false, message: "" },
                hellman_09_10: { status: false, message: "" },
                hellman_10_11: { status: false, message: "" },
                hellman_11_12: { status: false, message: "" },
                hellman_12_13: { status: false, message: "" },
                hellman_13_14: { status: false, message: "" },
                hellman_14_15: { status: false, message: "" },
                hellman_15_16: { status: false, message: "" },
                hellman_16_17: { status: false, message: "" },
                hellman_17_18: { status: false, message: "" },
                hellman_18_19: { status: false, message: "" },
                hellman_19_20: { status: false, message: "" },
                hellman_20_21: { status: false, message: "" },
                hellman_21_22: { status: false, message: "" },
                hellman_22_23: { status: false, message: "" },
                hellman_23_24: { status: false, message: "" },
                hellman_24_01: { status: false, message: "" },
                hellman_01_02: { status: false, message: "" },
                hellman_02_03: { status: false, message: "" },
                hellman_03_04: { status: false, message: "" },
                hellman_04_05: { status: false, message: "" },
                hellman_05_06: { status: false, message: "" },
                hellman_06_07: { status: false, message: "" },
            },
            CC: {
                hellman_5m: { status: false, message: "" },
                hellman_10m: { status: false, message: "" },
                hellman_15m: { status: false, message: "" },
                hellman_30m: { status: false, message: "" },
                hellman_45m: { status: false, message: "" },
                hellman_60m: { status: false, message: "" },
                hellman_120m: { status: false, message: "" },
                hellman_3h: { status: false, message: "" },
                hellman_6h: { status: false, message: "" },
                hellman_12h: { status: false, message: "" },
                hellman_07_08: { status: false, message: "" },
                hellman_08_09: { status: false, message: "" },
                hellman_09_10: { status: false, message: "" },
                hellman_10_11: { status: false, message: "" },
                hellman_11_12: { status: false, message: "" },
                hellman_12_13: { status: false, message: "" },
                hellman_13_14: { status: false, message: "" },
                hellman_14_15: { status: false, message: "" },
                hellman_15_16: { status: false, message: "" },
                hellman_16_17: { status: false, message: "" },
                hellman_17_18: { status: false, message: "" },
                hellman_18_19: { status: false, message: "" },
                hellman_19_20: { status: false, message: "" },
                hellman_20_21: { status: false, message: "" },
                hellman_21_22: { status: false, message: "" },
                hellman_22_23: { status: false, message: "" },
                hellman_23_24: { status: false, message: "" },
                hellman_24_01: { status: false, message: "" },
                hellman_01_02: { status: false, message: "" },
                hellman_02_03: { status: false, message: "" },
                hellman_03_04: { status: false, message: "" },
                hellman_04_05: { status: false, message: "" },
                hellman_05_06: { status: false, message: "" },
                hellman_06_07: { status: false, message: "" },
            },
        };
    },
    mounted() {
        if (this.t_station) {
            this.setQCData();
        }
        if (this.t_data_status == "update") {
            this.popupData();
        } else {
            this.resetForm();
        }
    },
    watch: {
        t_station(value) {
            console.log("ubah station : ", this.t_path);
        },
        t_data_status(newValue, OldValue) {
            if (newValue == "update") {
                this.popupData();
            } else {
                this.resetForm();
            }
        },
        hellman_5m() {
            this.validasiPerMenit();
        },
        hellman_10m() {
            this.validasiPerMenit();
        },
        hellman_15m() {
            this.validasiPerMenit();
        },
        hellman_30m() {
            this.validasiPerMenit();
        },
        hellman_45m() {
            this.validasiPerMenit();
        },
        hellman_120m() {
            this.validasiPerMenit();
        },
        hellman_3h() {
            this.validasiPerMenit();
        },
        hellman_6h() {
            this.validasiPerMenit();
        },
        hellman_12h() {
            this.validasiPerMenit();
        },
        hellman_60m() {
            this.validasiPerMenit();
            this.validasiPerJam();
        },
    },
    computed: {
        total_12_1: function () {
            // 12 JAM BERTURUT-TURUT TOTALNYA HARUS 12H
            let total = parseFloat(
                this.hellman_07_08 +
                    this.hellman_08_09 +
                    this.hellman_09_10 +
                    this.hellman_10_11 +
                    this.hellman_11_12 +
                    this.hellman_12_13 +
                    this.hellman_13_14 +
                    this.hellman_14_15 +
                    this.hellman_15_16 +
                    this.hellman_16_17 +
                    this.hellman_17_18 +
                    this.hellman_18_19
                // +
                // this.hellman_19_20 +
                // this.hellman_20_21 +
                // this.hellman_21_22 +
                // this.hellman_22_23 +
                // this.hellman_23_24 +
                // this.hellman_24_01 +
                // this.hellman_01_02 +
                // this.hellman_02_03 +
                // this.hellman_03_04 +
                // this.hellman_04_05 +
                // this.hellman_05_06 +
                // this.hellman_06_07
            );
            return total.toFixed(1);
        },
        total_12_2: function () {
            // 12 JAM BERTURUT-TURUT TOTALNYA HARUS 12H
            let total = parseFloat(
                this.hellman_08_09 +
                    this.hellman_09_10 +
                    this.hellman_10_11 +
                    this.hellman_11_12 +
                    this.hellman_12_13 +
                    this.hellman_13_14 +
                    this.hellman_14_15 +
                    this.hellman_15_16 +
                    this.hellman_16_17 +
                    this.hellman_17_18 +
                    this.hellman_18_19 +
                    this.hellman_19_20
            );
            return total.toFixed(1);
        },
        total_12_3: function () {
            // 12 JAM BERTURUT-TURUT TOTALNYA HARUS 12H
            let total = parseFloat(
                this.hellman_09_10 +
                    this.hellman_10_11 +
                    this.hellman_11_12 +
                    this.hellman_12_13 +
                    this.hellman_13_14 +
                    this.hellman_14_15 +
                    this.hellman_15_16 +
                    this.hellman_16_17 +
                    this.hellman_17_18 +
                    this.hellman_18_19 +
                    this.hellman_19_20 +
                    this.hellman_20_21
            );
            return total.toFixed(1);
        },
        total_12_4: function () {
            // 12 JAM BERTURUT-TURUT TOTALNYA HARUS 12H
            let total = parseFloat(
                this.hellman_10_11 +
                    this.hellman_11_12 +
                    this.hellman_12_13 +
                    this.hellman_13_14 +
                    this.hellman_14_15 +
                    this.hellman_15_16 +
                    this.hellman_16_17 +
                    this.hellman_17_18 +
                    this.hellman_18_19 +
                    this.hellman_19_20 +
                    this.hellman_20_21 +
                    this.hellman_21_22
            );
            return total.toFixed(1);
        },
        total_12_5: function () {
            // 12 JAM BERTURUT-TURUT TOTALNYA HARUS 12H
            let total = parseFloat(
                this.hellman_11_12 +
                    this.hellman_12_13 +
                    this.hellman_13_14 +
                    this.hellman_14_15 +
                    this.hellman_15_16 +
                    this.hellman_16_17 +
                    this.hellman_17_18 +
                    this.hellman_18_19 +
                    this.hellman_19_20 +
                    this.hellman_20_21 +
                    this.hellman_21_22 +
                    this.hellman_22_23
            );
            return total.toFixed(1);
        },
        total_12_6: function () {
            // 12 JAM BERTURUT-TURUT TOTALNYA HARUS 12H
            let total = parseFloat(
                this.hellman_12_13 +
                    this.hellman_13_14 +
                    this.hellman_14_15 +
                    this.hellman_15_16 +
                    this.hellman_16_17 +
                    this.hellman_17_18 +
                    this.hellman_18_19 +
                    this.hellman_19_20 +
                    this.hellman_20_21 +
                    this.hellman_21_22 +
                    this.hellman_22_23 +
                    this.hellman_23_24
            );
            return total.toFixed(1);
        },
        total_12_7: function () {
            // 12 JAM BERTURUT-TURUT TOTALNYA HARUS 12H
            let total = parseFloat(
                this.hellman_13_14 +
                    this.hellman_14_15 +
                    this.hellman_15_16 +
                    this.hellman_16_17 +
                    this.hellman_17_18 +
                    this.hellman_18_19 +
                    this.hellman_19_20 +
                    this.hellman_20_21 +
                    this.hellman_21_22 +
                    this.hellman_22_23 +
                    this.hellman_23_24 +
                    this.hellman_24_01
            );
            return total.toFixed(1);
        },
        total_12_8: function () {
            // 12 JAM BERTURUT-TURUT TOTALNYA HARUS 12H
            let total = parseFloat(
                this.hellman_14_15 +
                    this.hellman_15_16 +
                    this.hellman_16_17 +
                    this.hellman_17_18 +
                    this.hellman_18_19 +
                    this.hellman_19_20 +
                    this.hellman_20_21 +
                    this.hellman_21_22 +
                    this.hellman_22_23 +
                    this.hellman_23_24 +
                    this.hellman_24_01 +
                    this.hellman_01_02
            );
            return total.toFixed(1);
        },
        total_12_9: function () {
            // 12 JAM BERTURUT-TURUT TOTALNYA HARUS 12H
            let total = parseFloat(
                this.hellman_15_16 +
                    this.hellman_16_17 +
                    this.hellman_17_18 +
                    this.hellman_18_19 +
                    this.hellman_19_20 +
                    this.hellman_20_21 +
                    this.hellman_21_22 +
                    this.hellman_22_23 +
                    this.hellman_23_24 +
                    this.hellman_24_01 +
                    this.hellman_01_02 +
                    this.hellman_02_03
            );
            return total.toFixed(1);
        },
        total_12_10: function () {
            // 12 JAM BERTURUT-TURUT TOTALNYA HARUS 12H
            let total = parseFloat(
                this.hellman_16_17 +
                    this.hellman_17_18 +
                    this.hellman_18_19 +
                    this.hellman_19_20 +
                    this.hellman_20_21 +
                    this.hellman_21_22 +
                    this.hellman_22_23 +
                    this.hellman_23_24 +
                    this.hellman_24_01 +
                    this.hellman_01_02 +
                    this.hellman_02_03 +
                    this.hellman_03_04
            );
            return total.toFixed(1);
        },
        total_12_11: function () {
            // 12 JAM BERTURUT-TURUT TOTALNYA HARUS 12H
            let total = parseFloat(
                this.hellman_17_18 +
                    this.hellman_18_19 +
                    this.hellman_19_20 +
                    this.hellman_20_21 +
                    this.hellman_21_22 +
                    this.hellman_22_23 +
                    this.hellman_23_24 +
                    this.hellman_24_01 +
                    this.hellman_01_02 +
                    this.hellman_02_03 +
                    this.hellman_03_04 +
                    this.hellman_04_05
            );
            return total.toFixed(1);
        },
        total_12_12: function () {
            // 12 JAM BERTURUT-TURUT TOTALNYA HARUS 12H
            let total = parseFloat(
                this.hellman_18_19 +
                    this.hellman_19_20 +
                    this.hellman_20_21 +
                    this.hellman_21_22 +
                    this.hellman_22_23 +
                    this.hellman_23_24 +
                    this.hellman_24_01 +
                    this.hellman_01_02 +
                    this.hellman_02_03 +
                    this.hellman_03_04 +
                    this.hellman_04_05 +
                    this.hellman_05_06
            );
            return total.toFixed(1);
        },
        total_12_13: function () {
            // 12 JAM BERTURUT-TURUT TOTALNYA HARUS 12H
            let total = parseFloat(
                this.hellman_19_20 +
                    this.hellman_20_21 +
                    this.hellman_21_22 +
                    this.hellman_22_23 +
                    this.hellman_23_24 +
                    this.hellman_24_01 +
                    this.hellman_01_02 +
                    this.hellman_02_03 +
                    this.hellman_03_04 +
                    this.hellman_04_05 +
                    this.hellman_05_06 +
                    this.hellman_06_07
            );
            return total.toFixed(1);
        },
    },
    methods: {
        onKeyCheck(event) {
            console.log("onKeyCheck", event);
            if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                event.preventDefault();
            }
        },
        async setQCData() {
            const qc_data = await metadata.getQCData(this.t_station.path, Params.GET_QC_STATION_KEY("hujan_helman"));
            const qc_params = Params.GET_PARAMS("hujan_helman");
            const qc_key = Params.GET_KEY("hujan_helman");
            this.RC_helper.setData(qc_data, qc_params, qc_key);
        },
        formatPersepuluhan(event) {
            if (!/^\d+\.?\d?$/.test(event.target.value)) {
                const rounded = Math.round(event.target.value * 10) / 10;
                if (rounded) event.target.value = rounded;
            }
            return event;
        },
        validasiPerMenit() {
            this.checkValidate({ target: { value: this.hellman_5m } }, "hellman_5m");
            this.checkValidate({ target: { value: this.hellman_10m } }, "hellman_10m");
            this.checkValidate({ target: { value: this.hellman_15m } }, "hellman_15m");
            this.checkValidate({ target: { value: this.hellman_30m } }, "hellman_30m");
            this.checkValidate({ target: { value: this.hellman_45m } }, "hellman_45m");
            this.checkValidate({ target: { value: this.hellman_60m } }, "hellman_60m");
            this.checkValidate({ target: { value: this.hellman_120m } }, "hellman_120m");
            this.checkValidate({ target: { value: this.hellman_3h } }, "hellman_3h");
            this.checkValidate({ target: { value: this.hellman_6h } }, "hellman_6h");
            this.checkValidate({ target: { value: this.hellman_12h } }, "hellman_12h");
        },
        validasiPerJam() {
            this.checkValidate({ target: { value: this.hellman_07_08 } }, "hellman_07_08");
            this.checkValidate({ target: { value: this.hellman_08_09 } }, "hellman_08_09");
            this.checkValidate({ target: { value: this.hellman_09_10 } }, "hellman_09_10");
            this.checkValidate({ target: { value: this.hellman_10_11 } }, "hellman_10_11");
            this.checkValidate({ target: { value: this.hellman_11_12 } }, "hellman_11_12");
            this.checkValidate({ target: { value: this.hellman_12_13 } }, "hellman_12_13");
            this.checkValidate({ target: { value: this.hellman_13_14 } }, "hellman_13_14");
            this.checkValidate({ target: { value: this.hellman_14_15 } }, "hellman_14_15");
            this.checkValidate({ target: { value: this.hellman_15_16 } }, "hellman_15_16");
            this.checkValidate({ target: { value: this.hellman_16_17 } }, "hellman_16_17");
            this.checkValidate({ target: { value: this.hellman_17_18 } }, "hellman_17_18");
            this.checkValidate({ target: { value: this.hellman_18_19 } }, "hellman_18_19");
            this.checkValidate({ target: { value: this.hellman_19_20 } }, "hellman_19_20");
            this.checkValidate({ target: { value: this.hellman_20_21 } }, "hellman_20_21");
            this.checkValidate({ target: { value: this.hellman_21_22 } }, "hellman_21_22");
            this.checkValidate({ target: { value: this.hellman_22_23 } }, "hellman_22_23");
            this.checkValidate({ target: { value: this.hellman_23_24 } }, "hellman_23_24");
            this.checkValidate({ target: { value: this.hellman_24_01 } }, "hellman_24_01");
            this.checkValidate({ target: { value: this.hellman_01_02 } }, "hellman_01_02");
            this.checkValidate({ target: { value: this.hellman_02_03 } }, "hellman_02_03");
            this.checkValidate({ target: { value: this.hellman_03_04 } }, "hellman_03_04");
            this.checkValidate({ target: { value: this.hellman_04_05 } }, "hellman_04_05");
            this.checkValidate({ target: { value: this.hellman_05_06 } }, "hellman_05_06");
            this.checkValidate({ target: { value: this.hellman_06_07 } }, "hellman_06_07");
        },
        checkValidate: function (event, type) {
            let value = event.target.value;

            // VALIDASI CONSISTENCY CHECK
            // LD -> check periode yang lebih lama nilainya lebih besar dibanding periode sebelumnya (5' <= 10')
            let ld = false;
            let ld_message = "";

            switch (type) {
                case "hellman_5m":
                case "hellman_10m":
                    if (this.hellman_5m == 9999 || value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_5m <= value);
                    }
                    if (ld) ld_message = "CH 5' <= CH 10'";
                    break;
                case "hellman_15m":
                    if (this.hellman_10m == 9999 || value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_10m <= value);
                    }
                    if (ld) ld_message = "CH 10' <= CH 15'";
                    break;
                case "hellman_30m":
                    if (this.hellman_15m == 9999 || value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_15m <= value);
                    }
                    if (ld) ld_message = "CH 15' <= CH 30'";
                    break;
                case "hellman_45m":
                    if (this.hellman_30m == 9999 || value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_30m <= value);
                    }
                    if (ld) ld_message = "CH 30' <= CH 45'";
                    break;
                case "hellman_60m":
                    if (this.hellman_45m == 9999 || value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_45m <= value);
                    }
                    if (ld) ld_message = "CH 45' <= CH 60'";
                    break;
                case "hellman_120m":
                    if (this.hellman_60m == 9999 || value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m <= value);
                    }
                    if (ld) ld_message = "CH 60' <= CH 120'";
                    break;
                case "hellman_3h":
                    if (this.hellman_120m == 9999 || value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_120m <= value);
                    }
                    if (ld) ld_message = "CH 120' <= CH 3H";
                    break;
                case "hellman_6h":
                    if (this.hellman_3h == 9999 || value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_3h <= value);
                    }
                    if (ld) ld_message = "CH 3H <= CH 6H";
                    break;
                case "hellman_12h":
                    if (this.hellman_6h == 9999 || value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_6h <= value);
                        if (ld) ld_message = "CH 6H <= CH 12H";
                    }

                    break;

                case "hellman_07_08":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_08_09":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_09_10":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_10_11":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_11_12":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_12_13":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_13_14":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_14_15":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_15_16":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_16_17":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_17_18":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_18_19":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_19_20":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_20_21":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_21_22":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_22_23":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_23_24":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_24_01":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_01_02":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_02_03":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_03_04":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_04_05":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_06_07":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_05_06":
                    if (this.hellman_60m == 9999 || value == 9999) {
                        ld = false;
                    } else {
                        ld = !(value <= this.hellman_60m);
                    }
                    if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    this.cekLd12();
                    break;
                default:
                    break;
            }

            // VALIDASI RANGE CHECK
            let raw = this.RC_helper.check(value, type);

            // VALIDASI GROSS CHECK
            let gc = V_IntensitasHujan(value);

            let status = gc && !raw.status;
            let message = raw.message;
            this.RC[type] = { status, message };
            this.CC[type] = {
                status: ld,
                message: ld_message,
            };
        },

        checkValidateUpdate(type, value) {
            // VALIDASI CONSISTENCY CHECK
            // LD -> check periode yang lebih lama nilainya lebih besar dibanding periode sebelumnya (5' <= 10')
            let ld = false;
            let ld_message = "";

            switch (type) {
                case "hellman_5m":
                case "hellman_10m":
                    if (this.hellman_5m == 9999 || value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_5m <= value);
                    }
                    if (ld) ld_message = "CH 5' <= CH 10'";
                    break;
                case "hellman_15m":
                    if (this.hellman_10m == 9999 || value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_10m <= value);
                    }
                    if (ld) ld_message = "CH 10' <= CH 15'";
                    break;
                case "hellman_30m":
                    if (this.hellman_15m == 9999 || value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_15m <= value);
                    }
                    if (ld) ld_message = "CH 15' <= CH 30'";
                    break;
                case "hellman_45m":
                    if (this.hellman_30m == 9999 || value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_30m <= value);
                    }
                    if (ld) ld_message = "CH 30' <= CH 45'";
                    break;
                case "hellman_60m":
                    if (this.hellman_45m == 9999 || value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_45m <= value);
                    }
                    if (ld) ld_message = "CH 45' <= CH 60'";
                    break;
                case "hellman_120m":
                    if (this.hellman_60m == 9999 || value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m <= value);
                    }
                    if (ld) ld_message = "CH 60' <= CH 120'";
                    break;
                case "hellman_3h":
                    if (this.hellman_120m == 9999 || value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_120m <= value);
                    }
                    if (ld) ld_message = "CH 120' <= CH 3H";
                    break;
                case "hellman_6h":
                    if (this.hellman_3h == 9999 || value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_3h <= value);
                    }
                    if (ld) ld_message = "CH 3H <= CH 6H";
                    break;
                case "hellman_12h":
                    if (this.hellman_6h == 9999 || value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_6h <= value);
                        if (ld) ld_message = "CH 6H <= CH 12H";
                    }

                    break;

                case "hellman_07_08":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_08_09":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_09_10":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_10_11":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_11_12":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_12_13":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_13_14":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_14_15":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_15_16":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_16_17":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_17_18":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_18_19":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_19_20":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_20_21":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_21_22":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_22_23":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_23_24":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_24_01":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_01_02":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_02_03":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_03_04":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_04_05":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_06_07":
                    if (value == 9999) {
                        ld = false;
                    } else {
                        ld = !(this.hellman_60m >= value);
                        if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    }
                    this.cekLd12();
                    break;
                case "hellman_05_06":
                    if (this.hellman_60m == 9999 || value == 9999) {
                        ld = false;
                    } else {
                        ld = !(value <= this.hellman_60m);
                    }
                    if (ld) ld_message = "Intensitas  <= CH 60 Menit";
                    this.cekLd12();
                    break;
                default:
                    break;
            }

            // VALIDASI RANGE CHECK
            let raw = this.RC_helper.check(value, type);

            // VALIDASI GROSS CHECK
            let gc = V_IntensitasHujan(value);

            let status = gc && !raw.status;
            let message = raw.message;
            this.RC[type] = { status, message };
            this.CC[type] = {
                status: ld,
                message: ld_message,
            };
        },
        //validate tooltips GC
        tooltipMessage(attr) {
            return GC_PIAS(attr).message;
        },
        validateTooltipState(name) {
            const { $error } = this.$v[name];
            return $error;
        },
        validateState(item) {
            const { $dirty, $error } = item;
            return $dirty ? !$error : null;
        },
        V_Form() {
            this.$v.$touch();
            // console.log("helman vtouce")
            if (this.$v.$invalid) {
                // console.log("helman vtouce 1")
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Data tidak valid. Periksa kembali inputan anda",
                        icon: "BellIcon",
                        variant: "danger",
                    },
                });
                return;
            } else {
                // console.log("helman vtouce ! ")
                if (this.t_data_status == "insert") {
                    this.submitForm();
                } else if (this.t_data_status == "update") {
                    this.updateData();
                }
            }
        },
        cekLd12() {
            let ld = false;
            let ld_message = "";
            // if (this.total_12_1 >= this.hellman_12h) {
            console.log(
                this.hellman_12h,
                " - cekLd12 - ",
                this.total_12_1,
                this.total_12_2,
                this.total_12_3,
                this.total_12_4,
                this.total_12_5,
                this.total_12_6,
                this.total_12_7,
                this.total_12_8,
                this.total_12_9,
                this.total_12_10,
                this.total_12_11,
                this.total_12_12,
                this.total_12_13
            );
            if (
                this.total_12_1 == this.hellman_12h ||
                this.total_12_2 == this.hellman_12h ||
                this.total_12_3 == this.hellman_12h ||
                this.total_12_4 == this.hellman_12h ||
                this.total_12_5 == this.hellman_12h ||
                this.total_12_6 == this.hellman_12h ||
                this.total_12_7 == this.hellman_12h ||
                this.total_12_8 == this.hellman_12h ||
                this.total_12_9 == this.hellman_12h ||
                this.total_12_10 == this.hellman_12h ||
                this.total_12_11 == this.hellman_12h ||
                this.total_12_12 == this.hellman_12h ||
                this.total_12_13 == this.hellman_12h
            ) {
                ld = false;
            } else {
                ld = true;
                if (ld) ld_message = "Total Jumlah Intensitas Per jam >= CH 12H'";
            }

            this.CC["hellman_12h"] = {
                status: ld,
                message: ld_message,
            };

            // if(!this.CC.hellman_12h.status){
            //   this.CC["hellman_12h"] = {
            //     status: ld,
            //     message: ld_message,
            //   }
            // }
        },
        resetForm() {
            this.last_qc_histories = null;

            this.hellman_5m = "";
            this.hellman_10m = "";
            this.hellman_15m = "";
            this.hellman_30m = "";
            this.hellman_45m = "";
            this.hellman_60m = "";
            this.hellman_120m = "";
            this.hellman_3h = "";
            this.hellman_6h = "";
            this.hellman_12h = "";
            this.hellman_07_08 = "";
            this.hellman_08_09 = "";
            this.hellman_09_10 = "";
            this.hellman_10_11 = "";
            this.hellman_11_12 = "";
            this.hellman_12_13 = "";
            this.hellman_13_14 = "";
            this.hellman_14_15 = "";
            this.hellman_15_16 = "";
            this.hellman_16_17 = "";
            this.hellman_17_18 = "";
            this.hellman_18_19 = "";
            this.hellman_19_20 = "";
            this.hellman_20_21 = "";
            this.hellman_21_22 = "";
            this.hellman_22_23 = "";
            this.hellman_23_24 = "";
            this.hellman_24_01 = "";
            this.hellman_01_02 = "";
            this.hellman_02_03 = "";
            this.hellman_03_04 = "";
            this.hellman_04_05 = "";
            this.hellman_05_06 = "";
            this.hellman_06_07 = "";

            this.$v.$reset();
        },
        popupData() {
            if (this.t_data?.qc_histories) {
                this.last_qc_histories = JSON.parse(this.t_data.qc_histories)?.after || null;
            } else {
                this.last_qc_histories = null;
            }

            this.hellman_5m = this.t_data.hellman_5m;

            this.hellman_10m = this.t_data.hellman_10m;
            this.hellman_15m = this.t_data.hellman_15m;
            this.hellman_30m = this.t_data.hellman_30m;
            this.hellman_45m = this.t_data.hellman_45m;
            this.hellman_60m = this.t_data.hellman_60m;
            this.hellman_120m = this.t_data.hellman_120m;
            this.hellman_3h = this.t_data.hellman_3h;
            this.hellman_6h = this.t_data.hellman_6h;
            this.hellman_12h = this.t_data.hellman_12h;
            this.hellman_07_08 = this.t_data.hellman_07_08;
            this.hellman_08_09 = this.t_data.hellman_08_09;
            this.hellman_09_10 = this.t_data.hellman_09_10;
            this.hellman_10_11 = this.t_data.hellman_10_11;
            this.hellman_11_12 = this.t_data.hellman_11_12;
            this.hellman_12_13 = this.t_data.hellman_12_13;
            this.hellman_13_14 = this.t_data.hellman_13_14;
            this.hellman_14_15 = this.t_data.hellman_14_15;
            this.hellman_15_16 = this.t_data.hellman_15_16;
            this.hellman_16_17 = this.t_data.hellman_16_17;
            this.hellman_17_18 = this.t_data.hellman_17_18;
            this.hellman_18_19 = this.t_data.hellman_18_19;
            this.hellman_19_20 = this.t_data.hellman_19_20;
            this.hellman_20_21 = this.t_data.hellman_20_21;
            this.hellman_21_22 = this.t_data.hellman_21_22;
            this.hellman_22_23 = this.t_data.hellman_22_23;
            this.hellman_23_24 = this.t_data.hellman_23_24;
            this.hellman_24_01 = this.t_data.hellman_24_01;
            this.hellman_01_02 = this.t_data.hellman_01_02;
            this.hellman_02_03 = this.t_data.hellman_02_03;
            this.hellman_03_04 = this.t_data.hellman_03_04;
            this.hellman_04_05 = this.t_data.hellman_04_05;
            this.hellman_05_06 = this.t_data.hellman_05_06;
            this.hellman_06_07 = this.t_data.hellman_06_07;

            this.checkValidateUpdate("hellman_5m", this.t_data.hellman_5m);
            this.checkValidateUpdate("hellman_10m", this.t_data.hellman_10m);
            this.checkValidateUpdate("hellman_15m", this.t_data.hellman_15m);
            this.checkValidateUpdate("hellman_30m", this.t_data.hellman_30m);
            this.checkValidateUpdate("hellman_45m", this.t_data.hellman_45m);
            this.checkValidateUpdate("hellman_60m", this.t_data.hellman_60m);
            this.checkValidateUpdate("hellman_120m", this.t_data.hellman_120m);
            this.checkValidateUpdate("hellman_3h", this.t_data.hellman_3h);
            this.checkValidateUpdate("hellman_6h", this.t_data.hellman_6h);
            this.checkValidateUpdate("hellman_12h", this.t_data.hellman_12h);
            this.checkValidateUpdate("hellman_07_08", this.t_data.hellman_07_08);
            this.checkValidateUpdate("hellman_08_09", this.t_data.hellman_08_09);
            this.checkValidateUpdate("hellman_09_10", this.t_data.hellman_09_10);
            this.checkValidateUpdate("hellman_10_11", this.t_data.hellman_10_11);
            this.checkValidateUpdate("hellman_11_12", this.t_data.hellman_11_12);
            this.checkValidateUpdate("hellman_12_13", this.t_data.hellman_12_13);
            this.checkValidateUpdate("hellman_13_14", this.t_data.hellman_13_14);
            this.checkValidateUpdate("hellman_14_15", this.t_data.hellman_14_15);
            this.checkValidateUpdate("hellman_15_16", this.t_data.hellman_15_16);
            this.checkValidateUpdate("hellman_16_17", this.t_data.hellman_16_17);
            this.checkValidateUpdate("hellman_17_18", this.t_data.hellman_17_18);
            this.checkValidateUpdate("hellman_18_19", this.t_data.hellman_18_19);
            this.checkValidateUpdate("hellman_19_20", this.t_data.hellman_19_20);
            this.checkValidateUpdate("hellman_20_21", this.t_data.hellman_20_21);
            this.checkValidateUpdate("hellman_21_22", this.t_data.hellman_21_22);
            this.checkValidateUpdate("hellman_22_23", this.t_data.hellman_22_23);
            this.checkValidateUpdate("hellman_23_24", this.t_data.hellman_23_24);
            this.checkValidateUpdate("hellman_24_01", this.t_data.hellman_24_01);
            this.checkValidateUpdate("hellman_01_02", this.t_data.hellman_01_02);
            this.checkValidateUpdate("hellman_02_03", this.t_data.hellman_02_03);
            this.checkValidateUpdate("hellman_03_04", this.t_data.hellman_03_04);
            this.checkValidateUpdate("hellman_04_05", this.t_data.hellman_04_05);
            this.checkValidateUpdate("hellman_05_06", this.t_data.hellman_05_06);
            this.checkValidateUpdate("hellman_06_07", this.t_data.hellman_06_07);
        },
        updateData() {
            this.cekLd12();
            // qc parameter:START
            let qc_flag = 0;
            let qc_histories = {
                before: this.last_qc_histories,
                after: null,
            };
            // range check
            // RC: { status, message }
            let qc_found = Object.entries(this.RC)
                .filter((e) => e[1].status)
                .map((e) => e[0])
                .map((e) => {
                    const roles_data = RC_helper.data[e];
                    const roles = Object.values(roles_data).join(",");
                    return {
                        raw_type: "HujanHellman",
                        atribut: e,
                        value: this[e],
                        roles: roles,
                        type: "range_check",
                        message: `batas bawah ${roles_data.min}, batas atas ${roles_data.max}, special value ${roles_data.values}`,
                    };
                });
            if (Object.values(qc_found).length) {
                this.$swal({
                    title: "Apakah Anda Yakin?",
                    text: "Ada Data Terkena Range Check ",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Submit",
                    cancelButtonText: "Cek Data",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1",
                    },
                    buttonsStyling: false,
                }).then((result) => {
                    if (result.value) {
                        this.sendUpdateData();
                    } else if (result.dismiss === "cancel") {
                        return;
                    }
                });
            } else {
                this.sendUpdateData();
            }
        },
        sendUpdateData() {
            this.cekLd12();
            // qc parameter:START
            let qc_flag = 0;
            let qc_histories = {
                before: this.last_qc_histories,
                after: null,
            };
            // range check
            // RC: { status, message }
            let qc_found = Object.entries(this.RC)
                .filter((e) => e[1].status)
                .map((e) => e[0])
                .map((e) => {
                    const roles_data = RC_helper.data[e];
                    const roles = Object.values(roles_data).join(",");
                    return {
                        raw_type: "HujanHellman",
                        atribut: e,
                        value: this[e],
                        roles: roles,
                        type: "range_check",
                        message: `batas bawah ${roles_data.min}, batas atas ${roles_data.max}, special value ${roles_data.values}`,
                    };
                });
            if (Object.values(qc_found).length) {
                qc_flag = 1;
                qc_histories.after = qc_found;
            }
            // if (this.$route.query.qc_edit == true || this.$route.query.qc_edit == "true") {
            //   qc_flag = 2;
            // }
            // qc parameter:END

            const post_data = {
                "@type": "HujanHellman",
                hellman_5m: this.hellman_5m,
                hellman_10m: this.hellman_10m,
                hellman_15m: this.hellman_15m,
                hellman_30m: this.hellman_30m,
                hellman_45m: this.hellman_45m,
                hellman_60m: this.hellman_60m,
                hellman_120m: this.hellman_120m,
                hellman_3h: this.hellman_3h,
                hellman_6h: this.hellman_6h,
                hellman_12h: this.hellman_12h,
                hellman_07_08: this.hellman_07_08,
                hellman_08_09: this.hellman_08_09,
                hellman_09_10: this.hellman_09_10,
                hellman_10_11: this.hellman_10_11,
                hellman_11_12: this.hellman_11_12,
                hellman_12_13: this.hellman_12_13,
                hellman_13_14: this.hellman_13_14,
                hellman_14_15: this.hellman_14_15,
                hellman_15_16: this.hellman_15_16,
                hellman_16_17: this.hellman_16_17,
                hellman_17_18: this.hellman_17_18,
                hellman_18_19: this.hellman_18_19,
                hellman_19_20: this.hellman_19_20,
                hellman_20_21: this.hellman_20_21,
                hellman_21_22: this.hellman_21_22,
                hellman_22_23: this.hellman_22_23,
                hellman_23_24: this.hellman_23_24,
                hellman_24_01: this.hellman_24_01,
                hellman_01_02: this.hellman_01_02,
                hellman_02_03: this.hellman_02_03,
                hellman_03_04: this.hellman_03_04,
                hellman_04_05: this.hellman_04_05,
                hellman_05_06: this.hellman_05_06,
                hellman_06_07: this.hellman_06_07,
                modification_date: this.t_date,
                observer_id: this.t_observer,
            };

            const update_url = Helper.getBaseUrl() + this.t_path_update;

            axios
                .patch(update_url, post_data, Helper.getConfig())
                .then((response) => {
                    console.log(response);
                    this.$swal({
                        title: "Update data sukses!",
                        text: "Data Telah berhasil di Update",
                        icon: "success",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                    this.resetForm();
                })
                .catch((error) => {
                    console.log(error);
                    error_handler(this, error, "Hujan Helman", "Update Data Error");
                });
            var arr_path = this.t_path.split("/");
            var reg_id = arr_path[1];
            qc_helper.editQCNotification(
                this.t_station_id + "_hujanhellman_" + this.t_date, // id entry
                "HujanHellman", // type form entry
                this.t_date, // tanggal entry
                "", // jam entry
                JSON.stringify(qc_histories), // qc histories
                qc_flag,
                this.t_station_id,
                this.t_station.text,
                this.t_observer,
                this.t_observer_name, // observer name
                reg_id,
                this.t_path + "/" + this.t_station_id + "_hujanhellman_" + this.t_date
            );
        },
        submitForm() {
            // console.log("helman submitForm")
            this.cekLd12();
            // console.log("helman submitForm 1")
            const ID = this.t_station_id + "_hujanhellman_" + this.t_date;

            // qc parameter:START
            let qc_flag = 0;
            let qc_histories = {
                before: this.last_qc_histories,
                after: null,
            };
            // range check
            // RC: { status, message }
            let qc_found = Object.entries(this.RC)
                .filter((e) => e[1].status)
                .map((e) => e[0])
                .map((e) => {
                    const roles_data = RC_helper.data[e];
                    const roles = Object.values(roles_data).join(",");
                    return {
                        raw_type: "HujanHellman",
                        atribut: e,
                        value: this[e],
                        roles: roles,
                        type: "range_check",
                        message: `batas bawah ${roles_data.min}, batas atas ${roles_data.max}, special value ${roles_data.values}`,
                    };
                });
            if (Object.values(qc_found).length) {
                this.$swal({
                    title: "Apakah Anda Yakin?",
                    text: "Ada Data Terkena Range Check ",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Submit",
                    cancelButtonText: "Cek Data",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1",
                    },
                    buttonsStyling: false,
                }).then((result) => {
                    if (result.value) {
                        this.sendSubmitForm();
                    } else if (result.dismiss === "cancel") {
                        return;
                    }
                });
            } else {
                this.sendSubmitForm();
            }
        },
        sendSubmitForm() {
            this.cekLd12();

            const ID = this.t_station_id + "_hujanhellman_" + this.t_date;

            // qc parameter:START
            let qc_flag = 0;
            let qc_histories = {
                before: this.last_qc_histories,
                after: null,
            };
            // range check
            // RC: { status, message }
            let qc_found = Object.entries(this.RC)
                .filter((e) => e[1].status)
                .map((e) => e[0])
                .map((e) => {
                    const roles_data = RC_helper.data[e];
                    const roles = Object.values(roles_data).join(",");
                    return {
                        raw_type: "HujanHellman",
                        atribut: e,
                        value: this[e],
                        roles: roles,
                        type: "range_check",
                        message: `batas bawah ${roles_data.min}, batas atas ${roles_data.max}, special value ${roles_data.values}`,
                    };
                });
            if (Object.values(qc_found).length) {
                qc_flag = 1;
                qc_histories.after = qc_found;
            }
            // if (this.$route.query.qc_edit == true || this.$route.query.qc_edit == "true") {
            //   qc_flag = 2;
            // }
            // qc parameter:END

            const post_data = {
                "@type": "HujanHellman",
                id: ID,
                hellman_5m: this.hellman_5m,
                hellman_10m: this.hellman_10m,
                hellman_15m: this.hellman_15m,
                hellman_30m: this.hellman_30m,
                hellman_45m: this.hellman_45m,
                hellman_60m: this.hellman_60m,
                hellman_120m: this.hellman_120m,
                hellman_3h: this.hellman_3h,
                hellman_6h: this.hellman_6h,
                hellman_12h: this.hellman_12h,
                hellman_07_08: this.hellman_07_08,
                hellman_08_09: this.hellman_08_09,
                hellman_09_10: this.hellman_09_10,
                hellman_10_11: this.hellman_10_11,
                hellman_11_12: this.hellman_11_12,
                hellman_12_13: this.hellman_12_13,
                hellman_13_14: this.hellman_13_14,
                hellman_14_15: this.hellman_14_15,
                hellman_15_16: this.hellman_15_16,
                hellman_16_17: this.hellman_16_17,
                hellman_17_18: this.hellman_17_18,
                hellman_18_19: this.hellman_18_19,
                hellman_19_20: this.hellman_19_20,
                hellman_20_21: this.hellman_20_21,
                hellman_21_22: this.hellman_21_22,
                hellman_22_23: this.hellman_22_23,
                hellman_23_24: this.hellman_23_24,
                hellman_24_01: this.hellman_24_01,
                hellman_01_02: this.hellman_01_02,
                hellman_02_03: this.hellman_02_03,
                hellman_03_04: this.hellman_03_04,
                hellman_04_05: this.hellman_04_05,
                hellman_05_06: this.hellman_05_06,
                hellman_06_07: this.hellman_06_07,
                data_timestamp: this.t_date,
                observer_id: this.t_observer,
                qc_flag: qc_flag,
                qc_histories: JSON.stringify(qc_histories),
            };

            const post_url = Helper.getBaseUrl() + this.t_path;

            axios
                .post(post_url, post_data, Helper.getConfig())
                .then((response) => {
                    console.log(response);
                    this.$swal({
                        title: "Input Data Berhasil!",
                        text: "Data Telah berhasil di Update",
                        icon: "success",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                    this.resetForm();
                })
                .catch((error) => {
                    console.log(error);
                    error_handler(this, error, "Hujan Helman", "Input Data Error");
                });

            // input qc notification
            if (qc_flag == 1) {
                var arr_path = this.t_path.split("/");
                var reg_id = arr_path[1];
                qc_helper.editQCNotification(
                    this.t_station_id + "_hujanhellman_" + this.t_date, // id entry
                    "HujanHellman", // type form entry
                    this.t_date, // tanggal entry
                    "", // jam entry
                    JSON.stringify(qc_histories), // qc histories
                    qc_flag,
                    this.t_station_id,
                    this.t_station.text,
                    this.t_observer,
                    this.t_observer_name, // observer name
                    reg_id,
                    this.t_path + "/" + this.t_station_id + "_hujanhellman_" + this.t_date
                );
                // qc_helper.createQCNotification(
                //   ID, // id entry
                //   "HujanHellman", // type form entry
                //   this.t_date, // tanggal entry
                //   "", // jam entry
                //   JSON.stringify(qc_histories), // qc histories
                //   qc_flag,
                //   this.t_station_id,
                //   this.t_station.text,
                //   this.t_observer,
                //   this.t_observer_name // observer name
                // );
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";

.label-entry {
    text-align: center;
    color: #3b4253;
    width: 100%;
    font-weight: 500;
    border: 2px solid #9a9da1;
    border-radius: 3px;
    background: #f3f1f1;
    padding: 4px;
    font-weight: 500;
}
.label-entry1 {
    text-align: center;
    color: rgb(26, 25, 25);
    width: 100%;
    /* font-weight: bold !important; */
    border: 1px solid #b5b5c3;
    border-radius: 3px;
    padding: 4px;
    margin-bottom: 7px;
}
.mbtm {
    margin-bottom: 4px;
}
.pad2 {
    padding-left: 2px;
    padding-right: 2px;
}
.pad3 {
    padding-left: 10px;
    padding-right: 10px;
}
.pad4 {
    padding-right: 12px;
}
.bg-light-primary {
    color: #000000 !important;
}
.bg-light-success {
    color: #000000 !important;
}
</style>
<style scoped>
.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
</style>
