<template>
    <div>
      <div class="row">
        <div class="col-xl-12">
          <b-card-actions title="Filter" action-collapse>
            <b-row class="font-weight-bolder">

              <b-col sm="3">
                <b-form-group label="Jenis Form" label-for="select-form" size="sm">
                  <v-select v-model="t_form_ku" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="label" :options="t_form_ku_opt" class="select-size-sm" />
                </b-form-group>
              </b-col>

              <b-col sm="4">
                <b-form-group label="Stasiun" label-for="select-station" size="sm">
                  <v-select v-model="t_station" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="t_station_opt" class="select-size-sm" />
                </b-form-group>
              </b-col>

              <b-col sm="2">
                <b-form-group label="Tahun" label-for="select-year" size="sm">
                  <v-select v-model="t_year" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title" :options="t_year_opt" class="select-size-sm" />
                </b-form-group>
              </b-col>

              <b-col sm="2">
                <b-form-group label="Periode" label-for="select-periode" size="sm">
                  <v-select v-model="t_periode" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="label" :options="t_periode_opt" class="select-size-sm" />
                </b-form-group>
              </b-col>

              <b-col sm="1">
                <b-form-group label="" label-for="select-periode" size="sm" class="mt-2">
                  <b-button v-on:click="onFetch" variant="primary" class="ml-sm-6" size="sm" style="height:37px">Fetch</b-button>
                </b-form-group>
              </b-col>

            </b-row>
          </b-card-actions>
        </div>
      </div>
  
      <KahForm v-if="choosenForm == 'kah'" 
        :changeForm="onChangeForm"
        :t_data="t_data" 
        :t_station="t_station"
        :t_id_form="t_id_form"
        :list_observer="list_observer" 
        :t_data_status="t_data_status"
        :t_path="t_path"
        :t_path_update="t_path_update"
        :t_year="t_year"
        :t_periode="t_periode"
        :roles="roles"
        :list_penyelia="list_penyelia"
        :d_station="d_station"
      />
      <SpmForm v-if="choosenForm == 'spm'"
        :changeForm="onChangeForm" 
        :t_data="t_data" 
        :t_station="t_station"
        :t_id_form="t_id_form"
        :list_observer="list_observer"
        :t_data_status="t_data_status"
        :t_path="t_path"
        :t_path_update="t_path_update"
        :t_year="t_year"
        :t_periode="t_periode"
        :roles="roles"
        :list_penyelia="list_penyelia"
        :d_station="d_station"
      />
      <SoxNoxForm v-if="choosenForm == 'so2no2'" 
        :changeForm="onChangeForm"
        :t_data="t_data" 
        :t_station="t_station"
        :t_id_form="t_id_form"
        :list_observer="list_observer"
        :t_data_status="t_data_status"
        :t_path="t_path"
        :t_path_update="t_path_update"
        :t_year="t_year"
        :t_periode="t_periode"
        :roles="roles"
        :list_penyelia="list_penyelia"
        :d_station="d_station"
      />
      <Aerosol v-if="choosenForm == 'aerosol'" 
        :changeForm="onChangeForm"
        :t_data="t_data"  
        :t_station="t_station"
        :t_id_form="t_id_form"
        :list_observer="list_observer"
        :t_data_status="t_data_status"
        :t_path="t_path"
        :t_path_update="t_path_update"
        :t_year="t_year"
        :t_periode="t_periode"
        :roles="roles"
        :list_penyelia="list_penyelia"
        :d_station="d_station"
      />


      <b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay>
    </div>
  </template>
  
  <script>
  import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
  import { BCard, BCardHeader, BOverlay, BContainer, BRow, BCol, BFormSelect, BForm, BButton, BFormGroup, BCollapse, BFormDatepicker, BFormInput } from "bootstrap-vue";
  import vSelect from "vue-select";

  import {KahForm, SoxNoxForm, SpmForm, Aerosol} from "./KuComponent";
  import getstationku from "@/api/getstationku";
  import metadata from "@/api/metadata";
  import labkuservice from "@/api/labkuservice";
  import axios from "axios";
  import Helper from "@/api/helper";
  
  export default {
    components: {
    BCardActions,
    BOverlay,
    BCard,
    BCardHeader,
    BContainer,
    BRow,
    vSelect,
    BCol,
    BFormSelect,
    BForm,
    BButton,
    BFormGroup,
    BCollapse,
    BFormDatepicker,
    BFormInput,
    KahForm,
    SoxNoxForm,
    SpmForm,
    KahForm,
    Aerosol,
    Aerosol
},
  
    props: ["roles"],
  
    data: function() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const maxDate = new Date(today);
  
      return {
        max: maxDate,
        selectedForm: "",
        choosenForm: "",
        t_station: "",
        t_station_id: "",
        t_station_opt: [],
        t_wmoid: "",
        t_wmoid_opt: [],
        t_wmoid_list: {},
        t_observer: "",
        t_observer_name: "",
        t_tahun: "",
        t_path: "",
        t_form: {
          "01": "kah",
          "02": "spm",
          "03": "soxnox",
          "04": "aerosol",
        },
        t_data: null,
        t_id_form: "",
        showLoading: false,
        t_data_status: "insert",
        t_path_update: "",
        list_observer: [],
        list_penyelia: [],
        dateDisable: false,
        dataFormInput: {
          is_kah: false,
          is_spm: false,
          is_soxnox: false,
          is_aerosol: false,
        },
        data_qc_auto : {},
        count_form_click : 0,
        YEARS_SELECTED: "",
        YEARS_STORE: [],
        t_year: '',
        t_year_opt: [],
        t_form_ku: '',
        t_form_ku_opt: [
          {value: 'kah', label: "Kimia Air Hujan"},
          {value: 'spm', label: "Suspended Particulate Matter"},
          {value: 'so2no2', label: "SO2 & NO2"},
          {value: 'aerosol', label: "Aerosol"}
        ],
        t_periode: '',
        t_periode_opt: [],
        showFormKah:true
      };
    },
  
    methods: {
      onChangeForm(name){
        // console.log("onChangeForm", name);
        this.choosenForm = name;
      },
      async onFetch() {
        this.t_data_status = "insert";
        this.showLoading = true;
        // if (this.selectedForm == "" || this.t_station == "" || this.t_tahun == "" || this.selectedPeriod == "") {
        if (!this.t_form_ku || !this.t_station || !this.t_year || !this.t_periode) {
          this.$swal({
            title: "Error!",
            text: "Silahkan lengkapi Filter",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.showLoading = false;
          return;
        }
        try {
          await this.getpath();
          // this.t_id_form = this.t_station_id + "_" + this.t_form[this.selectedForm] + "_" + this.t_tahun;
          this.t_id_form = this.t_station_id + "_" + this.t_form_ku.value + "_" + this.t_year.title + '_' + this.t_periode.value;
          var path = this.t_path + "/" + this.t_id_form;
          this.t_path_update = path;
          await this.cekExistData(path);
        } catch (e) {
          this.showLoading = false;
          console.log("Eee : ", e);
        }
      },
      async cekExistData(path) {
        // console.log("cekExistDataurl : ", path);
        // if (this.t_form_ku) {
        //   this.choosenForm = this.t_form_ku.value;
        //   this.showLoading = false;
        // }
        // else

        // if (this.selectedForm != "04") {
          var url_data = Helper.getBaseUrl() + path;
          // console.log("url : a" + url_data);
          await axios
            .get(url_data, Helper.getConfig())
            .then((response) => {
              this.$swal({
                title: "Apakah Anda Yakin?",
                text: "Data Pada Periode Ini Telah Ada. Edit Data ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Edit",
                allowOutsideClick: false,
                allowEscapeKey: false,
                customClass: {
                  confirmButton: "btn btn-primary",
                  cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.value) {
                  
                  // let observer_valid = this.list_observer.find((el) => el.id === `${this.t_data.observer_id}`);
                  // // console.log("observer_valid : ", observer_valid);
                  // if (observer_valid) {
                  //   this.t_observer = `${this.t_data.observer_id}`;
                  //   this.t_observer_name = observer_valid.observer;
                  // } else {
                  //   this.t_observer = null;
                  //   this.t_observer_name = null;
                  // }
  
                  
                  this.choosenForm = this.t_form_ku.value;
                  this.t_data_status = "update";
                  this.t_data = response.data;
                  // console.log('cekExistData response true : ', this.t_data, this.choosenForm, this.t_data_status)
                  this.showLoading = false;
                } else if (result.dismiss === "cancel") {
                  this.choosenForm = "";
                  this.showLoading = false;
                  
                  return;
                }
              });
            })
            .catch((error) => {
              this.choosenForm = this.t_form_ku.value;
              if (error.response) {
                console.log(error.response);
                this.showLoading = false;
                this.t_data_status = "insert";
                this.count_form_click++;
              } else if (error.request) {
                // The request was made but no response was received
                // this.status = "fail";
                this.showLoading = false;
                // console.log("err Reqnya :", error.request);
                this.$swal({
                  title: "Error!",
                  text: "Server Not Respond!",
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
                return;
              } else {
                // Something happened in setting up the request that triggered an Error
                // this.status = "fail";
                this.showLoading = false;
                // console.log("Error aja : ", error.message);
                this.$swal({
                  title: "Error!",
                  text: "Terjadi Kesalahan",
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
                return;
              }
            });
        // } else {
        //   this.choosenForm = this.selectedForm;
        //   this.showLoading = false;
          
        // }
      },
      async getpath() {
        try {
          const { data } = await labkuservice.getPathStasiun(this.t_station.value);
          this.t_path = data[0].path+'/klimatologi/labku/' + this.t_form_ku.value;
          // console.log("path : ", this.t_path);
        } catch (e) {
          console.log(e);
        }
      },
      async listStasiun(val) {
        // console.log('listStasiun', val)
        try {
          // const { data } = await metadata.getAllStasiunWithWMO();
          if (!val) {
            return 
          }

          let is_form = ''
          switch (val.value) {
            case 'kah':
              is_form = 'is_kimia_air_hujan'
              break;
            case 'spm':
              is_form = 'is_spm'
              break;
            case 'so2no2':
              is_form = 'is_so2_no2'
              break;
            case 'aerosol':
              is_form = 'is_aerosol'
              break;
            default:
              break;
          }

          const { data } = await getstationku.getExStasiunKu(is_form)
          this.t_station_opt = []
          for (var i = 0; i < data.length; i++) {
            const data_station = {
              value: data[i].station_id,
              text: data[i].station_name,
              path: data[i].path,
              wmo: data[i].station_wmo_id,
              latitude: data[i].current_latitude,
              longitude: data[i].current_longitude
            };
            if (this.t_wmoid_list[data[i].station_id] == undefined) {
              this.t_wmoid_list[data[i].station_id] = "";
            }
            this.t_wmoid_list[data[i].station_id] = data[i].station_wmo_id;
  
            this.t_station_opt.push(data_station);
            this.t_station = "";
          }
        } catch (e) {
          console.log(e);
        }
      },

      async listObserver(station_id) {
        var data = await metadata.getObserverStation(station_id);
        // console.log("data observer: ",data)
        this.list_observer = data;
        this.t_observer = "";
      },

      async listPenyelia() {
        try {
          const { data } = await labkuservice.listPenyelia();
          let items = data.items
          let penyelias = []
          for (var i = 0; i < items.length; i++) {
            const data_penyelia = {
              value: items[i].sb_label,//items[i].sb_value,
              text: items[i].sb_label,
              path: items[i].path,
            };
            penyelias.push(data_penyelia)
          }
          // console.log('penyelias', penyelias)
          this.list_penyelia = penyelias
        } catch (e) {
          console.log(e);
        }
      },

      async listForm(path) {
        this.showLoading = true;
        var { data } = await metadata.getFormInput(path);
        // console.log("patnya : ",path);
        // console.log("datana : ",data);
        // console.log("form input : ",data["BmkgSatu.metadata.bmkgentry.IBMKGEntry"]);
        this.d_station = data
        this.dataFormInput = data["BmkgSatu.metadata.bmkgentry.IBMKGEntry"];
        this.data_qc_auto = data["BmkgSatu.metadata.qc.IAutorumus_qc"];
        // console.log("data_qc_auto : ",this.data_qc_auto)
        this.showLoading = false;
      },

      onYearsStart() {
        this.t_year_opt = [];
        var DateNow = new Date();
        for ( var i = Number(DateNow.getFullYear()); i > Number(DateNow.getFullYear() - 50); i-- ) {
          this.t_year_opt.push({title:i});
        }
      },

    },
    // computed: {
    //   nameState() {
    //     return this.name.length > 2;
    //   }
    // },
    mounted() {
      // this.listStasiun();
      this.listPenyelia();
      this.onYearsStart();
      // this.listObserver();
  
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
  
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      var date = yyyy + "-" + mm + "-" + dd;
      // var date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
      // console.log("date : ",date)
      this.t_date = date;
  
      let userLogin = JSON.parse(localStorage.getItem("userLogin"));
      // console.log("userLogin : ", userLogin);
      // this.t_observer = userLogin.username;
    },
    watch: {
      t_station(value) {
        // console.log('KU Main t_station', value)
        if (value) {
          this.t_wmoid_opt = [];
          this.t_station_id = value.value;
          this.listObserver(value.value);
          const data_wmo = {
            value: this.t_wmoid_list[value.value],
            text: this.t_wmoid_list[value.value],
          };
          this.t_wmoid_opt.push(data_wmo);
    
          this.t_wmoid = this.t_wmoid_list[value.value];
          this.listForm(value.path);
          if (this.selectedForm != "") {
            this.getpath();
          }
          this.choosenForm = "";
          // console.log("station : ",value);
        }
      },
      selectedForm(val) {
        if (val == "04") {
          this.dateDisable = true;
          var today = new Date();
          var date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
          this.t_date = date;
        } else {
          this.dateDisable = false;
        }
      },
      t_observer(val){
        this.t_observer_name = this.list_observer.find((el) => el.id === val).observer
        // console.log(this.list_observer.find((el) => el.id === val).observer)
      },
      t_wmoid() {

      },

      t_form_ku(val) {
        // console.log('t_form_ku', val)
        this.listStasiun(val)
        if (val) {
          this.t_periode_opt = []
          if (val.value == 'kah'){
            for (var i = 0; i < 52; i++) {
              this.t_periode_opt.push({value: (i+1), label: (i+1) })
            }            
          }
          else {
            for (var i = 0; i < 61; i++) {
              this.t_periode_opt.push({value: (i+1), label: (i+1) })
            } 
          }
          // console.log('t_form_ku', this.t_periode_opt)
        }
        else {
          this.t_periode_opt = []
        }
      }
      // selectedForm(){
      //   this.getpath()
      // },
    },
  };
  </script>
  
  <style scoped>
  .reduce-margin-row {
    margin-bottom: -1.75rem !important;
  }
  
  .icon-right {
    float: right;
  }
  
  .card-full {
    height: 100%;
  }
  
  #container-header {
    width: 100%;
  }
  
  #left-header {
    width: 100%;
  }
  
  #right-header {
    width: 100%;
  }
  .pad2 {
    padding-left: 2px;
    padding-right: 2px;
  }
  </style>
  