<template>
  <div>
    <b-row class="mt-n2">
      <!-- card actions -->
      <b-col cols="12">
        <b-card>
          <h6 class="badge badge-light-dark font-weight-bolder text-dark mb-2">Kedalaman Tanah (%)</h6>
          <b-row>
            <b-col sm="2">
              <div>
                <label for="input10" class="label-entry1"
                  >10 cm <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban tanah pada kedalaman 10 cm dalam satuan %"
                /></label>
              </div>
              <b-form-input id="input10" size="sm" class="input-entry text-center" 
              :state="validateState($v.soilmoisture_10)" 
               v-on:keydown="onKeyCheck($event)"
              v-model.number="$v.soilmoisture_10.$model" 
              v-on:keypress="isNumber($event)"
              type="number"></b-form-input>
            </b-col>

            <b-col sm="2 ">
              <div>
                <label for="input20" class="label-entry1"
                  >20 cm <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban tanah pada kedalaman 20 cm dalam satuan %"
                /></label>
              </div>
              <b-form-input id="input20" size="sm" class="input-entry text-center" 
              :state="validateState($v.soilmoisture_20)" 
               v-on:keydown="onKeyCheck($event)"
              v-model.number="$v.soilmoisture_20.$model" 
              v-on:keypress="isNumber($event)"
              type="number"></b-form-input>
            </b-col>

            <b-col sm="2 mb-1">
              <div>
                <label for="input30" class="label-entry1"
                  >30 cm <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="kelembaban tanah pada kedalaman 30 cm dalam satuan %"
                /></label>
              </div>
              <b-form-input id="input10" size="sm" class="input-entry text-center" 
              :state="validateState($v.soilmoisture_30)" 
               v-on:keydown="onKeyCheck($event)"
              v-model.number="$v.soilmoisture_30.$model" 
              v-on:keypress="isNumber($event)"
              type="number"></b-form-input>
            </b-col>

            <b-col sm="2 ">
              <div>
                <label for="input40" class="label-entry1"
                  >40 cm <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="kelembaban tanah pada kedalaman 40 cm dalam satuan %"
                /></label>
              </div>
              <b-form-input id="input10" size="sm" class="input-entry text-center" 
              :state="validateState($v.soilmoisture_40)" 
               v-on:keydown="onKeyCheck($event)"
              v-model.number="$v.soilmoisture_40.$model" 
              v-on:keypress="isNumber($event)"
              type="number"></b-form-input>
            </b-col>

            <b-col sm="2 ">
              <div>
                <label for="input60" class="label-entry1"
                  >60 cm <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban tanah pada kedalaman 60 cm dalam satuan %"
                /></label>
              </div>
              <b-form-input id="input10" size="sm" class="input-entry text-center" 
              :state="validateState($v.soilmoisture_60)" 
               v-on:keydown="onKeyCheck($event)"
              v-model.number="$v.soilmoisture_60.$model" 
              v-on:keypress="isNumber($event)"
              type="number"></b-form-input>
            </b-col>

            <b-col sm="2 ">
              <div>
                <label for="input100" class="label-entry1"
                  >100 cm <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Kelembaban tanah pada kedalaman 100 cm dalam satuan %"
                /></label>
              </div>
              <b-form-input id="input10" size="sm" class="input-entry text-center" 
              :state="validateState($v.soilmoisture_100)" 
               v-on:keydown="onKeyCheck($event)"
              v-model.number="$v.soilmoisture_100.$model" 
              v-on:keypress="isNumber($event)"
              type="number"></b-form-input>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-n2">
      <b-col cols="12">
        <b-card>
          <b-row class="float-right">
            <div>
              <b-button variant="danger" class="mr-2" size="sm" @click="resetForm()">Batal</b-button>
              <b-button variant="warning" class="mr-2" size="sm" @click="v_Form()" v-if="this.t_data_status == 'update'" :disabled="!roles.isRoleEdit">Update</b-button>
              <b-button variant="primary" class="mr-2" size="sm" @click="v_Form()" v-if="this.t_data_status == 'insert'" :disabled="!roles.isRoleAdd"> Submit</b-button>
            </div>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { BRow, BCol, BTable, BCardText, BFormInput, BContainer, BButton, BCard, VBTooltip, BTooltip } from "bootstrap-vue";
import axios from "axios";
import Helper from "@/api/helper";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import error_handler from "@/api/error_handler";
import { validationMixin } from "vuelidate";
import { required, minLength, between } from "vuelidate/lib/validators";
import { V_KEDALAMAN_TANAH } from "@/validators/piasvalidator";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BTable,
    BCardText,
    BFormInput,
    BContainer,
    BButton,
    BCard,
    VBTooltip,
    BTooltip,
  },

  props: ["t_station", "t_wmoid", "t_observer", "t_date", "t_station_id", "t_path",
   "t_data", "t_id_form", "t_data_status", "t_path_update", "roles","count_form_click"],
  data() {
    return {
      soilmoisture_10: "",
      soilmoisture_20: "",
      soilmoisture_30: "",
      soilmoisture_40: "",
      soilmoisture_60: "",
      soilmoisture_100: "",
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  mixins: [validationMixin],
  validations: {
    soilmoisture_10: { required, V_KEDALAMAN_TANAH },
    soilmoisture_20: { required, V_KEDALAMAN_TANAH },
    soilmoisture_30: { required, V_KEDALAMAN_TANAH },
    soilmoisture_40: { required, V_KEDALAMAN_TANAH },
    soilmoisture_60: { required, V_KEDALAMAN_TANAH },
    soilmoisture_100: { required, V_KEDALAMAN_TANAH },
  },
  mounted() {
    if (this.t_data_status == "update") {
      this.popupData();
    } else {
      this.resetForm();
    }
  },
  watch: {
    t_station(value) {
      console.log("ubah station : ", this.t_path);
    },
    t_data_status(newValue, OldValue) {
      if (newValue == "update") {
        this.popupData();
      } else {
        this.resetForm();
      }
    },
    count_form_click(NewValue, OldValue){
      if(this.t_data_status =="insert"){
        if(NewValue != OldValue){
          this.resetForm();
        }
      }
    },
  },
  methods: {
    onKeyCheck(event) {
      console.log('onKeyCheck', event)
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault()
      }
    },
    isNumber(event) {
      if (event.key == "." || event.key == ",") {
        return event.preventDefault();
      }
    },
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    popupData() {
      this.soilmoisture_10 = this.t_data.soilmoisture_10;
      this.soilmoisture_20 = this.t_data.soilmoisture_20;
      this.soilmoisture_30 = this.t_data.soilmoisture_30;
      this.soilmoisture_40 = this.t_data.soilmoisture_40;
      this.soilmoisture_60 = this.t_data.soilmoisture_60;
      this.soilmoisture_100 = this.t_data.soilmoisture_100;
    },
    resetForm() {
      this.soilmoisture_10 = "";
      this.soilmoisture_20 = "";
      this.soilmoisture_30 = "";
      this.soilmoisture_40 = "";
      this.soilmoisture_60 = "";
      this.soilmoisture_100 = "";
      this.$v.$reset();
    },
    v_Form() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Data tidak valid. Periksa kembali inputan anda",
            icon: "BellIcon",
            variant: "danger",
          },
        });
        return;
      } else {
        if (this.t_data_status == "insert") {
          this.submitForm();
        } else if (this.t_data_status == "update") {
          this.updateData();
        }
      }
    },
    updateData() {
      const post_data = {
        "@type": "KelembabanTanah",
        soilmoisture_10: this.soilmoisture_10,
        soilmoisture_20: this.soilmoisture_20,
        soilmoisture_30: this.soilmoisture_30,
        soilmoisture_40: this.soilmoisture_40,
        soilmoisture_60: this.soilmoisture_60,
        soilmoisture_100: this.soilmoisture_100,
        modification_date: this.t_date,
        observer_id: this.t_observer,
      };

      const post_url = Helper.getBaseUrl() + this.t_path_update;

      axios
        .patch(post_url, post_data, Helper.getConfig())
        .then((response) => {
          console.log(response);
          this.$swal({
            title: "Update data sukses!",
            text: "Data Telah berhasil di Update",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.resetForm();
        })
        .catch((error) => {
          console.log(error);
          error_handler(this, error, "Kelembaban Tanah", "Insert Data Error");
        });
    },
    submitForm() {
      const ID = this.t_station_id + "_kelembabantanah_" + this.t_date;
      const post_data = {
        "@type": "KelembabanTanah",
        id: ID,
        soilmoisture_10: this.soilmoisture_10,
        soilmoisture_20: this.soilmoisture_20,
        soilmoisture_30: this.soilmoisture_30,
        soilmoisture_40: this.soilmoisture_40,
        soilmoisture_60: this.soilmoisture_60,
        soilmoisture_100: this.soilmoisture_100,
        data_timestamp: this.t_date,
        observer_id: this.t_observer,
      };

      const post_url = Helper.getBaseUrl() + this.t_path;

      axios
        .post(post_url, post_data, Helper.getConfig())
        .then((response) => {
          console.log(response);
          this.$swal({
            title: "Insert data sukses!",
            text: "Data Telah berhasil di Simpan",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.resetForm();
        })
        .catch((error) => {
          console.log(error);
          error_handler(this, error, "Kelembaban Tanah", "Insert Data Error");
        });
    },
  },
};
</script>

<style scoped>
.label-entry1 {
  text-align: center;
  color: 3b4253;
  font-weight: 500;
  width: 100%;
  /* font-weight: bold !important; */
  border: 2px solid #6e6b7b;
  border-radius: 3px;
  background: #f3f1f1;
  padding: 4px;
}</style
>>
