<template>
    <div v-if="data_input.i_time == '1400ws'" style="font-size: 0.9rem;">
        <b-row class="mt-n1">
            <b-col md="12" lg="6">
                <!-- PSYCROMETER SANGKAR start -->
                <b-row>
                    <b-col cols="12" class="mb-1">
                        <b-card class="small font-weight-bolder h-100 text-dark">
                            <h6 class="mb-2 small text-dark font-weight-bolder badge badge-light-dark">PSYCHROMETER SANGKAR</h6>
                            <b-row class="">
                                <b-col cols="12">
                                    <b-row v-if="fdih_status.is_agm1a" class="">
                                        <b-col cols="3"></b-col>
                                        <b-col cols="2" class="text-center">
                                            <div class="label-entry2 bg-light-primary">TBK</div>
                                        </b-col>
                                        <b-col cols="2" class="text-center">
                                            <div class="label-entry2 bg-light-primary">TBB</div>
                                        </b-col>

                                        <b-col cols="2" class="text-center">
                                            <div class="label-entry2 bg-light-primary">RH</div>
                                        </b-col>
                                    </b-row>

                                    <b-row v-if="fdih_status.is_agm1a" class="mt-1">
                                        <b-col cols="3" class="">
                                            <div class="label-entry1">1.2 m <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips1" /></div>
                                            <!-- HTML tooltips -->
                                            <b-tooltip target="tooltips1" :triggers="'hover'" placement="top">
                                                <dl class="text-left">
                                                    <dt>Tbk</dt>
                                                    <dd>- Temperatur dari thermometer bola kering pada ketinggian 1.2 m, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                                    <dt>Tbb</dt>
                                                    <dd>- Temperatur dari thermometer bola basah pada ketinggian 1.2 m, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                                    <dt>RH</dt>
                                                    <dd>- Lembab Nisbi udara pada ketinggian 1.2 m, dinyatakan dalam persen (%)</dd>
                                                </dl>
                                            </b-tooltip>
                                        </b-col>
                                        <b-col cols="2" class="">
                                            <b-form-input
                                                v-on:keydown="onKeyCheck($event)"
                                                id="input_tbk_1c2m_1400"
                                                :formatter="format_one_decimal"
                                                size="sm"
                                                class="text-center"
                                                :state="validateState($v.tbk_1c2m_1400)"
                                                v-model.number="$v.tbk_1c2m_1400.$model"
                                                v-on:keyup="checkValidate($event, 'tbk_1c2m_1400')"
                                                v-bind:class="{ rangecheck: rc_tbk_1c2m_1400 }"
                                            ></b-form-input>
                                            <b-tooltip v-if="CC.tbk_1c2m_1400.status" target="input_tbk_1c2m_1400" :triggers="'hover'" placement="top">
                                                {{ CC.tbk_1c2m_1400.message }}
                                            </b-tooltip>
                                            <b-tooltip v-if="rc_tbk_1c2m_1400 && !CC.tbk_1c2m_1400.status" target="input_tbk_1c2m_1400" triggers="hover">{{ rc_tbk_1c2m_1400_message }}</b-tooltip>
                                            <b-tooltip
                                                v-if="!rc_tbk_1c2m_1400 && !CC.tbk_1c2m_1400.status"
                                                :show="validateTooltipState('tbk_1c2m_1400')"
                                                :triggers="'hover'"
                                                target="input_tbk_1c2m_1400"
                                                placement="top"
                                                custom-class="validation-tooltip"
                                            >
                                                {{ tooltipMessage("tbk_1c2m_1400") }}
                                            </b-tooltip>
                                        </b-col>
                                        <b-col cols="2" class=" ">
                                            <b-form-input
                                                v-on:keydown="onKeyCheck($event)"
                                                id="input_tbb_1c2m_1400"
                                                :formatter="format_one_decimal"
                                                size="sm"
                                                class="text-center"
                                                :state="validateState($v.tbb_1c2m_1400)"
                                                v-model.number="$v.tbb_1c2m_1400.$model"
                                                v-on:keyup="checkValidate($event, 'tbb_1c2m_1400')"
                                                v-bind:class="{ rangecheck: rc_tbb_1c2m_1400 }"
                                            ></b-form-input>
                                            <b-tooltip v-if="CC.tbb_1c2m_1400.status" target="input_tbb_1c2m_1400" :triggers="'hover'" placement="top">
                                                {{ CC.tbb_1c2m_1400.message }}
                                            </b-tooltip>
                                            <b-tooltip v-if="rc_tbb_1c2m_1400 && !CC.tbb_1c2m_1400.status" target="input_tbb_1c2m_1400" triggers="hover">{{ rc_tbb_1c2m_1400_message }}</b-tooltip>
                                            <b-tooltip
                                                v-if="!rc_tbb_1c2m_1400 && !CC.tbb_1c2m_1400.status"
                                                :show="validateTooltipState('tbb_1c2m_1400')"
                                                :triggers="'hover'"
                                                target="input_tbb_1c2m_1400"
                                                placement="top"
                                                custom-class="validation-tooltip"
                                            >
                                                {{ tooltipMessage("tbb_1c2m_1400") }}
                                            </b-tooltip>
                                        </b-col>

                                        <b-col cols="2" class=" ">
                                            <b-form-input size="sm" class="bg-light-success text-center" v-model.number="rh_1c2m_1400" disabled></b-form-input>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
                <!-- PSYCROMETER SANGKAR end -->

                <!-- SUHU MINIMUM start -->
                <b-row>
                    <b-col cols="6" class="mb-1">
                        <b-card class="small font-weight-bolder h-100 text-dark">
                            <h6 class="mb-2 small text-dark font-weight-bolder badge badge-light-dark">SUHU MINIMUM</h6>
                            <b-container v-if="fdih_status.is_agm1a" fluid>
                                <b-row>
                                    <b-col cols="12">
                                        <b-row class="">
                                            <b-col cols="6" class="">
                                                <div class="label-entry1">
                                                    Tmin
                                                    <feather-icon
                                                        icon="HelpCircleIcon"
                                                        size="12"
                                                        class="bg-primary rounded-circle text-white"
                                                        v-b-tooltip.hover.v-dark
                                                        title="Temperatur dari thermometer minimum, dinyatakan dalam persepuluhan derajat Celsius."
                                                    />
                                                </div>
                                            </b-col>
                                            <b-col cols="6">
                                                <b-form-input
                                                    v-on:keydown="onKeyCheck($event)"
                                                    id="input_t_min_uji_1400"
                                                    :formatter="format_one_decimal"
                                                    size="sm"
                                                    class="text-center"
                                                    :state="validateState($v.t_min_uji_1400)"
                                                    v-model.number="$v.t_min_uji_1400.$model"
                                                    v-on:keyup="checkValidate($event, 't_min_uji_1400')"
                                                    v-bind:class="{ rangecheck: rc_t_min_uji_1400 }"
                                                ></b-form-input>
                                                <b-tooltip v-if="!rc_t_min_uji_1400" :show="validateTooltipState('t_min_uji_1400')" :triggers="'hover'" target="input_t_min_uji_1400" placement="top" custom-class="validation-tooltip">
                                                    {{ tooltipMessage("t_min_uji_1400") }}
                                                </b-tooltip>
                                                <b-tooltip v-if="rc_t_min_uji_1400" target="input_t_min_uji_1400" triggers="hover">{{ rc_t_min_uji_1400_message }}</b-tooltip>
                                            </b-col>
                                        </b-row>

                                        <b-row class="my-1">
                                            <b-col cols="6" class="">
                                                <div class="label-entry1">
                                                    Treset
                                                    <feather-icon
                                                        icon="HelpCircleIcon"
                                                        size="12"
                                                        class="bg-primary rounded-circle text-white"
                                                        v-b-tooltip.hover.v-dark
                                                        title="Temperatur hasil reset thermometer minimum , dinyatakan dalam persepuluhan derajat Celsius."
                                                    />
                                                </div>
                                            </b-col>
                                            <b-col cols="6">
                                                <b-form-input
                                                    v-on:keydown="onKeyCheck($event)"
                                                    id="input_t_reset_uji_1400"
                                                    :formatter="format_one_decimal"
                                                    size="sm"
                                                    class="text-center"
                                                    :state="validateState($v.t_reset_uji_1400)"
                                                    v-model.number="$v.t_reset_uji_1400.$model"
                                                    v-on:keyup="checkValidate($event, 't_reset_uji_1400')"
                                                    v-bind:class="{ rangecheck: rc_t_reset_uji_1400 }"
                                                ></b-form-input>
                                                <b-tooltip v-if="!rc_t_reset_uji_1400" :show="validateTooltipState('t_reset_uji_1400')" :triggers="'hover'" target="input_t_reset_uji_1400" placement="top" custom-class="validation-tooltip">
                                                    {{ tooltipMessage("t_reset_uji_1400") }}
                                                </b-tooltip>
                                                <b-tooltip v-if="rc_t_reset_uji_1400" target="input_t_reset_uji_1400" triggers="hover">{{ rc_t_reset_uji_1400_message }}</b-tooltip>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </b-card>
                    </b-col>

                    <b-col cols="6" class="mb-1">
                        <b-card header="" class="small font-weight-bolder h-100" footer-class="text-muted">
                            <b-row v-if="fdih_status.is_agm1a">
                                <b-col cols="12">
                                    <b-row class="">
                                        <b-col cols="12">
                                            <!-- <label class="text-primary mb-1">Keadaan Cuaca Waktu Peramatan</label> -->
                                            <h6 class="mb-2 small text-dark font-weight-bolder badge badge-light-dark">KEADAAN CUACA WAKTU PERAMATAN</h6>
                                            <b-form-select :state="validateState($v.co_cu_1400)" v-model="$v.co_cu_1400.$model" :options="opt_keadaan_cuaca_peramatan" size="sm"> </b-form-select>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
                <!-- end keadaan cuaca -->
            </b-col>

            <!-- ANGIN start -->
            <b-col md="12" lg="6" class="mb-1">
                <b-card class="small font-weight-bolder h-100" footer-class="text-muted">
                    <h6 class="mb-2 small text-dark font-weight-bolder badge badge-light-dark">ANGIN</h6>
                    <b-row v-if="fdih_status.is_agm1a">
                        <b-col cols="12">
                            <b-row class="mb-1">
                                <b-col cols="12" class="font-weight-bolder text-primary"> Kecepatan Rata-Rata </b-col>
                            </b-row>

                            <b-row class="my-1">
                                <b-col cols="3"></b-col>
                                <b-col cols="3" class="text-center">
                                    <div class="label-entry2 bg-light-primary h-100">Counter Sebelum</div>
                                </b-col>
                                <b-col cols="3" class="text-center">
                                    <div class="label-entry2 bg-light-primary h-100">Counter Dibaca</div>
                                </b-col>
                                <b-col cols="3" class="text-center">
                                    <div class="label-entry2 bg-light-primary h-100">Counter Rata</div>
                                </b-col>
                            </b-row>

                            <b-row class="my-1">
                                <b-col cols="3" class="m-auto">
                                    <div class="label-entry1">7 m <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips7" /></div>
                                    <!-- HTML tooltips -->
                                    <b-tooltip target="tooltips7" :triggers="'hover'" placement="top">
                                        <dl class="text-left">
                                            <dt>Counter Sebelum</dt>
                                            <dd>- Cup Counter dibaca 7 m 07.00 WS hari ini</dd>
                                            <dt>Counter dibaca</dt>
                                            <dd>- cup counter dibaca 7 m 14.00 WS hari ini</dd>
                                            <dt>Counter Rata</dt>
                                            <dd>
                                                - Kecepatan angin rata-rata, hasil bagi (sampai dua angka dibelakang koma dari selisih pembacaan Cup Counter yang tingginya 7 m antara jam 07.00 WS sampai jam 14.00 WS dibagi 7. Dinyatakan dalam Km/jam.
                                            </dd>
                                        </dl>
                                    </b-tooltip>
                                </b-col>
                                <b-col cols="3">
                                    <b-form-input v-on:keydown="onKeyCheck($event)" size="sm" class="text-center" v-model.number="counter_sebelum_7_1400" :disabled="!is_counter_edit"></b-form-input>
                                </b-col>
                                <b-col cols="3">
                                    <b-form-input
                                        v-on:keydown="onKeyCheck($event)"
                                        id="counter_7_1400"
                                        :formatter="format_two_decimal"
                                        size="sm"
                                        class="text-center"
                                        :state="validateState($v.counter_7_1400)"
                                        v-model.number="$v.counter_7_1400.$model"
                                    ></b-form-input>
                                </b-col>
                                <!-- tooltip CC -->
                                <b-tooltip v-if="CC.counter_7_1400.status" target="counter_7_1400" :triggers="'hover'" placement="top">
                                    {{ CC.counter_7_1400.message }}
                                </b-tooltip>
                                <b-tooltip v-if="!CC.counter_7_1400.status" :show="validateTooltipState('counter_7_1400')" :triggers="'hover'" target="counter_7_1400" placement="top" custom-class="validation-tooltip">
                                    {{ tooltipMessage("counter_7_1400") }}
                                </b-tooltip>
                                <b-col cols="3">
                                    <b-form-input size="sm" class="bg-light-success text-center" v-model.number="ws_avg_7_1400" disabled></b-form-input>
                                </b-col>
                            </b-row>

                            <b-row class="mt-1">
                                <b-col cols="3" />
                                <b-col cols="3">
                                    <feather-icon icon="HelpCircleIcon" size="14" class="bg-primary rounded-circle text-white float-right" v-b-tooltip.hover.html="{ title: fdih_counter_sebelum_1400 }" style="margin: 5px auto;" />
                                    <b-form-checkbox tabindex="-1" class="custom-control-primary float-right" name="check-button" switch v-model="is_counter_edit">
                                        <span class="switch-icon-left">
                                            <feather-icon icon="CheckIcon" />
                                        </span>
                                        <span class="switch-icon-right">
                                            <feather-icon icon="XIcon" />
                                        </span>
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>

                            <!-- Kecepatan Rata-Rata end -->

                            <hr />

                            <!-- Arah dan Kecepatan start -->
                            <b-row class="mb-1">
                                <b-col cols="12" class="font-weight-bolder text-primary"> Arah dan Kecepatan saat Peramatan </b-col>
                            </b-row>

                            <b-row class="my-1">
                                <b-col cols="4"></b-col>
                                <b-col cols="4">
                                    <div class="label-entry2 bg-light-primary">ARAH</div>
                                </b-col>
                                <b-col cols="4">
                                    <div class="label-entry2 bg-light-primary">KECEPATAN</div>
                                </b-col>
                            </b-row>

                            <b-row class="my-1">
                                <b-col cols="4" class="m-auto">
                                    <div class="label-entry1">10 m <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips8" /></div>
                                    <!-- HTML tooltips -->
                                    <b-tooltip target="tooltips8" :triggers="'hover'" placement="top">
                                        <dl class="text-left">
                                            <dt>Arah</dt>
                                            <dd>- Arah dari mana datangnya angin ketinggian 10 m</dd>
                                            <dt>Kecepatan</dt>
                                            <dd>- Kecepatan angin ketinggian 10 m dalam meter/detik (m/s)</dd>
                                        </dl>
                                    </b-tooltip>
                                </b-col>
                                <b-col cols="4">
                                    <b-form-input
                                        v-on:keydown="onKeyCheck($event)"
                                        v-on:keyup="checkValidate($event, 'wd_1400')"
                                        v-bind:class="{ rangecheck: rc_wd_1400 }"
                                        id="input_wd_1400"
                                        size="sm"
                                        class="text-center"
                                        :state="validateState($v.wd_1400)"
                                        v-model.number="$v.wd_1400.$model"
                                        v-on:keypress="isNumber($event)"
                                    >
                                    </b-form-input>
                                    <b-tooltip v-if="rc_wd_1400" target="input_wd_1400" triggers="hover">{{ rc_wd_1400_message }}</b-tooltip>
                                    <b-tooltip v-if="!rc_wd_1400" :show="validateTooltipState('wd_1400')" :triggers="'hover'" target="input_wd_1400" placement="top" custom-class="validation-tooltip">
                                        {{ tooltipMessage("wd_1400") }}
                                    </b-tooltip>
                                </b-col>
                                <b-col cols="4">
                                    <b-form-input
                                        :formatter="format_one_decimal"
                                        id="input_ws_1400"
                                        v-on:keydown="onKeyCheck($event)"
                                        v-on:keyup="checkValidate($event, 'ws_1400')"
                                        v-bind:class="{ rangecheck: rc_ws_1400 }"
                                        size="sm"
                                        class="text-center"
                                        :state="validateState($v.ws_1400)"
                                        v-model.number="$v.ws_1400.$model"
                                    ></b-form-input>
                                    <b-tooltip v-if="rc_ws_1400" target="input_ws_1400" triggers="hover">{{ rc_ws_1400_message }}</b-tooltip>
                                    <b-tooltip v-if="!rc_ws_1400" :show="validateTooltipState('ws_1400')" :triggers="'hover'" target="input_ws_1400" placement="top" custom-class="validation-tooltip">
                                        {{ tooltipMessage("ws_1400") }}
                                    </b-tooltip>
                                </b-col>
                            </b-row>
                            <!-- Arah dan Kecepatan end -->
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <!-- ANGIN end -->
        </b-row>

        <!-- button Action-->
        <b-row>
            <b-col cols="12">
                <b-card>
                    <b-row class="float-right">
                        <div>
                            <b-button @click.prevent="cancelForm" variant="danger" class="mr-2" size="sm"> Cancel</b-button>
                            <!-- <b-button v-if="this.t_data_status == 'update'" @click.prevent="validationForm" variant="warning" class="mr-2" size="sm" :disabled="!roles.isRoleEdit || !fdih_status.is_agm1a"> Edit</b-button>
              <b-button v-if="this.t_data_status == 'new'" @click.prevent="validationForm" variant="primary" class="mr-2" size="sm" :disabled="!roles.isRoleAdd || !fdih_status.is_agm1a"> Submit</b-button> -->
                            <b-button @click.prevent="validationForm" variant="primary" class="mr-2" size="sm"> Submit</b-button>
                        </div>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay>
    </div>
</template>
<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import Service from "@/api/fdihservice";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import qc_helper from "@/api/qc_helper";
import Helper from "@/helper/helper";
import Params from "@/attr/metadata/params";
import RC_helper from "@/validators/RC_helper";
import { BCard, BCardHeader, BContainer, BRow, BCol, BFormSelect, BForm, BButton, BFormGroup, BCollapse, BFormDatepicker, BFormInput, VBTooltip, BTooltip, BIcon, BFormCheckbox } from "bootstrap-vue";
// } from '@validations'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { fdih_counter_sebelum_1400 } from "./fdih_tooltip_content_1400";
import {
    GC_FDIH_PS_1400_TBK,
    GC_FDIH_PS_1400_TBB,
    GC_FDIH_1400_ANGIN_COUNTER_BACA,
    GC_FDIH_SM_1400_Tmax,
    GC_FDIH_SM_1400_Treset,
    GC_FDIH_1400_ANGIN_KECEPATAN,
    GC_FDIH_1400_ANGIN_ARAH,
    RC_FDIH_ROLES,
    RC_FDIH,
    GC_FDIH,
    GC_FDIH_SM_1800_Treset,
} from "@/validators/fdihvalidator";
export default {
    components: {
        BCardActions,
        BCard,
        BCardHeader,
        BContainer,
        BRow,
        BCol,
        BFormSelect,
        BForm,
        BButton,
        BFormGroup,
        BCollapse,
        BFormDatepicker,
        BFormInput,
        VBTooltip,
        BTooltip,
        BIcon,
        BFormCheckbox,
    },
    data() {
        return {
            showLoading: false,
            RC_helper_agm1a: { ...RC_helper },
            flagdata_agm1a: null,
            keadaan_cuaca: "",
            tbk_1c2m_1400: 0,
            tbb_1c2m_1400: 0,
            rh_1c2m_1400: 0,
            ws_avg_7_1400: 0,
            counter_7_1400: "",
            CC: {
                tbk_1c2m_1400: { status: false, message: "" },
                tbb_1c2m_1400: { status: false, message: "" },
                counter_7_1400: { status: false, message: "" },
            },
            counter_sebelum_7_1400: 0,
            wd_1400: 0,
            ws_1400: 0,
            t_min_uji_1400: 0,
            t_reset_uji_1400: 0,
            co_cu_1400: "",
            opt_keadaan_cuaca_peramatan: [
                { value: "-", text: "-" },
                { value: 0, text: "0 - Langit tak berawan / sedikit berawan" },
                { value: 1, text: "1 - Perawanan berganti-ganti / berubah-ubah" },
                { value: 2, text: "2 - Langit berawan seluruhnya" },
                { value: 3, text: "3 - Badai debu" },
                { value: 4, text: "4 - Kabut/kabut yang tebal" },
                { value: 5, text: "5 -  Hujan rintik-rintik" },
                { value: 6, text: "6 - Hujan" },
                { value: 7, text: "7 - Salju" },
                { value: 8, text: "8 - Hujan tiba-tiba " },
                { value: 9, text: "9 - Udara buruk dengan / tanpa hujan" },
            ],

            fdih_status: {
                is_fklim: true,
                is_iklim_mikro: true,
                is_agm1a: true,
                is_agm1b: true,
                is_suhu_tanah: true,
                is_psychrometer_assman: true,
                is_op_penguapan: true,
                is_piche_penguapan: true,
                is_lysimeter: true,
                is_gunbellani: true,
            },

            rc_t_min_uji_1400: false,
            rc_t_min_uji_1400_message: "",
            rc_t_reset_uji_1400: false,
            rc_t_reset_uji_1400_message: "",
            rc_ws_1400: false,
            rc_ws_1400_message: "",
            rc_wd_1400: false,
            rc_wd_1400_message: "",

            rc_tbk_1c2m_1400: false,
            rc_tbk_1c2m_1400_message: "",
            rc_tbb_1c2m_1400: false,
            rc_tbb_1c2m_1400_message: "",

            is_counter_edit: false,

            fdih_counter_sebelum_1400,

            DATA_RC: {},
            LIST_QC_FIX: {},
            LIST_QC_NOTIFICATION_FIX: {},

            agm1a_histories_last: null,
        };
    },
    mixins: [validationMixin],
    validations: {
        tbk_1c2m_1400: {
            required,
            GC_FDIH_PS_1400_TBK,
            consistency(val) {
                if (this.tbb_1c2m_1400 && this.tbb_1c2m_1400 != 9999 && val != 9999) {
                    let result = val >= this.tbb_1c2m_1400;
                    this.CC.tbk_1c2m_1400.message = "Suhu Bola Kering >= Suhu Bola Basah";
                    this.CC.tbk_1c2m_1400.status = !result;
                    return result;
                }
                this.CC.tbk_1c2m_1400.message = "";
                this.CC.tbk_1c2m_1400.status = false;
                return true;
            },
        },
        tbb_1c2m_1400: {
            required,
            GC_FDIH_PS_1400_TBB,
            consistency(val) {
                if (this.tbk_1c2m_1400 && this.tbk_1c2m_1400 != 9999 && val != 9999) {
                    let result = val <= this.tbk_1c2m_1400;
                    this.CC.tbb_1c2m_1400.message = "Suhu Bola Kering >= Suhu Bola Basah";
                    this.CC.tbb_1c2m_1400.status = !result;
                    return result;
                }
                this.CC.tbb_1c2m_1400.message = "";
                this.CC.tbb_1c2m_1400.status = false;
                return true;
            },
        },

        counter_7_1400: {
            required,
            GC_FDIH_1400_ANGIN_COUNTER_BACA,
            consistency(val) {
                if (this.counter_sebelum_7_1400 && this.counter_sebelum_7_1400 != 9999) {
                    let result = val >= this.counter_sebelum_7_1400;
                    this.CC.counter_7_1400.message = "Counter Dibaca >= Counter Sebelum";
                    this.CC.counter_7_1400.status = !result;
                    return result;
                }
                this.CC.counter_7_1400.message = "";
                this.CC.counter_7_1400.status = false;
                return true;
            },
        },
        wd_1400: { required, GC_FDIH_1400_ANGIN_ARAH },
        ws_1400: { required, GC_FDIH_1400_ANGIN_KECEPATAN },
        t_min_uji_1400: { required, GC_FDIH_SM_1400_Tmax },
        t_reset_uji_1400: { required, GC_FDIH_SM_1400_Treset },

        co_cu_1400: { required },
    },
    props: ["data_input", "t_station", "t_station_id", "t_wmoid", "t_observer", "t_time", "t_date", "t_data_existing", "t_all_data", "t_data_requirement", "t_path", "t_id_form", "t_data_status", "roles", "t_bmkgentry", "d_station", "t_qc_histories"],

    mounted() {
        this.initialize();
    },
    //rc
    methods: {
        initialize() {
            this.setQCData();
            if (this.t_bmkgentry) {
                (this.fdih_status.is_fklim = this.t_bmkgentry.is_fklim),
                    (this.fdih_status.is_iklim_mikro = this.t_bmkgentry.is_iklim_mikro),
                    (this.fdih_status.is_agm1a = this.t_bmkgentry.is_agm1a),
                    (this.fdih_status.is_agm1b = this.t_bmkgentry.is_agm1b),
                    (this.fdih_status.is_suhu_tanah = this.t_bmkgentry.is_suhu_tanah),
                    (this.fdih_status.is_psychrometer_assman = this.t_bmkgentry.is_psychrometer_assman),
                    (this.fdih_status.is_op_penguapan = this.t_bmkgentry.is_op_penguapan),
                    (this.fdih_status.is_piche_penguapan = this.t_bmkgentry.is_piche_penguapan),
                    (this.fdih_status.is_lysimeter = this.t_bmkgentry.is_lysimeter),
                    (this.fdih_status.is_gunbellani = this.t_bmkgentry.is_gunbellani);
            }
        },

        async setQCData() {
            if (this.d_station) {
                let qc_data_agm1a = this.d_station["BmkgSatu.metadata.qc.IAgm1aqc"];
                let qc_params_agm1a = Params.GET_PARAMS("agm1a");
                let qc_key_agm1a = Params.GET_KEY("agm1a");
                this.RC_helper_agm1a.setData(qc_data_agm1a, qc_params_agm1a, qc_key_agm1a);
                console.log("this.RC_helper_agm1a", this.RC_helper_agm1a.getData());
            }
        },

        onKeyCheck(event) {
            if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                event.preventDefault();
            }
        },

        format_one_decimal(e) {
            if (Math.round(e * 10) / 10 != e) {
                let text = String(e);
                let filter = text.substring(0, text.length - 1);
                return filter;
            } else {
                if (Math.floor(e) === e) {
                    return e;
                } else {
                    if (e.toString().indexOf(".") > 0) {
                        let count = e.toString().split(".")[1].length || 0;
                        return count > 1 ? parseFloat(e).toFixed(1) : e;
                    } else {
                        return e;
                    }
                }
                return e;
            }
        },
        format_two_decimal(e, event) {
            if (Math.round(e * 100) / 100 != e) {
                let text = String(e);
                let filter = text.substring(0, text.length - 1);
                return filter;
            } else {
                if (Math.floor(e) === e) {
                    return e;
                } else {
                    if (e.toString().indexOf(".") > 0) {
                        let count = e.toString().split(".")[1].length || 0;
                        return count > 2 ? parseFloat(e).toFixed(2) : e;
                    } else {
                        return e;
                    }
                }

                return e;
            }
        },
        isNumber(evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            }
            if (evt.key == "." || evt.key == ",") {
                return evt.preventDefault();
            } else {
                return true;
            }
        },
        checkValidate: function (event, type) {
            let value = event.target.value;
            switch (type) {
                case "tbk_1c2m_1400":
                    var raw = this.RC_helper_agm1a.check(value, "tbk_1c2m_1400"); //RC_FDIH(value, "t_min_uji_1400");
                    var gc = GC_FDIH_PS_1400_TBK(value);
                    this.rc_tbk_1c2m_1400 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbk_1c2m_1400_message = raw.message;
                    this.setDataRC(type, this.rc_tbk_1c2m_1400, this.rc_tbk_1c2m_1400_message, raw.roles);
                    break;
                case "tbb_1c2m_1400":
                    var raw = this.RC_helper_agm1a.check(value, "tbb_1c2m_1400"); //RC_FDIH(value, "t_min_uji_1400");
                    var gc = GC_FDIH_PS_1400_TBB(value);
                    this.rc_tbb_1c2m_1400 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbb_1c2m_1400_message = raw.message;
                    this.setDataRC(type, this.rc_tbb_1c2m_1400, this.rc_tbb_1c2m_1400_message, raw.roles);
                    break;

                case "t_min_uji_1400":
                    var raw = this.RC_helper_agm1a.check(value, "t_min_uji_1400"); //RC_FDIH(value, "t_min_uji_1400");
                    var gc = GC_FDIH_SM_1400_Tmax(value);
                    this.rc_t_min_uji_1400 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_t_min_uji_1400_message = raw.message;
                    this.setDataRC(type, this.rc_t_min_uji_1400, this.rc_t_min_uji_1400_message, raw.roles);
                    break;
                case "t_reset_uji_1400":
                    var raw = this.RC_helper_agm1a.check(value, "t_reset_uji_1400"); //RC_FDIH(value, "t_reset_uji_1400");
                    var gc = GC_FDIH_SM_1400_Treset(value);
                    this.rc_t_reset_uji_1400 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_t_reset_uji_1400_message = raw.message;
                    this.setDataRC(type, this.rc_t_reset_uji_1400, this.rc_t_reset_uji_1400_message, raw.roles);
                    break;
                case "ws_1400":
                    var raw = this.RC_helper_agm1a.check(value, "ws_1400"); //RC_FDIH(value, "ws_0700");
                    var gc = GC_FDIH_1400_ANGIN_KECEPATAN(value);
                    this.rc_ws_1400 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_ws_1400_message = raw.message;
                    this.setDataRC(type, this.rc_ws_1400, this.rc_ws_1400_message, raw.roles);
                    break;
                case "wd_1400":
                    var raw = this.RC_helper_agm1a.check(value, "wd_1400"); //RC_FDIH(value, "ws_0700");
                    var gc = GC_FDIH_1400_ANGIN_ARAH(value);
                    this.rc_wd_1400 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_wd_1400_message = raw.message;
                    this.setDataRC(type, this.rc_wd_1400, this.rc_wd_1400_message, raw.roles);
                    break;
                default:
                    break;
            }
        },

        setDataRC(type, status, message, roles) {
            if (status) {
                this.DATA_RC[type] = {
                    status: status,
                    message: message,
                    roles: roles,
                };
            } else {
                if (this.DATA_RC.hasOwnProperty(type)) {
                    delete this.DATA_RC[type];
                }
            }
        },

        tooltipMessage(attr) {
            return GC_FDIH(attr).message;
        },
        validateTooltipState(name) {
            const { $error } = this.$v[name];
            return false; //$error;
        },

        clearData() {
            this.keadaan_cuaca = "";
            this.tbk_1c2m_1400 = "";
            this.tbb_1c2m_1400 = "";
            this.rh_1c2m_1400 = "";
            this.ws_avg_7_1400 = "";
            this.counter_7_1400 = "";
            this.counter_sebelum_7_1400 = "";
            this.wd_1400 = "";
            this.ws_1400 = "";
            this.t_min_uji_1400 = "";
            this.t_reset_uji_1400 = "";
            this.co_cu_1400 = "";
            this.LIST_QC_FIX = {};
            this.LIST_QC_NOTIFICATION_FIX = {};
            this.flagdata_agm1a = null;
            this.$v.$reset();
        },
        validateState(item) {
            const { $dirty, $error } = item;
            return $dirty ? !$error : null;
        },
        cancelForm() {
            this.clearData();
            this.$emit("form", "test");
        },
        validationForm() {
            // this.$v.$touch();
            // this.$v.tbk_1c2m_1400.$touch();
            // this.$v.tbb_1c2m_1400.$touch();
            // this.$v.counter_7_1400.$touch();
            // this.$v.wd_1400.$touch();
            // this.$v.ws_1400.$touch();
            // this.$v.t_min_uji_1400.$touch();
            // this.$v.t_reset_uji_1400.$touch();
            // this.$v.co_cu_1400.$touch();

            // if (
            //   this.$v.tbk_1c2m_1400.$anyError ||
            //   this.$v.tbb_1c2m_1400.$anyError ||
            //   this.$v.counter_7_1400.$anyError ||
            //   this.$v.wd_1400.$anyError ||
            //   this.$v.ws_1400.$anyError ||
            //   this.$v.t_min_uji_1400.$anyError ||
            //   this.$v.t_reset_uji_1400.$anyError ||
            //   this.$v.co_cu_1400.$anyError
            // ) {
            // let valid = false
            // let messageError = ""
            // for (let param in this.$v) {
            //     if (!param.startsWith("$")) {
            //       let hasRequired = this.$v[param].hasOwnProperty("required")
            //       // console.log('validationForm - ', param, hasRequired)
            //       if (hasRequired) {
            //           let required = this.$v[param].required
            //           valid = required ? (this.$v[param].$anyError ? false : true) : true
            //           messageError = valid ? "" : "Data "+param+" tidak valid. Periksa kembali inputan anda"
            //       }
            //       else {
            //           valid = this.$v[param].$anyError ? false : true
            //           messageError = valid ? "" : "Data "+param+" tidak valid. Periksa kembali inputan anda"
            //       }
            //       // let eror = ""
            //       if (!valid) {
            //           break;
            //       }
            //     }
            // }

            // let valid = true;
            // let messageError = "Data tidak valid. Periksa kembali inputan anda";

            // if (this.$v.$invalid) {
            //   console.log("this.$v", this.$v);
            //   valid = false;
            // }

            let valid = true;
            let messageError = "";

            if (this.fdih_status.is_agm1a) {
                this.$v.tbk_1c2m_1400.$touch();
                this.$v.tbb_1c2m_1400.$touch();
                this.$v.counter_7_1400.$touch();
                this.$v.wd_1400.$touch();
                this.$v.ws_1400.$touch();
                this.$v.t_min_uji_1400.$touch();
                this.$v.t_reset_uji_1400.$touch();
                this.$v.co_cu_1400.$touch();

                if (
                    this.$v.tbk_1c2m_1400.$invalid ||
                    this.$v.tbb_1c2m_1400.$invalid ||
                    this.$v.counter_7_1400.$invalid ||
                    this.$v.wd_1400.$invalid ||
                    this.$v.ws_1400.$invalid ||
                    this.$v.t_min_uji_1400.$invalid ||
                    this.$v.t_reset_uji_1400.$invalid ||
                    this.$v.co_cu_1400.$invalid
                ) {
                    valid = false;
                    messageError = "Data tidak valid. Periksa kembali inputan anda";
                }
            }

            if (!valid) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: messageError, //"Data tidak valid. Periksa kembali inputan anda",
                        icon: "BellIcon",
                        variant: "danger",
                    },
                });
            } else {
                let qc_flag = Object.keys(this.DATA_RC).length > 0 ? 1 : 0;
                let list_qc = this.getQCExist(); //qc_flag > 0 ? this.getQCExist() : null;
                this.LIST_QC_FIX = list_qc && Object.keys(list_qc).length > 0 ? list_qc : null;
                let msg_html = this.getHtmlMessage(list_qc);

                if (qc_flag == 1) {
                    let qc_histories = {
                        before: null,
                        after: list_qc,
                    };

                    this.$swal({
                        title: "Apakah Anda yakin untuk simpan data ?",
                        text: "Ada Data Terkoreksi Range Check ",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Submit",
                        cancelButtonText: "Cek Data",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        customClass: {
                            confirmButton: "btn btn-primary",
                            cancelButton: "btn btn-outline-danger ml-1",
                        },
                        buttonsStyling: false,
                    }).then((result) => {
                        if (result.value) {
                            this.submitFDIH1400();
                        } else if (result.dismiss === "cancel") {
                            return;
                        }
                    });
                } else {
                    this.submitFDIH1400();
                }
            }
        },

        getHtmlMessage(data) {
            let html = "";
            if (data && data.length > 0) {
                for (let dt of data) {
                    html += "<div>" + dt.atribut + ": " + dt.message + " </div>";
                }
                return html;
            }

            return "<div />";
        },

        getQCExist() {
            // if (Object.keys(this.DATA_RC).length > 0) {
            let qc_agm1a = Object.entries(this.DATA_RC)
                .filter((e) => e[1].status && (e[0] == "t_min_uji_1400" || e[0] == "t_reset_uji_1400" || e[0] == "ws_1400" || e[0] == "wd_1400" || e[0] == "tbk_1c2m_1400" || e[0] == "tbb_1c2m_1400"))
                .map((e) => {
                    let param = e[0];
                    let content = e[1];
                    return {
                        raw_type: "AGM1A",
                        time: "1400ws",
                        atribut: "agm1a_" + param,
                        atribut_name: param,
                        value: this[param],
                        roles: content.roles, //RC_FDIH_ROLES(param),
                        type: "range_check",
                        message: content.message,
                    };
                });

            let allform = {};

            // if (qc_agm1a && qc_agm1a.length > 0) allform['agm1a'] = qc_agm1a
            if (qc_agm1a && qc_agm1a.length > 0) {
                let exist = this.t_qc_histories?.agm1a;
                exist = exist?.after;
                if (exist && exist.length > 0) {
                    var filtered = exist.filter(function (e) {
                        return (
                            e.atribut != "t_min_uji_1400" &&
                            e.atribut != "agm1a_t_min_uji_1400" &&
                            e.atribut != "t_reset_uji_1400" &&
                            e.atribut != "agm1a_t_reset_uji_1400" &&
                            e.atribut != "ws_1400" &&
                            e.atribut != "agm1a_ws_1400" &&
                            e.atribut != "wd_1400" &&
                            e.atribut != "agm1a_wd_1400" &&
                            (e.atribut != "tbk_1c2m_1400") & (e.atribut != "agm1a_tbk_1c2m_1400") &&
                            e.atribut != "tbb_1c2m_1400" &&
                            e.atribut != "agm1a_tbb_1c2m_1400"
                        );
                    });
                    for (let data of qc_agm1a) {
                        var index = filtered.findIndex((x) => (x.hasOwnProperty("atribut_name") ? x.atribut_name == data.atribut_name : x.atribut == data.atribut_name));
                        if (index === -1) {
                            filtered.push(data);
                        } else {
                            filtered[index]["atribut"] = data["atribut"];
                            filtered[index]["atribut_name"] = data["atribut_name"];
                        }
                    }
                    allform["agm1a"] = filtered;
                } else {
                    allform["agm1a"] = qc_agm1a;
                }
            } else {
                // ketika current kosong, tetapi masih ada histori yg lama
                let exist = this.t_qc_histories?.agm1a;
                exist = exist?.after;
                if (exist && exist.length > 0) {
                    var filtered = exist.filter(function (e) {
                        return (
                            e.atribut != "t_min_uji_1400" &&
                            e.atribut != "agm1a_t_min_uji_1400" &&
                            e.atribut != "t_reset_uji_1400" &&
                            e.atribut != "agm1a_t_reset_uji_1400" &&
                            e.atribut != "ws_1400" &&
                            e.atribut != "agm1a_ws_1400" &&
                            e.atribut != "wd_1400" &&
                            e.atribut != "agm1a_wd_1400" &&
                            (e.atribut != "tbk_1c2m_1400") & (e.atribut != "agm1a_tbk_1c2m_1400") &&
                            e.atribut != "tbb_1c2m_1400" &&
                            e.atribut != "agm1a_tbb_1c2m_1400"
                        );
                    });
                    allform["agm1a"] = filtered;
                } else {
                    allform["agm1a"] = null;
                }
            }

            return allform;
            // }
            // return null;
        },

        submitFDIH1400() {
            const fdih_id = this.t_id_form; //"1_fdih_" + this.t_date;
            const data_timestamp = this.t_date;
            let post_data = {
                data_timestamp: data_timestamp,
                "@type": "Fdih",
                id: fdih_id,
                observer_id: this.t_observer.id,
                observer_name: this.t_observer.observer,
                m_1400ws: {
                    tbk_1c2m_1400: this.tbk_1c2m_1400,
                    tbb_1c2m_1400: this.tbb_1c2m_1400,
                    rh_1c2m_1400: this.rh_1c2m_1400,
                    ws_avg_7_1400: this.ws_avg_7_1400,
                    counter_7_1400: this.counter_7_1400,
                    counter_sebelum_7_1400: this.counter_sebelum_7_1400,
                    wd_1400: this.wd_1400,
                    ws_1400: this.ws_1400,
                    t_min_uji_1400: this.t_min_uji_1400,
                    t_reset_uji_1400: this.t_reset_uji_1400,
                    co_cu_1400: this.co_cu_1400,
                    flagm_1400: 1,
                },
            };

            if (this.t_data_status == "update") {
                post_data = {
                    data_timestamp: data_timestamp,
                    observer_id: this.t_observer.id,
                    observer_name: this.t_observer.observer,
                    m_1400ws: {
                        tbk_1c2m_1400: this.tbk_1c2m_1400,
                        tbb_1c2m_1400: this.tbb_1c2m_1400,
                        rh_1c2m_1400: this.rh_1c2m_1400,
                        ws_avg_7_1400: this.ws_avg_7_1400,
                        counter_7_1400: this.counter_7_1400,
                        counter_sebelum_7_1400: this.counter_sebelum_7_1400,
                        wd_1400: this.wd_1400,
                        ws_1400: this.ws_1400,
                        t_min_uji_1400: this.t_min_uji_1400,
                        t_reset_uji_1400: this.t_reset_uji_1400,
                        co_cu_1400: this.co_cu_1400,
                        flagm_1400: 1,
                    },
                };
            }

            let list_qc_notification = {};
            this.LIST_QC_NOTIFICATION_FIX = {};
            let regionId = this.t_path.split("/")[1];
            if (this.LIST_QC_FIX && Object.keys(this.LIST_QC_FIX).length > 0) {
                if (this.LIST_QC_FIX.hasOwnProperty("agm1a")) {
                    post_data["BmkgSatu.behaviors.qc.IFdih_Agm1a_qc"] = {
                        qc_flag_agm1a: this.flagdata_agm1a == 2 ? 2 : this.LIST_QC_FIX.agm1a && this.LIST_QC_FIX.agm1a.length > 0 ? 1 : 0,
                        qc_flag_agm1a_1400: this.LIST_QC_FIX.agm1a && this.LIST_QC_FIX.agm1a.length > 0 ? 1 : 0,
                        qc_histories_agm1a: JSON.stringify({ before: null, after: this.LIST_QC_FIX.agm1a }),
                    };

                    this.LIST_QC_NOTIFICATION_FIX["agm1a"] = [
                        "agm1a_" + this.t_id_form, // id entry
                        "AGM1A", // type form entry
                        data_timestamp, // tanggal entry
                        "1400ws", // jam entry
                        JSON.stringify({ before: null, after: this.LIST_QC_FIX.agm1a }), // qc histories
                        1, // qc_flag
                        this.t_station_id, // station_id
                        this.t_station.text, //station_name
                        this.t_observer.id, // observer_id
                        this.t_observer.observer, //observer_name
                        regionId, // region_id
                        this.t_path + "/" + this.t_id_form, // path
                    ];
                }
            }

            if (this.t_data_status == "update") {
                Service.setFDIH07301800(this.t_path + "/" + this.t_id_form, post_data)
                    .then((response) => {
                        if (response.status == 201 || response.status == 200 || response.status == 204) {
                            this.finishingRequest();
                            this.$emit("form", "test");
                            let titleMessage = "Input Data Berhasil!";
                            let txtMessage = "Input Data FDIH";
                            if (this.t_data_status == "update") {
                                titleMessage = "Update FDIH 1400 berhasil!";
                                txtMessage = "Update FDIH 1400";
                            }
                            this.$swal({
                                title: titleMessage,
                                text: txtMessage,
                                icon: "success",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        }
                    })
                    .catch((error) => {
                        let titleMessage = "";
                        let txtMessage = "";
                        if (error.response.status == 401) {
                            txtMessage = "Sesi anda telah habis. Logout dan silahkan login kembali";
                            if (this.t_data_status == "update") {
                                titleMessage = "Gagal Update. Unauthorized! ";
                            } else {
                                titleMessage = "Gagal proses submit. Unauthorized!";
                            }
                            this.$swal({
                                title: titleMessage,
                                text: txtMessage,
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        } else if (error.response.status == 404) {
                            txtMessage = "404. Object Not Found";
                            if (this.t_data_status == "update") {
                                titleMessage = "Gagal Update!";
                            } else {
                                titleMessage = "Gagal entry!";
                            }
                            this.$swal({
                                title: titleMessage,
                                text: txtMessage,
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        } else {
                            let error_msg = error.response?.data?.error?.message || ("" + error.response?.status)
                            
                            txtMessage = error_msg;
                            if (this.t_data_status == "update") {
                                titleMessage = "Gagal Update!";
                            } else {
                                titleMessage = "Gagal entry!";
                            }
                            this.$swal({
                                title: titleMessage,
                                text: txtMessage,
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        }
                    });
            } else if (this.t_data_status == "new") {
                Service.setNewFDIH07001800(this.t_path, post_data)
                    .then((response) => {
                        if (response.status == 201 || response.status == 200 || response.status == 204) {
                            this.finishingRequest();
                            this.clearData();
                            this.$emit("form", "test");
                            let titleMessage = "Input Data Berhasil!";
                            let txtMessage = "Input Data FDIH";
                            this.$swal({
                                title: titleMessage,
                                text: txtMessage,
                                icon: "success",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });

                            this.showLoading = false;
                        }
                    })
                    .catch((error) => {
                        if (error.response.status == 401) {
                            this.$swal({
                                title: "401. Unauthorized!",
                                text: "Sesi anda telah habis. Logout dan silahkan login kembali",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        } else if (error.response.status == 404) {
                            this.$swal({
                                title: "Gagal entry!",
                                text: "404. Object Not Found",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        } else {
                            let error_msg = error.response?.data?.error?.message || ("" + error.response?.status)
                            
                            this.$swal({
                                title: "Gagal entry!",
                                text: error_msg,
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        }
                        this.showLoading = false;
                    });
            }
        },

        async finishingRequest() {
            if (this.LIST_QC_NOTIFICATION_FIX && Object.keys(this.LIST_QC_NOTIFICATION_FIX).length > 0) {
                for (let key in this.LIST_QC_NOTIFICATION_FIX) {
                    new Promise(async (resolve) => {
                        let data = await qc_helper.editQCNotification(
                            this.LIST_QC_NOTIFICATION_FIX[key][0],
                            this.LIST_QC_NOTIFICATION_FIX[key][1],
                            this.LIST_QC_NOTIFICATION_FIX[key][2],
                            this.LIST_QC_NOTIFICATION_FIX[key][3],
                            this.LIST_QC_NOTIFICATION_FIX[key][4],
                            this.LIST_QC_NOTIFICATION_FIX[key][5],
                            this.LIST_QC_NOTIFICATION_FIX[key][6],
                            this.LIST_QC_NOTIFICATION_FIX[key][7],
                            this.LIST_QC_NOTIFICATION_FIX[key][8],
                            this.LIST_QC_NOTIFICATION_FIX[key][9],
                            this.LIST_QC_NOTIFICATION_FIX[key][10],
                            this.LIST_QC_NOTIFICATION_FIX[key][11]
                        );
                        resolve(data);
                    });
                }
            }
        },

        checkValidateAll() {
            this.checkValidate({ target: { value: this.tbk_1c2m_1400 } }, "tbk_1c2m_1400");
            this.checkValidate({ target: { value: this.tbb_1c2m_1400 } }, "tbb_1c2m_1400");
            this.checkValidate({ target: { value: this.ws_1400 } }, "ws_1400");
            this.checkValidate({ target: { value: this.t_min_uji_1400 } }, "t_min_uji_1400");
            this.checkValidate({ target: { value: this.t_reset_uji_1400 } }, "t_reset_uji_1400");
        },
    },
    watch: {
        data_input: {
            deep: true,
            handler() {
                // console.log("data_input: ", this.data_input.i_time);
                if (this.data_input.i_time == "1400ws") {
                    this.clearData();
                }
            },
        },

        t_bmkgentry: function (val) {
            this.initialize();
        },

        t_all_data: function (val) {
            this.flagdata_agm1a = val["BmkgSatu.behaviors.qc.IFdih_Agm1a_qc"].qc_flag_agm1a;
        },

        t_data_requirement: function (val) {
            // this.counter_sebelum_7_1400 = val.fdih_ws1400_counter_7_1400 == "null" ? 0 : val.fdih_ws1400_counter_7_1400;
            if (val.fdih_ws1400_counter_7_1400 != "null" && val.fdih_ws1400_counter_7_1400 != null) {
                if (this.counter_sebelum_7_1400 === null || this.counter_sebelum_7_1400 === "null" || this.counter_sebelum_7_1400 === "") {
                    this.counter_sebelum_7_1400 = val.fdih_ws1400_counter_7_1400;
                }
            }
            this.$v.$reset();
            this.checkValidateAll();
            if (this.t_data_status == "update") {
                this.$v.$touch();
            }
        },
        t_data_existing: function (val) {
            this.tbk_1c2m_1400 = val.tbk_1c2m_1400;
            this.tbb_1c2m_1400 = val.tbb_1c2m_1400;
            this.rh_1c2m_1400 = val.rh_1c2m_1400;
            this.ws_avg_7_1400 = val.ws_avg_7_1400;
            this.counter_7_1400 = val.counter_7_1400;
            this.counter_sebelum_7_1400 = val.counter_sebelum_7_1400;
            this.wd_1400 = val.wd_1400;
            this.ws_1400 = val.ws_1400;
            this.t_min_uji_1400 = val.t_min_uji_1400;
            this.t_reset_uji_1400 = val.t_reset_uji_1400;
            this.co_cu_1400 = val.co_cu_1400;

            // tambahan dari Pargol
            this.$v.$touch();
        },
        tbk_1c2m_1400: function (val) {
            if (val == 9999) {
                this.rh_1c2m_1400 = 9999;
            } else {
                var Es = Helper.rumus_Es(this.tbb_1c2m_1400, val);
                var E = Helper.rumus_E(val).toFixed(2);
                this.rh_1c2m_1400 = this.tbb_1c2m_1400 == 9999 ? 9999 : Math.round((Es / E) * 100); //.toFixed(1);
            }
        },
        tbb_1c2m_1400: function (val) {
            if (val == 9999) {
                this.rh_1c2m_1400 = 9999;
            } else {
                var Es = Helper.rumus_Es(val, this.tbk_1c2m_1400);
                var E = Helper.rumus_E(this.tbk_1c2m_1400).toFixed(2);
                this.rh_1c2m_1400 = this.tbk_1c2m_1400 == 9999 ? 9999 : Math.round((Es / E) * 100); //.toFixed(1);
            }
        },

        is_counter_edit: function (val) {
            if (!val) {
                if (this.t_data_existing) {
                    this.counter_sebelum_7_1400 = this.t_data_existing.counter_sebelum_7_1400;
                }
                if (this.t_data_requirement) {
                    this.counter_sebelum_7_1400 = this.t_data_requirement.fdih_ws1400_counter_7_1400 == "null" ? 0 : this.t_data_requirement.fdih_ws1400_counter_7_1400;
                }

                if (this.counter_7_1400 != 9999 && this.counter_sebelum_7_1400 != 9999) {
                    this.ws_avg_7_1400 = parseFloat(((this.counter_7_1400 - this.counter_sebelum_7_1400) / 7).toFixed(3)).toFixed(2);
                }
            }
        },

        counter_sebelum_7_1400: function (val) {
            if (val == 9999) {
                this.ws_avg_7_1400 = 9999;
            } else {
                this.ws_avg_7_1400 = this.counter_7_1400 == 9999 ? 9999 : parseFloat(((this.counter_7_1400 - val) / 7).toFixed(3)).toFixed(2);
            }
        },

        counter_7_1400: function (val) {
            if (val == 9999) {
                this.ws_avg_7_1400 = 9999;
            } else {
                this.ws_avg_7_1400 = this.counter_sebelum_7_1400 == 9999 ? 9999 : parseFloat(((val - this.counter_sebelum_7_1400) / 7).toFixed(3)).toFixed(2);
            }
        },
    },
    directives: {
        "b-tooltip": VBTooltip,
        Ripple,
    },
};
</script>
<style scoped>
.label-entry2 {
    text-align: center;
    color: #3b4253;
    font-weight: 500;
    width: 100%;
    border: 2px solid #6e6b7b;
    border-radius: 3px;
    padding: 4px;
}
.label-entry1 {
    /* text-align: center; */
    color: #3b4253;
    font-weight: 500;
    width: 100%;
    /* border: 2px solid #6e6b7b; */
    border-radius: 3px;
    padding: 4px;
}

.input-dark-grey {
    background-color: #ebedf3;
}
#input_ws_1400.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
.bg-light-primary {
    color: #000000 !important;
}
.bg-light-success {
    color: #000000 !important;
}
</style>

<style>
/* .rangecheck {
    background-color: rgb(210, 248, 37) !important;
  } */
#input_t_min_uji_1400.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}

#input_t_reset_uji_1400.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}

#input_tbk_1c2m_1400.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}

#input_tbb_1c2m_1400.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}

#input_wd_1400.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
</style>
