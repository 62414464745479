<template>
  <div>
    <b-row class="mt-n2">
      <b-col cols="12">
        <b-card class="text-dark font-weight-bolder">
          <b-row class="">
            <b-col sm="2 mb-2">
              <b-form-group label-for="select-form" size="sm">
                <label class="label-required">Petugas Pasang Sampling
                  <span class="text-danger">*</span>
                </label>
                <v-select v-model="ptgs_pasang_sampling" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="observer" :options="list_observer" class="select-size-sm">
                </v-select>
                <label v-if="!ptgs_pasang_sampling || !ptgs_angkat_sampling 
                || !ptgs_analis_lab || !tgl_pasang || !jam_pasang || !tgl_angkat || !jam_angkat"
                class="label">Wajib Diisi <span class="text-danger">*</span></label>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label-for="select-form" size="sm">
                <label class="label-required">Petugas Angkat Sampling
                  <span class="text-danger">*</span>
                </label>
                <v-select v-model="ptgs_angkat_sampling" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="observer" :options="list_observer" class="select-size-sm">
                </v-select>
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group label-for="select-form" size="sm">
                <label class="label-required">Petugas Analis Lab
                  <span class="text-danger">*</span>
                </label>
                <v-select v-model="ptgs_analis_lab" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text"
                  :options="list_penyelia" class="select-size-sm">
                </v-select>
              </b-form-group>
            </b-col>

            <b-col sm="3">
              <b-row>
                <b-col cols="8">
                  <b-form-group  label-for="datepicker-4" sm="2">
                    <label class="label-required">Tanggal Pemasangan
                      <span class="text-danger">*</span>
                    </label>
                    <b-form-datepicker id="datepicker-4" :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }" locale="en" size="sm" placeholder="tanggal" v-model="tgl_pasang" :disabled="dateDisable"
                      class="select-size-sm" />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group  label-for="datepicker-4" sm="2">
                    <label class="label-required">Jam
                      <span class="text-danger">*</span>
                    </label>
                    <b-form-select v-model="jam_pasang" class="col-12 select-size-sm" size="sm" style="height: 33px;">
                      <option disabled value="">Pilih Jam</option>
                      <option value="08">08:00</option>
                      <option value="09">09:00</option>
                      <option value="10">10:00</option>
                      <option value="11">11:00</option>
                      <option value="12">12:00</option>
                      <option value="13">13:00</option>
                      <option value="14">14:00</option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col sm="3">
              <b-row>
                <b-col cols="8">
                  <b-form-group  label-for="datepicker-5" sm="2">
                    <label class="label-required">Tanggal Pengangkatan
                      <span class="text-danger">*</span>
                    </label>
                    <b-form-datepicker id="datepicker-5" :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }" locale="en" size="sm" placeholder="tanggal" v-model="tgl_angkat" :disabled="dateDisable"
                      :min="min" class="select-size-sm" />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group  label-for="datepicker-5" sm="2">
                    <label class="label-required">Jam
                      <span class="text-danger">*</span>
                    </label>
                    <b-form-select v-model="jam_angkat" class="col-12 select-size-sm" size="sm" style="height: 33px;">
                      <option disabled value="">Pilih Jam</option>
                      <option value="08">08:00</option>
                      <option value="09">09:00</option>
                      <option value="10">10:00</option>
                      <option value="11">11:00</option>
                      <option value="12">12:00</option>
                      <option value="13">13:00</option>
                      <option value="14">14:00</option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>

        <b-card class="text-dark font-weight-bolder mt-n2">
          <b-row>
            <b-col sm="3 mb-n2">
              <b-form-group label="ID Sample" label-for="id-sample" label-cols-md="4">
                <b-form-input id="id-sample" v-model="so2no2_id_sampel" disabled class="font-weight-bolder select-grey" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-row>
          <b-col cols="6">
            <b-card class="text-dark font-weight-bolder mt-n2 mr-n1 h-100">
              <b-row>
                <b-col sm="5">
                  <b-form-group label-for="select-form-so2" size="sm">
                     <label class="label-required">Konsentrasi SO2
                  <span class="text-danger">*</span>
                </label>
                    <!-- <b-form inline id="select-form-so2"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="konsentrasi_so2" 
                        :formatter="format_two_decimal"
                        v-model.number="$v.konsentrasi_so2.$model"
                        v-on:keyup="checkValidate($event, 'konsentrasi_so2')"
                        v-bind:class="{ rangecheck: RC.konsentrasi_so2.status }"
                        :state="validateState($v.konsentrasi_so2)" class="col-8 text-center mbtm" size="sm"
                        style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.konsentrasi_so2.status" target="konsentrasi_so2" :triggers="'hover'" placement="topleft">
                        {{ RC.konsentrasi_so2.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.konsentrasi_so2.status" :triggers="'hover'" target="konsentrasi_so2" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("konsentrasi_so2") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">&micro;g/m3</span>
                    </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">&micro;g/m3</label>
                </b-col> -->
              </b-row>

              <b-row>
                <b-col sm="5">
                  <b-form-group  label-for="select-form-no2" size="sm">
                    <label class="label-required">Konsentrasi NO2
                      <span class="text-danger">*</span>
                    </label>
                    <!-- <b-form inline id="select-form-no2"> -->
                    <div class="content-inline">
                      <b-form-input 
                        id="konsentrasi_no2"
                        :formatter="format_two_decimal"
                        v-model.number="$v.konsentrasi_no2.$model"
                        v-on:keyup="checkValidate($event, 'konsentrasi_no2')"
                        v-bind:class="{ rangecheck: RC.konsentrasi_no2.status }"
                        :state="validateState($v.konsentrasi_no2)" class="col-8 text-center mbtm" size="sm"
                        style="height: 33px;"> 
                      </b-form-input>
                      <b-tooltip v-if="RC.konsentrasi_no2.status" target="konsentrasi_no2" :triggers="'hover'" placement="topleft">
                        {{ RC.konsentrasi_no2.message }}
                      </b-tooltip>
                      <b-tooltip v-if="!RC.konsentrasi_no2.status" :triggers="'hover'" target="konsentrasi_no2" placement="top"
                        custom-class="validation-tooltip">
                        {{ tooltipMessage("konsentrasi_no2") }}
                      </b-tooltip>
                      <span class="label label-modif mt-1 ml-1">&micro;g/m3</span>
                      </div>
                    <!-- </b-form> -->
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="2">
                  <label class="mb-2 font-weight-bolder mt-2">&micro;g/m3</label>
                </b-col> -->
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="6">
            <b-card class="text-dark font-weight-bolder mt-n2 h-100">
              <b-row>
                <b-col sm="12">
                  <b-form-group label="Kondisi Cuaca Awal" label-for="select-form-cawal" size="sm">
                      <v-select v-model="kondisi_cuaca_awal" :reduce="(option) => option.value" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="text" :options="kondisi_cuaca_awal_opt" class="select-size-sm">
                      </v-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="12">
                  <b-form-group label="Kondisi Cuaca Akhir" label-for="select-form-cakhir" size="sm">
                      <v-select v-model="kondisi_cuaca_akhir" :reduce="(option) => option.value" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="text" :options="kondisi_cuaca_akhir_opt" class="select-size-sm">
                      </v-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="12">
                  <b-form-group label="Kondisi Sampling " label-for="select-form-sample" size="sm">
                      <v-select v-model="kondisi_sampling" :reduce="(option) => option.value" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="text" :options="kondisi_sampling_opt" class="select-size-sm">
                      </v-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="">
                <b-col cols="12">
                  <b-row>
                    <b-col>
                      <label for="textarea-catatan-lainnya" class="font-weight-bolder"> Catatan Lainnya </label>
                    </b-col>
                  </b-row>
                  <b-row class="">
                    <b-col sm="12">
                      <b-form-textarea id="textarea-catatan-lainnya" placeholder="Catatan Lainnya"
                        v-model="catatan_lainnya"></b-form-textarea>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

            </b-card>
          </b-col>
        </b-row>

      </b-col>
    </b-row>

    <b-row class="mt-n1">
      <b-col cols="12">
        <b-card>
          <b-row class="float-right">
            <div>
              <b-button variant="danger" @click="resetForm('batal')" class="mr-2" size="sm">
                <feather-icon
                  icon="XIcon"
                  class="mr-50"
                />
                <span class="align-middle">Batal</span>
              </b-button>
              <b-button variant="warning" @click="V_Form" v-if="this.t_data_status == 'update'" class="mr-2" size="sm"
                :disabled="!roles.isRoleEdit">
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span class="align-middle">Update</span>
              </b-button>
              <b-button variant="primary" @click="V_Form" v-if="this.t_data_status == 'insert'" class="mr-2" size="sm"
                :disabled="!roles.isRoleAdd">
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                <span class="align-middle">Submit</span>
              </b-button>
            </div>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay>
  </div>
</template>
  
<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { BRow, BCol, BTable, BCardText, BFormInput, BContainer, BFormSelect, BForm, BButton, BCard, VBTooltip, BTooltip, BFormGroup, BFormDatepicker, BFormTextarea, BOverlay } from "bootstrap-vue";
import { methods } from "vue-echarts";
import axios from "axios";
import Helper from "@/api/helper";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import error_handler from "@/api/error_handler";
import { validationMixin } from "vuelidate";
import { required, minLength, between } from "vuelidate/lib/validators";
import { GC_KONSENTRASI_SO2, GC_KONSENTRASI_NO2, GC_KU } from "@/validators/kuvalidator";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import moment from 'moment';
import labkuservice from "@/api/labkuservice";
import Params from "@/attr/metadata/params";
import RC_helper from "@/validators/RC_helper";
export default {
  name: "SoxNoxForm",
  components: {
    BCardActions,
    BRow,
    BCol,
    BTable,
    BCardText,
    BFormInput,
    BContainer,
    BButton,
    BCard,
    VBTooltip,
    BTooltip,
    ToastificationContent,
    BFormSelect,
    BForm,
    BFormGroup,
    BFormDatepicker,
    vSelect,
    BFormTextarea,
    BOverlay
  },
  mixins: [validationMixin],
  validations: {
    konsentrasi_so2: {required, GC_KONSENTRASI_SO2 },
    konsentrasi_no2: {required, GC_KONSENTRASI_NO2 },
  },
  props: ["t_station", "d_station", "t_id_form", "t_data", "t_data_status", "t_path", "t_path_update", "list_observer", "list_penyelia", "t_year", "t_periode", "roles","changeForm"],
  data() {
    return {
      dateDisable: false,
      showLoading: false,
      RC_helper_so2no2: { ...RC_helper },
      ptgs_pasang_sampling: "",
      ptgs_angkat_sampling: "",
      ptgs_analis_lab: "",
      tgl_pasang: null,
      jam_pasang: "",
      tgl_angkat: null,
      jam_angkat: "",
      min: "",
      so2no2_id_sampel: null,
      konsentrasi_so2: null,
      konsentrasi_no2: null,
      so2no2_sekuens: null,
      kondisi_cuaca_awal: "",
      kondisi_cuaca_akhir: "",
      kondisi_sampling: "",
      kejadian_lainnya: "",
      sumber: "input",
      catatan_lainnya: "",
      so2no2_id_sampel: null,
      kondisi_cuaca_awal_opt: [],
      kondisi_cuaca_akhir_opt: [],
      kondisi_sampling_opt: [],
      last_qc_histories: {},
      qc_flag: 0,
      qc_flag_before: 0,
      DATA_RC: {},
      RC: {
        konsentrasi_so2: { status: false, message: "" },
        konsentrasi_no2: { status: false, message: "" }
      },
    };
  },
  computed: {
    total: function () {

    },
  },
  mounted() {
    this.getKondisiCuacaAwal()
    this.getKondisiCuacaAkhir()
    this.getKondisiSampling()
    if (this.t_data_status == "update") {
      this.popupData();
    } else {
      this.resetForm();
      this.getSampelID()
    }
    this.initialize()
  },
  watch: {
    t_year(value) {
      this.getSampelID();
    },
    t_periode(value){
      this.getSampelID();
    },
    list_penyelia(value) {
      // console.log('list_penyelia', value)
    },
    t_station(value) {
      // console.log("ubah station : ", this.t_path, value);
    },
    t_data_status(newValue) {
      // console.log('watch t_data_status ', newValue)
      // console.log('list_observer', this.list_observer)
      this.getKondisiCuacaAwal()
      this.getKondisiCuacaAkhir()
      this.getKondisiSampling()
      if (newValue == "update") {
        this.popupData();
      } else {
        this.resetForm();
        this.getSampelID()
      }
      this.checkValidateAll()
    },
    list_observer(value) {
      // console.log('list_observer', value)
    },

    tgl_pasang(value) {
      // console.log("tgl_pasang", value)
      if(value) {
        this.min = value
        if (this.tgl_angkat) {
          let dpasang = new Date(value)
          let dangkat = new Date(this.tgl_angkat)
          if (dangkat < dpasang) {
            this.tgl_angkat = ""
            this.min = value
          }
        }
        
      }
    },

    tgl_angkat(value) {
      // console.log("tgl_angkat", value)
      if(value) {
        this.min = this.tgl_pasang ? this.tgl_pasang : null
        if (this.tgl_pasang) {
          let dpasang = new Date(this.tgl_pasang)
          let dangkat= new Date(value)
          if (dangkat < dpasang) {
            this.tgl_angkat = ""
            this.min = this.tgl_pasang
            this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Tanggal Pengangkatan harus lebih besar dari Tanggal Pemasangan. Periksa kembali inputan anda",
                  icon: "BellIcon",
                  variant: "danger",
                },
              });
          }
        }     
      }
    }

  },
  methods: {
    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } if (evt.key == "." || evt.key == ",") {
        return evt.preventDefault();
      } else {
        return true;
      }
    },

    format_two_decimal(e, event) {
      if (Math.round(e * 100) / 100 != e) {
        let text = String(e);
        let filter = text.substring(0, text.length - 1);
        return filter;
      } else {
        if (Math.floor(e) === e) {
          return e;
        } else {
          if (e.toString().indexOf(".") > 0) {
            let count = e.toString().split(".")[1].length || 0;
            return count > 2 ? parseFloat(e).toFixed(2) : e;
          } else {
            return e;
          }
        }

        return e;
      }
    },

    initialize() {
      // console.log('initialize')
      this.setQCData();
      if (this.t_data_status == "update") {
        this.checkValidateAll()
      }
    },

    async getSampelID() {
      try {
        let { data } = await labkuservice.getUsageStationID(this.t_station.path)
        var kodestasiun = data['BmkgSatu.metadata.station.behavusage.IBehavStationUsage']['usage_station_id']
      } catch (e) {
        console.log(e)
      }
      try {
        const { data } = await labkuservice.getSekuens(this.t_path, String(this.t_year.title));
        var sekuens = data.items_total
      } catch (e) {
        console.log(e)
      }
      var tahun = parseInt(this.t_year.title)
      var tglAwalTahun = moment('2022-01-03T09:00').utcOffset('+0700')
      console.log("1:"+tglAwalTahun.format())
      /**
       * Tanggal 3 Januari 2022 kebetulan adalah tanggal patokan hitung P1 untuk Lab KU
       * 3 Januari 2022 adalah tanggal PASANG pertama, menandakan starting point 
       * untuk menghitung Periode 1 di tahun 2022
       * 
       * NOTE: basis periode adalah tanggal angkat, bukan tanggal pasang
       * 
       * Konsepnya adalah menggunakan basis 3 Januari 2022, dihitunglah P1 untuk tahun-tahun
       * yang lain, baik sebelum maupun sesudah 2022
       * 
       */
      if (tahun > 2022) {
        tglAwalTahun.add((parseInt(this.t_year.title) - 2022) * 6 * 61, 'days');
        let d = tglAwalTahun.diff(moment(tahun + '-01-01T09:00'), 'days');
        if (d > 6) {
          tglAwalTahun.subtract(Math.floor(d / 6) * 6, 'days');
        }
        console.log("2: d awaltahun "+ d + " " + tglAwalTahun.format())
      } else if (tahun < 2022) {
        tglAwalTahun.subtract((2022 - parseInt(this.t_year.title)) * 6 * 60, 'days');
        let d = tglAwalTahun.diff(moment(tahun +'-01-01T09:00').utcOffset('+0700'), "days");
        if (d > 6) {
          tglAwalTahun.subtract(Math.floor(d / 6) * 6, 'days');
        }
        console.log("3: d awaltahun "+ d + " " + tglAwalTahun.format())
      }
      console.log("4:"+tglAwalTahun.format())
      var tanggal_angkat = moment(tglAwalTahun).add((this.t_periode.value - 1) * 6, 'days');
      var tanggal_pasang = moment(tanggal_angkat).add(-6, 'days');
      this.tgl_pasang = tanggal_pasang.format()
      this.tgl_angkat = tanggal_angkat.format()
      this.jam_pasang = "09"//"09:00"
      this.jam_angkat = "09"//"09:00"
      console.log("6: periode pasang angkat : " + tahun + " " + tglAwalTahun.format() + " " + this.t_periode.value + " " + tanggal_pasang.format() + " " + tanggal_angkat.format())

      var periode = String(this.t_periode.value).padStart(2, "0");
      var bulan = tanggal_angkat.format('MM');
      let id_sampel = kodestasiun + "" + String(this.t_year.title).substring(2, 4) + "" + bulan + "" + periode + "" + String(parseInt(sekuens) + 1).padStart(2, "0");
      this.so2no2_id_sampel = id_sampel
      return id_sampel
    },

    async getKondisiCuacaAwal() {
      try {
        const { data } = await labkuservice.listKondisiCuacaAwal();
        let items = data.items
        let kondisi = []
        for (var i = 0; i < items.length; i++) {
          const item_kondisi = {
            value: items[i].sb_value,
            text: items[i].sb_label,
            path: items[i].path,
          };
          kondisi.push(item_kondisi)
        }
        this.kondisi_cuaca_awal_opt = kondisi
        // make kondisi cuaca awal order by name asc
        this.kondisi_cuaca_awal_opt.sort((a, b) => (a.text > b.text) ? 1 : -1)

      } catch (e) {
        console.log(e);
      }
    },

    async getKondisiCuacaAkhir() {
      try {
        const { data } = await labkuservice.listKondisiCuacaAkhir();
        let items = data.items
        let kondisi = []
        for (var i = 0; i < items.length; i++) {
          const item_kondisi = {
            value: items[i].sb_value,
            text: items[i].sb_label,
            path: items[i].path,
          };
          kondisi.push(item_kondisi)
        }
        this.kondisi_cuaca_akhir_opt = kondisi
        // make kondisi cuaca akhir order by name asc
        this.kondisi_cuaca_akhir_opt.sort((a, b) => (a.text > b.text) ? 1 : -1)
      } catch (e) {
        console.log(e);
      }
    },

    async getKondisiSampling() {
      try {
        const { data } = await labkuservice.listKondisiSampling();
        let items = data.items
        let kondisi = []
        for (var i = 0; i < items.length; i++) {
          const item_kondisi = {
            value: items[i].sb_value,
            text: items[i].sb_label,
            path: items[i].path,
          };
          kondisi.push(item_kondisi)
        }
        this.kondisi_sampling_opt = kondisi
        // make kondisi sampling order by name asc
        this.kondisi_sampling_opt.sort((a, b) => (a.text > b.text) ? 1 : -1)
      } catch (e) {
        console.log(e);
      }
    },

    onKeyCheck(event) {
      // console.log('onKeyCheck', event)
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault()
      }
    },
    format_one_decimal(e) {
      if (Math.round(e * 10) / 10 != e) {
        let text = String(e);
        let filter = text.substring(0, text.length - 1);
        return filter;
      } else {
        if (Math.floor(e) === e) {
          return e;
        } else {
          if (e.toString().indexOf(".") > 0) {
            let count = e.toString().split(".")[1].length || 0;
            return count > 1 ? parseFloat(e).toFixed(1) : e;
          } else {
            return e;
          }
        }
        return e;
      }
    },
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    tooltipMessage(attr) {
      return GC_KU(attr).message;
    },
    V_Form() {
      this.$v.$touch();
      if (!this.ptgs_pasang_sampling || !this.ptgs_angkat_sampling 
          || !this.ptgs_analis_lab || !this.tgl_pasang || !this.jam_pasang || !this.tgl_angkat || !this.jam_angkat
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Data tidak valid. Periksa kembali inputan anda",
            icon: "BellIcon",
            variant: "danger",
          },
        });
        return;
      }
      if (this.$v.$invalid) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Data tidak valid. Periksa kembali inputan anda",
            icon: "BellIcon",
            variant: "danger",
          },
        });
        return;
      } else {

        let dataRC = Object.entries(this.DATA_RC).filter((e) => e[1].status);
        // console.log('this.DATA_RC', this.DATA_RC, dataRC)
        let temp_QC_After = [];
        for (var i in dataRC) {
          let row_qc_after = {
            raw_type: "SO2NO2",
            atribut: dataRC[i][0],
            value: this[dataRC[i][0]],
            roles: dataRC[i][1].message,
            type: "range_check",
            message: dataRC[i][1].message,
          };
          temp_QC_After.push(row_qc_after);
        }

        // console.log("QC  AFTER : ",temp_QC_After);
        // this.last_qc_histories["before"] = "-";
        this.last_qc_histories["after"] = temp_QC_After;

        if (dataRC.length > 0) {
          this.$swal({
            title: "Apakah Anda Yakin Untuk Simpan Data ?",
            text: "Data Anda Terkoreksi Range Check ",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Submit",
            cancelButtonText: "Cek Data",
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              if (this.t_data_status == "insert") {
                this.sumbitSO2NO2();
              } else if (this.t_data_status == "update") {
                this.updateData();
              }
            } else if (result.dismiss === "cancel") {
              return;
            }
          });
        } else {
          if (this.t_data_status == "insert") {
            this.sumbitSO2NO2();
          } else if (this.t_data_status == "update") {
            this.updateData();
          }
        }
        // if (this.t_data_status == "insert") {
        //   this.sumbitSO2NO2();
        // } else if (this.t_data_status == "update") {
        //   this.updateData();
        // }
      }
    },
    popupData() {
      // console.log('popupData', this.t_data)
      this.resetForm()
      let p_pasang_sampling = this.list_observer.find(item => item.observer == this.t_data.so2no2_ptgs_pasang_sampling)
      let p_angkat_sampling = this.list_observer.find(item => item.observer == this.t_data.so2no2_ptgs_angkat_sampling)
      let p_analis_lab = this.list_penyelia.find(item => item.value == this.t_data.so2no2_ptgs_analis)
      this.so2no2_id_sampel = this.t_data.so2no2_id_sampel
      this.ptgs_pasang_sampling = p_pasang_sampling
      this.ptgs_angkat_sampling = p_angkat_sampling
      this.ptgs_analis_lab = p_analis_lab
      this.tgl_pasang = this.t_data.tgl_pasang
      this.jam_pasang = ""
      this.tgl_angkat = this.t_data.tgl_angkat
      this.jam_angkat = ""
      this.id_sampel = ""
      this.konsentrasi_so2 = this.t_data.konsentrasi_so2
      this.konsentrasi_no2 = this.t_data.konsentrasi_no2
      this.kondisi_cuaca_awal = this.t_data.so2no2_kondisi_cuaca_awal
      this.kondisi_cuaca_akhir = this.t_data.so2no2_kondisi_cuaca_akhir
      this.kondisi_sampling = this.t_data.so2no2_kondisi_sampling
      this.kejadian_lainnya = this.t_data.so2no2_kejadian_lainnya
      this.sumber = "input"
      this.catatan_lainnya = this.t_data.so2no2_catatan_lainnya
      this.qc_flag_before = this.t_data.qc_flag;
      this.QC_Histories = this.t_data.qc_histories

      if (this.tgl_pasang) {
        let tgl = this.tgl_pasang.split('T')
        let jam = tgl[1].split(':')[0]
        this.jam_pasang = jam
      }

      if (this.tgl_angkat) {
        let tgl = this.tgl_angkat.split('T')
        let jam = tgl[1].split(':')[0]
        this.jam_angkat = jam
      }

      if (!this.t_data.so2no2_id_sampel) {
        this.getSampelID()
      }
    },
    updateData() {
      const modification_date = this.t_date;

      if (this.jam_pasang) {
        let tglp = this.tgl_pasang.split('T')[0]
        this.tgl_pasang = tglp + 'T' + this.jam_pasang + ':00:00'
      }
      if (this.jam_pasang) {
        let tgla = this.tgl_angkat.split('T')[0]
        this.tgl_angkat = tgla + 'T' + this.jam_angkat + ':00:00'
      }

      // CHECK QC
      var len_qc_after = (this.last_qc_histories) ? this.last_qc_histories["after"].length : 0;
      var temp_qc_histories = {};
      if (this.QC_Histories && this.QC_Histories != "" || this.QC_Histories != null) {
        temp_qc_histories = JSON.parse(this.QC_Histories);
      }
      // console.log("TEMP QC : ", temp_qc_histories);
      this.last_qc_histories["before"] = [];
      if (temp_qc_histories != null) {
        if (temp_qc_histories["after"] != undefined) {
          this.last_qc_histories["before"] = temp_qc_histories["after"];
        }
      }

      var flag_temp = this.qc_flag_before ? this.qc_flag_before : 0;
      if (this.qc_flag_before != 2) {
        flag_temp = this.qc_flag_before;

        if (len_qc_after > 0) {
          flag_temp = 1;
        } 
        else {
          flag_temp = 0;
        }
      }
      

      var qc_temp = JSON.stringify(this.last_qc_histories);

      const post_data = {
        "@type": "SO2NO2",
        so2no2_ptgs_pasang_sampling: this.ptgs_pasang_sampling?.observer ? this.ptgs_pasang_sampling.observer : null,
        so2no2_ptgs_angkat_sampling: this.ptgs_angkat_sampling?.observer ? this.ptgs_angkat_sampling.observer : null,
        so2no2_ptgs_analis: this.ptgs_analis_lab?.value ? this.ptgs_analis_lab.value : null,
        tgl_pasang: this.tgl_pasang,
        tgl_angkat: this.tgl_angkat,
        konsentrasi_so2: this.konsentrasi_so2 ? this.konsentrasi_so2 : null,
        konsentrasi_no2: this.konsentrasi_no2 ? this.konsentrasi_no2 : null,
        so2no2_kondisi_cuaca_awal: this.kondisi_cuaca_awal,
        so2no2_kondisi_cuaca_akhir: this.kondisi_cuaca_akhir,
        so2no2_kondisi_sampling: this.kondisi_sampling,
        so2no2_kejadian_lainnya: this.kejadian_lainnya,
        so2no2_catatan_lainnya: this.catatan_lainnya,
        station_id: this.t_station.value,
        station_wmo_id: this.t_station.wmo,
        station_name: this.t_station.text,
        current_longitude: this.t_station.longitude,
        current_latitude: this.t_station.latitude,
        periode_form: this.t_periode.value,
        tahun_form: this.tgl_angkat,
        qc_flag: flag_temp,
        qc_histories: qc_temp
      };

      this.showLoading = true
      const update_url = Helper.getBaseUrl() + this.t_path_update;
      // console.log('update_url', update_url, post_data)
      axios
        .patch(update_url, post_data, Helper.getConfig())
        .then((response) => {
          this.$swal({
            title: "Update data sukses!",
            text: "Data Telah berhasil di Update",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.showLoading = false
          this.resetForm();
          this.changeForm(null);
        })
        .catch((error) => {
          this.showLoading = false
          console.log(error);
          error_handler(this, error, "SO2NO2", "Input Data Error");
        });
    },
    sumbitSO2NO2() {
      // console.log('sumbitSO2NO2', this.tgl_pasang);
      
      if (this.jam_pasang) {
        let tglp = this.tgl_pasang.split('T')[0]
        this.tgl_pasang = tglp + 'T' + this.jam_pasang + ':00:00'
      }
      if (this.jam_pasang) {
        let tgla = this.tgl_angkat.split('T')[0]
        this.tgl_angkat = tgla + 'T' + this.jam_angkat + ':00:00'
      }

      var len_qc_after = this.last_qc_histories["after"].length;
      this.last_qc_histories["before"] = [];
      // console.log("ada QC : ",len_qc_after);
      var flag_temp = 0;
      if (len_qc_after > 0) {
        flag_temp = 1;
      }
      var qc_temp = JSON.stringify(this.last_qc_histories);

      const so2no2_id = this.t_id_form//this.t_station_id + "_no2so2_" + this.t_date;
      // const data_timestamp = this.t_date;
      const post_data = {
        "@type": "SO2NO2",
        id: so2no2_id,
        so2no2_ptgs_pasang_sampling: this.ptgs_pasang_sampling?.observer ? this.ptgs_pasang_sampling.observer : null,
        so2no2_ptgs_angkat_sampling: this.ptgs_angkat_sampling?.observer ? this.ptgs_angkat_sampling.observer : null,
        so2no2_ptgs_analis: this.ptgs_analis_lab?.value ? this.ptgs_analis_lab.value : null,
        tgl_pasang: this.tgl_pasang,
        tgl_angkat: this.tgl_angkat,
        so2no2_id_sampel: this.so2no2_id_sampel,
        konsentrasi_so2: this.konsentrasi_so2 ? this.konsentrasi_so2 : null,
        konsentrasi_no2: this.konsentrasi_no2 ? this.konsentrasi_no2 : null,
        so2no2_kondisi_cuaca_awal: this.kondisi_cuaca_awal,
        so2no2_kondisi_cuaca_akhir: this.kondisi_cuaca_akhir,
        so2no2_kondisi_sampling: this.kondisi_sampling,
        so2no2_kejadian_lainnya: this.kejadian_lainnya,
        so2no2_catatan_lainnya: this.catatan_lainnya,
        station_id: this.t_station.value,
        station_wmo_id: this.t_station.wmo,
        station_name: this.t_station.text,
        current_longitude: this.t_station.longitude,
        current_latitude: this.t_station.latitude,
        periode_form: this.t_periode.value,
        tahun_form: this.tgl_angkat,
        qc_flag: flag_temp,
        qc_histories: qc_temp
      };

      this.showLoading = true
      const post_url = Helper.getBaseUrl() + this.t_path;
      // console.log('post_url', post_url, post_data)
      axios
        .post(post_url, post_data, Helper.getConfig())
        .then((response) => {
          this.$swal({
            title: "Simpan data sukses!",
            text: "Data Telah berhasil di Simpan",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.showLoading = false
          this.resetForm();
          this.changeForm(null);
        })
        .catch((error) => {
          this.showLoading = false
          console.log(error);
          error_handler(this, error, "SO2NO2", "Input Data Error");
        });
    },
    resetForm(tipe = null) {
      this.showLoading = false
      if(tipe == 'batal'){
        this.changeForm(null);
      }
      this.qc_flag = 0,
      this.qc_flag_before = 0,
      this.last_qc_histories = {},
      this.DATA_RC = {},
      this.RC = {
        konsentrasi_so2: { status: false, message: "" },
        konsentrasi_no2: { status: false, message: "" }
      },
      this.ptgs_pasang_sampling = ""
      this.ptgs_angkat_sampling = ""
      this.ptgs_analis_lab = ""
      this.tgl_pasang = null
      this.jam_pasang = null
      this.tgl_angkat = null
      this.jam_angkat = null
      this.id_sampel = ""
      this.konsentrasi_so2 = null
      this.konsentrasi_no2 = null
      this.kondisi_cuaca_awal = ""
      this.kondisi_cuaca_akhir = ""
      this.kondisi_sampling = ""
      this.kejadian_lainnya = ""
      this.sumber = "input"
      this.catatan_lainnya = ""
      this.so2no2_id_sampel = null
      this.$v.$reset();
      // this.changeForm(null);
    },
    // async getSekuensID() {
    //   var sekuens = 0;
    //   var tahun = String(this.t_year.title);
    //   var url = Helper.getBaseUrl() + this.t_path + "/@search?tgl_pasang__gte=01-01-" + tahun + "&tgl_pasang__lte=31-12-" + tahun;
    //   await axios
    //     .get(url, Helper.getConfig())
    //     .then(response => this.sekuens = response.data.items_total)
    //     .catch(error => console.log(error))
    //   this.so2no2_sekuens = this.sekuens
    // },

    checkValidate: function (event, type) {
      // console.log("checkValidate", event, type);
      let value = event.target.value;

      switch (type) {
        case "konsentrasi_so2":
          var raw = this.RC_helper_so2no2.check(value, type);
          var gc = GC_KONSENTRASI_SO2(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          // console.log('this.RC[type]', this.RC[type])
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;
        case "konsentrasi_no2":
        var raw = this.RC_helper_so2no2.check(value, type);
          var gc = GC_KONSENTRASI_NO2(value);
          this.RC[type].status = !raw.status ? (!gc ? false : true) : false;
          this.RC[type].message = raw.message;
          // console.log('this.RC[type]', this.RC[type])
          this.setDataRC(type, this.RC[type].status, this.RC[type].message, raw.roles);
          break;

        default:
          break;
      }
    },

    checkValidateAll() {
      // console.log('checkValidateAll')
      this.checkValidate({target: {value: this.konsentrasi_so2}}, "konsentrasi_so2")
      this.checkValidate({target: {value: this.konsentrasi_no2}}, "konsentrasi_no2")
    },

    setDataRC(type, status, message, roles) {
      // console.log('setDataRC', type, status, message, roles)
      if (status) {
        this.DATA_RC[type] = {
          status: status,
          message: message,
          roles: roles,
        };
      } else {
        if (this.DATA_RC.hasOwnProperty(type)) {
          delete this.DATA_RC[type];
        }
      }
    },

    async setQCData() {
      // console.log('setQCData')
      if (this.d_station) {
        let qc_data = this.d_station["BmkgSatu.metadata.qc.ISo2no2_qc"];
        let qc_params = Params.GET_PARAMS("so2no2");
        let qc_key = Params.GET_KEY("so2no2");
        this.RC_helper_so2no2.setData(qc_data, qc_params, qc_key);
        // console.log('setQCData', this.RC_helper_so2no2.getData())
      }
    },

    rangecheck_status(type) {
      if (this.RC[type]?.status) {
        return this.RC[type].status;
      } else {
        return false;
      }
    },

  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
};
</script>

<style>
#konsentrasi_no2.rangecheck,
#konsentrasi_so2.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
}

.content-inline {
  display: flex;
  flex-direction: row;
}
</style>

<style scoped>
.label-modif{
  margin-top:-5px;font-size:10pt;margin-left:3px
}
.select-grey {
  background-color: lightgray !important;
}

.label-entry1 {
  text-align: center;
  color: #3b4253;
  width: 100%;
  font-weight: 500;
  background-color: #f3f1f1;
  /* font-weight: bold !important; */
  border: 2px solid #6e6b7b;
  border-radius: 3px;
  padding: 4px;
  margin-bottom: 7px;
}

.mbtm {
  margin-bottom: 7px;
}

.bg-light-primary {
  color: #000000 !important;
}
</style>
  