//Kimia Air Hujan
export function GC_PH(value) {
  return (value >= GC.kah_ph.min && value <= GC.kah_ph.max) || value == GC.kah_ph.specialValue[0] || value == GC.kah_ph.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_CONDUCTIVITY(value) {
  return (value >= GC.kah_conductivity.min && value <= GC.kah_conductivity.max) || value == GC.kah_conductivity.specialValue[0] || value == GC.kah_conductivity.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_CL(value) {
  return (value >= GC.kah_cl.min && value <= GC.kah_cl.max) || value == GC.kah_cl.specialValue[0] || value == GC.kah_cl.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_SO42(value) {
  return (value >= GC.kah_so42.min && value <= GC.kah_so42.max) || value == GC.kah_so42.specialValue[0] || value == GC.kah_so42.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_NO3(value) {
  return (value >= GC.kah_no3.min && value <= GC.kah_no3.max) || value == GC.kah_no3.specialValue[0] || value == GC.kah_no3.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_NA(value) {
  return (value >= GC.kah_na.min && value <= GC.kah_na.max) || value == GC.kah_na.specialValue[0] || value == GC.kah_na.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_NH4(value) {
  return (value >= GC.kah_nh4.min && value <= GC.kah_nh4.max) || value == GC.kah_nh4.specialValue[0] || value == GC.kah_nh4.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_K(value) {
  return (value >= GC.kah_k.min && value <= GC.kah_k.max) || value == GC.kah_k.specialValue[0] || value == GC.kah_k.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_MG2(value) {
  return (value >= GC.kah_mg2.min && value <= GC.kah_mg2.max) || value == GC.kah_mg2.specialValue[0] || value == GC.kah_mg2.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_CA2(value) {
  return (value >= GC.kah_ca2.min && value <= GC.kah_ca2.max) || value == GC.kah_ca2.specialValue[0] || value == GC.kah_ca2.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_ACIDITY(value) {
  return (value >= GC.kah_acidity.min && value <= GC.kah_acidity.max) || value == GC.kah_acidity.specialValue[0] || value == GC.kah_acidity.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_ALKALINITY(value) {
  return (value >= GC.kah_alkalinity.min && value <= GC.kah_alkalinity.max) || value == GC.kah_alkalinity.specialValue[0] || value == GC.kah_alkalinity.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_CURAH_HUJAN_SAMPLING(value) {
  return (value >= GC.kah_curah_hujan_sampling.min && value <= GC.kah_curah_hujan_sampling.max) || value == GC.kah_curah_hujan_sampling.specialValue[0] || value == GC.kah_curah_hujan_sampling.specialValue[1] || ((value === "" || value === null) && value !== 0);
}
export function GC_MASSA_SAMPLING(value) {
  return (value >= GC.kah_massa_sampling.min && value <= GC.kah_massa_sampling.max) || value == GC.kah_massa_sampling.specialValue[0] || value == GC.kah_massa_sampling.specialValue[1] || ((value === "" || value === null) && value !== 0);
}


// Suspended Particulate Matter
export function GC_KONSENTRASI_SPM(value) {
  return (value >= GC.spm_konsentrasi.min && value <= GC.spm_konsentrasi.max) || value == GC.spm_konsentrasi.specialValue[0] || value == GC.spm_konsentrasi.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_FLOW_RATE_AWAL_M3(value) {
  return (value >= GC.spm_flow_rate_awal_m3.min && value <= GC.spm_flow_rate_awal_m3.max) || value == GC.spm_flow_rate_awal_m3.specialValue[0] || value == GC.spm_flow_rate_awal_m3.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_FLOW_RATE_AWAL_FT3(value) {
  return (value >= GC.spm_flow_rate_awal_ft3.min && value <= GC.spm_flow_rate_awal_ft3.max) || value == GC.spm_flow_rate_awal_ft3.specialValue[0] || value == GC.spm_flow_rate_awal_ft3.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_FLOW_RATE_AKHIR_M3(value) {
  return (value >= GC.spm_flow_rate_akhir_m3.min && value <= GC.spm_flow_rate_akhir_m3.max) || value == GC.spm_flow_rate_akhir_m3.specialValue[0] || value == GC.spm_flow_rate_akhir_m3.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_FLOW_RATE_AKHIR_FT3(value) {
  return (value >= GC.spm_flow_rate_akhir_ft3.min && value <= GC.spm_flow_rate_akhir_ft3.max) || value == GC.spm_flow_rate_akhir_ft3.specialValue[0] || value == GC.spm_flow_rate_akhir_ft3.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_SPM_BOBOT_FILTER_KOSONG(value) {
  return (value >= GC.spm_bobot_filter_kosong.min && value <= GC.spm_bobot_filter_kosong.max) || value == GC.spm_bobot_filter_kosong.specialValue[0] || value == GC.spm_bobot_filter_kosong.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_SPM_BOBOT_FILTER_SAMPLING(value) {
  return (value >= GC.spm_bobot_filter_sampling.min && value <= GC.spm_bobot_filter_sampling.max) || value == GC.spm_bobot_filter_sampling.specialValue[0] || value == GC.spm_bobot_filter_sampling.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_SPM_TOTAL_WAKTU_SAMPLING(value) {
  return (value >= GC.spm_total_waktu_sampling.min && value <= GC.spm_total_waktu_sampling.max) || value == GC.spm_total_waktu_sampling.specialValue[0] || value == GC.spm_total_waktu_sampling.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_SPM_TEKANAN_UDARA_AWAL(value) {
  return (value >= GC.spm_tekanan_udara_awal.min && value <= GC.spm_tekanan_udara_awal.max) || value == GC.spm_tekanan_udara_awal.specialValue[0] || value == GC.spm_tekanan_udara_awal.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_SPM_TEKANAN_UDARA_AKHIR(value) {
  return (value >= GC.spm_tekanan_udara_akhir.min && value <= GC.spm_tekanan_udara_akhir.max) || value == GC.spm_tekanan_udara_akhir.specialValue[0] || value == GC.spm_tekanan_udara_akhir.specialValue[1] || ((value === "" || value === null) && value !== 0);
} 

export function GC_SPM_TEMPERATUR_AWAL(value) {
  return (value >= GC.spm_temperatur_awal.min && value <= GC.spm_temperatur_awal.max) || value == GC.spm_temperatur_awal.specialValue[0] || value == GC.spm_temperatur_awal.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_SPM_TEMPERATUR_AKHIR(value) {
  return (value >= GC.spm_temperatur_akhir.min && value <= GC.spm_temperatur_akhir.max) || value == GC.spm_temperatur_akhir.specialValue[0] || value == GC.spm_temperatur_akhir.specialValue[1] || ((value === "" || value === null) && value !== 0);
}


// SO2 & NO2
export function GC_KONSENTRASI_SO2(value) {
  return (value >= GC.konsentrasi_so2.min && value <= GC.konsentrasi_so2.max) || value == GC.konsentrasi_so2.specialValue[0] || value == GC.konsentrasi_so2.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_KONSENTRASI_NO2(value) {
  return (value >= GC.konsentrasi_no2.min && value <= GC.konsentrasi_no2.max) || value == GC.konsentrasi_no2.specialValue[0] || value == GC.konsentrasi_no2.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

//AEROSOL
export function GC_AE_BOBOT_FILTER_KOSONG(value) {
  return (value >= GC.aerosol_bobot_filter_kosong.min && value <= GC.aerosol_bobot_filter_kosong.max) || value == GC.aerosol_bobot_filter_kosong.specialValue[0] || value == GC.aerosol_bobot_filter_kosong.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_AE_BOBOT_FILTER_SAMPLING(value) {
  return (value >= GC.aerosol_bobot_filter_sampling.min && value <= GC.aerosol_bobot_filter_sampling.max) || value == GC.aerosol_bobot_filter_sampling.specialValue[0] || value == GC.aerosol_bobot_filter_sampling.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_AE_FLOW_RATE_AWAL(value) {
  return (value >= GC.aerosol_flow_rate_awal.min && value <= GC.aerosol_flow_rate_awal.max) || value == GC.aerosol_flow_rate_awal.specialValue[0] || value == GC.aerosol_flow_rate_awal.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_AE_FLOW_RATE_AWAL_M3(value) {
  return (value >= GC.aerosol_flow_rate_awal_m3.min && value <= GC.aerosol_flow_rate_awal_m3.max) || value == GC.aerosol_flow_rate_awal_m3.specialValue[0] || value == GC.aerosol_flow_rate_awal_m3.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_AE_FLOW_RATE_AWAL_FT3(value) {
  return (value >= GC.aerosol_flow_rate_awal_ft3.min && value <= GC.aerosol_flow_rate_awal_ft3.max) || value == GC.aerosol_flow_rate_awal_ft3.specialValue[0] || value == GC.aerosol_flow_rate_awal_ft3.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_AE_FLOW_RATE_AKHIR(value) {
  return (value >= GC.aerosol_flow_rate_akhir.min && value <= GC.aerosol_flow_rate_akhir.max) || value == GC.aerosol_flow_rate_akhir_m3.specialValue[0] || value == GC.aerosol_flow_rate_akhir_m3.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_AE_FLOW_RATE_AKHIR_M3(value) {
  return (value >= GC.aerosol_flow_rate_akhir_m3.min && value <= GC.aerosol_flow_rate_akhir_m3.max) || value == GC.aerosol_flow_rate_akhir_m3.specialValue[0] || value == GC.aerosol_flow_rate_akhir_m3.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_AE_FLOW_RATE_AKHIR_FT3(value) {
  return (value >= GC.aerosol_flow_rate_akhir_ft3.min && value <= GC.aerosol_flow_rate_akhir_ft3.max) || value == GC.aerosol_flow_rate_akhir_ft3.specialValue[0] || value == GC.aerosol_flow_rate_akhir_ft3.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_AE_TOTAL_WAKTU_SAMPLING(value) {
  return (value >= GC.aerosol_total_waktu_sampling.min && value <= GC.aerosol_total_waktu_sampling.max) || value == GC.aerosol_total_waktu_sampling.specialValue[0] || value == GC.aerosol_total_waktu_sampling.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_AE_TEKANAN_UDARA_AWAL(value) {
  return (value >= GC.aerosol_tekanan_udara_awal.min && value <= GC.aerosol_tekanan_udara_awal.max) || value == GC.aerosol_tekanan_udara_awal.specialValue[0] || value == GC.aerosol_tekanan_udara_awal.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_AE_TEKANAN_UDARA_AKHIR(value) {
  return (value >= GC.aerosol_tekanan_udara_akhir.min && value <= GC.aerosol_tekanan_udara_akhir.max) || value == GC.aerosol_tekanan_udara_akhir.specialValue[0] || value == GC.aerosol_tekanan_udara_akhir.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_AE_TEMPERATUR_AWAL(value) {
  return (value >= GC.aerosol_temperatur_awal.min && value <= GC.aerosol_temperatur_awal.max) || value == GC.aerosol_temperatur_awal.specialValue[0] || value == GC.aerosol_temperatur_awal.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_AE_TEMPERATUR_AKHIR(value) {
  return (value >= GC.aerosol_temperatur_akhir.min && value <= GC.aerosol_temperatur_akhir.max) || value == GC.aerosol_temperatur_akhir.specialValue[0] || value == GC.aerosol_temperatur_akhir.specialValue[1] || ((value === "" || value === null) && value !== 0);
}


export function GC_AE_PH(value) {
  return (value >= GC.aerosol_ph.min && value <= GC.aerosol_ph.max) || value == GC.aerosol_ph.specialValue[0] || value == GC.aerosol_ph.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_AE_CONDUCTIVITY(value) {
  return (value >= GC.aerosol_conductivity.min && value <= GC.aerosol_conductivity.max) || value == GC.aerosol_conductivity.specialValue[0] || value == GC.aerosol_conductivity.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_AE_KONSENTRASI(value) {
  return (value >= GC.aerosol_konsentrasi.min && value <= GC.aerosol_konsentrasi.max) || value == GC.aerosol_konsentrasi.specialValue[0] || value == GC.aerosol_konsentrasi.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_AE_CL(value) {
  return (value >= GC.aerosol_cl.min && value <= GC.aerosol_cl.max) || value == GC.aerosol_cl.specialValue[0] || value == GC.aerosol_cl.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_AE_SO42(value) {
  return (value >= GC.aerosol_so42.min && value <= GC.aerosol_so42.max) || value == GC.aerosol_so42.specialValue[0] || value == GC.aerosol_so42.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_AE_NO3(value) {
  return (value >= GC.aerosol_no3.min && value <= GC.aerosol_no3.max) || value == GC.aerosol_no3.specialValue[0] || value == GC.aerosol_no3.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_AE_NA(value) {
  return (value >= GC.aerosol_na.min && value <= GC.aerosol_na.max) || value == GC.aerosol_na.specialValue[0] || value == GC.aerosol_na.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_AE_NH4(value) {
  return (value >= GC.aerosol_nh4.min && value <= GC.aerosol_nh4.max) || value == GC.aerosol_nh4.specialValue[0] || value == GC.aerosol_nh4.specialValue[1] || ((value === "" || value === null) && value !== 0); 
}

export function GC_AE_K(value) {
  return (value >= GC.aerosol_k.min && value <= GC.aerosol_k.max) || value == GC.aerosol_k.specialValue[0] || value == GC.aerosol_k.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_AE_MG2(value) {
  return (value >= GC.aerosol_mg2.min && value <= GC.aerosol_mg2.max) || value == GC.aerosol_mg2.specialValue[0] || value == GC.aerosol_mg2.specialValue[1] || ((value === "" || value === null) && value !== 0);
}

export function GC_AE_CA2(value) {
  return (value >= GC.aerosol_ca2.min && value <= GC.aerosol_ca2.max) || value == GC.aerosol_ca2.specialValue[0] || value == GC.aerosol_ca2.specialValue[1] || ((value === "" || value === null) && value !== 0);
}          


const GC = {

  //Kimia Air Hujan
  kah_ph: { min: 1.00, max: 14.00, specialValue: [9999,8888] },
  kah_conductivity: { min: 0, max: 900.0, specialValue: [9999,8888] },
  kah_cl: { min: 0, max: 150, specialValue: [9999,8888] },
  kah_so42: { min: 0, max: 90.000, specialValue: [9999,8888] },
  kah_no3: { min: 0, max: 90.000, specialValue: [9999,8888] },
  kah_na: { min: 0, max: 60.000, specialValue: [9999,8888] },
  kah_nh4: { min: 0, max: 60.000, specialValue: [9999,8888] },
  kah_k: { min: 0, max: 15.000, specialValue: [9999,8888] },
  kah_mg2: { min: 0, max: 15.000, specialValue: [9999,8888] },
  kah_ca2: { min: 0, max: 30.000, specialValue: [9999,8888] },
  kah_acidity: { min: 0, max: 900.0, specialValue: [9999,8888] },
  kah_alkalinity: { min: 0, max: 900.0, specialValue: [9999,8888] },
  kah_curah_hujan_sampling: { min: 0, max: 1000, specialValue: [9999,8888] },
  kah_massa_sampling: { min: 0, max: 3000, specialValue: [9999,8888] },
  
  // Suspended Particulate Matter
  spm_konsentrasi: { min: 2.5, max: 600, specialValue: [9999,8888] },
  spm_flow_rate_awal_m3: { min: 0, max: 6, specialValue: [9999,8888] },
  spm_flow_rate_awal_ft3: { min: 0, max: 180, specialValue: [9999,8888] },
  spm_flow_rate_akhir_m3: { min: 0, max: 6, specialValue: [9999,8888] },
  spm_flow_rate_akhir_ft3: { min: 0, max: 180, specialValue: [9999,8888] },
  spm_bobot_filter_kosong: { min: 0.0, max: 10.5, specialValue: [9999,8888] },
  spm_bobot_filter_sampling: { min: 0.0, max: 13.5, specialValue: [9999,8888] },
  spm_total_waktu_sampling: { min: 0, max: 3600, specialValue: [9999,8888] },
  spm_tekanan_udara_awal: { min: 700, max: 1050, specialValue: [9999,8888] },
  spm_tekanan_udara_akhir: { min: 700, max: 1050, specialValue: [9999,8888] },
  spm_temperatur_awal: { min: 5, max: 50, specialValue: [9999,8888] },
  spm_temperatur_akhir: { min: 5, max: 50, specialValue: [9999,8888] },
  
  // SO2 NO2
  konsentrasi_so2: { min: 0, max: 1100.4, specialValue: [9999,8888] }, 
  konsentrasi_no2: { min: 0, max: 451.2, specialValue: [9999,8888] }, 

  // AEROSOL
  aerosol_bobot_filter_kosong: { min: 0, max: 10.5, specialValue: [9999,8888] },
  aerosol_bobot_filter_sampling: { min: 0, max: 13.5, specialValue: [9999,8888] },

  aerosol_flow_rate_awal: { min: 0, max: 54, specialValue: [9999,8888] },
  aerosol_flow_rate_awal_m3: { min: 0, max: 54, specialValue: [9999,8888] },
  aerosol_flow_rate_awal_ft3: { min: 20, max: 120, specialValue: [9999,8888] }, 
  aerosol_flow_rate_akhir: { min: 0, max: 54, specialValue: [9999,8888] },
  aerosol_flow_rate_akhir_m3: { min: 0, max: 54, specialValue: [9999,8888] }, 
  aerosol_flow_rate_akhir_ft3: { min: 20, max: 120, specialValue: [9999,8888] },

  aerosol_total_waktu_sampling: { min: 0, max: 3600, specialValue: [9999,8888] },
  aerosol_tekanan_udara_awal: { min: 700, max: 1050, specialValue: [9999,8888] },
  aerosol_tekanan_udara_akhir: { min: 700, max: 1050, specialValue: [9999,8888] },
  aerosol_temperatur_awal: { min: 5, max: 50, specialValue: [9999,8888] },
  aerosol_temperatur_akhir: { min: 5, max: 50, specialValue: [9999,8888] },


  aerosol_ph: { min: 1.00, max: 14.0, specialValue: [9999,8888] }, 
  aerosol_conductivity: { min: 0, max: 900.0, specialValue: [9999,8888] }, 
  aerosol_konsentrasi: { min: 1, max: 700, specialValue: [9999,8888] }, 
  aerosol_cl: { min: 0, max: 150.000, specialValue: [9999,8888] }, 
  aerosol_so42: { min: 0, max: 90.000, specialValue: [9999,8888] }, 
  aerosol_no3: { min: 0, max: 90.000, specialValue: [9999,8888] }, 
  aerosol_na: { min: 0, max: 60.000, specialValue: [9999,8888] }, 
  aerosol_nh4: { min: 0, max: 60.000, specialValue: [9999,8888] }, 
  aerosol_k: { min: 0, max: 15.000, specialValue: [9999,8888] }, 
  aerosol_mg2: { min: 0, max: 15.000, specialValue: [9999,8888] }, 
  aerosol_ca2: { min: 0, max: 30.000, specialValue: [9999,8888] }, 

   
};

export function GC_KU(key) {
  let status = false;
  let message = "";
  let min = GC[key].min;
  let max = GC[key].max;
  let spv = GC[key].specialValue;
  status = false;
  message = "Gross Check - Batas Bawah " + min + ", Batas Atas " + max ;//+ ", Special Value " + spv;
  return { status: status, min: min, max: max, specialValue: spv, message: message };
}

