<template >
    <div v-if="data_input.i_time=='0730ws'" style="font-size: 0.90rem;">
        <b-form>
            <b-row class="mt-n1">
                <!-- PSYCROMETER SANGKAR start -->
                <b-col md="6" lg="4" class="mb-1">
                    <b-card
                        class="small font-weight-bolder h-100"
                    >
                    <b-row>
                        <b-col cols="10">
                            <h6 class="mb-2 small text-dark font-weight-bolder badge badge-light-dark">PSYCROMETER SANGKAR</h6>
                        </b-col>
                        <b-col cols="1">
                            <feather-icon v-b-toggle.collapse-a icon="ChevronDownIcon" />
                        </b-col>

                    </b-row>
                    <b-collapse v-if="fdih_status.is_agm1b" visible id="collapse-a">
                        <b-row class="">
                            <b-col cols="12">

                                <b-row class="">
                                    <b-col cols="3"></b-col>
                                    <b-col cols="3" class="text-center m-auto pr-1 pl-0">
                                        <div class="label-entry2 bg-light-primary">
                                            TBK
                                        </div>
                                    </b-col>
                                    <b-col cols="3" class="text-center m-auto pr-1 pl-0">
                                        <div class="label-entry2 bg-light-primary">
                                            TBB
                                        </div>
                                    </b-col>
                                    <b-col cols="3" class="text-center m-auto pr-1 pl-0">
                                        <div class="label-entry2 bg-light-primary">
                                            RH
                                        </div>
                                    </b-col>
                                </b-row>

                                <b-row class=" mt-1">
                                    <b-col cols="3" class="">
                                        <div class="label-entry1">
                                            1.2 m <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips1" />
                                        </div>
                                        <!-- HTML tooltips -->
                                        <b-tooltip target="tooltips1" :triggers="'hover'" placement="top">
                                            <dl class="text-left">
                                            <dt>Tbk</dt>
                                            <dd>- Temperatur dari thermometer bola kering pada ketinggian 1.2 m, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                            <dt>Tbb</dt>
                                            <dd>- Temperatur dari thermometer bola basah pada ketinggian 1.2 m, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                            <dt>RH</dt>
                                            <dd>- Lembab Nisbi udara pada ketinggian 1.2 m, dinyatakan dalam persen (%)</dd>
                                            </dl>
                                        </b-tooltip>
                                    </b-col>
                                    <b-col cols="3" class=" m-auto pr-1 pl-0">
                                        <b-form-input v-on:keydown="onKeyCheck($event)" id="tbk_1c2m_0730" :formatter="format_one_decimal"  size="sm" class="text-center" :state="validateState($v.tbk_1c2m_0730)" v-model.number="$v.tbk_1c2m_0730.$model"></b-form-input>
                                        <b-tooltip v-if="CC.tbk_1c2m_0730.status" target="tbk_1c2m_0730" :triggers="'hover'" placement="top">
                                            {{ CC.tbk_1c2m_0730.message }}
                                        </b-tooltip>
                                        <b-tooltip v-if="!CC.tbk_1c2m_0730.status" :show="validateTooltipState('tbk_1c2m_0730')" :triggers="'hover'" target="tbk_1c2m_0730" placement="top" custom-class="validation-tooltip">
                                            {{ tooltipMessage("tbk_1c2m_0730") }}
                                        </b-tooltip>
                                    </b-col>
                                    <b-col cols="3" class=" m-auto pr-1 pl-0">
                                        <b-form-input v-on:keydown="onKeyCheck($event)" id="tbb_1c2m_0730" :formatter="format_one_decimal"  size="sm" class="text-center" :state="validateState($v.tbb_1c2m_0730)" v-model.number="$v.tbb_1c2m_0730.$model"></b-form-input>
                                        <b-tooltip v-if="CC.tbb_1c2m_0730.status" target="tbb_1c2m_0730" :triggers="'hover'" placement="top">
                                            {{ CC.tbb_1c2m_0730.message }}
                                        </b-tooltip>
                                        <b-tooltip v-if="!CC.tbb_1c2m_0730.status" :show="validateTooltipState('tbb_1c2m_0730')" :triggers="'hover'" target="tbb_1c2m_0730" placement="top" custom-class="validation-tooltip">
                                            {{ tooltipMessage("tbb_1c2m_0730") }}
                                        </b-tooltip>
                                    </b-col>
                                    
                                    <b-col cols="3" class=" m-auto pr-1 pl-0">
                                        <b-form-input size="sm" class="bg-light-success text-center" v-model.number="rh_1c2m_0730" disabled></b-form-input>
                                    </b-col>
                                </b-row>

                            </b-col>
                        </b-row>
                    </b-collapse>
                    </b-card>
                </b-col>
                <!-- PSYCROMETER SANGKAR end -->
                <!-- ANGIN start -->
                <b-col md="6" lg="4" class="mb-1">
                    <b-card                   
                        class="small  h-100"                   
                    > 
                    <b-row>
                        <b-col cols="10">
                            <h6 class="mb-2 small text-dark font-weight-bolder badge badge-light-dark">ANGIN</h6>
                        </b-col>
                        <b-col cols="1">
                            <feather-icon v-b-toggle.collapse-a icon="ChevronDownIcon" />
                        </b-col>

                    </b-row>
                    <b-collapse v-if="fdih_status.is_agm1b" visible id="collapse-a">
                        <b-row>
                            <b-col cols="12" >
                                <b-row class="mb-1">
                                    <b-col cols="12" class="font-weight-bolder text-primary">
                                        Kecepatan Rata-Rata
                                    </b-col>
                                </b-row>

                                <b-row class="my-1">
                                    <b-col cols="3"></b-col>
                                    <b-col cols="3" class="text-center pad2">
                                        <div class="label-entry2 bg-light-primary h-100">
                                            Counter Sebelum
                                        </div>
                                    </b-col>
                                    <b-col cols="3" class="text-center pad2">
                                        <div class="label-entry2 bg-light-primary h-100">
                                            Counter Dibaca
                                        </div>
                                    </b-col>
                                    <b-col cols="3" class="text-center pad2">
                                        <div class="label-entry2 bg-light-primary h-100">
                                            Counter Rata
                                        </div>
                                    </b-col>
                                </b-row>

                                <b-row class=" my-1">
                                    <b-col cols="3" class="pad2">
                                        <div class="label-entry1">
                                            0.5 m 
                                            <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips2" />
                                        </div>
                                        <!-- HTML tooltips -->
                                        <b-tooltip target="tooltips2" :triggers="'hover'" placement="top">
                                            <dl class="text-left">
                                            <dt>Counter Sebelum</dt>
                                            <dd>- Cup Counter dibaca  0,5 m 17.30 WS kemarin</dd>
                                            <dt>Counter dibaca</dt>
                                            <dd>- cup counter dibaca 0,5 m 07.30 WS hari ini</dd>
                                            <dt>Counter Rata</dt>
                                            <dd>- Kecepatan angin rata-rata, hasil bagi (sampai dua angka dibelakang koma dari selisih pembacaan Cup Counter yang tingginya 0.5 m antara jam 17.30 WS kemarin dengan 
                                                     jam 07.30 WS hari ini dibagi 14. Dinyatakan dalam Km/jam.</dd>
                                            </dl>
                                        </b-tooltip>
                                    </b-col>
                                    <b-col cols="3 pad2">
                                        <b-form-input v-on:keydown="onKeyCheck($event)"  size="sm" class=" text-center" v-model.number="counter_sebelum_0c5_0730" :disabled="!is_counter_edit"></b-form-input>
                                    </b-col>
                                    <b-col cols="3 pad2">
                                        <b-form-input v-on:keydown="onKeyCheck($event)" id="counter_0c5_0730" :formatter="format_two_decimal"  size="sm" class="text-center" :state="validateState($v.counter_0c5_0730)" v-model.number="$v.counter_0c5_0730.$model"></b-form-input>
                                        <!-- tooltips CC -->
                                        <b-tooltip v-if="CC.counter_0c5_0730.status" target="counter_0c5_0730" :triggers="'hover'" placement="top">
                                            {{ CC.counter_0c5_0730.message }}
                                        </b-tooltip>
                                        <b-tooltip v-if="!CC.counter_0c5_0730.status" :show="validateTooltipState('counter_0c5_0730')" :triggers="'hover'" target="counter_0c5_0730" placement="top" custom-class="validation-tooltip">
                                            {{ tooltipMessage("counter_0c5_0730") }}
                                        </b-tooltip>
                                    </b-col>
                                    <b-col cols="3 pad2">
                                        <b-form-input  size="sm" class=" bg-light-success text-center" v-model.number="ws_avg_0c5_0730" disabled></b-form-input>
                                    </b-col>
                                </b-row>

                                <b-row class="mt-1">
                                    <b-col cols="3"/>
                                    <b-col cols="3" >
                                    <feather-icon 
                                        icon="HelpCircleIcon" 
                                        size="14" 
                                        class="bg-primary rounded-circle text-white float-right " 
                                        v-b-tooltip.hover.html="{ title: fdih_counter_sebelum_0730 }" 
                                        style="margin: 5px auto"
                                    />
                                    <b-form-checkbox tabindex="-1" class="custom-control-primary float-right" name="check-button" switch v-model="is_counter_edit">
                                        <span class="switch-icon-left">
                                        <feather-icon icon="CheckIcon" />
                                        </span>
                                        <span class="switch-icon-right">
                                        <feather-icon icon="XIcon" />
                                        </span>
                                    </b-form-checkbox>
                                    
                                    </b-col>
                                </b-row>

                                <hr>

                                <b-row class="mb-1">
                                    <b-col cols="12" class="font-weight-bolder text-primary">
                                        Kecepatan saat Peramatan
                                    </b-col>
                                </b-row>

                                <b-row class=" my-1">
                                    <b-col cols="6" class="m-auto">
                                        <div class="label-entry1" >
                                            10 m
                                            <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips10m" />
                                        </div>
                                        <b-tooltip target="tooltips10m" :triggers="'hover'" placement="top">
                                            <dl class="text-left">
                                            <dt>Kecepatan angin ketinggian 10 m dalam meter/detik (m/s)</dt>
                                            </dl>
                                        </b-tooltip>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-form-input 
                                            v-on:keydown="onKeyCheck($event)" 
                                            id="ws_10_0730" 
                                            :formatter="format_one_decimal"  
                                            size="sm" class="text-center" 
                                            :state="validateState($v.ws_10_0730)" 
                                            v-model.number="$v.ws_10_0730.$model"
                                        ></b-form-input>
                                        <b-tooltip :show="validateTooltipState('ws_10_0730')" :triggers="'hover'" target="ws_10_0730" placement="top" custom-class="validation-tooltip">
                                            {{ tooltipMessage("ws_10_0730") }}
                                        </b-tooltip>
                                    </b-col>
                                </b-row>

                            </b-col>
                        </b-row>
                    </b-collapse>
                    </b-card>
                </b-col>
                <!-- ANGIN end -->
            
                <!-- OP PENGUAPAN start -->
                <b-col md="6" lg="4" class="mb-1">
                    <b-card
                    class="small  h-100"
                    >
                    <b-row>
                        <b-col cols="10">
                            <h6 class="mb-2 small text-dark font-weight-bolder badge badge-light-dark">OP PENGUAPAN</h6>
                        </b-col>
                        <b-col cols="1">
                            <feather-icon v-b-toggle.collapse-a icon="ChevronDownIcon" />
                        </b-col>

                    </b-row>
                    <b-collapse v-if="fdih_status.is_agm1b || fdih_status.is_op_penguapan" visible id="collapse-a">
                        <b-row>
                            <b-col cols="12">
                                <b-row class="mbtm">
                                    <b-col cols="6">
                                        <div class="label-entry1">
                                            Tinggi Air Sebelum <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Ketinggian air pada bejana yang dibaca pada jam 17.30 WS kemarin"/>
                                        </div>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-form-input id="input_op_diff_baca_0730"
                                            v-on:keydown="onKeyCheck($event)"
                                            v-on:keyup="checkValidate($event, 'op_diff_baca_0730')"
                                            v-bind:class="{ rangecheck: rc_op_diff_baca_0730 }" 
                                            size="sm" class="text-center" 
                                            v-model.number="op_diff_baca_0730" 
                                            :disabled="!is_op_edit"
                                        ></b-form-input>
                                        <b-tooltip v-if="rc_op_diff_baca_0730" target="input_op_diff_baca_0730" triggers="hover">{{ rc_op_diff_baca_0730_message }}</b-tooltip>
                                    </b-col>
                                </b-row>

                                <b-row class="mbtm">
                                    <b-col cols="6" >
                                        <div class="label-entry1">
                                            Tinggi Air dibaca <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Ketinggian air pada bejana yang dibaca pada jam 07.30 WS hari ini"/>
                                        </div>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-form-input v-on:keydown="onKeyCheck($event)" :formatter="format_one_decimal"  size="sm" class="text-center" v-model.number="h_air_0730" ></b-form-input>
                                    </b-col>
                                </b-row>


                                <b-row class="mbtm">
                                    <b-col cols="6" >
                                        <div class="label-entry1">
                                            Selisih Tinggi Air <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips3" /></div>
                                        <!-- HTML tooltips -->
                                        <b-tooltip target="tooltips3" :triggers="'hover'" placement="topleft">
                                            <dl class="text-left">
                                            <dt>Selisih Tinggi Air</dt>
                                            <dd>Beda pembacaan tinggi air pada bejana selama 14 jam (jam 17.30 WS kemarin dan 07.30 WS hari ini)</dd>
                                            <dd>perhitungan selisih tinggi air dapat dilakukan dengan atau tanpa adanya pengurasan maupun penambahan air (Reset)</dd>
                                            
                                            <dd>- OP Penguapan Tanpa Kuras/Reset Selisih Tinggi Air = Tinggi Air Sebelum (17.30 WS ) - Tinggi Air Dibaca (07.30 WS)</dd>

                                            <dd>- OP Penguapan dengan Kuras/Reset (Jam 07.30 WS) Selisih Tinggi Air = Tinggi Air Sebelum (17.30 WS ) - Tinggi Air Dibaca (07.30 WS) </dd>
                                            
                                            <dd>- OP Penguapan dengan Kuras/Reset (jam antara)
                                                    Selisih Tinggi Air = ((Tinggi Air Sebelum (17.30 WS) - Tinggi Air Sebelum Kuras/Reset) + (Tinggi Air Setelah Kuras/Reset - Tinggi Air Dibaca(07.30 WS))</dd>
                                            </dl>
                                        </b-tooltip>
                                    </b-col>
                                    <b-col cols="4 pad3">
                                        <b-form-input  size="sm" class="bg-light-success text-center" :state="validateState($v.h_air_diff_0730)" v-model.number="$v.h_air_diff_0730.$model" disabled></b-form-input>
                                    </b-col>
                                    <b-col cols="2">
                                            <!-- <b-button
                                            v-b-tooltip.hover.v-primary
                                            title="Add OP Kuras"
                                            id="popover-reactive-1"
                                            ref="button"
                                            size="sm"
                                            variant="gradient-success"
                                            class="btn-icon rounded-circle ml-sm-6"
                                            >
                                            <feather-icon icon="PlusSquareIcon" />
                                            </b-button> -->
                                            <b-button
                                                v-b-tooltip.hover.v-primary
                                                title="Add OP Kuras"
                                                ref="button"
                                                size="sm"
                                                variant="gradient-success"
                                                class="btn-icon rounded-circle ml-sm-6"
                                                v-b-modal.modal-op-kuras-0730
                                            >
                                                <feather-icon icon="PlusSquareIcon" />
                                            </b-button>
                                    </b-col>
                                </b-row>

                                <b-row class="mbtm">
                                    <b-col cols="6" class="m-auto">
                                        <div class="label-entry1">
                                            T Air Maks <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Temperatur Maksimum dari thermometer apung, dinyatakan dalam persepuluhan derajat Celsius."/>
                                        </div>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-form-input :formatter="format_one_decimal" id="input_t_air_max_0730"
                                            v-on:keydown="onKeyCheck($event)"
                                            v-on:keyup="checkValidate($event, 't_air_max_0730')"
                                            v-bind:class="{ rangecheck: rc_t_air_max_0730 }"
                                             size="sm" class="text-center" :state="validateState($v.t_air_max_0730)" v-model.number="$v.t_air_max_0730.$model">
                                        </b-form-input>
                                        <b-tooltip v-if="CC.t_air_max_0730.status" target="input_t_air_max_0730" :triggers="'hover'" placement="top">
                                            {{ CC.t_air_max_0730.message }}
                                        </b-tooltip>
                                        <b-tooltip v-if="rc_t_air_max_0730 && !CC.t_air_max_0730.status" target="input_t_air_max_0730" triggers="hover">{{ rc_t_air_max_0730_message }}</b-tooltip>
                                        <b-tooltip v-if="!rc_t_air_max_0730 && !CC.t_air_max_0730.status" :show="validateTooltipState('t_air_max_0730')" :triggers="'hover'" target="input_t_air_max_0730" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("t_air_max_0730") }}
                                    </b-tooltip>
                                    </b-col>
                                </b-row>

                                <b-row class="mbtm">
                                    <b-col cols="6" class="m-auto">
                                        <div class="label-entry1">
                                            T Air Min <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Temperatur Minimum dari thermometer apung, dinyatakan dalam persepuluhan derajat Celsius."/>
                                        </div>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-form-input :formatter="format_one_decimal" id="input_t_air_min_0730"
                                            v-on:keydown="onKeyCheck($event)"
                                            v-on:keyup="checkValidate($event, 't_air_min_0730')"
                                            v-bind:class="{ rangecheck: rc_t_air_min_0730 }"
                                             size="sm" class="text-center" :state="validateState($v.t_air_min_0730)" v-model.number="$v.t_air_min_0730.$model">
                                        </b-form-input>
                                        <b-tooltip v-if="CC.t_air_min_0730.status" target="input_t_air_min_0730" :triggers="'hover'" placement="top">
                                            {{ CC.t_air_min_0730.message }}
                                        </b-tooltip>
                                        <b-tooltip v-if="rc_t_air_min_0730 && !CC.t_air_min_0730.status" target="input_t_air_min_0730" triggers="hover">{{ rc_t_air_min_0730_message }}</b-tooltip>
                                        <b-tooltip v-if="!rc_t_air_min_0730 && !CC.t_air_min_0730.status" :show="validateTooltipState('t_air_min_0730')" :triggers="'hover'" target="input_t_air_min_0730" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("t_air_min_0730") }}
                                    </b-tooltip>
                                    </b-col>
                                </b-row>

                                <b-row class="mbtm">
                                    <b-col cols="6" class="m-auto">
                                        <div class="label-entry1">
                                            T Rata Air <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title=" Temperatur Rata-rata dari Thermometer Apung, diperoleh dari (T Air Max + T Air Min)/2, dinyatakan dalam persepuluhan derajat Celsius."/>
                                        </div>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-form-input  size="sm" class="text-center bg-light-success" v-model.number="t_air_avg_0730" disabled></b-form-input>
                                    </b-col>
                                </b-row>

                                <b-row class="mbtm">
                                    <b-col cols="6" class="m-auto">
                                        <div class="label-entry1">
                                            CH diukur <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Hasil penakaran curah hujan selama 14 jam antara jam 17.30 WS dan 07.30  WS, yang dibaca pada jam 07.30 WS, dinyatakan dalam milimeter"/>
                                        </div>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-form-input v-on:keydown="onKeyCheck($event)" :formatter="format_one_decimal" id="input_rr_0730"
                                            v-on:keyup="checkValidate($event, 'rr_0730')"
                                            v-bind:class="{ rangecheck: rc_rr_0730 }"
                                             size="sm" class="text-center" :state="validateState($v.rr_0730)" v-model.number="$v.rr_0730.$model"></b-form-input>
                                        <b-tooltip v-if="rc_rr_0730" target="input_rr_0730" triggers="hover">{{ rc_rr_0730_message }}</b-tooltip>
                                        <b-tooltip v-if="!rc_rr_0730" :show="validateTooltipState('rr_0730')" :triggers="'hover'" target="input_rr_0730" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("rr_0730") }}
                                    </b-tooltip>
                                    </b-col>
                                </b-row>

                                <b-row class="">
                                    <b-col cols="6" >
                                        <div class="label-entry1">
                                            Penguapan <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Nilai penguapan antara jam  17.30 WS kemarin dan 07.30 WS hari ini, diperoleh dari hasil penjumlahan CH Diukur dengan Selisih Tinggi Air, dinyatakan dalam milimeter"/>
                                        </div>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-form-input  size="sm" class=" bg-light-success text-center" v-model.number="op_eva_0730" disabled></b-form-input>
                                    </b-col>
                                </b-row>

                                <b-row class="float-right mt-1">
                                    <b-col cols="12" >
                                    <feather-icon 
                                        icon="HelpCircleIcon" 
                                        size="14" 
                                        class="bg-primary rounded-circle text-white float-right " 
                                        v-b-tooltip.hover.html="{ title: fdih_op_penguapan_0730 }" 
                                        style="margin: 5px auto"
                                    />
                                    <b-form-checkbox tabindex="-1" class="custom-control-primary float-right" name="check-button" switch v-model="is_op_edit">
                                        <span class="switch-icon-left">
                                        <feather-icon icon="CheckIcon" />
                                        </span>
                                        <span class="switch-icon-right">
                                        <feather-icon icon="XIcon" />
                                        </span>
                                    </b-form-checkbox>
                                    
                                    </b-col>
                                </b-row>

                            </b-col>
                        </b-row>
                    </b-collapse>

                        
                    </b-card>
                </b-col>
                <!-- OP PENGUAPAN end -->
            </b-row>
            <b-row>
                <!-- PSYCROMETER ASSMAN start -->
                <b-col md="6" lg="4" class="mb-1">
                    <b-card class="small  h-100">
                        <h6 class="mb-2 small text-dark font-weight-bolder badge badge-light-dark">PSYCROMETER ASSMAN</h6>
                        <b-row v-if="fdih_status.is_psychrometer_assman">
                            <b-col cols="12">

                                <b-row class=" mb-1">
                                    <b-col cols="3"></b-col>
                                    <b-col cols="3" class="text-center pr-1 pl-0">
                                        <div class="label-entry2 bg-light-primary">
                                            TBK
                                        </div>
                                    </b-col>
                                    <b-col cols="3" class="text-center pr-1 pl-0">
                                        <div class="label-entry2 bg-light-primary">
                                            TBB
                                        </div>
                                    </b-col>
                                    <b-col cols="3" class="text-center pr-1 pl-0">
                                        <div class="label-entry2 bg-light-primary">
                                            RH
                                        </div>
                                    </b-col>
                                </b-row>

                                <b-row class="my-1">
                                    <b-col cols="3" class=" pad1">
                                        <div class="label-entry1">
                                            5 cm <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips4" /></div>
                                        <!-- HTML tooltips -->
                                        <b-tooltip target="tooltips4" :triggers="'hover'" placement="top">
                                            <dl class="text-left">
                                            <dt>Tbk</dt>
                                            <dd>- Pembacaan termometer bola kering psychrometer assmann untuk ketinggian 5 cm dalam persepuluhan derajat Celcius</dd>
                                            <dt>Tbb</dt>
                                            <dd>- Pembacaan termometer bola basah  psychrometer assmann untuk ketinggian 5 cm dalam persepuluhan derajat Celcius</dd>
                                            <dt>RH</dt>
                                            <dd>- Lembab Nisbi Udara  psychrometer assmann untuk ketinggian 5 cm dalam persen (%)</dd>
                                            </dl>
                                        </b-tooltip>
                                    </b-col>
                                    <b-col cols="3" class="pr-1 pl-0">
                                        <b-form-input v-on:keydown="onKeyCheck($event)" id="input_tbk_5cm_0730" :formatter="format_one_decimal"  size="sm" class="text-center"
                                            v-on:keyup="checkValidate($event, 'tbk_5cm_0730')"
                                            v-bind:class="{ rangecheck: rc_tbk_5cm_0730 }" 
                                            :state="validateState($v.tbk_5cm_0730)" v-model.number="$v.tbk_5cm_0730.$model">
                                        </b-form-input>
                                        <b-tooltip v-if="CC.tbk_5cm_0730.status" target="input_tbk_5cm_0730" :triggers="'hover'" placement="top">
                                            {{ CC.tbk_5cm_0730.message }}
                                        </b-tooltip>
                                        <b-tooltip v-if="rc_tbk_5cm_0730 && !CC.tbk_5cm_0730.status" target="input_tbk_5cm_0730" triggers="hover" placement="topleft">{{ rc_tbk_5cm_0730_message }}</b-tooltip>
                                        <b-tooltip v-if="!rc_tbk_5cm_0730 && !CC.tbk_5cm_0730.status" :show="validateTooltipState('tbk_5cm_0730')" :triggers="'hover'" target="input_tbk_5cm_0730" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("tbk_5cm_0730") }}
                                    </b-tooltip>
                                    </b-col>
                                    <b-col cols="3" class="pr-1 pl-0">
                                        <b-form-input v-on:keydown="onKeyCheck($event)" id="input_tbb_5cm_0730" :formatter="format_one_decimal"  size="sm" class="text-center" 
                                            v-on:keyup="checkValidate($event, 'tbb_5cm_0730')"
                                            v-bind:class="{ rangecheck: rc_tbb_5cm_0730 }" 
                                            :state="validateState($v.tbb_5cm_0730)" v-model.number="$v.tbb_5cm_0730.$model">
                                        </b-form-input>
                                        <b-tooltip v-if="CC.tbb_5cm_0730.status" target="input_tbb_5cm_0730" :triggers="'hover'" placement="top">
                                            {{ CC.tbb_5cm_0730.message }}
                                        </b-tooltip>
                                        <b-tooltip v-if="rc_tbb_5cm_0730 && !CC.tbb_5cm_0730.status" target="input_tbb_5cm_0730" triggers="hover" placement="topleft">{{ rc_tbb_5cm_0730_message }}</b-tooltip>
                                        <b-tooltip v-if="!rc_tbb_5cm_0730 && !CC.tbb_5cm_0730.status" :show="validateTooltipState('tbb_5cm_0730')" :triggers="'hover'" target="input_tbb_5cm_0730" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("tbb_5cm_0730") }}
                                    </b-tooltip>
                                    </b-col>
                                    
                                    <b-col cols="3" class="pr-1 pl-0">
                                        <b-form-input  size="sm" class="bg-light-success text-center" v-model.number="rh_5cm_0730" disabled></b-form-input>
                                    </b-col>
                                </b-row>

                                <b-row class=" my-1">
                                    <b-col cols="3" class="pad1">
                                        <div class="label-entry1">
                                            10 cm <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips5" /></div>
                                        <!-- HTML tooltips -->
                                        <b-tooltip target="tooltips5" :triggers="'hover'" placement="top">
                                            <dl class="text-left">
                                            <dt>Tbk</dt>
                                            <dd>- Pembacaan termometer bola kering psychrometer assmann untuk ketinggian 10 cm dalam persepuluhan derajat Celcius</dd>
                                            <dt>Tbb</dt>
                                            <dd>- Pembacaan termometer bola basah  psychrometer assmann untuk ketinggian 10 cm dalam persepuluhan derajat Celcius</dd>
                                            <dt>RH</dt>
                                            <dd>- Lembab Nisbi Udara  psychrometer assmann untuk ketinggian 10 cm dalam persen (%)</dd>
                                            </dl>
                                        </b-tooltip>
                                    </b-col>
                                    <b-col cols="3" class="pr-1 pl-0">
                                        <b-form-input v-on:keydown="onKeyCheck($event)" id="input_tbk_10cm_0730" :formatter="format_one_decimal"  size="sm" class="text-center" 
                                            v-on:keyup="checkValidate($event, 'tbk_10cm_0730')"
                                            v-bind:class="{ rangecheck: rc_tbk_10cm_0730 }"
                                            :state="validateState($v.tbk_10cm_0730)" v-model.number="$v.tbk_10cm_0730.$model">
                                        </b-form-input>
                                        <b-tooltip v-if="CC.tbk_10cm_0730.status" target="input_tbk_10cm_0730" :triggers="'hover'" placement="top">
                                            {{ CC.tbk_10cm_0730.message }}
                                        </b-tooltip>
                                        <b-tooltip v-if="rc_tbk_10cm_0730 && !CC.tbk_10cm_0730.status" target="input_tbk_10cm_0730" triggers="hover" placement="topleft">{{ rc_tbk_10cm_0730_message }}</b-tooltip>
                                        <b-tooltip v-if="!rc_tbk_10cm_0730 && !CC.tbk_10cm_0730.status" :show="validateTooltipState('tbk_10cm_0730')" :triggers="'hover'" target="input_tbk_10cm_0730" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("tbk_10cm_0730") }}
                                    </b-tooltip>
                                    </b-col>
                                    <b-col cols="3" class="pr-1 pl-0">
                                        <b-form-input v-on:keydown="onKeyCheck($event)" id="input_tbb_10cm_0730" :formatter="format_one_decimal"  size="sm" class="text-center" 
                                            v-on:keyup="checkValidate($event, 'tbb_10cm_0730')"
                                            v-bind:class="{ rangecheck: rc_tbb_10cm_0730 }"
                                            :state="validateState($v.tbb_10cm_0730)" v-model.number="$v.tbb_10cm_0730.$model">
                                        </b-form-input>
                                        <b-tooltip v-if="CC.tbb_10cm_0730.status" target="input_tbb_10cm_0730" :triggers="'hover'" placement="top">
                                            {{ CC.tbb_10cm_0730.message }}
                                        </b-tooltip>
                                        <b-tooltip v-if="rc_tbb_10cm_0730 && !CC.tbb_10cm_0730.status" target="input_tbb_10cm_0730" triggers="hover" placement="topleft">{{ rc_tbb_10cm_0730_message }}</b-tooltip>
                                        <b-tooltip v-if="!rc_tbb_10cm_0730 && !CC.tbb_10cm_0730.status"  :show="validateTooltipState('tbb_10cm_0730')" :triggers="'hover'" target="input_tbb_10cm_0730" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("tbb_10cm_0730") }}
                                    </b-tooltip>
                                    </b-col>
                                    
                                    <b-col cols="3" class="pr-1 pl-0">
                                        <b-form-input  size="sm" class="bg-light-success text-center" v-model.number="rh_10cm_0730" disabled></b-form-input>
                                    </b-col>
                                </b-row>

                                <b-row class=" my-1">
                                    <b-col cols="3" class=" pad1">
                                        <div class="label-entry1">
                                            20 cm <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips6" /></div>
                                        <!-- HTML tooltips -->
                                        <b-tooltip target="tooltips6" :triggers="'hover'" placement="top">
                                            <dl class="text-left">
                                            <dt>Tbk</dt>
                                            <dd>- Pembacaan termometer bola kering psychrometer assmann untuk ketinggian 20 cm dalam persepuluhan derajat Celcius</dd>
                                            <dt>Tbb</dt>
                                            <dd>- Pembacaan termometer bola basah  psychrometer assmann untuk ketinggian 20 cm dalam persepuluhan derajat Celcius</dd>
                                            <dt>RH</dt>
                                            <dd>- Lembab Nisbi Udara  psychrometer assmann untuk ketinggian 20 cm dalam persen (%)</dd>
                                            </dl>
                                        </b-tooltip>
                                    </b-col>
                                    <b-col cols="3" class="pr-1 pl-0">
                                        <b-form-input v-on:keydown="onKeyCheck($event)" id="input_tbk_20cm_0730" :formatter="format_one_decimal"  size="sm" class="text-center" 
                                            v-on:keyup="checkValidate($event, 'tbk_20cm_0730')"
                                            v-bind:class="{ rangecheck: rc_tbk_20cm_0730 }"
                                            :state="validateState($v.tbk_20cm_0730)" v-model.number="$v.tbk_20cm_0730.$model">
                                        </b-form-input>
                                        <b-tooltip v-if="CC.tbk_20cm_0730.status" target="input_tbk_20cm_0730" :triggers="'hover'" placement="top">
                                            {{ CC.tbk_20cm_0730.message }}
                                        </b-tooltip>
                                        <b-tooltip v-if="rc_tbk_20cm_0730 && !CC.tbk_20cm_0730.status" target="input_tbk_20cm_0730" triggers="hover" placement="topleft">{{ rc_tbk_20cm_0730_message }}</b-tooltip>
                                        <b-tooltip v-if="!rc_tbk_20cm_0730 && !CC.tbk_20cm_0730.status" :show="validateTooltipState('tbk_20cm_0730')" :triggers="'hover'" target="input_tbk_20cm_0730" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("tbk_20cm_0730") }}
                                    </b-tooltip>
                                    </b-col>
                                    <b-col cols="3" class="pr-1 pl-0">
                                        <b-form-input v-on:keydown="onKeyCheck($event)" id="input_tbb_20cm_0730" :formatter="format_one_decimal"  size="sm" class="text-center" 
                                            v-on:keyup="checkValidate($event, 'tbb_20cm_0730')"
                                            v-bind:class="{ rangecheck: rc_tbb_20cm_0730 }"
                                            :state="validateState($v.tbb_20cm_0730)" v-model.number="$v.tbb_20cm_0730.$model">
                                        </b-form-input>
                                        <b-tooltip v-if="CC.tbb_20cm_0730.status" target="input_tbb_20cm_0730" :triggers="'hover'" placement="top">
                                            {{ CC.tbb_20cm_0730.message }}
                                        </b-tooltip>
                                        <b-tooltip v-if="rc_tbb_20cm_0730 && !CC.tbb_20cm_0730.status" target="input_tbb_20cm_0730" triggers="hover" placement="topleft">{{ rc_tbb_20cm_0730_message }}</b-tooltip>
                                        <b-tooltip v-if="!rc_tbb_20cm_0730 && !CC.tbb_20cm_0730.status" :show="validateTooltipState('tbb_20cm_0730')" :triggers="'hover'" target="input_tbb_20cm_0730" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("tbb_20cm_0730") }}
                                    </b-tooltip>
                                    </b-col>
                                    
                                    <b-col cols="3" class="pr-1 pl-0">
                                        <b-form-input  size="sm" class="bg-light-success text-center" v-model.number="rh_20cm_0730" disabled></b-form-input>
                                    </b-col>
                                </b-row>

                                <b-row class=" my-1">
                                    <b-col cols="3" class=" pad1">
                                        <div class="label-entry1">
                                            50 cm <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips7" /></div>
                                        <!-- HTML tooltips -->
                                        <b-tooltip target="tooltips7" :triggers="'hover'" placement="top">
                                            <dl class="text-left">
                                            <dt>Tbk</dt>
                                            <dd>- Pembacaan termometer bola kering psychrometer assmann untuk ketinggian 50 cm dalam persepuluhan derajat Celcius</dd>
                                            <dt>Tbb</dt>
                                            <dd>- Pembacaan termometer bola basah  psychrometer assmann untuk ketinggian 50 cm dalam persepuluhan derajat Celcius</dd>
                                            <dt>RH</dt>
                                            <dd>- Lembab Nisbi Udara  psychrometer assmann untuk ketinggian 50 cm dalam persen (%)</dd>
                                            </dl>
                                        </b-tooltip>
                                    </b-col>
                                    <b-col cols="3" class="pr-1 pl-0">
                                        <b-form-input v-on:keydown="onKeyCheck($event)" id="input_tbk_50cm_0730" :formatter="format_one_decimal"  size="sm" class="text-center" 
                                            v-on:keyup="checkValidate($event, 'tbk_50cm_0730')"
                                            v-bind:class="{ rangecheck: rc_tbk_50cm_0730 }"
                                            :state="validateState($v.tbk_50cm_0730)" v-model.number="$v.tbk_50cm_0730.$model">
                                        </b-form-input>
                                        <b-tooltip v-if="CC.tbk_50cm_0730.status" target="input_tbk_50cm_0730" :triggers="'hover'" placement="top">
                                            {{ CC.tbk_50cm_0730.message }}
                                        </b-tooltip>
                                        <b-tooltip v-if="rc_tbk_50cm_0730 && !CC.tbk_50cm_0730.status" target="input_tbk_50cm_0730" triggers="hover" placement="topleft">{{ rc_tbk_50cm_0730_message }}</b-tooltip>
                                        <b-tooltip v-if="!rc_tbk_50cm_0730 && !CC.tbk_50cm_0730.status" :show="validateTooltipState('tbk_50cm_0730')" :triggers="'hover'" target="input_tbk_50cm_0730" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("tbk_50cm_0730") }}
                                    </b-tooltip>
                                    </b-col>
                                    <b-col cols="3" class="pr-1 pl-0">
                                        <b-form-input v-on:keydown="onKeyCheck($event)" id="input_tbb_50cm_0730" :formatter="format_one_decimal"  size="sm" class="text-center" 
                                            v-on:keyup="checkValidate($event, 'tbb_50cm_0730')"
                                            v-bind:class="{ rangecheck: rc_tbb_50cm_0730 }"
                                            :state="validateState($v.tbb_50cm_0730)" v-model.number="$v.tbb_50cm_0730.$model">
                                        </b-form-input>
                                        <b-tooltip v-if="CC.tbb_50cm_0730.status" target="input_tbb_50cm_0730" :triggers="'hover'" placement="top">
                                            {{ CC.tbb_50cm_0730.message }}
                                        </b-tooltip>
                                        <b-tooltip v-if="rc_tbb_50cm_0730 && !CC.tbb_50cm_0730.status" target="input_tbb_50cm_0730" triggers="hover">{{ rc_tbb_50cm_0730_message }}</b-tooltip>
                                        <b-tooltip v-if="!rc_tbb_50cm_0730 && !CC.tbb_50cm_0730.status" :show="validateTooltipState('tbb_50cm_0730')" :triggers="'hover'" target="input_tbb_50cm_0730" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("tbb_50cm_0730") }}
                                    </b-tooltip>
                                    </b-col>
                                    
                                    <b-col cols="3" class="pr-1 pl-0">
                                        <b-form-input  size="sm" class="bg-light-success text-center" v-model.number="rh_50cm_0730" disabled></b-form-input>
                                    </b-col>
                                </b-row>

                                <b-row class="my-1">
                                    <b-col cols="3" class="pad1">
                                        <div class="label-entry1">
                                            100 cm <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips8" /></div>
                                        <!-- HTML tooltips -->
                                        <b-tooltip target="tooltips8" :triggers="'hover'" placement="top">
                                            <dl class="text-left">
                                            <dt>Tbk</dt>
                                            <dd>- Pembacaan termometer bola kering psychrometer assmann untuk ketinggian 100 cm dalam persepuluhan derajat Celcius</dd>
                                            <dt>Tbb</dt>
                                            <dd>- Pembacaan termometer bola basah  psychrometer assmann untuk ketinggian 100 cm dalam persepuluhan derajat Celcius</dd>
                                            <dt>RH</dt>
                                            <dd>- Lembab Nisbi Udara  psychrometer assmann untuk ketinggian 100 cm dalam persen (%)</dd>
                                            </dl>
                                        </b-tooltip>
                                    </b-col>
                                    <b-col cols="3" class="pr-1 pl-0">
                                        <b-form-input v-on:keydown="onKeyCheck($event)" id="input_tbk_100cm_0730" :formatter="format_one_decimal"  size="sm" class="text-center" 
                                            v-on:keyup="checkValidate($event, 'tbk_100cm_0730')"
                                            v-bind:class="{ rangecheck: rc_tbk_100cm_0730 }"
                                            :state="validateState($v.tbk_100cm_0730)" v-model.number="$v.tbk_100cm_0730.$model">
                                        </b-form-input>
                                        <b-tooltip v-if="CC.tbk_100cm_0730.status" target="input_tbk_100cm_0730" :triggers="'hover'" placement="top">
                                            {{ CC.tbk_100cm_0730.message }}
                                        </b-tooltip>
                                        <b-tooltip v-if="rc_tbk_100cm_0730 && !CC.tbk_100cm_0730.status" target="input_tbk_100cm_0730" triggers="hover" placement="topleft">{{ rc_tbk_100cm_0730_message }}</b-tooltip>
                                        <b-tooltip v-if="!rc_tbk_100cm_0730 && !CC.tbk_100cm_0730.status" :show="validateTooltipState('tbk_100cm_0730')" :triggers="'hover'" target="input_tbk_100cm_0730" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("tbk_100cm_0730") }}
                                    </b-tooltip>
                                    </b-col>
                                    <b-col cols="3" class="pr-1 pl-0">
                                        <b-form-input v-on:keydown="onKeyCheck($event)" id="input_tbb_100cm_0730" :formatter="format_one_decimal"  size="sm" class="text-center" 
                                            v-on:keyup="checkValidate($event, 'tbb_100cm_0730')"
                                            v-bind:class="{ rangecheck: rc_tbb_100cm_0730 }"
                                            :state="validateState($v.tbb_100cm_0730)" v-model.number="$v.tbb_100cm_0730.$model">
                                        </b-form-input>
                                        <b-tooltip v-if="CC.tbb_100cm_0730.status" target="input_tbb_100cm_0730" :triggers="'hover'" placement="top">
                                            {{ CC.tbb_100cm_0730.message }}
                                        </b-tooltip>
                                        <b-tooltip v-if="rc_tbb_100cm_0730 && !CC.tbb_100cm_0730.status" target="input_tbb_100cm_0730" triggers="hover" placement="topleft">{{ rc_tbb_100cm_0730_message }}</b-tooltip>
                                        <b-tooltip v-if="!rc_tbb_100cm_0730 && !CC.tbb_100cm_0730.status" :show="validateTooltipState('tbb_100cm_0730')" :triggers="'hover'" target="input_tbb_100cm_0730" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("tbb_100cm_0730") }}
                                    </b-tooltip>
                                    </b-col>
                                    
                                    <b-col cols="3" class="pr-1 pl-0">
                                        <b-form-input  size="sm" class="bg-light-success text-center" v-model.number="rh_100cm_0730" disabled></b-form-input>
                                    </b-col>
                                </b-row>

                                <b-row class=" my-1">
                                    <b-col cols="3" class="pad1">
                                        <div class="label-entry1">
                                            150 cm <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips10" /></div>
                                        <!-- HTML tooltips -->
                                        <b-tooltip target="tooltips10" :triggers="'hover'" placement="top">
                                            <dl class="text-left">
                                            <dt>Tbk</dt>
                                            <dd>- Pembacaan termometer bola kering psychrometer assmann untuk ketinggian 150 cm dalam persepuluhan derajat Celcius</dd>
                                            <dt>Tbb</dt>
                                            <dd>- Pembacaan termometer bola basah  psychrometer assmann untuk ketinggian 150 cm dalam persepuluhan derajat Celcius</dd>
                                            <dt>RH</dt>
                                            <dd>- Lembab Nisbi Udara  psychrometer assmann untuk ketinggian 150 cm dalam persen (%)</dd>
                                            </dl>
                                        </b-tooltip>
                                    </b-col>
                                    <b-col cols="3" class="pr-1 pl-0">
                                        <b-form-input v-on:keydown="onKeyCheck($event)" id="input_tbk_150cm_0730" :formatter="format_one_decimal"  size="sm" class="text-center" 
                                            v-on:keyup="checkValidate($event, 'tbk_150cm_0730')"
                                            v-bind:class="{ rangecheck: rc_tbk_150cm_0730 }"
                                            :state="validateState($v.tbk_150cm_0730)" v-model.number="$v.tbk_150cm_0730.$model">
                                        </b-form-input>
                                        <b-tooltip v-if="CC.tbk_150cm_0730.status" target="input_tbk_150cm_0730" :triggers="'hover'" placement="top">
                                            {{ CC.tbk_150cm_0730.message }}
                                        </b-tooltip>
                                        <b-tooltip v-if="rc_tbk_150cm_0730 && !CC.tbk_150cm_0730.status" target="input_tbk_150cm_0730" triggers="hover" placement="topleft">{{ rc_tbk_150cm_0730_message }}</b-tooltip>
                                        <b-tooltip v-if="!rc_tbk_150cm_0730 && !CC.tbk_150cm_0730.status" :show="validateTooltipState('tbk_150cm_0730')" :triggers="'hover'" target="input_tbk_150cm_0730" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("tbk_150cm_0730") }}
                                    </b-tooltip>
                                    </b-col>
                                    <b-col cols="3" class="pr-1 pl-0">
                                        <b-form-input v-on:keydown="onKeyCheck($event)" id="input_tbb_150cm_0730" :formatter="format_one_decimal"  size="sm" class="text-center" 
                                            v-on:keyup="checkValidate($event, 'tbb_150cm_0730')"
                                            v-bind:class="{ rangecheck: rc_tbb_150cm_0730 }"
                                            :state="validateState($v.tbb_150cm_0730)" v-model.number="$v.tbb_150cm_0730.$model">
                                        </b-form-input>
                                        <b-tooltip v-if="CC.tbb_150cm_0730.status" target="input_tbb_150cm_0730" :triggers="'hover'" placement="top">
                                            {{ CC.tbb_150cm_0730.message }}
                                        </b-tooltip>
                                        <b-tooltip v-if="rc_tbb_150cm_0730 && !CC.tbb_150cm_0730.status" target="input_tbb_150cm_0730" triggers="hover" placement="topleft">{{ rc_tbb_150cm_0730_message }}</b-tooltip>
                                        <b-tooltip v-if="!rc_tbb_150cm_0730 && !CC.tbb_150cm_0730.status" :show="validateTooltipState('tbb_150cm_0730')" :triggers="'hover'" target="input_tbb_150cm_0730" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("tbb_150cm_0730") }}
                                    </b-tooltip>
                                    </b-col>
                                    <b-col cols="3" class="pr-1 pl-0">
                                        <b-form-input  size="sm" class="bg-light-success text-center" v-model.number="rh_150cm_0730" disabled></b-form-input>
                                    </b-col>
                                </b-row>

                                <b-row class="my-1">
                                    <b-col cols="3" class=" pad1">
                                        <div class="label-entry1">
                                            200 cm <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips11" /></div>
                                        <!-- HTML tooltips -->
                                        <b-tooltip target="tooltips11" :triggers="'hover'" placement="top">
                                            <dl class="text-left">
                                            <dt>Tbk</dt>
                                            <dd>- Pembacaan termometer bola kering psychrometer assmann untuk ketinggian 200 cm dalam persepuluhan derajat Celcius</dd>
                                            <dt>Tbb</dt>
                                            <dd>- Pembacaan termometer bola basah  psychrometer assmann untuk ketinggian 200 cm dalam persepuluhan derajat Celcius</dd>
                                            <dt>RH</dt>
                                            <dd>- Lembab Nisbi Udara  psychrometer assmann untuk ketinggian 200 cm dalam persen (%)</dd>
                                            </dl>
                                        </b-tooltip>
                                    </b-col>
                                    <b-col cols="3" class="pr-1 pl-0">
                                        <b-form-input v-on:keydown="onKeyCheck($event)" id="input_tbk_200cm_0730" :formatter="format_one_decimal"  size="sm" class="text-center" 
                                            v-on:keyup="checkValidate($event, 'tbk_200cm_0730')"
                                            v-bind:class="{ rangecheck: rc_tbk_200cm_0730 }"
                                            :state="validateState($v.tbk_200cm_0730)" v-model.number="$v.tbk_200cm_0730.$model">
                                        </b-form-input>
                                        <b-tooltip v-if="CC.tbk_200cm_0730.status" target="input_tbk_200cm_0730" :triggers="'hover'" placement="top">
                                            {{ CC.tbk_200cm_0730.message }}
                                        </b-tooltip>
                                        <b-tooltip v-if="rc_tbk_200cm_0730 && !CC.tbk_200cm_0730.status" target="input_tbk_200cm_0730" triggers="hover" placement="topleft">{{ rc_tbk_200cm_0730_message }}</b-tooltip>
                                        <b-tooltip v-if="!rc_tbk_200cm_0730 && !CC.tbk_200cm_0730.status" :show="validateTooltipState('tbk_200cm_0730')" :triggers="'hover'" target="input_tbk_200cm_0730" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("tbk_200cm_0730") }}
                                    </b-tooltip>
                                    </b-col>
                                    <b-col cols="3" class="pr-1 pl-0">
                                        <b-form-input v-on:keydown="onKeyCheck($event)" id="input_tbb_200cm_0730" :formatter="format_one_decimal"  size="sm" class="text-center" 
                                            v-on:keyup="checkValidate($event, 'tbb_200cm_0730')"
                                            v-bind:class="{ rangecheck: rc_tbb_200cm_0730 }"
                                            :state="validateState($v.tbb_200cm_0730)" v-model.number="$v.tbb_200cm_0730.$model">
                                        </b-form-input>
                                        <b-tooltip v-if="CC.tbb_200cm_0730.status" target="input_tbb_200cm_0730" :triggers="'hover'" placement="top">
                                            {{ CC.tbb_200cm_0730.message }}
                                        </b-tooltip>
                                        <b-tooltip v-if="rc_tbb_200cm_0730 && !CC.tbb_200cm_0730.status" target="input_tbb_200cm_0730" triggers="hover" placement="topleft">{{ rc_tbb_200cm_0730_message }}</b-tooltip>
                                        <b-tooltip v-if="!rc_tbb_200cm_0730 && !CC.tbb_200cm_0730.status" :show="validateTooltipState('tbb_200cm_0730')" :triggers="'hover'" target="input_tbb_200cm_0730" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("tbb_200cm_0730") }}
                                    </b-tooltip>
                                    </b-col>
                                    
                                    <b-col cols="3" class="pr-1 pl-0">
                                        <b-form-input  size="sm" class="bg-light-success text-center" v-model.number="rh_200cm_0730" disabled></b-form-input>
                                    </b-col>
                                </b-row>

                            </b-col>
                        </b-row>

                    </b-card>
                </b-col>
                <!-- PSYCROMETER ASSMAN end -->
            
                <!-- PICHE PENGUAPAN start -->
                <b-col md="6" lg="4" class="mb-1">
                    <b-card class="small  ">
                        <h6 class="mb-2 small text-dark font-weight-bolder badge badge-light-dark">PICHE PENGUAPAN</h6>
                        <b-row v-if="fdih_status.is_piche_penguapan">
                            <b-col cols="12">

                                <b-row class="">
                                    <b-col cols="6" class="m-auto">
                                        <div class="label-entry1">
                                            Pembacaan Sebelum <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Nilai pembacaan ketinggian air pada piche pada jam 17.30 WS kemarin, dinyatakan dalam milimeter"/>
                                        </div>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-form-input v-on:keydown="onKeyCheck($event)"  size="sm" class="text-center" v-model.number="pitche_sebelum_0730" :disabled="!is_piche_edit"></b-form-input>
                                    </b-col>
                                </b-row>

                                <b-row class="my-1">
                                    <b-col cols="6" class="m-auto">
                                        <div class="label-entry1">
                                            Pembacaan Sekarang <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Nilai pembacaan ketinggian air pada piche pada jam 07.30 WS hari ini, dinyatakan dalam milimeter"/>
                                        </div>
                                    </b-col>
                                    <b-col cols="4 pad3">
                                        <b-form-input v-on:keydown="onKeyCheck($event)" id="input_h_piche_0730" :formatter="format_one_decimal"  size="sm" class="text-center" :state="validateState($v.h_piche_0730)" v-model.number="$v.h_piche_0730.$model"></b-form-input>
                                        <b-tooltip v-if="CC.h_piche_0730.status" target="input_h_piche_0730" :triggers="'hover'" placement="top">
                                            {{ CC.h_piche_0730.message }}
                                        </b-tooltip>
                                    </b-col>
                                    <b-col cols="2">
                                            <b-button
                                            v-b-tooltip.hover.v-primary
                                            title="Tambah"

                                            id="popup-2"
                                            ref="popover2"

                                            size="sm"
                                            variant="gradient-success"
                                            class="btn-icon rounded-circle ml-sm-6"
                                            >
                                            <feather-icon icon="PlusSquareIcon" />
                                            </b-button>
                                    </b-col>
                                </b-row>

                                <b-row class="my-1">
                                    <b-col cols="6" class="m-auto">
                                        <div class="label-entry1">
                                            Penguapan Piche <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Selisih pembacaan tinggi air pada periode 17.30 WS kemarin s.d jam 07.30 WS hari ini, dinyatakan dalam milimeter"/>
                                        </div>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-form-input  size="sm" class="bg-light-success text-center" v-model.number="eva_piche_0730" disabled></b-form-input>
                                    </b-col>
                                </b-row>

                                <b-row class="float-right">
                                    <b-col cols="12" >
                                        <feather-icon 
                                            icon="HelpCircleIcon" 
                                            size="14" 
                                            class="bg-primary rounded-circle text-white float-right " 
                                            v-b-tooltip.hover.html="{ title: fdih_piche_0730 }" 
                                            style="margin: 5px auto"
                                        />
                                        <b-form-checkbox tabindex="-1" class="custom-control-primary float-right" name="check-button" switch v-model="is_piche_edit">
                                            <span class="switch-icon-left">
                                            <feather-icon icon="CheckIcon" />
                                            </span>
                                            <span class="switch-icon-right">
                                            <feather-icon icon="XIcon" />
                                            </span>
                                        </b-form-checkbox>
                                    
                                    </b-col>
                                </b-row>
                                <hr class="float-right">
                            </b-col>
                        </b-row>
                    </b-card>
                    <b-card class="mt-n1">
                        <b-row v-if="fdih_status.is_agm1b" class="">
                            <b-col cols="6">
                                <h6 class="mb-1 small font-weight-bolder badge badge-light-dark">KEADAAN TANAH</h6>
                                <b-form-select :state="validateState($v.ket_tanah_0730)" v-model="$v.ket_tanah_0730.$model" :options="opt_ket_tanah" size="sm">
                                </b-form-select>
                                
                            </b-col>
                            
                            <b-col cols="6">
                                <h6 class="mb-1 small font-weight-bolder badge badge-light-dark">KEADAAN HUJAN</h6>
                                <b-form-select  :state="validateState($v.ket_hujan_0730)" v-model="$v.ket_hujan_0730.$model" :options="opt_ket_hujan" size="sm">
                                </b-form-select>
                                
                            </b-col>
                        </b-row>
                        
                        <b-row v-if="fdih_status.is_agm1b" class="my-1">
                            <b-col cols="6">
                                <div class="label-entry1">
                                    <h6 class="mb-1 small font-weight-bolder badge badge-light-dark">KETERANGAN AGM 1-B</h6>
                                    <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Hal-hal yang perlu diterangkan. Mohon jg dapat disesuaikan header per kolom agar seragam"/>
                                </div>
                                
                            </b-col>
                            <b-col cols="6">
                                <b-form-textarea size="sm" class="border border-primary border border-2" v-model="ket_1b_0730"></b-form-textarea>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                
                
                <b-col md="6" lg="4" class="mb-1">
                    <b-card class="small  h-100">
                        <h6 class="mb-2 small text-dark font-weight-bolder badge badge-light-dark">SUHU TANAH</h6>
                        <b-row>
                            <b-col cols="12">

                                <b-row v-if="fdih_status.is_suhu_tanah || fdih_status.is_agm1b" class="">
                                    <b-col cols="4"></b-col>
                                    <b-col cols="4" class="text-center pad2">
                                        <div class="label-entry2 bg-light-primary">
                                            BERUMPUT
                                        </div>
                                    </b-col>
                                    <b-col cols="4" class="text-center">
                                        <div class="label-entry2 bg-light-primary">
                                            GUNDUL
                                        </div>
                                    </b-col>
                                </b-row>
                                     <!-- baris 1 -->
                                <b-row  class="my-1">
                                    <b-col cols="4">
                                        <b-row v-if="fdih_status.is_suhu_tanah" class="m-auto">
                                            <div class="label-entry1">
                                            0 cm <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips12" /></div>
                                            <!-- HTML tooltips -->
                                            <b-tooltip target="tooltips12" :triggers="'hover'" placement="top">
                                                <dl class="text-left">
                                                <dt>Berumput</dt>
                                                <dd>- Temperatur tanah area berumput pada kedalaman 0 cm, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                                <dt>Gundul</dt>
                                                <dd>- Temperatur tanah area gundul pada kedalaman 0 cm, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                                </dl>
                                            </b-tooltip>
                                        </b-row>
                                        <b-row v-if="fdih_status.is_suhu_tanah" class="m-auto">
                                            <div class="label-entry1 mt-2">
                                            2 cm <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips13" /></div>
                                            <!-- HTML tooltips -->
                                            <b-tooltip target="tooltips13" :triggers="'hover'" placement="top">
                                                <dl class="text-left">
                                                <dt>Berumput</dt>
                                                <dd>- Temperatur tanah area berumput pada kedalaman 2 cm, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                                <dt>Gundul</dt>
                                                <dd>- Temperatur tanah area gundul pada kedalaman 2 cm, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                                </dl>
                                            </b-tooltip>
                                        </b-row>
                                        <b-row v-if="fdih_status.is_suhu_tanah || fdih_status.is_agm1b" class="m-auto">
                                            <div class="label-entry1" style="margin-top: 20px;">
                                            5 cm <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips5cm" /></div>
                                            <b-tooltip target="tooltips5cm" :triggers="'hover'" placement="top">
                                                <dl class="text-left">
                                                <dt>Berumput</dt>
                                                <dd>- Temperatur tanah area berumput pada kedalaman 5 cm, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                                <dt>Gundul</dt>
                                                <dd>- Temperatur tanah area gundul pada kedalaman 5 cm, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                                </dl>
                                            </b-tooltip>
                                        </b-row>

                                        <b-row v-if="fdih_status.is_suhu_tanah || fdih_status.is_agm1b" class="m-auto">
                                            <div class="label-entry1 mt-2">
                                            10 cm <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips14" /></div>
                                            <!-- HTML tooltips -->
                                            <b-tooltip target="tooltips14" :triggers="'hover'" placement="top">
                                                <dl class="text-left">
                                                <dt>Berumput</dt>
                                                <dd>- Temperatur tanah area berumput pada kedalaman 10 cm, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                                <dt>Gundul</dt>
                                                <dd>- Temperatur tanah area gundul pada kedalaman 10 cm, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                                </dl>
                                            </b-tooltip>
                                        </b-row>

                                         <b-row v-if="fdih_status.is_suhu_tanah || fdih_status.is_agm1b" class="m-auto">
                                            <div class="label-entry1" style="margin-top: 15px">
                                            20 cm <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips15" /></div>
                                            <!-- HTML tooltips -->
                                            <b-tooltip target="tooltips15" :triggers="'hover'" placement="top">
                                                <dl class="text-left">
                                                <dt>Berumput</dt>
                                                <dd>- Temperatur tanah area berumput pada kedalaman 20 cm, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                                <dt>Gundul</dt>
                                                <dd>- Temperatur tanah area gundul pada kedalaman 20 cm, dinyatakan dalam persepuluhan derajat Celsius.</dd>                                               
                                                </dl>
                                            </b-tooltip>
                                        </b-row>

                                         <b-row v-if="fdih_status.is_suhu_tanah || fdih_status.is_agm1b" class="m-auto">
                                            <div class="label-entry1 mt-2">
                                            50 cm <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips16" /></div>
                                            <!-- HTML tooltips -->
                                            <b-tooltip target="tooltips16" :triggers="'hover'" placement="top">
                                                <dl class="text-left">
                                                <dt>Berumput</dt>
                                                <dd>- Temperatur tanah area berumput pada kedalaman 50 cm, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                                <dt>Gundul</dt>
                                                <dd>- Temperatur tanah area gundul pada kedalaman 50 cm, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                                
                                                </dl>
                                            </b-tooltip>
                                        </b-row>

                                         <b-row v-if="fdih_status.is_suhu_tanah || fdih_status.is_agm1b" class="m-auto">
                                            <div class="label-entry1 mt-2">
                                            100 cm <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips17" /></div>
                                            <!-- HTML tooltips -->
                                            <b-tooltip target="tooltips17" :triggers="'hover'" placement="top">
                                                <dl class="text-left">
                                                <dt>Berumput</dt>
                                                <dd>- Temperatur tanah area berumput pada kedalaman 100 cm, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                                <dt>Gundul</dt>
                                                <dd>- Temperatur tanah area gundul pada kedalaman 100 cm, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                                
                                                </dl>
                                            </b-tooltip>
                                        </b-row>
                                    </b-col>
                                    <!-- baris 2 -->
                                    <b-col cols="4" class="m-auto">
                                        <b-row v-if="fdih_status.is_suhu_tanah " class="m-auto">
                                            <b-form-input v-on:keydown="onKeyCheck($event)" :formatter="format_one_decimal"  size="sm" class="text-center mb-1" id="input_ttr_0cm_0730"
                                                v-on:keyup="checkValidate($event, 'ttr_0cm_0730')"
                                                v-bind:class="{ rangecheck: rc_ttr_0cm_0730 }" :state="validateState($v.ttr_0cm_0730)" v-model.number="$v.ttr_0cm_0730.$model">
                                            </b-form-input>
                                            <b-tooltip v-if="rc_ttr_0cm_0730" target="input_ttr_0cm_0730" triggers="hover" placement="topleft">{{ rc_ttr_0cm_0730_message }}</b-tooltip>

                                            <b-tooltip v-if="!rc_ttr_0cm_0730" :show="validateTooltipState('ttr_0cm_0730')" :triggers="'hover'" target="input_ttr_0cm_0730" placement="topleft" custom-class="validation-tooltip">
                                              {{ tooltipMessage("ttr_0cm_0730") }}
                                            </b-tooltip>
                                        </b-row>
                                        <b-row v-if="fdih_status.is_suhu_tanah " class="m-auto">
                                            <b-form-input v-on:keydown="onKeyCheck($event)" :formatter="format_one_decimal"  size="sm" class="text-center mb-1" id="input_ttr_2cm_0730"
                                                    v-on:keyup="checkValidate($event, 'ttr_2cm_0730')"
                                                    v-bind:class="{ rangecheck: rc_ttr_2cm_0730 }" :state="validateState($v.ttr_2cm_0730)" v-model.number="$v.ttr_2cm_0730.$model">
                                                </b-form-input>
                                                <b-tooltip v-if="rc_ttr_2cm_0730" target="input_ttr_2cm_0730" triggers="hover" placement="topleft">{{ rc_ttr_2cm_0730_message }}</b-tooltip>
                                                <b-tooltip v-if="!rc_ttr_2cm_0730" :show="validateTooltipState('ttr_2cm_0730')" :triggers="'hover'" target="input_ttr_2cm_0730" placement="topleft" custom-class="validation-tooltip">
                                                {{ tooltipMessage("ttr_2cm_0730") }}
                                            </b-tooltip>
                                        </b-row>
                                        <b-row v-if="fdih_status.is_suhu_tanah || fdih_status.is_agm1b" class="m-auto">
                                            <b-form-input v-on:keydown="onKeyCheck($event)" :formatter="format_one_decimal"  size="sm" class="text-center mb-1" id="input_ttr_5cm_0730"
                                             v-on:keyup="checkValidate($event, 'ttr_5cm_0730')"
                                             v-bind:class="{ rangecheck: rc_ttr_5cm_0730 }" :state="validateState($v.ttr_5cm_0730)" v-model.number="$v.ttr_5cm_0730.$model">
                                            </b-form-input>
                                            <b-tooltip v-if="rc_ttr_5cm_0730" target="input_ttr_5cm_0730" triggers="hover" placement="topleft">{{ rc_ttr_5cm_0730_message }}</b-tooltip>
                                            <b-tooltip v-if="!rc_ttr_5cm_0730" :show="validateTooltipState('ttr_5cm_0730')" :triggers="'hover'" target="input_ttr_5cm_0730" placement="topleft" custom-class="validation-tooltip">
                                                {{ tooltipMessage("ttr_5cm_0730") }}
                                            </b-tooltip>
                                        </b-row>
                                        
                                        <b-row v-if="fdih_status.is_suhu_tanah || fdih_status.is_agm1b" class="m-auto">
                                            <b-form-input v-on:keydown="onKeyCheck($event)" :formatter="format_one_decimal"  size="sm" class="text-center mb-1" id="input_ttr_10cm_0730"
                                            v-on:keyup="checkValidate($event, 'ttr_10cm_0730')"
                                            v-bind:class="{ rangecheck: rc_ttr_10cm_0730 }" :state="validateState($v.ttr_10cm_0730)" v-model.number="$v.ttr_10cm_0730.$model">
                                            </b-form-input>
                                            <b-tooltip v-if="rc_ttr_10cm_0730" target="input_ttr_10cm_0730" triggers="hover" placement="topleft">{{ rc_ttr_10cm_0730_message }}</b-tooltip>
                                            <b-tooltip v-if="!rc_ttr_10cm_0730" :show="validateTooltipState('ttr_10cm_0730')" :triggers="'hover'" target="input_ttr_10cm_0730" placement="topleft" custom-class="validation-tooltip">
                                                {{ tooltipMessage("ttr_10cm_0730") }}
                                            </b-tooltip>
                                        </b-row>

                                        <b-row v-if="fdih_status.is_suhu_tanah || fdih_status.is_agm1b" class="m-auto">
                                            <b-form-input v-on:keydown="onKeyCheck($event)" :formatter="format_one_decimal"  size="sm" class="text-center mb-1" id="input_ttr_20cm_0730"
                                            v-on:keyup="checkValidate($event, 'ttr_20cm_0730')"
                                            v-bind:class="{ rangecheck: rc_ttr_20cm_0730 }" :state="validateState($v.ttr_20cm_0730)" v-model.number="$v.ttr_20cm_0730.$model">
                                            </b-form-input>
                                            <b-tooltip v-if="rc_ttr_20cm_0730" target="input_ttr_20cm_0730" triggers="hover" placement="topleft">{{ rc_ttr_20cm_0730_message }}</b-tooltip>
                                            <b-tooltip v-if="!rc_ttr_20cm_0730" :show="validateTooltipState('ttr_20cm_0730')" :triggers="'hover'" target="input_ttr_20cm_0730" placement="topleft" custom-class="validation-tooltip">
                                            {{ tooltipMessage("ttr_20cm_0730") }}
                                            </b-tooltip>
                                        </b-row>

                                        <b-row v-if="fdih_status.is_suhu_tanah || fdih_status.is_agm1b" class="m-auto">
                                            <b-form-input v-on:keydown="onKeyCheck($event)" :formatter="format_one_decimal"  size="sm" class="text-center mb-1" id="input_ttr_50cm_0730"
                                            v-on:keyup="checkValidate($event, 'ttr_50cm_0730')"
                                            v-bind:class="{ rangecheck: rc_ttr_50cm_0730 }" :state="validateState($v.ttr_50cm_0730)" v-model.number="$v.ttr_50cm_0730.$model">
                                            </b-form-input>
                                            <b-tooltip v-if="rc_ttr_50cm_0730" target="input_ttr_50cm_0730" triggers="hover" placement="topleft">{{ rc_ttr_50cm_0730_message }}</b-tooltip>
                                            <b-tooltip v-if="!rc_ttr_50cm_0730" :show="validateTooltipState('ttr_50cm_0730')" :triggers="'hover'" target="input_ttr_50cm_0730" placement="topleft" custom-class="validation-tooltip">
                                            {{ tooltipMessage("ttr_50cm_0730") }}
                                            </b-tooltip>
                                        </b-row>

                                        <b-row v-if="fdih_status.is_suhu_tanah || fdih_status.is_agm1b" class="m-auto">
                                            <b-form-input v-on:keydown="onKeyCheck($event)" :formatter="format_one_decimal"  size="sm" class="text-center mb-1" id="input_ttr_100cm_0730"
                                            v-on:keyup="checkValidate($event, 'ttr_100cm_0730')"
                                            v-bind:class="{ rangecheck: rc_ttr_100cm_0730 }" :state="validateState($v.ttr_100cm_0730)" v-model.number="$v.ttr_100cm_0730.$model">
                                            </b-form-input>
                                            <b-tooltip v-if="rc_ttr_100cm_0730" target="input_ttr_100cm_0730" triggers="hover" placement="topleft">{{ rc_ttr_100cm_0730_message }}</b-tooltip>
                                            <b-tooltip v-if="!rc_ttr_100cm_0730" :show="validateTooltipState('ttr_100cm_0730')" :triggers="'hover'" target="input_ttr_100cm_0730" placement="topleft" custom-class="validation-tooltip">
                                            {{ tooltipMessage("ttr_100cm_0730") }}
                                            </b-tooltip>
                                        </b-row>
                                    </b-col>
                                    <!-- Baris 3 -->
                                    <b-col cols="4">
                                        <b-row v-if="fdih_status.is_suhu_tanah" class="m-auto">
                                            <b-form-input v-on:keydown="onKeyCheck($event)" :formatter="format_one_decimal"  size="sm" class="text-center mb-1" id="input_ttg_0cm_0730"
                                                v-on:keyup="checkValidate($event, 'ttg_0cm_0730')"
                                                v-bind:class="{ rangecheck: rc_ttg_0cm_0730 }" :state="validateState($v.ttg_0cm_0730)" v-model.number="$v.ttg_0cm_0730.$model">
                                            </b-form-input>
                                            <b-tooltip v-if="rc_ttg_0cm_0730" target="input_ttg_0cm_0730" triggers="hover" placement="topleft">{{ rc_ttg_0cm_0730_message }}</b-tooltip>

                                            <b-tooltip v-if="!rc_ttr_0cm_0730" :show="validateTooltipState('ttg_0cm_0730')" :triggers="'hover'" target="input_ttg_0cm_0730" placement="topleft" custom-class="validation-tooltip">
                                              {{ tooltipMessage("ttg_0cm_0730") }}
                                            </b-tooltip>
                                        </b-row>

                                        <b-row v-if="fdih_status.is_suhu_tanah" class="m-auto">
                                            <b-form-input v-on:keydown="onKeyCheck($event)" :formatter="format_one_decimal"  size="sm" class="text-center mb-1" id="input_ttg_2cm_0730"
                                            v-on:keyup="checkValidate($event, 'ttg_2cm_0730')"
                                            v-bind:class="{ rangecheck: rc_ttg_2cm_0730 }" :state="validateState($v.ttg_2cm_0730)" v-model.number="$v.ttg_2cm_0730.$model">
                                            </b-form-input>
                                            <b-tooltip v-if="rc_ttg_2cm_0730" target="input_ttg_2cm_0730" triggers="hover" placement="topleft">{{ rc_ttg_2cm_0730_message }}</b-tooltip>

                                         <b-tooltip v-if="!rc_ttr_2cm_0730" :show="validateTooltipState('ttg_2cm_0730')" :triggers="'hover'" target="input_ttg_2cm_0730" placement="topleft" custom-class="validation-tooltip">
                                            {{ tooltipMessage("ttg_2cm_0730") }}
                                            </b-tooltip>
                                        </b-row>

                                        <b-row v-if="fdih_status.is_suhu_tanah || fdih_status.is_agm1b" class="m-auto">
                                            <b-form-input v-on:keydown="onKeyCheck($event)" :formatter="format_one_decimal"  size="sm" class="text-center mb-1" id="input_ttg_5cm_0730"
                                            v-on:keyup="checkValidate($event, 'ttg_5cm_0730')"
                                            v-bind:class="{ rangecheck: rc_ttg_5cm_0730 }" :state="validateState($v.ttg_5cm_0730)" v-model.number="$v.ttg_5cm_0730.$model">
                                            </b-form-input>
                                            <b-tooltip v-if="rc_ttg_5cm_0730" target="input_ttg_5cm_0730" triggers="hover" placement="topleft">{{ rc_ttg_5cm_0730_message }}</b-tooltip>
                                            <b-tooltip v-if="!rc_ttr_5cm_0730" :show="validateTooltipState('ttg_5cm_0730')" :triggers="'hover'" target="input_ttg_5cm_0730" placement="topleft" custom-class="validation-tooltip">
                                                {{ tooltipMessage("ttg_5cm_0730") }}
                                            </b-tooltip>
                                        </b-row>

                                        <b-row v-if="fdih_status.is_suhu_tanah || fdih_status.is_agm1b" class="m-auto">
                                            <b-form-input v-on:keydown="onKeyCheck($event)" :formatter="format_one_decimal"  size="sm" class="text-center mb-1" id="input_ttg_10cm_0730"
                                            v-on:keyup="checkValidate($event, 'ttg_10cm_0730')"
                                            v-bind:class="{ rangecheck: rc_ttg_10cm_0730 }" :state="validateState($v.ttg_10cm_0730)" v-model.number="$v.ttg_10cm_0730.$model">
                                            </b-form-input>
                                            <b-tooltip v-if="rc_ttg_10cm_0730" target="input_ttg_10cm_0730" triggers="hover" placement="topleft">{{ rc_ttg_10cm_0730_message }}</b-tooltip>
                                            <b-tooltip v-if="!rc_ttr_10cm_0730" :show="validateTooltipState('ttg_10cm_0730')" :triggers="'hover'" target="input_ttg_10cm_0730" placement="topleft" custom-class="validation-tooltip">
                                            {{ tooltipMessage("ttg_10cm_0730") }}
                                            </b-tooltip>
                                        </b-row>

                                        <b-row v-if="fdih_status.is_suhu_tanah || fdih_status.is_agm1b" class="m-auto">
                                            <b-form-input v-on:keydown="onKeyCheck($event)" :formatter="format_one_decimal"  size="sm" class="text-center mb-1" id="input_ttg_20cm_0730"
                                            v-on:keyup="checkValidate($event, 'ttg_20cm_0730')"
                                            v-bind:class="{ rangecheck: rc_ttg_20cm_0730 }" :state="validateState($v.ttg_20cm_0730)" v-model.number="$v.ttg_20cm_0730.$model">
                                        </b-form-input>
                                        <b-tooltip v-if="rc_ttg_20cm_0730" target="input_ttg_20cm_0730" triggers="hover" placement="topleft">{{ rc_ttg_20cm_0730_message }}</b-tooltip>
                                        <b-tooltip v-if="!rc_ttr_20cm_0730" :show="validateTooltipState('ttg_20cm_0730')" :triggers="'hover'" target="input_ttg_20cm_0730" placement="topleft" custom-class="validation-tooltip">
                                             {{ tooltipMessage("ttg_20cm_0730") }}
                                        </b-tooltip>
                                        </b-row>

                                        <b-row v-if="fdih_status.is_suhu_tanah || fdih_status.is_agm1b" class="m-auto">
                                            <b-form-input v-on:keydown="onKeyCheck($event)" :formatter="format_one_decimal"  size="sm" class="text-center mb-1" id="input_ttg_50cm_0730"
                                            v-on:keyup="checkValidate($event, 'ttg_50cm_0730')"
                                            v-bind:class="{ rangecheck: rc_ttg_50cm_0730 }" :state="validateState($v.ttg_50cm_0730)" v-model.number="$v.ttg_50cm_0730.$model">
                                            </b-form-input>
                                            <b-tooltip v-if="rc_ttg_50cm_0730" target="input_ttg_50cm_0730" triggers="hover" placement="topleft">{{ rc_ttg_50cm_0730_message }}</b-tooltip>
                                            <b-tooltip v-if="!rc_ttr_50cm_0730" :show="validateTooltipState('ttg_50cm_0730')" :triggers="'hover'" target="input_ttg_50cm_0730" placement="topleft" custom-class="validation-tooltip">
                                                {{ tooltipMessage("ttg_50cm_0730") }}
                                            </b-tooltip>
                                        </b-row>
                                        <b-row v-if="fdih_status.is_suhu_tanah || fdih_status.is_agm1b" class="m-auto">
                                            <b-form-input v-on:keydown="onKeyCheck($event)" :formatter="format_one_decimal"  size="sm" class="text-center mb-1" id="input_ttg_100cm_0730"
                                            v-on:keyup="checkValidate($event, 'ttg_100cm_0730')"
                                            v-bind:class="{ rangecheck: rc_ttg_100cm_0730 }" :state="validateState($v.ttg_100cm_0730)" v-model.number="$v.ttg_100cm_0730.$model">
                                            </b-form-input>
                                            <b-tooltip v-if="rc_ttg_100cm_0730" target="input_ttg_100cm_0730" triggers="hover" placement="topleft">{{ rc_ttg_100cm_0730_message }}</b-tooltip>
                                            <b-tooltip v-if="!rc_ttr_100cm_0730" :show="validateTooltipState('ttg_100cm_0730')" :triggers="'hover'" target="input_ttg_100cm_0730" placement="topleft" custom-class="validation-tooltip">
                                                {{ tooltipMessage("ttg_100cm_0730") }}
                                            </b-tooltip>
                                        </b-row>
                                    </b-col>
                                </b-row>

                                <!-- <b-row v-if="fdih_status.is_suhu_tanah" class="my-1">
                                    <b-col cols="4" class="m-auto">
                                        
                                    </b-col>
                                    <b-col cols="4">
                                        
                                    </b-col>
                                    <b-col cols="4">
                                        
                                    </b-col>
                                </b-row> -->

                                <!-- <b-row v-if="fdih_status.is_suhu_tanah || fdih_status.is_agm1b" class="my-1">
                                    <b-col cols="4" class="m-auto">
                                        
                                    </b-col>
                                    <b-col cols="4">
                                        
                                    </b-col>
                                    <b-col cols="4">
                                        
                                    </b-col>
                                </b-row> -->

                                <!-- <b-row v-if="fdih_status.is_suhu_tanah || fdih_status.is_agm1b" class="my-1">
                                    <b-col cols="4" class="m-auto">
                                        
                                    </b-col>
                                    <b-col cols="4">
                                        
                                    </b-col>
                                    <b-col cols="4">
                                        
                                    </b-col>
                                </b-row> -->

                                <!-- <b-row v-if="fdih_status.is_suhu_tanah || fdih_status.is_agm1b" class="my-1">
                                    <b-col cols="4" class="m-auto">
                                        
                                    </b-col>
                                    <b-col cols="4">
                                        
                                    </b-col>
                                    <b-col cols="4">
                                        
                                    </b-col>
                                </b-row> -->

                                <!-- <b-row v-if="fdih_status.is_suhu_tanah" class="my-1">
                                    <b-col cols="4" class="m-auto">
                                        
                                    </b-col>
                                    <b-col cols="4">
                                        
                                    </b-col>
                                    <b-col cols="4">
                                        
                                    </b-col>
                                </b-row> -->

                                <!-- <b-row v-if="fdih_status.is_suhu_tanah" class="my-1">
                                    <b-col cols="4" class="m-auto">
                                        
                                    </b-col>
                                    <b-col cols="4">
                                        
                                    </b-col>
                                    <b-col cols="4">
                                        
                                    </b-col>
                                </b-row> -->
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <!-- SUHU TANAH end -->
            </b-row>

        <!-- button Action-->
            <b-row>
                <b-col cols="12">
                    <b-card>
                    <b-row class="float-right">
                        
                        <div>
                            <b-button @click.prevent="cancelForm" variant="danger" class="mr-2" size="sm">Cancel</b-button>
                            <!-- <b-button v-if="this.t_data_status == 'update'" @click.prevent="validationForm" variant="warning" class="mr-2" size="sm" :disabled="!roles.isRoleEdit">Edit</b-button>
                            <b-button v-if="this.t_data_status == 'new'" @click.prevent="validationForm" variant="primary" class="mr-2" size="sm" :disabled="!roles.isRoleAdd">Submit</b-button> -->
                            <b-button  @click.prevent="validationForm" variant="primary" class="mr-2" size="sm" >Submit</b-button>
                        </div>
                        
                    </b-row>
                    </b-card>
                </b-col>
            </b-row>

        </b-form>


        <b-modal
			id="modal-op-kuras-0730"
			ref="modal-op-kuras-0730"
			title="OP KURAS/RESET"
			button-size="sm"
			size="md"
			centered
			no-close-on-backdrop
		>
			<b-row class="mb-1">
                <b-col cols="6">
                    <div>
                        <b-form-select v-model="op_kuras_time" class=" col-12" size="sm" :disabled="isPengurasan">
                                <!-- <option :value="option">Pilih Jam</option> -->
                                <option value="0730ws">07.30 WS</option>
                                <option value="antara">Jam Antara</option> 
                            </b-form-select> 
                            <feather-icon
                                icon="HelpCircleIcon"
                                size="12"
                                class="bg-primary rounded-circle text-white absolute "
                                v-b-tooltip.hover.v-dark
                                title=""
                                style="z-index:100000000 !important; "
                                />
                    </div>
                </b-col>
                <b-col cols="6">
                    <b-row v-if="op_kuras_time == 'antara'" >
                        <b-col cols="5">
                            <div class="label-entry1">
                                Isi Jam
                                <feather-icon
                                    icon="HelpCircleIcon"
                                    size="12"
                                    class="bg-primary rounded-circle text-white absolute "
                                    v-b-tooltip.hover.v-dark
                                    title="Silahkan diisi dengan Jam Pengurasan/Reset"
                                    style="z-index:100000000 !important; "
                                />
                            </div>
                        </b-col>
                        <b-col cols="7">
                            <b-form-input
                                id="jam"
                                v-model="time_kuras_0730"
                                type="text"
                                size="sm"
                                class="text-center"
                            ></b-form-input>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>

            <b-row class="mb-1" v-if="op_kuras_time == 'antara'">
                <b-col cols="8">
                    <div class="label-entry1">
                        Tinggi Air Sebelum Kuras/Reset
                        <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Nilai pembacaan ketinggian air pada bejana tepat sebelum dilakukan pengurasan/reset diantara jam 17.30 WS kemarin  s.d 07.30 WS hari ini, dinyatakan dalam milimeter"/>
                    </div>
                </b-col>
                <b-col cols="4">
                     <b-form-input
                        id="pic"
                        :formatter="format_one_decimal"
                        v-model.number="h_air_sebelum_kuras_0730"
                        
                        size="sm"
                        class="text-center"
                    ></b-form-input>
                </b-col>
            </b-row>
            <b-row class="mb-1">
                <b-col cols="8">
                    <div class="label-entry1">
                        Tinggi Air Setelah Kuras/Reset
                        <feather-icon v-if="op_kuras_time != 'antara'" icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" 
                            v-b-tooltip.hover.v-dark title="Nilai pembacaan ketinggian air pada bejana  tepat setalah dilakukan pengurasan/reset, digunakan sebagai Tinggi Air Sebelum pada jam 13.30 WS, dinyatakan dalam milimeter"/>
                        <feather-icon v-if="op_kuras_time == 'antara'" icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" 
                            v-b-tooltip.hover.v-dark title="Nilai pembacaan ketinggian air pada bejana tepat setelah dilakukan pengurasan/reset diantara jam 17.30 WS kemarin  s.d 07.30 WS hari ini, dinyatakan dalam milimeter"/>
                    </div>
                </b-col>
                <b-col cols="4">
                    <b-form-input
                        id="pic"
                        :formatter="format_one_decimal"
                        v-model.number="h_air_setelah_kuras_0730"
                        
                        size="sm"
                        class="text-center"
                    ></b-form-input>
                </b-col>
            </b-row>
            <b-row class="mb-1" v-if="op_kuras_time == 'antara'">
                <b-col cols="8">
                    <div class="label-entry1">
                        Selisih Tinggi Sebelum Kuras/Reset
                        <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltipsSTSBK" />
                    </div>
                    <b-tooltip target="tooltipsSTSBK" placement="top">
                        <dl class="text-left">
                            <dt>Beda pembacaan tinggi air pada periode 17.30 WS hari sebelumnya s.d jam pengurasan/reset  yang diperoleh dari selisih (Tinggi Air Sebelum dan Tinggi Air Sebelum Kuras), dinyatakan dalam milimeter</dt>
                            <dd>Contoh 1:</dd>
                            <dd>- Tinggi Air Sebelum (jam 17.30 WS) = 10 mm</dd>
                            <dd>- Pengurasan jam 05.00 WS karena hujan lebat</dd>
                            <dd>- Tinggi Air Sebelum Kuras = 15 mm</dd>
                            <dd>- Selisih Tinggi Sebelum Kuras = 10 mm - 15 mm = -5 mm</dd>
                            <dd> </dd>
                            <dd>Contoh 2:</dd>
                            <dd>- Tinggi Air Sebelum (jam 17.30 WS) = 10 mm</dd>
                            <dd>- Penambahan air jam 05.00 WS karena penguapan tinggi</dd>
                            <dd>- Tinggi Air Sebelum Kuras = 6 mm</dd>
                            <dd>- Selisih Tinggi Sebelum Kuras = 10 mm - 6 mm = 4 mm</dd>
                        </dl>
                    </b-tooltip>
                </b-col>
                <b-col cols="4">
                    <b-form-input
                        id="pic"
                        :formatter="format_one_decimal"
                        v-model.number="h_air_diff_sebelum_kuras_0730"
                        
                        size="sm"
                        class="bg-light-success text-center"
                        readonly
                    ></b-form-input>
                </b-col>
            </b-row>
            <b-row class="mb-1" v-if="op_kuras_time == 'antara'">
                <b-col cols="8">
                    <div class="label-entry1">
                        Selisih Tinggi Setelah Kuras/Reset
                        <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltipsSTSTK" />
                    </div>
                    <b-tooltip target="tooltipsSTSTK" placement="top">
                        <dl class="text-left">
                            <dt>Beda pembacaan tinggi air pada periode jam pengurasan/reset s.d jam 07.30 WS yang diperoleh dari selisih (Tinggi Air Setelah Kuras dan Tinggi Air dibaca), dinyatakan dalam milimeter</dt>
                            <dd>Contoh 1:</dd>
                            <dd>- Pengurasan jam 05.00 WS karena hujan lebat</dd>
                            <dd>- Tinggi Air Setelah Kuras = 9 mm</dd>
                            <dd>- Tinggi Air dibaca (jam 07.30 WS) = 7 mm</dd>
                            <dd>- Selisih Tinggi Setelah Kuras = 9 mm - 7 mm = 2 mm</dd>
                            <dd> </dd>
                            <dd>Contoh 2:</dd>
                            <dd>- Penambahan air jam 05.00 WS karena penguapan tinggi</dd>
                            <dd>- Tinggi Air Setelah Reset= 12 mm</dd>
                            <dd>- Tinggi Air dibaca (jam 07.30 WS) = 10 mm</dd>
                            <dd>- Selisih Tinggi Setelah Kuras = 12 mm - 10 mm = 2 mm</dd>
                        </dl>
                    </b-tooltip>
                </b-col>
                <b-col cols="4">
                   <b-form-input
                        id="pic"
                        :formatter="format_one_decimal"
                        v-model.number="h_air_diff_setelah_kuras_0730"
                        
                        size="sm"
                        class="bg-light-success text-center"
                        readonly
                    ></b-form-input>
                </b-col>
            </b-row>			

			<template #modal-footer>
                <b-button size="sm" variant="gradient-danger" @click="onReset()">
				Reset
				</b-button>
				<b-button size="sm" @click="onCancel">
				Cancel
				</b-button>
				<b-button
					size="sm"
					variant="gradient-primary"
					@click="onOk"
				>
				OK
				</b-button>
			</template>
			
		</b-modal>

    <!-- <b-popover
      ref="popover"
      variant="secondary"
      target="popover-reactive-1"
      triggers="click"
      :show.sync="popoverShow"
      placement="auto"
      container="my-container"
      style="font-size: 0.90rem;"
    >
      <template v-slot:title>
        <div class="d-flex justify-content-between align-items-center">
          <span>OP KURAS</span>
          <b-button
            class="close"
            variant="white"
            aria-label="Close"
            @click="onClosePopup1"
          >
            <span
              class="d-inline-block text-white"
              aria-hidden="true"
            >&times;</span>
          </b-button>
        </div>
      </template>

        <div>
            <b-row class="mb-2">
                <b-col cols="6">
                    <b-form-group>
                    <b-form-select v-model="op_kuras_time" class=" col-12" size="sm">
                                <option value="0730ws">07.30 WS</option>
                                <option value="antara">Jam Antara</option> 
                            </b-form-select> <feather-icon
                          icon="HelpCircleIcon"
                          size="12"
                          class="bg-primary rounded-circle text-white absolute "
                          v-b-tooltip.hover.v-dark
                          title="Silahkan diisi dengan Jam Pengurasan."
                          style="z-index:100000000 !important; "
                        />
                    </b-form-group>
                    
                </b-col>
                <b-col cols="6">
                    <b-form-group v-if="op_kuras_time == 'antara'"
                                label-cols="4"
                                label-cols-lg="6"
                                label-size="sm"
                                label="Isi Jam"
                                label-for="select-wmo"
                                size="sm"
                            >
                                <b-form-input
                                id="pic"
                                v-model="time_kuras_0730"
                                type="text"
                                size="sm"
                                class="text-center"
                                ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>

            
            <b-form-group v-if="op_kuras_time == 'antara'"
                        label-cols="12"
                        label-cols-lg="8"
                        label-size="sm"
                        label="Tinggi Air Sebelum Kuras"
                        label-for="select-wmo"
                        size="sm"
                    >
                        <b-form-input
                            id="pic"
                            :formatter="format_one_decimal"
                            v-model="h_air_sebelum_kuras_0730"
                            type="text"
                            size="sm"
                            class="text-center"
                        ></b-form-input>
            </b-form-group>

            <b-form-group 
                        label-cols="12"
                        label-cols-lg="8"
                        label-size="sm"
                        label="Tinggi Air Setelah Kuras"
                        label-for="select-wmo"
                        size="sm"
                    >
                        <b-form-input
                        id="pic"
                        :formatter="format_one_decimal"
                        v-model="h_air_setelah_kuras_0730"
                        type="text"
                        size="sm"
                        class="text-center"
                        ></b-form-input>
            </b-form-group>
            <b-form-group v-if="op_kuras_time == 'antara'"
                        label-cols="12"
                        label-cols-lg="8"
                        label-size="sm"
                        label="Selisih Tinggi Sebelum Kuras"
                        label-for="select-wmo"
                        size="sm"
                    >
                        <b-form-input
                        id="pic"
                        :formatter="format_one_decimal"
                        v-model="h_air_diff_sebelum_kuras_0730"
                        type="text"
                        size="sm"
                        class="bg-light-success text-center"
                        readonly
                        ></b-form-input>
            </b-form-group>
            <b-form-group v-if="op_kuras_time == 'antara'"
                        label-cols="12"
                        label-cols-lg="8"
                        label-size="sm"
                        label="Selisih Tinggi Setelah Kuras"
                        label-for="select-wmo"
                        size="sm"
                    >
                        <b-form-input
                        id="pic"
                        :formatter="format_one_decimal"
                        v-model="h_air_diff_setelah_kuras_0730"
                        type="text"
                        size="sm"
                        class="bg-light-success text-center"
                        readonly
                        ></b-form-input>
            </b-form-group>


            <b-button
            
            size="sm"
            variant="primary"
            @click="onOk"
            >
            Ok
            </b-button>
        
        </div>
    </b-popover> -->
    <!-- popover 2 -->
    <b-popover
      ref="popover2"
      variant="bg-light-secondary"
      target="popup-2"
      triggers="click"
      :show.sync="popoverShow2"
      placement="auto"
      container="my-container"
    >
      <template v-slot:title>
        <div class="d-flex justify-content-between align-items-center">
          <span>Tinggi Air</span>
          <b-button
            class="close"
            variant="white"
            aria-label="Close"
            @click="onClosePopup2"
          >
            <span
              class="d-inline-block text-white"
              aria-hidden="true"
            >&times;</span>
          </b-button>
        </div>
      </template>

      <div>
          
        <b-form-group 
                    label-cols="4"
                    label-cols-lg="9"
                    label-size="sm"
                    label="Tinggi Air Sesudah Tambah"
                    label-for="select-wmo"
                    size="sm"
                  >
                    <b-form-input
                      id="pic"
                      v-model="h_piche_ditambah_0730"
                      type="text"
                      size="sm"
                      class="text-center"
                      ></b-form-input>
        </b-form-group>
 
        
        <!-- if konidsi -->
       
            <!--  end -->


        <b-button
          
          size="sm"
          variant="primary"
          @click="onOk2"
        >
          Ok
        </b-button>
        
      </div>
    </b-popover>
    <!-- end popover2 -->
    <b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay>
  </div>
</template>
<!-- end tes pop -->
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import Service from "@/api/fdihservice";
import axios from 'axios'
import qc_helper from "@/api/qc_helper";
import Helper from "@/helper/helper";
// } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Params from "@/attr/metadata/params";
import RC_helper from "@/validators/RC_helper";
import { GC_FDIH_PS_0730_TBK, 
            GC_FDIH_PS_0730_TBB, 
            GC_FDIH_0730_ANGIN_COUNTER_BACA, 
            GC_FDIH_0730_ANGIN_KEC_PERAMATAN, 
            GC_FDIH_0730_OP_SELISIH, 
            GC_FDIH_0730_OP_T_AIR_MAX, 
            GC_FDIH_0730_OP_T_AIR_MIN, 
            GC_FDIH_0730_OP_CH_DIUKUR, 
            GC_FDIH_0730_SUHU_TANAH, 
            GC_FDIH_0730_50CM_SUHU_TANAH,
            GC_FDIH_0730_100CM_SUHU_TANAH,
            RC_FDIH_ROLES,
            RC_FDIH, 
            GC_FDIH,} from "@/validators/fdihvalidator";
import { fdih_op_penguapan_0730, fdih_counter_sebelum_0730, fdih_piche_0730 } from "./fdih_tooltip_content_0730";
import {
    BCard,
    VBTooltip,
    BCardHeader,
    BContainer,
    BRow,
    BCol,
    BFormSelect,
    BFormTextarea,
    BForm,
    BButton,
    BFormGroup,
    BFormDatepicker,
    BFormInput,
    BCollapse,
    VBToggle,
    VBPopover,
    BPopover, 
    BAlert,
    BIcon,
    BTooltip,
    BModal,
    BOverlay,
    BFormCheckbox
} from "bootstrap-vue";
export default {
    components: {
        BCardActions,
        BCard,
        BCardHeader,
        BContainer,
        BRow,
        BCol,
        BFormSelect,
        BFormTextarea,
        BForm,
        BButton,
        BFormGroup,
        BFormDatepicker,
        BFormInput,
        BCollapse,
        BPopover, 
        BAlert,
        BIcon,
        VBTooltip,
        BTooltip,
        BModal,
        BOverlay,
        BFormCheckbox
    },
    directives: {
		'b-toggle': VBToggle,
        'b-popover': VBPopover,
        'b-tooltip': VBTooltip,
		Ripple,
        
	},
    props :['data_input', 't_station', "t_station_id", 't_wmoid','t_observer', 't_time', 't_date', 't_data_existing', "t_all_data",
            "t_data_requirement", "t_path", "t_id_form", "t_data_status", "roles", "t_bmkgentry", 'd_station', 't_qc_histories'],     
    data() {
        return {
            showLoading: false,
            popoverShow: false,
            popoverShow2: false,
            RC_helper: {...RC_helper},
            RC_helper_agm1b: {...RC_helper},
            RC_helper_psassman: {...RC_helper},
            flagdata_agm1b: null,
            flagdata_psychrometer_assman: null,
            flagdata_suhu_tanah: null,
            keadaan_tanah: '',
            keadaan_hujan: '',      
            op_kuras_time: '0730ws',
            tbk_1c2m_0730: "",
            tbb_1c2m_0730: "",
            rh_1c2m_0730: "",
            counter_sebelum_0c5_0730: "",
            counter_0c5_0730: "",
            CC: {
                tbk_1c2m_0730: { status: false, message: "" },
                tbb_1c2m_0730: { status: false, message: "" },
                tbk_5cm_0730: { status: false, message: "" },
                tbk_10cm_0730: { status: false, message: "" },
                tbk_20cm_0730: { status: false, message: "" },
                tbk_50cm_0730: { status: false, message: "" },
                tbk_100cm_0730: { status: false, message: "" },
                tbk_150cm_0730: { status: false, message: "" },
                tbk_200cm_0730: { status: false, message: "" },
                tbb_5cm_0730: { status: false, message: "" },
                tbb_10cm_0730: { status: false, message: "" },
                tbb_20cm_0730: { status: false, message: "" },
                tbb_50cm_0730: { status: false, message: "" },
                tbb_100cm_0730: { status: false, message: "" },
                tbb_150cm_0730: { status: false, message: "" },
                tbb_200cm_0730: { status: false, message: "" },
                counter_0c5_0730:{ status: false, message: "" },
                t_air_max_0730: { status: false, message: "" },
                t_air_min_0730: { status: false, message: "" },
                h_piche_0730: {status: false, message: ""}
            },
            ws_avg_0c5_0730: "",
            ws_10_0730: "",
            op_diff_baca_0730: "",
            h_air_0730: "",
            h_air_diff_0730: "",
            time_kuras_0730: "",
            h_air_setelah_kuras_0730: "",
            h_air_sebelum_kuras_0730: "",
            h_air_diff_setelah_kuras_0730: "",
            h_air_diff_sebelum_kuras_0730: "",
            isPengurasan: false,
            op_kuras_time_temp: '0730ws',
            time_kuras_0730_temp: "",
            h_air_setelah_kuras_0730_temp: "",
            h_air_sebelum_kuras_0730_temp: "",
            h_air_diff_setelah_kuras_0730_temp: "",
            h_air_diff_sebelum_kuras_0730_temp: "",
            t_air_max_0730: "",
            t_air_min_0730: "",
            t_air_avg_0730: "",
            rr_0730: "",
            op_eva_0730: "",
            tbk_5cm_0730: "",
            tbk_10cm_0730: "",
            tbk_20cm_0730: "",
            tbk_50cm_0730: "",
            tbk_100cm_0730: "",
            tbk_150cm_0730: "",
            tbk_200cm_0730: "",
            tbb_5cm_0730: "",
            tbb_10cm_0730: "",
            tbb_20cm_0730: "",
            tbb_50cm_0730: "",
            tbb_100cm_0730: "",
            tbb_150cm_0730: "",
            tbb_200cm_0730: "",
            rh_5cm_0730: "",
            rh_10cm_0730: "",
            rh_20cm_0730: "",
            rh_50cm_0730: "",
            rh_100cm_0730: "",
            rh_150cm_0730: "",
            rh_200cm_0730: "",
            pitche_sebelum_0730: "",
            h_piche_0730: "",
            eva_piche_0730: "",
            h_piche_ditambah_0730: "",
            ket_tanah_0730: '',
            ket_hujan_0730: '',
            ket_1b_0730: '',
            ttg_0cm_0730: "",
            ttg_2cm_0730: "",
            ttg_5cm_0730: "",
            ttg_10cm_0730: "",
            ttg_20cm_0730: "",
            ttg_50cm_0730: "",
            ttg_100cm_0730: "",
            ttr_0cm_0730: "",
            ttr_2cm_0730: "",
            ttr_5cm_0730: "",
            ttr_10cm_0730: "",
            ttr_20cm_0730: "",
            ttr_100cm_0730: "",
            ttr_50cm_0730: "",
            opt_ket_tanah: [
                //{ value: "", text: 'Keadaan Tanah', disabled:true },
                { value: 'pecah_pecah', text: 'Pecah-pecah' },
                { value: 'kering', text: 'Kering' },
                { value: 'berdebu', text: 'Berdebu' },
                { value: 'basah', text: 'Basah' },
                { value: 'becek', text: 'Becek' },
                { value: 'tergenang_air', text: 'Tergenang Air' },
            ],
            opt_ket_hujan: [
                //{ value: "", text: 'Keadaan Hujan', disabled:true },
                { value: 'tidak ada', text: 'Tidak Ada' },
                { value: 'ringan', text: 'Ringan' },
                { value: 'sedang', text: 'Sedang' },
                { value: 'lebat', text: 'Lebat' },
            ],

            rc_op_diff_baca_0730: false,
            rc_op_diff_baca_0730_message: "",
            rc_t_air_max_0730: false,
            rc_t_air_max_0730_message: "",
            rc_t_air_min_0730: false,
            rc_t_air_min_0730_message: "",
            rc_rr_0730: false,
            rc_rr_0730_message: "",

            rc_ttr_0cm_0730: false,
            rc_ttr_0cm_0730_message:"",
            rc_ttr_2cm_0730: false,
            rc_ttr_2cm_0730_message:"",
            rc_ttr_5cm_0730: false,
            rc_ttr_5cm_0730_message:"",
            rc_ttr_10cm_0730: false,
            rc_ttr_10cm_0730_message:"",
            rc_ttr_20cm_0730: false,
            rc_ttr_20cm_0730_message:"",
            rc_ttr_50cm_0730: false,
            rc_ttr_50cm_0730_message:"",
            rc_ttr_100cm_0730: false,
            rc_ttr_100cm_0730_message:"",

            rc_ttg_0cm_0730: false,
            rc_ttg_0cm_0730_message:"",
            rc_ttg_2cm_0730: false,
            rc_ttg_2cm_0730_message:"",
            rc_ttg_5cm_0730: false,
            rc_ttg_5cm_0730_message:"",
            rc_ttg_10cm_0730: false,
            rc_ttg_10cm_0730_message:"",
            rc_ttg_20cm_0730: false,
            rc_ttg_20cm_0730_message:"",
            rc_ttg_50cm_0730: false,
            rc_ttg_50cm_0730_message:"",
            rc_ttg_100cm_0730: false,
            rc_ttg_100cm_0730_message:"",

            rc_tbk_5cm_0730: false,
            rc_tbk_5cm_0730_message:"",
            rc_tbk_10cm_0730: false,
            rc_tbk_10cm_0730_message:"",
            rc_tbk_20cm_0730: false,
            rc_tbk_20cm_0730_message:"",
            rc_tbk_50cm_0730: false,
            rc_tbk_50cm_0730_message:"",
            rc_tbk_100cm_0730: false,
            rc_tbk_100cm_0730_message:"",
            rc_tbk_150cm_0730: false,
            rc_tbk_150cm_0730_message:"",
            rc_tbk_200cm_0730: false,
            rc_tbk_200cm_0730_message:"",

            rc_tbb_5cm_0730: false,
            rc_tbb_5cm_0730_message:"",
            rc_tbb_10cm_0730: false,
            rc_tbb_10cm_0730_message:"",
            rc_tbb_20cm_0730: false,
            rc_tbb_20cm_0730_message:"",
            rc_tbb_50cm_0730: false,
            rc_tbb_50cm_0730_message:"",
            rc_tbb_100cm_0730: false,
            rc_tbb_100cm_0730_message:"",
            rc_tbb_150cm_0730: false,
            rc_tbb_150cm_0730_message:"",
            rc_tbb_200cm_0730: false,
            rc_tbb_200cm_0730_message:"",

            fdih_status: {
                is_fklim: true,
                is_iklim_mikro: true,
                is_agm1a: true,
                is_agm1b: true,
                is_suhu_tanah: true,
                is_psychrometer_assman: true,
                is_op_penguapan: true,
                is_piche_penguapan: true,
                is_lysimeter: true,
                is_gunbellani: true
            },

            is_counter_edit: false,

            fdih_counter_sebelum_0730,
            fdih_op_penguapan_0730,
            fdih_piche_0730,

            is_op_edit: false,
            is_piche_edit: false,

            DATA_RC: {},
            LIST_QC_FIX : {},
            LIST_QC_NOTIFICATION_FIX: {},

            agm1b_histories_last : null,
            suhutanah_histories_last : null
        }
    },
    mixins: [validationMixin],
    validations: {
        tbk_1c2m_0730: { required, GC_FDIH_PS_0730_TBK, consistency(val) {
            if (this.tbb_1c2m_0730 && this.tbb_1c2m_0730 != 9999 && val != 9999) {
                let result =  val >= this.tbb_1c2m_0730
                this.CC.tbk_1c2m_0730.message = "Suhu Bola Kering >= Suhu Bola Basah";
                this.CC.tbk_1c2m_0730.status = !result;
                return result;
            }
            this.CC.tbk_1c2m_0730.message = "";
            this.CC.tbk_1c2m_0730.status = false;
            return true
        }},
        tbb_1c2m_0730: { required, GC_FDIH_PS_0730_TBB, consistency(val) {
            if (this.tbk_1c2m_0730 && this.tbk_1c2m_0730 != 9999 && val != 9999) {
                let result =  val <= this.tbk_1c2m_0730
                this.CC.tbb_1c2m_0730.message = "Suhu Bola Kering >= Suhu Bola Basah";
                this.CC.tbb_1c2m_0730.status = !result;
                return result;
            }
            this.CC.tbb_1c2m_0730.message = "";
            this.CC.tbb_1c2m_0730.status = false;
            return true
        } },

        counter_0c5_0730: { 
            required, 
            GC_FDIH_0730_ANGIN_COUNTER_BACA, 
                consistency(val) {
                    if (this.counter_sebelum_0c5_0730 && this.counter_sebelum_0c5_0730 != 9999 && val != 9999) {
                        let result = val >= this.counter_sebelum_0c5_0730;
                        this.CC.counter_0c5_0730.message = "Counter Dibaca >= Counter Sebelum";
                        this.CC.counter_0c5_0730.status = !result;
                        return result;
                    }
                    this.CC.counter_0c5_0730.message = "";
                    this.CC.counter_0c5_0730.status = false;
                    return true;
                },
            },
        ws_10_0730: {required, GC_FDIH_0730_ANGIN_KEC_PERAMATAN},
        h_air_diff_0730: {required, GC_FDIH_0730_OP_SELISIH},
        t_air_max_0730: {required, GC_FDIH_0730_OP_T_AIR_MAX, consistency(val) {
            if (val != 9999 && this.t_air_min_0730 && this.t_air_min_0730 != 9999) {
                let result = val >= this.t_air_min_0730
                this.CC.t_air_max_0730.message = "Suhu Air Maksimum >= Suhu Air Minimum";
                this.CC.t_air_max_0730.status = !result;
                return result;
            }
            this.CC.t_air_max_0730.message = "";
            this.CC.t_air_max_0730.status = false;
            return true
        }},
        t_air_min_0730: {required, GC_FDIH_0730_OP_T_AIR_MIN, consistency(val) {
            if (val != 9999 && this.t_air_max_0730 && this.t_air_max_0730 != 9999) {
                let result = val <= this.t_air_max_0730
                this.CC.t_air_min_0730.message = "Suhu Air Maksimum >= Suhu Air Minimum";
                this.CC.t_air_min_0730.status = !result;
                return result;
            }
            this.CC.t_air_min_0730.message = "";
            this.CC.t_air_min_0730.status = false;
            return true
        }},
        rr_0730: {required, GC_FDIH_0730_OP_CH_DIUKUR},

        tbk_5cm_0730: { required, GC_FDIH_PS_0730_TBK, consistency(val) {
            if (this.tbb_5cm_0730 && this.tbb_5cm_0730 != 9999 && val != 9999) {
                let result = val >= this.tbb_5cm_0730
                this.CC.tbk_5cm_0730.message = "Suhu Bola Kering >= Suhu Bola Basah";
                this.CC.tbk_5cm_0730.status = !result;
                return result;
            }
            this.CC.tbk_5cm_0730.message = "";
            this.CC.tbk_5cm_0730.status = false;
            return true
        } },
        tbk_10cm_0730: { required, GC_FDIH_PS_0730_TBK, consistency(val) {
            if (this.tbb_10cm_0730 && this.tbb_10cm_0730 != 9999 && val != 9999) {
                let result = val >= this.tbb_10cm_0730
                this.CC.tbk_10cm_0730.message = "Suhu Bola Kering >= Suhu Bola Basah";
                this.CC.tbk_10cm_0730.status = !result;
                return result;
            }
            this.CC.tbk_10cm_0730.message = "";
            this.CC.tbk_10cm_0730.status = false;
            return true
        } },
        tbk_20cm_0730: { required, GC_FDIH_PS_0730_TBK, consistency(val) {
            if (this.tbb_20cm_0730 && this.tbb_20cm_0730 != 9999 && val != 9999) {
                let result = val >= this.tbb_20cm_0730
                this.CC.tbk_20cm_0730.message = "Suhu Bola Kering >= Suhu Bola Basah";
                this.CC.tbk_20cm_0730.status = !result;
                return result;
            }
            this.CC.tbk_20cm_0730.message = "";
            this.CC.tbk_20cm_0730.status = false;
            return true
        } },
        tbk_50cm_0730: { required, GC_FDIH_PS_0730_TBK, consistency(val) {
            if (this.tbb_50cm_0730 && this.tbb_50cm_0730 != 9999 && val != 9999) {
                let result = val >= this.tbb_50cm_0730
                this.CC.tbk_50cm_0730.message = "Suhu Bola Kering >= Suhu Bola Basah";
                this.CC.tbk_50cm_0730.status = !result;
                return result;
            }
            this.CC.tbk_50cm_0730.message = "";
            this.CC.tbk_50cm_0730.status = false;
            return true
        } },
        tbk_100cm_0730: { required, GC_FDIH_PS_0730_TBK, consistency(val) {
            if (this.tbb_100cm_0730 && this.tbb_100cm_0730 != 9999 && val != 9999) {
                let result = val >= this.tbb_100cm_0730
                this.CC.tbk_100cm_0730.message = "Suhu Bola Kering >= Suhu Bola Basah";
                this.CC.tbk_100cm_0730.status = !result;
                return result;
            }
            this.CC.tbk_100cm_0730.message = "";
            this.CC.tbk_100cm_0730.status = false;
            return true
        } },
        tbk_150cm_0730: { required, GC_FDIH_PS_0730_TBK, consistency(val) {
            if (this.tbb_150cm_0730 && this.tbb_150cm_0730 != 9999 && val != 9999) {
                let result = val >= this.tbb_150cm_0730
                this.CC.tbk_150cm_0730.message = "Suhu Bola Kering >= Suhu Bola Basah";
                this.CC.tbk_150cm_0730.status = !result;
                return result;
            }
            this.CC.tbk_150cm_0730.message = "";
            this.CC.tbk_150cm_0730.status = false;
            return true
        } },
        tbk_200cm_0730: { required, GC_FDIH_PS_0730_TBK, consistency(val) {
            if (this.tbb_200cm_0730 && this.tbb_200cm_0730 != 9999 && val != 9999) {
                let result = val >= this.tbb_200cm_0730
                this.CC.tbk_200cm_0730.message = "Suhu Bola Kering >= Suhu Bola Basah";
                this.CC.tbk_200cm_0730.status = !result;
                return result;
            }
            this.CC.tbk_200cm_0730.message = "";
            this.CC.tbk_200cm_0730.status = false;
            return true
        } },

        tbb_5cm_0730: { required, GC_FDIH_PS_0730_TBB, consistency(val) {
            if (this.tbk_5cm_0730 && this.tbk_5cm_0730 != 9999 && val != 9999) {
                let result = val <= this.tbk_5cm_0730
                this.CC.tbb_5cm_0730.message = "Suhu Bola Kering >= Suhu Bola Basah";
                this.CC.tbb_5cm_0730.status = !result;
                return result;
            }
            this.CC.tbb_5cm_0730.message = "";
            this.CC.tbb_5cm_0730.status = false;
            return true
        }},
        tbb_10cm_0730: { required, GC_FDIH_PS_0730_TBB, consistency(val) {
            if (this.tbk_10cm_0730 && this.tbk_10cm_0730 != 9999 && val != 9999) {
                let result = val <= this.tbk_10cm_0730
                this.CC.tbb_10cm_0730.message = "Suhu Bola Kering >= Suhu Bola Basah";
                this.CC.tbb_10cm_0730.status = !result;
                return result;
            }
            this.CC.tbb_10cm_0730.message = "";
            this.CC.tbb_10cm_0730.status = false;
            return true
        } },
        tbb_20cm_0730: { required, GC_FDIH_PS_0730_TBB, consistency(val) {
            if (this.tbk_20cm_0730 && this.tbk_20cm_0730 != 9999 && val != 9999) {
                let result = val <= this.tbk_20cm_0730
                this.CC.tbb_20cm_0730.message = "Suhu Bola Kering >= Suhu Bola Basah";
                this.CC.tbb_20cm_0730.status = !result;
                return result;
            }
            this.CC.tbb_20cm_0730.message = "";
            this.CC.tbb_20cm_0730.status = false;
            return true
        } },
        tbb_50cm_0730: { required, GC_FDIH_PS_0730_TBB, consistency(val) {
            if (this.tbk_50cm_0730 && this.tbk_50cm_0730 != 9999 && val != 9999) {
                let result = val <= this.tbk_50cm_0730
                this.CC.tbb_50cm_0730.message = "Suhu Bola Kering >= Suhu Bola Basah";
                this.CC.tbb_50cm_0730.status = !result;
                return result;
            }
            this.CC.tbb_50cm_0730.message = "";
            this.CC.tbb_50cm_0730.status = false;
            return true
        } },
        tbb_100cm_0730: { required, GC_FDIH_PS_0730_TBB, consistency(val) {
            if (this.tbk_100cm_0730 && this.tbk_100cm_0730 != 9999 && val != 9999) {
                let result = val <= this.tbk_100cm_0730
                this.CC.tbb_100cm_0730.message = "Suhu Bola Kering >= Suhu Bola Basah";
                this.CC.tbb_100cm_0730.status = !result;
                return result;
            }
            this.CC.tbb_100cm_0730.message = "";
            this.CC.tbb_100cm_0730.status = false;
            return true
        } },
        tbb_150cm_0730: { required, GC_FDIH_PS_0730_TBB, consistency(val) {
            if (this.tbk_150cm_0730 && this.tbk_150cm_0730 != 9999 && val != 9999) {
                let result = val <= this.tbk_150cm_0730
                this.CC.tbb_150cm_0730.message = "Suhu Bola Kering >= Suhu Bola Basah";
                this.CC.tbb_150cm_0730.status = !result;
                return result;
            }
            this.CC.tbb_150cm_0730.message = "";
            this.CC.tbb_150cm_0730.status = false;
            return true
        } },
        tbb_200cm_0730: { required, GC_FDIH_PS_0730_TBB, consistency(val) {
            if (this.tbk_200cm_0730 && this.tbk_200cm_0730 != 9999 && val != 9999) {
                let result = val <= this.tbk_200cm_0730
                this.CC.tbb_200cm_0730.message = "Suhu Bola Kering >= Suhu Bola Basah";
                this.CC.tbb_200cm_0730.status = !result;
                return result;
            }
            this.CC.tbb_200cm_0730.message = "";
            this.CC.tbb_200cm_0730.status = false;
            return true
        } },

        ttr_0cm_0730: {required, GC_FDIH_0730_SUHU_TANAH},
        ttr_2cm_0730: {required, GC_FDIH_0730_SUHU_TANAH},
        ttr_5cm_0730: {required, GC_FDIH_0730_SUHU_TANAH},
        ttr_10cm_0730: {required, GC_FDIH_0730_SUHU_TANAH},
        ttr_20cm_0730: {required, GC_FDIH_0730_SUHU_TANAH},
        ttr_50cm_0730: {GC_FDIH_0730_50CM_SUHU_TANAH},
        ttr_100cm_0730: {GC_FDIH_0730_100CM_SUHU_TANAH},

        ttg_0cm_0730: {required, GC_FDIH_0730_SUHU_TANAH},
        ttg_2cm_0730: {required, GC_FDIH_0730_SUHU_TANAH},
        ttg_5cm_0730: {required, GC_FDIH_0730_SUHU_TANAH},
        ttg_10cm_0730: {required, GC_FDIH_0730_SUHU_TANAH},
        ttg_20cm_0730: {required, GC_FDIH_0730_SUHU_TANAH},
        ttg_50cm_0730: {GC_FDIH_0730_50CM_SUHU_TANAH},
        ttg_100cm_0730: {GC_FDIH_0730_100CM_SUHU_TANAH},
        
        h_piche_0730: {required, consistency(val) {
                if (this.h_piche_0730) {
                    let result =  val >= this.pitche_sebelum_0730
                    this.CC.h_piche_0730.message = "Piche Pembacaan Sekarang >= Piche Pembacaan Sebelum";
                    this.CC.h_piche_0730.status = !result;
                    return result;
                }
                this.CC.h_piche_0730.message = "";
                this.CC.h_piche_0730.status = false;
                return true
            }
        },

        ket_tanah_0730: {required},
        ket_hujan_0730: {required}
    },

    mounted() {
        this.initialize()
    },

    methods: {
        initialize() {
            this.setQCData()
            this.is_op_edit = false
            this.is_piche_edit = false
            this.is_counter_edit = false
            if (this.t_bmkgentry) {
                this.fdih_status.is_fklim = this.t_bmkgentry.is_fklim,
                this.fdih_status.is_iklim_mikro = this.t_bmkgentry.is_iklim_mikro,
                this.fdih_status.is_agm1a = this.t_bmkgentry.is_agm1a,
                this.fdih_status.is_agm1b = this.t_bmkgentry.is_agm1b,
                this.fdih_status.is_suhu_tanah = this.t_bmkgentry.is_suhu_tanah,
                this.fdih_status.is_psychrometer_assman = this.t_bmkgentry.is_psychrometer_assman,
                this.fdih_status.is_op_penguapan = this.t_bmkgentry.is_op_penguapan,
                this.fdih_status.is_piche_penguapan = this.t_bmkgentry.is_piche_penguapan,
                this.fdih_status.is_lysimeter = this.t_bmkgentry.is_lysimeter,
                this.fdih_status.is_gunbellani = this.t_bmkgentry.is_gunbellani
            }
            
        },

        onKeyCheck(event) {
            if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                event.preventDefault()
            }
        },

        format_one_decimal(e) {
            if (Math.round(e * 10) / 10 != e) {
                let text = String(e)
                let filter = text.substring(0, text.length-1)
                return filter;
            } else {
                if(Math.floor(e) === e) {
                    return e
                } 
                else {
                    if (e.toString().indexOf('.') > 0) {
                        let count = e.toString().split(".")[1].length || 0;
                        return count > 1 ? parseFloat(e).toFixed(1) : e
                    }
                    else {
                        return e
                    }
                     
                }
                return e;
            }
        },

        format_two_decimal(e, event) {
            if (Math.round(e * 100) / 100 != e) {
                let text = String(e)
                let filter = text.substring(0, text.length-1)
                return filter
            } else {
                if(Math.floor(e) === e) {
                    return e
                } 
                else {
                    if (e.toString().indexOf('.') > 0) {
                        let count = e.toString().split(".")[1].length || 0;
                        return count > 2 ? parseFloat(e).toFixed(2) : e 
                    }
                    else {
                        return e
                    }
                }
                
                return e
            }
        },
        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } if (evt.key == "." || evt.key == ",") {
                return evt.preventDefault();
            } else {
                return true;
            }
        },
        validateState(item) {
            const { $dirty, $error } = item;
            return $dirty ? !$error : null;
        },
        cancelForm() {
            this.clearData();
            this.$emit("form", "test"); 
        },
        validationForm() {
            // this.$v.$touch()
            // let valid = true
            // let messageError = "Data tidak valid. Periksa kembali inputan anda";

            // if (this.$v.$invalid) {
            //     console.log('this.$v', this.$v)
            //     valid = false
            // }
            let valid = true;
            let messageError = "";

            if (this.fdih_status.is_agm1b) {
                this.$v.tbk_1c2m_0730.$touch();
                this.$v.tbb_1c2m_0730.$touch();
                this.$v.counter_0c5_0730.$touch();
                this.$v.ws_10_0730.$touch();
                this.$v.t_air_max_0730.$touch();
                this.$v.t_air_min_0730.$touch();
                this.$v.rr_0730.$touch();
                this.$v.ket_tanah_0730.$touch();
                this.$v.ket_hujan_0730.$touch();
                this.$v.ttr_5cm_0730.$touch();
                this.$v.ttr_10cm_0730.$touch();
                this.$v.ttr_20cm_0730.$touch();
                this.$v.ttr_50cm_0730.$touch();
                this.$v.ttr_100cm_0730.$touch();
                this.$v.ttg_5cm_0730.$touch();
                this.$v.ttg_10cm_0730.$touch();
                this.$v.ttg_20cm_0730.$touch();
                this.$v.ttg_50cm_0730.$touch();
                this.$v.ttg_100cm_0730.$touch();

                if ( this.$v.tbk_1c2m_0730.$invalid || this.$v.tbb_1c2m_0730.$invalid || this.$v.counter_0c5_0730.$invalid || this.$v.ws_10_0730.$invalid ||
                        this.$v.t_air_max_0730.$invalid || this.$v.t_air_min_0730.$invalid || this.$v.rr_0730.$invalid || this.$v.ket_tanah_0730.$invalid ||
                        this.$v.ket_hujan_0730.$invalid || this.$v.ttr_5cm_0730.$invalid || this.$v.ttr_10cm_0730.$invalid || this.$v.ttr_20cm_0730.$invalid ||
                        this.$v.ttr_50cm_0730.$invalid || this.$v.ttr_100cm_0730.$invalid || this.$v.ttg_5cm_0730.$invalid || this.$v.ttg_10cm_0730.$invalid ||
                        this.$v.ttg_20cm_0730.$invalid || this.$v.ttg_50cm_0730.$invalid || this.$v.ttg_100cm_0730.$invalid ) 
                {
                    valid = false
                    messageError = "Data tidak valid. Periksa kembali inputan anda";
                }
            }

            if (this.fdih_status.is_psychrometer_assman) {
                this.$v.tbk_5cm_0730.$touch();
                this.$v.tbb_5cm_0730.$touch();
                this.$v.tbk_10cm_0730.$touch();
                this.$v.tbb_10cm_0730.$touch();
                this.$v.tbk_20cm_0730.$touch();
                this.$v.tbb_20cm_0730.$touch();
                this.$v.tbk_50cm_0730.$touch();
                this.$v.tbb_50cm_0730.$touch();
                this.$v.tbk_100cm_0730.$touch();
                this.$v.tbb_100cm_0730.$touch();
                this.$v.tbk_150cm_0730.$touch();
                this.$v.tbb_150cm_0730.$touch();
                this.$v.tbk_200cm_0730.$touch();
                this.$v.tbb_200cm_0730.$touch();

                if ( this.$v.tbk_5cm_0730.$invalid || this.$v.tbb_5cm_0730.$invalid || this.$v.tbk_10cm_0730.$invalid || this.$v.tbb_10cm_0730.$invalid ||
                        this.$v.tbk_20cm_0730.$invalid || this.$v.tbb_20cm_0730.$invalid || this.$v.tbk_50cm_0730.$invalid || this.$v.tbb_50cm_0730.$invalid ||
                        this.$v.tbk_100cm_0730.$invalid || this.$v.tbb_100cm_0730.$invalid || this.$v.tbk_150cm_0730.$invalid || this.$v.tbb_150cm_0730.$invalid ||
                        this.$v.tbk_200cm_0730.$invalid || this.$v.tbb_200cm_0730.$invalid ) 
                {
                    valid = false
                    messageError = "Data tidak valid. Periksa kembali inputan anda";
                }
            }

            if (this.fdih_status.is_piche_penguapan) {
                this.$v.h_piche_0730.$touch();

                if ( this.$v.h_piche_0730.$invalid ) 
                {
                    valid = false
                    messageError = "Data tidak valid. Periksa kembali inputan anda";
                }
            }

            if (this.fdih_status.is_suhu_tanah) {
                this.$v.ttr_0cm_0730.$touch();
                this.$v.ttr_2cm_0730.$touch();
                this.$v.ttr_5cm_0730.$touch();
                this.$v.ttr_10cm_0730.$touch();
                this.$v.ttr_20cm_0730.$touch();
                this.$v.ttr_50cm_0730.$touch();
                this.$v.ttr_100cm_0730.$touch();
                this.$v.ttg_0cm_0730.$touch();
                this.$v.ttg_2cm_0730.$touch();
                this.$v.ttg_5cm_0730.$touch();
                this.$v.ttg_10cm_0730.$touch();
                this.$v.ttg_20cm_0730.$touch();
                this.$v.ttg_50cm_0730.$touch();
                this.$v.ttg_100cm_0730.$touch();

                if ( this.$v.ttr_0cm_0730.$invalid || this.$v.ttr_2cm_0730.$invalid || this.$v.ttr_5cm_0730.$invalid || this.$v.ttr_10cm_0730.$invalid ||
                        this.$v.ttr_20cm_0730.$invalid || this.$v.ttr_50cm_0730.$invalid || this.$v.ttr_100cm_0730.$invalid || this.$v.ttg_0cm_0730.$invalid ||
                        this.$v.ttg_2cm_0730.$invalid || this.$v.ttg_5cm_0730.$invalid || this.$v.ttg_10cm_0730.$invalid || this.$v.ttg_20cm_0730.$invalid ||
                        this.$v.ttg_50cm_0730.$invalid || this.$v.ttg_100cm_0730.$invalid ) 
                {
                    valid = false
                    messageError = "Data tidak valid. Periksa kembali inputan anda";
                }
            }
            
            if (!valid) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                    title: messageError,//'Data tidak valid. Periksa kembali inputan anda',
                    icon: 'BellIcon',
                    variant:'danger',
                    },
                })
            }
            else {

                let qc_flag = (Object.keys(this.DATA_RC).length > 0) ? 1 : 0
                let list_qc = this.getQCExist()//qc_flag > 0 ? this.getQCExist() : null
                let msg_html = this.getHtmlMessage(list_qc)
                this.LIST_QC_FIX = (list_qc && Object.keys(list_qc).length > 0) ? list_qc : null
                // console.log('list_qc', list_qc)
                if (qc_flag == 1) {
                    let qc_histories = {
                        before: null,
                        after: list_qc,
                    };

                    this.$swal({
                        title: "Apakah Anda yakin untuk simpan data ?",
                        text: "Ada Data Terkoreksi Range Check ",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Submit",
                        cancelButtonText : "Cek Data",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1",
                        },
                        buttonsStyling: false,
                    }).then((result) => {
                        if (result.value) {
                            this.submitFDIH0730();
                        } else if (result.dismiss === "cancel") {
                        return;
                        }
                    });

                }
                else {
                    this.submitFDIH0730();
                }
                // var qc_flag = ( this.rc_op_diff_baca_0730 && this.rc_t_air_max_0730 && this.rc_t_air_min_0730 && this.rc_rr_0730 ) ? 0 : 1 
                // this.submitFDIH0730(qc_flag)
            }
        },

        async setQCData() {
            // console.log("call set data qc");
            // const qc_data = await metadata.getQCData(this.path_hujan, Params.GET_QC_STATION_KEY("form_hujan"));
            if (this.d_station) {
                const qc_data = this.d_station["BmkgSatu.metadata.qc.ISuhuTanahqc"]
                const qc_params = Params.GET_PARAMS("suhu_tanah");
                const qc_key = Params.GET_KEY("suhu_tanah");
                this.RC_helper.setData(qc_data, qc_params, qc_key);
                
                let qc_data_agm1b = this.d_station["BmkgSatu.metadata.qc.IAgm1bqc"]
                let qc_params_agm1b = Params.GET_PARAMS("agm1b");
                let qc_key_agm1b = Params.GET_KEY("agm1b");
                this.RC_helper_agm1b.setData(qc_data_agm1b, qc_params_agm1b, qc_key_agm1b);

                let qc_data_psassman = this.d_station["BmkgSatu.metadata.qc.IPsychrometerAssmanqc"]
                let qc_params_psassman = Params.GET_PARAMS("psychrometer_assman");
                let qc_key_psassman = Params.GET_KEY("psychrometer_assman");
                this.RC_helper_psassman.setData(qc_data_psassman, qc_params_psassman, qc_key_psassman);
                // console.log('this.RC_helper_psassman', this.RC_helper_psassman.getData())
            }
            
        },

        checkValidate: function(event, type) {
            // console.log("event", event);
            let value = event.target.value;

            switch (type) {
                // case "op_diff_baca_0730":
                //     var raw = this.RC_helper_op.check(value, "op_diff_baca_0730");
                //     // var raw = RC_FDIH(value, "op_diff_baca_0730");
                //     var gc = GC_FDIH_0730_OP_SELISIH(value);
                //     // console.log("raw", raw);
                //     // console.log("gc", gc);
                //     this.rc_op_diff_baca_0730 = !raw.status ? (!gc ? false : true) : false;
                //     this.rc_op_diff_baca_0730_message = raw.message;
                //     this.setDataRC(type, this.rc_op_diff_baca_0730, this.rc_op_diff_baca_0730_message, raw.roles)
                //     break;
                // case "t_air_max_0730": 
                //     var raw = this.RC_helper_op.check(value, "t_air_max_0730");
                //     // var raw = RC_FDIH(value, "t_air_max_0730");
                //     var gc = GC_FDIH_0730_OP_T_AIR_MAX(value);
                //     // console.log("raw", raw);
                //     // console.log("gc", gc);
                //     this.rc_t_air_max_0730 = !raw.status ? (!gc ? false : true) : false;
                //     this.rc_t_air_max_0730_message = raw.message;
                //     this.setDataRC(type, this.rc_t_air_max_0730, this.rc_t_air_max_0730_message, raw.roles)
                //     break;
                // case "t_air_min_0730":
                //     var raw = this.RC_helper_op.check(value, "t_air_min_0730");
                //     // var raw = RC_FDIH(value, "t_air_min_0730");
                //     var gc = GC_FDIH_0730_OP_T_AIR_MIN(value);
                //     // console.log("raw", raw);
                //     // console.log("gc", gc);
                //     this.rc_t_air_min_0730 = !raw.status ? (!gc ? false : true) : false;
                //     this.rc_t_air_min_0730_message = raw.message;
                //     this.setDataRC(type, this.rc_t_air_min_0730, this.rc_t_air_min_0730_message, raw.roles)
                //     break;
                case "rr_0730": 
                    var raw = this.RC_helper_agm1b.check(value, "rr_0730");
                    // var raw = RC_FDIH(value, "rr_0730");
                    var gc = GC_FDIH_0730_OP_CH_DIUKUR(value);
                    this.rc_rr_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_rr_0730_message = raw.message;
                    this.setDataRC(type, this.rc_rr_0730, this.rc_rr_0730_message, raw.roles)
                    break;
                //new RC
                case "ttr_0cm_0730":
                    var raw = this.RC_helper.check(value, "ttr_0cm_0730");//RC_FDIH(value, "ttr_0cm_0730");
                    var gc = GC_FDIH_0730_SUHU_TANAH(value);
                    this.rc_ttr_0cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_ttr_0cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_ttr_0cm_0730, this.rc_ttr_0cm_0730_message, raw.roles)
                    break;
                case "ttr_2cm_0730":
                    var raw = this.RC_helper.check(value, "ttr_2cm_0730");//RC_FDIH(value, "ttr_2cm_0730");
                    var gc = GC_FDIH_0730_SUHU_TANAH(value);
                    this.rc_ttr_2cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_ttr_2cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_ttr_2cm_0730, this.rc_ttr_2cm_0730_message, raw.roles)
                    break;
                case "ttr_5cm_0730": 
                    var raw = this.RC_helper.check(value, "ttr_5cm_0730");//RC_FDIH(value, "ttr_5cm_0730");
                    var gc = GC_FDIH_0730_SUHU_TANAH(value);
                    this.rc_ttr_5cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_ttr_5cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_ttr_5cm_0730, this.rc_ttr_5cm_0730_message, raw.roles)
                    break;
                case "ttr_10cm_0730":
                    var raw = this.RC_helper.check(value, "ttr_10cm_0730");//RC_FDIH(value, "ttr_10cm_0730");
                    var gc = GC_FDIH_0730_SUHU_TANAH(value);
                    this.rc_ttr_10cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_ttr_10cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_ttr_10cm_0730, this.rc_ttr_10cm_0730_message, raw.roles)
                    break;
                case "ttr_20cm_0730":
                    var raw = this.RC_helper.check(value, "ttr_20cm_0730");//RC_FDIH(value, "ttr_20cm_0730");
                    var gc = GC_FDIH_0730_SUHU_TANAH(value);
                    this.rc_ttr_20cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_ttr_20cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_ttr_20cm_0730, this.rc_ttr_20cm_0730_message, raw.roles)
                    break;
                case "ttr_50cm_0730":
                    var raw = this.RC_helper.check(value, "ttr_50cm_0730");//RC_FDIH(value, "ttr_50cm_0730");
                    var gc = GC_FDIH_0730_SUHU_TANAH(value);
                    this.rc_ttr_50cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_ttr_50cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_ttr_50cm_0730, this.rc_ttr_50cm_0730_message, raw.roles)
                    break;
                case "ttr_100cm_0730":
                    var raw = this.RC_helper.check(value, "ttr_100cm_0730");//RC_FDIH(value, "ttr_100cm_0730");
                    var gc = GC_FDIH_0730_SUHU_TANAH(value);
                    this.rc_ttr_100cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_ttr_100cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_ttr_100cm_0730, this.rc_ttr_100cm_0730_message, raw.roles)
                    break;

                case "ttg_0cm_0730": 
                    var raw = this.RC_helper.check(value, "ttg_0cm_0730");//RC_FDIH(value, "ttg_0cm_0730");
                    var gc = GC_FDIH_0730_SUHU_TANAH(value);
                    this.rc_ttg_0cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_ttg_0cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_ttg_0cm_0730, this.rc_ttg_0cm_0730_message, raw.roles)
                    break;
                case "ttg_2cm_0730":
                    var raw = this.RC_helper.check(value, "ttg_2cm_0730");//RC_FDIH(value, "ttg_2cm_0730");
                    var gc = GC_FDIH_0730_SUHU_TANAH(value);
                    this.rc_ttg_2cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_ttg_2cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_ttg_2cm_0730, this.rc_ttg_2cm_0730_message, raw.roles)
                    break;
                case "ttg_5cm_0730":
                    var raw = this.RC_helper.check(value, "ttg_5cm_0730");//RC_FDIH(value, "ttg_5cm_0730");
                    var gc = GC_FDIH_0730_SUHU_TANAH(value);
                    this.rc_ttg_5cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_ttg_5cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_ttg_5cm_0730, this.rc_ttg_5cm_0730_message, raw.roles)
                    break;
                case "ttg_10cm_0730":
                    var raw = this.RC_helper.check(value, "ttg_10cm_0730");//RC_FDIH(value, "ttg_10cm_0730");
                    var gc = GC_FDIH_0730_SUHU_TANAH(value);
                    this.rc_ttg_10cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_ttg_10cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_ttg_10cm_0730, this.rc_ttg_10cm_0730_message, raw.roles)
                    break;
                case "ttg_20cm_0730": 
                    var raw = this.RC_helper.check(value, "ttg_20cm_0730");//RC_FDIH(value, "ttg_20cm_0730");
                    var gc = GC_FDIH_0730_SUHU_TANAH(value);
                    this.rc_ttg_20cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_ttg_20cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_ttg_20cm_0730, this.rc_ttg_20cm_0730_message, raw.roles)
                    break;
                case "ttg_50cm_0730":
                    var raw = this.RC_helper.check(value, "ttg_50cm_0730");//RC_FDIH(value, "ttg_50cm_0730");
                    var gc = GC_FDIH_0730_SUHU_TANAH(value);
                    this.rc_ttg_50cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_ttg_50cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_ttg_50cm_0730, this.rc_ttg_50cm_0730_message, raw.roles)
                    break;
                case "ttg_100cm_0730":
                    var raw = this.RC_helper.check(value, "ttg_100cm_0730");//RC_FDIH(value, "ttg_100cm_0730");
                    var gc = GC_FDIH_0730_SUHU_TANAH(value);
                    this.rc_ttg_100cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_ttg_100cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_ttg_100cm_0730, this.rc_ttg_100cm_0730_message, raw.roles)
                    break;
                // PSYCHROMETER ASSMAN
                case "tbk_5cm_0730":
                    var raw = this.RC_helper_psassman.check(value, "tbk_5cm_0730");
                    var gc = GC_FDIH_PS_0730_TBK(value);
                    this.rc_tbk_5cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbk_5cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_tbk_5cm_0730, this.rc_tbk_5cm_0730_message, raw.roles)
                    break;
                case "tbb_5cm_0730": 
                    var raw = this.RC_helper_psassman.check(value, "tbb_5cm_0730");
                    var gc = GC_FDIH_PS_0730_TBB(value);
                    this.rc_tbb_5cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbb_5cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_tbb_5cm_0730, this.rc_tbb_5cm_0730_message, raw.roles)
                    break;
                case "tbk_10cm_0730":
                    var raw = this.RC_helper_psassman.check(value, "tbk_10cm_0730");
                    var gc = GC_FDIH_PS_0730_TBK(value);
                    this.rc_tbk_10cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbk_10cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_tbk_10cm_0730, this.rc_tbk_10cm_0730_message, raw.roles)
                    break;
                case "tbb_10cm_0730":
                    var raw = this.RC_helper_psassman.check(value, "tbb_10cm_0730");
                    var gc = GC_FDIH_PS_0730_TBB(value);
                    this.rc_tbb_10cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbb_10cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_tbb_10cm_0730, this.rc_tbb_10cm_0730_message, raw.roles)
                    break;
                case "tbk_20cm_0730":
                    var raw = this.RC_helper_psassman.check(value, "tbk_20cm_0730");
                    var gc = GC_FDIH_PS_0730_TBK(value);
                    this.rc_tbk_20cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbk_20cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_tbk_20cm_0730, this.rc_tbk_20cm_0730_message, raw.roles)
                    break;
                case "tbb_20cm_0730":
                    var raw = this.RC_helper_psassman.check(value, "tbb_20cm_0730");
                    var gc = GC_FDIH_PS_0730_TBB(value);
                    this.rc_tbb_20cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbb_20cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_tbb_20cm_0730, this.rc_tbb_20cm_0730_message, raw.roles)
                    break;
                case "tbk_50cm_0730":
                    var raw = this.RC_helper_psassman.check(value, "tbk_50cm_0730");
                    var gc = GC_FDIH_PS_0730_TBK(value);
                    this.rc_tbk_50cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbk_50cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_tbk_50cm_0730, this.rc_tbk_50cm_0730_message, raw.roles)
                    break;
                case "tbb_50cm_0730": 
                    var raw = this.RC_helper_psassman.check(value, "tbb_50cm_0730");
                    var gc = GC_FDIH_PS_0730_TBB(value);
                    this.rc_tbb_50cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbb_50cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_tbb_50cm_0730, this.rc_tbb_50cm_0730_message, raw.roles)
                    break;
                case "tbk_100cm_0730":
                    var raw = this.RC_helper_psassman.check(value, "tbk_100cm_0730");
                    var gc = GC_FDIH_PS_0730_TBK(value);
                    this.rc_tbk_100cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbk_100cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_tbk_100cm_0730, this.rc_tbk_100cm_0730_message, raw.roles)
                    break;
                case "tbb_100cm_0730":
                    var raw = this.RC_helper_psassman.check(value, "tbb_100cm_0730");
                    var gc = GC_FDIH_PS_0730_TBB(value);
                    this.rc_tbb_100cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbb_100cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_tbb_100cm_0730, this.rc_tbb_100cm_0730_message, raw.roles)
                    break;
                case "tbk_150cm_0730":
                    var raw = this.RC_helper_psassman.check(value, "tbk_150cm_0730");
                    var gc = GC_FDIH_PS_0730_TBK(value);
                    this.rc_tbk_150cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbk_150cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_tbk_150cm_0730, this.rc_tbk_150cm_0730_message, raw.roles)
                    break;
                case "tbb_150cm_0730": 
                    var raw = this.RC_helper_psassman.check(value, "tbb_150cm_0730");
                    var gc = GC_FDIH_PS_0730_TBB(value);
                    this.rc_tbb_150cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbb_150cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_tbb_150cm_0730, this.rc_tbb_150cm_0730_message, raw.roles)
                    break;
                case "tbk_200cm_0730":
                    var raw = this.RC_helper_psassman.check(value, "tbk_200cm_0730");
                    var gc = GC_FDIH_PS_0730_TBK(value);
                    this.rc_tbk_200cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbk_200cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_tbk_200cm_0730, this.rc_tbk_200cm_0730_message, raw.roles)
                    break;
                case "tbb_200cm_0730": 
                    var raw = this.RC_helper_psassman.check(value, "tbb_200cm_0730");
                    var gc = GC_FDIH_PS_0730_TBB(value);
                    this.rc_tbb_200cm_0730 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbb_200cm_0730_message = raw.message;
                    this.setDataRC(type, this.rc_tbb_200cm_0730, this.rc_tbb_200cm_0730_message, raw.roles)
                    break;

                default:
                    break
            }
         
        },

        setDataRC(type, status, message, roles) {
            if (status) {
                this.DATA_RC[type] = {
                    status: status,
                    message: message,
                    roles: roles
                };
            }
            else {
                if(this.DATA_RC.hasOwnProperty(type)) {
                    delete this.DATA_RC[type]
                }
            }
        },
        
        tooltipMessage(attr) {
        return GC_FDIH(attr).message;
        },
        validateTooltipState(name) {
        const { $error } = this.$v[name];
        return false//$error;
        },
        onClosePopup1() {
            this.popoverShow = false
        },
        onClosePopup2() {
            this.popoverShow2 = false
        },
        // rumus_Es(val) {
        //     return 6.11 * Math.pow(10, (7.5 * val/10) / (2373.3 + val/10))
        // },
        // rumus_E(es, tbk, tbb) {
        //     return es - 0.0007947 * (tbk/10 - tbb/10) * 1000
        // },

        getHtmlMessage(data) {
            let html = ""
            if(data && data.length > 0) {
                for (let dt of data) {
                html += '<div>'+dt.atribut+ ': '+dt.message+' </div>'
                }
                return html
            }

            return "<div />"
        },

        getQCExist() {
            // if (Object.keys(this.DATA_RC).length > 0) {    
                let qc_agm1b = Object.entries(this.DATA_RC)
                                    .filter((e) => e[1].status && (e[0] == "rr_0730") || e[0] == "ttr_5cm_0730" || e[0] == "ttr_10cm_0730" || e[0] == "ttr_20cm_0730" ||
                                                    e[0] == "ttg_5cm_0730" || e[0] == "ttg_10cm_0730" || e[0] == "ttg_20cm_0730" )
                                    .map((e) => {
                                        let param = e[0]
                                        let content = e[1]
                                        return {
                                        raw_type: "AGM1B",
                                        time: '0730ws',
                                        atribut_name: param,
                                        atribut: 'agm1b_'+param,
                                        value: this[param],
                                        roles: content.roles,//RC_FDIH_ROLES(param),
                                        type: "range_check",
                                        message: content.message,
                                        };
                                    });
                                          
                let qc_suhu_tanah = Object.entries(this.DATA_RC) 
                                    .filter((e) => e[1].status &&    
                                                    (e[0] == "ttr_0cm_0730" || e[0] == "ttr_2cm_0730" || e[0] == "ttr_5cm_0730" || e[0] == "ttr_10cm_0730"
                                                    || e[0] == "ttr_20cm_0730" || e[0] == "ttr_50cm_0730" || e[0] == "ttr_100cm_0730" || e[0] == "ttg_0cm_0730"
                                                    || e[0] == "ttg_2cm_0730" || e[0] == "ttg_5cm_0730" || e[0] == "ttg_10cm_0730" || e[0] == "ttg_20cm_0730"
                                                    || e[0] == "ttg_50cm_0730" || e[0] == "ttg_100cm_0730" ) )
                                    .map((e) => {
                                        let param = e[0]
                                        let content = e[1]
                                        return {
                                        raw_type: "SUHU_TANAH",
                                        time: '0730ws',
                                        atribut: param,
                                        atribut_name: param,
                                        value: this[param],
                                        roles: content.roles,//RC_FDIH_ROLES(param),
                                        type: "range_check",
                                        message: content.message,
                                        };
                                    });
                                    
                let qc_psychrometer_assman = Object.entries(this.DATA_RC) 
                                    .filter((e) => e[1].status &&                 
                                                    (e[0] == "tbk_5cm_0730" || e[0] == "tbb_5cm_0730" || e[0] == "tbk_10cm_0730" || e[0] == "tbb_10cm_0730"
                                                    || e[0] == "tbk_20cm_0730" || e[0] == "tbb_20cm_0730" || e[0] == "tbk_50cm_0730" || e[0] == "tbb_50cm_0730"
                                                    || e[0] == "tbk_100cm_0730" || e[0] == "tbb_100cm_0730" || e[0] == "tbk_150cm_0730" || e[0] == "tbb_150cm_0730"
                                                    || e[0] == "tbk_200cm_0730" || e[0] == "tbb_200cm_0730" ) )
                                    .map((e) => {
                                        let param = e[0]
                                        let content = e[1]
                                        return {
                                        raw_type: "PSYCHROMETER_ASSMAN",
                                        time: '0730ws',
                                        atribut: param,
                                        atribut_name: param,
                                        value: this[param],
                                        roles: content.roles,//RC_FDIH_ROLES(param),
                                        type: "range_check",
                                        message: content.message,
                                        };
                                    });

                let allform = {}
                if (qc_agm1b && qc_agm1b.length > 0) {
                    let exist = this.t_qc_histories?.agm1b
                    exist = exist?.after
                    if(exist && exist.length > 0) {
                        var filtered = exist.filter(function(e) {
                                                    return (
                                                        e.atribut != "rr_0730" && e.atribut != "agm1b_rr_0730" && 
                                                        e.atribut != "ttr_5cm_0730" && e.atribut != "agm1b_ttr_5cm_0730" &&
                                                        e.atribut != "ttr_10cm_0730" && e.atribut != "agm1b_ttr_10cm_0730" && 
                                                        e.atribut != "ttr_20cm_0730" && e.atribut != "agm1b_ttr_20cm_0730" && 
                                                        e.atribut != "ttg_5cm_0730" & e.atribut != "agm1b_ttg_5cm_0730" && 
                                                        e.atribut != "ttg_10cm_0730" && e.atribut != "agm1b_ttg_10cm_0730" &&
                                                        e.atribut != "ttg_20cm_0730" && e.atribut != "agm1b_ttg_20cm_0730" )
                                                    })
                        for (let data of qc_agm1b) {
                            var index = filtered.findIndex(x => x.hasOwnProperty('atribut_name') ? (x.atribut_name == data.atribut_name) : (x.atribut == data.atribut_name))
                            if (index === -1) {
                                filtered.push(data);
                            }else {
                                filtered[index]['atribut'] = data['atribut']
                                filtered[index]['atribut_name'] = data['atribut_name']
                            }
                        }
                        allform['agm1b'] = filtered
                    }
                    else {
                        allform['agm1b'] = qc_agm1b
                    }
                }
                else { // ketika current kosong, tetapi masih ada histori yg lama
                    let exist = this.t_qc_histories?.agm1b;
                    exist = exist?.after;
                    if (exist && exist.length > 0) {
                        var filtered = exist.filter(function(e) {
                                                    return (
                                                        e.atribut != "rr_0730" && e.atribut != "agm1b_rr_0730" && 
                                                        e.atribut != "ttr_5cm_0730" && e.atribut != "agm1b_ttr_5cm_0730" &&
                                                        e.atribut != "ttr_10cm_0730" && e.atribut != "agm1b_ttr_10cm_0730" && 
                                                        e.atribut != "ttr_20cm_0730" && e.atribut != "agm1b_ttr_20cm_0730" && 
                                                        e.atribut != "ttg_5cm_0730" & e.atribut != "agm1b_ttg_5cm_0730" && 
                                                        e.atribut != "ttg_10cm_0730" && e.atribut != "agm1b_ttg_10cm_0730" &&
                                                        e.atribut != "ttg_20cm_0730" && e.atribut != "agm1b_ttg_20cm_0730" )
                                                    })
                        allform["agm1b"] = filtered ;
                    }
                    else {
                        allform["agm1b"] = null 
                    }
                }
                                
                // if (qc_suhu_tanah && qc_suhu_tanah.length > 0) allform['suhutanah'] = qc_suhu_tanah
                if (qc_suhu_tanah && qc_suhu_tanah.length > 0) {
                    let exist = this.t_qc_histories?.suhutanah
                    exist = exist?.after
                    if(exist && exist.length > 0) {
                        var filtered = exist.filter(function(e) {
                                                    return (
                                                        e.atribut != "ttr_0cm_0730" && e.atribut != "ttr_2cm_0730" && 
                                                        e.atribut != "ttr_5cm_0730" && e.atribut != "ttr_10cm_0730" &&
                                                        e.atribut != "ttr_20cm_0730" && e.atribut != "ttr_50cm_0730" && 
                                                        e.atribut != "ttr_100cm_0730" && e.atribut != "ttg_0cm_0730" && 
                                                        e.atribut != "ttg_2cm_0730" & e.atribut != "ttg_5cm_0730" && 
                                                        e.atribut != "ttg_10cm_0730" && e.atribut != "ttg_20cm_0730" &&
                                                        e.atribut != "ttg_50cm_0730" && e.atribut != "ttg_100cm_0730" )
                                                    })
                        for (let data of qc_suhu_tanah) {
                            var index = filtered.findIndex(x => x.hasOwnProperty('atribut_name') ? (x.atribut_name == data.atribut_name) : (x.atribut == data.atribut_name))
                            if (index === -1) {
                                filtered.push(data);
                            }else {
                                filtered[index]['atribut_name'] = data['atribut_name']
                            }
                        }
                        allform['suhutanah'] = filtered
                    }
                    else {
                        allform['suhutanah'] = qc_suhu_tanah
                    } 
                }
                else { // ketika current kosong, tetapi masih ada histori yg lama
                    let exist = this.t_qc_histories?.suhutanah;
                    exist = exist?.after;
                    if (exist && exist.length > 0) {
                        var filtered = exist.filter(function(e) {
                                                    return (
                                                        e.atribut != "ttr_0cm_0730" && e.atribut != "ttr_2cm_0730" && 
                                                        e.atribut != "ttr_5cm_0730" && e.atribut != "ttr_10cm_0730" &&
                                                        e.atribut != "ttr_20cm_0730" && e.atribut != "ttr_50cm_0730" && 
                                                        e.atribut != "ttr_100cm_0730" && e.atribut != "ttg_0cm_0730" && 
                                                        e.atribut != "ttg_2cm_0730" & e.atribut != "ttg_5cm_0730" && 
                                                        e.atribut != "ttg_10cm_0730" && e.atribut != "ttg_20cm_0730" &&
                                                        e.atribut != "ttg_50cm_0730" && e.atribut != "ttg_100cm_0730" )
                                                    })
                        allform["suhutanah"] = filtered ;
                    }
                    else {
                        allform["suhutanah"] = null 
                    }
                }

                if (qc_psychrometer_assman && qc_psychrometer_assman.length > 0) {
                    let exist = this.t_qc_histories?.psychrometerassman
                    exist = exist?.after
                    if(exist && exist.length > 0) {
                        var filtered = exist.filter(function(e) {
                                                    return (
                                                        e.atribut != "tbk_5cm_0730" && e.atribut != "tbb_5cm_0730" && 
                                                        e.atribut != "tbk_10cm_0730" && e.atribut != "tbb_10cm_0730" &&
                                                        e.atribut != "tbk_20cm_0730" && e.atribut != "tbb_20cm_0730" && 
                                                        e.atribut != "tbk_50cm_0730" && e.atribut != "tbb_50cm_0730" && 
                                                        e.atribut != "tbk_100cm_0730" & e.atribut != "tbb_100cm_0730" && 
                                                        e.atribut != "tbk_150cm_0730" && e.atribut != "tbb_150cm_0730" &&
                                                        e.atribut != "tbk_200cm_0730" && e.atribut != "tbb_200cm_0730" )
                                                    })
                        for (let data of qc_psychrometer_assman) {
                            var index = filtered.findIndex(x => x.hasOwnProperty('atribut_name') ? (x.atribut_name == data.atribut_name) : (x.atribut == data.atribut_name))
                            if (index === -1) {
                                filtered.push(data);
                            }else {
                                filtered[index]['atribut_name'] = data['atribut_name']
                            }
                        }
                        allform['psychrometerassman'] = filtered
                    }
                    else {
                        allform['psychrometerassman'] = qc_psychrometer_assman
                    }  
                }
                else { // ketika current kosong, tetapi masih ada histori yg lama
                    let exist = this.t_qc_histories?.psychrometerassman;
                    exist = exist?.after;
                    if (exist && exist.length > 0) {
                        var filtered = exist.filter(function(e) {
                                                    return (
                                                        e.atribut != "tbk_5cm_0730" && e.atribut != "tbb_5cm_0730" && 
                                                        e.atribut != "tbk_10cm_0730" && e.atribut != "tbb_10cm_0730" &&
                                                        e.atribut != "tbk_20cm_0730" && e.atribut != "tbb_20cm_0730" && 
                                                        e.atribut != "tbk_50cm_0730" && e.atribut != "tbb_50cm_0730" && 
                                                        e.atribut != "tbk_100cm_0730" & e.atribut != "tbb_100cm_0730" && 
                                                        e.atribut != "tbk_150cm_0730" && e.atribut != "tbb_150cm_0730" &&
                                                        e.atribut != "tbk_200cm_0730" && e.atribut != "tbb_200cm_0730" )
                                                    })
                        allform["psychrometerassman"] = filtered ;
                    }
                    else {
                        allform["psychrometerassman"] = null 
                    }
                }
                
                return allform
            // }
            // return null
        },

        submitFDIH0730(){
            let qc_flag = 0
            const fdih_id = this.t_id_form;//"1_fdih_"+this.t_date
            const data_timestamp = this.t_date
            let post_data = {
                "data_timestamp":data_timestamp,
                "@type": "Fdih",
                "id": fdih_id,
                "observer_id": this.t_observer.id, 
                "observer_name": this.t_observer.observer,
                "m_0730ws": { 
                    "tbk_1c2m_0730": this.tbk_1c2m_0730,
                    "tbb_1c2m_0730": this.tbb_1c2m_0730,
                    "rh_1c2m_0730": this.rh_1c2m_0730,
                    "counter_sebelum_0c5_0730": this.counter_sebelum_0c5_0730,
                    "counter_0c5_0730": this.counter_0c5_0730,
                    "ws_avg_0c5_0730": this.ws_avg_0c5_0730,
                    "ws_10_0730": this.ws_10_0730,
                    "op_diff_baca_0730": this.op_diff_baca_0730,
                    "h_air_0730": this.h_air_0730,
                    "h_air_diff_0730": this.h_air_diff_0730,
                    "time_kuras_0730": this.time_kuras_0730,
                    "h_air_setelah_kuras_0730": this.h_air_setelah_kuras_0730,
                    "h_air_sebelum_kuras_0730": this.h_air_sebelum_kuras_0730,
                    "h_air_diff_setelah_kuras_0730": this.h_air_diff_setelah_kuras_0730,
                    "h_air_diff_sebelum_kuras_0730": this.h_air_diff_sebelum_kuras_0730,
                    "t_air_max_0730": this.t_air_max_0730,
                    "t_air_min_0730": this.t_air_min_0730,
                    "t_air_avg_0730": this.t_air_avg_0730,
                    "rr_0730": this.rr_0730,
                    "op_eva_0730": this.op_eva_0730,
                    "tbk_5cm_0730": this.tbk_5cm_0730,
                    "tbk_10cm_0730": this.tbk_10cm_0730,
                    "tbk_20cm_0730": this.tbk_20cm_0730,
                    "tbk_50cm_0730": this.tbk_50cm_0730,
                    "tbk_100cm_0730": this.tbk_100cm_0730,
                    "tbk_150cm_0730": this.tbk_150cm_0730,
                    "tbk_200cm_0730": this.tbk_200cm_0730,
                    "tbb_5cm_0730": this.tbb_5cm_0730,
                    "tbb_10cm_0730": this.tbb_10cm_0730,
                    "tbb_20cm_0730": this.tbb_20cm_0730,
                    "tbb_50cm_0730": this.tbb_50cm_0730,
                    "tbb_100cm_0730": this.tbb_100cm_0730,
                    "tbb_150cm_0730": this.tbb_150cm_0730,
                    "tbb_200cm_0730": this.tbb_200cm_0730,
                    "rh_5cm_0730": this.rh_5cm_0730,
                    "rh_10cm_0730": this.rh_10cm_0730,
                    "rh_20cm_0730": this.rh_20cm_0730,
                    "rh_50cm_0730": this.rh_50cm_0730,
                    "rh_100cm_0730": this.rh_100cm_0730,
                    "rh_150cm_0730": this.rh_150cm_0730,
                    "rh_200cm_0730": this.rh_200cm_0730,
                    "pitche_sebelum_0730": this.pitche_sebelum_0730,
                    "h_piche_0730": this.h_piche_0730,
                    "eva_piche_0730": this.eva_piche_0730,
                    "h_piche_ditambah_0730": this.h_piche_ditambah_0730,
                    "ket_tanah_0730": this.ket_tanah_0730,
                    "ket_hujan_0730": this.ket_hujan_0730,
                    "ket_1b_0730": this.ket_1b_0730,
                    "ttg_0cm_0730": this.ttg_0cm_0730,
                    "ttg_2cm_0730": this.ttg_2cm_0730,
                    "ttg_5cm_0730": this.ttg_5cm_0730,
                    "ttg_10cm_0730": this.ttg_10cm_0730,
                    "ttg_20cm_0730": this.ttg_20cm_0730,
                    "ttg_50cm_0730": this.ttg_50cm_0730,
                    "ttg_100cm_0730": this.ttg_100cm_0730,
                    "ttr_0cm_0730": this.ttr_0cm_0730,
                    "ttr_2cm_0730": this.ttr_2cm_0730,
                    "ttr_5cm_0730": this.ttr_5cm_0730,
                    "ttr_10cm_0730": this.ttr_10cm_0730,
                    "ttr_20cm_0730": this.ttr_20cm_0730,
                    "ttr_100cm_0730": this.ttr_100cm_0730,
                    "ttr_50cm_0730": this.ttr_50cm_0730,
                    "flagm_0730": 1,
                    "qc_flag": qc_flag
                }
            }

            if (this.t_data_status == "update") {
                post_data = {
                    "data_timestamp":data_timestamp,
                    "observer_id": this.t_observer.id, 
                    "observer_name": this.t_observer.observer,
                    "m_0730ws": { 
                        "tbk_1c2m_0730": this.tbk_1c2m_0730,
                        "tbb_1c2m_0730": this.tbb_1c2m_0730,
                        "rh_1c2m_0730": this.rh_1c2m_0730,
                        "counter_sebelum_0c5_0730": this.counter_sebelum_0c5_0730,
                        "counter_0c5_0730": this.counter_0c5_0730,
                        "ws_avg_0c5_0730": this.ws_avg_0c5_0730,
                        "ws_10_0730": this.ws_10_0730,
                        "op_diff_baca_0730": this.op_diff_baca_0730,
                        "h_air_0730": this.h_air_0730,
                        "h_air_diff_0730": this.h_air_diff_0730,
                        "time_kuras_0730": this.time_kuras_0730,
                        "h_air_setelah_kuras_0730": this.h_air_setelah_kuras_0730,
                        "h_air_sebelum_kuras_0730": this.h_air_sebelum_kuras_0730,
                        "h_air_diff_setelah_kuras_0730": this.h_air_diff_setelah_kuras_0730,
                        "h_air_diff_sebelum_kuras_0730": this.h_air_diff_sebelum_kuras_0730,
                        "t_air_max_0730": this.t_air_max_0730,
                        "t_air_min_0730": this.t_air_min_0730,
                        "t_air_avg_0730": this.t_air_avg_0730,
                        "rr_0730": this.rr_0730,
                        "op_eva_0730": this.op_eva_0730,
                        "tbk_5cm_0730": this.tbk_5cm_0730,
                        "tbk_10cm_0730": this.tbk_10cm_0730,
                        "tbk_20cm_0730": this.tbk_20cm_0730,
                        "tbk_50cm_0730": this.tbk_50cm_0730,
                        "tbk_100cm_0730": this.tbk_100cm_0730,
                        "tbk_150cm_0730": this.tbk_150cm_0730,
                        "tbk_200cm_0730": this.tbk_200cm_0730,
                        "tbb_5cm_0730": this.tbb_5cm_0730,
                        "tbb_10cm_0730": this.tbb_10cm_0730,
                        "tbb_20cm_0730": this.tbb_20cm_0730,
                        "tbb_50cm_0730": this.tbb_50cm_0730,
                        "tbb_100cm_0730": this.tbb_100cm_0730,
                        "tbb_150cm_0730": this.tbb_150cm_0730,
                        "tbb_200cm_0730": this.tbb_200cm_0730,
                        "rh_5cm_0730": this.rh_5cm_0730,
                        "rh_10cm_0730": this.rh_10cm_0730,
                        "rh_20cm_0730": this.rh_20cm_0730,
                        "rh_50cm_0730": this.rh_50cm_0730,
                        "rh_100cm_0730": this.rh_100cm_0730,
                        "rh_150cm_0730": this.rh_150cm_0730,
                        "rh_200cm_0730": this.rh_200cm_0730,
                        "pitche_sebelum_0730": this.pitche_sebelum_0730,
                        "h_piche_0730": this.h_piche_0730,
                        "eva_piche_0730": this.eva_piche_0730,
                        "h_piche_ditambah_0730": this.h_piche_ditambah_0730,
                        "ket_tanah_0730": this.ket_tanah_0730,
                        "ket_hujan_0730": this.ket_hujan_0730,
                        "ket_1b_0730": this.ket_1b_0730,
                        "ttg_0cm_0730": this.ttg_0cm_0730,
                        "ttg_2cm_0730": this.ttg_2cm_0730,
                        "ttg_5cm_0730": this.ttg_5cm_0730,
                        "ttg_10cm_0730": this.ttg_10cm_0730,
                        "ttg_20cm_0730": this.ttg_20cm_0730,
                        "ttg_50cm_0730": this.ttg_50cm_0730,
                        "ttg_100cm_0730": this.ttg_100cm_0730,
                        "ttr_0cm_0730": this.ttr_0cm_0730,
                        "ttr_2cm_0730": this.ttr_2cm_0730,
                        "ttr_5cm_0730": this.ttr_5cm_0730,
                        "ttr_10cm_0730": this.ttr_10cm_0730,
                        "ttr_20cm_0730": this.ttr_20cm_0730,
                        "ttr_100cm_0730": this.ttr_100cm_0730,
                        "ttr_50cm_0730": this.ttr_50cm_0730,
                        "flagm_0730": 1,
                        "qc_flag": qc_flag
                    }
                }
            }

            let list_qc_notification = {}
            this.LIST_QC_NOTIFICATION_FIX = {}
            let regionId = this.t_path.split('/')[1]
            if (this.LIST_QC_FIX && Object.keys(this.LIST_QC_FIX).length > 0) {
                if(this.LIST_QC_FIX.hasOwnProperty('agm1b')) {
                    post_data['BmkgSatu.behaviors.qc.IFdih_Agm1b_qc'] = {
                        qc_flag_agm1b: this.flagdata_agm1b == 2 ? 2 : (this.LIST_QC_FIX.agm1b && this.LIST_QC_FIX.agm1b.length > 0) ? 1 : 0,
                        qc_flag_agm1b_0730: (this.LIST_QC_FIX.agm1b && this.LIST_QC_FIX.agm1b.length > 0) ? 1 : 0,
                        qc_histories_agm1b: JSON.stringify({before: null, after: this.LIST_QC_FIX.agm1b}) 
                    }
                    this.LIST_QC_NOTIFICATION_FIX['agm1b'] = [ 'agm1b_'+this.t_id_form, // id entry
                                                                        "AGM1B", // type form entry
                                                                        data_timestamp, // tanggal entry
                                                                        "0730ws", // jam entry
                                                                        JSON.stringify({before: null, after: this.LIST_QC_FIX.agm1b} ), // qc histories
                                                                        1, // qc_flag
                                                                        this.t_station_id, // station_id
                                                                        this.t_station.text, //station_name
                                                                        this.t_observer.id, // observer_id
                                                                        this.t_observer.observer, //observer_name
                                                                        regionId, // region_id
                                                                        this.t_path+"/"+this.t_id_form // path
                                                                    ]
                } 

                if(this.LIST_QC_FIX.hasOwnProperty('suhutanah')) {
                    post_data['BmkgSatu.behaviors.qc.IFdih_SuhuTanah_qc'] = {
                        qc_flag_suhu_tanah: this.flagdata_suhu_tanah == 2 ? 2 : (this.LIST_QC_FIX.suhutanah &&  this.LIST_QC_FIX.suhutanah.length > 0 ) ? 1 : 0,
                        qc_flag_suhu_tanah_0730: (this.LIST_QC_FIX.suhutanah &&  this.LIST_QC_FIX.suhutanah.length > 0 ) ? 1 : 0,
                        qc_histories_suhu_tanah: JSON.stringify({before: null, after: this.LIST_QC_FIX.suhutanah})
                    }

                    this.LIST_QC_NOTIFICATION_FIX['suhutanah'] = [ 'suhutanah_'+this.t_id_form, // id entry
                                                                        "SUHU_TANAH", // type form entry
                                                                        data_timestamp, // tanggal entry
                                                                        "0730ws", // jam entry
                                                                        JSON.stringify({before: null, after: this.LIST_QC_FIX.suhutanah} ), // qc histories
                                                                        1, // qc_flag
                                                                        this.t_station_id, // station_id
                                                                        this.t_station.text, //station_name
                                                                        this.t_observer.id, // observer_id
                                                                        this.t_observer.observer, //observer_name
                                                                        regionId, // region_id
                                                                        this.t_path+"/"+this.t_id_form // path
                                                                    ]
                } 

                if(this.LIST_QC_FIX.hasOwnProperty('psychrometerassman')) {
                    post_data['BmkgSatu.behaviors.qc.IFdih_PsychrometerAssman_qc'] = {
                        qc_flag_psychrometer_assman: this.flagdata_psychrometer_assman == 2 ? 2 : (this.LIST_QC_FIX.psychrometerassman && this.LIST_QC_FIX.psychrometerassman.length > 0) ? 1 : 0,
                        qc_flag_psychrometer_assman_0730: (this.LIST_QC_FIX.psychrometerassman && this.LIST_QC_FIX.psychrometerassman.length > 0) ?  1 : 0,
                        qc_histories_psychrometer_assman: JSON.stringify({before: null, after: this.LIST_QC_FIX.psychrometerassman})
                    }

                    this.LIST_QC_NOTIFICATION_FIX['psychrometerassman'] = [ 'psychrometerassman_'+this.t_id_form, // id entry
                                                                        "PSYCHROMETER_ASSMAN", // type form entry
                                                                        data_timestamp, // tanggal entry
                                                                        "0730ws", // jam entry
                                                                        JSON.stringify({before: null, after: this.LIST_QC_FIX.psychrometerassman} ), // qc histories
                                                                        1, // qc_flag
                                                                        this.t_station_id, // station_id
                                                                        this.t_station.text, //station_name
                                                                        this.t_observer.id, // observer_id
                                                                        this.t_observer.observer, //observer_name
                                                                        regionId, // region_id
                                                                        this.t_path+"/"+this.t_id_form // path
                                                                    ]
                }
            }

            
            this.showLoading = true
            if (this.t_data_status == "update") {
                Service.setFDIH07301800(this.t_path+'/'+this.t_id_form, post_data)
                    .then((response) => {
                        if (response.status == 201 || response.status == 200 || response.status == 204) {
                            this.finishingRequest()
                            this.$emit('form', "test")
                            let titleMessage = "Input Data Berhasil!!"
                            let txtMessage = "Input Data FDIH"
                            if(this.t_data_status == "update") {
                                titleMessage = "Update FDIH 0730 berhasil!"
                                txtMessage = "Update FDIH 0730"
                            }
                            this.$swal({
                                title: titleMessage,
                                text: txtMessage,
                                icon: 'success',
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                                buttonsStyling: false,
                            })

                            this.showLoading = false
                        }
                    })
                    .catch((error) => {
                        let titleMessage = ""
                        let txtMessage = ""
                        if (error.response.status == 401) {
                            txtMessage = "Sesi anda telah habis. Logout dan silahkan login kembali"
                            if(this.t_data_status == "update") {
                                titleMessage = "Gagal Update. Unauthorized! "
                            }
                            else {
                                titleMessage = "Gagal proses submit. Unauthorized!"
                            }
                            this.$swal({
                            title: titleMessage,
                            text: txtMessage,
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                            });
                        } 
                        else if (error.response.status == 404) {
                            txtMessage = "404. Object Not Found"
                            if(this.t_data_status == "update") {
                                titleMessage = "Gagal Update!"
                            }
                            else {
                                titleMessage = "Gagal entry!"
                            }
                            this.$swal({
                            title: titleMessage,
                            text: txtMessage,
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                            });
                        } 
                        else {
                            let error_msg = error.response?.data?.error?.message || ("" + error.response?.status)
                            
                            txtMessage = error_msg;
                            if(this.t_data_status == "update") {
                                titleMessage = "Gagal Update!"
                            }
                            else {
                                titleMessage = "Gagal entry!"
                            }
                            this.$swal({
                            title: titleMessage,
                            text: txtMessage,
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                            });
                        }

                        this.showLoading = false
                    });
            }
            else if (this.t_data_status == "new"){
                Service.setNewFDIH07001800(this.t_path, post_data)
                    .then((response) => {
                        if (response.status == 201 || response.status == 200 || response.status == 204) {
                        this.finishingRequest();
                        this.clearData();
                        this.$emit("form", "test");
                        let titleMessage = "Input Data Berhasil!";
                        let txtMessage = "Input Data FDIH";
                        this.$swal({
                            title: titleMessage,
                            text: txtMessage,
                            icon: "success",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });

                        this.showLoading = false;
                        }
                    })
                    .catch((error) => {
                        if (error.response.status == 401) {
                        this.$swal({
                            title: "Gagal proses submit. Unauthorized!",
                            text: "Sesi anda telah habis. Logout dan silahkan login kembali",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });
                        } else if (error.response.status == 404) {
                        this.$swal({
                            title: "Gagal entry!",
                            text: "404. Object Not Found",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });
                        } else {
                            let error_msg = error.response?.data?.error?.message || ("" + error.response?.status)
                            this.$swal({
                                title: "Gagal entry!",
                                text: error_msg,
                                customClass: {
                                confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        }
                        this.showLoading = false;
                    });
            } 
            

        },

        async finishingRequest() {
            if(this.LIST_QC_NOTIFICATION_FIX && Object.keys(this.LIST_QC_NOTIFICATION_FIX).length > 0) {    
                for (let key in this.LIST_QC_NOTIFICATION_FIX) {
                    new Promise(async (resolve) => {
                        let data = await qc_helper.editQCNotification(
                                            this.LIST_QC_NOTIFICATION_FIX[key][0],
                                            this.LIST_QC_NOTIFICATION_FIX[key][1],
                                            this.LIST_QC_NOTIFICATION_FIX[key][2],
                                            this.LIST_QC_NOTIFICATION_FIX[key][3],
                                            this.LIST_QC_NOTIFICATION_FIX[key][4],
                                            this.LIST_QC_NOTIFICATION_FIX[key][5],
                                            this.LIST_QC_NOTIFICATION_FIX[key][6],
                                            this.LIST_QC_NOTIFICATION_FIX[key][7],
                                            this.LIST_QC_NOTIFICATION_FIX[key][8],
                                            this.LIST_QC_NOTIFICATION_FIX[key][9],
                                            this.LIST_QC_NOTIFICATION_FIX[key][10],
                                            this.LIST_QC_NOTIFICATION_FIX[key][11])

                        resolve(data)
                    });
                }
            }
        },
        clearData() {
            // console.log('clearData')
            this.showLoading = false,
            this.popoverShow = false
            this.popoverShow2 = false
            this.keadaan_tanah = ''
            this.keadaan_hujan = ''   
            this.isPengurasan = false         
            this.op_kuras_time = '0730ws'
            this.tbk_1c2m_0730 = ""
            this.tbb_1c2m_0730 = ""
            this.rh_1c2m_0730 = ""
            this.counter_sebelum_0c5_0730 = ""
            this.counter_0c5_0730 = ""
            this.ws_avg_0c5_0730 = ""
            this.ws_10_0730 = ""
            this.op_diff_baca_0730 = ""
            this.h_air_0730 = ""
            this.h_air_diff_0730 = ""
            this.time_kuras_0730 = ""
            this.h_air_setelah_kuras_0730 = ""
            this.h_air_sebelum_kuras_0730 = ""
            this.h_air_diff_setelah_kuras_0730 = ""
            this.h_air_diff_sebelum_kuras_0730 = ""
            this.op_kuras_time_temp = '0730ws'
            this.time_kuras_0730_temp = "",
            this.h_air_setelah_kuras_0730_temp = "",
            this.h_air_sebelum_kuras_0730_temp = "",
            this.h_air_diff_setelah_kuras_0730_temp = "",
            this.h_air_diff_sebelum_kuras_0730_temp = "",
            this.t_air_max_0730 = ""
            this.t_air_min_0730 = ""
            this.t_air_avg_0730 = ""
            this.rr_0730 = ""
            this.op_eva_0730 = ""
            this.tbk_5cm_0730 = ""
            this.tbk_10cm_0730 = ""
            this.tbk_20cm_0730 = ""
            this.tbk_50cm_0730 = ""
            this.tbk_100cm_0730 = ""
            this.tbk_150cm_0730 = ""
            this.tbk_200cm_0730 = ""
            this.tbb_5cm_0730 = ""
            this.tbb_10cm_0730 = ""
            this.tbb_20cm_0730 = ""
            this.tbb_50cm_0730 = ""
            this.tbb_100cm_0730 = ""
            this.tbb_150cm_0730 = ""
            this.tbb_200cm_0730 = ""
            this.rh_5cm_0730 = ""
            this.rh_10cm_0730 = ""
            this.rh_20cm_0730 = ""
            this.rh_50cm_0730 = ""
            this.rh_100cm_0730 = ""
            this.rh_150cm_0730 = ""
            this.rh_200cm_0730 = ""
            this.pitche_sebelum_0730 = ""
            this.h_piche_0730 = ""
            this.eva_piche_0730 = ""
            this.h_piche_ditambah_0730 = ""
            this.ket_tanah_0730 = ''
            this.ket_hujan_0730 = ''
            this.ket_1b_0730 = ''
            this.ttg_0cm_0730 = ""
            this.ttg_2cm_0730 = ""
            this.ttg_5cm_0730 = ""
            this.ttg_10cm_0730 = ""
            this.ttg_20cm_0730 = ""
            this.ttg_50cm_0730 = ""
            this.ttg_100cm_0730 = ""
            this.ttr_0cm_0730 = ""
            this.ttr_2cm_0730 = ""
            this.ttr_5cm_0730 = ""
            this.ttr_10cm_0730 = ""
            this.ttr_20cm_0730 = ""
            this.ttr_100cm_0730 = ""
            this.ttr_50cm_0730 = ""
            this.flagdata_agm1b = null
            this.flagdata_psychrometer_assman = null
            this.flagdata_suhu_tanah = null

            this.is_counter_edit = false
            this.is_op_edit = false
            this.is_piche_edit = false
            this.LIST_QC_FIX = {}
            this.LIST_QC_NOTIFICATION_FIX = {}

            this.$v.$reset()
        },
        onOk() {
            
            if (this.op_kuras_time == "antara") {
                if (this.time_kuras_0730 == "" || this.h_air_sebelum_kuras_0730 == "" || this.h_air_setelah_kuras_0730 == "") {
                    let message = ""
                    if (this.time_kuras_0730 == "") {
                        message = 'Isi field "Jam" pada OP Kuras'
                    } 
                    else if (this.h_air_sebelum_kuras_0730 == "") {
                        message = 'Isi field "Tinggi Air Sebelum Kuras" pada OP Kuras'
                    }
                    else if (this.h_air_setelah_kuras_0730 == "") {
                        message = 'Isi field "Tinggi Air Setelah Kuras" pada OP Kuras'
                    }
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: message,
                            icon: 'BellIcon',
                            variant:'danger',
                        },
                    })
                    return
                }
                else {
                    
                    // this.h_air_diff_0730 = ((parseFloat(this.op_diff_baca_0730) - parseFloat(this.h_air_sebelum_kuras_0730)) + (parseFloat(this.h_air_setelah_kuras_0730) - parseFloat(this.h_air_0730))).toFixed(1)
                    // this.op_eva_0730 = (parseFloat(this.h_air_diff_0730) + parseFloat(this.rr_0730)).toFixed(1)
                    this.h_air_diff_0730 = parseFloat((+this.op_diff_baca_0730 - this.h_air_sebelum_kuras_0730) + (+this.h_air_setelah_kuras_0730 - this.h_air_0730)).toFixed(1)
                    this.op_eva_0730 = parseFloat(+this.h_air_diff_0730 + +this.rr_0730).toFixed(1)
                    this.op_kuras_time_temp = this.op_kuras_time
                    this.time_kuras_0730_temp = this.time_kuras_0730
                    this.h_air_setelah_kuras_0730_temp = this.h_air_setelah_kuras_0730
                    this.h_air_sebelum_kuras_0730_temp = this.h_air_sebelum_kuras_0730
                    this.h_air_diff_setelah_kuras_0730_temp = this.h_air_diff_setelah_kuras_0730
                    this.h_air_diff_sebelum_kuras_0730_temp = this.h_air_diff_sebelum_kuras_0730
                    this.isPengurasan = true
                }
                
            }
            else {
                if (this.h_air_setelah_kuras_0730 == "") {
                    message = 'Isi field "Tinggi Air Setelah Kuras" pada OP Kuras'

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: message,
                            icon: 'BellIcon',
                            variant:'danger',
                        },
                    })

                    return
                }
                else {
                    
                    this.h_air_diff_0730 = (parseFloat(this.op_diff_baca_0730) - parseFloat(this.h_air_0730)).toFixed(1)
                    this.op_kuras_time_temp = this.op_kuras_time
                    this.time_kuras_0730_temp = this.time_kuras_0730
                    this.h_air_setelah_kuras_0730_temp = this.h_air_setelah_kuras_0730
                    this.h_air_sebelum_kuras_0730_temp = this.h_air_sebelum_kuras_0730
                    this.h_air_diff_setelah_kuras_0730_temp = this.h_air_diff_setelah_kuras_0730
                    this.h_air_diff_sebelum_kuras_0730_temp = this.h_air_diff_sebelum_kuras_0730
                    this.isPengurasan = true
                }
                
            }
            // this.popoverShow = false
            this.$refs['modal-op-kuras-0730'].hide()
        },

        onCancel() {
            // console.log('onCancel-cross')
            this.h_air_setelah_kuras_0730 = this.h_air_setelah_kuras_0730_temp

            if (this.isPengurasan) {
                this.op_kuras_time = this.op_kuras_time_temp
                this.time_kuras_0730 = this.time_kuras_0730_temp
                this.h_air_sebelum_kuras_0730 = this.h_air_sebelum_kuras_0730_temp
                this.h_air_diff_sebelum_kuras_0730 = this.h_air_diff_sebelum_kuras_0730_temp
                this.h_air_diff_setelah_kuras_0730 = this.h_air_diff_setelah_kuras_0730_temp
            }
            else {
                this.op_kuras_time = this.op_kuras_time_temp
                this.time_kuras_0730 = ""
                this.h_air_sebelum_kuras_0730 = ""
                this.h_air_diff_sebelum_kuras_0730 = ""
                this.h_air_diff_setelah_kuras_0730 = ""
            }
            
            this.$refs['modal-op-kuras-0730'].hide()
        },

        onReset() {
            this.$swal({
                title: "Reset OP Kuras",
                text: "Anda yakin untuk melakukan reset OP Kuras pada OP Penguapan jam 07.30 ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Ya",
                cancelButtonText: "Tidak",
                allowOutsideClick: false,
                allowEscapeKey: false,
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-secondary ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    this.op_kuras_time = '0730ws'
                    this.time_kuras_0730 = ""
                    this.h_air_sebelum_kuras_0730 = ""
                    this.h_air_setelah_kuras_0730 = ""
                    this.h_air_diff_sebelum_kuras_0730 = ""
                    this.h_air_diff_setelah_kuras_0730 = ""
                    this.h_air_diff_0730 = (this.op_diff_baca_0730 - this.h_air_0730).toFixed(1)
                    this.isPengurasan = false
                    this.time_kuras_0730_temp = ""
                    this.h_air_setelah_kuras_0730_temp = ""
                    this.h_air_sebelum_kuras_0730_temp = ""
                    this.h_air_diff_setelah_kuras_0730_temp = ""
                    this.h_air_diff_sebelum_kuras_0730_temp = ""
                    this.$refs['modal-op-kuras-0730'].hide()
                } else if (result.dismiss === "cancel") {
                    // console.log('cancel reset', result)
                    return;
                }
            });
        },

        checkValidateAll() {
            this.checkValidate({target: {value: this.op_diff_baca_0730}}, "op_diff_baca_0730")
            this.checkValidate({target: {value: this.t_air_max_0730}}, "t_air_max_0730")
            this.checkValidate({target: {value: this.t_air_min_0730}}, "t_air_min_0730")
            this.checkValidate({target: {value: this.rr_0730}}, "rr_0730")
            this.checkValidate({target: {value: this.tbk_5cm_0730}}, "tbk_5cm_0730")
            this.checkValidate({target: {value: this.tbk_10cm_0730}}, "tbk_10cm_0730")
            this.checkValidate({target: {value: this.tbk_20cm_0730}}, "tbk_20cm_0730")
            this.checkValidate({target: {value: this.tbk_50cm_0730}}, "tbk_50cm_0730")
            this.checkValidate({target: {value: this.tbk_100cm_0730}}, "tbk_100cm_0730")
            this.checkValidate({target: {value: this.tbk_150cm_0730}}, "tbk_150cm_0730")
            this.checkValidate({target: {value: this.tbk_200cm_0730}}, "tbk_200cm_0730")
            this.checkValidate({target: {value: this.tbb_5cm_0730}}, "tbb_5cm_0730")
            this.checkValidate({target: {value: this.tbb_10cm_0730}}, "tbb_10cm_0730")
            this.checkValidate({target: {value: this.tbb_20cm_0730}}, "tbb_20cm_0730")
            this.checkValidate({target: {value: this.tbb_50cm_0730}}, "tbb_50cm_0730")
            this.checkValidate({target: {value: this.tbb_100cm_0730}}, "tbb_100cm_0730")
            this.checkValidate({target: {value: this.tbb_150cm_0730}}, "tbb_150cm_0730")
            this.checkValidate({target: {value: this.tbb_200cm_0730}}, "tbb_200cm_0730")
            this.checkValidate({target: {value: this.ttr_0cm_0730}}, "ttr_0cm_0730")
            this.checkValidate({target: {value: this.ttr_2cm_0730}}, "ttr_2cm_0730")
            this.checkValidate({target: {value: this.ttr_5cm_0730}}, "ttr_5cm_0730")
            this.checkValidate({target: {value: this.ttr_10cm_0730}}, "ttr_10cm_0730")
            this.checkValidate({target: {value: this.ttr_20cm_0730}}, "ttr_20cm_0730")
            this.checkValidate({target: {value: this.ttr_50cm_0730}}, "ttr_50cm_0730")
            this.checkValidate({target: {value: this.ttr_100cm_0730}}, "ttr_100cm_0730")
            this.checkValidate({target: {value: this.ttg_0cm_0730}}, "ttg_0cm_0730")
            this.checkValidate({target: {value: this.ttg_2cm_0730}}, "ttg_2cm_0730")
            this.checkValidate({target: {value: this.ttg_5cm_0730}}, "ttg_5cm_0730")
            this.checkValidate({target: {value: this.ttg_10cm_0730}}, "ttg_10cm_0730")
            this.checkValidate({target: {value: this.ttg_20cm_0730}}, "ttg_20cm_0730")
            this.checkValidate({target: {value: this.ttg_50cm_0730}}, "ttg_50cm_0730")
            this.checkValidate({target: {value: this.ttg_100cm_0730}}, "ttg_100cm_0730")
        },

        onOk2() {
            this.popoverShow2 = false
        }
    },

    watch: {
        
        data_input: {
            deep: true,
            handler() {
                // console.log('data_input: ',this.data_input.i_time)
                if(this.data_input.i_time == '0730ws') {
                    this.clearData()
                }
                
            }
        },

        t_bmkgentry: function(val) {
            this.initialize()
        },

        t_all_data: function (val) {
            // console.log('t_all_data 0730', val)
            this.flagdata_agm1b = val["BmkgSatu.behaviors.qc.IFdih_Agm1b_qc"].qc_flag_agm1b
            this.flagdata_psychrometer_assman = val["BmkgSatu.behaviors.qc.IFdih_PsychrometerAssman_qc"].qc_flag_psychrometer_assman
            this.flagdata_suhu_tanah = val["BmkgSatu.behaviors.qc.IFdih_SuhuTanah_qc"].qc_flag_suhu_tanah
        },
        
        t_data_requirement: function(val) {
            // console.log('t_data_requirement: ', val)
            // this.counter_sebelum_0c5_0730 = val.fdih_ws0730_counter_0c5_0730 == 'null' ? 0 : val.fdih_ws0730_counter_0c5_0730

            if (val.fdih_ws0730_counter_0c5_0730 != "null" && val.fdih_ws0730_counter_0c5_0730 != null ) {
                if (this.counter_sebelum_0c5_0730 === null || this.counter_sebelum_0c5_0730 === "null" || this.counter_sebelum_0c5_0730 === "" ) {
                    this.counter_sebelum_0c5_0730 = val.fdih_ws0730_counter_0c5_0730;
                }
            }
            
            if(val.fdih_ws0730_h_air_setelah_kuras_0730 != 'null' && val.fdih_ws0730_h_air_setelah_kuras_0730 != "") {
                this.op_diff_baca_0730 = val.fdih_ws0730_h_air_setelah_kuras_0730
            }
            else {
                this.op_diff_baca_0730 = val.fdih_ws0730_h_air_0730 == 'null' ? 0 : val.fdih_ws0730_h_air_0730
            }

            // if(val.fdih_ws1730_time_kuras_1730 != "") {
            //     this.op_diff_baca_0730 = val.fdih_ws0730_h_air_0730 == 'null' ? 0 : val.fdih_ws0730_h_air_0730
            // }
            // else {
            //     if(val.fdih_ws0730_h_air_setelah_kuras_0730 != 0 && val.fdih_ws0730_h_air_setelah_kuras_0730 != 'null' && val.fdih_ws0730_h_air_setelah_kuras_0730 != "") {
            //         this.op_diff_baca_0730 = val.fdih_ws0730_h_air_setelah_kuras_0730 == 'null' ? 0 : val.fdih_ws0730_h_air_setelah_kuras_0730
            //     }
            //     else {
            //         this.op_diff_baca_0730 = val.fdih_ws0730_h_air_0730 == 'null' ? 0 : val.fdih_ws0730_h_air_0730
            //     }
            // }
            // if(val.fdih_ws0730_h_air_setelah_kuras_0730 != 0 && val.fdih_ws0730_h_air_setelah_kuras_0730 != 'null' && val.fdih_ws0730_h_air_setelah_kuras_0730 != "") {
            //     this.op_diff_baca_0730 = val.fdih_ws0730_h_air_setelah_kuras_0730 == 'null' ? 0 : val.fdih_ws0730_h_air_setelah_kuras_0730
            // }
            // else {
            //     this.op_diff_baca_0730 = val.fdih_ws0730_h_air_0730 == 'null' ? 0 : val.fdih_ws0730_h_air_0730
            // }
            
            // if (val.fdih_ws1730_h_piche_ditambah_1730 != 'null' && val.fdih_ws1730_h_piche_ditambah_1730 != 0){
            //     this.pitche_sebelum_0730 = val.fdih_ws1730_h_piche_ditambah_1730 == 'null' ? 0 : val.fdih_ws1730_h_piche_ditambah_1730    
            // }
            // else {
            //     this.pitche_sebelum_0730 = val.fdih_ws1730_h_piche_1730
            // }
            if (val.fdih_ws1730_h_piche_ditambah_1730 != "null" && val.fdih_ws1730_h_piche_ditambah_1730 != null && val.fdih_ws1730_h_piche_ditambah_1730 != "") {
                if (this.pitche_sebelum_0730 === null || this.pitche_sebelum_0730 === "null" || this.pitche_sebelum_0730 === "" ) {
                    this.pitche_sebelum_0730 = val.fdih_ws1730_h_piche_ditambah_1730;
                }
            }
            else {
                this.pitche_sebelum_0730 = (val.fdih_ws1730_h_piche_1730 != null && val.fdih_ws1730_h_piche_1730 != "null") ? val.fdih_ws1730_h_piche_1730 : ""
            }

            this.$v.$reset();
            this.checkValidateAll()
            if (this.t_data_status == "update") {
                this.$v.$touch();
            }
        },
        t_data_existing: function(val) {
            // console.log('t_data_existing: ', val.time_kuras_0730, val.h_air_setelah_kuras_0730)

            this.isPengurasan = false
            this.op_kuras_time = "0730ws"

            this.time_kuras_0730 = val.time_kuras_0730
            this.time_kuras_0730_temp = this.time_kuras_0730
            
            if (this.time_kuras_0730 && this.time_kuras_0730 != "") {
                this.isPengurasan = true
                this.op_kuras_time = "antara"
                this.op_kuras_time_temp = this.op_kuras_time
                
            }
            else {
                if (val.h_air_setelah_kuras_0730 && val.h_air_setelah_kuras_0730 != "") {
                    this.isPengurasan = true
                    this.op_kuras_time = "0730ws"
                    this.op_kuras_time_temp = this.op_kuras_time
                }
            }
            
            this.tbk_1c2m_0730 = val.tbk_1c2m_0730
            this.tbb_1c2m_0730 = val.tbb_1c2m_0730
            this.rh_1c2m_0730 = val.rh_1c2m_0730
            this.counter_sebelum_0c5_0730 = val.counter_sebelum_0c5_0730
            this.counter_0c5_0730 = val.counter_0c5_0730
            this.ws_avg_0c5_0730 = val.ws_avg_0c5_0730
            this.ws_10_0730 = val.ws_10_0730
            
            this.op_diff_baca_0730 = val.op_diff_baca_0730
            this.h_air_0730 = val.h_air_0730
            this.h_air_diff_0730 = val.h_air_diff_0730
            
            this.t_air_max_0730 = val.t_air_max_0730
            this.t_air_min_0730 = val.t_air_min_0730
            this.t_air_avg_0730 = val.t_air_avg_0730
            this.rr_0730 = val.rr_0730
            this.op_eva_0730 = val.op_eva_0730
            this.tbk_5cm_0730 = val.tbk_5cm_0730
            this.tbk_10cm_0730 = val.tbk_10cm_0730
            this.tbk_20cm_0730 = val.tbk_20cm_0730
            this.tbk_50cm_0730 = val.tbk_50cm_0730
            this.tbk_100cm_0730 = val.tbk_100cm_0730
            this.tbk_150cm_0730 = val.tbk_150cm_0730
            this.tbk_200cm_0730 = val.tbk_200cm_0730
            this.tbb_5cm_0730 = val.tbb_5cm_0730
            this.tbb_10cm_0730 = val.tbb_10cm_0730
            this.tbb_20cm_0730 = val.tbb_20cm_0730
            this.tbb_50cm_0730 = val.tbb_50cm_0730
            this.tbb_100cm_0730 = val.tbb_100cm_0730
            this.tbb_150cm_0730 = val.tbb_150cm_0730
            this.tbb_200cm_0730 = val.tbb_200cm_0730
            this.rh_5cm_0730 = val.rh_5cm_0730
            this.rh_10cm_0730 = val.rh_10cm_0730
            this.rh_20cm_0730 = val.rh_20cm_0730
            this.rh_50cm_0730 = val.rh_50cm_0730
            this.rh_100cm_0730 = val.rh_100cm_0730
            this.rh_150cm_0730 = val.rh_150cm_0730
            this.rh_200cm_0730 = val.rh_200cm_0730
            this.pitche_sebelum_0730 = val.pitche_sebelum_0730
            this.h_piche_0730 = val.h_piche_0730
            this.eva_piche_0730 = val.eva_piche_0730
            this.h_piche_ditambah_0730 = val.h_piche_ditambah_0730
            this.ket_tanah_0730 = val.ket_tanah_0730
            this.ket_hujan_0730 = val.ket_hujan_0730
            this.ket_1b_0730 = val.ket_1b_0730
            this.ttg_0cm_0730 = val.ttg_0cm_0730
            this.ttg_2cm_0730 = val.ttg_2cm_0730
            this.ttg_5cm_0730 = val.ttg_5cm_0730
            this.ttg_10cm_0730 = val.ttg_10cm_0730
            this.ttg_20cm_0730 = val.ttg_20cm_0730
            this.ttg_50cm_0730 = val.ttg_50cm_0730
            this.ttg_100cm_0730 = val.ttg_100cm_0730
            this.ttr_0cm_0730 = val.ttr_0cm_0730
            this.ttr_2cm_0730 = val.ttr_2cm_0730
            this.ttr_5cm_0730 = val.ttr_5cm_0730
            this.ttr_10cm_0730 = val.ttr_10cm_0730
            this.ttr_20cm_0730 = val.ttr_20cm_0730
            this.ttr_100cm_0730 = val.ttr_100cm_0730
            this.ttr_50cm_0730 = val.ttr_50cm_0730

            

            this.h_air_setelah_kuras_0730 = val.h_air_setelah_kuras_0730
            this.h_air_sebelum_kuras_0730 = val.h_air_sebelum_kuras_0730
            this.h_air_diff_setelah_kuras_0730 = val.h_air_diff_setelah_kuras_0730
            this.h_air_diff_sebelum_kuras_0730 = val.h_air_diff_sebelum_kuras_0730

            this.h_air_setelah_kuras_0730_temp = this.h_air_setelah_kuras_0730
            this.h_air_sebelum_kuras_0730_temp = this.h_air_sebelum_kuras_0730
            this.h_air_diff_setelah_kuras_0730_temp = this.h_air_diff_setelah_kuras_0730
            this.h_air_diff_sebelum_kuras_0730_temp = this.h_air_diff_sebelum_kuras_0730

            // tambahan dari Pargol
            this.$v.$touch();
          
        },
        
        tbk_1c2m_0730: function(val) {
            if(val == 9999) {
                this.rh_1c2m_0730 = 9999
            }
            else {
                var Es = Helper.rumus_Es(this.tbb_1c2m_0730, val)
                var E = Helper.rumus_E(val).toFixed(2)
                this.rh_1c2m_0730 = this.tbb_1c2m_0730 == 9999 ? 9999 : Math.round((Es / E) * 100);//.toFixed(1);
            }
        },
        tbb_1c2m_0730: function(val) {
            if(val == 9999) {
                this.rh_1c2m_0730 = 9999
            }
            else {
                var Es = Helper.rumus_Es(val, this.tbk_1c2m_0730);
                var E = Helper.rumus_E(this.tbk_1c2m_0730).toFixed(2);
                this.rh_1c2m_0730 = this.tbk_1c2m_0730 == 9999 ? 9999 : Math.round((Es / E) * 100);
            }
        },

        is_counter_edit: function(val) {
            if (!val) {
                if (this.t_data_existing) {
                    this.counter_sebelum_0c5_0730 = this.t_data_existing.counter_sebelum_0c5_0730
                }
                if (this.t_data_requirement) {
                    this.counter_sebelum_0c5_0730 = this.t_data_requirement.fdih_ws0730_counter_0c5_0730 == 'null' ? 0 : this.t_data_requirement.fdih_ws0730_counter_0c5_0730
                }

                if (this.counter_0c5_0730 != 9999 && this.counter_sebelum_0c5_0730 != 9999) {
                    this.ws_avg_0c5_0730 = parseFloat(((this.counter_0c5_0730 - this.counter_sebelum_0c5_0730) / 14).toFixed(3)).toFixed(2)
                }
            }
        },

        counter_sebelum_0c5_0730: function(val) {
            if (val == 9999) {
                this.ws_avg_0c5_0730 = 9999
            }
            else {
                this.ws_avg_0c5_0730 = this.counter_0c5_0730 == 9999 ? 9999 : parseFloat(((this.counter_0c5_0730 - val) / 14).toFixed(3)).toFixed(2)
            }
        },

        counter_0c5_0730: function(val) {
            if (val == 9999 ) {
                this.ws_avg_0c5_0730 = 9999
            }
            else {
                this.ws_avg_0c5_0730 = this.counter_sebelum_0c5_0730 == 9999 ? 9999 : parseFloat(((val - this.counter_sebelum_0c5_0730) / 14).toFixed(3)).toFixed(2)
            }
        },

        tbk_5cm_0730: function(val) {
            if(val == 9999) {
                this.rh_5cm_0730 = 9999
            }
            else {
                var Es = Helper.rumus_Es(this.tbb_5cm_0730, val)
                var E = Helper.rumus_E(val).toFixed(2)
                this.rh_5cm_0730 = this.tbb_5cm_0730 == 9999 ? 9999 : Math.round((Es / E) * 100);//.toFixed(1);
            }
        },
        tbb_5cm_0730: function(val) {
            if(val == 9999) {
                this.rh_5cm_0730 = 9999
            }
            else {
                var Es = Helper.rumus_Es(val, this.tbk_5cm_0730)
                var E = Helper.rumus_E(this.tbk_5cm_0730).toFixed(2)
                this.rh_5cm_0730 = this.tbk_5cm_0730 == 9999 ? 9999 : Math.round((Es / E) * 100);//.toFixed(1);
            }
        },
        tbk_10cm_0730: function(val) {
            if(val == 9999) {
                this.rh_10cm_0730 = 9999
            }
            else {
                var Es = Helper.rumus_Es(this.tbb_10cm_0730, val)
                var E = Helper.rumus_E(val).toFixed(2)
                this.rh_10cm_0730 = this.tbb_10cm_0730 == 9999 ? 9999 : Math.round((Es / E) * 100);//.toFixed(1);
            }
        },
        tbb_10cm_0730: function(val) {
            if(val == 9999) {
                this.rh_10cm_0730 = 9999
            }
            else {
                var Es = Helper.rumus_Es(val, this.tbk_10cm_0730)
                var E = Helper.rumus_E(this.tbk_10cm_0730).toFixed(2)
                this.rh_10cm_0730 = this.tbk_10cm_0730 == 9999 ? 9999 : Math.round((Es / E) * 100);//.toFixed(1);
            }
        },
        tbk_20cm_0730: function(val) {
            if(val == 9999) {
                this.rh_20cm_0730 = 9999
            }
            else {
                var Es = Helper.rumus_Es(this.tbb_20cm_0730, val)
                var E = Helper.rumus_E(val).toFixed(2)
                this.rh_20cm_0730 = this.tbb_20cm_0730 == 9999 ? 9999 : Math.round((Es / E) * 100);//.toFixed(1);
            }
        },
        tbb_20cm_0730: function(val) {
            if(val == 9999) {
                this.rh_20cm_0730 = 9999
            }
            else {
                var Es = Helper.rumus_Es(val, this.tbk_20cm_0730)
                var E = Helper.rumus_E(this.tbk_20cm_0730).toFixed(2)
                this.rh_20cm_0730 = this.tbk_20cm_0730 == 9999 ? 9999 : Math.round((Es / E) * 100);//.toFixed(1);
            }
        },
        tbk_50cm_0730: function(val) {
            if(val == 9999) {
                this.rh_50cm_0730 = 9999
            }
            else {
                var Es = Helper.rumus_Es(this.tbb_50cm_0730, val)
                var E = Helper.rumus_E(val).toFixed(2)
                this.rh_50cm_0730 = this.tbb_50cm_0730 == 9999 ? 9999 : Math.round((Es / E) * 100);//.toFixed(1);
            }
        },
        tbb_50cm_0730: function(val) {
            if(val == 9999) {
                this.rh_50cm_0730 = 9999
            }
            else {
                var Es = Helper.rumus_Es(val, this.tbk_50cm_0730)
                var E = Helper.rumus_E(this.tbk_50cm_0730).toFixed(2)
                this.rh_50cm_0730 = this.tbk_50cm_0730 == 9999 ? 9999 : Math.round((Es / E) * 100);//.toFixed(1);
            }
        },
        tbk_100cm_0730: function(val) {
            if(val == 9999) {
                this.rh_100cm_0730 = 9999
            }
            else {
                var Es = Helper.rumus_Es(this.tbb_100cm_0730, val)
                var E = Helper.rumus_E(val).toFixed(2)
                this.rh_100cm_0730 = this.tbb_100cm_0730 == 9999 ? 9999 : Math.round((Es / E) * 100);//.toFixed(1);
            }
        },
        tbb_100cm_0730: function(val) {
            if(val == 9999) {
                this.rh_100cm_0730 = 9999
            }
            else {
                var Es = Helper.rumus_Es(val, this.tbk_100cm_0730)
                var E = Helper.rumus_E(this.tbk_100cm_0730).toFixed(2)
                this.rh_100cm_0730 = this.tbk_100cm_0730 == 9999 ? 9999 : Math.round((Es / E) * 100);//.toFixed(1);
            }
        },
        tbk_150cm_0730: function(val) {
            if(val == 9999) {
                this.rh_150cm_0730 = 9999
            }
            else {
                var Es = Helper.rumus_Es(this.tbb_150cm_0730, val)
                var E = Helper.rumus_E(val).toFixed(2)
                this.rh_150cm_0730 = this.tbb_150cm_0730 == 9999 ? 9999 : Math.round((Es / E) * 100);//.toFixed(1);
            }
        },
        tbb_150cm_0730: function(val) {
            if(val == 9999) {
                this.rh_150cm_0730 = 9999
            }
            else {
                var Es = Helper.rumus_Es(val, this.tbk_150cm_0730)
                var E = Helper.rumus_E(this.tbk_150cm_0730).toFixed(2)
                this.rh_150cm_0730 = this.tbk_150cm_0730 == 9999 ? 9999 : Math.round((Es / E) * 100);//.toFixed(1);
            }
        },
        tbk_200cm_0730: function(val) {
            if(val == 9999) {
                this.rh_200cm_0730 = 9999
            }
            else {
                var Es = Helper.rumus_Es(this.tbb_200cm_0730, val)
                var E = Helper.rumus_E(val).toFixed(2)
                this.rh_200cm_0730 = this.tbb_200cm_0730 == 9999 ? 9999 : Math.round((Es / E) * 100);//.toFixed(1);
            }
        },
        tbb_200cm_0730: function(val) {
            if(val == 9999) {
                this.rh_200cm_0730 = 9999
            }
            else {
                var Es = Helper.rumus_Es(val, this.tbk_200cm_0730)
                var E = Helper.rumus_E(this.tbk_200cm_0730).toFixed(2)
                this.rh_200cm_0730 = this.tbk_200cm_0730 == 9999 ? 9999 : Math.round((Es / E) * 100);//.toFixed(1);
            }
        },

        is_op_edit: function(val) {
            if (!val) {
                if (this.t_data_existing) {
                    // this.ha_gunbellani_2_kemarin = this.t_data_existing.h_air_gunbellani_2_kemarin;
                    this.op_diff_baca_0730 = this.t_data_existing.op_diff_baca_0730
                }
                if (this.t_data_requirement) {
                    // this.ha_gunbellani_2_kemarin = this.t_data_requirement.fdih_ws0700_h_air_gunbellani_2_kemarin == "null" ? 0 : this.t_data_requirement.fdih_ws0700_h_air_gunbellani_2_kemarin;
                    if(this.t_data_requirement.fdih_ws0730_h_air_setelah_kuras_0730 != 'null' && this.t_data_requirement.fdih_ws0730_h_air_setelah_kuras_0730 != "") {
                        this.op_diff_baca_0730 = this.t_data_requirement.fdih_ws0730_h_air_setelah_kuras_0730
                    }
                    else {
                        this.op_diff_baca_0730 = this.t_data_requirement.fdih_ws0730_h_air_0730 == 'null' ? 0 : this.t_data_requirement.fdih_ws0730_h_air_0730
                    }
                }
            }
        },

        op_diff_baca_0730: function(val) {
            if (val == 9999) {
                this.h_air_diff_sebelum_kuras_0730 = 9999
                this.h_air_diff_0730 = 9999
                this.op_eva_0730 = 9999
            }
            else {
                if(this.time_kuras_0730 != "") {
                    this.h_air_diff_setelah_kuras_0730 = this.h_air_0730 == 9999 ? 9999 : (parseFloat(this.h_air_setelah_kuras_0730) - parseFloat(this.h_air_0730)).toFixed(1)
                    this.h_air_diff_sebelum_kuras_0730 = val == 9999 ? 9999 : (parseFloat(val) - parseFloat(this.h_air_sebelum_kuras_0730)).toFixed(1)
                }

                if (this.h_air_0730 == 9999) {
                    this.op_eva_0730 = 9999
                    this.h_air_diff_0730 = 9999
                }
                else {

                    if (this.isPengurasan) {
                        if (this.op_kuras_time == "antara") {
                            this.h_air_diff_0730 = ((parseFloat(val) - parseFloat(this.h_air_sebelum_kuras_0730)) + (parseFloat(this.h_air_setelah_kuras_0730) - parseFloat(this.h_air_0730))).toFixed(1)
                        }
                        else {
                            this.h_air_diff_0730 = (val - this.h_air_0730).toFixed(1)
                        }
                    }
                    else {
                        this.h_air_diff_0730 = (val - this.h_air_0730).toFixed(1)
                    }
        
                    // this.op_eva_0730 = (val - parseFloat(this.h_air_0730) + parseFloat(this.rr_0730)).toFixed(1)
                    // this.op_eva_0730 = (parseFloat(this.h_air_diff_0730) + parseFloat(this.rr_0730)).toFixed(1)
                    this.op_eva_0730 = this.rr_0730 == 9999 ? 9999 : this.rr_0730 == 8888 ? (parseFloat(this.h_air_diff_0730)).toFixed(1) : (parseFloat(this.h_air_diff_0730) + parseFloat(this.rr_0730)).toFixed(1)
                }
            }
        },

        t_air_max_0730: function(val) {
            if (this.t_air_min_0730 == 9999) {
                this.t_air_avg_0730 = 9999
            }
            else {
                this.t_air_avg_0730 = val == 9999 ? 9999 : parseFloat((+val + +this.t_air_min_0730) / 2).toFixed(1)
            }
        },

        t_air_min_0730: function(val) {
            if (this.t_air_max_0730 == 9999) {
                this.t_air_avg_0730 = 9999
            }
            else {
                this.t_air_avg_0730 = val == 9999 ? 9999 : parseFloat((+this.t_air_max_0730 + +val) / 2).toFixed(1)
            }
        },

        time_kuras_0730: function(val) {
            if(this.time_kuras_0730 != "") {
                // this.h_air_diff_setelah_kuras_0730 = this.h_air_0730 == 9999 ? 9999 : (parseFloat(this.h_air_setelah_kuras_0730) - parseFloat(this.h_air_0730 && this.h_air_0730 != "" ? this.h_air_0730 : 0)).toFixed(1)
                // this.h_air_diff_sebelum_kuras_0730 = this.op_diff_baca_0730 == 9999 ? 9999 : (parseFloat(this.op_diff_baca_0730) - parseFloat(this.h_air_sebelum_kuras_0730)).toFixed(1)
                this.h_air_diff_setelah_kuras_0730 = this.h_air_0730 == 9999 ? 9999 : parseFloat(this.h_air_setelah_kuras_0730 - this.h_air_0730).toFixed(1)
                this.h_air_diff_sebelum_kuras_0730 = this.op_diff_baca_0730 == 9999 ? 9999 : parseFloat(this.op_diff_baca_0730 - this.h_air_sebelum_kuras_0730).toFixed(1)
            }
            else {
                this.h_air_diff_setelah_kuras_0730 = ""
                this.h_air_diff_sebelum_kuras_0730 = ""
            }
        },

        h_air_sebelum_kuras_0730: function(val) {
            if(this.time_kuras_0730 != "") {
                // this.h_air_diff_setelah_kuras_0730 = this.h_air_0730 == 9999 ? 9999 : (parseFloat(this.h_air_setelah_kuras_0730) - parseFloat(this.h_air_0730 && this.h_air_0730 != "" ? this.h_air_0730 : 0)).toFixed(1)
                // this.h_air_diff_sebelum_kuras_0730 = this.op_diff_baca_0730 == 9999 ? 9999 : (parseFloat(this.op_diff_baca_0730) - parseFloat(val)).toFixed(1)
                this.h_air_diff_setelah_kuras_0730 = this.h_air_0730 == 9999 ? 9999 : parseFloat(this.h_air_setelah_kuras_0730 - this.h_air_0730 ).toFixed(1)
                this.h_air_diff_sebelum_kuras_0730 = this.op_diff_baca_0730 == 9999 ? 9999 : parseFloat(this.op_diff_baca_0730 - val).toFixed(1)
            }
            else {
                this.h_air_diff_setelah_kuras_0730 = ""
                this.h_air_diff_sebelum_kuras_0730 = ""
            }
        },
        h_air_setelah_kuras_0730: function(val) {
            if(this.time_kuras_0730 != "") {
                // this.h_air_diff_setelah_kuras_0730 = this.h_air_0730 == 9999 ? 9999 : parseFloat((parseFloat(val && val != ""? val : 0 ) - parseFloat(this.h_air_0730))).toFixed(1)
                // this.h_air_diff_sebelum_kuras_0730 = this.op_diff_baca_0730 == 9999 ? 9999 : (parseFloat(this.op_diff_baca_0730) - parseFloat(this.h_air_sebelum_kuras_0730)).toFixed(1)
                this.h_air_diff_setelah_kuras_0730 = this.h_air_0730 == 9999 ? 9999 : parseFloat(val - this.h_air_0730).toFixed(1)
                this.h_air_diff_sebelum_kuras_0730 = this.op_diff_baca_0730 == 9999 ? 9999 : parseFloat(this.op_diff_baca_0730 - this.h_air_sebelum_kuras_0730).toFixed(1)
            }
            else {
                this.h_air_diff_setelah_kuras_0730 = ""
                this.h_air_diff_sebelum_kuras_0730 = ""
            }     
        },
        op_kuras_time: function(val) {
            
            if (this.isPengurasan) {
                if (val != 'antara') {
                    this.time_kuras_0730 = ""
                    this.h_air_sebelum_kuras_0730 = ""
                    this.h_air_diff_setelah_kuras_0730 = ""
                    this.h_air_diff_sebelum_kuras_0730 = ""
                }
                else {

                }
            }

        },
        
        h_air_0730: function(val) {
            // console.log('h_air_0730', '-'+this.op_kuras_time, '-'+this.isPengurasan, '-'+this.op_diff_baca_0730, '-'+this.h_air_sebelum_kuras_0730, '-'+this.h_air_setelah_kuras_0730, '-'+this.h_air_0730)
            if (val == 9999) {
                this.op_eva_0730 = 9999
                this.h_air_diff_0730 = 9999
            }
            else {
                if (this.isPengurasan) {
                    if (this.op_kuras_time == "antara") {
                        // this.h_air_diff_0730 = ((parseFloat(this.op_diff_baca_0730) - parseFloat(this.h_air_sebelum_kuras_0730)) + (parseFloat(this.h_air_setelah_kuras_0730) - parseFloat(this.h_air_0730))).toFixed(1)
                        // this.h_air_diff_setelah_kuras_0730 = this.h_air_0730 == 9999 ? 9999 : (parseFloat(this.h_air_setelah_kuras_0730) - parseFloat(this.h_air_0730 && this.h_air_0730 != "" ? this.h_air_0730 : 0)).toFixed(1)
                        // this.h_air_diff_sebelum_kuras_0730 = this.op_diff_baca_0730 == 9999 ? 9999 : (parseFloat(this.op_diff_baca_0730) - parseFloat(this.h_air_sebelum_kuras_0730)).toFixed(1)
                        this.h_air_diff_0730 = parseFloat((this.op_diff_baca_0730 - this.h_air_sebelum_kuras_0730) + (this.h_air_setelah_kuras_0730 - this.h_air_0730)).toFixed(1)
                        this.h_air_diff_setelah_kuras_0730 = this.h_air_0730 == 9999 ? 9999 : parseFloat(this.h_air_setelah_kuras_0730 - this.h_air_0730 ).toFixed(1)
                        this.h_air_diff_sebelum_kuras_0730 = this.op_diff_baca_0730 == 9999 ? 9999 : parseFloat(this.op_diff_baca_0730 - this.h_air_sebelum_kuras_0730).toFixed(1)
                    }
                    else {
                        this.h_air_diff_0730 = (+this.op_diff_baca_0730 - val).toFixed(1)
                    }
                }
                else {
                    this.h_air_diff_0730 = (+this.op_diff_baca_0730 - val).toFixed(1)
                }
    
                // this.op_eva_0730 = (this.op_diff_baca_0730 - parseFloat(val) + parseFloat(this.rr_0730)).toFixed(1)
                // this.op_eva_0730 = parseFloat(+this.h_air_diff_0730 + +this.rr_0730).toFixed(1)
                this.op_eva_0730 = this.rr_0730 == 9999 ? 9999 : this.rr_0730 == 8888 ? parseFloat(+this.h_air_diff_0730).toFixed(1) : parseFloat(+this.h_air_diff_0730 + +this.rr_0730).toFixed(1)
            }
        },
        rr_0730: function(val) {
            // this.op_eva_0730 = val == 9999 ? 9999 : (this.op_diff_baca_0730 - parseFloat(this.h_air_0730) + parseFloat(val)).toFixed(1)
            // this.op_eva_0730 = val == 9999 ? 9999 : parseFloat(+this.h_air_diff_0730 + +val).toFixed(1)
            this.op_eva_0730 = val == 9999 ? 9999 : (val == 8888 ? parseFloat(+this.h_air_diff_0730).toFixed(1) : parseFloat(+this.h_air_diff_0730 + +val).toFixed(1))
        },
        h_piche_0730: function(val) {
            let h_piche = (val && val != "null") ? val : 0
            // this.eva_piche_0730 = val == 9999 || this.pitche_sebelum_0730 == 9999 ? 9999 : (this.pitche_sebelum_0730 - h_piche).toFixed(1)
            this.eva_piche_0730 = val == 9999 || this.pitche_sebelum_0730 == 9999 ? 9999 : (h_piche - this.pitche_sebelum_0730).toFixed(1)
        },
        pitche_sebelum_0730 : function(val) {
            let piche_sebelum = (val && val != "null") ? val : 0
            // this.eva_piche_0730 = val == 9999 || this.h_piche_0730 == 9999 ? 9999 : (piche_sebelum - this.h_piche_0730).toFixed(1)
            this.eva_piche_0730 = val == 9999 || this.h_piche_0730 == 9999 ? 9999 : (this.h_piche_0730 - piche_sebelum).toFixed(1)
        },

        ttr_50cm_0730: function(val) {
            if (val == '') this.$v.ttr_50cm_0730.$reset()
        },
        ttr_100cm_0730: function(val) {
            if (val == '')  this.$v.ttr_100cm_0730.$reset()
        },
        ttg_50cm_0730: function(val) {
            if (val == '') this.$v.ttg_50cm_0730.$reset()
        },
        ttg_100cm_0730: function(val) {
            if (val == '') this.$v.ttg_100cm_0730.$reset()
        }
        
        
    },
}
</script>

<style>
.popover {
    z-index: 10 !important;
}
</style>

<style scoped>

.label-entry1 {
  /* text-align: center; */
  color: #3b4253;
  font-weight: 500;
  width: 100%;
  /* border: 2px solid #6e6b7b; */
  border-radius: 3px;
  padding: 4px;
}
.label-entry2 {
  text-align: center;
  color: #3b4253;
  font-weight: 500;
  width: 100%;
  border: 2px solid #6e6b7b;
  border-radius: 3px;
  padding: 4px;
}

.input-dark-grey {
    background-color: #EBEDF3;
}
.mbtm {
  margin-bottom: 7px;
}
.pad2{
    padding-left: 2px;
    padding-right: 2px;
}
.pad1{
    padding-left: 2px;
    padding-right: 5px;
}
.pad3{
    
    padding-right: 2px;
}

#input_op_diff_baca_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
}

#input_t_air_max_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
}

#input_t_air_min_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
}

#input_rr_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
}
#input_ttr_0cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }
#input_ttr_2cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }
#input_ttr_5cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }
  #input_ttr_10cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }
  #input_ttr_20cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }
  #input_ttr_50cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }
  #input_ttr_100cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }
#input_ttg_0cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }
#input_ttg_2cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }
#input_ttg_5cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }
  #input_ttg_10cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }
  #input_ttg_20cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }
  #input_ttg_50cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }
  #input_ttg_100cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }

  #input_tbk_5cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }
  #input_tbb_5cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }
  #input_tbk_10cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }
  #input_tbb_10cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }
  #input_tbk_20cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }
  #input_tbb_20cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }
  #input_tbk_50cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }
  #input_tbb_50cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }
  #input_tbk_100cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }
  #input_tbb_100cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }
  #input_tbk_150cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }
  #input_tbb_150cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }
  #input_tbk_200cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }
  #input_tbb_200cm_0730.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
  }


.bg-light-primary {
    color: #000000 !important;
}
.bg-light-success {
  color: #000000 !important;
}
</style>
