<template>
  <!-- <b-tabs align="center" pills card> -->

  <b-tabs>
    <b-tab title="FDIH">
      <fdih-main :roles="roles"/>
    </b-tab>
    <b-tab title="FORM" lazy>
      <pias-main :roles="roles"/>
    </b-tab>
	<b-tab title="LAB KU" lazy>
      <ku-main :roles="roles"/>
    </b-tab>
    <!-- <b-tab title="INGEST" lazy>
      <ingest-main />
    </b-tab> -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab, BCard } from "bootstrap-vue";
import FdihMain from "./klimatologi/FdihMain.vue";
import PiasMain from "./klimatologi/PiasMain.vue";
import KuMain from "./klimatologi/KuMain.vue";
import helper from "@/api/helper";
// import IngestMain from "./klimatologi/IngestMain.vue";

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    "fdih-main": FdihMain,
    "pias-main": PiasMain,
	"ku-main": KuMain,
    // "ingest-main": IngestMain,
  },

  data() {
    return {
      roles: null
    }
  },

  mounted() {
    this.listRoles()
  },

  methods: {
    listRoles() {
			let userLogin =  JSON.parse(localStorage.getItem('userLogin'))
			let role = userLogin.role
			let roles = {
				isRoleAdd: false,
				isRoleEdit: false,
				isRoleView: false,
        isRoleDelete: false
			}
			if(role != "admin") {
				let myRoles = helper.getMenuRoles(userLogin.listperm)
				// console.log('myRoles', myRoles)

				if (myRoles.hasOwnProperty("MegaEntry")) {
					if (myRoles.MegaEntry.includes("Add")) {
						roles['isRoleAdd'] = true
					}
					if (myRoles.MegaEntry.includes("Modify")) {
						roles['isRoleEdit'] = true
					}
					if (myRoles.MegaEntry.includes("View")) {
						roles['isRoleView'] = true
					}
					if (myRoles.MegaEntry.includes("Delete")) {
						roles['isRoleDelete'] = true
					}
				}
			}
			else {
				roles['isRoleAdd'] = true
				roles['isRoleEdit'] = true
				roles['isRoleView'] = true
				roles['isRoleDelete'] = true
			}
			// console.log('roles', roles)
			this.roles = roles
		},
  }
};
</script>
